import { ListItem, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';
import { TaskEditWorkloadSelector } from './TaskEditWorkloadSelector';

export interface TaskEditWorkloadCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditWorkloadCell = observer((props: TaskEditWorkloadCellProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;

  return (
    <ListItem sx={sx} className={className}>
      <ListItemText primary={localizationService.localizedStrings.studyo.contents.taskEdit.workloadLevel} />
      <TaskEditWorkloadSelector viewModel={viewModel} />
    </ListItem>
  );
});
