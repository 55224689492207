import { DateUtils } from '@shared/components/utils';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { first, last } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  NavigationService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { AppYearDayViewModel, YearDayViewModel } from './YearDayViewModel';
import { AppYearHeaderViewModel, YearHeaderViewModel } from './YearHeaderViewModel';
import { AppYearMonthViewModel, YearMonthViewModel } from './YearMonthViewModel';

export interface YearViewModel {
  readonly data: DataLoader;
  readonly header: YearHeaderViewModel;
  readonly months: YearMonthViewModel[];
}

export class AppYearViewModel implements YearViewModel {
  readonly header: YearHeaderViewModel;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    settingsStore: StudyoSettingsStore,
    uiSettingsStore: UISettingsStore
  ) {
    makeObservable(this);
    const preferences = settingsStore.getPreferences(_accountService.displayedAccountData.accountId);

    this.header = new AppYearHeaderViewModel(
      _localizationService,
      navigationService,
      attachmentManager,
      this.data,
      preferences,
      uiSettingsStore
    );
  }

  @computed.struct
  private get days(): YearDayViewModel[] {
    return this.data.schoolDays.map((sd) => new AppYearDayViewModel(sd));
  }

  @computed
  get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  get months(): YearMonthViewModel[] {
    const configFirstDay = first(this.data.schoolDays);
    const configLastDay = last(this.data.schoolDays);

    if (configFirstDay == null || configLastDay == null) {
      return [];
    }

    const months: YearMonthViewModel[] = [];
    let firstMonthDate = Day.create({ day: 1, month: configFirstDay.day.month, year: configFirstDay.day.year });

    while (firstMonthDate.isSameOrBefore(configLastDay.day)) {
      const schoolDays = this.days.filter((day) => DateUtils.daysAreInSameMonth(firstMonthDate, day.schoolDay.day));
      months.push(new AppYearMonthViewModel(this._localizationService, firstMonthDate, schoolDays));
      firstMonthDate = firstMonthDate.addMonths(1);
    }

    return months;
  }
}
