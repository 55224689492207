import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface PlannerSectionInfoStudentRowViewModel {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly fullName: string;
  readonly avatarColor: Color;
  readonly emailAddress: string;
  readonly parentEmailAdresses: string[];
}

export class AppPlannerSectionInfoStudentRowViewModel implements PlannerSectionInfoStudentRowViewModel {
  constructor(
    private readonly _student: AccountModel,
    private readonly _data: AccountData
  ) {
    makeObservable(this);
  }

  @computed
  get id(): string {
    return this._student.id;
  }

  @computed
  get firstName(): string {
    return this._student.firstName;
  }

  @computed
  get lastName(): string {
    return this._student.lastName;
  }

  @computed
  get fullName(): string {
    return AccountUtils.getDisplayLastFirstName(this._student, this._student.visibleEmail);
  }

  @computed
  get avatarColor(): Color {
    return this._student.profile.avatarColor;
  }

  @computed
  get emailAddress(): string {
    return this._student.visibleEmail;
  }

  @computed
  get parentEmailAdresses(): string[] {
    return _(this._data.accounts)
      .filter((a) => a.childrenAccountIds.indexOf(this._student.id) >= 0)
      .map((a) => a.visibleEmail)
      .value();
  }
}
