import { ContentDefinition as PBContentDefinition } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { DateUtils } from '@shared/components/utils';
import { SchoolDay, SchoolDayPeriod } from '@shared/models/calendar';
import {
  ContentDefinition,
  ContentDefinitionModel,
  ContentPublishTarget,
  EditableContentDefinition,
  EditableContentPublishTarget
} from '@shared/models/content';
import { Day } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { verifyAndConfirmWorkload } from '@studyo/utils';
import { computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { NavigationService } from './NavigationService';

export interface ContentPasteboardStore {
  storedContent: ContentDefinitionModel | undefined;

  /**
   * Allow creating a copy of a content into a specific period.
   *
   * @param {ContentDefinitionModel} originalContent Content to make a copy of.
   * @param {Day} day Day in which the target period occurs.
   * @param {string} periodTag Tag of the target period.
   * @param {AccountData} data Data for the current account.
   * @param sectionId The sectionId of target period
   * @param {boolean} sameDueSection Whether or not the copy should have the same due section as the original.
   * @param {(boolean | undefined)} copyFromAssignment Determine whether it's the assignment or the due date
   * that is equal to `day`. The date will be determined via the date difference in the original content.
   * @param {(boolean | undefined)} shouldSave Indicates whether the copy should be saved.
   * @returns {ContentDefinitionModel} The new copy created.
   */
  pasteContentInPeriod(
    originalContent: ContentDefinitionModel,
    day: Day,
    periodTag: string,
    data: AccountData,
    sectionId: string | undefined,
    sameDueSection: boolean,
    copyFromAssignment: boolean | undefined,
    shouldSave?: boolean
  ): Promise<ContentDefinitionModel>;

  /**
   * Allow creating a copy of a content into a specific school day.
   *
   * @param {ContentDefinitionModel} originalContent Content to make a copy of.
   * @param {Day} day Day in which to copy the content
   * @param {(string | undefined)} specificSectionId If undefined, it will be equal to the original content section id.
   * @param {AccountData} data Data for the current account.
   * @param {(boolean | undefined)} copyFromAssignement Determine wheter it's the assignment or the due date
   * that is equal to `day`. The date will be determine via the date difference in the original content.
   * @param {(boolean | undefined)} shouldSave Indicates whether the copy should be saved.
   * @returns {ContentDefinitionModel} The new copy created.
   * @memberof ContentPasteboardManager
   */
  pasteContentInDay(
    originalContent: ContentDefinitionModel,
    day: Day,
    specificSectionId: string | undefined,
    data: AccountData,
    copyFromAssignement: boolean | undefined,
    shouldSave?: boolean
  ): Promise<ContentDefinitionModel>;
}

export class AppContentPasteboardStore implements ContentPasteboardStore {
  @observable private _storeContentPB: PBContentDefinition | undefined;

  constructor(private readonly _navigationService: NavigationService) {
    makeObservable(this);
  }

  @computed
  get storedContent() {
    if (this._storeContentPB != null) {
      return new ContentDefinition(this._storeContentPB);
    }

    return undefined;
  }

  set storedContent(value: ContentDefinitionModel | undefined) {
    this._storeContentPB = value != null ? value.toProtobuf() : undefined;
  }

  async pasteContentInPeriod(
    originalContent: ContentDefinitionModel,
    day: Day,
    periodTag: string,
    data: AccountData,
    sectionId: string | undefined,
    sameDueSection: boolean,
    copyFromAssignement: boolean | undefined,
    shouldSave = true
  ): Promise<ContentDefinitionModel> {
    // We may need to edit the group identifier for the original content,
    // so we create an editable content from it.
    const editableOriginalContent = new EditableContentDefinition(originalContent, false);

    const newContent = ContentDefinition.copy(originalContent, data.configId, data.accountId);
    const editableNewContent = new EditableContentDefinition(newContent, true);

    this.setContentsGroupId(editableOriginalContent, editableNewContent);

    const targetSchoolDay = data.schoolDaysByDay.get(day.asString);
    if (targetSchoolDay == null) {
      throw new Error('Trying to copy a task into an unknown school day');
    }

    const targetPeriod = targetSchoolDay.periods.find((period) => period.tag === periodTag);
    if (targetPeriod == null && periodTag.length > 0) {
      throw new Error('Trying to copy a task into an unknown school day period');
    }

    this.setSection(editableNewContent, editableOriginalContent, targetPeriod, day, data, sectionId, sameDueSection);

    if (originalContent.isMasterPublishedToSection) {
      // Published to section if original was also published to its section.
      editableNewContent.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
      editableNewContent.publishTarget.kind = 'section';
    } else if (
      originalContent.isMasterPublishedToAccounts &&
      originalContent.sectionId == editableNewContent.sectionId
    ) {
      // If we keep the same section and the original was published to students, the copy
      // is published to the same students. We compare section ids instead of relying on
      // sameDueSection since the target period's occurrences can determine it's the same
      // section.
      editableNewContent.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
      editableNewContent.publishTarget.kind = 'accounts';
      editableNewContent.publishTarget.targetAccountIds = originalContent.publishTarget?.targetAccountIds ?? [];
    }

    // Setting appropriate dates based on whether or not we are copying from the assignment date.
    if (copyFromAssignement == null || !copyFromAssignement) {
      this.privatePasteContentInPeriod(
        editableNewContent,
        editableOriginalContent,
        targetSchoolDay,
        targetPeriod,
        data
      );
    } else {
      this.pasteAssignmentInPeriod(editableNewContent, editableOriginalContent, targetSchoolDay, data);
    }

    if (shouldSave) {
      await this.saveContents(data, editableOriginalContent, editableNewContent);
      this.storedContent = editableOriginalContent;
    }

    return editableNewContent;
  }

  async pasteContentInDay(
    originalContent: ContentDefinitionModel,
    day: Day,
    specificSectionId: string | undefined,
    data: AccountData,
    copyFromAssignement: boolean | undefined,
    shouldSave = true
  ): Promise<ContentDefinitionModel> {
    // We may need to edit the group identifier for the original content,
    // so we create an editable content from it.
    const editableOriginalContent = new EditableContentDefinition(originalContent, false);

    const newContent = ContentDefinition.copy(originalContent, data.configId, data.accountId);
    const editableNewContent = new EditableContentDefinition(newContent, true);
    editableNewContent.duePeriodTag = '';

    this.setContentsGroupId(editableOriginalContent, editableNewContent);

    // If a specific section id has been passed, we use it. Otherwise, we default to the
    // original content section id.x
    editableNewContent.sectionId = specificSectionId ?? editableOriginalContent.sectionId;

    if (originalContent.isMasterPublishedToSection) {
      // Published to section if original was also published to its section.
      editableNewContent.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
      editableNewContent.publishTarget.kind = 'section';
    } else if (
      originalContent.isMasterPublishedToAccounts &&
      originalContent.sectionId == editableNewContent.sectionId
    ) {
      // If we keep the same section and the original was published to students, the copy
      // is published to the same students.
      editableNewContent.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
      editableNewContent.publishTarget.kind = 'accounts';
      editableNewContent.publishTarget.targetAccountIds = originalContent.publishTarget?.targetAccountIds ?? [];
    }

    const assignmentDelta = DateUtils.numberOfDaysBetween(
      editableOriginalContent.dueDay,
      editableOriginalContent.assignmentDay
    );

    if (copyFromAssignement) {
      editableNewContent.assignmentDay = day;

      // The due date is based on the days difference between the original assignment and due date.
      const newDueDay = day.addDays(assignmentDelta);
      // Making sure the new due date is not after the last school day for the config.
      const configLastDay = data.schoolDays.length > 0 ? data.schoolDays[data.schoolDays.length - 1].day : undefined;
      editableNewContent.dueDay = configLastDay?.isBefore(newDueDay) === true ? configLastDay : newDueDay;
    } else {
      editableNewContent.dueDay = day;

      // The assignment date is based on the days difference between the original assignment and due date.
      const newAssignmentDay = day.addDays(-assignmentDelta);
      // Making sure the new due date is not before the first school day for the config.
      const configFirstDay = data.schoolDays.length > 0 ? data.schoolDays[0].day : undefined;
      editableNewContent.assignmentDay =
        configFirstDay?.isAfter(newAssignmentDay) === true ? configFirstDay : newAssignmentDay;
    }

    editableNewContent.plannedDay = editableNewContent.assignmentDay;

    if (shouldSave) {
      await this.saveContents(data, editableOriginalContent, editableNewContent);
      this.storedContent = editableOriginalContent;
    }

    return editableNewContent;
  }

  //
  // Period paste
  //

  /**
   * Set dates when pasting a content from its assignment date into a period.
   *
   * @param newContent New
   * @param originalContent
   * @param schoolDay
   * @param data
   */
  private pasteAssignmentInPeriod(
    newContent: EditableContentDefinition,
    originalContent: EditableContentDefinition,
    schoolDay: SchoolDay,
    data: AccountData
  ) {
    // Content assignment and planned date are equal to the target period day.
    newContent.assignmentDay = schoolDay.day;
    newContent.plannedDay = schoolDay.day;

    const originalContentOccurrences = data.getOccurrencesForSectionId(originalContent.sectionId);

    // Occurrence in which the original content is due.
    const originalContentDueOccurrence = originalContentOccurrences.find(
      (occurrence) =>
        occurrence.day.isSame(originalContent.dueDay) && occurrence.period.tag === originalContent.duePeriodTag
    );
    // One occurrence occurring on the same day as the original content assignment date.
    const originalContentAssignmentOccurrence = originalContentOccurrences.find((occurrence) =>
      occurrence.day.isSame(originalContent.assignmentDay)
    );

    const newContentCourseOccurrences = data.getOccurrencesForSectionId(newContent.sectionId);
    // One occurrence occurring on the same day as the new content assignment date.
    const newContentAssignmentOccurrence = newContentCourseOccurrences.find((occurrence) =>
      occurrence.day.isSame(newContent.assignmentDay)
    );

    // If the original content assignement occurs on a date with a course occurrence, the original due day and tag
    // match a course occurrence for its section, and the new content assignement occurs on a date with
    // a course occurrence, we calculate the due date based on ordinal difference. Otherwise, it's based
    // on the day difference between the original content assignment and due date.
    if (
      newContentAssignmentOccurrence != null &&
      originalContentDueOccurrence != null &&
      originalContentAssignmentOccurrence != null
    ) {
      const assignmentOrdinalDelta =
        originalContentDueOccurrence.occurrence.ordinal - originalContentAssignmentOccurrence.occurrence.ordinal;
      const newContentDueOrdinal = newContentAssignmentOccurrence.occurrence.ordinal + assignmentOrdinalDelta;

      // If the predicted due ordinal exists, we use it. Otherwise, we use the assignment date.
      if (newContentDueOrdinal < newContentCourseOccurrences.length && newContentDueOrdinal >= 0) {
        const dueOccurrence = newContentCourseOccurrences[newContentDueOrdinal - 1];
        newContent.dueDay = dueOccurrence.day;
        newContent.duePeriodTag = dueOccurrence.period.tag;
      } else {
        newContent.dueDay = newContent.assignmentDay;
      }
    } else {
      // The due date is based on the days difference between the original assignement and due date.
      const dueDayDelta = DateUtils.numberOfDaysBetween(originalContent.dueDay, originalContent.assignmentDay);
      const newDueDay = newContent.assignmentDay.addDays(dueDayDelta);
      // Making sure the new due date is not after the last school day in the config.
      const configLastDay = data.schoolDays.length > 0 ? data.schoolDays[data.schoolDays.length - 1].day : undefined;
      newContent.dueDay = configLastDay?.isBefore(newDueDay) === true ? configLastDay : newDueDay;
    }
  }

  /**
   * Set dates when pasting a content from its due date into a period.
   *
   * @param newContent
   * @param originalContent
   * @param schoolDay
   * @param period
   * @param data
   */
  private privatePasteContentInPeriod(
    newContent: EditableContentDefinition,
    originalContent: EditableContentDefinition,
    schoolDay: SchoolDay,
    period: SchoolDayPeriod | undefined,
    data: AccountData
  ) {
    // Content due date tag are equal to the target period.
    newContent.dueDay = schoolDay.day;
    newContent.duePeriodTag = period?.tag ?? '';

    const originalContentOccurrences = data.getOccurrencesForSectionId(originalContent.sectionId);

    // Occurrence in which the original content is due.
    const originalContentDueOccurrence = originalContentOccurrences.find(
      (occurrence) =>
        occurrence.day.isSame(originalContent.dueDay) && occurrence.period.tag === originalContent.duePeriodTag
    );
    // One occurrence occurring on the same day as the original content assignment date.
    const originalContentAssignmentOccurrence = originalContentOccurrences.find((occurrence) =>
      occurrence.day.isSame(originalContent.assignmentDay)
    );

    const periodOccurrence =
      period != null ? data.periodPrioritiesStore.getOccurrenceForPeriod(period, schoolDay.day) : undefined;

    // If the original content assignement occurs on a date with a course occurrence, the original due day and tag
    // match a course occurrence for its section, and the target period displays an occurrence of the new task
    // section, we calculate the due date based on ordinal difference. Otherwise, it's based
    // on the day difference between the original content assignment and due date.
    if (
      periodOccurrence != null &&
      periodOccurrence.sectionId === newContent.sectionId &&
      originalContentDueOccurrence != null &&
      originalContentAssignmentOccurrence != null
    ) {
      const assignmentOrdinalDelta =
        originalContentDueOccurrence.occurrence.ordinal - originalContentAssignmentOccurrence.occurrence.ordinal;

      const newContentAssignmentOrdinal = periodOccurrence.ordinal - assignmentOrdinalDelta;
      const newContentCourseOccurrences = data.getOccurrencesForSectionId(newContent.sectionId);
      // If an occurrence exists, we use it to get the assignment date.
      let newAssignmentDay = newContent.dueDay;

      if (
        newContentAssignmentOrdinal < newContentCourseOccurrences.length &&
        newContentAssignmentOrdinal >= 0 &&
        newContentCourseOccurrences.length > 0
      ) {
        const targetAssignmentOccurrence = newContentCourseOccurrences[newContentAssignmentOrdinal - 1];

        if (targetAssignmentOccurrence != null) {
          newAssignmentDay = targetAssignmentOccurrence.day;
        }
      }

      newContent.assignmentDay = newAssignmentDay;
    } else {
      // The assignment date is based on the days difference between the original assignment and due date.
      const assignmentDayDelta = DateUtils.numberOfDaysBetween(originalContent.dueDay, originalContent.assignmentDay);
      const newAssignmentDay = newContent.dueDay.addDays(-assignmentDayDelta);
      // Making sure the new due date is not before the first school day in the config.
      const configFirstDay = data.schoolDays.length > 0 ? data.schoolDays[0].day : undefined;
      newContent.assignmentDay = configFirstDay?.isAfter(newAssignmentDay) === true ? configFirstDay : newAssignmentDay;
    }

    newContent.plannedDay = newContent.assignmentDay;
  }

  //
  // Generic
  //

  private setContentsGroupId(originalContent: EditableContentDefinition, newContent: EditableContentDefinition) {
    if (originalContent.contentsGroupIdentifier.length > 0) {
      newContent.contentsGroupIdentifier = originalContent.contentsGroupIdentifier;
    } else {
      const groupIdentifier = uuidv4();
      newContent.contentsGroupIdentifier = groupIdentifier;
      originalContent.contentsGroupIdentifier = groupIdentifier;
    }
  }

  private setSection(
    newContent: EditableContentDefinition,
    originalContent: EditableContentDefinition,
    period: SchoolDayPeriod | undefined,
    day: Day,
    data: AccountData,
    sectionId: string | undefined,
    sameDueSection: boolean
  ) {
    if (sectionId != null) {
      newContent.sectionId = sectionId;
      return;
    }

    if (period != null) {
      const periodCourseOccurrence = data.periodPrioritiesStore.getOccurrenceForPeriod(period, day);

      if (periodCourseOccurrence != null && (!sameDueSection || data.account.role === 'teacher')) {
        newContent.sectionId = periodCourseOccurrence.sectionId;
        return;
      }
    }

    newContent.sectionId = originalContent.sectionId;
  }

  //
  // Save
  //

  private async saveContents(
    data: AccountData,
    originalContent: EditableContentDefinition,
    newContent: EditableContentDefinition
  ) {
    if (newContent.isMaster) {
      if (!(await verifyAndConfirmWorkload(this._navigationService, data, newContent, true))) {
        return;
      }
    }

    await data.createOrUpdateContent(originalContent);
    await data.createOrUpdateContent(newContent);
  }
}
