import { Button, Divider, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../../UseStudyoServicesHook';
import { TimelineListSectionBaseViewModel } from '../../../../viewmodels';

export interface TimelineListHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineListSectionBaseViewModel;
}

export const TimelineListHeader = observer((props: TimelineListHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.timeline;

  if (viewModel.isReadOnly) {
    return null;
  }

  const onMovePlannedActionButtonPressed = () => {
    viewModel.moveAllPlannedDates();
  };

  const onEditActionButtonPressed = () => {
    if (viewModel.hasSelection) {
      void viewModel.markSelectedContentsAsDone();
    } else {
      viewModel.selectAllContents();
    }
  };

  const onSelectPastActionButtonPressed = () => {
    viewModel.selectPastContents();
  };

  const onEditButtonPressed = () => {
    viewModel.isEditing = !viewModel.isEditing;
  };

  return (
    <Stack sx={sx} className={className} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {!viewModel.isEditing && (
          <Button onClick={onMovePlannedActionButtonPressed} size="small">
            {strings.moveAllPlannedDates}
          </Button>
        )}

        {viewModel.isEditing && (
          <Button onClick={onEditActionButtonPressed} size="small">
            {viewModel.hasSelection
              ? viewModel.editActionType === 'complete'
                ? strings.markSelectionAsDone
                : strings.markSelectionAsNotDone
              : strings.selectAllTasks}
          </Button>
        )}

        {viewModel.isEditing && !viewModel.hasSelection && (
          <>
            <Divider orientation="vertical" sx={{ height: 20 }} />
            <Button onClick={onSelectPastActionButtonPressed} size="small">
              {strings.selectPastTasks}
            </Button>
          </>
        )}
      </Stack>

      <Button onClick={onEditButtonPressed} size="small">
        {viewModel.isEditing ? strings.cancelListEdit : strings.listEdit}
      </Button>
    </Stack>
  );
});
