import { AccountUtils, CourseOccurrenceUtils, SectionUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Color, Day, Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { EmailInfo, LinkingService, OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { OptionsDialogElement } from '@studyo/models';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AccountAutoSyncService, AccountService } from '../../services';

export interface NoteEditViewModel extends OnInit, OnDestroy {
  readonly hasChanges: boolean;
  readonly canEdit: boolean;

  readonly day: Day;
  readonly periodTag: string;
  readonly periodStartTime: Time | undefined;
  readonly periodEndTime: Time | undefined;

  readonly sectionTitle: string | undefined;
  readonly sectionNumber: string | undefined;
  readonly sectionColor: Color | undefined;
  readonly isSkipped: boolean;
  readonly showTeacherEmailSelection: boolean;
  readonly occurrenceTitle: string | undefined;
  readonly teachersName: string | undefined;
  readonly teacherHasEmail: boolean;
  readonly teacherSelectionElements: OptionsDialogElement[];
  readonly roomName: string | undefined;

  notes: string;

  cancel: () => Promise<void>;
  closeAllModals: () => void;
  hideTeacherSelectionDialog: () => void;
  sendEmailToTeacher: () => void;
  save: () => Promise<void>;
}

export class AppNoteEditViewModel implements NoteEditViewModel {
  @observable private _showTeacherEmailSelection = false;
  private _data: AccountData;
  private _note: EditableContentDefinition;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _linkingService: LinkingService,
    private readonly _onSuccess: (value: string) => void,
    private readonly _onCancel: () => void,
    private readonly _sectionId: string | undefined,
    public readonly day: Day,
    public readonly periodTag: string,
    accountService: AccountService
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;

    const existingNotes = this._data.contents.filter(
      (cd) => cd.kind === 'note' && cd.dueDay.isSame(day) && cd.duePeriodTag === periodTag
    );

    let existingNote: ContentDefinitionModel | undefined = undefined;

    if (existingNotes.length > 0) {
      // If multiple notes in period, we find the first note which does have note.
      // If they're all empty, we take the first one.
      existingNote = existingNotes.find((cd) => cd.notes.length > 0) ?? existingNotes[0];
    }

    if (existingNote != null) {
      this._note = new EditableContentDefinition(existingNote, false);
    } else {
      this._note = EditableContentDefinition.createNewNote(this._data.configId, this._data.accountId, day, periodTag);
    }
  }

  @computed
  private get periodForTag() {
    const schoolDay = this._data.schoolDaysByDay.get(this.day.asString);
    if (schoolDay == null) {
      return undefined;
    }

    return schoolDay.periods.find((period) => {
      if (period.tag !== this.periodTag) {
        return false;
      }

      const occurrence = this._data.periodPrioritiesStore.getOccurrenceForPeriod(period, this.day);
      return occurrence?.sectionId === this._sectionId;
    });
  }

  @computed
  private get occurrence() {
    const period = this.periodForTag;
    if (period != null) {
      return this._data.periodPrioritiesStore.getOccurrenceForPeriod(period, this.day);
    } else {
      return undefined;
    }
  }

  @computed
  private get section(): SectionModel | undefined {
    return this.occurrence != null ? this._data.sectionsById.get(this.occurrence.sectionId) : undefined;
  }

  @computed
  get hasChanges() {
    return this._note.hasChanges;
  }

  @computed
  get canEdit() {
    return !this._data.isReadOnly && this._data.canAddOrEditContentAtDate(this.day);
  }

  @computed
  get periodStartTime() {
    const period = this.periodForTag;

    return period?.startTime;
  }

  @computed
  get periodEndTime() {
    const period = this.periodForTag;

    return period?.endTime;
  }

  @computed
  get sectionTitle(): string | undefined {
    return this.section != null ? this.section.title : undefined;
  }

  @computed
  get sectionNumber(): string | undefined {
    return this.section != null ? this.section.sectionNumber : undefined;
  }

  @computed
  get sectionColor(): Color | undefined {
    return SectionUtils.getSectionColor(this.section, this._data.account, undefined);
  }

  @computed
  get isSkipped(): boolean {
    return this.occurrence?.skipped ?? false;
  }

  @computed
  get showTeacherEmailSelection() {
    return this._showTeacherEmailSelection;
  }

  @computed
  get occurrenceTitle(): string | undefined {
    return this.occurrence?.displayTitle;
  }

  @computed
  get teachersName(): string | undefined {
    if (this.section == null) {
      return undefined;
    }

    const teachers = this.sortedTeacherAccounts;
    const firstTeacher = SectionUtils.getFirstTeacher(this.section, teachers);

    if (firstTeacher == null) {
      return undefined;
    }

    const firstDisplayName = AccountUtils.getDisplayLastFirstName(firstTeacher, firstTeacher.visibleEmail);

    if (teachers.length > 1) {
      return `${firstDisplayName} +${teachers.length - 1}`;
    } else {
      return firstDisplayName;
    }
  }

  @computed
  get teacherHasEmail(): boolean {
    return this.sortedTeacherAccounts.find((teacher) => teacher.visibleEmail.length > 0) != null;
  }

  @computed
  get roomName(): string | undefined {
    if (this.occurrence != null) {
      return this.occurrence.customRoomName.length > 0 ? this.occurrence.customRoomName : this.occurrence.roomName;
    } else {
      return undefined;
    }
  }

  @computed
  get notes(): string {
    return this._note.notes;
  }

  set notes(value: string) {
    this._note.notes = value;
  }

  @computed
  get teacherSelectionElements() {
    return _.map(this.sortedTeacherAccounts, (t, i) => {
      const element: OptionsDialogElement = {
        title: AccountUtils.getDisplayLastFirstName(t, t.visibleEmail),
        type: 'normal',
        selectionHandler: () => {
          this.didSelectTeacherAtIndex(i);
        }
      };

      return element;
    });
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  @action
  hideTeacherSelectionDialog() {
    this._showTeacherEmailSelection = false;
  }

  sendEmailToTeacher() {
    if (this.sortedTeacherAccounts.length > 1) {
      runInAction(() => {
        this._showTeacherEmailSelection = true;
      });
    } else {
      this.didSelectTeacherAtIndex(0);
    }
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async save(): Promise<void> {
    const result = await this._data.createOrUpdateContent(this._note);
    this._note = new EditableContentDefinition(result);
  }

  cancel(): Promise<void> {
    this._onCancel();
    return Promise.resolve();
  }

  closeAllModals() {
    this._onSuccess(this._note.id);
  }

  private didSelectTeacherAtIndex(index: number) {
    this.hideTeacherSelectionDialog();

    if (this.sortedTeacherAccounts != null) {
      const message = AccountUtils.getEmailBody(
        this.sectionTitle ?? '',
        this._data,
        this._localizationService.localizedStrings
      );
      const teacher = this.sortedTeacherAccounts[index];
      const info: EmailInfo = {
        to: [teacher.visibleEmail],
        body: message
      };

      this._linkingService.launchEmail(info);
    }
  }

  @computed
  private get sortedTeacherAccounts(): AccountModel[] {
    if (this.occurrence != null) {
      return CourseOccurrenceUtils.getSortedTeacherAccounts(
        this.occurrence,
        this._data.accountsById,
        this._localizationService.currentLocale
      );
    }

    return [];
  }
}
