import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';
import { AccountService, UISettingsStore } from '../../services';

export interface DayInfoViewModel {
  readonly configId: string;
  readonly accountId: string;
  readonly schoolDay: SchoolDay | undefined;
  readonly setCurrentSchoolDayInDaily: () => void;
}

export class AppDayInfoViewModel implements DayInfoViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _day: Day | undefined
  ) {
    makeObservable(this);
  }

  @computed
  get configId(): string {
    return this._accountService.displayedAccountData.configId;
  }

  @computed
  get accountId(): string {
    return this._accountService.displayedAccountData.accountId;
  }

  @computed
  get schoolDay() {
    return this._day != null
      ? this._accountService.displayedAccountData.schoolDaysByDay.get(this._day.asString)
      : undefined;
  }

  setCurrentSchoolDayInDaily() {
    if (this._day == null) {
      return;
    }

    this._uiSettingsStore.dailyCurrentDay = this._day;
  }
}
