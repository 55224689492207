import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, CircularProgress, Divider, Stack, SxProps, Typography } from '@mui/material';
import { DataPresenter, WorkloadHeader } from '@studyo/components';
import { AgendaWorkloadViewModel } from '@studyo/viewmodels';
import { Observer, observer } from 'mobx-react';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface AgendaWorkloadViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaWorkloadViewModel;
}

export const AgendaWorkloadView = observer((props: AgendaWorkloadViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const [lastRefreshId, setLastRefreshId] = useState<number | undefined>();
  const [hasError, setHasError] = useState(false);
  const strings = localizationService.localizedStrings.studyo.agenda.workload;

  const onContentLoad = () => {
    setLastRefreshId(viewModel.urlRefreshId);
  };

  const onContentError = () => {
    setHasError(true);
  };

  const refreshContent = () => {
    setHasError(false);

    viewModel.refresh();
  };

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => (
        <Observer>
          {() => (
            <Stack width="100%" height="100%" overflow="hidden">
              <Box>
                <WorkloadHeader viewModel={viewModel.header} />
              </Box>

              <Divider />

              <Stack flex={1} position="relative">
                {lastRefreshId != null && lastRefreshId !== viewModel.urlRefreshId && (
                  <Stack sx={{ flex: 1 }} alignItems="center" justifyContent="center" spacing={2}>
                    <CircularProgress />
                    <Typography variant="body2" color="textSecondary">
                      {strings.loadingTitle}
                    </Typography>
                  </Stack>
                )}

                {hasError && (
                  <Stack
                    sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <ErrorIcon fontSize="large" color="error" />
                    <Typography variant="body1">{strings.errorMessage}</Typography>

                    <Button variant="contained-grey" onClick={refreshContent} sx={{ minWidth: 250 }}>
                      {strings.refresh}
                    </Button>
                  </Stack>
                )}

                <iframe
                  src={viewModel.url}
                  style={{ border: 'none', flex: 1 }}
                  onLoad={onContentLoad}
                  onError={onContentError}
                />
              </Stack>
            </Stack>
          )}
        </Observer>
      )}
    />
  );
});
