import { protobufFromSubscriptionEntitlement } from '@shared/models/types/EnumConversion';
import { SubscriptionsTransport } from '@shared/services/transports';
import { RouteTemplates } from '../Routes';
import { SubscriptionsManager } from './SubscriptionsManager';

export class WebSubscriptionsManager implements SubscriptionsManager {
  constructor(private readonly _transport: SubscriptionsTransport) {}

  async getCheckoutUrl(configId: string, accountId: string, entitlement: 'parent-access'): Promise<string> {
    const origin = window.location.origin;

    const successReturnUrl = origin + RouteTemplates.redirect.success;
    const cancelReturnUrl = 'https://studyo.co';

    return await this._transport.getCheckoutUrl(
      configId,
      accountId,
      protobufFromSubscriptionEntitlement(entitlement),
      successReturnUrl,
      cancelReturnUrl
    );
  }
}
