import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PresenterRejectedViewModel } from '../../../viewmodels';

export interface PresenterRejectedViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PresenterRejectedViewModel;
}

export const PresenterRejectedView = observer((props: PresenterRejectedViewProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.studyo.presenterErrorStrings;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const isParentAccessDenied = viewModel.reason === 'parent-access-denied';

  const onBuyButtonPressed = () => {
    void viewModel.buyAccess();
  };

  const onContactUsButtonPressed = () => {
    viewModel.displaySupport();
  };

  const onReloadButtonPressed = () => {
    void viewModel.reload();
  };

  const onProfilesButtonPressed = () => {
    void viewModel.viewProfiles();
  };

  const onLogoutButtonPressed = () => {
    void viewModel.logout();
  };

  return (
    <Stack
      sx={sx}
      className={className}
      alignItems="center"
      justifyContent={isExtraSmallScreen ? 'stretch' : 'center'}
      m={2}
      spacing={4}
    >
      <Stack alignItems="center" spacing={1}>
        <TintedImage
          source={imageService.studyoImages.generic.error}
          color={theme.palette.error.main}
          sx={{ width: 40, height: 40 }}
        />

        <Stack spacing={0.5}>
          <Typography variant="h6" textAlign="center">
            {viewModel.title}
          </Typography>

          {viewModel.message != null && (
            <Typography variant="body2" textAlign="center">
              {viewModel.message}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box sx={{ height: '1px', width: 200, backgroundColor: theme.studyo.lists.separatorColor }} />

      <Stack spacing={1}>
        {isParentAccessDenied && (
          <Stack spacing={0.5}>
            <Button
              variant="contained"
              onClick={onBuyButtonPressed}
              disabled={viewModel.isProcessing}
              sx={{ width: 250 }}
            >
              {strings.buy}
            </Button>

            {viewModel.error != null && (
              <Typography variant="subtitle2" color="error" textAlign="center">
                {viewModel.error}
              </Typography>
            )}
          </Stack>
        )}

        <Button
          variant="contained"
          onClick={onContactUsButtonPressed}
          disabled={viewModel.isProcessing}
          sx={{ width: 250 }}
        >
          {strings.contactUs}
        </Button>

        <Button
          variant="contained-grey"
          onClick={onReloadButtonPressed}
          disabled={viewModel.isProcessing}
          sx={{ width: 250 }}
        >
          {strings.reload}
        </Button>

        <Button
          variant="contained-grey"
          onClick={onProfilesButtonPressed}
          disabled={viewModel.isProcessing}
          sx={{ width: 250 }}
        >
          {strings.viewOtherProfiles}
        </Button>

        <Button
          variant="contained-grey"
          onClick={onLogoutButtonPressed}
          disabled={viewModel.isProcessing}
          color="error"
          sx={{ width: 250 }}
        >
          {strings.logout}
        </Button>
      </Stack>
    </Stack>
  );
});
