export interface Equatable {
  equals: <T>(other: T) => boolean;
}

export class EquatableSet<T> extends Set<T> {
  constructor(private readonly _equalsFn: (first: T, second: T) => boolean) {
    super();
  }

  add(value: T): this {
    let found = false;
    this.forEach((item) => {
      if (this._equalsFn(value, item)) {
        found = true;
      }
    });

    if (!found) {
      super.add(value);
    }

    return this;
  }

  addValues(values: T[]): this {
    values.forEach((value) => {
      let found = false;
      this.forEach((item) => {
        if (this._equalsFn(value, item)) {
          found = true;
        }
      });

      if (!found) {
        super.add(value);
      }
    });

    return this;
  }

  toArray(): T[] {
    return Array.from(this);
  }
}
