import { Role } from '@shared/models/types';
import {
  AccountComponentsStrings,
  EnglishAccountComponentsStrings,
  FrenchAccountComponentsStrings
} from './AccountComponentsStrings';
import {
  BehaviourSummaryStrings,
  EnglishBehaviourSummaryStrings,
  FrenchBehaviourSummaryStrings
} from './BehaviourSummaryStrings';
import {
  BrowserDetectorStrings,
  EnglishBrowserDetectorStrings,
  FrenchBrowserDetectorStrings
} from './BrowserDetectorStrings';
import {
  CalendarComponentsStrings,
  EnglishCalendarComponentsStrings,
  FrenchCalendarComponentsStrings
} from './CalendarComponentsStrings';
import {
  ConfirmationDialogStrings,
  EnglishConfirmationDialogStrings,
  FrenchConfirmationDialogStrings
} from './ConfirmationDialogStrings';
import {
  ConnectorComponentsStrings,
  EnglishConnectorComponentsStrings,
  FrenchConnectorComponentsStrings
} from './ConnectorComponentsStrings';
import {
  EnglishImportComponentsStrings,
  FrenchImportComponentsStrings,
  ImportComponentsStrings
} from './ImportComponentsStrings';
import { EnglishMessageDialogStrings, FrenchMessageDialogStrings, MessageDialogStrings } from './MessageDialogStrings';
import {
  EnglishRequiresFeatureConditionStrings,
  FrenchRequiresFeatureConditionStrings,
  RequiresFeatureConditionStrings
} from './RequiresFeatureConditionStrings';
import {
  EnglishSectionComponentsStrings,
  FrenchSectionComponentsStrings,
  SectionComponentsStrings
} from './SectionComponentsStrings';
import {
  EnglishTaskComponentsStrings,
  FrenchTaskComponentsStrings,
  TaskComponentsStrings
} from './TaskComponentsStrings';
import {
  EnglishThresholdsComponentStrings,
  FrenchThresholdsComponentStrings,
  ThresholdsComponentStrings
} from './ThresholdsComponentStrings';
import {
  EnglishUserComponentsStrings,
  FrenchUserComponentsStrings,
  UserComponentsStrings
} from './UserComponentsStrings';

export interface ComponentsStrings {
  readonly accounts: AccountComponentsStrings;
  readonly browserDetector: BrowserDetectorStrings;
  readonly calendar: CalendarComponentsStrings;
  readonly connectors: ConnectorComponentsStrings;
  readonly import: ImportComponentsStrings;
  readonly sections: SectionComponentsStrings;
  readonly tasks: TaskComponentsStrings;
  readonly users: UserComponentsStrings;
  readonly behaviourSummary: BehaviourSummaryStrings;
  readonly thresholds: ThresholdsComponentStrings;
  readonly confirmationDialog: ConfirmationDialogStrings;
  readonly messageDialog: MessageDialogStrings;
  readonly requiresFeatureCondition: RequiresFeatureConditionStrings;

  // non-categorized strings
  readonly autoEnrollRoles: string;
  readonly color: string;
  readonly copyId: string;
  readonly copyIdNotification: string;
  readonly currentWeek: string;
  readonly daily: string;
  readonly demoMode: string;
  readonly firstWeek: string;
  readonly lastWeek: string;
  readonly lateUnchecked: string;
  readonly lateUncheckedTasks: string;
  readonly metric: string;
  readonly monthly: string;
  readonly multipleValues: string;
  readonly nextWeek: string;
  readonly none: string;
  readonly notFound: string;
  readonly noVariation: string;
  readonly previousWeek: string;
  readonly unplanned: string;
  readonly unplannedTasks: string;
  readonly week: string;
  readonly weekly: string;
  readonly weekOf: string;

  getIncreaseVariationMessage: (variation: number) => string;
  getDecreaseVariationMessage: (variation: number) => string;
  getDisplayableRole: (role: Role) => string;
  getAutoEnrollmentRolesAndTags: (roles: Role[], tags: string[]) => string;
}

function englishDisplayableRole(role: Role): string {
  switch (role) {
    case 'individual':
      return 'Individual';
    case 'parent':
      return 'Parent';
    case 'student':
      return 'Student';
    case 'teacher':
      return 'Teacher';
    case 'school-staff':
      return 'School Staff';
    case 'studyo-staff':
      return 'Studyo Staff';
    default:
      return 'Unknown';
  }
}

function englishAutoEnrollmentRoles(roles: Role[], tags: string[]): string {
  let value = 'Auto-enrolled:';

  if (roles.length > 0) {
    value += '\n' + roles.map(englishDisplayableRole).join(', ');
  }

  const gradeLevelPrefix = 'gradeLevel=';
  const gradeLevelTags = tags.filter((tag) => tag.startsWith(gradeLevelPrefix));
  if (gradeLevelTags.length > 0) {
    value +=
      '\nGrade(s) ' +
      gradeLevelTags
        .map((tag) => tag.slice(gradeLevelPrefix.length))
        .sort()
        .join(',');
  }

  return value;
}

function frenchDisplayableRole(role: Role): string {
  switch (role) {
    case 'individual':
      return 'Individuel';
    case 'parent':
      return 'Parent';
    case 'student':
      return 'Élève';
    case 'teacher':
      return 'Enseignant';
    case 'school-staff':
      return 'Personnel de l’école';
    case 'studyo-staff':
      return 'Personnel de Studyo';
    default:
      return 'Inconnu';
  }
}

function frenchAutoEnrollmentRoles(roles: Role[], tags: string[]): string {
  let value = 'Auto-abonné:';

  if (roles.length > 0) {
    value += '\n' + roles.map(englishDisplayableRole).join(', ');
  }

  const gradeLevelPrefix = 'gradeLevel=';
  const gradeLevelTags = tags.filter((tag) => tag.startsWith(gradeLevelPrefix));
  if (gradeLevelTags.length > 0) {
    value +=
      '\nNiveau(x) ' +
      gradeLevelTags
        .map((tag) => tag.slice(gradeLevelPrefix.length))
        .sort()
        .join(',');
  }

  return value;
}

export const EnglishComponentsStrings: ComponentsStrings = {
  accounts: EnglishAccountComponentsStrings,
  browserDetector: EnglishBrowserDetectorStrings,
  calendar: EnglishCalendarComponentsStrings,
  connectors: EnglishConnectorComponentsStrings,
  import: EnglishImportComponentsStrings,
  sections: EnglishSectionComponentsStrings,
  tasks: EnglishTaskComponentsStrings,
  users: EnglishUserComponentsStrings,
  behaviourSummary: EnglishBehaviourSummaryStrings,
  thresholds: EnglishThresholdsComponentStrings,
  confirmationDialog: EnglishConfirmationDialogStrings,
  messageDialog: EnglishMessageDialogStrings,
  requiresFeatureCondition: EnglishRequiresFeatureConditionStrings,

  // non-categorized strings
  autoEnrollRoles: 'Roles',
  color: 'Color',
  copyId: 'Copy ID to clipboard',
  copyIdNotification: 'The ID was copied to the clipboard!',
  currentWeek: 'Current Week',
  daily: 'Daily',
  demoMode: 'DEMO MODE',
  firstWeek: 'First Week',
  lastWeek: 'Last Week',
  lateUnchecked: 'Late and unchecked',
  lateUncheckedTasks: 'late and unchecked tasks',
  metric: 'Metric:',
  monthly: 'Monthly',
  multipleValues: '(Multiple values)',
  nextWeek: 'Next Week',
  none: 'None',
  notFound: 'Page not found.',
  noVariation: 'No variation',
  previousWeek: 'Previous Week',
  unplanned: 'Unplanned',
  unplannedTasks: 'unplanned tasks',
  week: 'Week',
  weekly: 'Weekly',
  weekOf: 'Week of',

  getIncreaseVariationMessage: (variation: number) => `${variation}% increase`,
  getDecreaseVariationMessage: (variation: number) => `${variation}% decrease`,
  getDisplayableRole: englishDisplayableRole,
  getAutoEnrollmentRolesAndTags: englishAutoEnrollmentRoles
};

export const FrenchComponentsStrings: ComponentsStrings = {
  accounts: FrenchAccountComponentsStrings,
  browserDetector: FrenchBrowserDetectorStrings,
  calendar: FrenchCalendarComponentsStrings,
  connectors: FrenchConnectorComponentsStrings,
  import: FrenchImportComponentsStrings,
  sections: FrenchSectionComponentsStrings,
  tasks: FrenchTaskComponentsStrings,
  users: FrenchUserComponentsStrings,
  behaviourSummary: FrenchBehaviourSummaryStrings,
  thresholds: FrenchThresholdsComponentStrings,
  confirmationDialog: FrenchConfirmationDialogStrings,
  messageDialog: FrenchMessageDialogStrings,
  requiresFeatureCondition: FrenchRequiresFeatureConditionStrings,

  // non-categorized strings
  autoEnrollRoles: 'Rôles',
  color: 'Couleur',
  copyId: 'Copier l’identifiant',
  copyIdNotification: 'L’identifiant est copié dans le presse-papier!',
  currentWeek: 'Semaine actuelle',
  daily: 'Quotidien',
  demoMode: 'MODE DÉMO',
  firstWeek: 'Première semaine',
  lastWeek: 'Dernière semaine',
  lateUnchecked: 'Passées-dues, non-cochées',
  lateUncheckedTasks: 'Tâches passées-dues, non-cochées',
  metric: 'Métrique:',
  monthly: 'Mensuel',
  multipleValues: '(Valeurs multiples)',
  nextWeek: 'Semaine prochaine',
  none: 'Aucun',
  notFound: 'Page non-trouvée.',
  noVariation: 'Pas de variation',
  previousWeek: 'Semaine précédente',
  unplanned: 'Non-planifiées',
  unplannedTasks: 'tâches non-planifiées',
  week: 'Semaine',
  weekly: 'Hebdomadaire',
  weekOf: 'Semaine du',

  getIncreaseVariationMessage: (variation: number) => `Augmentation de ${variation}%`,
  getDecreaseVariationMessage: (variation: number) => `Diminution de ${variation}%`,
  getDisplayableRole: frenchDisplayableRole,
  getAutoEnrollmentRolesAndTags: frenchAutoEnrollmentRoles
};
