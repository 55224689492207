import { AllDayOfWeek, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { sortBy, times } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { YearDayViewModel } from './YearDayViewModel';

export interface YearMonthViewModel {
  readonly monthTitle: string;
  readonly weeks: (YearDayViewModel | undefined)[][];
}

export class AppYearMonthViewModel implements YearMonthViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _firstDayOfMonth: Day,
    private readonly _schoolDays: YearDayViewModel[]
  ) {
    makeObservable(this);
  }

  @computed
  get monthTitle(): string {
    return this._firstDayOfMonth.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  @computed
  get weeks(): (YearDayViewModel | undefined)[][] {
    const days = sortBy(this._schoolDays, [(sd) => sd.schoolDay.day.day]);

    const elementRows: (YearDayViewModel | undefined)[][] = [];

    if (days.length > 0) {
      let currentRow: (YearDayViewModel | undefined)[] = [];

      const firstElement = days[0];
      const numberOfPaddingDayBefore = AllDayOfWeek.indexOf(firstElement.schoolDay.day.dayOfWeek);

      for (let i = 0; i < numberOfPaddingDayBefore; i++) {
        currentRow.push(undefined);
      }

      for (const item of days) {
        currentRow.push(item);

        if (currentRow.length === 7) {
          elementRows.push(currentRow);
          currentRow = [];
        }
      }

      const numberOfPaddingDayAfter = currentRow.length === 0 ? 0 : 7 - currentRow.length;
      for (let i = 0; i < numberOfPaddingDayAfter; i++) {
        currentRow.push(undefined);
      }
      if (currentRow.length > 0) {
        elementRows.push(currentRow);
      }

      // We want a constant number of week between each month.
      // As some months can have 6 weeks, we make sure all month display 6 weeks.
      if (elementRows.length < 6) {
        const emptyRow: undefined[] = times(7).map(() => undefined);
        elementRows.push(emptyRow);
      }
    }

    return elementRows;
  }
}
