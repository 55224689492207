import { ThemeOptions } from '@mui/material';
import {
  AppStudyoDarkThemeColors,
  AppStudyoLightThemeColors,
  AppStudyoThemeDimensions,
  createAppStudyoTheme
} from '@studyo/theme';
import { elevatedButtonVariants } from './ElevatedButtonVariant.ts';

const lightStudyoTheme = createAppStudyoTheme('light');
const darkStudyoTheme = createAppStudyoTheme('dark');

export const StudyoMuiTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: AppStudyoLightThemeColors.blue1
    }
  },
  shared: lightStudyoTheme,
  studyo: lightStudyoTheme,
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary'
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: AppStudyoThemeDimensions.baseThicknessValue
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: AppStudyoThemeDimensions.baseThicknessValue
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: AppStudyoLightThemeColors.blue1,
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal,
          '&$focused': {
            fontSize: AppStudyoThemeDimensions.fontSizes.normal
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [...elevatedButtonVariants],
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiSwitch: {
      defaultProps: {
        disableTouchRipple: true
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '0 !important',
          marginRight: 16
        }
      }
    }
  }
};

export const StudyoMuiDarkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: AppStudyoDarkThemeColors.blue1
    }
  },
  shared: darkStudyoTheme,
  studyo: darkStudyoTheme,
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary'
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true
      },
      styleOverrides: {
        root: {
          padding: AppStudyoThemeDimensions.baseThicknessValue
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true
      },
      styleOverrides: {
        root: {
          padding: AppStudyoThemeDimensions.baseThicknessValue
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: AppStudyoDarkThemeColors.blue1,
          fontSize: AppStudyoThemeDimensions.fontSizes.normal
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: AppStudyoThemeDimensions.fontSizes.normal,
          '&$focused': {
            fontSize: AppStudyoThemeDimensions.fontSizes.normal
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'black'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [...elevatedButtonVariants],
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiSwitch: {
      defaultProps: {
        disableTouchRipple: true
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '0 !important',
          marginRight: 16
        }
      }
    }
  }
};
