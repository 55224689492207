import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { Dictionary, compact, flatten, groupBy, sortBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService } from '../../../services';
import { AppLinkedTasksDeleteOccurrenceViewModel } from './LinkedTasksDeleteOccurrenceViewModel';
import {
  AppLinkedTasksDeleteSectionViewModel,
  LinkedTasksDeleteSectionViewModel
} from './LinkedTasksDeleteSectionViewModel';

export interface LinkedTasksDeleteViewModel {
  readonly content: ContentDefinitionModel;
  readonly sections: LinkedTasksDeleteSectionViewModel[];
  readonly allContentsAreSelected: boolean;
  selectAll: () => void;
  toggleSelection: (sectionIndex: number, rowIndex: number) => void;
  cancel: () => void;
  dismiss: () => void;
  deleteSelected: () => Promise<void>;
}

export class AppLinkedTasksDeleteViewModel implements LinkedTasksDeleteViewModel {
  private readonly _sections: LinkedTasksDeleteSectionViewModel[];

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _contentId: string
  ) {
    makeObservable(this);
    this._sections = this.createSections();
  }

  @computed
  private get data() {
    return this._accountService.displayedAccountData;
  }

  @computed
  get content() {
    return this.data.contentsById.get(this._contentId)!;
  }

  @computed
  get sections() {
    return this._sections;
  }

  @computed
  get allContentsAreSelected() {
    let allTasksAreSelected = true;
    this._sections.forEach((section) => {
      section.occurrences.forEach((occurrence) => {
        if (!occurrence.isSelected) {
          allTasksAreSelected = false;
        }
      });
    });

    return allTasksAreSelected;
  }

  selectAll() {
    const newValue = !this.allContentsAreSelected;

    this._sections.forEach((section) => {
      section.occurrences.forEach((occurrence) => (occurrence.isSelected = newValue));
    });
  }

  toggleSelection(sectionIndex: number, rowIndex: number) {
    if (sectionIndex >= this.sections.length || sectionIndex < 0) {
      throw new Error('Trying to select with a section index which is out of bounds');
    }

    const section = this.sections[sectionIndex];
    if (rowIndex >= section.occurrences.length || rowIndex < 0) {
      throw new Error('Trying to select with a row index which is out of bounds');
    }

    const occurrence = section.occurrences[rowIndex];
    occurrence.isSelected = !occurrence.isSelected;
  }

  cancel() {
    this._onCancel();
  }

  dismiss() {
    this._onSuccess();
  }

  async deleteSelected() {
    const contentsToDelete = flatten(
      this._sections.map((s) => s.occurrences.filter((o) => o.isSelected).map((e) => e.content))
    );

    for (const content of contentsToDelete) {
      const editableContent = new EditableContentDefinition(content, false);
      editableContent.state = 'cancelled';
      editableContent.contentsGroupIdentifier = '';
      await this.data.createOrUpdateContent(editableContent);
    }
  }

  private createSections(): LinkedTasksDeleteSectionViewModel[] {
    const tasks = this.data.visibleContents.filter(
      (cd) => cd.contentsGroupIdentifier === this.content.contentsGroupIdentifier
    );

    const tasksBySectionId: Dictionary<ContentDefinitionModel[]> = groupBy(tasks, (t) => t.sectionId);
    const sections = Object.keys(tasksBySectionId).map((id) => this.data.sectionsById.get(id));
    const hasNoSectionTasks = sections.indexOf(undefined) >= 0;
    const sortedSections = sortBy(compact(sections), [(s) => s.title, (s) => s.sectionNumber]);

    let sectionsVM = sortedSections.map<LinkedTasksDeleteSectionViewModel>((section) => {
      const sectionTasks = tasksBySectionId[section.id].sort((t1, t2) => this.sortContent(t1, t2));
      const occurrences = sectionTasks.map(
        (task) => new AppLinkedTasksDeleteOccurrenceViewModel(task, task.id === this._contentId)
      );

      return new AppLinkedTasksDeleteSectionViewModel(
        this._localizationService,
        this.data.account,
        section,
        occurrences
      );
    });

    if (hasNoSectionTasks) {
      const noSectionTasks = tasksBySectionId[''].sort((t1, t2) => this.sortContent(t1, t2));
      const occurrences = noSectionTasks.map(
        (cd) => new AppLinkedTasksDeleteOccurrenceViewModel(cd, cd.id === this._contentId)
      );

      const noCourseSection = new AppLinkedTasksDeleteSectionViewModel(
        this._localizationService,
        this.data.account,
        undefined,
        occurrences
      );

      sectionsVM = [noCourseSection, ...sectionsVM];
    }

    return sectionsVM;
  }

  private sortContent(cd1: ContentDefinitionModel, cd2: ContentDefinitionModel) {
    const locale = this._localizationService.currentLocale;
    const dateCompare = cd1.dueDay.compare(cd2.dueDay);
    if (dateCompare !== 0) {
      return dateCompare;
    }
    return cd1.duePeriodTag.localeCompare(cd2.duePeriodTag, locale);
  }
}
