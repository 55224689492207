import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, CancelOutlined, DragHandle } from '@mui/icons-material';
import { Checkbox, IconButton, InputBase, ListItem, ListItemText, Stack, SxProps, useTheme } from '@mui/material';
import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskStepListItemViewModel } from '../../../viewmodels';
import { TaskStepCalendar } from './TaskStepCalendar';

export interface TaskStepListItemProps {
  sx?: SxProps;
  className?: string;
  index: number;
  viewModel: TaskStepListItemViewModel;
  displaySeparator?: boolean;
  dragHandle?: React.ReactNode;
}

export const TaskStepListItem = observer((props: TaskStepListItemProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.studyo.contents.stepList;
  const theme = useTheme();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: viewModel.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'grab',
    color: theme.palette.text.disabled,
    touchAction: 'none'
  };

  const addNewStep = () => {
    if (viewModel.isStepCreation) {
      viewModel.saveNewStep();
    }
  };

  const leftComponent = () => {
    return (
      <Observer>
        {() => (
          <>
            {viewModel.isStepCreation && viewModel.canEdit && (
              <IconButton size="medium" color="primary" onClick={addNewStep}>
                <Add fontSize="medium" />
              </IconButton>
            )}

            {!viewModel.isStepCreation && (
              <Checkbox
                checked={viewModel.isCompleted}
                disabled={!viewModel.canCompleteStep}
                onChange={(e) => (viewModel.isCompleted = e.currentTarget.checked)}
              />
            )}
          </>
        )}
      </Observer>
    );
  };

  const rightComponent = () => {
    return (
      <Stack direction="row" alignItems="center" mr={1} spacing={0.5}>
        <TaskStepCalendar viewModel={viewModel} />

        {viewModel.canEdit && !viewModel.isStepCreation && (
          <IconButton size="small" color="error" onClick={() => viewModel.delete()}>
            <CancelOutlined fontSize="small" />
          </IconButton>
        )}

        {viewModel.canReorder && <DragHandle {...attributes} {...listeners} style={dragHandleStyle} />}
      </Stack>
    );
  };

  return (
    <form ref={setNodeRef} style={style} onSubmit={() => addNewStep()}>
      <ListItem sx={sx} className={className}>
        {leftComponent()}

        {viewModel.canEdit ? (
          <InputBase
            sx={{ flex: 1 }}
            value={viewModel.title}
            onChange={(e) => (viewModel.title = e.currentTarget.value)}
            placeholder={strings.noTitle}
          />
        ) : (
          <ListItemText primary={viewModel.title.length > 0 ? viewModel.title : strings.noTitle} />
        )}

        {rightComponent()}
      </ListItem>
    </form>
  );
});
