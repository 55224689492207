export interface UseCodeStrings {
  readonly enterCodeTitle: string;
  readonly useStudentCodeTitle: string;
  readonly useParentCodeTitle: string;
  readonly enterCodeSubtitle: string;
  readonly useStudentCodeSubtitle: string;
  readonly useParentCodeSubtitle: string;
  readonly codePlaceholder: string;
  readonly validate: string;
  readonly continueAsStudent: string;
  readonly continueAsParent: string;
  readonly loggedInAs: string;
  readonly logout: string;
  readonly back: string;
  readonly invalidCode: string;
  readonly webOnlyMessage: string;
  readonly webOnlyTitle: string;
}

export const EnglishUseCodeStrings: UseCodeStrings = {
  enterCodeTitle: 'Enter a code',
  useStudentCodeTitle: 'STUDENT code',
  useParentCodeTitle: 'PARENT code',
  enterCodeSubtitle: 'Enter a code shared by your teacher or school',
  useStudentCodeSubtitle: 'Use this code to create a student account',
  useParentCodeSubtitle: 'Use this code to complete a parent account creation',
  codePlaceholder: 'Code',
  validate: 'Validate',
  continueAsStudent: 'Continue as a student',
  continueAsParent: 'Continue as a parent',
  loggedInAs: 'Logged in as',
  logout: 'Log out',
  back: 'Back',
  invalidCode: '* The entered code is invalid.',
  webOnlyMessage:
    'In order to join a school with a code, you must use the web application at studyo.app. Once joined, you will have access to your school here.',
  webOnlyTitle: 'Using a code'
};

export const FrenchUseCodeStrings: UseCodeStrings = {
  enterCodeTitle: 'Entrez un code',
  useStudentCodeTitle: 'Code ÉLÈVE',
  useParentCodeTitle: 'Code PARENT',
  enterCodeSubtitle: 'Entrez un code partagé par votre enseignant(e) ou votre école',
  useStudentCodeSubtitle: 'Utilisez ce code pour créer un compte élève',
  useParentCodeSubtitle: 'Utilisez ce code pour compléter la création d’un compte parent',
  codePlaceholder: 'Code',
  validate: 'Valider',
  continueAsStudent: 'Continuer comme élève',
  continueAsParent: 'Continuer comme parent',
  loggedInAs: 'Connecté avec',
  logout: 'Déconnexion',
  back: 'Retour',
  invalidCode: '* Le code entré est invalide.',
  webOnlyMessage:
    'Pour rejoindre une école à l’aide d’un code, vous devez utiliser l’application web à studyo.app. Une fois fait, vous aurez accès à votre école ici.',
  webOnlyTitle: 'Utilisation d’un code'
};
