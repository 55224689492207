import { Box, SxProps } from '@mui/material';
import { ObserverAutoSizer } from '@shared/components/layout';
import { ContentDefinitionModel } from '@shared/models/content';
import _, { chain } from 'lodash';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { DisplayableContentBoxViewModel } from '../../viewmodels';
import { DayAndWeekConstants } from '../agenda';
import { DisplayableContent, DisplayableContentRepresentation } from './DisplayableContent';
import { DisplayableContentBoxActionButton } from './DisplayableContentBoxActionButton';

interface LayoutValues {
  iconSize: number;
  itemWidth: number;
  itemHeight: number;
  numberOfItemsToDisplay: number;
  shortMode: ShortMode;
}

export type DisplayableContentBoxContentListKind = 'period' | 'school-day';

export interface DisplayableContentBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: DisplayableContentBoxViewModel;
  representationKind: DisplayableContentRepresentation;
  minItemWidth: number;
  minItemHeight: number;
  maxItemWidth?: number;
  maxItemHeight?: number;
  iconSize: number;
  itemSpacing: number;
  canAddTask?: boolean;
  canDragTasks?: boolean;
  accessoryView?: () => ReactNode;
  padding?: { top: number; left: number; right: number; bottom: number };
  alwaysDisplayActionButton?: boolean;
  contentListKind?: DisplayableContentBoxContentListKind;
  backgroundColor?: string;
  invertActionButtonColors?: boolean;
}

type ShortMode = 'disabled' | 'force-detailed' | 'force-dotted';

export const DisplayableContentBox = observer((props: DisplayableContentBoxProps) => {
  const {
    sx,
    className,
    accessoryView,
    padding,
    alwaysDisplayActionButton = false,
    backgroundColor,
    invertActionButtonColors,
    viewModel,
    canAddTask = true,
    contentListKind = 'period',
    itemSpacing,
    canDragTasks = false,
    maxItemHeight,
    maxItemWidth,
    iconSize: baseIconSize,
    minItemHeight,
    minItemWidth,
    representationKind
  } = props;

  const tapOnContent = (content: ContentDefinitionModel) => {
    const { viewModel } = props;

    if (content.kind === 'task') {
      void viewModel.openTaskInfoForContent(content);
    } else {
      void viewModel.openNoteEdit(content);
    }
  };

  const doubleTapOnContent = (content: ContentDefinitionModel) => {
    const { viewModel } = props;

    if (content.kind === 'task') {
      void viewModel.toggleStateForContent(content);
    } else if (content.kind === 'note') {
      void viewModel.openNoteEdit(content);
    }
  };

  const shouldDisplayMoreContentsButton = (layoutValues: LayoutValues) =>
    viewModel.canPaste ||
    !canAddTask ||
    !viewModel.canEdit ||
    layoutValues.numberOfItemsToDisplay < viewModel.contents.length;

  const onAddButtonPress = () => {
    void viewModel.createNewTask();
  };

  const onMoreButtonPress = () => {
    return openMoreContentList();
  };

  const openMoreContentList = () => {
    switch (contentListKind) {
      case 'period':
        return viewModel.openPeriodContentList();

      case 'school-day':
        return viewModel.openSchoolDayContentList();
    }
  };

  const computeLayout = (width: number, height: number): LayoutValues => {
    const tasksCount = viewModel.contents.length;

    const totalMinItemWidth = minItemWidth + itemSpacing;
    const totalMinItemHeight = minItemHeight + itemSpacing;
    const totalMaxItemWidth = maxItemWidth != null ? maxItemWidth + itemSpacing : undefined;
    const totalMaxItemHeight = maxItemHeight != null ? maxItemHeight + itemSpacing : undefined;

    const tasksPerRow = _.min([tasksCount, Math.floor(width / totalMinItemWidth)]) ?? 0;
    // We always want to display at least 1 task per row.
    let resolvedTasksPerRow = tasksPerRow || 1;

    if (totalMaxItemWidth != null && width / resolvedTasksPerRow > totalMaxItemWidth) {
      // If resulting item width.current would be greater than the max allowed, we recompute the number of tasks
      // per row with the maximum item size.
      resolvedTasksPerRow = Math.floor(width / totalMaxItemWidth) || 1;
    }
    const taskWidth = width / resolvedTasksPerRow;

    const rowCount = _.min([Math.ceil(tasksCount / resolvedTasksPerRow), Math.floor(height / totalMinItemHeight)]);
    // We always want to display at least 1 row.
    let resolvedRowCount = rowCount != null && rowCount > 0 ? rowCount : 1;
    if (totalMaxItemHeight != null && height / resolvedRowCount > totalMaxItemHeight) {
      // If resulting item height.current would be greater than the max allowed, we recompute the
      // number of rows with the maximum item size.
      resolvedRowCount = Math.floor(height / totalMaxItemHeight) || 1;
    }
    const taskHeight = height / resolvedRowCount;
    const numberOfItemsToDisplay = _.min([resolvedRowCount * resolvedTasksPerRow, tasksCount])!;
    const shortMode: ShortMode =
      rowCount === 0
        ? taskHeight < DayAndWeekConstants.detailedMinItemHeight
          ? 'force-dotted'
          : 'force-detailed'
        : 'disabled';
    const resolvedIconSize = height < baseIconSize + 2 ? DayAndWeekConstants.detailedIconSize : baseIconSize;

    return {
      itemHeight: taskHeight,
      itemWidth: taskWidth,
      numberOfItemsToDisplay,
      shortMode,
      iconSize: resolvedIconSize
    };
  };

  const renderElements = (layoutValues: LayoutValues) =>
    chain(viewModel.contents)
      .take(layoutValues.numberOfItemsToDisplay)
      .map((c) => (
        <DisplayableContent
          key={c.content.id}
          viewModel={c}
          representation={layoutValues.shortMode === 'force-detailed' ? 'detailed' : representationKind}
          sx={{
            margin: `${itemSpacing / 2}px`,
            width: layoutValues.itemWidth - itemSpacing,
            height: layoutValues.itemHeight - itemSpacing
          }}
          iconSize={layoutValues.iconSize}
          shouldDisplayTitle={true}
          smallMode={layoutValues.shortMode === 'force-dotted'}
          tapHandler={tapOnContent}
          doubleTapHandler={doubleTapOnContent}
          canBeDragged={canDragTasks}
          showUnreadMarker
        />
      ))
      .value();

  return (
    <Box sx={{ ...sx, backgroundColor, display: 'flex', flexDirection: 'column' }}>
      <ObserverAutoSizer>
        {({ width, height }) => {
          let paddingHorizontal = 0;
          let paddingVertical = 0;

          if (padding != null) {
            paddingHorizontal += padding?.left ?? 0;
            paddingHorizontal += padding?.right ?? 0;

            paddingVertical += padding?.top ?? 0;
            paddingVertical += padding?.bottom ?? 0;
          }

          // Removing 1 as we can get a rounded value of the actual size in the browser.
          // For example, a size of 200.5 in the browser width.current will end up to 201 here.
          // This means that the calculations are based on a higher number than the
          // space we actually have.
          const resolvedHeight = height - 1 - paddingVertical;
          const resolvedWidth = width - 1 - paddingHorizontal;
          const layoutValues = computeLayout(resolvedWidth, resolvedHeight);
          const displayMoreContentsButton = shouldDisplayMoreContentsButton(layoutValues);
          const actionButtonOnPress = displayMoreContentsButton ? onMoreButtonPress : onAddButtonPress;

          return (
            <Box
              sx={{ position: 'relative', cursor: 'pointer', display: 'flex', width, height }}
              onClick={(e) => {
                e.stopPropagation();

                if (displayMoreContentsButton) {
                  void openMoreContentList();
                } else {
                  void viewModel.createNewTask();
                }
              }}
              className={className}
            >
              <Box width="100%" height="100%">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    pl: `${padding?.left ?? 0}px`,
                    pr: `${padding?.right ?? 0}px`,
                    pt: `${padding?.top ?? 0}px`,
                    pb: `${padding?.bottom ?? 0}px`,
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}
                  flexWrap="wrap"
                  alignItems="top"
                >
                  {renderElements(layoutValues)}
                </Box>

                <Box
                  display="flex"
                  sx={{ position: 'absolute', right: 0, bottom: 0 }}
                  alignItems="center"
                  mb="1px"
                  mr={0.5}
                >
                  {(layoutValues.numberOfItemsToDisplay > 0 ||
                    alwaysDisplayActionButton ||
                    displayMoreContentsButton) && (
                    <DisplayableContentBoxActionButton
                      sx={{ mr: accessoryView != null ? 0.5 : 0 }}
                      kind={
                        displayMoreContentsButton
                          ? layoutValues.numberOfItemsToDisplay < viewModel.contents.length
                            ? 'more-overflow'
                            : 'more'
                          : 'add'
                      }
                      onPress={actionButtonOnPress}
                      backgroundColor={backgroundColor}
                      invertActionButtonColors={invertActionButtonColors}
                    />
                  )}

                  {accessoryView?.()}
                </Box>
              </Box>
            </Box>
          );
        }}
      </ObserverAutoSizer>
    </Box>
  );
});
