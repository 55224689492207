import { SectionModel } from '@shared/models/config';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { StudyoAccountSettings } from '../../../services';
import { AppPeriodsSectionListItemViewModel, PeriodsSectionListItemViewModel } from './PeriodsSectionListItemViewModel';

export interface PeriodsSectionListViewModel {
  readonly sections: PeriodsSectionListItemViewModel[];
}

export class AppPeriodsSectionListViewModel implements PeriodsSectionListViewModel {
  constructor(
    private readonly _preferences: StudyoAccountSettings,
    private _data: AccountData,
    private didSelectSection: (section: SectionModel) => void
  ) {
    makeObservable(this);
  }

  @computed
  get sections(): PeriodsSectionListItemViewModel[] {
    return _.map(
      this.userSections,
      (s) => new AppPeriodsSectionListItemViewModel(this._data, s, this.didSelectSection)
    );
  }

  @computed
  get hasSectionFiltersSet() {
    const numberOfSections = this._preferences.periodSectionFilters.length;
    return numberOfSections !== 0 && numberOfSections !== this._data.userSections.length;
  }

  @computed
  private get userSections(): SectionModel[] {
    const sectionIds = this._preferences.periodSectionFilters;
    let sections = this._data.userSections;

    if (this.hasSectionFiltersSet) {
      sections = _.filter(sections, (s) => sectionIds.includes(s.id));
    }

    return _.sortBy(sections, (s) => s.title);
  }
}
