import { Check } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  List,
  MenuItem,
  Select,
  Stack,
  SxProps,
  useTheme
} from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { MoveCourseOccurrencesDirection } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataPresenter, DialogHeader, ListSection, SchoolDayPicker } from '@studyo/components';
import {
  AllMoveCourseOccurrencesDirections,
  ContentMoveDateLimitType,
  PlannerPeriodContentMoveViewModel
} from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface PlannerPeriodContentMoveViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPeriodContentMoveViewModel;
}

export const PlannerPeriodContentMoveView = observer((props: PlannerPeriodContentMoveViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.planner.contentMoveStrings;
  const dateFormat = localizationService.localizedStrings.models.dateFormats.long;
  const theme = useTheme();

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => (
        <Stack width="100%" height="100%" overflow="hidden">
          <DialogHeader
            title={strings.title}
            color={SectionColors.get(viewModel.sectionColor)}
            tintColor={theme.studyo.periods.periodWithSectionTextColor}
            rightButton={{
              icon: (size) => <Check color="inherit" fontSize={size} />,
              color: 'inherit',
              action: () => void viewModel.save(),
              onlyShowOnExtraSmallScreen: true
            }}
          />

          <List sx={{ flex: 1, overflow: 'auto' }}>
            <ListSection>
              <Stack px={2} spacing={1}>
                <FormControl>
                  <InputLabel variant="filled" id="move-effect" shrink>
                    {strings.effectSectionTitle}
                  </InputLabel>
                  <Select
                    label={strings.effectSectionTitle}
                    labelId="move-effect"
                    variant="filled"
                    value={viewModel.contentMoveType}
                    onChange={(event) =>
                      (viewModel.contentMoveType = event.target.value as MoveCourseOccurrencesDirection)
                    }
                  >
                    {AllMoveCourseOccurrencesDirections.map((type) => (
                      <MenuItem key={type} value={type}>
                        {getTextForMoveType(type, localizationService)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {getNoticeForMoveType(viewModel.contentMoveType, localizationService)}
                  </FormHelperText>
                </FormControl>

                {viewModel.canSkip && (
                  <FormControlLabel
                    value="isSkipping"
                    control={
                      <Checkbox
                        checked={viewModel.isSkipping}
                        onChange={(event) => (viewModel.isSkipping = event.target.checked)}
                      />
                    }
                    label={strings.markAsSkipped}
                    labelPlacement="end"
                  />
                )}
              </Stack>
            </ListSection>

            <ListSection title={strings.includeOptionsTitle}>
              <Stack px={2} spacing={1}>
                <FormControlLabel
                  value="includeTitles"
                  control={
                    <Checkbox
                      checked={viewModel.includeTitles}
                      disabled={viewModel.canSkip && viewModel.isSkipping}
                      onChange={(event) => (viewModel.includeTitles = event.target.checked)}
                    />
                  }
                  label={strings.includeTitles}
                  labelPlacement="end"
                />

                {viewModel.contentMoveType === 'left' && viewModel.includeTitles && (
                  <FormControl>
                    <FormHelperText>{strings.includeTitlesNotice}</FormHelperText>
                  </FormControl>
                )}

                <FormControlLabel
                  value="includeTasks"
                  control={
                    <Checkbox
                      checked={viewModel.includeTasks}
                      onChange={(event) => (viewModel.includeTasks = event.target.checked)}
                    />
                  }
                  label={strings.includeTasks}
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="includeNotes"
                  control={
                    <Checkbox
                      checked={viewModel.includeNotes}
                      onChange={(event) => (viewModel.includeNotes = event.target.checked)}
                    />
                  }
                  label={strings.includeNotes}
                  labelPlacement="end"
                />
              </Stack>
            </ListSection>

            <ListSection>
              <Stack px={2} spacing={1}>
                <FormControl>
                  <InputLabel variant="filled" id="date-limit" shrink>
                    {strings.untilSectionTitle}
                  </InputLabel>

                  <Select
                    variant="filled"
                    label={strings.untilSectionTitle}
                    labelId="date-limit"
                    value={viewModel.dateLimitType}
                    onChange={(event) => (viewModel.dateLimitType = event.target.value as ContentMoveDateLimitType)}
                    renderValue={(value) =>
                      value === 'specific-date'
                        ? viewModel.dateLimit.formattedString(dateFormat)
                        : strings.endOfYearOption
                    }
                  >
                    <MenuItem value="end-school-year">{strings.endOfYearOption}</MenuItem>
                    <MenuItem value="specific-date">{strings.specificDateOption}</MenuItem>
                  </Select>
                </FormControl>

                {viewModel.dateLimitType === 'specific-date' && (
                  <SchoolDayPicker
                    sx={{ height: 300, pt: 1 }}
                    viewModel={viewModel.schoolDaysViewModel}
                    onSelection={(schoolDay) => (viewModel.dateLimit = schoolDay.day)}
                  />
                )}
              </Stack>
            </ListSection>
          </List>
        </Stack>
      )}
    />
  );
});

function getTextForMoveType(type: MoveCourseOccurrencesDirection, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.studyo.agenda.planner.contentMoveStrings;

  switch (type) {
    case 'left':
      return strings.movePreviousEffect;
    case 'right':
      return strings.moveNextEffect;
    case 'right-skip':
      return strings.skipPeriodEffect;
  }
}

function getNoticeForMoveType(type: MoveCourseOccurrencesDirection, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.studyo.agenda.planner.contentMoveStrings;

  switch (type) {
    case 'left':
      return strings.movePreviousNotice;
    case 'right':
      return strings.moveNextNotice;
  }

  return '';
}
