import { css } from '@emotion/css';
import { Box, Button, Stack, SxProps, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { OnboardingCodeKind } from '@shared/models/types';
import { UseCodeStrings } from '@shared/resources/strings/studyo/onboarding/UseCodeStrings';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { UseCodeViewModel } from '../../viewmodels';

export interface UseCodeViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: UseCodeViewModel;
}

export const UseCodeView = observer((props: UseCodeViewProps) => {
  const { localizationService, navigationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = localizationService.localizedStrings.studyo.onboarding.useCode;
  const navigate = useNavigate();

  const logout = () => {
    void viewModel.logout();
  };

  const goBack = () => {
    void viewModel.goBack(navigate);
  };

  const validateCode = () => {
    void viewModel.validateCode();
  };

  const useCode = async () => {
    const account = await viewModel.useCode();
    if (account != null) {
      navigate(navigationService.getAgendaScreenLocation('daily', account.configId, account.id));
    }
  };

  const title = getTitle(viewModel.codeKind, strings);
  const subtitle = getSubtitle(viewModel.codeKind, strings);
  const continueButtonLabel = getContinueButtonLabel(viewModel.codeKind, strings);
  const continueAction = viewModel.codeKind == null ? validateCode : useCode;
  const shouldGoBack = viewModel.codeKind != null || !viewModel.isInitialConfig;

  const controlClassName = css({
    minWidth: !isExtraSmallScreen ? 350 : undefined
  });

  return (
    <Stack
      sx={{ ...sx, overflowY: 'auto', overflowX: 'hidden' }}
      className={className}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      p={{ xs: 2, sm: 3 }}
      spacing={2}
    >
      <Stack alignItems="center">
        <Typography variant="h5" textAlign="center">
          {title}
        </Typography>

        {viewModel.codeKind != null && (
          <Typography variant="h6" textAlign="center">
            {viewModel.code}
          </Typography>
        )}

        <Typography variant="body1" textAlign="center" mt={1}>
          {subtitle}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        {viewModel.codeKind == null && (
          <TextField
            value={viewModel.code}
            onChange={(e) => (viewModel.code = e.currentTarget.value)}
            variant="filled"
            label={strings.codePlaceholder}
            onSubmit={continueAction}
            className={controlClassName}
          />
        )}

        {viewModel.errorMessage.length > 0 && (
          <Typography color="error" textAlign="center" variant="subtitle2">
            {viewModel.errorMessage}
          </Typography>
        )}
      </Stack>

      <Button
        className={controlClassName}
        variant="contained"
        onClick={continueAction}
        disabled={viewModel.code.length < 1}
      >
        {continueButtonLabel}
      </Button>

      <Box
        sx={{
          height: '1px',
          backgroundColor: theme.studyo.onboarding.separatorLineColor,
          width: !isExtraSmallScreen ? 300 : undefined
        }}
      />

      <Button
        className={controlClassName}
        variant="contained-grey"
        onClick={shouldGoBack ? goBack : logout}
        color={shouldGoBack ? 'primary' : 'error'}
      >
        {shouldGoBack ? strings.back : strings.logout}
      </Button>
      <Typography variant="caption">
        ({strings.loggedInAs}&nbsp;{viewModel.email})
      </Typography>
    </Stack>
  );
});

function getTitle(codeKind: OnboardingCodeKind | undefined, strings: UseCodeStrings) {
  switch (codeKind) {
    case undefined:
      return strings.enterCodeTitle;
    case 'managed-student':
      return strings.useStudentCodeTitle;
    case 'parent-invitation':
      return strings.useParentCodeTitle;
  }
}

function getSubtitle(codeKind: OnboardingCodeKind | undefined, strings: UseCodeStrings) {
  switch (codeKind) {
    case undefined:
      return strings.enterCodeSubtitle;
    case 'managed-student':
      return strings.useStudentCodeSubtitle;
    case 'parent-invitation':
      return strings.useParentCodeSubtitle;
  }
}

function getContinueButtonLabel(codeKind: OnboardingCodeKind | undefined, strings: UseCodeStrings) {
  switch (codeKind) {
    case undefined:
      return strings.validate;
    case 'managed-student':
      return strings.continueAsStudent;
    case 'parent-invitation':
      return strings.continueAsParent;
  }
}
