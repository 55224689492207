import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PeriodsListItemViewModel } from '../../../viewmodels';
import { ContentNoteBox, DisplayableContentBox } from '../../contents';

export interface PeriodsListItemProps {
  sx?: SxProps;
  className?: string;
  height: number;
  viewModel: PeriodsListItemViewModel;
}

export const PeriodsListItem = observer((props: PeriodsListItemProps) => {
  const { height, sx, className, viewModel } = props;
  const { localizationService } = useStudyoServices();
  const theme = useTheme();
  const colors = theme.studyo.periods;
  const backgroundColor =
    viewModel.color != null
      ? SectionColors.get(viewModel.color)
      : viewModel.isSkipped
        ? colors.skippedPeriodColor
        : colors.freePeriodColor;

  const textColor = viewModel.color != null ? colors.periodWithSectionTextColor : colors.freePeriodTextColor;

  const dayInfoText = viewModel.day.formattedString(
    localizationService.localizedStrings.models.dateFormats.shortUnabridged
  );
  const periodInfoText = `${dayInfoText}, ${viewModel.startTime.asString} - ${viewModel.endTime.asString}`;

  return (
    <Stack sx={{ ...sx, height }} className={className} overflow="hidden">
      <Stack direction="row" sx={{ backgroundColor, color: textColor }} py={0.5} px={1} alignItems="center">
        <Stack direction="row" flexBasis={0} flexGrow={1}>
          <Typography flex={1} color="inherit" variant="subtitle2">
            {periodInfoText}
          </Typography>
          <Typography color="inherit" variant="body2">
            {viewModel.occurrenceTitle}
          </Typography>
        </Stack>

        <Stack direction="row" flexBasis={0} flexGrow={1} justifyContent="flex-end">
          <Typography color="inherit" variant="body2">
            {localizationService.localizedStrings.studyo.agenda.periods.occurrencesLeft(viewModel.occurrencesLeft)}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" flex={1} overflow="hidden">
        <DisplayableContentBox
          sx={{ p: 0.5, flexBasis: 0, flexGrow: 1, flexShrink: 0 }}
          viewModel={viewModel.tasksDisplayableContentViewModel}
          iconSize={26}
          itemSpacing={4}
          minItemHeight={50}
          minItemWidth={60}
          maxItemWidth={100}
          representationKind="icon-title"
        />

        <ContentNoteBox
          sx={{
            backgroundColor: theme.studyo.contents.noteTextInputBackgroundColor,
            flexBasis: 0,
            flexGrow: 3,
            flexShrink: 1
          }}
          viewModel={viewModel.notesDisplayableContentViewModel}
        />
      </Stack>
    </Stack>
  );
});
