import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { computed, makeObservable, observable } from 'mobx';

export interface LinkedTasksDeleteOccurrenceViewModel {
  readonly content: ContentDefinitionModel;
  readonly date: Day;
  readonly periodTag: string;
  isSelected: boolean;
}

export class AppLinkedTasksDeleteOccurrenceViewModel implements LinkedTasksDeleteOccurrenceViewModel {
  @observable private _isSelected: boolean;

  constructor(
    public readonly content: ContentDefinitionModel,
    isSelected: boolean
  ) {
    makeObservable(this);
    this._isSelected = isSelected;
  }

  @computed
  get date() {
    return this.content.dueDay;
  }

  @computed
  get periodTag() {
    return this.content.duePeriodTag;
  }

  @computed
  get isSelected() {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }
}
