import { EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigationService, StudyoSettingsStore } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { TimelineListItemViewModelDelegate } from './TimelineListItemViewModel';
import {
  AppTimelineListSectionCancelledViewModel,
  TimelineListSectionCancelledViewModel
} from './TimelineListSectionCancelledViewModel';
import {
  AppTimelineListSectionCompletedViewModel,
  TimelineListSectionCompletedViewModel
} from './TimelineListSectionCompletedViewModel';
import {
  AppTimelineListSectionTodayViewModel,
  TimelineListSectionTodayViewModel
} from './TimelineListSectionTodayViewModel';
import {
  AppTimelineListSectionTodoViewModel,
  TimelineListSectionTodoViewModel
} from './TimelineListSectionTodoViewModel';

export interface TimelineSearchFilter {
  searchFilter: string;
}

export interface TimelineListViewModel extends TimelineSearchFilter {
  readonly cancelled: TimelineListSectionCancelledViewModel;
  readonly completed: TimelineListSectionCompletedViewModel;
  readonly today: TimelineListSectionTodayViewModel;
  readonly todo: TimelineListSectionTodoViewModel;
  readonly isConfirmingMovePlannedDates: boolean;

  hideMovePlannedDates(): void;
  doMovePlannedDates(): Promise<void>;
}

export class AppTimelineListViewModel implements TimelineListViewModel {
  @observable private _searchFilter = '';
  @observable private _isConfirmingMovePlannedDates = false;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _data: AccountData,
    private readonly _listItemDelegate: TimelineListItemViewModelDelegate
  ) {
    makeObservable(this);
  }

  @computed
  get searchFilter(): string {
    return this._searchFilter;
  }

  set searchFilter(value: string) {
    this._searchFilter = value;
  }

  @computed
  get isConfirmingMovePlannedDates() {
    return this._isConfirmingMovePlannedDates;
  }

  @computed
  get cancelled() {
    return new AppTimelineListSectionCancelledViewModel(
      this._listItemDelegate,
      this,
      this._navigationService,
      this._localizationService,
      this._analyticsService,
      this._data,
      this._settingsStore,
      action(() => (this._isConfirmingMovePlannedDates = true))
    );
  }

  @computed
  get completed() {
    return new AppTimelineListSectionCompletedViewModel(
      this._listItemDelegate,
      this,
      this._navigationService,
      this._localizationService,
      this._analyticsService,
      this._data,
      this._settingsStore,
      action(() => (this._isConfirmingMovePlannedDates = true))
    );
  }

  @computed
  get today() {
    return new AppTimelineListSectionTodayViewModel(
      this._listItemDelegate,
      this,
      this._navigationService,
      this._localizationService,
      this._analyticsService,
      this._data,
      this._settingsStore,
      action(() => (this._isConfirmingMovePlannedDates = true))
    );
  }

  @computed
  get todo() {
    return new AppTimelineListSectionTodoViewModel(
      this._listItemDelegate,
      this,
      this._navigationService,
      this._localizationService,
      this._analyticsService,
      this._data,
      this._settingsStore,
      action(() => (this._isConfirmingMovePlannedDates = true))
    );
  }

  @action
  hideMovePlannedDates(): void {
    this._isConfirmingMovePlannedDates = false;
  }

  @action
  async doMovePlannedDates(): Promise<void> {
    this.hideMovePlannedDates();

    const today = Day.fromDate(new Date())!;
    const affectedTasks = this._data.contents
      .filter(
        (content) =>
          content.kind === 'task' &&
          content.state == 'active' &&
          !content.isMaster &&
          content.plannedDay.isBefore(today) &&
          content.dueDay.isSameOrAfter(today)
      )
      .map((c) => new EditableContentDefinition(c));

    affectedTasks.forEach((c) => (c.plannedDay = today));

    await this._data.createOrUpdateContents(affectedTasks);
  }
}
