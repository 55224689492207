import { AccountUtils } from '@shared/components/utils';
import { EditableContentAttachment, EditableContentDefinition } from '@shared/models/content';
import { Color, Day, Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../services';

export interface BaseAgendaHeaderViewModel {
  readonly profileDisplayName: string;
  readonly schoolName: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly isImpersonating: boolean;
  readonly profileColor: Color;
  readonly isSyncing: boolean;
  readonly canAddTask: boolean;
  readonly isDemoSchool: boolean;
  readonly isPreventingChanges: boolean;

  createNewTask: () => Promise<void>;
  displaySettings: () => Promise<void | DialogCancelled>;
}

export class AppBaseAgendaHeaderViewModel implements BaseAgendaHeaderViewModel {
  constructor(
    protected readonly _localizationService: LocalizationService,
    protected readonly _navigationService: NavigationService,
    protected readonly _attachmentManager: AttachmentManager,
    protected readonly _data: AccountData,
    protected readonly _preferences: StudyoAccountSettings,
    protected readonly _uiSettingsStore: UISettingsStore
  ) {
    makeObservable(this);
  }

  @computed
  get profileDisplayName(): string {
    return AccountUtils.getDisplayFirstLastName(this._data.account, '', !this.isImpersonating);
  }

  @computed
  get schoolName(): string {
    return this._data.config.schoolName;
  }

  @computed
  get firstName() {
    return this.isImpersonating
      ? this._data.account.firstName
      : this._data.account.profile.privateFirstName || this._data.account.firstName;
  }

  @computed
  get lastName() {
    return this.isImpersonating
      ? this._data.account.lastName
      : this._data.account.profile.privateLastName || this._data.account.lastName;
  }

  @computed
  get isImpersonating() {
    return this._data.isImpersonating;
  }

  @computed
  get profileColor() {
    return this._data.account.profile.avatarColor;
  }

  @computed
  get isSyncing(): boolean {
    return this._data.isRefreshing;
  }

  @computed
  get canAddTask(): boolean {
    return !this._data.isReadOnly;
  }

  @computed
  get isDemoSchool() {
    return this._data.config.isDemo;
  }

  @computed
  get isPreventingChanges() {
    return this._data.isPreventingChanges;
  }

  async createNewTask() {
    try {
      const editableContent = this.createContentInCurrentOrNextPeriod();
      const attachment = await this._attachmentManager.takePicture();

      if (attachment != null) {
        editableContent.addAttachment(new EditableContentAttachment(attachment, true));
        await this._data.createOrUpdateContent(editableContent);
      }
    } catch (e) {
      console.error('An error occurred while creating a task from a picture:', e);
    }
  }

  async displaySettings() {
    return this._navigationService.navigateToProfileMenu();
  }

  private createContentInCurrentOrNextPeriod(): EditableContentDefinition {
    const today = dateService.today;
    const firstSchoolDay = this._data.schoolDays[0].day;
    const lastSchoolDay = this._data.schoolDays[this._data.schoolDays.length - 1].day;

    let day: Day = today;
    if (today.isBefore(firstSchoolDay)) {
      day = firstSchoolDay;
    } else if (today.isAfter(lastSchoolDay)) {
      day = lastSchoolDay;
    }

    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const now = Time.create({ hour, minute });

    const schoolDay = this._data.schoolDaysByDay.get(day.asString);
    const periods = schoolDay?.sortedPeriods;
    const period = periods?.find((p) => {
      if (this._data.periodPrioritiesStore.getPeriodIsHidden(p, day)) {
        return false;
      }

      return p.endTime.isSameOrAfter(now);
    });

    const occurrence =
      period != null ? this._data.periodPrioritiesStore.getOccurrenceForPeriod(period, day) : undefined;

    return EditableContentDefinition.createNewTask(
      this._data.configId,
      this._data.accountId,
      day,
      day,
      period?.tag,
      occurrence?.sectionId
    );
  }
}
