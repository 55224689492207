import { LocalizationService } from '@shared/resources/services';
import { DataLoader } from '@shared/services';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AccountService, NavigationService, SubscriptionsManager } from '../services';

export type RejectionReason = 'parent-access-denied' | 'access-denied' | 'error';

export interface PresenterRejectedViewModel {
  title: string;
  message: string | undefined;
  reason: RejectionReason;

  isProcessing: boolean;
  error: string | undefined;

  buyAccess(): Promise<void>;
  displaySupport(): void;
  reload(): Promise<void>;
  viewProfiles(): Promise<void>;
  logout(): Promise<void>;
}

export class AppPresenterRejectedViewModel implements PresenterRejectedViewModel {
  @observable private _isProcessing = false;
  @observable private _error: string | undefined;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _subscriptionsManager: SubscriptionsManager,
    private readonly _dataLoaders: DataLoader[],
    private readonly _errors: Error[],
    private readonly _defaultErrorMessage?: string
  ) {
    makeObservable(this);
  }

  @computed
  get title(): string {
    const strings = this._localizationService.localizedStrings.studyo.presenterErrorStrings;

    switch (this.reason) {
      case 'parent-access-denied':
        return strings.parentAccessDeniedTitle;
      default:
        return strings.defaultTitle;
    }
  }

  @computed
  get message(): string | undefined {
    const strings = this._localizationService.localizedStrings.studyo.presenterErrorStrings;

    switch (this.reason) {
      case 'parent-access-denied':
        return strings.parentAccessDeniedMessage;
      default:
        return this._defaultErrorMessage;
    }
  }

  @computed
  get reason(): RejectionReason {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    if (this._errors.findIndex((e) => (e as any).code === 7) !== -1) {
      const currentAccount = this._accountService.currentAccount;

      if (
        currentAccount !== 'super-admin' &&
        currentAccount?.configurationSummary?.disabledFeatures?.includes('parent-access')
      ) {
        return 'parent-access-denied';
      }

      return 'access-denied';
    }

    return 'error';
  }

  @computed
  get isProcessing(): boolean {
    return this._isProcessing;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @action
  async buyAccess(): Promise<void> {
    const account = this._accountService.currentAccount;

    if (account != null && account != 'super-admin') {
      this._isProcessing = true;
      this._error = undefined;

      try {
        const url = await this._subscriptionsManager.getCheckoutUrl(account.configId, account.id, 'parent-access');
        await this._navigationService.navigateToCheckout(url);
      } catch (error) {
        runInAction(() => (this._error = (error as Error).message));
      } finally {
        runInAction(() => (this._isProcessing = false));
      }
    }
  }

  displaySupport() {
    this._navigationService.showIntercomMessenger();
  }

  async reload() {
    await Promise.all(this._dataLoaders.map((dt) => dt.refresh()));
  }

  async viewProfiles() {
    await this._navigationService.navigateToOtherProfilesListModal();
  }

  async logout() {
    return this._accountService.logout();
  }
}
