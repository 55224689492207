import { Box, SxProps, Typography } from '@mui/material';
import { Role } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface ProfileMenuNewYearMessageRowProps {
  sx?: SxProps;
  className?: string;
  role: Role;
}

export const ProfileMenuNewYearMessageRow = observer((props: ProfileMenuNewYearMessageRowProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, role } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;

  const message = () => {
    switch (role) {
      case 'parent':
        return strings.parentNewSchoolYearMessage;
      case 'student':
        return strings.studentNewSchoolYearMessage;
      case 'teacher':
        return strings.teacherNewSchoolYearMessage;
      default:
        return '';
    }
  };

  return (
    <Box sx={sx} className={className} p={2}>
      <Typography textAlign="center" variant="body2">
        {message()}
      </Typography>
    </Box>
  );
});
