import { EquatableSet } from '@shared/models/Equatable';
import { CourseOccurrence, SchoolDayPeriod } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Storage } from '../../../Storage';
import {
  AccountData,
  OccurrencePeriodPriorityObject,
  OverlappingPeriodPriorityObject,
  PeriodPriorityStore,
  occurrencePeriodPriorityObjectsAreEqual,
  overlappingPeriodPriorityObjectsAreEqual
} from '../../interfaces';
import {
  AppOccurrencePeriodPriorityObject,
  OccurrencePeriodPriorityObjectKey
} from './AppOccurrencePeriodPriorityObject';
import {
  AppOverlappingPeriodPriorityObject,
  OverlappingPeriodPriorityObjectKey
} from './AppOverlappingPeriodPriorityObject';

export interface PeriodPriorities {
  occurrencePriorities: OccurrencePeriodPriorityObject[];
  overlappingPriorities: OverlappingPeriodPriorityObject[];
}

export class AppPeriodPriorityStore implements PeriodPriorityStore {
  private _periodWithConflictsPerSchoolDay = new Map<string, SchoolDayPeriod[]>();
  @observable private _priorities: PeriodPriorities | undefined;

  constructor(
    private readonly _accountData: AccountData,
    private readonly _localStorage: Storage
  ) {
    makeObservable(this);
  }

  @action
  async updatePriorities() {
    this.setPeriodsWithConflict();
    const existingPriorities = await this.getExistingPriorities();
    runInAction(() => (this._priorities = this.createPeriodPrioritiesFromExisting(existingPriorities)));
    await this.saveCurrentPriorities();
  }

  getPeriodIsOverlapping(period: SchoolDayPeriod, day: Day): boolean {
    if (this._accountData.getDisplayedOccurrencesForPeriod(period).length > 1) {
      // Period is considered overlapping if it has more than one occurrence to display.
      return true;
    }

    if (period.conflictingPeriodIds.length > 0) {
      const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
      const overlappingPeriods = schoolDay.periods.filter((p) => period.conflictingPeriodIds.indexOf(p.id) >= 0);
      const hasOverlappingPeriodWithSection =
        overlappingPeriods.findIndex((p) => this.getOccurrenceForPeriod(p, day) != null) >= 0;
      const overlappingHiddenPeriods = overlappingPeriods.filter((p) => this.getPeriodIsHidden(p, day));

      // If period has conflicting period, we check these scenarios:
      //
      // 1. If period is free, and all its overlapping periods are hidden,
      //    we don't consider the period as overlapping.
      // 2. If the period has course occurrence and is overlapping with free periods only,
      //    we do not consider it as overlapping.
      // 3. In other cases, the period is overlapping.
      return (
        hasOverlappingPeriodWithSection ||
        (this.getOccurrenceForPeriod(period, day) == null &&
          overlappingHiddenPeriods.length !== period.conflictingPeriodIds.length)
      );
    }

    return false;
  }

  getPeriodIsHidden(period: SchoolDayPeriod, day: Day): boolean {
    return this.getPeriodIsHiddenRecursive(period, day, []);
  }

  getNumberOfConflictsForPeriod(period: SchoolDayPeriod, day: Day) {
    let count = 0;
    count += _.max([this._accountData.getDisplayedOccurrencesForPeriod(period).length - 1, 0])!;

    if (period.conflictingPeriodIds.length > 0) {
      const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
      const overlappingPeriods = schoolDay.periods.filter((p) => period.conflictingPeriodIds.indexOf(p.id) >= 0);
      const overlappingPeriodWithSection = overlappingPeriods.filter(
        (p) => this.getOccurrenceForPeriod(p, day) != null
      );
      count += _.max([overlappingPeriodWithSection.length, 0])!;
    }

    return count;
  }

  getOccurrenceForPeriod(period: SchoolDayPeriod, day: Day): CourseOccurrence | undefined {
    if (period.courseOccurrences.length === 0) {
      return undefined;
    }

    const occurrences = this._accountData.getDisplayedOccurrencesForPeriod(period);

    if (occurrences.length === 1) {
      return occurrences[0];
    }

    const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
    const cycleDay = schoolDay.cycleDay;

    if (this._priorities != null) {
      // Find priority for this period.
      const priority = this._priorities.occurrencePriorities.find(
        (p) => p.cycleDay === cycleDay && p.periodTag === period.tag
      );

      if (priority != null) {
        // If a course occurrences matches the priority sectionId, we return it.
        const matchingOccurrence = occurrences.find((co) => co.sectionId === priority.sectionId);
        if (matchingOccurrence != null) {
          return matchingOccurrence;
        }
      }
    }

    // If no priority set, return first non-skipped, otherwise first.
    return _.find(occurrences, (o) => !o.skipped) ?? occurrences[0];
  }

  getPeriodHasOverlappingPriority(period: SchoolDayPeriod, day: Day): boolean {
    if (period.conflictingPeriodIds.length === 0) {
      // Period is not conflicting with another period.
      return true;
    }

    const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
    const cycleDay = schoolDay.cycleDay;

    if (this._priorities != null) {
      const cycleDayPriorities = this._priorities.overlappingPriorities.filter((p) => p.cycleDay === cycleDay);
      // Create a temporary priority that represent the period.
      const priority = new AppOverlappingPeriodPriorityObject(
        cycleDay,
        period.tag,
        period.startTime,
        period.endTime,
        period.scheduleTag,
        // It's really a "skipped or empty"
        period.courseOccurrences.find((co) => !co.skipped) == null
      );
      // If we have a priority matching the period, it means it has priority.
      return cycleDayPriorities.findIndex((p) => p.equals(priority)) >= 0;
    }

    // Periods with only skipped occurrences don't have priority.
    return period.courseOccurrences.find((o) => !o.skipped) != null;
  }

  @action
  setPriorityToOccurrenceInPeriod(occurrence: CourseOccurrence, period: SchoolDayPeriod, day: Day) {
    const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
    const periodCycleDay = schoolDay.cycleDay;

    if (this._priorities != null) {
      const occurrencePriorities = [...this._priorities.occurrencePriorities];

      // Find current priority for period.
      const existingPriorityIndex = occurrencePriorities.findIndex(
        (p) => p.cycleDay === periodCycleDay && p.periodTag === period.tag
      );

      if (existingPriorityIndex >= 0 && occurrence.sectionId.length > 0) {
        // Replacing the existing priority by a new one pointing to the occurrence we want to have priority.
        const newPriority = new AppOccurrencePeriodPriorityObject(
          periodCycleDay,
          period.tag,
          occurrence.sectionId,
          occurrence.skipped
        );
        occurrencePriorities.splice(existingPriorityIndex, 1, newPriority);
        this._priorities = {
          occurrencePriorities,
          overlappingPriorities: this._priorities.overlappingPriorities
        };
      }

      void this.saveCurrentPriorities();
    }
  }

  @action
  setPriorityToPeriod(period: SchoolDayPeriod, day: Day) {
    const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
    const periodCycleDay = schoolDay.cycleDay;

    if (this._priorities != null) {
      // Create priority for period
      const newPriority = new AppOverlappingPeriodPriorityObject(
        periodCycleDay,
        period.tag,
        period.startTime,
        period.endTime,
        period.scheduleTag,
        // It's really a "skipped or empty"
        period.courseOccurrences.find((co) => !co.skipped) == null
      );

      const overlappingPriorities = [...this._priorities.overlappingPriorities];
      const existingOverlappingPeriodPriorities = overlappingPriorities.filter(
        (p) => p.cycleDay === periodCycleDay && p.overlapsWithOther(newPriority)
      );

      // Remove existing priorities that overlap with the period.
      existingOverlappingPeriodPriorities.forEach((p) => {
        const priorityIndex = overlappingPriorities.findIndex((priority) => p.equals(priority));

        if (priorityIndex >= 0) {
          overlappingPriorities.splice(priorityIndex, 1);
        }
      });

      overlappingPriorities.push(newPriority);
      this._priorities = {
        overlappingPriorities,
        occurrencePriorities: this._priorities.occurrencePriorities
      };
    }

    void this.saveCurrentPriorities();
  }

  togglePriorityForPeriod(period: SchoolDayPeriod, day: Day) {
    if (this.getNumberOfConflictsForPeriod(period, day) > 1) {
      console.warn("Can't toggle priority for period which has more than one conflicts.");
      return;
    }

    const occurrences = this._accountData.getDisplayedOccurrencesForPeriod(period);

    if (occurrences.length > 1) {
      // If period has two course occurrences, we change which one is displayed.
      const displayedOccurrence = this.getOccurrenceForPeriod(period, day)!;
      const newOccurrence = occurrences.find((co) => co.sectionId !== displayedOccurrence.sectionId);
      if (newOccurrence != null) {
        this.setPriorityToOccurrenceInPeriod(newOccurrence, period, day);
      }
    } else {
      // If period has an overlapping conflict, we change which one has the priority.
      const periodHasPriority = this.getPeriodHasOverlappingPriority(period, day);

      if (!periodHasPriority) {
        // If period doesn't have priority, we set it.
        this.setPriorityToPeriod(period, day);
      } else {
        const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
        const conflictingPeriodId = _.first(period.conflictingPeriodIds);

        // Find conflicting period to set the priority to it.
        if (conflictingPeriodId != null) {
          const overlappingPeriod = schoolDay.periods.find((p) => p.id === conflictingPeriodId);
          if (overlappingPeriod != null) {
            this.setPriorityToPeriod(overlappingPeriod, day);
          }
        }
      }
    }
  }

  // This recursive verion of getPeriodIsHidden allows to skip free periods from the check
  // to avoid an infinite loop.
  private getPeriodIsHiddenRecursive(period: SchoolDayPeriod, day: Day, ignoredPeriodIds: string[]): boolean {
    if (period.conflictingPeriodIds.length < 1) {
      // Don't hide period not conflicting with others.
      return false;
    }

    const occurrence = this.getOccurrenceForPeriod(period, day);
    if (occurrence != null) {
      // If period has a course occurrence, don't hide it.
      return false;
    }

    const schoolDay = this._accountData.schoolDaysByDay.get(day.asString)!;
    const overlappingPeriods = schoolDay.periods.filter((p) => period.conflictingPeriodIds.indexOf(p.id) >= 0);

    // Hide free period if overlapping with at least one period with a course occurrence.
    if (overlappingPeriods.findIndex((p) => this.getOccurrenceForPeriod(p, day) != null) >= 0) {
      return true;
    }

    // Free periods can be in conflict from different bell times, or be deliberate conflicts from the same.
    // For example, this is useful for alternate lunch time scenarios. We always keep the first occurring
    // period (by start time), then longest (reverse end time). But watch out! Some conflicts should already
    // be ignored because they are themselves hidden by section occurrences or are hidden by another free
    // period that is not in conflict with "period".
    const nextIgnoredPeriodIds = ignoredPeriodIds.concat(period.id);
    const sortedPeriods = _.orderBy(
      overlappingPeriods
        .filter(
          (p) => !ignoredPeriodIds.includes(p.id) && !this.getPeriodIsHiddenRecursive(p, day, nextIgnoredPeriodIds)
        )
        .concat(period),
      // We include the schedule tag and period tag, so we always pick one
      // period if many have the same times. This ensures the same one is always
      // before the other no matter which one we're querying for.
      [(c) => c.startTime.asString, (c) => c.endTime.asString, (c) => c.scheduleTag, (c) => c.tag],
      ['asc', 'desc']
    );

    return sortedPeriods[0].id !== period.id;
  }

  // Creates period priorities for the config contained in the AccountData.
  private createPeriodPrioritiesFromExisting(existingPriorities: PeriodPriorities | undefined): PeriodPriorities {
    const conflicts = this.conflictsByCycleDayForSchoolDaysInConfig();

    const occurrencePriorities = this.mergeOccurrencesPriorities(
      conflicts.occurrencePriorities,
      existingPriorities != null ? existingPriorities.occurrencePriorities : undefined
    );

    const overlappingPriorities = this.mergeOverlappingPriorities(
      conflicts.overlappingPriorities,
      existingPriorities != null ? existingPriorities.overlappingPriorities : undefined
    );

    return { occurrencePriorities, overlappingPriorities };
  }

  /**
   * Creates overlapping and occurrence priorities grouped by cycle day.
   *
   * @private
   * @returns {{
   *     overlappingPriorities: EquatableSet<OverlappingPeriodPriorityObject>[];
   *     occurrencePriorities: EquatableSet<OccurrencePeriodPriorityObject>[];
   *   }}
   * @memberof AppPeriodPriorityStore
   */
  private conflictsByCycleDayForSchoolDaysInConfig(): {
    overlappingPriorities: EquatableSet<OverlappingPeriodPriorityObject>[];
    occurrencePriorities: EquatableSet<OccurrencePeriodPriorityObject>[];
  } {
    const conflictsByCycleDay: EquatableSet<OverlappingPeriodPriorityObject>[] = [];
    const periodsWithMultipleOccurrencesByCycleDay: EquatableSet<OccurrencePeriodPriorityObject>[] = [];

    // Creating a set of periods with conflicts by cycle day
    for (let i = 0; i < this._accountData.config.daysPerCycle; i++) {
      conflictsByCycleDay.push(
        new EquatableSet<OverlappingPeriodPriorityObject>(overlappingPeriodPriorityObjectsAreEqual)
      );
      periodsWithMultipleOccurrencesByCycleDay.push(
        new EquatableSet<OccurrencePeriodPriorityObject>(occurrencePeriodPriorityObjectsAreEqual)
      );
    }

    for (const schoolDay of this._accountData.schoolDays) {
      const conflicts = this._periodWithConflictsPerSchoolDay.get(schoolDay.day.asString);
      if (schoolDay.cycleDay > 0 && conflicts != null) {
        const periodsWithMultipleOccurrences = schoolDay.periods.filter((p) => p.courseOccurrences.length > 1);

        const occurrencePriorities: OccurrencePeriodPriorityObject[] = [];

        // Creating a priority object per occurrence in period. The merge will later keep only one per period.
        // We need all of them to check if the existing priority still matches the current school days.
        periodsWithMultipleOccurrences.forEach((p) => {
          const priorities = p.courseOccurrences.map(
            (co) => new AppOccurrencePeriodPriorityObject(schoolDay.cycleDay, p.tag, co.sectionId, co.skipped)
          );

          occurrencePriorities.push(...priorities);
        });

        const cycleDayIndex = schoolDay.cycleDay - 1;

        let cycleDayOccurrenceConflicts = periodsWithMultipleOccurrencesByCycleDay[cycleDayIndex];
        cycleDayOccurrenceConflicts = cycleDayOccurrenceConflicts.addValues(occurrencePriorities);
        periodsWithMultipleOccurrencesByCycleDay[cycleDayIndex] = cycleDayOccurrenceConflicts;

        // Create a priority object for each period that overlaps with another one.
        // We need all of them to check if the existing priority still matches the current school days.
        const schoolDayConflicts = conflicts
          .filter((co) => co.conflictingPeriodIds.length > 0)
          .map(
            (p) =>
              new AppOverlappingPeriodPriorityObject(
                schoolDay.cycleDay,
                p.tag,
                p.startTime,
                p.endTime,
                p.scheduleTag,
                // It's really a "skipped or empty"
                p.courseOccurrences.find((co) => !co.skipped) == null
              )
          );

        let cycleDayOverlappingConflicts = conflictsByCycleDay[cycleDayIndex];
        cycleDayOverlappingConflicts = cycleDayOverlappingConflicts.addValues(schoolDayConflicts);
        conflictsByCycleDay[cycleDayIndex] = cycleDayOverlappingConflicts;
      }
    }

    return {
      occurrencePriorities: periodsWithMultipleOccurrencesByCycleDay,
      overlappingPriorities: conflictsByCycleDay
    };
  }

  /**
   * Store periods that have a conflict, either having multiple course occurrences or overlapping with another period,
   * in a dictionnary where the keys are the string representation of their school day's day.
   *
   * @private
   * @memberof AppPeriodPriorityStore
   */
  private setPeriodsWithConflict() {
    this._accountData.schoolDays.forEach((schoolDay) => {
      const periodsWithConflicts = schoolDay.periods.filter(
        (period) => period.courseOccurrences.length > 0 || period.conflictingPeriodIds.length > 0
      );

      this._periodWithConflictsPerSchoolDay.set(schoolDay.day.asString, periodsWithConflicts);
    });
  }

  /**
   * Take occurrence priority objects computed from the current schools days and compare them with the stored one.
   * It returns the priorities to apply in the agenda.
   *
   * @private
   * @param {EquatableSet<OccurrencePeriodPriorityObject>[]} newPriorities
   * @param {(OccurrencePeriodPriorityObject[] | undefined)} existingPriorities
   * @returns {OccurrencePeriodPriorityObject[]}
   * @memberof AppPeriodPriorityStore
   */
  private mergeOccurrencesPriorities(
    newPriorities: EquatableSet<OccurrencePeriodPriorityObject>[],
    existingPriorities: OccurrencePeriodPriorityObject[] | undefined
  ): OccurrencePeriodPriorityObject[] {
    const newPrioritiesArray = newPriorities.map((p) => p.toArray());
    const priorities = _.flatten(newPrioritiesArray);

    const result: OccurrencePeriodPriorityObject[] = [];
    const objectIndexesCovered: number[] = [];

    for (let i = 0; i < priorities.length; i++) {
      if (objectIndexesCovered.indexOf(i) === -1) {
        const priority = priorities[i];
        objectIndexesCovered.push(i);

        const associatedPriorities = [priority];

        // Find all priorities that haven't already been covered and that are for the same period.
        for (let subIndex = 0; subIndex < priorities.length; subIndex++) {
          if (objectIndexesCovered.indexOf(subIndex) === -1) {
            const subPriority = priorities[subIndex];

            if (priority.cycleDay === subPriority.cycleDay && priority.periodTag === subPriority.periodTag) {
              associatedPriorities.push(subPriority);
              objectIndexesCovered.push(subIndex);
            }
          }
        }

        // Skipped occurrences never get priority by default, only if user-induced.
        let resolvedPriority = _.first(
          _.sortBy(
            associatedPriorities.filter((p) => !p.isSkipped),
            [(p) => p.sectionId ?? '']
          )
        );

        if (existingPriorities != null) {
          // If we have existing priorities, we check in the new ones if we have a match.
          // If so, we use it instead of the default one.
          const existingPriority = existingPriorities.find(
            (p) => p.cycleDay === priority.cycleDay && p.periodTag === priority.periodTag
          );

          if (existingPriority != null) {
            const matchingPriority = associatedPriorities.find((p) => p.sectionId === existingPriority.sectionId);

            if (matchingPriority != null) {
              resolvedPriority = matchingPriority;
            }
          }
        }

        if (resolvedPriority != null) {
          result.push(resolvedPriority);
        }
      }
    }

    return result;
  }

  /**
   * Take overlapping priority objects computed from the current schools days and compare them with the stored one.
   * It returns the priorities to apply in the agenda.
   *
   * @private
   * @param {EquatableSet<OverlappingPeriodPriorityObject>[]} newPriorities
   * @param {(OverlappingPeriodPriorityObject[] | undefined)} existingPriorities
   * @returns {OverlappingPeriodPriorityObject[]}
   * @memberof AppPeriodPriorityStore
   */
  private mergeOverlappingPriorities(
    newPriorities: EquatableSet<OverlappingPeriodPriorityObject>[],
    existingPriorities: OverlappingPeriodPriorityObject[] | undefined
  ): OverlappingPeriodPriorityObject[] {
    const result: OverlappingPeriodPriorityObject[] = [];

    newPriorities.forEach((prioritiesByCycleDay) => {
      const priorities = prioritiesByCycleDay.toArray();
      const sortedPriorities = priorities.sort((p1, p2) => p1.startTime.compare(p2.startTime));
      const objectIndexesCovered: number[] = [];

      for (let i = 0; i < sortedPriorities.length; i++) {
        if (objectIndexesCovered.indexOf(i) === -1) {
          const priority = sortedPriorities[i];
          objectIndexesCovered.push(i);

          const associatedPriorities = [priority];

          // Find all priorities that haven't already been covered and that are overlapping with 'priority'.
          for (let subIndex = 0; subIndex < sortedPriorities.length; subIndex++) {
            if (objectIndexesCovered.indexOf(subIndex) === -1) {
              const subPriority = sortedPriorities[subIndex];

              if (subPriority.overlapsWithOther(priority)) {
                associatedPriorities.push(subPriority);
                objectIndexesCovered.push(subIndex);
              }
            }
          }

          // Skipped occurrences never get priority by default, only if user-induced.
          let resolvedPriority = associatedPriorities.find((p) => !p.isSkipped);

          if (existingPriorities != null) {
            // If we have existing priorities, we check in the new ones if we have a match.
            // If so, we use it instead of the default one.
            const existingPriority = existingPriorities.find(
              (p) => p.cycleDay === priority.cycleDay && p.overlapsWithOther(priority)
            );

            if (existingPriority != null) {
              const matchingPriority = associatedPriorities.find((p) => p.equals(existingPriority));

              if (matchingPriority != null) {
                resolvedPriority = matchingPriority;
              }
            }
          }

          if (resolvedPriority != null) {
            result.push(resolvedPriority);
          }
        }
      }
    });

    return result;
  }

  /**
   * Retrieve existing priorities from the local storage.
   *
   * @private
   * @returns {(Promise<PeriodPriorities | undefined>)}
   * @memberof AppPeriodPriorityStore
   */
  private async getExistingPriorities(): Promise<PeriodPriorities | undefined> {
    const occurrencePrioritiesByConfig = await this._localStorage.get<Record<string, Record<string, unknown>[]>>(
      OccurrencePeriodPriorityObjectKey
    );
    if (occurrencePrioritiesByConfig == null) {
      return undefined;
    }

    const occurrencePrioritiesJson = occurrencePrioritiesByConfig[this._accountData.configId];
    if (occurrencePrioritiesJson == null) {
      return undefined;
    }

    const occurrencePriorities = occurrencePrioritiesJson.map((value) =>
      AppOccurrencePeriodPriorityObject.fromJson(value)
    );

    const overlappingPrioritiesByConfig = await this._localStorage.get<Record<string, Record<string, unknown>[]>>(
      OverlappingPeriodPriorityObjectKey
    );
    if (overlappingPrioritiesByConfig == null) {
      return undefined;
    }

    const overlappingPrioritiesJson = overlappingPrioritiesByConfig[this._accountData.configId];
    if (overlappingPrioritiesJson == null) {
      return undefined;
    }

    const overlappingPriorities = overlappingPrioritiesJson.map((value) =>
      AppOverlappingPeriodPriorityObject.fromJson(value)
    );

    return { occurrencePriorities, overlappingPriorities };
  }

  /**
   * Saves the current priorities into the local storage.
   *
   * @private
   * @memberof AppPeriodPriorityStore
   */
  private async saveCurrentPriorities() {
    if (this._priorities != null) {
      const overlappingPriorities = this._priorities.overlappingPriorities.map((p) => p.asJson);
      const occurrencePriorities = this._priorities.occurrencePriorities.map((p) => p.asJson);

      await this.savePriorities(OverlappingPeriodPriorityObjectKey, overlappingPriorities);
      await this.savePriorities(OccurrencePeriodPriorityObjectKey, occurrencePriorities);
    }
  }

  /**
   * Saves objects to local storage using a key.
   *
   * @private
   * @param {string} key
   * @param {any[]} objects
   * @memberof AppPeriodPriorityStore
   */
  private async savePriorities(key: string, objects: Record<string, unknown>[]) {
    let storedValue = await this._localStorage.get<Record<string, Record<string, unknown>[]>>(key);
    if (storedValue == null) {
      storedValue = {};
    }

    storedValue[this._accountData.configId] = objects;

    await this._localStorage.set(key, storedValue);
  }
}
