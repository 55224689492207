import { Day } from '@shared/models/types';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';

export interface ProfileMenuDemoOptionsViewModel {
  readonly isDemoSchool: boolean;
  readonly canPreventChanges: boolean;
  isPreventingChanges: boolean;
  today: Day;
}

export class AppProfileMenuDemoOptionsViewModel implements ProfileMenuDemoOptionsViewModel {
  constructor(private readonly _accountData: AccountData) {
    makeObservable(this);
  }

  @computed
  get isDemoSchool() {
    return this._accountData.config.isDemo;
  }

  @computed
  get canPreventChanges() {
    return this._accountData.canPreventChanges;
  }

  @computed
  get isPreventingChanges() {
    return this._accountData.isPreventingChanges;
  }

  set isPreventingChanges(value: boolean) {
    this._accountData.isPreventingChanges = value;
  }

  @computed
  get today() {
    return dateService.today;
  }

  set today(value: Day) {
    dateService.mockToday(value);
  }
}
