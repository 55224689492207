import { AppThemeService } from '@shared/resources/services/ThemeService';
import { ThemeKind } from '@shared/resources/themes';
import { Storage } from '@shared/services';
import { computed, makeObservable } from 'mobx';
import { StudyoMuiDarkTheme, StudyoMuiTheme } from '../StudyoMuiTheme.ts';

export class StudyoThemeService extends AppThemeService {
  constructor(defaultKind: ThemeKind, storage: Storage) {
    super(defaultKind, storage);
    makeObservable(this);
  }
  @computed
  get availableKinds(): ThemeKind[] {
    return ['light', 'dark'];
  }

  protected getMaterialThemeForKind(kind: ThemeKind) {
    return kind === 'dark' ? StudyoMuiDarkTheme : StudyoMuiTheme;
  }
}
