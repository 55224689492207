import { ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _, { chain, last, max, min } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  NavigationService,
  StudyoAccountSettings,
  StudyoSettingsStore,
  TimelineCellSizeKind,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { LargeContentLineHeight, StandardContentLineHeight } from './Constants';
import {
  AppTimelineCollectionViewItemViewModel,
  TimelineCollectionViewItemViewModel
} from './TimelineCollectionViewItemViewModel';
import {
  AppTimelineCollectionViewSchoolDayHeaderViewModel,
  TimelineCollectionViewSchoolDayHeaderViewModel
} from './TimelineCollectionViewSchoolDayHeaderViewModel';
import {
  AppTimelineCollectionViewSectionHeaderViewModel,
  TimelineCollectionViewSectionHeaderViewModel
} from './TimelineCollectionViewSectionHeaderViewModel';

export interface AgendaTimelineCollectionViewViewModelData {
  rowIndex: number;
  startColumnIndex: number;
  endColumnIndex: number;
  sectionId: string;
  vm: TimelineCollectionViewItemViewModel;
}

export interface AgendaTimelineCollectionViewViewModel {
  cellSize: TimelineCellSizeKind;
  startDayIndex: number;
  maxRowIndex: number;
  contentItems: AgendaTimelineCollectionViewViewModelData[];
  sections: TimelineCollectionViewSectionHeaderViewModel[];

  schoolDayAtIndex: (index: number) => SchoolDay | undefined;
  schoolDayViewModelAtIndex: (index: number) => TimelineCollectionViewSchoolDayHeaderViewModel | undefined;
  setScrollViewReference: (scrollView: HTMLDivElement | null) => void;

  getCalculatedNumberOfDays: () => number;
  setCalculatedNumberOfDays: (numberOfDays: number) => void;
}

export class AppAgendaTimelineCollectionViewViewModel implements AgendaTimelineCollectionViewViewModel {
  private readonly _preferences: StudyoAccountSettings;
  private _scrollView: HTMLDivElement | null = null;
  private _numberOfDays = 20;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _data: AccountData
  ) {
    makeObservable(this);
    this._preferences = _settingsStore.getPreferences(_data.accountId);
  }

  @computed
  private get currentDayIndex() {
    const currentDay = this._uiSettingsStore.timelineCurrentDay;

    if (currentDay == null) {
      return 0;
    } else {
      const index = this.findIndexForDay(currentDay);

      if (index >= 0) {
        return index;
      } else {
        if (currentDay.isBefore(this.schoolDayAtIndex(0)!.day)) {
          return 0;
        } else {
          return this._data.schoolDays.length - 1;
        }
      }
    }
  }

  @computed
  private get startDay() {
    return this._data.schoolDays[this.currentDayIndex].day;
  }

  @computed
  private get sortedSectionIds() {
    // These ids are used to walk contents always in the same order. We must include sectionless contents atop.
    return [''].concat(_.orderBy(this._data.sections, ['title', 'sectionNumber'], ['asc', 'asc']).map((s) => s.id));
  }

  @computed
  private get contentsBySectionId() {
    return _.groupBy(
      this.contents.map((content) => ({
        content,
        displayTitle: ContentDefinitionUtils.getDisplayTitleForContent(
          content,
          this._localizationService.localizedStrings
        )
      })),
      (info) => info.content.sectionId
    );
  }

  @computed
  get cellSize(): TimelineCellSizeKind {
    return this._preferences.timelineCellSize;
  }

  @computed
  get contents() {
    const today = dateService.today;
    const filterManager = this._settingsStore.getPreferences(this._data.accountId).timelineContentFilters;

    const timelineContents = _.chain(this._data.visibleContents)
      .filter(
        (c) => c.kind === 'task' && (c.state === 'active' || (c.state === 'completed' && c.dueDay.isSameOrAfter(today)))
      )
      .value();

    // The "not completed tasks" filter is performed with other content-filtering options below, not above.
    return filterManager.applyFiltersToContents(timelineContents, this._localizationService);
  }

  @computed
  get startDayIndex() {
    return _.findIndex(this._data.schoolDays, (sd) => sd.day.isSame(this.startDay));
  }

  @computed
  get sections() {
    const elements = this.contentItems;

    return this.sortedSectionIds
      .filter((sectionId) => this.contentsBySectionId[sectionId] != null)
      .map((sectionId) => {
        const elementsForSection = elements.filter((e) => e.sectionId === sectionId);
        const rowIndexes = chain(elementsForSection)
          .map((element) => element.rowIndex)
          .uniq()
          .value();

        const maxRowIndex = max(rowIndexes)!;
        const minRowIndex = min(rowIndexes)!;

        return new AppTimelineCollectionViewSectionHeaderViewModel(
          this._preferences,
          this._data,
          maxRowIndex - minRowIndex + 1,
          sectionId
        );
      });
  }

  @computed
  get contentItems() {
    const endDay = this.startDay.addDays(this._numberOfDays);
    let rowIndex = 0;

    return _.chain(
      this.sortedSectionIds.map((sectionId) => ({ sectionId, contents: this.contentsBySectionId[sectionId] }))
    )
      .filter((contentsBySection) => contentsBySection.contents != null)
      .map((contentsBySection) => {
        const elements = contentsBySection.contents.map((element) => {
          const vm = new AppTimelineCollectionViewItemViewModel(
            this._navigationService,
            this._localizationService,
            this._analyticsService,
            this._preferences,
            this._data,
            element.content.id,
            this.startDay,
            endDay
          );
          const startColumnIndex = this.findIndexForDay(vm.leftmostDay);
          const endColumnIndex = this.findIndexForDay(element.content.dueDay) + 3;

          return {
            rowIndex,
            startColumnIndex,
            endColumnIndex,
            sectionId: contentsBySection.sectionId,
            vm
          };
        });

        rowIndex = this.optimizeVerticalSpaceForElements(elements, rowIndex);

        return elements;
      })
      .flatten()
      .value();
  }

  @computed
  get maxRowIndex() {
    return max(this.contentItems.map((item) => item.rowIndex)) ?? -1;
  }

  schoolDayAtIndex(index: number) {
    if (index < 0 || index >= this._data.schoolDays.length) {
      return undefined;
    } else {
      return this._data.schoolDays[index];
    }
  }

  schoolDayViewModelAtIndex(index: number) {
    if (index < 0 || index >= this._data.schoolDays.length) {
      return undefined;
    } else {
      return new AppTimelineCollectionViewSchoolDayHeaderViewModel(this._data, index);
    }
  }

  setScrollViewReference(scrollView: HTMLDivElement | null) {
    this._scrollView = scrollView;
  }

  getCalculatedNumberOfDays(): number {
    return this._numberOfDays;
  }

  setCalculatedNumberOfDays(numberOfDays: number) {
    this._numberOfDays = numberOfDays;
  }

  scrollToSection(sectionId: string) {
    if (this._scrollView != null) {
      const section = this._data.sectionsById.get(sectionId)!;

      if (section != null) {
        const lineHeight =
          this._preferences.timelineCellSize === 'large' ? LargeContentLineHeight : StandardContentLineHeight;
        const sectionIds = this.sortedSectionIds;
        let topValue = 0;

        for (const currentSectionId of sectionIds) {
          if (section.id === currentSectionId) {
            break;
          }

          const contents = this.contentsBySectionId[currentSectionId];

          if (contents != null) {
            topValue += contents.length * lineHeight;
          }
        }

        this._scrollView.scrollTo({ top: topValue });
      }
    }
  }

  private findIndexForDay(day: Day) {
    return _.findIndex(this._data.schoolDays, (sd) => sd.day.isSame(day));
  }

  private optimizeVerticalSpaceForElements(
    elements: AgendaTimelineCollectionViewViewModelData[],
    startingRowIndex: number
  ): number {
    let rowIndex = startingRowIndex;

    const sortedItems = _.orderBy(elements, (e) => e.endColumnIndex, 'asc');
    const rows: AgendaTimelineCollectionViewViewModelData[][] = [];

    sortedItems.forEach((item) => {
      let inserted = false;

      for (const row of rows) {
        if (item.startColumnIndex > last(row)!.endColumnIndex) {
          row.push(item);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        rows.push([item]);
      }
    });

    rows.forEach((row) => {
      row.forEach((item) => (item.rowIndex = rowIndex));
      rowIndex += 1;
    });

    return rowIndex;
  }
}
