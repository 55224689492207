import DeleteIcon from '@mui/icons-material/Delete';
import { Box, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { LinkedTasksDeleteView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface LinkedTasksDeleteScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  contentId: string;
}

export const LinkedTasksDeleteScreen = observer((props: LinkedTasksDeleteScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, contentId, onSuccess, onCancel } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createLinkedTasksDelete(contentId, onSuccess!, onCancel!),
    [contentId]
  );

  const strings = localizationService.localizedStrings.studyo.contents.linkedTasksDelete;

  const [displayDeleteConfirmationDialog, setDisplayDeleteConfirmationDialog] = useState(false);
  const [deletePromise, startDelete, resetDelete, retryDelete] = useSaveDialog(() => viewModel.deleteSelected());

  const selectAll = () => {
    viewModel.selectAll();
  };

  const cancel = () => {
    viewModel.cancel();
  };

  const deleteButtonPressed = () => {
    showDeleteConfirmationDialog();
  };

  const confirmDelete = () => {
    hideDeleteConfirmationDialog();
    startDelete();
  };

  const showDeleteConfirmationDialog = () => {
    setDisplayDeleteConfirmationDialog(true);
  };

  const hideDeleteConfirmationDialog = () => {
    setDisplayDeleteConfirmationDialog(false);
  };

  const saveDialogClose = (success: boolean) => {
    resetDelete();
    if (success) {
      viewModel.dismiss();
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancel}
    >
      <DialogHeader
        title={strings.title}
        onClose={cancel}
        rightButton={{
          icon: (size) => <DeleteIcon fontSize={size} color="inherit" />,
          action: deleteButtonPressed,
          color: 'error',
          onlyShowOnExtraSmallScreen: true
        }}
      />

      <LinkedTasksDeleteView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons>
        <DialogButton
          title={viewModel.allContentsAreSelected ? strings.deselectAll : strings.selectAll}
          onPress={selectAll}
          type="normal"
        />

        <Box flex={1} />

        <DialogButton title={strings.cancel} onPress={cancel} type="cancel" minimumScreenSize="sm" />
        <DialogButton
          title={strings.delete}
          onPress={deleteButtonPressed}
          type="destructive"
          variant="contained"
          minimumScreenSize="sm"
        />
      </DialogActionButtons>

      <ConfirmDialog
        open={displayDeleteConfirmationDialog}
        title={strings.deleteConfirmTitle}
        description={strings.deleteConfirmDescription}
        cancelTitle={strings.deleteConfirmCancel}
        onCancelPress={hideDeleteConfirmationDialog}
        confirmTitle={strings.deleteConfirm}
        onConfirmPress={confirmDelete}
        onClose={hideDeleteConfirmationDialog}
      />

      <SaveDialog
        promise={deletePromise}
        retryCall={retryDelete}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
