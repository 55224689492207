import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook.ts';

export interface StudyoLogoProps {
  style?: CSSProperties;
  className?: string;
}

export const StudyoLogo = observer(({ style, className }: StudyoLogoProps) => {
  const { imageService } = useStudyoServices();
  const theme = useTheme();
  const imageUrl =
    theme.palette.mode === 'dark'
      ? imageService.studyoImages.generic.logoTextOnlyDark
      : imageService.studyoImages.generic.logoTextOnlyLight;

  return <img src={imageUrl} alt="logo" style={style} className={className} />;
});
