import { SxProps, useTheme } from '@mui/material';
import { DataPresenter } from '@studyo/components';
import { DailyViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DailyView } from './DailyView.tsx';

export interface AgendaDailyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: DailyViewModel;
}

export const AgendaDailyView = observer((props: AgendaDailyViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();

  return (
    <DataPresenter
      sx={{ ...sx, backgroundColor: theme.studyo.agenda.dayAndWeekHeaderBackgroundColor }}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => <DailyView sx={{ width: '100%', height: '100%' }} viewModel={viewModel} />}
    />
  );
});
