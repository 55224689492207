import { ContentDefinition_Attachment as PBAttachment } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { v4 as uuidv4 } from 'uuid';
import { EditableModel, EditableValueProperty } from '../EditableModel';
import { AttachmentIcon, AttachmentKind } from '../types';
import { protobufFromAttachmentKind } from '../types/EnumConversion';
import { ContentAttachment, ContentAttachmentModel } from './ContentAttachment';

export class EditableContentAttachment extends EditableModel<PBAttachment> implements ContentAttachmentModel {
  private _kind: EditableValueProperty<AttachmentKind, PBAttachment>;
  private _title: EditableValueProperty<string, PBAttachment>;
  private _thumbUrl: EditableValueProperty<string, PBAttachment>;
  private _fileUrl: EditableValueProperty<string, PBAttachment>;
  private _fileName: EditableValueProperty<string, PBAttachment>;
  private _externalUrl: EditableValueProperty<string, PBAttachment>;

  static createNew() {
    const pb = new PBAttachment();
    pb.id = uuidv4();
    return new EditableContentAttachment(new ContentAttachment(pb), true);
  }

  constructor(
    private readonly _originalAttachment: ContentAttachmentModel,
    public readonly isNew = false
  ) {
    super(_originalAttachment.toProtobuf(), isNew);

    this.setFields([
      (this._kind = new EditableValueProperty(
        _originalAttachment.kind,
        (pb, value) => (pb.kind = protobufFromAttachmentKind(value))
      )),
      (this._title = new EditableValueProperty(_originalAttachment.title, (pb, value) => (pb.title = value))),
      (this._thumbUrl = new EditableValueProperty(_originalAttachment.thumbUrl, (pb, value) => (pb.thumbUrl = value))),
      (this._fileUrl = new EditableValueProperty(_originalAttachment.fileUrl, (pb, value) => (pb.fileUrl = value))),
      (this._fileName = new EditableValueProperty(_originalAttachment.fileName, (pb, value) => (pb.fileName = value))),
      (this._externalUrl = new EditableValueProperty(
        _originalAttachment.externalUrl,
        (pb, value) => (pb.externalUrl = value)
      ))
    ]);
  }

  //
  // Read-only properties
  //

  get id(): string {
    return this._originalAttachment.id;
  }

  get fileExtension(): string | undefined {
    return this._originalAttachment.fileExtension;
  }

  get localFilename(): string {
    return this._originalAttachment.localFilename;
  }

  get localThumbFilename(): string {
    return this._originalAttachment.localThumbFilename;
  }

  get attachmentIcon(): AttachmentIcon {
    switch (this.kind) {
      case 'photo':
        return 'photo-thumb';

      case 'url':
        return 'link';

      case 'document':
      case 'document-url':
        return this.attachmentIconForFileExtension;

      /* istanbul ignore next */
      default:
        return 'document';
    }
  }

  //
  // Editable properties
  //

  get kind(): AttachmentKind {
    return this._kind.value;
  }

  set kind(value: AttachmentKind) {
    this._kind.value = value;
  }

  get title(): string {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  get thumbUrl(): string {
    return this._thumbUrl.value;
  }

  set thumbUrl(value: string) {
    this._thumbUrl.value = value;
  }

  get fileUrl(): string {
    return this._fileUrl.value;
  }

  set fileUrl(value: string) {
    this._fileUrl.value = value;
  }

  get fileName(): string {
    return this._fileName.value;
  }

  set fileName(value: string) {
    this._fileName.value = value;
  }

  get externalUrl(): string {
    return this._externalUrl.value;
  }

  set externalUrl(value: string) {
    this._externalUrl.value = value;
  }

  protected get attachmentIconForFileExtension(): AttachmentIcon {
    let icon: AttachmentIcon = 'document';

    switch (this.fileExtension) {
      case 'zip':
        icon = 'zip';
        break;
      case 'doc':
      case 'docx':
        icon = 'word';
        break;
      case 'xls':
      case 'xlsx':
        icon = 'excel';
        break;
      case 'ppt':
      case 'pptx':
        icon = 'powerpoint';
        break;
      case 'pages':
      case 'pages.zip':
        icon = 'pages';
        break;
      case 'numbers':
      case 'numbers.zip':
        icon = 'numbers';
        break;
      case 'key':
      case 'key.zip':
        icon = 'keynote';
        break;
      case 'txt':
        icon = 'text';
        break;
      case 'pdf':
        icon = 'pdf';
        break;
      case 'tif':
      case 'tiff':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
        icon = 'image';
        break;
      case 'avi':
      case 'flv':
      case 'wmv':
      case 'mov':
      case 'mp4':
      case 'MPEG-4':
        icon = 'video';
        break;
      case 'mp3':
      case 'wma':
      case 'aac':
        icon = 'music';
        break;
      default:
        break;
    }

    return icon;
  }
}
