import { IndexPath, SectionUtils } from '@shared/components/utils';
import { CourseOccurrenceInfo } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { Color, Day, Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ContentPasteboardStore, NavigationService, StudyoAccountSettings } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import {
  AppContentNoteBoxViewModel,
  AppDisplayableContentBoxViewModel,
  AppDisplayableContentViewModel,
  ContentNoteBoxViewModel,
  DisplayableContentBoxViewModel
} from '../../contents';

export interface PeriodsListItemViewModel {
  readonly day: Day;
  readonly startTime: Time;
  readonly endTime: Time;

  readonly color?: Color;
  readonly isSkipped: boolean;

  readonly occurrenceTitle: string;
  readonly occurrencesLeft: number;

  readonly tasksDisplayableContentViewModel: DisplayableContentBoxViewModel;
  readonly notesDisplayableContentViewModel: ContentNoteBoxViewModel;
}

export class AppPeriodsListItemViewModel implements PeriodsListItemViewModel {
  constructor(
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _data: AccountData,
    private readonly _section: SectionModel,
    private readonly _courseOccurrence: CourseOccurrenceInfo,
    private readonly _indexPath: IndexPath,
    private readonly noteDidChangeAtIndexPath: (indexPath: IndexPath) => void
  ) {
    makeObservable(this);
  }

  @computed
  get color(): Color | undefined {
    return !this._courseOccurrence.occurrence.skipped
      ? SectionUtils.getSectionColor(this._section, this._data.account, undefined)
      : undefined;
  }

  @computed
  get isSkipped(): boolean {
    return this._courseOccurrence.occurrence.skipped;
  }

  @computed
  get day(): Day {
    return this._courseOccurrence.day;
  }

  @computed
  get startTime(): Time {
    return this._courseOccurrence.period.startTime;
  }

  @computed
  get endTime(): Time {
    return this._courseOccurrence.period.endTime;
  }

  @computed
  get occurrenceTitle(): string {
    return this._courseOccurrence.occurrence.displayTitle;
  }

  @computed
  get occurrencesLeft() {
    return this.numberOfOccurrences - this._courseOccurrence.occurrence.ordinal;
  }

  @computed
  get tasksDisplayableContentViewModel(): DisplayableContentBoxViewModel {
    const filterManager = this._preferences.periodContentFilters;

    return new AppDisplayableContentBoxViewModel(
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this._contentPasteboardStore,
      this._data,
      this.day,
      this._courseOccurrence.period,
      () => this._section,
      () => {
        const contents = _.filter(this._data.visibleContents, (cd) => {
          return (
            cd.sectionId === this._section.id &&
            cd.kind === 'task' &&
            ((cd.dueDay.isSame(this.day) && cd.duePeriodTag === this._courseOccurrence.period.tag) ||
              (this._preferences.periodShowAssignmentDay &&
                !cd.dueDay.isSame(cd.assignmentDay) &&
                cd.assignmentDay.isSame(this.day)))
          );
        });

        const filteredContents = filterManager.applyFiltersToContents(contents, this._localizationService);

        return _.map(
          filteredContents,
          (cd) =>
            new AppDisplayableContentViewModel(
              this._localizationService,
              this._data,
              this._section,
              // Always displayed in the same section!
              this._section.id,
              cd,
              !cd.dueDay.isSame(this.day) && cd.assignmentDay.isSame(this.day)
            )
        );
      }
    );
  }

  @computed
  get notesDisplayableContentViewModel(): ContentNoteBoxViewModel {
    return new AppContentNoteBoxViewModel(
      this._navigationService,
      this.day,
      this._courseOccurrence.period.tag,
      this._courseOccurrence.occurrence.sectionId,
      () => {
        const notes = _.filter(this._data.visibleContents, (cd) => {
          return (
            cd.kind === 'note' && cd.dueDay.isSame(this.day) && cd.duePeriodTag === this._courseOccurrence.period.tag
          );
        });

        if (notes.length > 0) {
          return new AppDisplayableContentViewModel(
            this._localizationService,
            this._data,
            this._section,
            undefined,
            notes[0],
            false
          );
        } else {
          return undefined;
        }
      },
      this.noteWasChanged
    );
  }

  @computed
  get numberOfOccurrences(): number {
    return this._data.getOccurrencesForSectionId(this._section.id).length;
  }

  private noteWasChanged = () => {
    this.noteDidChangeAtIndexPath(this._indexPath);
  };
}
