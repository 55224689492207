import { Time as PBTime } from '@buf/studyo_studyo.bufbuild_es/studyo/type_pb';
import { Locale } from '@shared/resources/services';
import { Duration, differenceInSeconds, format as formatDate, intervalToDuration, set } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { LazyGetter } from 'lazy-get-decorator';

export class Time {
  private _pb: PBTime;

  static fromPB(pb?: PBTime): Time | undefined {
    return pb ? new Time(pb) : undefined;
  }

  static fromHoursMinutes(hours: number, minutes: number): Time {
    const pb = new PBTime();
    pb.hour = hours;
    pb.minute = minutes;

    return new Time(pb);
  }

  static fromString(value?: string): Time | undefined {
    if (value == null) {
      return undefined;
    }

    const parts = value.split(':');

    if (parts.length !== 2) {
      return undefined;
    }

    const hours = Number(parts[0]);
    const minutes = Number(parts[1]);

    if (hours < 0 || minutes < 0 || hours > 23 || minutes > 59) {
      return undefined;
    }

    const pb = new PBTime();
    pb.hour = hours;
    pb.minute = minutes;

    return new Time(pb);
  }

  static fromDate(value: Date | undefined): Time | undefined {
    if (value == null) {
      return undefined;
    }

    const pb = new PBTime();
    pb.hour = value.getHours();
    pb.minute = value.getMinutes();

    return new Time(pb);
  }

  static create(value: { hour: number; minute: number }) {
    const pbTime = new PBTime();
    pbTime.hour = value.hour;
    pbTime.minute = value.minute;

    return new Time(pbTime);
  }

  constructor(pb: PBTime) {
    this._pb = pb;
  }

  get asPB(): PBTime {
    const pb = new PBTime();

    pb.hour = this.hour;
    pb.minute = this.minute;

    return pb;
  }

  get asJson() {
    return { hour: this.hour, minute: this.minute };
  }

  get hour(): number {
    return this._pb.hour;
  }

  get minute(): number {
    return this._pb.minute;
  }

  get asString(): string {
    const hourValue = this.hour < 10 ? '0' + this.hour : this.hour;
    const minValue = this.minute < 10 ? '0' + this.minute : this.minute;

    return hourValue + ':' + minValue;
  }

  @LazyGetter()
  get asDate(): Date {
    return set(new Date(), { hours: this.hour, minutes: this.minute, seconds: 0, milliseconds: 0 });
  }

  formattedString(format: string, locale?: Locale): string {
    const day = this.asDate;
    return formatDate(day, format, { locale: locale === 'fr' ? fr : enUS });
  }

  compare(otherTime: Time): -1 | 0 | 1 {
    if (this.hour < otherTime.hour) {
      return -1;
    } else if (this.hour > otherTime.hour) {
      return 1;
    } else if (this.minute < otherTime.minute) {
      return -1;
    } else if (this.minute > otherTime.minute) {
      return 1;
    } else {
      return 0;
    }
  }

  isSame(other: Time) {
    return this.compare(other) === 0;
  }

  isBefore(other: Time) {
    return this.compare(other) < 0;
  }

  isAfter(other: Time) {
    return this.compare(other) > 0;
  }

  isSameOrBefore(other: Time) {
    return this.compare(other) <= 0;
  }

  isSameOrAfter(other: Time) {
    return this.compare(other) >= 0;
  }

  addMinutes(minutes: number): Time {
    let newMinute = this.minute + minutes;
    const newHour = (this.hour + Math.floor(newMinute / 60)) % 24;
    newMinute = newMinute % 60;

    return Time.create({ hour: newHour, minute: newMinute });
  }

  duration(end: Time): Duration {
    /**
     * For an unknown reason, the dayjs diff in milliseconds sometimes
     * return an imprecise number that has 1 millisecond missing.
     * To prevent that error, we get the precise seconds, then round it and transform it to milliseconds
     */
    return intervalToDuration({ start: 0, end: Math.round(differenceInSeconds(end.asDate, this.asDate)) * 1000 });
  }
}
