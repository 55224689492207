import { LocalizationService } from '@shared/resources/services';
import { DataLoader } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  NavigationService,
  StudyoEnvironmentService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { AppWorkloadHeaderViewModel, WorkloadHeaderViewModel } from './WorkloadHeaderViewModel';

export interface AgendaWorkloadViewModel {
  readonly data: DataLoader;
  readonly header: WorkloadHeaderViewModel;
  readonly url: string;
  readonly urlRefreshId: number;

  refresh(): void;
}

export class AppAgendaWorkloadViewModel implements AgendaWorkloadViewModel {
  @observable private _urlRefreshId = 1;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _environmentService: StudyoEnvironmentService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _attachmentManager: AttachmentManager,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _uiSettingsStore: UISettingsStore
  ) {
    makeObservable(this);
  }

  @computed
  get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  get header(): WorkloadHeaderViewModel {
    const preferences = this._settingsStore.getPreferences(this.data.accountId);

    return new AppWorkloadHeaderViewModel(
      () => this.refresh(),
      this._environmentService,
      this._localizationService,
      this._navigationService,
      this.data,
      this._attachmentManager,
      preferences,
      this._uiSettingsStore
    );
  }

  @computed
  get url(): string {
    const { accountId, configId } = this.data;
    const locale = this._localizationService.currentLocale;
    let url = this._environmentService.insightsBaseUrl;
    url += `/configs/${configId}/embedded/workload/${accountId}?studyo_refresh_id=${this._urlRefreshId}&lang=${locale}`;
    return url;
  }

  @computed
  get urlRefreshId() {
    return this._urlRefreshId;
  }

  @action
  refresh() {
    this._urlRefreshId += 1;
  }
}
