import { Time } from '@shared/models/types';

export interface OverlappingPeriodPriorityObject {
  readonly cycleDay: number;
  readonly periodTag: string;
  readonly startTime: Time;
  readonly endTime: Time;
  readonly scheduleTag: string;
  readonly isSkipped: boolean;
  readonly asJson: Record<string, unknown>;

  overlapsWithOther: (other: OverlappingPeriodPriorityObject) => boolean;
  overlapsWithTimes: (startTime: Time, endTime: Time) => boolean;
  equals: (other: OverlappingPeriodPriorityObject) => boolean;
}

export function overlappingPeriodPriorityObjectsAreEqual(
  first: OverlappingPeriodPriorityObject,
  second: OverlappingPeriodPriorityObject
): boolean {
  return (
    first.cycleDay === second.cycleDay &&
    first.startTime.isSame(second.startTime) &&
    first.endTime.isSame(second.endTime) &&
    first.periodTag === second.periodTag &&
    first.scheduleTag === second.scheduleTag
  );
}
