import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { NavigationService, StudyoSettingsStore } from '@studyo/services';
import { StudyoAnalyticsService } from '@studyo/services/analytics';
import _ from 'lodash';
import { makeObservable, override } from 'mobx';
import { TimelineListItemViewModelDelegate } from './TimelineListItemViewModel';
import {
  AppTimelineListSectionBaseViewModel,
  TimelineListSectionBaseViewModel
} from './TimelineListSectionBaseViewModel';
import { TimelineSearchFilter } from './TimelineListViewModel';

export type TimelineListSectionCompletedViewModel = TimelineListSectionBaseViewModel;

export class AppTimelineListSectionCompletedViewModel extends AppTimelineListSectionBaseViewModel {
  constructor(
    listItemDelegate: TimelineListItemViewModelDelegate,
    searchFilterProvider: TimelineSearchFilter,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    analyticsService: StudyoAnalyticsService,
    data: AccountData,
    settingsStore: StudyoSettingsStore,
    onMovePlannedDates: () => void
  ) {
    super(
      listItemDelegate,
      searchFilterProvider,
      navigationService,
      localizationService,
      analyticsService,
      data,
      settingsStore,
      onMovePlannedDates
    );
    makeObservable(this);
  }

  @override
  get sections() {
    const completedContents = _.filter(this.contents, (c) => c.state === 'completed');
    return this.calculateContentGroupByMonth(completedContents);
  }

  readonly editActionType = 'uncomplete';
}
