import { Section_Schedule as PBSchedule } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import { Day, DayOfWeek } from '../types';
import { dayOfWeekFromProtobuf } from '../types/EnumConversion';
import { SectionScheduleCustom, SectionScheduleCustomModel } from './SectionScheduleCustom';
import { SectionScheduleMaster, SectionScheduleMasterModel } from './SectionScheduleMaster';

export interface SectionScheduleModel extends SerializableModel<PBSchedule> {
  readonly id: string;
  readonly roomName: string;
  readonly effectiveFromDay: Day | undefined;
  readonly effectiveToDay: Day | undefined;

  readonly dayOfWeek: DayOfWeek | undefined;
  readonly cycleDay: number | undefined;
  readonly day: Day | undefined;
  readonly appliesTo: DayOfWeek | number | Day;

  readonly masterSchedule: SectionScheduleMasterModel | undefined;
  readonly customSchedule: SectionScheduleCustomModel | undefined;
  readonly schedule: SectionScheduleMasterModel | SectionScheduleCustomModel;

  readonly teacherIds: string[];
  readonly termTag: string;
}

export class SectionSchedule extends BaseModel<PBSchedule> implements SectionScheduleModel {
  constructor(pb: PBSchedule) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get roomName(): string {
    return this._pb.roomName;
  }

  @LazyGetter()
  get effectiveFromDay(): Day | undefined {
    return Day.fromPB(this._pb.effectiveFromDay);
  }

  @LazyGetter()
  get effectiveToDay(): Day | undefined {
    return Day.fromPB(this._pb.effectiveToDay);
  }

  @LazyGetter()
  get dayOfWeek(): DayOfWeek | undefined {
    return this._pb.appliesTo.case === 'dayOfWeek' ? dayOfWeekFromProtobuf(this._pb.appliesTo.value) : undefined;
  }

  @LazyGetter()
  get cycleDay(): number | undefined {
    return this._pb.appliesTo.case === 'cycleDay' ? this._pb.appliesTo.value : undefined;
  }

  @LazyGetter()
  get day(): Day | undefined {
    return this._pb.appliesTo.case === 'day' ? Day.fromPB(this._pb.appliesTo.value) : undefined;
  }

  @LazyGetter()
  get appliesTo(): DayOfWeek | number | Day {
    if (this._pb.appliesTo.case === 'dayOfWeek') {
      return dayOfWeekFromProtobuf(this._pb.appliesTo.value);
    } else if (this._pb.appliesTo.case === 'cycleDay') {
      return this._pb.appliesTo.value;
    } else if (this._pb.appliesTo.case === 'day') {
      return this.day!;
    } else {
      throw new Error('Invalid data from backend: SectionSchedule.appliesTo has no value.');
    }
  }

  @LazyGetter()
  get masterSchedule(): SectionScheduleMasterModel | undefined {
    return this._pb.schedule.case === 'master' ? new SectionScheduleMaster(this._pb.schedule.value) : undefined;
  }

  @LazyGetter()
  get customSchedule(): SectionScheduleCustomModel | undefined {
    return this._pb.schedule.case === 'custom' ? new SectionScheduleCustom(this._pb.schedule.value) : undefined;
  }

  @LazyGetter()
  get schedule(): SectionScheduleMasterModel | SectionScheduleCustomModel {
    if (this._pb.schedule.case === 'master') {
      return this.masterSchedule!;
    } else if (this._pb.schedule.case === 'custom') {
      return this.customSchedule!;
    } else {
      throw new Error('Invalid data from backend: SectionSchedule.schedule has no value.');
    }
  }

  get teacherIds(): string[] {
    return this._pb.teacherIds;
  }

  get termTag(): string {
    return this._pb.termTag;
  }
}
