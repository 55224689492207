import * as CPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/connector_pb';
import {
  AutoMatchEntry,
  ExternalAccount,
  ExternalAccountKind,
  ExternalAssociation,
  ExternalSection
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { Day as PBDay, Time as PBTime } from '@buf/studyo_studyo.bufbuild_es/studyo/type_pb';
import { ConnectorManager } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/connector_connect';
import { Timestamp } from '@bufbuild/protobuf';
import { ConnectorManagerTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class ConnectorManagerGrpcTransport extends BaseGrpcTransport implements ConnectorManagerTransport {
  async fetchExternalAccounts(configId: string): Promise<ExternalAccount[]> {
    const request = new CPB.GetExternalAccountsRequest();
    request.configId = configId;
    request.kind = ExternalAccountKind.Any;
    request.includeStatus = true;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.getExternalAccounts, request);

    return response.externalAccounts;
  }

  async updateSnoozeAccountErrors(
    configId: string,
    externalAccountId: string,
    snoozeErrorsUntil: Timestamp | undefined,
    pauseSyncWhenSnoozed: boolean
  ): Promise<void> {
    const request = new CPB.SnoozeAccountErrorsRequest();
    request.configId = configId;
    request.externalAccountId = externalAccountId;
    request.until = snoozeErrorsUntil;
    request.pauseSyncWhenSnoozed = pauseSyncWhenSnoozed;

    await this.performRequest(ConnectorManager, ConnectorManager.methods.snoozeAccountErrors, request);
  }

  async throttleAccountSync(
    configId: string,
    externalAccountId: string,
    skippedSyncCycleCount: number,
    syncPauseTime: PBTime | undefined,
    syncResumeTime: PBTime | undefined,
    millisecondsBetweenSyncs: number
  ): Promise<void> {
    const request = new CPB.ThrottleAccountSyncRequest({
      configId,
      externalAccountId,
      skippedSyncCycleCount,
      syncPauseTime,
      syncResumeTime,
      millisecondsBetweenSyncs
    });

    await this.performRequest(ConnectorManager, ConnectorManager.methods.throttleAccountSync, request);
  }

  async deleteExternalAccount(externalAccountId: string): Promise<void> {
    const request = new CPB.DeleteExternalAccountRequest();
    request.externalAccountId = externalAccountId;

    await this.performRequest(ConnectorManager, ConnectorManager.methods.deleteExternalAccount, request);
  }

  async fetchExternalSections(externalAccountId: string): Promise<ExternalSection[]> {
    const request = new CPB.GetExternalSectionsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.getExternalSections, request);

    return response.externalSections;
  }

  async fetchExternalAssociations(configId: string, externalAccountId: string): Promise<ExternalAssociation[]> {
    const request = new CPB.GetAssociationsRequest();
    request.configId = configId;
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.getAssociations, request);

    return response.associations;
  }

  async fetchExternalAssociation(externalAssociationId: string): Promise<ExternalAssociation> {
    const request = new CPB.GetAssociationRequest();
    request.associationId = externalAssociationId;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.getAssociation, request);

    const association = response.association;

    if (association == null) {
      throw new Error('Unexpected result from backend: A GetAssociation request did not return an association.');
    }

    return association;
  }

  async createExternalAssociation(
    configId: string,
    sectionId: string,
    ownerId: string,
    kind: ExternalAccountKind,
    externalSectionId: string,
    externalAccountId: string,
    minDate: PBDay,
    maxDate: PBDay
  ): Promise<ExternalAssociation> {
    const request = new CPB.CreateAssociationRequest();
    request.configId = configId;
    request.sectionId = sectionId;
    request.ownerId = ownerId;
    request.kind = kind;
    request.externalSectionId = externalSectionId;
    request.externalAccountId = externalAccountId;
    request.minimumDate = minDate;
    request.maximumDate = maxDate;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.createAssociation, request);

    const association = response.association;

    if (association == null) {
      throw new Error('Unexpected result from backend: A CreateAssociation request did not return an association.');
    }

    return association;
  }

  async updateExternalAssociation(
    externalAssociationId: string,
    ownerId: string,
    minDate: PBDay,
    maxDate: PBDay
  ): Promise<ExternalAssociation> {
    const request = new CPB.UpdateAssociationRequest();
    request.associationId = externalAssociationId;
    request.minimumDate = minDate;
    request.maximumDate = maxDate;
    request.ownerId = ownerId;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.updateAssociation, request);

    const association = response.association;

    if (association == null) {
      throw new Error('Unexpected result from backend: An UpdateAssociation request did not return an association.');
    }

    return association;
  }

  async deleteExternalAssociation(externalAssociationId: string): Promise<void> {
    const request = new CPB.DeleteAssociationRequest();
    request.associationId = externalAssociationId;

    await this.performRequest(ConnectorManager, ConnectorManager.methods.deleteAssociation, request);
  }

  async deleteExternalAssociations(configId: string, kind: ExternalAccountKind): Promise<void> {
    const request = new CPB.DeleteAssociationsRequest();
    request.configId = configId;
    request.kind = kind;

    await this.performRequest(ConnectorManager, ConnectorManager.methods.deleteAssociations, request);
  }

  async queueExternalAssociationSync(
    externalAssociationId: string,
    forceRefresh: boolean
  ): Promise<CPB.QueueAssociationSyncResponse> {
    const request = new CPB.QueueAssociationSyncRequest();
    request.associationId = externalAssociationId;
    request.forceRefresh = forceRefresh;

    return await this.performRequest(ConnectorManager, ConnectorManager.methods.queueAssociationSync, request);
  }

  async syncExternalAssociation(
    externalAssociationId: string,
    forceRefresh: boolean
  ): Promise<CPB.SyncAssociationResponse> {
    const request = new CPB.SyncAssociationRequest();
    request.associationId = externalAssociationId;
    request.forceRefresh = forceRefresh;

    return await this.performRequest(ConnectorManager, ConnectorManager.methods.syncAssociation, request);
  }

  async testExternalAssociation(externalAssociationId: string): Promise<CPB.TestAssociationResponse> {
    const request = new CPB.TestAssociationRequest();
    request.associationId = externalAssociationId;

    return await this.performRequest(ConnectorManager, ConnectorManager.methods.testAssociation, request);
  }

  async resetKnownElements(
    configId: string,
    externalAccountId: string,
    externalAssociationId?: string
  ): Promise<bigint> {
    const request = new CPB.ResetKnownElementsRequest();
    request.configId = configId;
    request.externalAccountId = externalAccountId;
    request.optionalExternalAssociationId = externalAssociationId ?? '';

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.resetKnownElements, request);

    return response.elementCount;
  }

  async addAutoMatch(
    externalAccountId: string,
    studyoPattern: string,
    externalPattern: string
  ): Promise<ExternalAccount> {
    const request = new CPB.AddAutoMatchRequest();
    request.externalAccountId = externalAccountId;
    request.studyoPattern = studyoPattern;
    request.externalPattern = externalPattern;

    const response = await this.performRequest(ConnectorManager, ConnectorManager.methods.addAutoMatch, request);

    const account = response.externalAccount;

    if (account == null) {
      throw new Error('Unexpected result from backend: An AddAutoMatch request did not return an account.');
    }

    return account;
  }

  async setScheduledAutoMatch(
    externalAccountId: string,
    isScheduledAutoMatchEnabled: boolean,
    autoMatchHistory: AutoMatchEntry[]
  ): Promise<ExternalAccount> {
    const request = new CPB.SetScheduledAutoMatchingRequest({
      externalAccountId,
      isScheduledAutoMatchEnabled,
      autoMatchHistory
    });

    const response = await this.performRequest(
      ConnectorManager,
      ConnectorManager.methods.setScheduledAutoMatching,
      request
    );

    const account = response.externalAccount;

    if (account == null) {
      throw new Error('Unexpected result from backend: A SetScheduledAutoMatching request did not return an account.');
    }

    return account;
  }

  async runAutoMatch(externalAccountId: string, configId: string): Promise<CPB.RunAutoMatchingResponse> {
    const request = new CPB.RunAutoMatchingRequest({ externalAccountId, configId });
    return await this.performRequest(ConnectorManager, ConnectorManager.methods.runAutoMatching, request);
  }
}
