import { ContentDefinition_ExternalContentDefinition as PBExternalContentDefinition } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { Integration, Time } from '../types';
import { integrationFromProtobuf } from '../types/EnumConversion';

export interface ExternalContentDefinitionModel {
  readonly id: string;
  readonly sourceId: string;
  readonly sourceIntegration: Integration;
  readonly uri: string;
  readonly dueTime: Time | undefined;
}

export class ExternalContentDefinition implements ExternalContentDefinitionModel {
  private _pb: PBExternalContentDefinition;

  constructor(pb: PBExternalContentDefinition) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get sourceId(): string {
    return this._pb.sourceId;
  }

  get sourceIntegration(): Integration {
    return integrationFromProtobuf(this._pb.sourceIntegration);
  }

  get uri(): string {
    return this._pb.uri;
  }

  @LazyGetter()
  get dueTime(): Time | undefined {
    return Time.fromPB(this._pb.dueTime);
  }
}
