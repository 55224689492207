export interface CalendarViewModelsStrings {
  characters: string;
  copySuffix: string;
  cycleDayTitleCharLimit: string;
  emptyPeriodTagError: string;
  emptySchoolNameError: string;
  emptyTagError: string;
  emptyTitleError: string;
  maximumNumberOfDaysPerCycle: string;
  minimumNumberOfDaysPerCycle: string;
  none: string;
  periodsStartTimeBeforeEndTime: string;
  preserve: string;
  restart: string;
  shiftToNext: string;
  skip: string;
  startDateAfterEndDate: string;
  tagTooLongError: string;
  tagsTooLongError: string;
  titleTooLongError: string;
  unexpectedError: string;
}

export const EnglishCalendarViewModelsStrings: CalendarViewModelsStrings = {
  characters: ' characters', // No capital letter since used at end of a message
  copySuffix: ' COPY',
  cycleDayTitleCharLimit: 'Cycle day titles cannot contain more than ',
  emptyPeriodTagError: 'Period tags cannot be empty.',
  emptySchoolNameError: 'The school name cannot be empty.',
  emptyTagError: 'The tag cannot be empty.',
  emptyTitleError: 'The title cannot be empty.',
  maximumNumberOfDaysPerCycle: 'The number of days per cycle cannot be more than ',
  minimumNumberOfDaysPerCycle: 'The number of days per cycle cannot be less than ',
  none: 'None',
  periodsStartTimeBeforeEndTime: 'Period start times cannot be past period end times.',
  preserve: 'Preserve',
  restart: 'Restart',
  shiftToNext: 'Shift',
  skip: 'Skip',
  startDateAfterEndDate: 'The start date cannot occur after the end date.',
  tagTooLongError: 'The tag cannot contain more than ',
  tagsTooLongError: 'Period tags cannot contain more than ',
  titleTooLongError: 'The title cannot contain more than ',
  unexpectedError: 'An unexpected error occurred'
};

export const FrenchCalendarViewModelsStrings: CalendarViewModelsStrings = {
  characters: ' caractères', // No capital letter and has a space since used at end of a message
  copySuffix: ' (COPIE)',
  cycleDayTitleCharLimit: 'Les titres de jours-cycles ne peuvent contenir plus de ',
  emptyPeriodTagError: 'Les étiquettes de périodes ne peuvent être vides.',
  emptySchoolNameError: 'Le nom de l’école ne peut pas être vide.',
  emptyTagError: 'Le tag ne peut être vide.',
  emptyTitleError: 'Le titre ne peut être vide.',
  maximumNumberOfDaysPerCycle: 'Le nombre de jours par cycle ne peut être plus de ',
  minimumNumberOfDaysPerCycle: 'Le nombre de jours par cycle ne peut être moins de ',
  none: 'Aucun',
  periodsStartTimeBeforeEndTime: 'Les heures de début de périodes ne peuvent être passé les heures de fin.',
  preserve: 'Préserver',
  restart: 'Redémarrer',
  shiftToNext: 'Décaler',
  skip: 'Sauter',
  startDateAfterEndDate: 'La date de début doit être avant la date de fin.',
  tagTooLongError: 'Le tag ne peut contenir plus de ',
  tagsTooLongError: 'Les tags de périodes ne peuvent contenir plus de ',
  titleTooLongError: 'Le titre ne peut contenir plus de ',
  unexpectedError: 'Une erreur inattendue est survenue'
};
