import { Check } from '@mui/icons-material';
import { Button, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { GearMenuShareViewModel } from '../../viewmodels';
import { SaveDialog, useSaveDialog } from '../utils';

export interface InviteParentProps {
  sx?: SxProps;
  className?: string;
  viewModel: GearMenuShareViewModel;
}

export const InviteParent = observer((props: InviteParentProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;
  const [invitePromise, startInvite, resetInvite, retryInvite] = useSaveDialog(() => viewModel.submitInvite());

  return (
    <Stack sx={sx} className={className}>
      {!viewModel.emailWasSent && (
        <Stack alignItems="center" my={3} spacing={2}>
          <Typography variant="body1" textAlign="center" px={4}>
            {strings.pleaseEnterParentEmail}
          </Typography>

          <Stack spacing={1} width="100%" alignItems="center">
            <TextField
              variant="filled"
              value={viewModel.email}
              onChange={(e) => (viewModel.email = e.currentTarget.value)}
              label={strings.parentEmailAddressPlaceholder}
              onSubmit={startInvite}
              type="email"
              sx={{ width: '85%' }}
            />

            {viewModel.errorMessage.length > 0 && (
              <Typography variant="subtitle2" textAlign="center" px={4} color="error">
                {viewModel.errorMessage}
              </Typography>
            )}
          </Stack>

          <Button variant="contained" sx={{ width: '85%' }} onClick={startInvite}>
            {strings.invite}
          </Button>
        </Stack>
      )}

      {viewModel.emailWasSent && (
        <Stack alignItems="center" my={3} spacing={1}>
          <Check color="primary" sx={{ width: 64, height: 64 }} />

          <Typography variant="h6" textAlign="center">
            {strings.inviteSent}
          </Typography>
        </Stack>
      )}

      <SaveDialog
        promise={invitePromise}
        retryCall={retryInvite}
        onClose={resetInvite}
        titles={{
          saving: strings.inviteLoadingTitle,
          saved: strings.inviteSuccessTitle,
          error: strings.inviteErrorTitle
        }}
        descriptions={{
          saving: strings.inviteLoadingMessage,
          saved: strings.inviteSuccessMessage,
          error: viewModel.errorMessage
        }}
      />
    </Stack>
  );
});
