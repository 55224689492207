import { AccountSummary } from '@shared/models/config';
import { Role } from '@shared/models/types';
import { ImageService, LocalizationService } from '@shared/resources/services';
import { EnvironmentService, NetworkService, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { getYear } from 'date-fns';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { Location, NavigateFunction } from 'react-router-dom';
import { AccountService, NavigationService } from '../../../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../../../services/analytics';
import {
  AppProfileMenuActiveProfileViewModel,
  ProfileMenuActiveProfileViewModel
} from './ProfileMenuActiveProfileViewModel';
import { AppProfileMenuDemoOptionsViewModel, ProfileMenuDemoOptionsViewModel } from './ProfileMenuDemoOptionsViewModel';
import { AppProfileMenuOptionViewModel, ProfileMenuOptionViewModel } from './ProfileMenuOptionViewModel';
import { AppProfileMenuProfileViewModel, ProfileMenuProfileViewModel } from './ProfileMenuProfileViewModel';
import {
  AppProfileMenuSynchronizeOptionViewModel,
  ProfileMenuSynchronizeOptionViewModel
} from './ProfileMenuSynchronizeOptionViewModel';

export interface ProfileMenuViewModel {
  readonly userEmail: string;
  readonly activeProfile: ProfileMenuActiveProfileViewModel;
  readonly currentRole: Role;
  readonly displayNewSchoolYearMessage: boolean;
  readonly currentYearProfiles: ProfileMenuProfileViewModel[];
  readonly options: ProfileMenuOptionViewModel[];
  readonly synchronizeOption: ProfileMenuSynchronizeOptionViewModel;
  readonly version: string;
  readonly copyright: string;
  readonly demoOptions: ProfileMenuDemoOptionsViewModel;

  close(): void;
  logout(location: Location, navigate: NavigateFunction): void;
  presentOtherProfiles(): void;
  createNewConfig(navigate: NavigateFunction): void;
}

export class AppProfileMenuViewModel implements ProfileMenuViewModel {
  private readonly _data: AccountData;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _imageService: ImageService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _environmentService: EnvironmentService,
    private readonly _networkService: NetworkService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._data = _accountService.displayedAccountData;
  }

  @computed
  private get configureOption(): ProfileMenuOptionViewModel {
    const isStandalone = this._data.config.type === 'standalone';

    return new AppProfileMenuOptionViewModel(
      isStandalone
        ? this._imageService.studyoImages.settings.profileMenu.configureSchool
        : this._imageService.studyoImages.settings.profileMenu.sectionSelection,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.configureConfig,
      (navigate) => void this.configureActiveProfile(navigate)
    );
  }

  @computed
  private get impersonateOption(): ProfileMenuOptionViewModel {
    return new AppProfileMenuOptionViewModel(
      this._imageService.studyoImages.settings.profileMenu.impersonateSelection,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.openStudentPlanner,
      () => void this.displayImpersonateStudentList()
    );
  }

  @computed
  private get shareCalendarOption(): ProfileMenuOptionViewModel {
    return new AppProfileMenuOptionViewModel(
      this._imageService.studyoImages.settings.profileMenu.shareConfig,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.sharePlanner,
      () => void this.shareActiveConfig()
    );
  }

  @computed
  private get schoolLinksOption(): ProfileMenuOptionViewModel {
    return new AppProfileMenuOptionViewModel(
      this._imageService.studyoImages.settings.profileMenu.schoolLinks,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.schoolLinks,
      () => void this.displaySchoolLinks()
    );
  }

  @computed
  private get preferencesOption(): ProfileMenuOptionViewModel {
    return new AppProfileMenuOptionViewModel(
      this._imageService.studyoImages.settings.profileMenu.preferences,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.preferences,
      () => void this.displayPreferences()
    );
  }

  @computed
  private get supportOption(): ProfileMenuOptionViewModel {
    return new AppProfileMenuOptionViewModel(
      this._imageService.studyoImages.settings.profileMenu.support,
      this._localizationService.localizedStrings.studyo.agenda.setting.profileMenu.contactUs,
      () => this.displaySupport()
    );
  }

  @computed
  private get currentAccount(): AccountSummary {
    return this._accountService.currentAccount != 'super-admin'
      ? this._accountService.currentAccount!
      : this._accountService.currentDisplayedAccount!;
  }

  @computed
  private get childAccount(): AccountSummary | undefined {
    const displayedAccount = this._accountService.currentDisplayedAccount;
    return this.currentAccount.id !== displayedAccount?.id ? displayedAccount : undefined;
  }

  @computed
  get userEmail(): string {
    return this._accountService.currentUserEmail;
  }

  @computed
  get activeProfile(): ProfileMenuActiveProfileViewModel {
    return new AppProfileMenuActiveProfileViewModel(
      this.currentAccount,
      this.childAccount,
      this._accountService,
      this._navigationService,
      this._onSuccess
    );
  }

  @computed
  get currentRole(): Role {
    return this.currentAccount.role;
  }

  @computed
  get displayNewSchoolYearMessage() {
    const currentAccountIsCurrentYear =
      this.currentAccount.configurationSummary?.endDay.isSameOrAfter(dateService.today) === true;

    const hasOtherProfiles = this.currentYearProfiles.length > 0;
    return !currentAccountIsCurrentYear && !hasOtherProfiles;
  }

  @computed
  get currentYearProfiles(): ProfileMenuProfileViewModel[] {
    const profiles: ProfileMenuProfileViewModel[] = [];
    const today = dateService.today;

    this._accountService.accounts.forEach((account) => {
      const endDay = account.configurationSummary?.endDay;

      if (endDay?.isBefore(today) === false) {
        if (account.role === 'parent') {
          account.childrenAccountSummaries.forEach((child) => {
            if (!(child.id === this.childAccount?.id && account.id === this.currentAccount.id)) {
              profiles.push(
                new AppProfileMenuProfileViewModel(
                  account,
                  child,
                  this._accountService,
                  this._navigationService,
                  this._onSuccess
                )
              );
            }
          });
        } else if (account.id !== this._accountService.currentDisplayedAccount?.id) {
          profiles.push(
            new AppProfileMenuProfileViewModel(
              account,
              undefined,
              this._accountService,
              this._navigationService,
              this._onSuccess
            )
          );
        }
      }
    });

    return profiles;
  }

  @computed
  get options(): ProfileMenuOptionViewModel[] {
    const account = this.currentAccount;
    const isStaff = account.role === 'school-staff' || account.role === 'studyo-staff';
    const isTeacher = account.role === 'teacher';
    const isImpersonatingTeacher = isTeacher && this.childAccount != null;
    const isParent = account.role === 'parent';

    const options = [
      isTeacher ? this.impersonateOption : undefined,
      !isImpersonatingTeacher && !isParent && !isStaff ? this.configureOption : undefined,
      !isImpersonatingTeacher ? this.shareCalendarOption : undefined,
      this.schoolLinksOption,
      this.preferencesOption,
      this.supportOption
    ];

    return _.compact(options);
  }

  @computed
  get synchronizeOption() {
    return new AppProfileMenuSynchronizeOptionViewModel(this._data, this._networkService);
  }

  @computed
  get version(): string {
    return this._environmentService.formattedVersionNumber;
  }

  @computed
  get copyright() {
    return this._localizationService.localizedStrings.studyo.copyright(getYear(new Date()));
  }

  @computed
  get demoOptions() {
    return new AppProfileMenuDemoOptionsViewModel(this._data);
  }

  close() {
    this._onCancel();
  }

  async logout(location: Location, navigate: NavigateFunction) {
    this._onSuccess();
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.logout });

    await this._accountService.logout();

    /**
     * This is another awesome hack to make sure we don't have a warning
     * nothing to dismiss
     */
    this._onSuccess();
    return this._navigationService.navigateToLogin(location, navigate, true);
  }

  presentOtherProfiles() {
    void this._navigationService.navigateToOtherProfilesList();
  }

  async createNewConfig(navigate: NavigateFunction) {
    await this._navigationService.closeAllModals();

    if (this.currentRole === 'individual') {
      // This screen references Today, but also allows using a code.
      return this._navigationService.navigateToCreateConfig(false, navigate);
    } else {
      return this._navigationService.navigateToUseCode(false, navigate);
    }
  }

  private async displayImpersonateStudentList() {
    return this._navigationService.navigateToImpersonateStudentSelection();
  }

  private async configureActiveProfile(navigate: NavigateFunction) {
    if (this._data.config.type === 'managed') {
      await this._navigationService.navigateToCourseSelection();
    } else {
      await this._navigationService.closeAllModals();
      await this._navigationService.navigateToLearnAboutToday(navigate);
    }
  }

  private shareActiveConfig() {
    return this._navigationService.navigateToGearMenuShare();
  }

  private displaySchoolLinks() {
    return this._navigationService.navigateToConfigLinkListModal();
  }

  private displayPreferences() {
    return this._navigationService.navigateToPreferences();
  }

  private displaySupport() {
    this.close();
    this._navigationService.showIntercomMessenger();
  }
}
