import { SectionUtils } from '@shared/components/utils';
import { ContentAttachmentModel } from '@shared/models/content';
import { Color } from '@shared/models/types';
import { ImageService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AccountService, AttachmentManager, NavigationService } from '../../services';
import { AppAttachmentListItemViewModel, AttachmentListItemViewModel } from './AttachmentListItemViewModel';

export interface AttachmentListViewModel {
  readonly canAddAttachment: boolean;
  readonly sectionColor: Color | undefined;
  readonly attachments: AttachmentListItemViewModel[];

  addAttachment: () => void;
  close: () => void;
}

export class AppAttachmentListViewModel implements AttachmentListViewModel {
  private readonly _data: AccountData;

  constructor(
    private readonly _attachmentManager: AttachmentManager,
    private readonly _imageService: ImageService,
    private readonly _navigationService: NavigationService,
    private readonly _getAttachments: () => ContentAttachmentModel[],
    private readonly _deleteAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    private readonly _addAttachment: (attachment: ContentAttachmentModel) => void,
    private readonly _isSlaveContent: boolean,
    private readonly _sectionId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    accountService: AccountService
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;
  }

  @computed
  get canAddAttachment() {
    return !this._data.isReadOnly && !this._isSlaveContent;
  }

  @computed
  get sectionColor(): Color | undefined {
    if (this._sectionId !== '') {
      return SectionUtils.getSectionColor(this._data.sectionsById.get(this._sectionId), this._data.account, undefined);
    } else {
      return undefined;
    }
  }

  @computed
  get attachments() {
    return this._getAttachments().map(
      (att) =>
        new AppAttachmentListItemViewModel(
          this._attachmentManager,
          this._imageService,
          att,
          (attachment) => void this._deleteAttachment(attachment),
          !this._isSlaveContent
        )
    );
  }

  async addAttachment() {
    // eslint-disable-next-line @typescript-eslint/require-await
    const response = await this._navigationService.navigateToAttachmentAdd(async (attachment) =>
      this._addAttachment(attachment)
    );

    if (response !== 'cancelled') {
      this.close();
    }
  }

  close() {
    this._onSuccess();
  }
}
