import { AccountUtils, ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import {
  ContentDefinitionModel,
  ContentPublishTarget,
  EditableContentDefinition,
  EditableContentPublishTarget
} from '@shared/models/content';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { verifyAndConfirmWorkload } from '@studyo/utils';
import { orderBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { AccountAutoSyncService, AccountService, NavigationService } from '../../../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../../../services/analytics';
import { AppDisplayableContentViewModel, DisplayableContentViewModel } from '../DisplayableContentViewModel';

export type ContentPublishKind = 'none' | 'all' | 'some';
export const AllContentPublishKind: ContentPublishKind[] = ['none', 'all', 'some'];

export interface ContentPublishStudentSelectionViewModel extends OnInit, OnDestroy {
  readonly hasChanges: boolean;
  readonly isPublishing: boolean;

  readonly content: DisplayableContentViewModel;
  readonly contentHasLongTimeSpan: boolean;
  readonly publishKind: ContentPublishKind;
  readonly publishedToStudentWithIds: string[];
  readonly sectionColor: Color | undefined;
  readonly sectionTitle: string | undefined;
  readonly date: string;
  readonly students: AccountModel[];
  readonly filteredStudents: AccountModel[];
  searchString: string;
  visibleToParentsAndTeachers: boolean;

  setPublishTargetNone: () => void;
  setPublishTargetAll: () => void;
  setPublishTargetSpecific: () => void;
  toggleStudentSelection: (student: AccountModel) => void;
  selectAllStudents: () => void;

  applyChanges: () => void;
  publish: () => Promise<void | DialogCancelled>;
  cancel: () => void;
  dismiss: () => void;
}

export class AppContentPublishStudentSelectionViewModel implements ContentPublishStudentSelectionViewModel {
  private readonly _content: EditableContentDefinition;

  @observable private _publishKind: ContentPublishKind;
  @observable private _selectedStudentIds: string[];
  @observable private _searchString = '';
  @observable private _visibleToParentsAndTeachers = true;

  private readonly _initialPublishKind: ContentPublishKind;
  private readonly _initialSelectedStudentIds: string[];
  private readonly _shouldSaveContent: boolean;
  private readonly _data: AccountData;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    content: string | (() => ContentDefinitionModel),
    accountService: AccountService
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;

    if (typeof content === 'string') {
      const cd = this._data.contentsById.get(content);
      if (cd == null) {
        throw new Error("Trying to publish a task that doesn't exists in the AccountData");
      }
      this._content = new EditableContentDefinition(cd);
      this._shouldSaveContent = true;
    } else {
      this._content = content() as EditableContentDefinition;
      this._shouldSaveContent = false;
    }

    const publishTarget = this._content.publishTarget;

    if (publishTarget == null) {
      this._publishKind = 'none';
      this._selectedStudentIds = [];
    } else if (publishTarget.kind === 'section') {
      this._publishKind = 'all';
      this._selectedStudentIds = [];
    } else {
      this._publishKind = publishTarget.targetAccountIds.length > 0 ? 'some' : 'none';
      this._selectedStudentIds = publishTarget.targetAccountIds;
    }

    this._initialPublishKind = this._publishKind;
    this._initialSelectedStudentIds = this._selectedStudentIds.slice();
    this._visibleToParentsAndTeachers = !(this._content.publishTarget?.isPrivate ?? false);
  }

  @computed
  private get section() {
    return this._content.sectionId.length > 0 ? this._data.sectionsById.get(this._content.sectionId) : undefined;
  }
  @computed
  get contentHasLongTimeSpan() {
    return ContentDefinitionUtils.hasLongTimeSpan(this._content, this._data);
  }

  @computed
  get isPublishing() {
    return this._shouldSaveContent;
  }

  @computed
  get hasChanges() {
    if (
      this._initialPublishKind !== this._publishKind ||
      this._initialSelectedStudentIds.length !== this._selectedStudentIds.length
    ) {
      return true;
    }

    const sortedInitialStudentIds = orderBy(this._initialSelectedStudentIds);
    const sortedCurrentStudentIds = orderBy(this._initialSelectedStudentIds);

    for (let i = 0; i < sortedInitialStudentIds.length; i++) {
      if (sortedInitialStudentIds[i] !== sortedCurrentStudentIds[i]) {
        return true;
      }
    }

    return false;
  }

  @computed
  get publishKind() {
    return this._publishKind;
  }

  @computed
  get publishedToStudentWithIds() {
    return this._selectedStudentIds;
  }

  @computed
  get content() {
    return new AppDisplayableContentViewModel(
      this._localizationService,
      this._data,
      this.section,
      this.section?.id,
      this._content,
      false
    );
  }
  @computed
  get sectionColor() {
    return this.section != null ? SectionUtils.getSectionColor(this.section, this._data.account, undefined) : undefined;
  }

  @computed
  get sectionTitle() {
    return this.section != null ? `${this.section.title} (${this.section.sectionNumber})` : undefined;
  }

  @computed
  get date() {
    const dateFormat = this._localizationService.localizedStrings.models.dateFormats.medium;
    return this._content.dueDay.formattedString(dateFormat);
  }

  @computed
  get students() {
    if (this.section != null) {
      return orderBy(this._data.getStudentsForSection(this.section), [(s) => s.lastName, (s) => s.firstName]);
    } else {
      return [];
    }
  }

  @computed
  private get upperCasedStudents() {
    return this.students.map((student) => ({
      student,
      upperCaseDisplayName: AccountUtils.getDisplayLastFirstName(student).toUpperCase()
    }));
  }

  @computed
  get filteredStudents() {
    const searchString = this.searchString.toUpperCase();

    return searchString.length === 0
      ? this.students
      : this.upperCasedStudents.filter((uc) => uc.upperCaseDisplayName.includes(searchString)).map((uc) => uc.student);
  }

  @computed
  get searchString() {
    return this._searchString;
  }

  set searchString(value: string) {
    this._searchString = value;
  }

  @computed
  get visibleToParentsAndTeachers() {
    return this._visibleToParentsAndTeachers;
  }

  set visibleToParentsAndTeachers(value: boolean) {
    this._visibleToParentsAndTeachers = value;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  @action
  setPublishTargetAll() {
    this._publishKind = 'all';
  }

  @action
  setPublishTargetNone() {
    this._publishKind = 'none';
  }

  @action
  setPublishTargetSpecific() {
    this._publishKind = 'some';
  }

  @action
  toggleStudentSelection(student: AccountModel) {
    const studentIndex = this._selectedStudentIds.indexOf(student.id);

    if (studentIndex >= 0) {
      this._selectedStudentIds.splice(studentIndex, 1);
    } else {
      this._selectedStudentIds.push(student.id);
    }
  }

  @action
  selectAllStudents() {
    this._selectedStudentIds = this.filteredStudents.map((s) => s.id);
  }

  applyChanges() {
    this.createPublishTargetIfNeeded();
    this._content.publishTarget!.kind = this._publishKind === 'all' ? 'section' : 'accounts';
    this._content.publishTarget!.targetAccountIds = this._publishKind === 'some' ? this._selectedStudentIds : [];
    this._content.publishTarget!.isPrivate = this.publishKind === 'some' ? !this._visibleToParentsAndTeachers : false;
  }

  async publish() {
    const originalTarget = this._content.publishTarget?.clone();
    this.applyChanges();

    if (this._publishKind === 'all') {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.publishToGroup });
    } else if (this._publishKind === 'some') {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.publishCustom });
    } else {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.unpublish });
    }

    if (this._shouldSaveContent) {
      if (this._publishKind !== 'none') {
        if (!(await verifyAndConfirmWorkload(this._navigationService, this._data, this._content, true))) {
          this._content.publishTarget = originalTarget;
          return 'cancelled';
        }
      }

      await this._data.publishContent(this._content);
    }
  }

  cancel() {
    this._onCancel();
  }

  dismiss() {
    this._onSuccess();
  }

  private createPublishTargetIfNeeded() {
    if (this._content.publishTarget == null) {
      this._content.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
    }
  }
}
