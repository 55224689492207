import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AllDayOfWeek, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader, dateService } from '@shared/services';
import _ from 'lodash';
import { action, computed, makeObservable, when } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { AppMonthDayViewModel, MonthDayViewModel } from './MonthDayViewModel';
import { AppMonthlyHeaderViewModel, MonthlyHeaderViewModel } from './MonthlyHeaderViewModel';

export interface MonthlyViewModel {
  readonly data: DataLoader;
  readonly header: MonthlyHeaderViewModel;
  readonly preferences: DataLoader;
  readonly currentMonthFirstDay: Day;
  readonly days: (MonthDayViewModel | undefined)[][];

  goToNextMonth: () => void;
  goToPreviousMonth: () => void;
  scrollToToday: () => void;
}

export class AppMonthlyViewModel implements MonthlyViewModel {
  readonly header: AppMonthlyHeaderViewModel;
  readonly preferences: StudyoAccountSettings;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    settingsStore: StudyoSettingsStore,
    attachmentManager: AttachmentManager
  ) {
    makeObservable(this);
    this.preferences = settingsStore.getPreferences(this.data.accountId);

    this.header = new AppMonthlyHeaderViewModel(
      _localizationService,
      _navigationService,
      attachmentManager,
      this.data,
      this.preferences,
      _uiSettingsStore
    );

    when(
      () => this.data.hasData,
      () => this.initialScroll()
    );
  }

  @computed
  private get currentMonthDays(): SchoolDay[] {
    return _.chain(this.data.schoolDays)
      .filter(
        (sd) => sd.day.month === this.currentMonthFirstDay.month && sd.day.year === this.currentMonthFirstDay.year
      )
      .sortBy([(sd) => sd.day])
      .value();
  }

  @computed
  get data() {
    return this._accountService.displayedAccountData;
  }

  @computed
  get currentMonthFirstDay() {
    const day = this._uiSettingsStore.monthlyCurrentDay ?? dateService.today;

    if (this.data.schoolDaysByDay.get(day.asString) != null) {
      return day;
    } else {
      const isBeforeFirstDay = this.data.schoolDays.find((sd) => sd.day.isAfter(day)) != null;
      return this.data.schoolDays[isBeforeFirstDay ? 0 : this.data.schoolDays.length - 1].day;
    }
  }

  @computed
  get days() {
    const currentDays = this.currentMonthDays;

    const elementRows: (MonthDayViewModel | undefined)[][] = [];
    let currentRow: (MonthDayViewModel | undefined)[] = [];

    const firstElement: SchoolDay = currentDays[0];
    const numberOfPaddingDayBefore = AllDayOfWeek.indexOf(firstElement.day.dayOfWeek);

    for (let i = 0; i < numberOfPaddingDayBefore; i++) {
      currentRow.push(undefined);
    }

    for (const item of currentDays) {
      currentRow.push(
        new AppMonthDayViewModel(
          this._analyticsService,
          this._localizationService,
          this._navigationService,
          this._contentPasteboardStore,
          item,
          this.data,
          this.preferences
        )
      );

      if (currentRow.length === 7) {
        elementRows.push(currentRow);
        currentRow = [];
      }
    }

    const numberOfPaddingDayAfter = currentRow.length === 0 ? 0 : 7 - currentRow.length;
    for (let i = 0; i < numberOfPaddingDayAfter; i++) {
      currentRow.push(undefined);
    }
    if (currentRow.length > 0) {
      elementRows.push(currentRow);
    }

    return elementRows;
  }

  @action
  goToPreviousMonth() {
    const configFirstDay = _.first(this.data.schoolDays)!.day;
    const isFirstMonthOfConfig =
      this.currentMonthFirstDay.month === configFirstDay.month &&
      this.currentMonthFirstDay.year === configFirstDay.year;

    if (!isFirstMonthOfConfig) {
      this.updateCurrentDate(this.currentMonthFirstDay.addMonths(-1));
    }
  }

  @action
  goToNextMonth() {
    const configEndDay = _.last(this.data.schoolDays)!.day;
    const isLastMonthOfConfig =
      this.currentMonthFirstDay.month === configEndDay.month && this.currentMonthFirstDay.year === configEndDay.year;

    if (!isLastMonthOfConfig) {
      this.updateCurrentDate(this.currentMonthFirstDay.addMonths(1));
    }
  }

  @action
  initialScroll() {
    if (this._uiSettingsStore.monthlyCurrentDay != null) {
      const monthIsStillInCalendar =
        this.data.schoolDays.find((sd) =>
          DateUtils.daysAreInSameMonth(sd.day, this._uiSettingsStore.monthlyCurrentDay!)
        ) != null;

      if (!monthIsStillInCalendar) {
        this.scrollToToday();
      }
    } else {
      this.scrollToToday();
    }
  }

  @action
  scrollToToday() {
    const today = dateService.today;
    const configStartDay = _.first(this.data.schoolDays)!.day;
    const configEndDay = _.last(this.data.schoolDays)!.day;

    let newDate: Day;
    if (this.data.schoolDaysByDay.get(today.asString) != null) {
      newDate = Day.create({ day: 1, month: today.month, year: today.year });
    } else if (configStartDay.isAfter(today)) {
      newDate = Day.create({
        day: 1,
        month: configStartDay.month,
        year: configStartDay.year
      });
    } else {
      newDate = Day.create({
        day: 1,
        month: configEndDay.month,
        year: configEndDay.year
      });
    }

    this.updateCurrentDate(newDate);
  }

  private updateCurrentDate(date: Day) {
    this._uiSettingsStore.monthlyCurrentDay = date;
  }
}
