import { Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { MonthlyHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader } from '../header';

export interface MonthHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: MonthlyHeaderViewModel;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
}

export const MonthHeader = observer(
  ({ sx, viewModel, goToNextPage, goToPreviousPage, className }: MonthHeaderProps) => {
    const onSectionFiltersPress = () => {
      void viewModel.displaySectionsFilter();
    };

    const onContentFiltersPress = () => {
      void viewModel.displayContentsFilter();
    };

    return (
      <CalendarHeader
        sx={sx}
        className={className}
        viewModel={viewModel}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        dateSwitcherContext="monthly"
        hasContentFilters={viewModel.hasContentFiltersSet}
        onContentFiltersPress={onContentFiltersPress}
        hasSectionFilters={viewModel.hasSectionFiltersSet}
        onSectionFiltersPress={onSectionFiltersPress}
        renderDate={(sx) => (
          <Stack sx={sx} alignItems="center" direction="row">
            <Typography sx={{ userSelect: 'none' }} variant="h6">
              {viewModel.dateTitle}
            </Typography>
          </Stack>
        )}
      />
    );
  }
);
