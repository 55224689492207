import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, when } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import {
  AgendaTimelineCollectionViewViewModel,
  AppAgendaTimelineCollectionViewViewModel
} from './AgendaTimelineCollectionViewViewModel';
import { AppTimelineHeaderViewModel, TimelineHeaderViewModel } from './TimelineHeaderViewModel';
import { TimelineListItemViewModelDelegate } from './TimelineListItemViewModel';
import { AppTimelineListViewModel, TimelineListViewModel } from './TimelineListViewModel';

export interface AgendaTimelineViewModel {
  readonly configId: string;
  readonly accountId: string;
  readonly data: AccountData;
  readonly preferences: StudyoAccountSettings;
  readonly showGoToPreviousWeekButton: boolean;
  readonly showGoToNextWeekButton: boolean;

  readonly currentDay: Day;

  readonly collectionView: AgendaTimelineCollectionViewViewModel;
  readonly header: TimelineHeaderViewModel;
  readonly list: TimelineListViewModel;

  goToToday: () => void;
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
}

export class AppAgendaTimelineViewModel implements AgendaTimelineViewModel, TimelineListItemViewModelDelegate {
  readonly collectionView: AppAgendaTimelineCollectionViewViewModel;
  readonly data: AccountData;
  readonly header: AppTimelineHeaderViewModel;
  readonly preferences: StudyoAccountSettings;
  readonly list: TimelineListViewModel;
  private readonly _numberOfDaysPerWeek = 7;

  @observable private _showPreviousButton = true;
  @observable private _showNextButton = true;

  constructor(
    private readonly _uiSettingsStore: UISettingsStore,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    accountService: AccountService,
    analyticsService: StudyoAnalyticsService,
    settingsStore: StudyoSettingsStore,
    attachmentManager: AttachmentManager
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;
    this.preferences = settingsStore.getPreferences(accountService.displayedAccountData.accountId);

    this.header = new AppTimelineHeaderViewModel(
      localizationService,
      navigationService,
      attachmentManager,
      this.data,
      this.preferences,
      this._uiSettingsStore,
      false
    );

    this.collectionView = new AppAgendaTimelineCollectionViewViewModel(
      navigationService,
      localizationService,
      analyticsService,
      settingsStore,
      _uiSettingsStore,
      this.data
    );

    this.list = new AppTimelineListViewModel(
      navigationService,
      localizationService,
      analyticsService,
      settingsStore,
      this.data,
      this
    );

    when(
      () => this.data.schoolDays.length > 0,
      () => {
        this.goToDayAtIndex(this.currentDayIndex);
      }
    );
  }

  @computed
  private get currentDayIndex() {
    const currentDay = this._uiSettingsStore.timelineCurrentDay;
    const today = dateService.today;
    // If there is no current day stored we use Today. However, we don't want it to be the first day on the left.
    // So we display the day that happens 10 days before today. Ideally, it would be based on the screen size
    // and how many days are displayed, but it is only known at render.
    const day = currentDay ?? today.addDays(-10);
    let dayIndex = this.findIndexForDay(day);

    if (dayIndex === -1) {
      const isBeforeFirstDay = this.data.schoolDays.find((sd) => sd.day.isAfter(today)) != null;
      dayIndex = isBeforeFirstDay ? 0 : this.data.schoolDays.length - 1;
    }

    return dayIndex;
  }

  @computed
  get accountId() {
    return this.data.accountId;
  }

  @computed
  get configId() {
    return this.data.configId;
  }

  @computed
  get currentDay() {
    return this.data.schoolDays[this.currentDayIndex].day;
  }

  @computed
  get showGoToPreviousWeekButton() {
    return this._showPreviousButton;
  }

  @computed
  get showGoToNextWeekButton() {
    return this._showNextButton;
  }

  goToToday() {
    const today = dateService.today;
    const todayIndex = this.findIndexForDay(today);
    this.goToDayAtIndex(todayIndex);
  }

  goToPreviousWeek() {
    this.goToDayAtIndex(this.currentDayIndex - this._numberOfDaysPerWeek);
  }

  goToNextWeek() {
    this.goToDayAtIndex(this.currentDayIndex + this._numberOfDaysPerWeek);
  }

  scrollToContent(content: ContentDefinitionModel) {
    const dueDayIndex = this.findIndexForDay(content.dueDay);
    this.goToDayAtIndex(Math.max(0, dueDayIndex - Math.floor(this.collectionView.getCalculatedNumberOfDays() / 2) + 1));
    this.collectionView.scrollToSection(content.sectionId);
  }

  @action
  private goToDayAtIndex(index: number) {
    let day: Day;
    if (index < 0) {
      day = this.data.schoolDays[0].day;
    } else if (index >= this.data.schoolDays.length) {
      day = this.data.schoolDays[this.data.schoolDays.length - 1].day;
    } else {
      day = this.data.schoolDays[index].day;
    }

    this._showPreviousButton = true;
    this._showNextButton = true;

    this._uiSettingsStore.timelineCurrentDay = day;

    this.updateGoToButtonVisibility(index);
  }

  @action
  private updateGoToButtonVisibility(index: number) {
    if (this.data.schoolDays[index + this._numberOfDaysPerWeek] == null) {
      this._showNextButton = false;
    } else if (index - this._numberOfDaysPerWeek < 0) {
      this._showPreviousButton = false;
    } else {
      this._showPreviousButton = true;
      this._showNextButton = true;
    }
  }

  private findIndexForDay(day: Day) {
    return _.findIndex(this.data.schoolDays, (sd) => sd.day.isSame(day));
  }
}
