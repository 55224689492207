import { Theme } from '@mui/material';
import { DateUtils } from '@shared/components/utils';
import { ContentDefinitionModel, ContentStepModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { TimelineContentState } from './TimelineContentState';

export class TimelineUtils {
  static getTimelineStateForContent(content: ContentDefinitionModel) {
    if (content.state != 'active') {
      return 'active';
    }

    return this.getTimelineStateForDay(content.dueDay);
  }

  static getTimelineStateForStep(step: ContentStepModel) {
    if (step.date != null) {
      return this.getTimelineStateForDay(step.date);
    } else {
      return 'active';
    }
  }

  static getStateColor(state: TimelineContentState, theme: Theme) {
    switch (state) {
      case 'active':
        return theme.studyo.contents.states.active;
      case 'late':
        return theme.palette.error.main;
      case 'today':
        return theme.studyo.contents.states.today;
    }
  }

  private static getTimelineStateForDay(day: Day) {
    if (DateUtils.isToday(day)) {
      return 'today';
    } else if (DateUtils.isLate(day)) {
      return 'late';
    }

    return 'active';
  }
}
