import { SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { SectionModel } from '@shared/models/config';
import { EditableContentDefinition } from '@shared/models/content';
import { DialogResult } from '@shared/services';
import { ConfirmDialog, DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { TaskStepsOrderView } from '../../../views';

export interface TaskStepsOrderScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  content: EditableContentDefinition;
  selectedSection: SectionModel | undefined;
  onSave: (content: EditableContentDefinition) => void;
}

export const TaskStepsOrderScreen = observer((props: TaskStepsOrderScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, selectedSection, content, onSave, onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createTaskStepsOrder(content, onSave, onSuccess!, onCancel!),
    [content]
  );

  const strings = localizationService.localizedStrings.studyo.contents.taskEdit;
  const theme = useTheme();

  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);

  const sectionColor =
    selectedSection != null
      ? SectionColors.get(viewModel.getSectionColor(selectedSection))
      : theme.studyo.periods.freePeriodColor;

  const saveButtonPressed = async () => {
    await viewModel.save();
  };

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      showCancelConfirmationDialog();
    } else {
      void viewModel.cancel();
    }
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const cancel = () => {
    hideCancelConfirmationDialog();
    void viewModel.cancel();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancelButtonPressed}
      disableEnforceFocus
    >
      <DialogHeader
        onClose={cancelButtonPressed}
        title={strings.reorderSteps}
        color={sectionColor}
        tintColor={
          selectedSection != null
            ? theme.studyo.periods.periodWithSectionTextColor
            : theme.studyo.periods.freePeriodTextColor
        }
      />

      <TaskStepsOrderView sx={{ flex: 1, py: 2 }} viewModel={viewModel} />

      <DialogActionButtons>
        <DialogButton title={strings.editCancel} type="cancel" onPress={cancelButtonPressed} />

        <DialogButton title={strings.editSave} type="normal" onPress={() => void saveButtonPressed()} />
      </DialogActionButtons>

      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />
    </ResponsiveDialog>
  );
});
