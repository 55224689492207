import { CardActionArea, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { ContentNoteBoxViewModel } from '../../viewmodels';
import { DisplayableContent } from './DisplayableContent';
import { EmptyNotePlaceholderBox } from './EmptyNotePlaceholderBox';

export interface ContentNoteBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentNoteBoxViewModel;
}

export const ContentNoteBox = observer((props: ContentNoteBoxProps) => {
  const { sx, className, viewModel } = props;
  const note = viewModel.note;

  const tapOnNote = () => {
    void viewModel.openNoteEdit();
  };

  return (
    <CardActionArea
      sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      className={className}
      onClick={tapOnNote}
      component="div"
    >
      {note != null && (
        <DisplayableContent
          sx={{ height: '100%', width: '100%' }}
          viewModel={note}
          representation={'inline'}
          tapHandler={tapOnNote}
        />
      )}

      {note == null && <EmptyNotePlaceholderBox sx={{ height: '100%', width: '100%' }} />}
    </CardActionArea>
  );
});
