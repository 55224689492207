import { CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';

export interface PresenterPendingViewProps {
  sx?: SxProps;
  className?: string;
  loadingMessage?: string;
  indicatorsSize?: 'small' | 'medium' | 'large';
}

export const PresenterPendingView = observer((props: PresenterPendingViewProps) => {
  const { loadingMessage, indicatorsSize, sx, className } = props;

  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" spacing={2}>
      <CircularProgress size={indicatorSize(indicatorsSize)} />

      {loadingMessage != null && (
        <Typography variant="body2" color="textSecondary">
          {loadingMessage}
        </Typography>
      )}
    </Stack>
  );
});

function indicatorSize(size: 'small' | 'medium' | 'large' | undefined) {
  switch (size) {
    case 'small':
      return '1em';

    case 'medium':
      return '2em';

    default:
      return '3em';
  }
}
