import { SchoolDayPeriodUtils, SectionUtils } from '@shared/components/utils';
import { CourseOccurrence, SchoolDay, SchoolDayPeriod } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Color, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { verifyAndConfirmWorkload } from '@studyo/utils';
import { filter, find, findIndex, map } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  ContentPasteboardStore,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService
} from '../../../services';
import {
  AppDisplayableContentBoxViewModel,
  AppDisplayableContentViewModel,
  DisplayableContentBoxViewModel,
  DisplayableContentViewModel
} from '../../contents';

export interface PlannerCellViewModel {
  readonly hasPeriod: boolean;
  readonly isSkipped: boolean;
  readonly title: string;
  readonly remainingOccurrences: number | undefined;
  readonly displayPeriodTag: boolean;
  readonly periodTag: string | undefined;
  readonly sectionColor?: Color;
  readonly isFirstDayOfCycle: boolean;
  readonly day: Day;
  readonly displayWeekendIndicator: boolean;

  readonly contentBox: DisplayableContentBoxViewModel;

  displayPeriodInfo: () => Promise<void | DialogCancelled>;
  compareSection: (sectionId: string) => boolean;
  copyContent: (contentId: string, sectionId: string, copyFromAssignment: boolean) => void;
  canPasteContent: (contentId: string) => boolean;
}

export class AppPlannerCellViewModel implements PlannerCellViewModel {
  readonly contentBox: AppDisplayableContentBoxViewModel;

  constructor(
    private readonly _contentPasteboard: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _cellIndexForSection: number,
    private readonly _schoolDay: SchoolDay,
    private readonly _occurrence: CourseOccurrence | undefined,
    private readonly _section: SectionModel,
    private readonly _data: AccountData,
    private readonly _period: SchoolDayPeriod | undefined
  ) {
    makeObservable(this);
    this.contentBox = new AppDisplayableContentBoxViewModel(
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this._contentPasteboard,
      this._data,
      this._schoolDay.day,
      this._period,
      () => this._section,
      () => this.contentViewModels
    );
  }

  @computed
  private get account() {
    return this._data.accountsById.get(this._data.accountId)!;
  }

  @computed
  get isSkipped() {
    return this._occurrence?.skipped ?? false;
  }

  @computed
  get isFirstDayOfCycle() {
    return this._schoolDay.cycleDay === 1;
  }

  @computed
  get displayWeekendIndicator() {
    return !this._preferences.plannerDisplayWeekends;
  }

  @computed
  get day(): Day {
    return this._schoolDay.day;
  }

  @computed
  get hasPeriod(): boolean {
    return this._occurrence != null;
  }

  @computed
  get periodTag(): string | undefined {
    return this._period != null ? this._period.tag : undefined;
  }

  @computed
  get displayPeriodTag() {
    return this._data.config.scheduleKind !== 'class-times';
  }

  @computed
  get title(): string {
    return this._occurrence?.displayTitle ?? '';
  }

  @computed
  get remainingOccurrences(): number | undefined {
    if (this._occurrence != null && !this.isSkipped) {
      return this._occurrence.termRemaining;
    }

    return undefined;
  }

  @computed
  get sectionColor(): Color | undefined {
    return SectionUtils.getSectionColor(this._section, this.account, undefined);
  }

  @computed
  private get contents(): ContentDefinitionModel[] {
    const schoolDayContents = this._data.visibleContents.filter(
      (cd) =>
        (cd.dueDay.isSame(this._schoolDay.day) ||
          (this._preferences.plannerShowAssignmentDay && cd.assignmentDay.isSame(this._schoolDay.day))) &&
        (cd.kind === 'task' || cd.notes.trim().length > 0)
    );

    const sectionContents = schoolDayContents.filter((cd) => {
      if (cd.kind === 'note') {
        // Only showing notes for this period.
        return this.periodTag != null && cd.duePeriodTag == this.periodTag;
      }

      return cd.sectionId === this._section.id;
    });
    const allPeriodTags = this.otherPeriodTagForSectionInSchoolDay;

    const contents = map(sectionContents, (content) => {
      const isAssignement = !content.dueDay.isSame(this._schoolDay.day);
      let shouldDisplayContent = false;

      if (this._cellIndexForSection > 0) {
        if (!isAssignement && content.duePeriodTag === this.periodTag) {
          shouldDisplayContent = true;
        }
      } else {
        if (isAssignement || content.duePeriodTag === this.periodTag) {
          shouldDisplayContent = true;
        } else {
          // Checking if the due period displays this content section.
          const tagIndex = findIndex(allPeriodTags, (tag) => tag === content.duePeriodTag);
          if (tagIndex === -1) {
            shouldDisplayContent = true;
          }
        }
      }

      return shouldDisplayContent ? content : undefined;
    }).filter((cd) => cd != null) as ContentDefinitionModel[];

    return this._preferences.plannerContentFilters.applyFiltersToContents(contents, this._localizationService);
  }

  @computed.struct
  private get contentViewModels(): DisplayableContentViewModel[] {
    return map(this.contents, (content) => {
      const isAssignement = !content.dueDay.isSame(this._schoolDay.day);
      return this.createViewModelForContent(content, isAssignement);
    });
  }

  @computed
  private get otherPeriodTagForSectionInSchoolDay(): string[] {
    const sectionPeriods = filter(this._schoolDay.periods, (p) => {
      const occurrences = SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(p, this._data.account);
      return find(occurrences, (co) => co.sectionId === this._section.id) != null;
    });

    return map(sectionPeriods, (p) => p.tag);
  }

  async displayPeriodInfo() {
    if (this.hasPeriod) {
      return this._navigationService.navigateToPlannerPeriodInfoModal(
        this._schoolDay.day,
        this.periodTag!,
        this._section.id
      );
    }
  }

  compareSection(sectionId: string) {
    return this._section.id === sectionId;
  }

  copyContent(contentId: string, sectionId: string, copyFromAssignment: boolean) {
    const isSameSection = this.compareSection(sectionId);
    const content = this._data.contentsById.get(contentId)!;

    if (content.kind === 'note') {
      this.mergeNotes(content, isSameSection);
    } else if (isSameSection) {
      void this.moveContent(content, copyFromAssignment);
    } else if (this._period != null) {
      void this._contentPasteboard.pasteContentInPeriod(
        content,
        this._schoolDay.day,
        this._period.tag,
        this._data,
        this._section.id,
        false,
        copyFromAssignment
      );
    } else {
      void this._contentPasteboard.pasteContentInDay(
        content,
        this.day,
        this._section.id,
        this._data,
        copyFromAssignment
      );
    }
  }

  canPasteContent(contentId: string): boolean {
    if (!this._data.canAddOrEditContentAtDate(this.day)) {
      return false;
    }

    const content = this._data.contentsById.get(contentId)!;
    return content.kind === 'task' || this._period != null;
  }

  private async moveContent(content: ContentDefinitionModel, isAssignment: boolean) {
    const editableContent = new EditableContentDefinition(content);
    const isSameAssignmentAndDueDate = editableContent.assignmentDay.isSame(editableContent.dueDay);

    if (isAssignment) {
      editableContent.assignmentDay = this.day;

      if (isSameAssignmentAndDueDate || editableContent.plannedDay.isBefore(editableContent.assignmentDay)) {
        editableContent.plannedDay = editableContent.assignmentDay;
      }

      if (isSameAssignmentAndDueDate || editableContent.dueDay.isBefore(editableContent.assignmentDay)) {
        editableContent.dueDay = editableContent.assignmentDay;
      }
    } else {
      editableContent.dueDay = this.day;

      if (isSameAssignmentAndDueDate || editableContent.assignmentDay.isAfter(editableContent.dueDay)) {
        editableContent.assignmentDay = editableContent.dueDay;
      }

      if (isSameAssignmentAndDueDate || editableContent.plannedDay.isAfter(editableContent.dueDay)) {
        editableContent.plannedDay = editableContent.dueDay;
      }

      if (this.periodTag != null) {
        editableContent.duePeriodTag = this.periodTag;
      }
    }

    if (editableContent.isMaster) {
      if (!(await verifyAndConfirmWorkload(this._navigationService, this._data, editableContent, true))) {
        return;
      }
    }

    await this._data.createOrUpdateContent(editableContent);
  }

  private mergeNotes(content: ContentDefinitionModel, isSameSection: boolean) {
    if (content.kind !== 'note' || this._period == null) {
      return;
    }

    const periodTag = this._period.tag;
    const targetNote = this._data.contents.find(
      (cd) => cd.kind === 'note' && cd.dueDay.isSame(this._schoolDay.day) && cd.duePeriodTag === periodTag
    );

    // If we are copying into the same period, we do nothing
    if (targetNote?.id === content.id) {
      return;
    }

    if (targetNote != null) {
      const editingExistingNote = new EditableContentDefinition(targetNote, false);
      if (editingExistingNote.notes.length > 0) {
        editingExistingNote.notes += '\n';
      }
      editingExistingNote.notes += content.notes;
      void this._data.createOrUpdateContent(editingExistingNote);
    } else {
      const newNote = EditableContentDefinition.createNewNote(
        this._data.configId,
        this._data.accountId,
        this._schoolDay.day,
        periodTag
      );

      newNote.notes = content.notes;

      void this._data.createOrUpdateContent(newNote);
    }

    if (isSameSection) {
      const editableContent = new EditableContentDefinition(content, false);
      editableContent.notes = '';
      void this._data.createOrUpdateContent(editableContent);
    }
  }

  private createViewModelForContent(
    content: ContentDefinitionModel,
    isAssignment: boolean
  ): DisplayableContentViewModel {
    return new AppDisplayableContentViewModel(
      this._localizationService,
      this._data,
      this._section,
      this._section.id,
      content,
      isAssignment
    );
  }
}
