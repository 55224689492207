import {
  AccountProfile as PBAccountProfile,
  AccountSummary as PBAccountSummary
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { Role } from '../types';
import { roleFromProtobuf } from '../types/EnumConversion';
import { AccountProfile, AccountProfileModel } from './AccountProfile';
import { SchoolYearConfigurationSummary } from './SchoolYearConfigurationSummary';

export class AccountSummary {
  private _pb: PBAccountSummary;

  constructor(pb: PBAccountSummary) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get role(): Role {
    return roleFromProtobuf(this._pb.role);
  }

  get isAdmin(): boolean {
    return this._pb.isAdmin;
  }

  get firstName(): string {
    return this._pb.firstName;
  }

  get lastName(): string {
    return this._pb.lastName;
  }

  get email(): string {
    return this._pb.email;
  }

  get managedIdentifier(): string {
    return this._pb.managedIdentifier;
  }

  get configId(): string {
    return this._pb.configId;
  }

  @LazyGetter()
  get configurationSummary(): SchoolYearConfigurationSummary | undefined {
    const summary = this._pb.configSummary;

    return summary && new SchoolYearConfigurationSummary(summary);
  }

  @LazyGetter()
  get childrenAccountSummaries(): AccountSummary[] {
    return this._pb.childrenAccountSummaries.map((a) => new AccountSummary(a));
  }

  @LazyGetter()
  get profile(): AccountProfileModel {
    return new AccountProfile(this._pb.profile ?? new PBAccountProfile());
  }

  @LazyGetter()
  get childrenAccountPendingVerificationSummaries(): AccountSummary[] {
    return this._pb.childrenAccountPendingVerificationSummaries.map((a) => new AccountSummary(a));
  }

  get language(): string {
    return this._pb.language;
  }

  get userId(): string {
    return this._pb.userId;
  }

  get isDeleted(): boolean {
    return this._pb.isDeleted;
  }
}
