import { css } from '@emotion/css';
import { Avatar, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { Color } from '@shared/models/types';
import { TintedImage } from '@shared/rxp/tinted-image';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface AvatarViewProps {
  sx?: SxProps;
  className?: string;
  size: number;
  firstName: string;
  lastName: string;
  color?: Color;
  textColor?: string;
  isImpersonating: boolean;
  isDemoSchool?: boolean;
  isPreventingChanges?: boolean;
}

export const AvatarView = observer((props: AvatarViewProps) => {
  const { imageService } = useStudyoServices();
  const {
    sx,
    className,
    size,
    color,
    textColor,
    isImpersonating,
    firstName,
    lastName,
    isPreventingChanges,
    isDemoSchool
  } = props;
  const theme = useTheme();

  const containerClassName = css({
    borderColor: isDemoSchool
      ? isPreventingChanges
        ? theme.studyo.utils.avatarView.readonlyBorderColor
        : theme.studyo.utils.avatarView.borderColor
      : undefined,
    borderWidth: isDemoSchool ? 3 : 0,
    borderStyle: isPreventingChanges ? 'solid' : 'dotted'
  });

  // Support emojis using two chars
  const firstCharCode = firstName.codePointAt(0);
  const secondCharCode = lastName.codePointAt(0);
  const initials = (
    (firstCharCode != null ? String.fromCodePoint(firstCharCode) : '') +
    (secondCharCode != null ? String.fromCodePoint(secondCharCode) : '')
  ).toUpperCase();

  const impersonatingViewSize = size * 0.6;

  const bgcolor = (color && SectionColors.get(color)) ?? theme.studyo.utils.avatarView.defaultBackgroundColor;
  const resolvedTextColor = textColor ?? theme.palette.getContrastText(bgcolor);

  return (
    <Avatar
      sx={{
        ...sx,
        width: size,
        height: size,
        fontSize: size * 0.45,
        color: resolvedTextColor,
        bgcolor
      }}
      className={clsx(containerClassName, className)}
    >
      {!isImpersonating && initials}

      {isImpersonating && (
        <TintedImage
          sx={{ width: impersonatingViewSize, height: impersonatingViewSize, borderRadius: '50%' }}
          source={imageService.studyoImages.settings.profileMenu.impersonatingIndicator}
          color="white"
        />
      )}
    </Avatar>
  );
});
