import { Check } from '@mui/icons-material';
import { SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  OptionsDialog,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { OptionsDialogElement } from '@studyo/models';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { TaskEditView } from '../../../views';

export interface TaskEditScreenProps extends DialogResult<string> {
  sx?: SxProps;
  className?: string;
  contentId: string | undefined;
  day: Day | undefined;
  periodTag: string | undefined;
  sectionId: string | undefined;
}

export const TaskEditScreen = observer((props: TaskEditScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, contentId, day, periodTag, sectionId, onCancel, onSuccess } = props;
  const theme = useTheme();

  const viewModel = useMemo(
    () => viewModelFactory.createTaskEdit(contentId, day, periodTag, sectionId, onSuccess!, onCancel!),
    [contentId, day, periodTag, sectionId]
  );

  const [displayApplyChangesToLinkedTaskDialog, setDisplayApplyChangesToLinkedTaskDialog] = useState(false);
  const [displayCancelChangesDialog, setDisplayCancelChangesDialog] = useState(false);
  const [displayLongTimeSpanDialog, setDisplayLongTimeSpanDialog] = useState(false);
  const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

  const strings = localizationService.localizedStrings.studyo.contents.taskEdit;

  const { selectedSection } = viewModel;
  const sectionColor =
    selectedSection != null
      ? SectionColors.get(viewModel.getSectionColor(selectedSection))
      : theme.studyo.periods.freePeriodColor;

  const cancelButtonPressed = () => {
    if (viewModel.hasChanges) {
      showCancelConfirmationDialog();
    } else {
      void viewModel.cancel();
    }
  };

  const cancel = () => {
    hideCancelConfirmationDialog();
    void viewModel.cancel();
  };

  const showCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(true);
  };

  const hideCancelConfirmationDialog = () => {
    setDisplayCancelChangesDialog(false);
  };

  const longTimeSpanConfirm = () => {
    hideLongTimeSpanDialog();
    applyOrSave();
  };

  const hideLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(false);
  };

  const showLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(true);
  };

  const applyOrSave = () => {
    if (viewModel.isLinkedToOtherTasks && viewModel.hasEditedLinkedTaskProperties) {
      showApplyToDialog();
    } else {
      startSave();
    }
  };

  const saveDialogClose = (success: boolean) => {
    resetSave();
    if (success) {
      viewModel.closeAllModals();
    }
  };

  const saveButtonPressed = () => {
    if (viewModel.shouldWarnUserForLongTimeSpan) {
      showLongTimeSpanDialog();
    } else {
      applyOrSave();
    }
  };

  const applyChangesToAll = () => {
    hideApplyToDialog();
    startSave();
  };

  const applyChangesOnlyToThisTask = () => {
    viewModel.unlink();
    hideApplyToDialog();
    startSave();
  };

  const hideApplyToDialog = () => {
    setDisplayApplyChangesToLinkedTaskDialog(false);
  };

  const showApplyToDialog = () => {
    setDisplayApplyChangesToLinkedTaskDialog(true);
  };

  const applyChangesToOptions: OptionsDialogElement[] = [
    {
      selectionHandler: () => applyChangesToAll(),
      title: localizationService.localizedStrings.studyo.contents.taskEdit.linkedTaskSaveApplyToAll,
      type: 'normal'
    },
    {
      selectionHandler: () => applyChangesOnlyToThisTask(),
      title: localizationService.localizedStrings.studyo.contents.taskEdit.linkedTaskSaveUnlinkAndApply,
      type: 'normal'
    },
    {
      selectionHandler: () => hideApplyToDialog(),
      title: localizationService.localizedStrings.studyo.contents.taskEdit.linkedTaskSaveCancel,
      type: 'disabled'
    }
  ];

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={cancelButtonPressed}
      disableEnforceFocus
    >
      <DialogHeader
        onClose={cancelButtonPressed}
        title={viewModel.isCreatingTask ? strings.screenTitleCreate : strings.screenTitleEdit}
        color={sectionColor}
        tintColor={
          selectedSection != null
            ? theme.studyo.periods.periodWithSectionTextColor
            : theme.studyo.periods.freePeriodTextColor
        }
        rightButton={{
          icon: (size) => <Check fontSize={size} color="inherit" />,
          color: 'inherit',
          action: saveButtonPressed,
          onlyShowOnExtraSmallScreen: true
        }}
      />

      <TaskEditView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.editCancel} type="cancel" onPress={cancelButtonPressed} />
        <DialogButton title={strings.editSave} type="normal" variant="contained" onPress={saveButtonPressed} />
      </DialogActionButtons>

      <ConfirmDialog
        open={displayCancelChangesDialog}
        title={strings.unsavedChangesTitle}
        description={strings.unsavedChangesDescription}
        cancelTitle={strings.unsavedChangesStayTitle}
        onCancelPress={hideCancelConfirmationDialog}
        confirmTitle={strings.unsavedChangesDiscardTitle}
        onConfirmPress={cancel}
        onClose={hideCancelConfirmationDialog}
      />

      <ConfirmDialog
        open={displayLongTimeSpanDialog}
        title={strings.longTimeSpanAlertTitle}
        description={strings.longTimeSpanAlertDescription}
        cancelTitle={localizationService.localizedStrings.studyo.utils.alertDialogCancel}
        onCancelPress={hideLongTimeSpanDialog}
        confirmTitle={strings.editSave}
        onConfirmPress={longTimeSpanConfirm}
        onClose={hideLongTimeSpanDialog}
      />

      <OptionsDialog
        open={displayApplyChangesToLinkedTaskDialog}
        title={strings.linkedTaskSaveTitle}
        description={strings.linkedTaskSaveDescription}
        elements={applyChangesToOptions}
        onClose={hideApplyToDialog}
      />

      <SaveDialog
        promise={savePromise}
        retryCall={retrySave}
        onClose={saveDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
