import { Button, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { PreparingSchoolViewModel } from '../../viewmodels';

export interface PreparingSchoolViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: PreparingSchoolViewModel;
}

export const PreparingSchoolView = observer((props: PreparingSchoolViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = localizationService.localizedStrings.studyo.preparing;

  return (
    <Stack
      sx={{ ...sx, overflowY: 'auto', overflowX: 'hidden' }}
      className={className}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      p={{ xs: 2, sm: 3 }}
      spacing={2}
    >
      <Stack alignItems="center" spacing={1} px={4}>
        <Typography variant="h5" textAlign="center">
          {viewModel.schoolName}
        </Typography>

        <Typography variant="h6" textAlign="center">
          {strings.title}
        </Typography>

        <Typography variant="body1" textAlign="center">
          {strings.explanation}
        </Typography>

        <Typography variant="body1" textAlign="center">
          {strings.explanation2}
        </Typography>
      </Stack>

      <Button
        sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }}
        variant="contained"
        onClick={() => void viewModel.viewProfiles()}
      >
        {strings.viewProfiles}
      </Button>

      <Divider sx={{ width: 300 }} />

      <Button
        sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }}
        variant="contained-grey"
        color="error"
        onClick={() => void viewModel.logOut()}
      >
        {strings.logOut}
      </Button>
    </Stack>
  );
});
