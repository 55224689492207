import { Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TimelineHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader, CalendarHeaderActionButtonOptions } from '../header';

export interface TimelineHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineHeaderViewModel;
  onScrollToTodayPressed: () => void;

  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
}

export const TimelineHeader = observer(
  ({ sx, viewModel, goToNextWeek, goToPreviousWeek, className }: TimelineHeaderProps) => {
    const { localizationService, navigationService } = useStudyoServices();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const onPlusSignPress = async () => {
      await navigationService.navigateToTaskEditModal(undefined, viewModel.newTaskDueDay, undefined, undefined);
    };

    const actions: CalendarHeaderActionButtonOptions[] = useMemo(() => {
      const actions: CalendarHeaderActionButtonOptions[] = [];
      if (viewModel.canAddTask) {
        actions.push({ kind: 'add', action: () => void onPlusSignPress() });
      }

      // All options are only relevant to the timeline which is not displayed on mobile.
      if (!isExtraSmallScreen) {
        actions.push({ kind: 'options', action: () => void viewModel.displayOptions() });
      }

      return actions;
    }, [viewModel.canAddTask, isExtraSmallScreen]);

    return (
      <CalendarHeader
        sx={sx}
        className={className}
        viewModel={viewModel}
        goToNextPage={() => goToNextWeek()}
        goToPreviousPage={() => goToPreviousWeek()}
        // No sense showing date picker on small screen.
        dateSwitcherContext={!isExtraSmallScreen ? 'timeline' : undefined}
        actionButtons={actions}
        hasContentFilters={viewModel.hasContentFiltersSet}
        onContentFiltersPress={() => void viewModel.displayFiltersOptions()}
        renderDate={(sx) => (
          <Stack sx={sx} alignItems="center" direction="row">
            <Typography variant={isExtraSmallScreen ? 'body1' : 'h6'} fontWeight="500">
              {isExtraSmallScreen
                ? localizationService.localizedStrings.studyo.routes.agendaTimeline
                : viewModel.dateTitle}
            </Typography>
          </Stack>
        )}
      />
    );
  }
);
