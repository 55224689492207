import { SchoolDay } from '@shared/models/calendar';
import { computed, makeObservable, observable } from 'mobx';

export interface YearDayViewModel {
  readonly schoolDay: SchoolDay;

  isSelected: boolean;
  isDisabled: boolean;
  isHighlighted: boolean;
}

export class AppYearDayViewModel implements YearDayViewModel {
  @observable private _isSelected = false;
  @observable private _isDisabled = false;
  @observable private _isHighlighted: boolean;

  constructor(
    public readonly schoolDay: SchoolDay,
    initialIsHighlighted = false
  ) {
    makeObservable(this);
    this._isHighlighted = initialIsHighlighted;
  }

  @computed
  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  @computed
  get isDisabled(): boolean {
    return this._isDisabled;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  @computed
  get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  set isHighlighted(value: boolean) {
    this._isHighlighted = value;
  }
}
