import { EmailOutlined } from '@mui/icons-material';
import { Box, Button, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { withoutPropagation } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { OptionsDialog } from '../../utils';
import { DayAndWeekPeriodInfoBoxProps } from './DayAndWeekPeriodInfoBox';

export const RegularDayAndWeekPeriodInfoBox = observer((props: DayAndWeekPeriodInfoBoxProps) => {
  const { localizationService } = useStudyoServices();
  const { height, backgroundColor, borderColor, sx, className, isCompact, viewModel } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda.daily;

  let sectionDisplayTitle = '';
  if (viewModel.sectionTitle.length > 0) {
    sectionDisplayTitle = isCompact ? viewModel.sectionShortTitle : viewModel.sectionTitle;
  }

  const textColor =
    viewModel.hasSection && !viewModel.isSkipped
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  const startTime = viewModel.periodStartTime.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );
  const endTime = viewModel.periodEndTime.formattedString(
    localizationService.localizedStrings.models.timeFormats.singleDigit
  );

  const marginAndPaddingSpace = 2 + theme.studyo.baseThicknessValue;
  const normalLineHeight = 16 * 1.2;
  const smallLineHeight = 14 * 1.2;

  const threeLineSpace = normalLineHeight * 2 + smallLineHeight + marginAndPaddingSpace;
  const twoLineSpace = normalLineHeight + smallLineHeight + marginAndPaddingSpace;
  const oneLineSpace = normalLineHeight + marginAndPaddingSpace;
  const smallOneLineSpace = smallLineHeight + marginAndPaddingSpace;

  const showTeacherName = height > threeLineSpace && viewModel.teacherName.length > 0;

  return (
    <Stack sx={sx} className={className} overflow="hidden">
      <CardActionArea
        onClick={() => viewModel.openNoteEdit()}
        sx={{
          flex: 1,
          p: 0.5,
          justifyContent: height <= twoLineSpace ? 'center' : undefined,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor,
          width: 250,
          color: textColor,
          overflow: 'hidden',
          borderWidth: 1,
          borderColor,
          borderStyle: 'solid',
          borderBottomLeftRadius: 4,
          borderTopLeftRadius: 4
        }}
        component="div"
      >
        <Stack width="100%" height="100%" overflow="hidden" spacing="2px">
          {height >= smallOneLineSpace && (
            <Stack direction="row" alignItems={height > twoLineSpace ? 'baseline' : 'center'} spacing={1}>
              <Typography
                flex={1}
                variant={height < oneLineSpace ? 'body2' : 'body1'}
                fontWeight="600"
                color="inherit"
                noWrap
                lineHeight={`${normalLineHeight}px`}
              >
                {sectionDisplayTitle}
              </Typography>

              <Typography
                variant="subtitle2"
                color="inherit"
                noWrap
                lineHeight={`${smallLineHeight}px`}
              >{`${startTime} - ${endTime}`}</Typography>
            </Stack>
          )}

          {height >= threeLineSpace && (
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography flex={1} variant="body2" noWrap color="inherit" lineHeight={`${smallLineHeight}px`}>
                {viewModel.sectionNumber}
              </Typography>

              <Typography variant="body2" noWrap color="inherit" lineHeight={`${smallLineHeight}px`}>
                {viewModel.courseOrdinal}
              </Typography>
            </Stack>
          )}

          {height >= twoLineSpace && <Box flex={1} />}

          {height >= twoLineSpace && (
            <Stack direction="row" alignItems="baseline">
              {showTeacherName &&
                (viewModel.teacherHasEmail ? (
                  <Button
                    size="small"
                    color="inherit"
                    startIcon={<EmailOutlined />}
                    sx={{ height: normalLineHeight }}
                    onClick={withoutPropagation(() => viewModel.sendEmailToTeacher())}
                  >
                    {viewModel.teacherName}
                  </Button>
                ) : (
                  <Typography variant="body2" noWrap color="inherit" lineHeight={`${smallLineHeight}px`}>
                    {viewModel.teacherName}
                  </Typography>
                ))}

              <Box flex={1} />

              <Typography
                variant="body2"
                noWrap
                color="inherit"
                lineHeight={`${smallLineHeight}px`}
                flex={1}
                textAlign="end"
              >
                {viewModel.roomName}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardActionArea>

      {viewModel.showTeacherEmailSelection && (
        <OptionsDialog
          open={viewModel.showTeacherEmailSelection}
          title={strings.teacherEmailMenuTitle}
          description={strings.teacherEmailMenuMessage}
          onCancel={() => viewModel.hideTeacherSelection()}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          elements={viewModel.teacherSelectionElements}
        />
      )}
    </Stack>
  );
});
