import { TaskIconPublishKind } from '@shared/components/contents';
import { ContentDefinitionUtils, DateUtils } from '@shared/components/utils';
import { ContentStepModel, EditableContentDefinition } from '@shared/models/content';
import { ContentIcon, ContentState, ContentWorkloadLevel, Day, Integration } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsEventActions,
  StudyoAnalyticsService
} from '../../../services';
import { getStepViewPadding } from './Constants';
import { TimelineContentState } from './TimelineContentState';
import { TimelineIconType } from './TimelineIconType';
import { TimelineUtils } from './TimelineUtils';

export interface TimelineCollectionViewItemViewModel {
  readonly contentState: ContentState;
  readonly contentIsUnread: boolean;
  readonly contentTaskIconPublishKind: TaskIconPublishKind;
  readonly contentTitle: string;
  readonly contentIcon: ContentIcon;
  readonly timelineState: TimelineContentState;
  readonly externalSource?: Integration;
  readonly isPrivate: boolean;
  readonly steps: ContentStepModel[];
  readonly allSteps: ContentStepModel[];
  readonly workloadLevel: ContentWorkloadLevel;
  readonly hasPublishError: boolean;

  readonly leftmostDay: Day;
  readonly isSingleDay: boolean;
  readonly shouldDisplayAssignedIcon: boolean;
  readonly shouldDisplayPlannedIcon: boolean;
  readonly shouldDisplayClippedPlannedIcon: boolean;
  readonly shouldDisplayAssignmentLine: boolean;
  readonly shouldDisplayDottedAssignmentLine: boolean;
  readonly shouldDisplayDueLine: boolean;
  readonly shouldDisplayAssignedTitle: boolean;
  readonly shouldDisplayPlannedTitle: boolean;
  readonly shouldDisplayAssignedPlannedTitle: boolean;
  readonly shouldEnableDoubleClickOnAssignedIcon: boolean;

  readonly canChangePlannedDay: boolean;
  readonly canCompleteTask: boolean;

  componentWidth: (dayWidth: number) => number;
  componentPositionToLeft: (type: TimelineIconType, dayWidth: number) => number;
  stepViewPositionToLeft: (step: ContentStepModel, dayWidth: number) => number;

  didClickOnItem: () => void;
  didDoubleClickOnAssignedIcon: () => void;
  didDoubleClickOnDueIcon: () => void;
  didDoubleClickOnPlannedIcon: () => void;
  didDoubleClickOnClippedPlannedIcon: () => void;
}

export class AppTimelineCollectionViewItemViewModel implements TimelineCollectionViewItemViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _data: AccountData,
    private readonly _contentId: string,
    private readonly _startDate: Day,
    private readonly _endDate: Day
  ) {
    makeObservable(this);
  }

  @computed
  private get content() {
    return this._data.contentsById.get(this._contentId)!;
  }

  @computed
  private get showAssignmentDay(): boolean {
    return this._preferences.timelineShowAssignmentDay;
  }

  @computed
  private get limitTimelineWeeks(): boolean {
    return this._preferences.timelineLimitWeeks;
  }

  @computed
  get leftmostDay(): Day {
    const day = this.showAssignmentDay
      ? this.content.assignmentDay
      : _.chain(this.content.steps.filter((s) => !s.completed).map((s) => s.date))
          .compact()
          .concat(this.content.plannedDay)
          .minBy((d) => d.asDateString)
          .value();

    if (this.limitTimelineWeeks && day.dayCountUntil(this.content.dueDay) > 20) {
      return this.content.dueDay.substractDays(20);
    }

    return day;
  }

  @computed
  private get daySpan(): number {
    return DateUtils.numberOfDaysBetween(this.content.dueDay, this.leftmostDay) + 1;
  }

  @computed
  get contentState(): ContentState {
    return this.content.state;
  }

  @computed
  get contentIsUnread() {
    return this.content.isUnread;
  }

  @computed
  get contentTaskIconPublishKind() {
    return ContentDefinitionUtils.getTaskIconPublishKind(this.content, this._data.sectionsById, this._data.config);
  }

  @computed
  get contentIcon() {
    return this.content.icon;
  }

  @computed
  get timelineState(): TimelineContentState {
    return TimelineUtils.getTimelineStateForContent(this.content);
  }

  @computed
  get externalSource(): Integration | undefined {
    return undefined;
  }

  @computed
  get isPrivate() {
    return this.content.isPrivate;
  }

  @computed
  get allSteps() {
    return this.content.steps;
  }

  @computed
  get steps() {
    return _.filter(this.content.notCompletedSteps, (step) => step.date != null);
  }

  @computed
  get workloadLevel() {
    return this.content.workloadLevel;
  }

  @computed
  get hasPublishError() {
    return this.content.publishTarget?.status === 'publish-error';
  }

  @computed
  get isSingleDay() {
    return this.daySpan === 1;
  }

  @computed
  get shouldDisplayAssignedIcon() {
    return (
      !this.isSingleDay &&
      (this.content.isMaster || (this.showAssignmentDay && this.content.assignmentDay.isSameOrAfter(this.leftmostDay)))
    );
  }

  @computed
  get shouldDisplayPlannedIcon() {
    const { assignmentDay, plannedDay, dueDay, isMaster } = this.content;

    if (this.contentState !== 'active') {
      return false;
    }

    return (
      !isMaster &&
      !dueDay.isSame(plannedDay) &&
      !(this.showAssignmentDay && assignmentDay.isSame(plannedDay)) &&
      plannedDay.isSameOrAfter(this.leftmostDay)
    );
  }

  @computed
  get shouldDisplayClippedPlannedIcon() {
    // Represents both the assignment and planned days being before the leftmost day.
    // That's why it's displayed even if master task.
    return !this.shouldDisplayPlannedIcon && this.content.plannedDay.isBefore(this.leftmostDay);
  }

  @computed
  get shouldDisplayAssignmentLine() {
    if (!this.showAssignmentDay) {
      // Steps before the planned day.
      return !this.leftmostDay.isSame(this.content.plannedDay);
    } else if (this.contentState !== 'active') {
      return true;
    }

    return !this.content.assignmentDay.isSame(this.content.plannedDay);
  }

  @computed
  get shouldDisplayDottedAssignmentLine() {
    return (
      !this.shouldDisplayAssignedIcon &&
      !this.content.assignmentDay.isSame(this.content.plannedDay) &&
      (this.content.state !== 'completed' || this.showAssignmentDay)
    );
  }

  @computed
  get shouldDisplayDueLine() {
    if (this.contentState !== 'active') {
      return false;
    }

    return !this.content.dueDay.isSame(this.content.plannedDay);
  }

  @computed
  get shouldDisplayAssignedTitle() {
    if (!this.showAssignmentDay) {
      return false;
    } else if (DateUtils.dayIsWithin(this.leftmostDay, this._startDate, this._endDate)) {
      return this.deltaLeftmostPlanned > 5;
    } else {
      return false;
    }
  }

  @computed
  get shouldDisplayPlannedTitle() {
    if (this.content.state === 'completed' && !this.showAssignmentDay) {
      return false;
    } else if (DateUtils.dayIsWithin(this.content.plannedDay, this._startDate, this._endDate)) {
      return this.deltaDuePlanned > 5;
    } else if (DateUtils.dayIsWithin(this.leftmostDay, this._startDate, this._endDate)) {
      return this.daySpan > 5;
    } else {
      return false;
    }
  }

  @computed
  get shouldDisplayAssignedPlannedTitle() {
    return this.shouldDisplayAssignedTitle || this.shouldDisplayPlannedTitle;
  }

  @computed
  get shouldEnableDoubleClickOnAssignedIcon() {
    return this.content.assignmentDay.isSame(this.content.plannedDay);
  }

  @computed
  get canChangePlannedDay() {
    return !this._data.isReadOnly && !this.content.isMaster;
  }

  @computed
  get canCompleteTask() {
    return !this._data.isReadOnly;
  }

  @computed
  get contentTitle() {
    return ContentDefinitionUtils.getDisplayTitleForContent(this.content, this._localizationService.localizedStrings);
  }

  componentWidth(dayWidth: number) {
    return dayWidth * (this.daySpan + this.calculatedContentTitleDaySpan);
  }

  componentPositionToLeft(type: TimelineIconType, dayWidth: number) {
    switch (type) {
      case 'assigned':
        return 0;
      case 'planned':
        return this.calculateLeftPositionPlannedIcon(dayWidth);
      case 'due':
        return this.calculateLeftPositionDueIcon(dayWidth);
    }
  }

  stepViewPositionToLeft(step: ContentStepModel, dayWidth: number) {
    const daysDifference = DateUtils.numberOfDaysBetween(step.date!, this.leftmostDay);
    return dayWidth * daysDifference + getStepViewPadding(dayWidth);
  }

  didClickOnItem() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.viewTask });
    void this._data
      .ensureMarkedAsRead(this.content.id)
      .then(() => this._navigationService.navigateToTaskInfoModal(this.content.id));
  }

  didDoubleClickOnAssignedIcon() {
    this.bumpPlannedDay();
  }

  didDoubleClickOnDueIcon() {
    const editableCD = new EditableContentDefinition(this.content, false);
    editableCD.toggleState();
    void this._data.createOrUpdateContent(editableCD);
  }

  didDoubleClickOnPlannedIcon() {
    this.bumpPlannedDay();
  }

  didDoubleClickOnClippedPlannedIcon() {
    this.bumpPlannedDay(this.leftmostDay);
  }

  @computed
  private get deltaDuePlanned() {
    return DateUtils.numberOfDaysBetween(this.content.dueDay, this.content.plannedDay);
  }

  @computed
  private get deltaLeftmostPlanned() {
    return DateUtils.numberOfDaysBetween(this.content.plannedDay, this.leftmostDay);
  }

  private calculateLeftPositionPlannedIcon(dayWidth: number) {
    if (this.shouldDisplayPlannedIcon) {
      const nbDays = DateUtils.numberOfDaysBetween(this.content.plannedDay, this.leftmostDay);
      return nbDays * dayWidth;
    } else if (this.content.plannedDay.isSame(this.content.dueDay)) {
      return this.calculateLeftPositionDueIcon(dayWidth);
    } else {
      return 0;
    }
  }

  private calculateLeftPositionDueIcon(dayWidth: number) {
    const nbDays = DateUtils.numberOfDaysBetween(this.content.dueDay, this.leftmostDay);
    return nbDays * dayWidth;
  }

  private bumpPlannedDay(to?: Day) {
    const editableCD = new EditableContentDefinition(this.content, false);
    editableCD.plannedDay = to ?? editableCD.plannedDay.addDays(1);
    editableCD.isUnread = false;

    void this._data.createOrUpdateContent(editableCD);
  }

  @computed
  private get calculatedContentTitleDaySpan() {
    return Math.ceil(this.contentTitle.length / 10.0);
  }
}
