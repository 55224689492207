import { computed, makeObservable, observable } from 'mobx';

export interface CourseSelectionFilterCellViewModel {
  selectedIndex?: number;
  readonly values: string[];
}

export class AppCourseSelectionFilterCellViewModel implements CourseSelectionFilterCellViewModel {
  @observable private _selectedIndex?: number;

  constructor(public values: string[]) {
    makeObservable(this);
  }

  @computed
  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value: number | undefined) {
    this._selectedIndex = value;
  }
}
