import { Box, Stack, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AccountUtils, ContentDefinitionUtils, DoubleClickView } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { ContentDefinitionModel } from '@shared/models/content';
import { ImageService, LocalizationService } from '@shared/resources/services';
import { Draggable } from '@shared/rxp/drag-drop';
import { observer } from 'mobx-react';
import { ReactNode, useMemo } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DisplayableContentViewModel } from '../../viewmodels';
import { DisplayableContentDragData } from './DisplayableContentDragData.ts';

export type DisplayableContentRepresentation = 'icon' | 'icon-title' | 'detailed' | 'inline';

export interface DisplayableContentProps {
  sx?: SxProps;
  className?: string;
  viewModel: DisplayableContentViewModel;
  representation: DisplayableContentRepresentation;
  canBeDragged?: boolean;
  iconSize?: number;
  smallMode?: boolean;
  showUnreadMarker?: boolean;
  iconColor?: string;
  shouldDisplayTitle?: boolean;
  tapHandler?: (content: ContentDefinitionModel) => void;
  doubleTapHandler?: (content: ContentDefinitionModel) => void;
}

export const DisplayableContent = observer((props: DisplayableContentProps) => {
  const { localizationService, imageService } = useStudyoServices();
  const { viewModel, canBeDragged = false, representation, sx, className, doubleTapHandler, tapHandler } = props;
  const theme = useTheme();

  const element = useMemo(() => {
    switch (viewModel.content.kind) {
      case 'task':
        return DisplayableTask(props, theme);

      case 'note':
        return DisplayableNote(props, theme, imageService, localizationService);

      default:
        console.warn('Unexpected ContentKind value. Defaulting to task.');
        return DisplayableTask(props, theme);
    }
  }, [viewModel.content.kind, props, theme]);

  const draggedData: DisplayableContentDragData = {
    contentId: viewModel.content.id,
    sectionId: viewModel.content.sectionId,
    isDraggingAssignment: viewModel.isAssignment
  };

  const canDragContent =
    canBeDragged && viewModel.canEdit && !viewModel.content.isSlave && viewModel.content.externalContent == null;

  const renderTooltip = () => {
    if (viewModel.targetAccounts.length === 0 && viewModel.originalAuthor == null) {
      return undefined;
    }

    const strings = localizationService.localizedStrings.studyo.contents.taskInfo;
    // When more than 10 accounts, we display the first 9 and "N more".
    const more = Math.max(0, viewModel.targetAccounts.length - 10);
    const accounts = more > 0 ? viewModel.targetAccounts.slice(0, 10) : viewModel.targetAccounts;

    return (
      <Stack>
        {viewModel.originalAuthor && (
          <Typography variant="caption">
            {strings.fromTooltip}
            {AccountUtils.getDisplayFirstLastName(viewModel.originalAuthor)}
          </Typography>
        )}
        {accounts.length > 0 && (
          <>
            <Typography variant="caption" fontWeight="medium">
              {strings.taskPublishedTooltipTitle}
            </Typography>
            {accounts.map((a) => (
              <Typography key={`published-account-${a.id}`} variant="caption">
                {AccountUtils.getDisplayLastFirstName(a)}
              </Typography>
            ))}
            {more > 0 && <Typography variant="caption">{strings.taskPublishedTooltipMore(more)}</Typography>}
          </>
        )}
      </Stack>
    );
  };

  const renderPreview = () => {
    let element: ReactNode;

    switch (viewModel.content.kind) {
      case 'task':
        element = DisplayableTask(props, theme);
        break;

      case 'note':
        element = DisplayableNote(props, theme, imageService, localizationService);
        break;

      default:
        console.warn('Unexpected ContentKind value. Defaulting to task.');
        element = DisplayableTask(props, theme);
        break;
    }

    const headerColor =
      viewModel.sectionColor != null
        ? SectionColors.get(viewModel.sectionColor)!
        : theme.studyo.periods.freePeriodColor;

    const headerTextColor =
      viewModel.sectionColor != null
        ? theme.studyo.periods.periodWithSectionTextColor
        : theme.studyo.periods.freePeriodTextColor;

    return (
      <Stack sx={{ backgroundColor: theme.studyo.contents.displayableDragPreviewBackgroundColor }} overflow="hidden">
        <Stack
          direction="row"
          flexShrink={0}
          sx={{ backgroundColor: headerColor }}
          p={0.5}
          alignItems="center"
          overflow="hidden"
        >
          <Typography flex={1} variant="subtitle2" color={headerTextColor} sx={{ userSelect: 'none' }} noWrap>
            {viewModel.sectionTitle}
          </Typography>
        </Stack>

        <Box flex={1} sx={{ overflow: 'hidden' }} p={0.5}>
          {element}
        </Box>
      </Stack>
    );
  };

  const onTapHandler = () => {
    if (tapHandler != null) {
      tapHandler(viewModel.content);
    }
  };

  const onDoubleTapHandler = () => {
    if (doubleTapHandler != null) {
      doubleTapHandler(viewModel.content);
    }
  };

  return (
    <Draggable
      isDraggable={canDragContent}
      sx={{ overflow: 'hidden', width: '100%', ...sx }}
      className={className}
      renderPreview={renderPreview}
      data={draggedData}
      type="content"
    >
      <DoubleClickView
        onPress={tapHandler != null ? onTapHandler : undefined}
        onDoublePress={doubleTapHandler != null && viewModel.canEdit ? onDoubleTapHandler : undefined}
        propagateEvent={false}
        sx={{ flex: 1, overflow: representation === 'icon' ? 'visible' : 'hidden' }}
      >
        <Tooltip title={renderTooltip()} placement="top-start">
          {element}
        </Tooltip>
      </DoubleClickView>
    </Draggable>
  );
});

function isUnread(props: DisplayableContentProps) {
  return props.viewModel.content.isUnread && props.showUnreadMarker === true;
}

function taskIconPublishKind(props: DisplayableContentProps) {
  return props.viewModel.taskIconPublishKind;
}

function DisplayableTask(props: DisplayableContentProps, theme: Theme) {
  switch (props.representation) {
    case 'icon':
      return DisplayableTask_Icon(props, theme);

    case 'icon-title':
      return DisplayableTask_IconTitle(props, theme);

    case 'detailed':
      return DisplayableTask_Detailed(props, theme);

    case 'inline':
      return DisplayableTask_Inline(props, theme);

    default:
      console.warn('Unexpected DisplayableContentRepresentation value. Defaulting to icon.');
      return DisplayableTask_IconTitle(props, theme);
  }
}

function DisplayableTask_Icon(props: DisplayableContentProps, theme: Theme) {
  const { viewModel, iconColor, iconSize = 0, smallMode = false } = props;
  const { content } = viewModel;

  const externalSource = content.externalContent != null ? content.externalContent.sourceIntegration : undefined;

  return (
    <Box display="flex" flexDirection="column" flex={1} alignItems="center" overflow="visible">
      {smallMode && GetSmallModeIcon(iconColor, theme)}

      {!smallMode && (
        <TaskIcon
          icon={content.icon}
          state={content.state}
          squareSize={iconSize}
          isAssignment={viewModel.isAssignment}
          isPrivate={content.isPrivate}
          isUnread={isUnread(props)}
          publishedKind={taskIconPublishKind(props)}
          externalSource={externalSource}
          workloadLevel={content.workloadLevel}
          hasSteps={content.steps.length > 0}
          hasPublishError={viewModel.hasPublishError}
        />
      )}
    </Box>
  );
}

function DisplayableTask_IconTitle(props: DisplayableContentProps, theme: Theme) {
  const { viewModel, iconSize = 0, shouldDisplayTitle, tapHandler, doubleTapHandler, smallMode = false } = props;
  const { content } = viewModel;
  const color = GetContentColor(props, theme);
  const externalSource = content.externalContent != null ? content.externalContent.sourceIntegration : undefined;
  const hasAction = tapHandler != null || doubleTapHandler != null;
  const showTitle = shouldDisplayTitle ?? true;

  return (
    <Stack flex={1} alignItems={'center'} overflow="visible" spacing={0.5}>
      {smallMode && GetSmallModeIcon(color, theme)}

      {!smallMode && (
        <TaskIcon
          icon={content.icon}
          state={content.state}
          squareSize={iconSize}
          isAssignment={viewModel.isAssignment}
          isPrivate={content.isPrivate}
          isUnread={isUnread(props)}
          publishedKind={taskIconPublishKind(props)}
          externalSource={externalSource}
          workloadLevel={content.workloadLevel}
          hasSteps={content.steps.length > 0}
          hasPublishError={viewModel.hasPublishError}
        />
      )}

      {showTitle && (
        <Typography
          variant="caption"
          lineHeight={`${12 * 1.2}px`}
          textAlign="center"
          color={viewModel.isAssignment ? theme.studyo.contents.icons.assignmentColor : GetTextColor(props, theme)}
          sx={{ userSelect: hasAction ? 'none' : undefined }}
        >
          {viewModel.displayTitle}
        </Typography>
      )}
    </Stack>
  );
}

function DisplayableTask_Detailed(props: DisplayableContentProps, theme: Theme) {
  const {
    viewModel,
    shouldDisplayTitle,
    tapHandler,
    doubleTapHandler,
    iconColor,
    iconSize = 0,
    smallMode = false
  } = props;
  const { content } = viewModel;

  const externalSource = content.externalContent != null ? content.externalContent.sourceIntegration : undefined;
  const hasAction = tapHandler != null || doubleTapHandler != null;
  const textColor = GetTextColor(props, theme);
  const notes = ContentDefinitionUtils.getDisplayedNotes(content);
  const showTitle = shouldDisplayTitle ?? true;

  return (
    <Stack direction="row" flex={1} alignItems={smallMode ? 'center' : 'top'} spacing={1}>
      {smallMode && GetSmallModeIcon(iconColor, theme)}

      {!smallMode && (
        <TaskIcon
          icon={content.icon}
          state={content.state}
          squareSize={iconSize}
          isAssignment={viewModel.isAssignment}
          isPrivate={content.isPrivate}
          isUnread={isUnread(props)}
          publishedKind={taskIconPublishKind(props)}
          externalSource={externalSource}
          workloadLevel={content.workloadLevel}
          hasSteps={content.steps.length > 0}
          hasPublishError={viewModel.hasPublishError}
        />
      )}

      {showTitle && (
        <Stack flex={1} spacing={0.5} overflow="hidden">
          <Typography
            variant="subtitle2"
            noWrap
            color={textColor}
            lineHeight={`${14 * 1.2}px`}
            sx={{ userSelect: hasAction ? 'none' : undefined }}
          >
            {viewModel.displayTitle}
          </Typography>

          {!smallMode && (
            <Typography
              variant="caption"
              lineHeight={`${12 * 1.2}px`}
              color={textColor}
              sx={{ userSelect: hasAction ? 'none' : undefined, whiteSpace: 'pre-line' }}
            >
              {notes}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
}

function DisplayableTask_Inline(props: DisplayableContentProps, theme: Theme) {
  // Don't do anything fancy, just reuse the 'detailed' representation.
  // Eventually, we could display attachments in the 'inline' representation,
  // for example a video player or image viewer.
  return DisplayableTask_Detailed(props, theme);
}

function DisplayableNote(
  props: DisplayableContentProps,
  theme: Theme,
  imageService: ImageService,
  localizationService: LocalizationService
) {
  switch (props.representation) {
    case 'icon':
      return DisplayableNote_Icon(props, theme, imageService);

    case 'icon-title':
      return DisplayableNote_IconTitle(props, theme, imageService, localizationService);

    case 'detailed':
      return DisplayableNote_Detailed(props, theme, imageService);

    case 'inline':
      return DisplayableNote_Inline(props, theme);

    default:
      console.warn('Unexpected DisplayableContentRepresentation value. Defaulting to icon-title.');
      return DisplayableNote_IconTitle(props, theme, imageService, localizationService);
  }
}

function DisplayableNote_Icon(props: DisplayableContentProps, theme: Theme, imageService: ImageService) {
  const { iconSize = 0, smallMode = false } = props;
  const noteIcon = imageService.studyoImages.tasks.regularIcons.note;

  return (
    <Box flex={1} display="flex" flexDirection="column" alignItems="center">
      {smallMode && GetSmallModeIcon(undefined, theme)}
      {!smallMode && <TaskIcon customIcon={noteIcon} squareSize={iconSize} />}
    </Box>
  );
}

function DisplayableNote_IconTitle(
  props: DisplayableContentProps,
  theme: Theme,
  imageService: ImageService,
  localizationService: LocalizationService
) {
  const { tapHandler, doubleTapHandler, iconSize = 0, smallMode = false } = props;

  const noteIcon = imageService.studyoImages.tasks.regularIcons.note;
  const hasAction = tapHandler != null || doubleTapHandler != null;

  return (
    <Stack flex={1} alignItems="center" spacing={0.5}>
      {smallMode && GetSmallModeIcon(undefined, theme)}
      {!smallMode && <TaskIcon customIcon={noteIcon} squareSize={iconSize} />}

      <Typography
        variant="caption"
        lineHeight={`${12 * 1.2}px`}
        textAlign="center"
        sx={{ userSelect: hasAction ? 'none' : undefined }}
      >
        {localizationService.localizedStrings.studyo.contents.notesTitle}
      </Typography>
    </Stack>
  );
}

function DisplayableNote_Detailed(props: DisplayableContentProps, theme: Theme, imageService: ImageService) {
  const { viewModel, tapHandler, doubleTapHandler, iconSize = 0, smallMode = false } = props;
  const { content } = viewModel;

  const noteIcon = imageService.studyoImages.tasks.regularIcons.note;
  const hasAction = tapHandler != null || doubleTapHandler != null;
  const notes = ContentDefinitionUtils.getDisplayedNotes(content);

  return (
    <Stack direction="row" flex={1} alignItems={smallMode ? 'center' : 'top'} spacing={1} overflow="hidden">
      {smallMode && GetSmallModeIcon(undefined, theme)}
      {!smallMode && <TaskIcon customIcon={noteIcon} state={content.state} squareSize={iconSize} />}

      <Typography
        variant="caption"
        lineHeight={`${12 * 1.2}px`}
        flex={1}
        sx={{ userSelect: hasAction ? 'none' : undefined, whiteSpace: 'pre-line' }}
      >
        {notes}
      </Typography>
    </Stack>
  );
}

function DisplayableNote_Inline(props: DisplayableContentProps, theme: Theme) {
  const { viewModel, tapHandler, doubleTapHandler } = props;
  const { content } = viewModel;

  const hasAction = tapHandler != null || doubleTapHandler != null;
  const notes = ContentDefinitionUtils.getDisplayedNotes(content);

  return (
    <Stack
      flex={1}
      padding={0.5}
      sx={{ backgroundColor: theme.studyo.contents.icons.notesBackgroundColor }}
      overflow="hidden"
      justifyContent="flex-start"
    >
      <Typography flex={1} variant="body2" sx={{ userSelect: hasAction ? 'none' : undefined, whiteSpace: 'pre-line' }}>
        {notes}
      </Typography>
    </Stack>
  );
}

function GetContentColor(props: DisplayableContentProps, theme: Theme): string | undefined {
  const { viewModel } = props;
  const { content } = viewModel;

  if (ContentDefinitionUtils.hasImportantWorkload(content)) {
    return theme.studyo.contents.icons.importantColor;
  } else if (viewModel.isAssignment) {
    return theme.studyo.contents.icons.assignmentColor;
  } else if (content.sectionId === '') {
    return theme.studyo.contents.icons.personalColor;
  }

  return theme.studyo.contents.icons.normalColor;
}

function GetTextColor(props: DisplayableContentProps, theme: Theme): string | undefined {
  const { viewModel } = props;
  const { content, color } = viewModel;

  if (viewModel.isAssignment) {
    return theme.studyo.contents.icons.assignmentColor;
  } else if (content.sectionId === '') {
    return theme.studyo.contents.icons.personalColor;
  } else if (color != null) {
    return SectionColors.get(color);
  }

  return undefined;
}

function GetSmallModeIcon(iconColor: string | undefined, theme: Theme) {
  return (
    <Box
      sx={{
        borderRadius: 5,
        height: 10,
        width: 10,
        backgroundColor: iconColor ?? theme.studyo.contents.icons.normalColor
      }}
    />
  );
}
