import { ConfigState, Day } from '@shared/models/types';
import { Locale } from '../../../services';

export interface ManagedGeneralViewStrings {
  addLinkButtonCaption: string;
  assessmentPlanningCardTitle: string;
  assessmentPlanningGradeLevelColumnTitle: string;
  assessmentPlanningVisibilityDateColumnTitle: string;
  baseAccountEmailHint: string;
  baseAccountEmailLabel: string;
  cancelButtonCaption: string;
  clearButtonCaption: string;
  clearCacheLabel: string;
  commentsFieldLabel: string;
  createCopyButtonLabel: string;
  createDemoCopyLabel: string;
  createDemoCopyMessage: string;
  createDemoCopyTitle: string;
  createNextYearLabel: string;
  dailyThresholdColumnTitle: string;
  datesCardTitle: string;
  defaultDailyThresholdFieldLabel: string;
  defaultWeeklyThresholdFieldLabel: string;
  demoDateFieldLabel: string;
  demoSchoolCheckboxCaption: string;
  editAssessmentPlanningButtonTooltip: string;
  editAssessmentPlanningDialogTitle: string;
  editDatesButtonTooltip: string;
  editDatesDialogTitle: string;
  editIndividualAccount: string;
  editInformationButtonTooltip: string;
  editInformationDialogTitle: string;
  editLinksButtonTooltip: string;
  editLinksDialogTitle: string;
  editThresholdsDialogTitle: string;
  editWorkloadManagerThresholdsButtonTooltip: string;
  endDateFieldLabel: string;
  errorMessage: string;
  expectedStudentCountFieldLabel: string;
  gradeLevelColumnTitle: string;
  gradeLevelSourceTitle: string;
  gradeLevelSourceAssessmentPlanningTitle: string;
  informationCardTitle: string;
  languageLabel: string;
  linkUrlColumnTitle: string;
  linkPositionColumnTitle: string;
  linkTitleColumnTitle: string;
  linksCardTitle: string;
  loadingMessage: string;
  newSchoolLanguageLabel: string;
  newSchoolNameLabel: string;
  newTagLabel: (tag: string) => string;
  noTags: string;
  noTimezone: string;
  okButtonCaption: string;
  onboardingCodeFieldLabel: string;
  powerToolsCardTitle: string;
  purgeDeletedAccountsLabel: string;
  saveButtonCaption: string;
  schoolNameFieldLabel: string;
  schoolTitleFieldLabel: string;
  startDateFieldLabel: string;
  stateFieldLabel: string;
  studentsAccessDate: string;
  tagsLabel: string;
  teachersAccessDate: string;
  timezoneLabel: string;
  uploadFileButtonCaption: string;
  validationResultsCardTitle: string;
  validationResultsNotAvailable: string;
  validationResultsNoErrors: string;
  weeklyThresholdColumnTitle: string;
  workloadManagerThresholdsCardTitle: string;
  workloadManagerThresholdsGradeLevelColumnTitle: string;
  workloadManagerThresholdsDailyThresholdColumnTitle: string;
  workloadManagerThresholdsWeeklyThresholdColumnTitle: string;

  getDemoSchoolLabel: (demoDay?: Day) => string;
  getLocaleDisplayName: (locale: Locale) => string;
  getThresholdHelperText: (disabledThresholdValue: number) => string;
  getConfigStateLabel: (state: ConfigState) => string;
}

export const EnglishManagedGeneralViewStrings: ManagedGeneralViewStrings = {
  addLinkButtonCaption: 'Add Link',
  assessmentPlanningCardTitle: 'Assessment Planning',
  assessmentPlanningGradeLevelColumnTitle: 'Grade Level',
  assessmentPlanningVisibilityDateColumnTitle: 'Visibility Day',
  baseAccountEmailHint: 'A variation of this email will be assigned to each record',
  baseAccountEmailLabel: 'Base email address for accounts',
  cancelButtonCaption: 'Cancel',
  clearButtonCaption: 'Clear',
  clearCacheLabel: 'Remove configuration from cache',
  commentsFieldLabel: 'Comments',
  createCopyButtonLabel: 'Create a copy',
  createDemoCopyLabel: 'Create a Demo copy',
  createDemoCopyMessage:
    'This will create a new demo school, copying the calendar, sections and records from this school, but anonymizing all names. Tasks and notes are removed.',
  createDemoCopyTitle: 'Make a demo copy',
  createNextYearLabel: "Create next year's copy",
  dailyThresholdColumnTitle: 'Daily',
  datesCardTitle: 'Dates',
  defaultDailyThresholdFieldLabel: 'Default Daily Threshold',
  defaultWeeklyThresholdFieldLabel: 'Default Weekly Threshold',
  demoDateFieldLabel: 'Fake "Today" as',
  demoSchoolCheckboxCaption: 'This is a demo school',
  editAssessmentPlanningButtonTooltip: 'Edit the assessment planning dates',
  editAssessmentPlanningDialogTitle: 'Assessment Planning',
  editDatesButtonTooltip: 'Edit the dates',
  editDatesDialogTitle: 'Dates',
  editIndividualAccount: 'Edit individual record',
  editInformationButtonTooltip: 'Edit the information',
  editInformationDialogTitle: 'Information',
  editLinksButtonTooltip: 'Edit the links',
  editLinksDialogTitle: 'Links',
  editThresholdsDialogTitle: 'Workload Thresholds',
  editWorkloadManagerThresholdsButtonTooltip: 'Edit the workload manager thresholds',
  endDateFieldLabel: 'End Date',
  errorMessage: 'An error occurred while loading the data. Please reload.',
  expectedStudentCountFieldLabel: 'Expected number of active students',
  gradeLevelColumnTitle: 'Grade Level',
  gradeLevelSourceTitle: 'Workload Grade Level Source',
  gradeLevelSourceAssessmentPlanningTitle: 'Assessment Planning Grade Level Source',
  informationCardTitle: 'Information',
  languageLabel: 'Default Language',
  linkUrlColumnTitle: 'Link',
  linkPositionColumnTitle: 'Position',
  linkTitleColumnTitle: 'Title',
  linksCardTitle: 'Links',
  loadingMessage: 'Loading data. Please wait…',
  newSchoolLanguageLabel: 'Language for new names',
  newSchoolNameLabel: 'New school name',
  newTagLabel: (tag) => `Add tag ${tag}`,
  noTags: 'None',
  noTimezone: 'Default (Montreal time)',
  okButtonCaption: 'Ok',
  onboardingCodeFieldLabel: 'Onboarding Code',
  powerToolsCardTitle: 'Power Tools',
  purgeDeletedAccountsLabel: 'Remove deleted accounts permanently',
  saveButtonCaption: 'Save',
  schoolNameFieldLabel: 'School Name',
  schoolTitleFieldLabel: 'School Title',
  startDateFieldLabel: 'Start Date',
  stateFieldLabel: 'Status',
  studentsAccessDate: 'Students Access Date',
  tagsLabel: 'Tags',
  teachersAccessDate: 'Teachers Access Date',
  timezoneLabel: 'Timezone',
  uploadFileButtonCaption: 'Upload File',
  validationResultsCardTitle: 'Validation Results',
  validationResultsNotAvailable: 'Validation results are not available.',
  validationResultsNoErrors: 'No validation errors.',
  weeklyThresholdColumnTitle: 'Weekly',
  workloadManagerThresholdsCardTitle: 'Workload Manager Thresholds',
  workloadManagerThresholdsGradeLevelColumnTitle: 'Grade Level',
  workloadManagerThresholdsDailyThresholdColumnTitle: 'Daily Threshold',
  workloadManagerThresholdsWeeklyThresholdColumnTitle: 'Weekly Threshold',

  getDemoSchoolLabel: (demoDay?: Day) =>
    demoDay != null ? `Demo School: ${demoDay.formattedString('MMM d, yyyy')}` : 'Demo School',
  getLocaleDisplayName: getLocaleDisplayName,
  getThresholdHelperText: (disabledThresholdValue) => `Set the values to ${disabledThresholdValue} to disable`,
  getConfigStateLabel: (state) => {
    switch (state) {
      case 'active':
        return 'Active';
      case 'archived':
        return 'Archived';
      case 'preparing':
        return 'Preparing';
    }
  }
};

export const FrenchManagedGeneralViewStrings: ManagedGeneralViewStrings = {
  addLinkButtonCaption: 'Ajouter lien',
  assessmentPlanningCardTitle: 'Planification des évaluations',
  assessmentPlanningGradeLevelColumnTitle: 'Niveau scolaire',
  assessmentPlanningVisibilityDateColumnTitle: 'Date de visibilité',
  baseAccountEmailHint: 'Une variante de ce courriel sera assignée à chaque fiche',
  baseAccountEmailLabel: 'Courriel de base des fiches',
  cancelButtonCaption: 'Annuler',
  clearButtonCaption: 'Effacer',
  clearCacheLabel: 'Retirer de la mémoire tampon',
  commentsFieldLabel: 'Remarques',
  createCopyButtonLabel: 'Créer une copie',
  createDemoCopyLabel: 'Créer une copie Démo',
  createDemoCopyMessage:
    'Ceci va créer une nouvelle école démo, en copiant le calendrier, les groupes-matière et les fiches de cette école, mais en anonymisant tous les noms. Les tâches et notes ne sont pas conservées.',
  createDemoCopyTitle: 'Créer une copie démo',
  createNextYearLabel: 'Créer une copie pour la prochaine année',
  dailyThresholdColumnTitle: 'Quotidien',
  datesCardTitle: 'Dates',
  defaultDailyThresholdFieldLabel: 'Seuil quotidien par défaut',
  defaultWeeklyThresholdFieldLabel: 'Seuil hebdomadaire par défaut',
  demoDateFieldLabel: 'Simuler "Aujourd’hui" comme étant',
  demoSchoolCheckboxCaption: 'Ceci est une école démo',
  editAssessmentPlanningButtonTooltip: 'Éditer les dates de planification des évaluations',
  editAssessmentPlanningDialogTitle: 'Planification des évaluations',
  editDatesButtonTooltip: 'Éditer les dates',
  editDatesDialogTitle: 'Dates',
  editIndividualAccount: 'Modifier la fiche individuelle',
  editInformationButtonTooltip: 'Éditer les informations',
  editInformationDialogTitle: 'Information',
  editLinksButtonTooltip: 'Éditer les liens',
  editLinksDialogTitle: 'Liens',
  editThresholdsDialogTitle: 'Seuils pour la charge de travail',
  editWorkloadManagerThresholdsButtonTooltip: 'Éditer les seuils pour la charge de travail',
  endDateFieldLabel: 'Date de fin',
  errorMessage: 'Une erreur est survenue lors du chargement des données. Merci de recharger.',
  expectedStudentCountFieldLabel: 'Nombre d’élèves actifs prévu',
  gradeLevelColumnTitle: 'Niveau scolaire',
  gradeLevelSourceTitle: 'Source du niveau pour charge scolaire',
  gradeLevelSourceAssessmentPlanningTitle: 'Source du niveau pour planification des évaluations',
  informationCardTitle: 'Information',
  languageLabel: 'Langue par défaut',
  linkUrlColumnTitle: 'Lien',
  linkPositionColumnTitle: 'Position',
  linkTitleColumnTitle: 'Titre',
  linksCardTitle: 'Liens',
  loadingMessage: 'Chargement des données. Merci de patienter…',
  newSchoolLanguageLabel: 'Langue des nouveaux noms',
  newSchoolNameLabel: 'Nom de la nouvelle école',
  newTagLabel: (tag) => `Ajouter ${tag}`,
  noTags: 'Aucune',
  noTimezone: 'Par défaut (heure de Montréal)',
  okButtonCaption: 'Ok',
  onboardingCodeFieldLabel: 'Code d’école',
  powerToolsCardTitle: 'Outils avancés',
  purgeDeletedAccountsLabel: 'Retirer les fiches effacées de façon permanente',
  saveButtonCaption: 'Sauvegarder',
  schoolNameFieldLabel: 'Nom de l’école',
  schoolTitleFieldLabel: 'Titre',
  startDateFieldLabel: 'Date de début',
  stateFieldLabel: 'État',
  studentsAccessDate: 'Date disponible aux élèves',
  tagsLabel: 'Étiquettes',
  teachersAccessDate: 'Date disponible aux enseignants',
  timezoneLabel: 'Fuseau horaire',
  uploadFileButtonCaption: 'Téléverser fichier',
  validationResultsCardTitle: 'Résultats de la validation',
  validationResultsNotAvailable: 'Résultats non disponibles.',
  validationResultsNoErrors: 'Aucune erreur de validation.',
  weeklyThresholdColumnTitle: 'Hebdomadaire',
  workloadManagerThresholdsCardTitle: 'Seuils pour la charge de travail',
  workloadManagerThresholdsGradeLevelColumnTitle: 'Niveau scolaire',
  workloadManagerThresholdsDailyThresholdColumnTitle: 'Seuil quotidien',
  workloadManagerThresholdsWeeklyThresholdColumnTitle: 'Seuil hebdomadaire',

  getDemoSchoolLabel: (demoDay?: Day) =>
    demoDay != null ? `École démo: ${demoDay.formattedString('d MMM yyyy')}` : 'École démo',
  getLocaleDisplayName: getLocaleDisplayName,
  getThresholdHelperText: (disabledThresholdValue) =>
    `Affecter les valeurs à ${disabledThresholdValue} pour les désactiver`,
  getConfigStateLabel: (state) => {
    switch (state) {
      case 'active':
        return 'Active';
      case 'archived':
        return 'Archivée';
      case 'preparing':
        return 'En préparation';
    }
  }
};

function getLocaleDisplayName(locale: Locale): string {
  switch (locale) {
    case 'en':
      return 'English';
    case 'fr':
      return 'Français';
  }
}
