import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AnalyticsConfigInfo,
  AnalyticsEvent,
  AnalyticsPage,
  AnalyticsService,
  AnalyticsUserInfo
} from '@shared/services/analytics';

export interface ApplicationInsightsEventProperties {
  eventCategory: string;
  eventLabel?: string;
}

export abstract class WebAppInsightsAnalyticsService<
  TPage extends AnalyticsPage<string>,
  TEvent extends AnalyticsEvent<string>,
  TPageProperties extends object,
  TEventProperties extends ApplicationInsightsEventProperties,
  TErrorProperties extends object
> implements AnalyticsService<TPage, TEvent>
{
  protected _userInfo: AnalyticsUserInfo | undefined;
  protected _configInfo: AnalyticsConfigInfo | undefined;

  protected constructor(private readonly _applicationInsightsClient: ApplicationInsights) {
    this._applicationInsightsClient.loadAppInsights();
  }

  setUserInfo(info: AnalyticsUserInfo) {
    this._userInfo = info;
  }

  clearUserInfo() {
    this._userInfo = undefined;
  }

  setConfigInfo(info: AnalyticsConfigInfo) {
    this._configInfo = info;
  }

  clearConfigInfo() {
    this._configInfo = undefined;
  }

  trackPage(page: TPage): void {
    // Build the properties to send with the event
    const properties = this.buildPageProperties(page);

    // Ensure the authenticated context is properly set
    this.ensureAuthenticatedUserContext();

    // Send the telemetry event to Application Insights
    this.executeActionWithLogging(
      () =>
        this._applicationInsightsClient.trackPageView({
          name: page.name,
          properties: properties
        }),
      'Application Insights - Tracking Page',
      () => console.log('Name:', page.name),
      properties
    );
  }

  trackEvent(event: TEvent): void {
    // Build the properties to send with the event
    const properties = this.buildEventProperties(event);

    // Set the properties that are specific to events
    properties.eventCategory = event.action.category;
    properties.eventLabel = event.label;

    // Ensure the authenticated context is properly set
    this.ensureAuthenticatedUserContext();

    // Send the telemetry event to Application Insights
    this.executeActionWithLogging(
      () =>
        this._applicationInsightsClient.trackEvent({
          name: event.action.name,
          properties: properties
        }),
      'Application Insights - Tracking Event',
      () => {
        console.log('Action:', event.action.name);
        console.log('Category:', event.action.category);
        console.log('Label:', event.label);
      },
      properties
    );
  }

  protected abstract buildPageProperties(page: TPage): TPageProperties;
  protected abstract buildEventProperties(event: TEvent): TEventProperties;
  protected abstract buildErrorProperties(error: Error): TErrorProperties;

  private ensureAuthenticatedUserContext() {
    if (this._userInfo != null) {
      this._applicationInsightsClient.setAuthenticatedUserContext(this._userInfo.userId, this._configInfo?.accountId);
    } else {
      this._applicationInsightsClient.clearAuthenticatedUserContext();
    }
  }

  private executeActionWithLogging(
    actionCallback: () => unknown,
    logTitle: string,
    logInformationCallback?: () => void,
    actionProperties?: unknown
  ): void {
    try {
      console.group(logTitle);

      if (logInformationCallback != null) {
        logInformationCallback();
      }

      if (actionProperties) {
        console.log(`Custom properties:`, actionProperties);
      }

      actionCallback();

      console.log(`Successfully completed!`);
    } catch (error) {
      console.log(`Failed with error:`, error);
    } finally {
      console.groupEnd();
    }
  }
}
