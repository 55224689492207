import { Box, Divider, Stack, SxProps, useTheme } from '@mui/material';
import { Observer, observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DataPresenter, MonthDay, MonthDayOfWeek, MonthHeader } from '../../components';
import { MonthlyViewModel } from '../../viewmodels';

export interface MonthlyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: MonthlyViewModel;
}

export const MonthView = observer((props: MonthlyViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();

  const goToNextMonth = () => {
    viewModel.goToNextMonth();
  };

  const goToPreviousMonth = () => {
    viewModel.goToPreviousMonth();
  };

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data, viewModel.preferences]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => (
        <Observer>
          {() => (
            <Stack width="100%" height="100%" overflow="hidden">
              <Box>
                <MonthHeader
                  viewModel={viewModel.header}
                  goToNextPage={goToNextMonth}
                  goToPreviousPage={goToPreviousMonth}
                />
              </Box>

              <Divider />
              <MonthDayOfWeek />
              <Divider />

              <Stack
                flex={1}
                p={1}
                sx={{ backgroundColor: theme.studyo.agenda.month.monthBackgroundColor }}
                overflow="auto"
              >
                {viewModel.days.map((dayRow, rowIndex) => (
                  <Box display="flex" flexGrow={1} flexBasis={0} key={`row-${rowIndex}`} overflow="hidden">
                    {dayRow.map((day, columnIndex) => (
                      <Box
                        width="calc((100% / 7) - 4px)"
                        p="2px"
                        key={`row-${rowIndex}-column-${columnIndex}`}
                        flexShrink={0}
                      >
                        <MonthDay sx={{ width: '100%', height: '100%' }} viewModel={day} />
                      </Box>
                    ))}
                  </Box>
                ))}
              </Stack>
            </Stack>
          )}
        </Observer>
      )}
    />
  );
});
