import { Box, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Time } from '@shared/models/types';
import { times } from 'lodash';
import { Observer, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DayAndWeekConstants } from '../shared';
import { CurrentTimeIndicator } from './CurrentTimeIndicator';

const hours = times(23).map((v, i) => Time.create({ hour: i + 1, minute: 0 }));

export interface TimesColumnProps {
  sx?: SxProps;
  className?: string;
  pointsPerHour: number;
}

export const TimesColumn = observer((props: TimesColumnProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, pointsPerHour } = props;
  const [currentTime, setCurrentTime] = useState(Time.create({ hour: 8, minute: 0 }));
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = localizationService.localizedStrings.models.timeFormats;

  useEffect(() => {
    const timer = setInterval(updateTime, 1000);
    return () => clearTimeout(timer);
  }, []);

  const updateTime = () => {
    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();

    if (currentTime.hour != hour || currentTime.minute != minute) {
      setCurrentTime(Time.create({ hour, minute }));
    }
  };

  const currentTimeComponent = () => {
    const currentTimeInMinutes = currentTime.hour * 60 + currentTime.minute;
    const currentOffset = (pointsPerHour / 60) * currentTimeInMinutes - 12;
    // The -12 is used to align the hour with the corresponding lines of the agenda

    return <Observer>{() => <CurrentTimeIndicator offset={currentOffset} time={currentTime} />}</Observer>;
  };

  return (
    <Box
      sx={sx}
      className={className}
      width={isExtraSmallScreen ? DayAndWeekConstants.timesColumnCompactWidth : DayAndWeekConstants.timesColumnWidth}
      justifyContent="center"
      position="relative"
    >
      {hours.map((v, i) => (
        <Box
          key={`time-label-${i}`}
          sx={{
            px: 0.5,
            position: 'absolute',
            left: 0,
            right: 0,
            alignItems: 'center',
            top: pointsPerHour * (i + 1) - 6
          }}
        >
          <Typography fontSize={10} variant="caption" textAlign={isExtraSmallScreen ? 'center' : undefined} noWrap>
            {v.formattedString(isExtraSmallScreen ? strings.compact : strings.singleDigit)}
          </Typography>
        </Box>
      ))}

      {currentTimeComponent()}
    </Box>
  );
});
