import { Day } from '@shared/models/types';
import { action, computed, makeObservable, observable } from 'mobx';

export interface UISettingsStore {
  dailyCurrentDay: Day | undefined;
  weeklyCurrentDay: Day | undefined;
  monthlyCurrentDay: Day | undefined;
  timelineCurrentDay: Day | undefined;
  plannerCurrentDay: Day | undefined;
  periodsCurrentDay: Day | undefined;
  isCompletingLogin: boolean;
  hasLoginError: boolean;
  readonly shouldPreventArrowNavigation: boolean;
  preventArrowNavigation: () => void;
  allowArrowNavigation: () => void;

  resetAll: () => void;
}

export class AppUISettingsStore implements UISettingsStore {
  @observable private _dailyCurrentDay: Day | undefined;
  @observable private _weeklyCurrentDay: Day | undefined;
  @observable private _monthlyCurrentDay: Day | undefined;
  @observable private _timelineCurrentDay: Day | undefined;
  @observable private _plannerCurrentDay: Day | undefined;
  @observable private _periodsCurrentDay: Day | undefined;
  @observable private _hasLoginError = false;
  @observable private _isCompletingLogin = false;
  @observable private _preventedArrowNavigation = 0;

  constructor() {
    makeObservable(this);
  }

  @computed
  get dailyCurrentDay(): Day | undefined {
    return this._dailyCurrentDay;
  }

  set dailyCurrentDay(value: Day | undefined) {
    this._dailyCurrentDay = value;
  }

  @computed
  get weeklyCurrentDay(): Day | undefined {
    return this._weeklyCurrentDay;
  }

  set weeklyCurrentDay(value: Day | undefined) {
    this._weeklyCurrentDay = value;
  }

  @computed
  get monthlyCurrentDay(): Day | undefined {
    return this._monthlyCurrentDay;
  }

  set monthlyCurrentDay(value: Day | undefined) {
    this._monthlyCurrentDay = value;
  }

  @computed
  get timelineCurrentDay(): Day | undefined {
    return this._timelineCurrentDay;
  }

  set timelineCurrentDay(value: Day | undefined) {
    this._timelineCurrentDay = value;
  }

  @computed
  get plannerCurrentDay(): Day | undefined {
    return this._plannerCurrentDay;
  }

  set plannerCurrentDay(value: Day | undefined) {
    this._plannerCurrentDay = value;
  }

  @computed
  get periodsCurrentDay(): Day | undefined {
    return this._periodsCurrentDay;
  }

  set periodsCurrentDay(value: Day | undefined) {
    this._periodsCurrentDay = value;
  }

  @computed
  get isCompletingLogin(): boolean {
    return this._isCompletingLogin;
  }

  set isCompletingLogin(value: boolean) {
    this._isCompletingLogin = value;
  }

  @computed
  get hasLoginError(): boolean {
    return this._hasLoginError;
  }

  set hasLoginError(value: boolean) {
    this._hasLoginError = value;
  }

  @computed
  get shouldPreventArrowNavigation(): boolean {
    return this._preventedArrowNavigation > 0;
  }

  @action
  preventArrowNavigation() {
    this._preventedArrowNavigation++;
  }

  @action
  allowArrowNavigation() {
    this._preventedArrowNavigation = Math.max(0, this._preventedArrowNavigation - 1);
  }

  resetAll() {
    // This only resets days.
    this.dailyCurrentDay = undefined;
    this.weeklyCurrentDay = undefined;
    this.monthlyCurrentDay = undefined;
    this.timelineCurrentDay = undefined;
    this.plannerCurrentDay = undefined;
    this.periodsCurrentDay = undefined;
  }
}
