import { Browser } from '@capacitor/browser';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ContentAttachmentModel, EditableContentAttachment } from '@shared/models/content';
import { v4 } from 'uuid';
import { AccountService } from './AccountService';
import { AttachmentManager } from './AttachmentManager';

export class AndroidAttachmentManager implements AttachmentManager {
  constructor(private _accountService: AccountService) {}

  openUrl = (url: string) => {
    void Browser.open({ url });
  };

  openAttachment = (attachment: ContentAttachmentModel) => {
    const url = attachment.externalUrl || attachment.fileUrl;
    void Browser.open({ url });
  };

  takePicture = async () => {
    const photo = await Camera.getPhoto({
      source: CameraSource.Camera,
      resultType: CameraResultType.DataUrl,
      saveToGallery: true
    });

    return await this.uploadAndAttachPhoto(photo);
  };

  openCameraRoll = async () => {
    const photo = await Camera.getPhoto({
      source: CameraSource.Photos,
      resultType: CameraResultType.DataUrl
    });

    return await this.uploadAndAttachPhoto(photo);
  };

  getGoogleDriveFile = () => {
    throw new Error('Selecting Google Drive attachments is not implemented on Android.');
  };

  private async uploadAndAttachPhoto(photo: Photo) {
    const data = this._accountService.displayedAccountData;

    let attachment = EditableContentAttachment.createNew();
    attachment.kind = 'photo';
    attachment.fileName = `${v4()}.${photo.format}`;

    // Getting the URL where we can upload the data.
    const uploadUrl = await data.getUploadURL(attachment);
    attachment.fileUrl = uploadUrl.downloadUrl;

    // Upload the file data to the url we got from the backend.
    await fetch(uploadUrl.uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': uploadUrl.contentType
      },
      body: this.dataUrlToBlob(photo.dataUrl!)
    });

    // By calling updateContentAttachment, the backend will generate the thumbnail for the
    // attachment. The url will be set in the attachment we get as a response.
    const response = await data.updateContentAttachment(attachment);
    attachment = new EditableContentAttachment(response, true);

    return attachment;
  }

  // See https://stackoverflow.com/a/27781331
  // Important: do not use the XMLHttpRequest-based solution, as it is not compatible with Safari
  private dataUrlToBlob(dataUrl: string) {
    if (typeof dataUrl !== 'string') {
      throw new Error('Invalid argument: dataURI must be a string');
    }

    const newDataUrl = dataUrl.split(',');
    const type = newDataUrl[0].split(':')[1].split(';')[0],
      byteString = atob(newDataUrl[1]),
      byteStringLength = byteString.length,
      arrayBuffer = new ArrayBuffer(byteStringLength),
      intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStringLength; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], {
      type
    });
  }
}
