import { AccountSummary } from '@shared/models/config';
import { OnboardingCodeKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import { AccountService, NavigationService, StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../services';

export interface UseCodeViewModel {
  readonly email: string;
  readonly isInitialConfig: boolean;
  readonly errorMessage: string;

  code: string;
  readonly codeKind: OnboardingCodeKind | undefined;

  validateCode: () => Promise<void>;
  useCode: () => Promise<AccountSummary | undefined>;
  logout: () => Promise<void>;
  goBack: (navigate: NavigateFunction) => void;
}

export class AppUseCodeViewModel implements UseCodeViewModel {
  @observable private _code = '';
  @observable private _codeKind: OnboardingCodeKind | undefined;
  @observable private _message = '';

  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    public readonly isInitialConfig: boolean
  ) {
    makeObservable(this);
  }

  get email() {
    return this._accountService.currentUserEmail;
  }

  @computed
  get errorMessage(): string {
    return this._message;
  }

  @computed
  get code() {
    return this._code;
  }

  set code(newValue: string) {
    this._code = newValue;
  }

  @computed
  get codeKind() {
    return this._codeKind;
  }

  @action
  async validateCode() {
    this._message = '';
    const response = await this._schoolYearConfigurationStore.validateOnboardingCode(this._code);

    if (response.isValid) {
      runInAction(() => (this._codeKind = response.kind));
    } else {
      runInAction(
        () => (this._message = this._localizationService.localizedStrings.studyo.onboarding.useCode.invalidCode)
      );
    }
  }

  async useCode(): Promise<AccountSummary | undefined> {
    if (this._codeKind != null) {
      const account = await this._schoolYearConfigurationStore.useOnboardingCode(this._code);

      // Refreshing accounts to have the account summaries. If we don't do this, we'll have a not authorized.
      await this._accountService.refreshAccounts();

      return account;
    }
  }

  async logout() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.logout });
    await this._accountService.logout();
  }

  goBack(navigate: NavigateFunction) {
    if (this._codeKind == null) {
      navigate(-1);
    } else {
      runInAction(() => {
        this._codeKind = undefined;
        this._code = '';
      });
    }
  }
}
