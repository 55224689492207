import { SchoolDayPeriod } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AccountService, ContentPasteboardStore, NavigationService } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppContentListItemViewModel } from './ContentListItemViewModel';
import {
  AppSchoolDayContentListSectionViewModel,
  SchoolDayContentListSectionViewModel
} from './SchoolDayContentListSectionViewModel';

export interface SchoolDayContentListViewModel {
  readonly data: DataLoader;
  readonly sections: SchoolDayContentListSectionViewModel[];
  readonly day: Day;

  close: () => void;
}

export class AppSchoolDayContentListViewModel implements SchoolDayContentListViewModel {
  readonly data: AccountData;

  constructor(
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly day: Day
  ) {
    makeObservable(this);
    this.data = _accountService.displayedAccountData;
  }

  @computed
  private get schoolDay() {
    return this.data.schoolDaysByDay.get(this.day.asString)!;
  }

  @computed
  private get contents() {
    return this.data.visibleContents.filter(
      (cd) =>
        (cd.dueDay.isSame(this.day) || cd.assignmentDay.isSame(this.day)) &&
        (cd.kind === 'task' || cd.notes.trim().length > 0)
    );
  }

  @computed
  get sections() {
    if (this.schoolDay == null) {
      return [];
    }

    const canAddOrEditContentAtDate = this.data.canAddOrEditContentAtDate(this.schoolDay.day);

    const allDaySection = new AppSchoolDayContentListSectionViewModel(
      this._contentPasteboardStore,
      this._navigationService,
      this._localizationService,
      this._accountService.displayedAccountData.configId,
      this.data.account,
      undefined,
      this.schoolDay,
      undefined,
      this.data,
      () => this.getAllDayContents(),
      !this.data.isReadOnly && canAddOrEditContentAtDate
    );

    const periods = this.schoolDay.sortedPeriods.filter(
      (p) => !this.data.periodPrioritiesStore.getPeriodIsHidden(p, this.day)
    );

    return [
      allDaySection,
      ...periods.map((period, periodIndex) => {
        const periodCourseOccurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(period, this.day);

        return new AppSchoolDayContentListSectionViewModel(
          this._contentPasteboardStore,
          this._navigationService,
          this._localizationService,
          this.data.configId,
          this.data.account,
          periodCourseOccurrence != null ? this.data.sectionsById.get(periodCourseOccurrence.sectionId) : undefined,
          this.schoolDay,
          period,
          this.data,
          () => this.getPeriodContents(period, periodIndex),
          !this.data.isReadOnly && canAddOrEditContentAtDate
        );
      })
    ];
  }

  close() {
    this._onSuccess();
  }

  private getAllDayContents = () => {
    return this.contents
      .filter((cd) => {
        if (cd.dueDay.isSame(this.day)) {
          if (cd.duePeriodTag.length === 0) {
            return true;
          }

          const periodForTag = this.schoolDay.sortedPeriods.find((p) => p.tag === cd.duePeriodTag);
          // If no period was found for the content duePeriodTag, we include it in the all day tasks.
          return periodForTag == null;
        } else {
          if (cd.sectionId.length > 0) {
            const periodForDueSection = this.schoolDay.sortedPeriods.find((period) =>
              this.data.periodPrioritiesStore.getOccurrenceForPeriod(period, this.day)
            );
            return periodForDueSection == null;
          } else {
            return false;
          }
        }
      })
      .map(
        (cd) =>
          new AppContentListItemViewModel(
            this._navigationService,
            this._analyticsService,
            this.data,
            cd.sectionId.length > 0 ? this.data.sectionsById.get(cd.sectionId) : undefined,
            this.day,
            undefined,
            undefined,
            cd
          )
      );
  };

  private getPeriodContents = (period: SchoolDayPeriod, index: number) => {
    const periodCourseOccurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(period, this.day);
    const periodSectionId = periodCourseOccurrence != null ? periodCourseOccurrence.sectionId : undefined;

    return this.contents
      .filter((cd) => {
        if (cd.dueDay.isSame(this.day)) {
          return cd.duePeriodTag === period.tag;
        } else {
          return cd.sectionId.length > 0 ? this.getPeriodShouldDisplayAssignment(index, cd.sectionId) : false;
        }
      })
      .map(
        (cd) =>
          new AppContentListItemViewModel(
            this._navigationService,
            this._analyticsService,
            this.data,
            cd.sectionId.length > 0 ? this.data.sectionsById.get(cd.sectionId) : undefined,
            this.day,
            period.tag,
            periodSectionId,
            cd
          )
      );
  };

  private getPeriodShouldDisplayAssignment = (periodIndex: number, contentDueSectionId: string) => {
    const { sortedPeriods } = this.schoolDay;

    for (let i = 0; i < sortedPeriods.length; i++) {
      const p = sortedPeriods[i];

      const periodCourseOccurrence = this.data.periodPrioritiesStore.getOccurrenceForPeriod(p, this.day);
      if (periodCourseOccurrence != null && periodCourseOccurrence.sectionId === contentDueSectionId) {
        return i === periodIndex;
      }
    }

    return false;
  };
}
