import { SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { ContentDefinitionModel } from '@shared/models/content';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog, SaveDialog, useSaveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { ContentRepeatView } from '../../../views';

export interface ContentRepeatScreenProps extends DialogResult<ContentDefinitionModel> {
  sx?: SxProps;
  className?: string;
  contentId: string;
}

export const ContentRepeatScreen = observer((props: ContentRepeatScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { contentId, sx, onSuccess, onCancel, className } = props;
  const viewModel = useMemo(() => viewModelFactory.createContentRepeat(contentId, onSuccess!, onCancel!), [contentId]);
  const theme = useTheme();

  const headerColor = viewModel.taskColor && SectionColors.get(viewModel.taskColor);
  const strings = localizationService.localizedStrings.studyo.contents.contentRepeat;

  const [repeatPromise, startRepeat, resetRepeat, retryRepeat] = useSaveDialog(() => viewModel.repeatContent());

  const repeatProcessDialogClose = (success: boolean) => {
    resetRepeat();
    if (success) {
      viewModel.dismiss();
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="medium"
      onClose={() => viewModel.cancel()}
    >
      <DialogHeader
        onClose={() => viewModel.cancel()}
        title={viewModel.title}
        color={headerColor}
        tintColor={headerColor && theme.studyo.periods.periodWithSectionTextColor}
        rightButton={{
          title: strings.repeat,
          action: startRepeat,
          color: 'inherit',
          onlyShowOnExtraSmallScreen: true,
          disabled: !viewModel.canProceed
        }}
      />

      <ContentRepeatView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.cancel} type="cancel" onPress={() => viewModel.cancel()} />

        <DialogButton
          title={strings.repeat}
          type="normal"
          variant="contained"
          disabled={!viewModel.canProceed}
          onPress={startRepeat}
        />
      </DialogActionButtons>

      <SaveDialog
        promise={repeatPromise}
        retryCall={retryRepeat}
        onClose={repeatProcessDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
