import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface OnboardingBackgroundImageProps {
  style?: CSSProperties;
}

export const OnboardingBackgroundImage = observer((props: OnboardingBackgroundImageProps) => {
  const { imageService } = useStudyoServices();

  return (
    <img
      src={imageService.studyoImages.generic.onboardingBackground}
      style={{ ...props.style, position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: 1 }}
    />
  );
});
