import { LinkModel } from '@shared/models/config';
import { sortBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService, AttachmentManager } from '../../services';

export interface ConfigLinkListViewModel {
  links: LinkModel[];
  close: () => void;
  openLink: (link: LinkModel) => void;
}

export class AppConfigLinkListViewModel implements ConfigLinkListViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _attachmentManager: AttachmentManager,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get links() {
    return sortBy(this._accountService.displayedAccountData.config.links, [(l) => l.sortPosition]);
  }

  close() {
    this._onSuccess();
  }

  openLink(link: LinkModel) {
    this._attachmentManager.openUrl(link.url);
  }
}
