import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';

export interface NotFoundScreenProps {
  sx?: SxProps;
  className?: string;
}

export const NotFoundScreen = observer((props: NotFoundScreenProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className } = props;

  const strings = localizationService.localizedStrings.studyo.notFound;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            flex={1}
            zIndex={2}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            p={{ xs: 2, sm: 3 }}
            spacing={2}
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <Stack spacing={1}>
              <Typography variant="h5" textAlign="center">
                {strings.title}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {strings.subtitle}
              </Typography>
            </Stack>

            <Box
              sx={{
                height: '1px',
                backgroundColor: theme.studyo.onboarding.separatorLineColor,
                width: !isExtraSmallScreen ? 300 : undefined
              }}
            />

            <Button variant="contained" sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }} onClick={goToHomepage}>
              {strings.goHomeButtonTitle}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
