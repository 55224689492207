import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface YearHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly dateTitle: string;
}

export class AppYearHeaderViewModel extends AppBaseAgendaHeaderViewModel implements YearHeaderViewModel {
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  get dateTitle() {
    const startYear = this._data.config.startDay.year;
    const endYear = this._data.config.endDay.year;

    if (startYear != endYear) {
      return `${startYear}-${endYear}`;
    } else {
      return `${startYear}`;
    }
  }
}
