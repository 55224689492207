import { ContentDefinitionUtils, DateUtils } from '@shared/components/utils';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { NavigationService, StudyoSettingsStore } from '@studyo/services';
import { StudyoAnalyticsService } from '@studyo/services/analytics';
import { setDay } from 'date-fns';
import _ from 'lodash';
import { makeObservable, override } from 'mobx';
import { TimelineListItemViewModelDelegate } from './TimelineListItemViewModel';
import {
  AppTimelineListSectionBaseViewModel,
  TimelineListElement,
  TimelineListGroup,
  TimelineListSectionBaseViewModel
} from './TimelineListSectionBaseViewModel';
import { TimelineSearchFilter } from './TimelineListViewModel';

type TodoSections = 'late' | 'today' | 'tomorrow' | 'this-week' | 'next-week' | 'later';

export type TimelineListSectionTodoViewModel = TimelineListSectionBaseViewModel;

export class AppTimelineListSectionTodoViewModel extends AppTimelineListSectionBaseViewModel {
  constructor(
    listItemDelegate: TimelineListItemViewModelDelegate,
    searchFilterProvider: TimelineSearchFilter,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    analyticsService: StudyoAnalyticsService,
    data: AccountData,
    settingsStore: StudyoSettingsStore,
    onMovePlannedDates: () => void
  ) {
    super(
      listItemDelegate,
      searchFilterProvider,
      navigationService,
      localizationService,
      analyticsService,
      data,
      settingsStore,
      onMovePlannedDates
    );
    makeObservable(this);
  }

  @override
  get sections() {
    const lateTasks: ContentDefinitionModel[] = [];
    const todayTasks: ContentDefinitionModel[] = [];
    const tomorrowTasks: ContentDefinitionModel[] = [];
    const thisWeekTasks: ContentDefinitionModel[] = [];
    const nextWeekTasks: ContentDefinitionModel[] = [];
    const laterTasks: ContentDefinitionModel[] = [];

    const tasks = _.filter(
      this.contents,
      (c) => c.kind === 'task' && ContentDefinitionUtils.isVisibleContent(c) && c.state == 'active'
    );
    tasks.forEach((content) => {
      switch (this.getSectionForContent(content)) {
        case 'late':
          lateTasks.push(content);
          break;
        case 'today':
          todayTasks.push(content);
          break;
        case 'tomorrow':
          tomorrowTasks.push(content);
          break;
        case 'this-week':
          thisWeekTasks.push(content);
          break;
        case 'next-week':
          nextWeekTasks.push(content);
          break;
        case 'later':
          laterTasks.push(content);
          break;
      }
    });

    const groups: TimelineListGroup[] = [];

    // TODO PBI 1347: rewrite without use push
    if (lateTasks.length > 0) {
      const sortedTasks = lateTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'late',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.lateTasksSectionTitle,
        data: elements
      });
    }

    if (todayTasks.length > 0) {
      const sortedTasks = todayTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'today',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.todayTasksSectionTitle,
        data: elements
      });
    }

    if (tomorrowTasks.length > 0) {
      const sortedTasks = tomorrowTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'tomorrow',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.tomorrowTasksSectionTitle,
        data: elements
      });
    }

    if (thisWeekTasks.length > 0) {
      const sortedTasks = thisWeekTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'this-week',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.thisWeekTasksSectionTitle,
        data: elements
      });
    }

    if (nextWeekTasks.length > 0) {
      const sortedTasks = nextWeekTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'next-week',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.nextWeekTasksSectionTitle,
        data: elements
      });
    }

    if (laterTasks.length > 0) {
      const sortedTasks = laterTasks.sort((c1, c2) => this.compareTwoContents(c1, c2, false));
      const elements: TimelineListElement[] = [];
      sortedTasks.forEach((task) => elements.push(...this.createElementsForContent(task)));

      groups.push({
        id: 'later',
        title: this._localizationService.localizedStrings.studyo.agenda.timeline.laterTasksSectionTitle,
        data: elements
      });
    }

    return groups;
  }

  readonly editActionType = 'complete';

  private getSectionForContent = (content: ContentDefinitionModel): TodoSections => {
    const { dueDay } = content;

    if (DateUtils.isLate(dueDay)) {
      return 'late';
    } else if (DateUtils.isToday(dueDay)) {
      return 'today';
    } else if (DateUtils.isTomorrow(dueDay)) {
      return 'tomorrow';
    } else {
      const tomorrow = dateService.today.addDays(1);
      // Returns Saturday's date in `tomorrow` week. As it is the last day of week, it will never be before `tomorrow`.
      const thisSaturday = Day.fromDate(setDay(tomorrow.asDate, 6))!;

      if (DateUtils.compareDayWithOther(dueDay, thisSaturday) <= 0) {
        return 'this-week';
      } else if (DateUtils.compareDayWithOther(dueDay, thisSaturday.addWeeks(1)) <= 0) {
        return 'next-week';
      } else {
        return 'later';
      }
    }
  };
}
