import { SectionUtils } from '@shared/components/utils';
import { CourseOccurrence, EditableCourseOccurrenceCustomization } from '@shared/models/calendar';
import { Color } from '@shared/models/types';
import { DataLoader, OnDestroy, OnInit } from '@shared/services';
import { AccountData, CalendarStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AccountAutoSyncService, AccountService, NavigationService } from '../../../../services';

export interface PlannerPeriodEditViewModel extends OnInit, OnDestroy {
  readonly data: DataLoader;
  readonly sectionColor: Color;
  readonly defaultOccurrenceTitle: string;
  customOccurrenceTitle: string;
  customOccurrenceRoomName: string;
  isSkipped: boolean;

  dismiss: (dismissAll: boolean) => Promise<void>;
  save: () => Promise<void>;
}

export class AppPlannerPeriodEditViewModel implements PlannerPeriodEditViewModel {
  readonly data: AccountData;

  private _customization: EditableCourseOccurrenceCustomization;

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _navigationService: NavigationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _occurrence: CourseOccurrence,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = _accountService.displayedAccountData;

    this._customization = EditableCourseOccurrenceCustomization.createNew(_occurrence);
  }

  @computed
  private get section() {
    const section = this.data.sectionsById.get(this._occurrence.sectionId);
    if (section == null) {
      throw new Error("Occurrence's section doens't exists.");
    }
    return section;
  }

  @computed
  get sectionColor(): Color {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined)!;
  }

  @computed
  get defaultOccurrenceTitle(): string {
    return `${this._occurrence.normalizedOrdinal}`;
  }

  @computed
  get customOccurrenceTitle(): string {
    return this._customization.title;
  }

  set customOccurrenceTitle(value: string) {
    this._customization.title = value;
  }

  @computed
  get customOccurrenceRoomName(): string {
    return this._customization.roomName;
  }

  set customOccurrenceRoomName(value: string) {
    this._customization.roomName = value;
  }

  @computed
  get isSkipped(): boolean {
    return this._customization.skipped;
  }

  set isSkipped(value: boolean) {
    this._customization.skipped = value;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async dismiss(dismissAll: boolean): Promise<void> {
    if (dismissAll) {
      this._onSuccess();
      return this._navigationService.closeAllModals();
    } else {
      this._onCancel();
    }
  }

  async save(): Promise<void> {
    await this._calendarStore.customizeCourseOccurrence(
      this._accountService.displayedAccountData.configId,
      this._occurrence.sectionId,
      this._customization
    );
    return this.data.refresh();
  }
}
