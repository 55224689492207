import { AccountService } from '../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../services/analytics';

export interface ConfigCreationViewModel {
  readonly email: string;
  readonly isInitialCreation: boolean;
  logout: () => Promise<void>;
}

export class AppConfigCreationViewModel implements ConfigCreationViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _analyticsService: StudyoAnalyticsService,
    public readonly isInitialCreation: boolean
  ) {}

  get email() {
    return this._accountService.currentUserEmail;
  }

  async logout() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.logout });
    await this._accountService.logout();
  }
}
