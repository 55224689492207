import { SpecialDaySymbolAndColor } from '@shared/components/special_day_symbols';
import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SpecialDayOccurrence } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';

export interface WeeklyDayHeaderViewModel {
  readonly canAddTask: boolean;
  readonly canEdit: boolean;
  readonly day: Day;
  readonly cycleDayTitle: string;
  readonly specialDays: SpecialDayOccurrence[];
  readonly specialDaySymbolsAndColors: SpecialDaySymbolAndColor[];
  readonly isToday: boolean;
}

export class AppWeeklyDayHeaderViewModel implements WeeklyDayHeaderViewModel {
  constructor(
    public readonly day: Day,
    public readonly cycleDayTitle: string,
    public readonly specialDays: SpecialDayOccurrence[],
    public readonly canAddTask: boolean,
    public readonly canEdit: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get isToday(): boolean {
    return DateUtils.isToday(this.day);
  }

  @computed
  get specialDaySymbolsAndColors(): SpecialDaySymbolAndColor[] {
    return this.specialDays
      .filter((sp) => sp.symbol !== 'none')
      .map(
        (sd) =>
          ({
            symbol: sd.symbol,
            color: SectionColors.get(sd.color)
          }) as SpecialDaySymbolAndColor
      );
  }
}
