import { Box, CardActionArea, Typography, useTheme } from '@mui/material';

import { observer } from 'mobx-react';
import { DayAndWeekPeriodInfoBoxProps } from './DayAndWeekPeriodInfoBox';

export const CompactDayAndWeekPeriodInfoBox = observer((props: DayAndWeekPeriodInfoBoxProps) => {
  const { height, isCompact, backgroundColor, borderColor, viewModel, sx, className } = props;
  const theme = useTheme();

  let sectionDisplayTitle = '';
  if (viewModel.sectionTitle != null) {
    sectionDisplayTitle = isCompact ? viewModel.sectionShortTitle : viewModel.sectionTitle;
  }

  const textColor =
    viewModel.hasSection && !viewModel.isSkipped
      ? theme.studyo.periods.periodWithSectionTextColor
      : theme.studyo.periods.freePeriodTextColor;

  const marginAndPaddingSpace = theme.studyo.baseThicknessValue * 0.5;
  const extraSmallLineHeight = 12 * 1.2;
  const smallLineHeight = 14 * 1.2;

  const fourLineSpace = smallLineHeight + extraSmallLineHeight * 3 + marginAndPaddingSpace;
  const threeLineSpace = smallLineHeight + extraSmallLineHeight * 2 + marginAndPaddingSpace;
  const twoLineSpace = smallLineHeight + extraSmallLineHeight + marginAndPaddingSpace;

  return (
    <CardActionArea
      sx={{
        ...sx,
        backgroundColor,
        color: textColor,
        p: 0.5,
        width: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: height < twoLineSpace ? 'center' : undefined,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        overflow: 'hidden',
        borderWidth: 1,
        borderColor,
        borderStyle: 'solid'
      }}
      className={className}
      onClick={() => viewModel.openNoteEdit()}
    >
      <Typography
        color="inherit"
        noWrap
        variant="subtitle2"
        sx={{ userSelect: 'none', lineHeight: `${smallLineHeight}px` }}
      >
        {sectionDisplayTitle}
      </Typography>

      {height >= twoLineSpace && (
        <Typography
          color="inherit"
          noWrap
          variant="caption"
          sx={{ userSelect: 'none', lineHeight: `${extraSmallLineHeight}px` }}
        >
          {viewModel.sectionNumber}
        </Typography>
      )}

      <Box flex={1} />

      {height >= fourLineSpace && (
        <Typography
          color="inherit"
          noWrap
          variant="caption"
          sx={{ userSelect: 'none', lineHeight: `${extraSmallLineHeight}px` }}
        >
          {viewModel.courseOrdinal}
        </Typography>
      )}

      {height >= threeLineSpace && (
        <Typography
          color="inherit"
          noWrap
          variant="caption"
          sx={{ userSelect: 'none', lineHeight: `${extraSmallLineHeight}px` }}
        >
          {viewModel.roomName}
        </Typography>
      )}
    </CardActionArea>
  );
});
