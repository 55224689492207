import { Box, Divider, Stack, SxProps, useTheme } from '@mui/material';
import {
  DayAndWeekConstants,
  SchoolDayHeaderRow,
  TimesColumn,
  WeeklyHeader,
  WeeklySchoolDays
} from '@studyo/components';
import { times } from 'lodash';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { WeeklyViewModel } from '../../viewmodels';

export interface WeeklyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: WeeklyViewModel;
}

export const WeeklyView = observer((props: WeeklyViewProps) => {
  const { sx, className, viewModel } = props;
  const theme = useTheme();
  const scrollViewRef = useRef<HTMLDivElement | null>();
  const hasInitialScrolled = useRef(false);

  const scrollViewDidLayout = () => {
    if (!hasInitialScrolled.current) {
      hasInitialScrolled.current = true;

      scrollViewRef.current!.scrollTo({
        top: viewModel.pointsPerHour * DayAndWeekConstants.startHour - 12,
        behavior: 'auto'
      });
    }
  };

  return (
    <Box
      className={className}
      sx={{
        ...sx,
        backgroundColor: theme.studyo.agenda.weekly.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'stretch'
      }}
    >
      <Box>
        <WeeklyHeader
          viewModel={viewModel.header}
          goToNextWeek={() => viewModel.goToNextPage()}
          goToPreviousWeek={() => viewModel.goToPreviousPage()}
        />
      </Box>

      <Divider />

      <Stack
        ref={(ref) => {
          scrollViewRef.current = ref;
          scrollViewDidLayout();
        }}
        flex={1}
        overflow="auto"
        position="relative"
      >
        {/*
         * The school day header is in the scroll view because if the scrollbar is present,
         * the content won't be aligned with the header because the container that is within
         * the scroll view will be a little less large than the header
         */}
        <SchoolDayHeaderRow sx={{ position: 'sticky', top: 0, zIndex: 1_001, width: '100%' }} viewModel={viewModel} />

        {/* the array(23) is for 23 hours to map periods in weeklyView by hour. */}
        {times(23).map((_, i) => {
          const top = viewModel.pointsPerHour * (i + 1) + DayAndWeekConstants.schoolDayHeaderHeight;

          return (
            <Box
              key={`time-line-${i}`}
              sx={{
                backgroundColor: theme.studyo.agenda.daily.headerSeparatorColor,
                height: '1px',
                position: 'absolute',
                right: 0,
                left: DayAndWeekConstants.timesColumnWidth + DayAndWeekConstants.itemSpacing * 2,
                top
              }}
            />
          );
        })}

        <Stack direction="row" flex={1}>
          <TimesColumn pointsPerHour={viewModel.pointsPerHour} />

          <WeeklySchoolDays sx={{ flex: 1 }} viewModel={viewModel} />
        </Stack>
      </Stack>
    </Box>
  );
});
