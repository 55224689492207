import { Day } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';
import { NavigationService } from '../../services';
import { DisplayableContentViewModel } from './DisplayableContentViewModel';

export interface ContentNoteBoxViewModel {
  note: DisplayableContentViewModel | undefined;
  openNoteEdit: () => Promise<void>;
}

export class AppContentNoteBoxViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _day: Day,
    private readonly _periodTag: string | undefined,
    private readonly _sectionId: string | undefined,
    private readonly _note: () => DisplayableContentViewModel | undefined,
    private readonly _didEditNote: (() => void) | undefined
  ) {
    makeObservable(this);
  }

  @computed
  get note(): DisplayableContentViewModel | undefined {
    return this._note();
  }

  async openNoteEdit() {
    // The ContentNoteBox cannot be used where there's no period.
    if (this._day == null || this._periodTag == null) {
      throw new Error("Can't display period info if no day or period tag defined.");
    }

    const response = await this._navigationService.navigateToNoteEditModal(this._day, this._periodTag, this._sectionId);

    if (response !== 'cancelled') {
      this._didEditNote?.();
    }
  }
}
