import { alpha, Box, SxProps, useTheme } from '@mui/material';
import { CSSProperties, ReactNode, RefObject, useEffect, useMemo, useRef, useState, WheelEvent } from 'react';

interface ScrollValues {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

export interface ScrollShadowWrapperProps {
  sx?: SxProps;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export function ScrollShadowWrapper(props: ScrollShadowWrapperProps) {
  const { children, className = '', style = {}, sx } = props;
  const theme = useTheme();
  const [scrollValues, setScrollValues] = useState<ScrollValues>({ scrollHeight: 0, scrollTop: 0, clientHeight: 0 });

  const onScrollHandler = (event: WheelEvent<HTMLDivElement>) => {
    setScrollValues({
      scrollHeight: event.currentTarget.scrollHeight,
      scrollTop: event.currentTarget.scrollTop,
      clientHeight: event.currentTarget.clientHeight
    });
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resetRefSizes = (ref: RefObject<HTMLDivElement>) => {
      if (ref.current == null) {
        return;
      }

      setScrollValues({
        scrollHeight: ref.current.scrollHeight,
        scrollTop: ref.current.scrollTop,
        clientHeight: ref.current.clientHeight
      });
    };

    resetRefSizes(wrapperRef);
  }, [wrapperRef?.current?.clientHeight]);

  const shadowColor = useMemo(() => alpha('#000', theme.palette.mode === 'dark' ? 0.4 : 0.1), [theme]);

  const getVisibleSides = (): { top: boolean; bottom: boolean } => {
    const { clientHeight, scrollHeight, scrollTop } = scrollValues;
    const isBottom = clientHeight >= scrollHeight - scrollTop;
    const isTop = scrollTop === 0;

    return {
      top: !isTop,
      bottom: !isBottom
    };
  };

  return (
    <Box
      ref={wrapperRef}
      style={style}
      sx={{ position: 'relative', overflowY: 'auto', ...sx }}
      className={className}
      onScroll={onScrollHandler}
    >
      <Box
        sx={{
          pointerEvents: 'none',
          position: 'sticky',
          top: 0,
          height: 16,
          width: '100%',
          transition: 'opacity 0.3s',
          opacity: getVisibleSides().top ? 100 : 0,
          background: `linear-gradient(180deg, ${shadowColor} 0%, rgba(0,0,0,0) 100%)`,
          mb: '-16px'
        }}
      />
      {children}

      <Box
        sx={{
          mt: '-16px',
          pointerEvents: 'none',
          position: 'sticky',
          bottom: 0,
          height: 16,
          width: '100%',
          transition: 'opacity 0.3s',
          opacity: getVisibleSides().bottom ? 100 : 0,
          background: `linear-gradient(0deg, ${shadowColor} 0%, rgba(0,0,0,0) 100%)`
        }}
      />
    </Box>
  );
}
