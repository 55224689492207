export const SingleDayWidth = 45;
export const LargeSingleDayWidth = 90;
export const SectionHeaderWidth = 100;

export const AssignedPlannedIconSize = 24;
export const CollectionViewItemPadding = 2;
export const CollectionViewSchoolDayHeaderHeight = 55;
export const DueIconSize = 24;
export const LargeSpacerHeight = AssignedPlannedIconSize * 0.666666;
export const StepViewSize = AssignedPlannedIconSize * 0.333333;
export const StandardContentLineHeight = 40;
export const LargeContentLineHeight = 60;

export function getAssignedPlannedIconPadding(dayWidth: number) {
  return (dayWidth - AssignedPlannedIconSize) / 2;
}

export function getDueIconPadding(dayWidth: number) {
  return (dayWidth - DueIconSize) / 2;
}

export function getContentTitlePadding(dayWidth: number) {
  return (dayWidth - DueIconSize) / 2 + 5;
}

export function getStepViewPadding(dayWidth: number) {
  return (dayWidth - StepViewSize) / 2;
}
