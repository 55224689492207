import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';

import { AllDayOfWeek } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface MonthDayOfWeekProps {
  sx?: SxProps;
  className?: string;
}

export const MonthDayOfWeek = observer((props: MonthDayOfWeekProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{ ...sx, backgroundColor: theme.studyo.agenda.month.dayOfWeekHeaderColor }}
      className={className}
      padding={1}
    >
      {AllDayOfWeek.map((dow) => (
        <Box
          key={`month-dow-header-${dow}`}
          sx={{ display: 'flex', flexGrow: 1, flexBasis: 0 }}
          alignItems="center"
          justifyContent="center"
          margin={0.5}
        >
          <Typography variant="body2">
            {localizationService.localizedStrings.models.dayOfWeek.localizedDayOfWeek(dow)}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
});
