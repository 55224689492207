import { SxProps, useTheme } from '@mui/material';
import { TopIndicator } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface NoInternetIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const NoInternetIndicator = observer((props: NoInternetIndicatorProps) => {
  const { localizationService, networkService } = useStudyoServices();
  const { sx, className } = props;
  const theme = useTheme();
  const colors = theme.studyo.utils.indicators.noInternet;

  return (
    <TopIndicator
      message={localizationService.localizedStrings.studyo.utils.noInternetIndicator}
      backgroundColor={colors.backgroundColor}
      display={!networkService.isOnline}
      sx={sx}
      className={className}
    />
  );
});
