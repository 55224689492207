import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, reaction, when } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppSchoolDayPickerViewModel, SchoolDayPickerViewModel } from '../../utils';
import { AppPeriodsHeaderViewModel, PeriodsHeaderViewModel } from './PeriodsHeaderViewModel';
import { AppPeriodsListViewModel, PeriodsListViewModel } from './PeriodsListViewModel';
import { AppPeriodsSectionListViewModel, PeriodsSectionListViewModel } from './PeriodsSectionListViewModel';

export interface AgendaPeriodsViewModel {
  readonly data: AccountData;
  readonly headerViewModel: PeriodsHeaderViewModel;
  readonly periodsListViewModel: PeriodsListViewModel;
  readonly schoolDayPickerViewModel: SchoolDayPickerViewModel;
  readonly sectionsListViewModel: PeriodsSectionListViewModel;
  readonly selectedSection: SectionModel | undefined;

  didSelectSchoolDay: (schoolDay: SchoolDay) => void;
  setSchoolDayPickerDay: () => void;
  setSchoolDayPickerHighlightedDays: () => void;
}

export class AppAgendaPeriodsViewModel implements AgendaPeriodsViewModel {
  @observable selectedSection: SectionModel | undefined;
  private _pickerViewModel: SchoolDayPickerViewModel | undefined;
  private _periodsListViewModel: AppPeriodsListViewModel | undefined;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _attachmentManager: AttachmentManager,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _contentPasteboardStore: ContentPasteboardStore
  ) {
    makeObservable(this);
    when(
      () => this.data.hasData,
      () => {
        this.selectedSection = this.userSections.length > 0 ? this.userSections[0] : undefined;

        this._pickerViewModel = new AppSchoolDayPickerViewModel(
          this._accountService,
          this._localizationService,
          this.highlightedDays
        );

        this._periodsListViewModel = new AppPeriodsListViewModel(
          this._analyticsService,
          this._localizationService,
          this._navigationService,
          this.preferences,
          this._uiSettingsStore,
          this._contentPasteboardStore,
          this.data,
          this.selectedSection
        );
      }
    );

    reaction(
      () => this._uiSettingsStore.periodsCurrentDay,
      (storeDay) => {
        const day = storeDay ?? dateService.today;
        this._pickerViewModel?.setCurrentDay(day);
      }
    );

    reaction(
      () => this.selectedSection,
      (section) => {
        this._periodsListViewModel?.updateSelectedSection(section);
        this.setSchoolDayPickerHighlightedDays();
      }
    );

    reaction(
      () => this.userSections,
      (userSections) => {
        if (userSections.length === 0 && this.selectedSection != null) {
          this.selectedSection = undefined;
        }

        if (this.selectedSection == null || !userSections.includes(this.selectedSection)) {
          this.selectedSection = userSections[0];
        }
      }
    );
  }

  @computed
  get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  get headerViewModel(): PeriodsHeaderViewModel {
    return new AppPeriodsHeaderViewModel(
      this._localizationService,
      this._navigationService,
      this._attachmentManager,
      this.data,
      this.preferences,
      this._uiSettingsStore
    );
  }

  @computed
  get schoolDayPickerViewModel(): SchoolDayPickerViewModel {
    return this._pickerViewModel!;
  }

  @computed
  get sectionsListViewModel(): PeriodsSectionListViewModel {
    return new AppPeriodsSectionListViewModel(this.preferences, this.data, this.didSelectSection);
  }

  @computed
  get periodsListViewModel(): PeriodsListViewModel {
    return this._periodsListViewModel!;
  }

  didSelectSchoolDay(schoolDay: SchoolDay) {
    this._uiSettingsStore.periodsCurrentDay = schoolDay.day;
  }

  setSchoolDayPickerDay() {
    this._pickerViewModel?.setCurrentDay(this._uiSettingsStore.periodsCurrentDay ?? dateService.today);
  }

  setSchoolDayPickerHighlightedDays = () => {
    this._pickerViewModel?.setHighlightedDays(this.highlightedDays);
  };

  @computed
  get hasSectionFiltersSet() {
    const numberOfSections = this.preferences.periodSectionFilters.length;
    return numberOfSections !== 0 && numberOfSections !== this.data.userSections.length;
  }

  @computed
  get preferences() {
    return this._settingsStore.getPreferences(this.data.accountId);
  }

  @computed
  private get highlightedDays(): Day[] {
    let days: Day[] = [];

    if (this.selectedSection != null) {
      const occurrences = this.data.getOccurrencesForSectionId(this.selectedSection.id);
      days = _.chain(occurrences)
        .map((oc) => oc.day)
        .uniqWith((day1, day2) => day1.isSame(day2))
        .value();
    }

    return days;
  }

  @computed
  private get userSections(): SectionModel[] {
    const sectionIds = this.preferences.periodSectionFilters;
    let sections = this.data.userSections;

    if (this.hasSectionFiltersSet) {
      sections = _.filter(sections, (s) => sectionIds.includes(s.id));
    }

    return _.sortBy(sections, (s) => s.title);
  }

  @action
  private didSelectSection = (section: SectionModel) => {
    this.selectedSection = section;
    this.setSchoolDayPickerDay();
  };
}
