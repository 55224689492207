import { SxProps, useTheme } from '@mui/material';
import { TopIndicator } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../UseStudyoServicesHook';

export interface UpdateIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const UpdateIndicator = observer((props: UpdateIndicatorProps) => {
  const { localizationService, killSwitchService } = useStudyoServices();
  const { sx, className } = props;
  const theme = useTheme();

  return (
    <TopIndicator
      message={localizationService.localizedStrings.studyo.utils.updateAvailable}
      backgroundColor={theme.studyo.utils.indicators.updateAvailable.backgroundColor}
      display={killSwitchService.updateAvailable}
      onPress={() => window.location.reload()}
      sx={sx}
      className={className}
    />
  );
});
