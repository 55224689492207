import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { AccountData } from '@shared/services/stores';
import { sortBy } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { Location, NavigateFunction } from 'react-router-dom';
import { AccountService, NavigationService } from '../../services';

export interface ImpersonateStudentSelectionViewModel {
  currentFilter: string;
  readonly students: AccountModel[];
  readonly impersonatingAccount: AccountModel | undefined;

  impersonateStudent: (account: AccountModel, location: Location, navigate: NavigateFunction) => void;
  endImpersonification: (location: Location, navigate: NavigateFunction) => void;
  close: () => void;
}

export class AppImpersonateStudentSelectionViewModel implements ImpersonateStudentSelectionViewModel {
  @observable private _currentFilter = '';

  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  private get allStudents() {
    return this.data.accounts
      .filter((account) => account.role === 'student' && (account.lastName.length > 0 || account.firstName.length > 0))
      .map((student) => ({
        student,
        upperCaseDisplayName: AccountUtils.getDisplayLastFirstName(student).toUpperCase()
      }));
  }

  @computed
  private get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed
  get currentFilter(): string {
    return this._currentFilter;
  }

  set currentFilter(value: string) {
    this._currentFilter = value;
  }

  @computed
  get students(): AccountModel[] {
    let students = this.allStudents;
    if (this._currentFilter.length > 0) {
      students = students.filter(
        (student) => student.upperCaseDisplayName.indexOf(this._currentFilter.toUpperCase()) >= 0
      );
    }

    return sortBy(students, [(s) => s.upperCaseDisplayName]).map((s) => s.student);
  }

  @computed
  get impersonatingAccount(): AccountModel | undefined {
    return this.data.isImpersonating && this.data.hasData ? this.data.account : undefined;
  }

  async impersonateStudent(account: AccountModel, location: Location, navigate: NavigateFunction) {
    await this._navigationService.closeAllModals();

    if (this.impersonatingAccount != null && this.impersonatingAccount.id === account.id) {
      await this.endImpersonification(location, navigate);
    } else {
      await this._accountService.setCurrentDisplayedAccount(account.id);
      await this._navigationService.navigateToSamePage(account.configId, account.id, location, navigate);
    }
  }

  async endImpersonification(location: Location, navigate: NavigateFunction) {
    await this._navigationService.closeAllModals();
    const currentAccount = this._accountService.currentAccount!;

    if (currentAccount === 'super-admin') {
      await this._accountService.setCurrentDisplayedAccountToDefault();
    } else {
      await this._accountService.setCurrentDisplayedAccount(currentAccount.id);
      await this._navigationService.navigateToSamePage(currentAccount.configId, currentAccount.id, location, navigate);
    }
  }

  close() {
    this._onCancel();
  }
}
