import { GetPublishingTaskWorkloadImpactResponse } from '@buf/studyo_studyo.bufbuild_es/studyo/services/contents_pb';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { SymmetricBoolMatrix } from '@shared/models/SymmetricBoolMatrix';
import {
  AppCourseOccurrenceInfo,
  CourseOccurrence,
  CourseOccurrenceInfo,
  SchoolDay,
  SchoolDayPeriod
} from '@shared/models/calendar';
import {
  Account,
  AccountModel,
  SchoolYearConfiguration,
  SchoolYearConfigurationModel,
  SectionModel
} from '@shared/models/config';
import {
  ContentAttachment,
  ContentAttachmentModel,
  ContentAttachmentUploadUrlResponse,
  ContentDefinition,
  ContentDefinitionModel
} from '@shared/models/content';
import { AuthorizationRole, Day } from '@shared/models/types';
import _ from 'lodash';
import { autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { AppDataLoader } from '../../DataLoader';
import { dateService } from '../../DateService';
import { KillSwitchService } from '../../KillSwitchService';
import { NetworkService } from '../../NetworkService';
import { Storage } from '../../Storage';
import { AnalyticsEvent, AnalyticsPage, AnalyticsService } from '../../analytics';
import {
  ContentTransport,
  GeneratorTransport,
  IcsUrls,
  SchoolTransport,
  isFailedPreconditionError
} from '../../transports';
import { AccountData, DayString, PeriodPriorityStore, StudyoObjectId } from '../interfaces';
import { DemoSchoolInterceptor } from './DemoSchoolInterceptor';
import { AppPeriodPriorityStore } from './period_priority';

export class AppAccountData extends AppDataLoader implements AccountData {
  readonly periodPrioritiesStore: PeriodPriorityStore;

  private readonly _demoInterceptor?: DemoSchoolInterceptor;
  private readonly _configTransport: SchoolTransport;
  private readonly _contentTransport: ContentTransport;

  @observable private _config?: SchoolYearConfigurationModel;
  @observable private _scheduleConflicts?: SymmetricBoolMatrix;
  private _accounts = observable.map<StudyoObjectId, AccountModel>();
  private _contents = observable.map<StudyoObjectId, ContentDefinitionModel>();
  private _schoolDays = observable.map<DayString, SchoolDay>();

  private _accountsSyncToken?: string;
  private _contentsSyncToken?: string;
  private _taughtSectionsContentsSyncToken?: string;
  private _taughtSectionsPublishedContentsSyncToken?: string;
  private _taughtSectionsContentsSyncTokenSource?: Record<string, string[]>;
  private _schoolDaysSyncToken?: string;
  private _scheduleConflictsSyncToken?: string;

  constructor(
    readonly configId: string,
    readonly accountId: string,
    configTransport: SchoolTransport,
    contentTransport: ContentTransport,
    private readonly _generatorTransport: GeneratorTransport,
    private readonly _networkService: NetworkService,
    private readonly _killSwitchService: KillSwitchService,
    private readonly _analyticsService: AnalyticsService<AnalyticsPage<string>, AnalyticsEvent<string>>,
    public readonly isImpersonating: boolean,
    public readonly impersonatingRole: AuthorizationRole | undefined,
    localStore: Storage,
    isDemoModeEnabled = false
  ) {
    super();

    makeObservable(this);

    // This interceptor is not injected. It's an internal mechanism of AppAccountData.
    if (isDemoModeEnabled) {
      this._demoInterceptor = new DemoSchoolInterceptor(configTransport, contentTransport);
      this._configTransport = this._demoInterceptor;
      this._contentTransport = this._demoInterceptor;
    } else {
      this._configTransport = configTransport;
      this._contentTransport = contentTransport;
    }

    void this.loadData();
    this.periodPrioritiesStore = new AppPeriodPriorityStore(this, localStore);

    autorun(() => {
      if (this.hasData) {
        // We need to reference 'schoolDays' so that priorities get updated when the calendar changes.
        this.schoolDays;
        void this.periodPrioritiesStore.updatePriorities();
      }
    });
  }

  get config() {
    if (!this.hasData) {
      throw new Error('Trying to access config before data has been loaded.');
    }

    if (this._config == null) {
      throw new Error('Unexpected: hasData but no config.');
    }

    return this._config;
  }

  @computed
  get scheduleConflicts() {
    if (!this.hasData) {
      throw new Error('Trying to access schedule conflicts before data has been loaded.');
    }

    if (this._scheduleConflicts == null) {
      throw new Error('Unexpected: hasData but no schedule conflicts');
    }

    return this._scheduleConflicts;
  }

  @computed
  get account() {
    if (!this.hasData) {
      throw new Error('Trying to access account before data has been loaded.');
    }

    const a = this._accounts.get(this.accountId);

    if (a == null) {
      throw new Error('Unexpected: No account match accountId in data');
    }

    return a;
  }

  @computed
  get sectionsById() {
    return new Map(Object.entries(_.keyBy(this.sections, (s) => s.id)));
  }

  @computed
  get userSectionsById() {
    return new Map(Object.entries(_.keyBy(this.userSections, (s) => s.id)));
  }

  get accountsById() {
    return this._accounts;
  }

  get contentsById() {
    return this._contents;
  }

  get schoolDaysByDay() {
    return this._schoolDays;
  }

  @computed
  get sections() {
    return this._config ? this._config.sections : [];
  }

  @computed
  get userSections(): SectionModel[] {
    return _.compact(this.getSectionIdsForAccount(this.account, this.sections).map((id) => this.sectionsById.get(id)));
  }

  @computed
  get autoEnrollSections() {
    return this.sections.filter((s) => this.getSectionIsAutoEnrolled(this.account, s));
  }

  @computed
  get accounts() {
    return _.sortBy(Array.from(this._accounts.values()), (a) => a.id);
  }

  @computed
  get contents() {
    const sectionIds = new Set(this.getSectionIdsForAccount(this.account, this.sections));

    return _.chain([...this._contents.values()])
      .filter((c) => {
        if (c.sectionId !== '' && !sectionIds.has(c.sectionId)) {
          return false;
        }

        const isBeyondVisibility = ContentDefinitionUtils.shouldBeFilteredByAssessmentPlanningForRole(
          c,
          this.account.role,
          this.sectionsById,
          this.config
        );

        if (isBeyondVisibility) {
          return false;
        }

        if (this.account.role === 'teacher') {
          // Filtering all replica tasks in taught sections. A teacher would otherwise see all replica
          // tasks owned by all other teachers of a section.
          if (c.isSlave) {
            const section = this.sectionsById.get(c.sectionId);
            if (section?.teacherIds.find((id) => id === this.accountId) != null) {
              return false;
            }
          }
        }

        return true;
      })
      .sortBy((c) => c.id)
      .value();
  }

  @computed
  get visibleContents() {
    return this.contents.filter((cd) => ContentDefinitionUtils.isVisibleContent(cd));
  }

  @computed
  get schoolDays() {
    return _.sortBy(Array.from(this._schoolDays.values()), (s) => s.day.asString);
  }

  @computed
  get isReadOnly() {
    return this.isImpersonating || !this._networkService.isOnline || this._killSwitchService.updateAvailable;
  }

  @computed
  get canPreventChanges() {
    return (this._config?.isDemo ?? false) && this._demoInterceptor != null;
  }

  @computed
  get isPreventingChanges() {
    return (this._config?.isDemo ?? false) && (this._demoInterceptor?.isPreventingChanges ?? false);
  }

  set isPreventingChanges(value: boolean) {
    // Avoid any error. Simply ignore if not supported.
    if (this._demoInterceptor != null) {
      this._demoInterceptor.isPreventingChanges = value;
    }
  }

  canAddOrEditContentAtDate(date: Day | undefined) {
    if (date == null) {
      return true;
    }

    const lastDay = this.config.endDay;
    const maxCreationDay = lastDay.addMonths(1).lastDayOfMonth();
    return date.compare(maxCreationDay) <= 0;
  }

  getOccurrencesForSectionId(sectionId: string): CourseOccurrenceInfo[] {
    const occurrences = _.flatten(
      this.schoolDays.map((sd) =>
        sd.periods
          .map((period) => ({
            period,
            occurrence: this.getDisplayedOccurrencesForPeriod(period).find((o) => o.sectionId === sectionId)
          }))
          .filter((info) => info.occurrence != null)
          .map((info) => new AppCourseOccurrenceInfo(sd.day, info.period, info.occurrence!))
      )
    );

    return occurrences.sort((occurrence1, occurrence2) =>
      occurrence1.occurrence.ordinal < occurrence2.occurrence.ordinal ? -1 : 1
    );
  }

  getPrioritizedOccurrencesForSectionId(sectionId: string): CourseOccurrenceInfo[] {
    // As opposed to getOccurrencesForSectionId, here we skip conflicting periods
    // that are not set as topmost.
    const occurrences = _.flatten(
      this.schoolDays.map((sd) =>
        sd.periods
          .map((period) => ({
            period,
            occurrence: this.periodPrioritiesStore.getOccurrenceForPeriod(period, sd.day)
          }))
          .filter((info) => info.occurrence != null && info.occurrence.sectionId === sectionId)
          .map((info) => new AppCourseOccurrenceInfo(sd.day, info.period, info.occurrence!))
      )
    );

    return occurrences.sort((occurrence1, occurrence2) =>
      occurrence1.occurrence.ordinal < occurrence2.occurrence.ordinal ? -1 : 1
    );
  }

  async createOrUpdateAccount(account: AccountModel): Promise<AccountModel> {
    const accountProtobuf = account.toProtobuf();

    const updatedAccountPB = await this._configTransport.createOrUpdateAccount(accountProtobuf);

    const newAccount = new Account(updatedAccountPB);
    // Setting the updated account here again if something else changed with the server's version
    runInAction(() => {
      this.accountsById.set(updatedAccountPB.id, newAccount);
    });
    return newAccount;
  }

  async inviteParent(email: string): Promise<void> {
    await this._configTransport.inviteParent(this.accountId, email);
  }

  async ensureMarkedAsRead(contentId: string): Promise<void> {
    if (!this.isReadOnly) {
      const content = this._contents.get(contentId);

      if (content?.isUnread === true) {
        try {
          const newContent = await this._contentTransport.markContentAsRead(content.id);
          runInAction(() => this.contentsById.set(content.id, new ContentDefinition(newContent)));
        } catch (error) {
          console.error('Failed to mark task as read');
        }
      }
    }
  }

  async createOrUpdateContent(content: ContentDefinitionModel): Promise<ContentDefinitionModel> {
    if (this.isReadOnly || !this.canAddOrEditContentAtDate(content.dueDay)) {
      throw new Error("Data is readonly. User shouldn't be able to edit content.");
    }
    const isUpdatingContent = content.id.length > 0;
    const originalContent = isUpdatingContent ? this.contentsById.get(content.id) : undefined;
    const originalContentPB = originalContent?.toProtobuf();

    const contentProtobuf = content.toProtobuf();

    // Ensure each content has a syncLocalId
    if (contentProtobuf.syncLocalId === '') {
      contentProtobuf.syncLocalId = uuidv4();
    }

    if (isUpdatingContent) {
      runInAction(() => this.contentsById.set(content.id, new ContentDefinition(contentProtobuf)));
    }

    try {
      const updatedContentPB = await this._contentTransport.saveOrUpdateContent(contentProtobuf);

      const newContent = new ContentDefinition(updatedContentPB);
      // Setting the updated content here again if something else changed with the server's version
      runInAction(() => this.contentsById.set(updatedContentPB.id, newContent));
      return newContent;
    } catch (e) {
      if (originalContentPB != null) {
        if (isFailedPreconditionError(e) && isUpdatingContent) {
          // The sync token was not matching. We must reload it and discard changes.
          let reloaded = false;
          try {
            const reloadedPB = await this._contentTransport.fetchContent(content.id);
            runInAction(() => this.contentsById.set(content.id, new ContentDefinition(reloadedPB)));

            // We still want to throw to the caller, but we're in a try/catch!
            reloaded = true;
          } catch (e2) {
            console.error('Failed to reload content after sync token issue');
            // Continue with code below to at least restore original.
          }

          if (reloaded) {
            throw e;
          }
        }

        // Restore the original content but keep the last sync local id.
        if (originalContentPB.syncLocalId === '') {
          originalContentPB.syncLocalId = contentProtobuf.syncLocalId;
        }

        runInAction(() => this.contentsById.set(content.id, new ContentDefinition(originalContentPB)));
      }

      // TODO: Throw the appropriate error
      throw e;
    }
  }

  async createOrUpdateContents(contents: ContentDefinitionModel[]): Promise<ContentDefinitionModel[]> {
    if (this.isReadOnly) {
      throw new Error("Data is readonly. User shouldn't be able to edit content.");
    }

    // Show changes early, then try to save.
    const updatedContents = contents
      .filter((cd) => cd.id.length > 0)
      .map((cd) => new ContentDefinition(cd.toProtobuf()));
    runInAction(() => this.contentsById.merge(_.keyBy(updatedContents, (c) => c.id)));

    // Now keep track of the final ContentDefinition to merge, which can be:
    // * The result of a successful save
    // * The result of a reload after a failed save for failed preconditions (sync token)
    // * The orginal if either the save failed for another reason, or the reload failed.
    // We start the array with the originals, with undefined values for new contents.
    const finalContents = contents.map((cd) => (cd.id.length > 0 ? this.contentsById.get(cd.id) : undefined));

    const results = await Promise.allSettled(
      contents.map(async (content, i) => {
        const contentPB = content.toProtobuf();

        if (contentPB.syncLocalId === '') {
          contentPB.syncLocalId = uuidv4();
        }

        try {
          const updatedContentPB = await this._contentTransport.saveOrUpdateContent(contentPB);
          finalContents[i] = new ContentDefinition(updatedContentPB);
        } catch (e2) {
          if (isFailedPreconditionError(e2) && content.id.length > 0) {
            // The sync token was not matching. We must reload it and discard changes.
            try {
              const reloadedPB = await this._contentTransport.fetchContent(content.id);
              finalContents[i] = new ContentDefinition(reloadedPB);
            } catch (e3) {
              console.error('Failed to reload content after sync token issue');
              // finalContent[i] contains the original if was an update.
            }
          }

          throw e2;
        }
      })
    );

    const firstError = results.find((r) => r.status === 'rejected') as PromiseRejectedResult;

    const updates = _.compact(finalContents);
    runInAction(() => this.contentsById.merge(_.keyBy(updates, (c) => c.id)));

    if (firstError != null) {
      throw firstError.reason;
    }

    return updates;
  }

  async updateContentAttachment(attachment: ContentAttachmentModel): Promise<ContentAttachmentModel> {
    if (this.isReadOnly) {
      throw new Error("Data is readonly. User shouldn't be able to edit content attachment.");
    }

    const response = await this._contentTransport.updateContentAttachment(
      this.configId,
      this.accountId,
      attachment.toProtobuf()
    );

    return new ContentAttachment(response);
  }

  async getUploadURL(attachment: ContentAttachmentModel): Promise<ContentAttachmentUploadUrlResponse> {
    return this._contentTransport.getUploadUrlForContentAttachment(
      this.configId,
      this.accountId,
      attachment.toProtobuf()
    );
  }

  async getIcsUrls(ignoreFreePeriods: boolean): Promise<IcsUrls> {
    return this._generatorTransport.fetchConfigIcsUrls(this.configId, this.accountId, ignoreFreePeriods);
  }

  async publishContent(content: ContentDefinitionModel) {
    try {
      const publishedContent = await this._contentTransport.publishContent(content.toProtobuf());
      runInAction(() => this.contentsById.set(content.id, new ContentDefinition(publishedContent)));
    } catch (error) {
      console.warn(`Publishing content with id ${content.id} failed with error ${(error as Error).message}`);
      throw error;
    }
  }

  getStudentsForSection(section: SectionModel): AccountModel[] {
    const students = new Set<AccountModel>();

    _.forEach(this.accounts, (account) => {
      if (
        (account.selectedSectionIds.indexOf(section.id) >= 0 ||
          section.autoEnrollRoles.indexOf(account.role) >= 0 ||
          section.autoEnrollTags.indexOf(`gradeLevel=${account.gradeLevel}`) >= 0) &&
        section.teacherIds.find((id) => id === account.id) == null
      ) {
        students.add(account);
      }
    });

    return Array.from(students.values());
  }

  getPublishingTaskWorkloadImpact(
    sectionId: string,
    dueDay: Day,
    targetAccountIds: string[],
    ignoredMasterTaskId?: string
  ): Promise<GetPublishingTaskWorkloadImpactResponse> {
    return this._contentTransport.getPublishingTaskWorkloadImpact(
      this.configId,
      sectionId,
      dueDay,
      targetAccountIds,
      ignoredMasterTaskId
    );
  }

  /**
   * Returns the course occurrences a period should display. It normally returns all of the period course occurrences.
   * The only exception is for teachers. We remove occurrences where the user is not a teacher.
   *
   * @private
   * @param {SchoolDayPeriod} period
   * @returns {CourseOccurrence[]}
   * @memberof AppPeriodPriorityStore
   */
  getDisplayedOccurrencesForPeriod(period: SchoolDayPeriod): CourseOccurrence[] {
    const account = this.account;

    if (account.role === 'teacher') {
      return period.courseOccurrences.filter(
        (co) =>
          account.selectedSectionIds.includes(co.sectionId) ||
          co.teacherIds.includes(account.id) ||
          (this.sectionsById.get(co.sectionId) != null
            ? this.sectionsById.get(co.sectionId)!.autoEnrollRoles.includes('teacher')
            : false)
      );
    } else {
      return period.courseOccurrences;
    }
  }

  protected async loadData(isRefresh = false) {
    if (!this.startLoadingData()) {
      return;
    }

    const existingAccounts = Array.from(this._accounts.values());
    const existingSchoolDays = Array.from(this._schoolDays.values());
    const existingContents = Array.from(this._contents.values());

    try {
      const updateAvailable = await this._killSwitchService.checkForUpdate();
      if (updateAvailable) {
        console.log('AccountData - Skipping refresh since an update is available');
        this.stopLoadingData(new Error('Update available'));
        return;
      }

      // There is a known issue where Promise.all results are T | undefined.
      // https://github.com/microsoft/TypeScript/issues/33752
      const [configPb, accountsResult, contentsResult, conflictsResult] = await Promise.all([
        this._configTransport.fetchConfig(this.configId, false, this._config ? this._config.syncToken : undefined),
        this._configTransport.fetchAccounts(this.configId, false, this._accountsSyncToken),
        this._contentTransport.fetchContents({
          configId: this.configId,
          accountIds: [this.accountId],
          includeCompleted: true,
          includeCancelled: true,
          syncToken: this._contentsSyncToken
        }),
        this._generatorTransport.fetchConfigSectionSchedulingConflicts(this.configId, this._scheduleConflictsSyncToken)
      ]);

      const accounts = accountsResult?.result.map((pb) => new Account(pb)) ?? [];
      const contents = contentsResult?.result.map((pb) => new ContentDefinition(pb)) ?? [];

      const myAccount = this._accounts.get(this.accountId) ?? accounts.find((a) => a.id == this.accountId);

      if (myAccount == null) {
        throw new Error("Unexpected: user's AccountSummary.id was not found in all the config's accounts.");
      }

      const config = configPb != null ? new SchoolYearConfiguration(configPb) : this._config;
      const sectionIds = config != null ? this.getSectionIdsForAccount(myAccount, config.sections) : [];

      const schoolDaysResult = await this._generatorTransport.fetchSchoolDays(
        this.configId,
        [...sectionIds],
        myAccount.preferredScheduleTag,
        this._schoolDaysSyncToken
      );
      const schoolDays = schoolDaysResult.result.map((pb) => new SchoolDay(pb));

      const taughtSectionContents: ContentDefinitionModel[] = await this.loadTaughtSectionContents(myAccount, config);

      runInAction(() => {
        try {
          this._config = config;

          accounts.filter((a) => a.isDeleted).forEach((a) => this._accounts.delete(a.id));
          this._accounts.merge(
            _.keyBy(
              accounts.filter((a) => !a.isDeleted),
              (a) => a.id
            )
          );

          contents.filter((c) => c.isDeleted).forEach((c) => this._contents.delete(c.id));
          this._contents.merge(
            _.keyBy(
              contents.filter((c) => !c.isDeleted),
              (c) => c.id
            )
          );

          taughtSectionContents.filter((c) => c.isDeleted).forEach((c) => this._contents.delete(c.id));
          this._contents.merge(
            _.keyBy(
              taughtSectionContents.filter((c) => !c.isDeleted),
              (c) => c.id
            )
          );

          // When we provide a syncToken to the generator and the generated calendar has not changed,
          // schoolDays is empty. In that case, we don't need to update our local store.
          if (schoolDays.length > 0) {
            this._schoolDays.replace(_.keyBy(schoolDays, (sd) => sd.day.asString));
          }

          if (conflictsResult?.result != null) {
            // If not undefined means something has changed since last call
            // Keeping changes. Else do not replace.
            this._scheduleConflicts = conflictsResult.result;
          }

          this._accountsSyncToken = accountsResult?.syncToken;
          this._contentsSyncToken = contentsResult?.syncToken;
          this._schoolDaysSyncToken = schoolDaysResult.syncToken;
          this._scheduleConflictsSyncToken = conflictsResult?.syncToken;

          if (!isRefresh && this._demoInterceptor != null) {
            const isDemoSchool = config?.isDemo ?? false;

            // Every time we reload data, we update these default values.
            this._demoInterceptor.isPreventingChanges = isDemoSchool;

            if (isDemoSchool) {
              dateService.mockToday(config?.demoDay ?? dateService.realToday);
            } else {
              dateService.unmockToday();
            }
          }

          this.stopLoadingData(undefined);
        } catch (e) {
          // Reapplying existing data if there was some before
          if (this.hasData) {
            this._accounts.replace(_.keyBy(existingAccounts, (a) => a.id));
            this._schoolDays.replace(_.keyBy(existingSchoolDays, (s) => s.day.asString));
            this._contents.replace(_.keyBy(existingContents, (c) => c.id));
          }

          this.stopLoadingData(e as Error);
        }
      });
    } catch (error) {
      // Reapplying existing data if there was some before
      if (this.hasData) {
        runInAction(() => {
          this._accounts.replace(_.keyBy(existingAccounts, (a) => a.id));
          this._schoolDays.replace(_.keyBy(existingSchoolDays, (s) => s.day.asString));
          this._contents.replace(_.keyBy(existingContents, (c) => c.id));
        });
      }

      this.stopLoadingData(error as Error);
    }
  }

  private async loadTaughtSectionContents(
    account: AccountModel,
    config: SchoolYearConfigurationModel | undefined
  ): Promise<ContentDefinitionModel[]> {
    if (account.role !== 'teacher') {
      return [];
    }

    const taughtSectionIds = config != null ? this.getTaughtSectionIdsForAccount(account, config.sections) : [];

    if (taughtSectionIds.length === 0) {
      return [];
    }

    const taughtSectionsContentsSyncTokenSource: Record<string, string[]> = {};
    const teacherIds = new Set<string>();

    taughtSectionIds.forEach((id) => {
      const section = config!.sections.find((s) => s.id === id);
      if (section != null) {
        taughtSectionsContentsSyncTokenSource[id] = section.teacherIds;
        section.teacherIds.forEach((t) => teacherIds.add(t));
      }
    });

    const isTaughtSectionsSyncTokenStillValid = _.isEqual(
      taughtSectionsContentsSyncTokenSource,
      this._taughtSectionsContentsSyncTokenSource
    );

    const [taughtSectionsContentsResult, taughtSectionsPublishedContentsResult] = await Promise.all([
      this._contentTransport.fetchContents({
        configId: this.configId,
        includeCompleted: true,
        includeCancelled: true,
        kindsToInclude: ['task'],
        accountIds: Array.from(teacherIds.values()),
        sectionIds: taughtSectionIds,
        syncToken: isTaughtSectionsSyncTokenStillValid ? this._taughtSectionsContentsSyncToken : undefined
      }),
      this._contentTransport.fetchContents({
        configId: this.configId,
        includeCompleted: true,
        includeCancelled: true,
        kindsToInclude: ['task'],
        sectionIds: taughtSectionIds,
        publishedState: 'publishedMaster',
        syncToken: isTaughtSectionsSyncTokenStillValid ? this._taughtSectionsPublishedContentsSyncToken : undefined
      })
    ]);

    this._taughtSectionsContentsSyncToken = taughtSectionsContentsResult.syncToken;
    this._taughtSectionsPublishedContentsSyncToken = taughtSectionsPublishedContentsResult.syncToken;
    this._taughtSectionsContentsSyncTokenSource = taughtSectionsContentsSyncTokenSource;

    return _.uniqBy(
      _.concat(taughtSectionsContentsResult.result, taughtSectionsPublishedContentsResult.result),
      (cd) => cd.id
    ).map((pb) => new ContentDefinition(pb));
  }

  private getSectionIdsForAccount(account: AccountModel, sections: SectionModel[]): string[] {
    if (account.role === 'individual') {
      return sections.map((s) => s.id);
    }

    const sectionIds = new Set(account.selectedSectionIds);

    sections.filter((s) => this.getSectionIsAutoEnrolled(account, s)).forEach((s) => sectionIds.add(s.id));

    return [...sectionIds];
  }

  private getTaughtSectionIdsForAccount(account: AccountModel, sections: SectionModel[]): string[] {
    return sections
      .filter((s) => {
        return s.teacherIds.indexOf(account.id) >= 0;
      })
      .map((s) => s.id);
  }

  private getSectionIsAutoEnrolled(account: AccountModel, section: SectionModel): boolean {
    if (section.autoEnrollRoles.indexOf(account.role) >= 0) {
      return true;
    } else if (account.role === 'teacher') {
      return section.teacherIds.indexOf(account.id) >= 0;
    } else if (section.autoEnrollTags.indexOf(`gradeLevel=${account.gradeLevel}`) >= 0) {
      return true;
    }

    return false;
  }
}
