import { ContentDefinition_PublishTarget as PBPublishTarget } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModel, EditableValueArrayProperty, EditableValueProperty } from '../EditableModel';
import { PublishStatus, PublishTargetKind } from '../types';
import { protobufFromPublishTargetKind } from '../types/EnumConversion';
import { ContentPublishTarget, ContentPublishTargetModel } from './ContentPublishTarget';

export class EditableContentPublishTarget extends EditableModel<PBPublishTarget> implements ContentPublishTargetModel {
  private _kind: EditableValueProperty<PublishTargetKind, PBPublishTarget>;
  private _isPrivate: EditableValueProperty<boolean, PBPublishTarget>;
  private _targetAccountIds: EditableValueArrayProperty<string, PBPublishTarget>;

  constructor(
    private readonly _originalPublishTarget: ContentPublishTargetModel,
    public readonly isNew = false
  ) {
    super(_originalPublishTarget.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._kind = new EditableValueProperty(
        _originalPublishTarget.kind,
        (pb, value) => (pb.kind = protobufFromPublishTargetKind(value))
      )),
      (this._isPrivate = new EditableValueProperty(
        _originalPublishTarget.isPrivate,
        (pb, value) => (pb.isPrivate = value)
      )),
      (this._targetAccountIds = new EditableValueArrayProperty(
        _originalPublishTarget.targetAccountIds,
        (pb, value) => (pb.targetAccountIds = value)
      ))
    ]);
  }

  //
  // Readonly properties
  //

  get publishedAt(): Date | undefined {
    return this._originalPublishTarget.publishedAt;
  }

  get status(): PublishStatus {
    return this._originalPublishTarget.status;
  }

  get lastPublishingAt(): Date | undefined {
    return this._originalPublishTarget.lastPublishingAt;
  }

  get lastPublishedAt(): Date | undefined {
    return this._originalPublishTarget.lastPublishedAt;
  }

  get lastPublishTraceId(): string {
    return this._originalPublishTarget.lastPublishTraceId;
  }

  //
  // Editable properties
  //

  @computed
  get kind(): PublishTargetKind {
    return this._kind.value;
  }

  set kind(value: PublishTargetKind) {
    this._kind.value = value;
  }

  @computed
  get isPrivate(): boolean {
    return this._isPrivate.value;
  }

  set isPrivate(value: boolean) {
    this._isPrivate.value = value;
  }

  @computed
  get targetAccountIds(): string[] {
    return this._targetAccountIds.value;
  }

  set targetAccountIds(value: string[]) {
    this._targetAccountIds.value = value;
  }

  //
  // Utilities
  //

  clone(): EditableContentPublishTarget {
    const pb = this._originalPublishTarget.toProtobuf().clone();
    return new EditableContentPublishTarget(new ContentPublishTarget(pb), this.isNew);
  }
}
