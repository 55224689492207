import { Box, Divider, SxProps, useTheme } from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import { StudyoThemeService } from '../../../services';
import { YearMonthViewModel } from '../../../viewmodels';
import { YearDay } from './YearDay';
import { YearMonthDayOfWeeks } from './YearMonthDayOfWeeks';
import { YearMonthTitle } from './YearMonthTitle';

export interface YearMonthMonthProps {
  sx?: SxProps;
  className?: string;
  month: YearMonthViewModel;
  onSelection?: (schoolDay: SchoolDay) => void;
  localizationService?: LocalizationService;
  canDisplayDayInfo?: boolean;
  themeService?: StudyoThemeService;
}

export const YearMonth = observer((props: YearMonthMonthProps) => {
  const { month, sx, className, onSelection, canDisplayDayInfo = true } = props;
  const theme = useTheme();

  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column">
      <YearMonthTitle sx={{ px: 0.5 }} month={month} />

      <Divider sx={{ mt: 0.5, mb: 2 }} color={theme.palette.text.primary} />

      <YearMonthDayOfWeeks sx={{ height: 34 }} />

      <Box display="flex" flexDirection="column">
        {month.weeks.map((week, i) => {
          return (
            <Box display="flex" key={`week-${i}`}>
              {week.map((day, sdIndex) => (
                <YearDay
                  key={`week-${i}-schoolDay-${sdIndex}`}
                  day={day}
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  onSelection={onSelection}
                  canDisplayDayInfo={canDisplayDayInfo}
                />
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});
