import { ContentStepModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { TimelineContentState } from './TimelineContentState';
import { TimelineUtils } from './TimelineUtils';

export interface TimelineListStepViewModel {
  readonly title?: string;
  readonly date?: Day;
  readonly state: TimelineContentState;
}

export class AppTimelineListStepViewModel implements TimelineListStepViewModel {
  readonly step: ContentStepModel;

  constructor(contentId: string, stepId: string, data: AccountData) {
    makeObservable(this);
    const content = data.contentsById.get(contentId)!;
    this.step = _.find(content.steps, (s) => s.id === stepId)!;
  }

  @computed
  get title() {
    return this.step.title;
  }

  @computed
  get date() {
    return this.step.date;
  }

  @computed
  get state(): TimelineContentState {
    return TimelineUtils.getTimelineStateForStep(this.step);
  }
}
