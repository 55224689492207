import { Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { DayAndWeekHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader } from '../header';

export interface WeeklyHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekHeaderViewModel;
  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
}

export const WeeklyHeader = observer(
  ({ sx, viewModel, goToNextWeek, goToPreviousWeek, className }: WeeklyHeaderProps) => {
    return (
      <CalendarHeader
        sx={sx}
        className={className}
        viewModel={viewModel}
        goToNextPage={goToNextWeek}
        goToPreviousPage={goToPreviousWeek}
        dateSwitcherContext="weekly"
        actionButtons={[{ kind: 'options', action: () => void viewModel.displayOptions() }]}
        renderDate={(sx) => (
          <Stack sx={sx} alignItems="center" direction="row">
            <Typography sx={{ userSelect: 'none' }} variant="h6">
              {viewModel.weeklyDateTitle}
            </Typography>
          </Stack>
        )}
      />
    );
  }
);
