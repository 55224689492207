import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, GearMenuShareView, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface GearMenuShareScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const GearMenuShareScreen = observer((props: GearMenuShareScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onCancel, onSuccess } = props;
  const viewModel = useMemo(() => viewModelFactory.createGearMenuShare(onSuccess!, onCancel!), [onSuccess, onCancel]);

  const strings = localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => viewModel.close()}
    >
      <DialogHeader title={strings.share} onClose={() => viewModel.close()} />

      <GearMenuShareView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.close()} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
