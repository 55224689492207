import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SpecialDaySymbolAndColor, SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { DateUtils } from '@shared/components/utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TimelineCollectionViewSchoolDayHeaderViewModel } from '../../../viewmodels';
import { DayInfo } from '../utils';

export interface TimelineCollectionViewSchoolDayHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel?: TimelineCollectionViewSchoolDayHeaderViewModel;
  itemWidth: number;
}

export const TimelineCollectionViewSchoolDayHeader = observer((props: TimelineCollectionViewSchoolDayHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel, itemWidth } = props;
  const theme = useTheme();
  let symbols: SpecialDaySymbolAndColor[] = viewModel?.symbolsAndColors ?? [];

  const backgroundColor =
    viewModel != null && DateUtils.isToday(viewModel.schoolDay.day)
      ? theme.studyo.todaySymbolBackgroundColor
      : _.first(symbols.filter((s) => s.symbol !== 'none').map((s) => s.color));

  if (symbols.length === 0) {
    symbols = [{ color: undefined, symbol: 'none' }];
  }

  const cycleDayTitle = viewModel?.cycleDayTitle ?? '';

  return (
    <Stack sx={{ ...sx, width: itemWidth, overflow: 'visible' }} className={className}>
      <DayInfo
        day={viewModel != null ? viewModel.schoolDay.day : undefined}
        key={viewModel != null ? viewModel.schoolDay.day.asString : undefined}
      >
        <Stack flex={1} position="relative">
          {backgroundColor != null && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: -0.5,
                bottom: 0,
                opacity: 0.15,
                backgroundColor,
                zIndex: 1
              }}
            />
          )}

          <Stack p={0.5} alignItems="center" zIndex={2} spacing={0.5}>
            <Stack alignItems="center">
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2" noWrap>
                  {viewModel != null ? viewModel.schoolDayDay : ''}
                </Typography>

                {cycleDayTitle.length > 0 && (
                  <Typography variant="caption" fontSize={8} noWrap color="textSecondary">
                    {cycleDayTitle}
                  </Typography>
                )}
              </Stack>

              <Typography variant="caption" fontWeight="500" noWrap>
                {viewModel != null
                  ? localizationService.localizedStrings.models.dayOfWeek.shortLocalizedDayOfWeek(viewModel.dayOfWeek)
                  : ''}
              </Typography>
            </Stack>

            <SpecialDaySymbolGrid symbols={symbols} squareSize={10} displayKind={'linear'} maxSymbolCount={2} />
          </Stack>
        </Stack>
      </DayInfo>
    </Stack>
  );
});
