import { CourseOccurrence } from '@shared/models/calendar';
import { AccountModel } from '@shared/models/config';
import { Locale } from '@shared/resources/services';
import { StudyoObjectId } from '@shared/services/stores';

export class CourseOccurrenceUtils {
  static getSortedTeacherAccounts(
    occurrence: CourseOccurrence,
    accountsById: Map<StudyoObjectId, AccountModel>,
    locale: Locale
  ): AccountModel[] {
    const teachers = occurrence.teacherIds
      .map((id) => accountsById.get(id))
      .filter((teacher) => teacher != null) as AccountModel[];

    const options = { sensitivity: 'base' as const };
    return [...teachers].sort(
      (teacher1, teacher2) =>
        teacher1.lastName.localeCompare(teacher2.lastName, locale, options) ||
        teacher1.firstName.localeCompare(teacher2.firstName, locale, options)
    );
  }
}
