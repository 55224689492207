import { ListItemButton, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileMenuProfileViewModel } from '../../../viewmodels';
import { AvatarView } from '../AvatarView';

export interface ProfileMenuProfileRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuProfileViewModel;
}

export const ProfileMenuProfileRow = observer((props: ProfileMenuProfileRowProps) => {
  const { viewModel, sx, className } = props;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => void viewModel.selectProfile(location, navigate)}>
      <AvatarView
        sx={{ ml: 4, mr: 2 }}
        size={40}
        firstName={viewModel.firstName}
        lastName={viewModel.lastName}
        color={viewModel.color}
        isImpersonating={viewModel.isImpersonating}
      />
      <ListItemText primary={viewModel.displayName} secondary={`${viewModel.schoolName} (${viewModel.schoolTitle})`} />
    </ListItemButton>
  );
});
