import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AgendaWorkloadView } from '../../../views';

export interface AgendaWorkloadScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaWorkloadScreen = observer((props: AgendaWorkloadScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx, className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createAgendaWorkload(), [accountId, configId]);

  return <AgendaWorkloadView sx={sx} className={className} viewModel={viewModel} />;
});
