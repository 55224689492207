import { AccountUtils, CourseOccurrenceUtils, SectionUtils } from '@shared/components/utils';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { AccountModel } from '@shared/models/config';
import { Day, Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { EmailInfo, LinkingService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { OptionsDialogElement } from '@studyo/models';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigationService } from '../../../services';

export interface DayAndWeekPeriodInfoBoxViewModel {
  // backgroundColor will be passed in props by parent (Period)
  readonly courseOrdinal: string;
  readonly isSkipped: boolean;
  readonly hasSection: boolean;
  readonly periodStartTime: Time;
  readonly periodEndTime: Time;
  readonly roomName: string;
  readonly sectionNumber: string;
  readonly sectionTitle: string;
  readonly sectionShortTitle: string;
  readonly shadowColor?: string;
  readonly showTeacherEmailSelection: boolean;
  readonly teacherName: string;
  readonly teacherSelectionElements: OptionsDialogElement[];
  readonly teacherHasEmail: boolean;

  readonly hideTeacherSelection: () => void;
  readonly openNoteEdit: () => void;
  readonly sendEmailToTeacher: () => void;
}

export class AppDayAndWeekPeriodInfoBoxViewModel implements DayAndWeekPeriodInfoBoxViewModel {
  @observable private _showTeacherEmailSelection = false;

  constructor(
    private readonly _day: Day,
    private readonly _period: SchoolDayPeriod,
    private readonly _data: AccountData,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _linkingService: LinkingService
  ) {
    makeObservable(this);
  }

  @computed
  private get courseOccurrence() {
    return this._data.periodPrioritiesStore.getOccurrenceForPeriod(this._period, this._day);
  }

  @computed
  private get section() {
    if (this.courseOccurrence != null) {
      const sectionId = this.courseOccurrence.sectionId;
      return this._data.sectionsById.get(sectionId);
    }
    return undefined;
  }

  @computed
  get isSkipped() {
    return this.courseOccurrence?.skipped ?? false;
  }

  @computed
  get sectionTitle() {
    return this.section != null ? this.section.title : '';
  }

  @computed
  get sectionShortTitle() {
    return this.section != null ? this.section.shortTitle || this.section.title.slice(0, 3) : '';
  }

  @computed
  get sectionNumber() {
    return this.section != null ? this.section.sectionNumber : '';
  }

  @computed
  get roomName() {
    return this.hasSection
      ? this.courseOccurrence!.customRoomName.length > 0
        ? this.courseOccurrence!.customRoomName
        : this.courseOccurrence!.roomName
      : '';
  }

  @computed
  get courseOrdinal() {
    return this.courseOccurrence?.displayTitle ?? '';
  }

  @computed
  get teacherName() {
    if (this.section == null) {
      return '';
    }

    const teachers = this.sortedTeacherAccounts;
    const firstTeacher = SectionUtils.getFirstTeacher(this.section, teachers);

    if (firstTeacher == null) {
      return '';
    }

    const firstDisplayName = AccountUtils.getDisplayLastFirstName(firstTeacher, firstTeacher.visibleEmail);

    if (teachers.length > 1) {
      return `${firstDisplayName} + ${teachers.length - 1}`;
    } else {
      return firstDisplayName;
    }
  }

  @computed
  get teacherSelectionElements() {
    return _.map(this.sortedTeacherAccounts, (t, i) => {
      const element: OptionsDialogElement = {
        title: AccountUtils.getDisplayLastFirstName(t, t.visibleEmail),
        type: 'normal',
        selectionHandler: () => {
          this.didSelectTeacherAtIndex(i);
        }
      };

      return element;
    });
  }

  @computed
  get teacherHasEmail() {
    return this.sortedTeacherAccounts.find((teacher) => teacher.visibleEmail.length > 0) != null;
  }

  // This property will be used in the info box to help decide the text's color depending on the section.
  @computed
  get hasSection() {
    return this.courseOccurrence != null;
  }

  @computed
  get periodStartTime() {
    return this._period.startTime;
  }

  @computed
  get periodEndTime() {
    return this._period.endTime;
  }

  @computed
  get showTeacherEmailSelection() {
    return this._showTeacherEmailSelection;
  }

  @action
  hideTeacherSelection() {
    this._showTeacherEmailSelection = false;
  }

  openNoteEdit() {
    return this._navigationService.navigateToNoteEditModal(
      this._day,
      this._period.tag,
      this.courseOccurrence?.sectionId
    );
  }

  sendEmailToTeacher() {
    if (this.sortedTeacherAccounts != null) {
      if (this.sortedTeacherAccounts.length > 1) {
        runInAction(() => {
          this._showTeacherEmailSelection = true;
        });
      } else {
        this.didSelectTeacherAtIndex(0);
      }
    }
  }

  @computed
  private get sortedTeacherAccounts(): AccountModel[] {
    if (this.courseOccurrence != null) {
      return CourseOccurrenceUtils.getSortedTeacherAccounts(
        this.courseOccurrence,
        this._data.accountsById,
        this._localizationService.currentLocale
      );
    }

    return [];
  }

  private didSelectTeacherAtIndex(index: number) {
    this.hideTeacherSelection();

    if (this.sortedTeacherAccounts != null) {
      const message = AccountUtils.getEmailBody(
        this.sectionTitle,
        this._data,
        this._localizationService.localizedStrings
      );
      const teacher = this.sortedTeacherAccounts[index];
      const info: EmailInfo = {
        to: [teacher.visibleEmail],
        body: message
      };

      this._linkingService.launchEmail(info);
    }
  }
}
