import { TaskIconPublishKind } from '@shared/components/contents';
import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Color, Day } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { NavigationService } from '../../../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../../../services/analytics';

export interface ContentListItemViewModel {
  readonly content: ContentDefinitionModel;
  readonly displayColor: Color | undefined;
  readonly isAssignment: boolean;
  readonly taskIconPublishKind: TaskIconPublishKind;
  readonly hasPublishError: boolean;

  displayContentInfo: () => Promise<void>;
}

export class AppContentListItemViewModel implements ContentListItemViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _data: AccountData,
    private readonly _contentSection: SectionModel | undefined,
    private readonly _displayedDay: Day,
    private readonly _periodTag: string | undefined,
    private readonly _displayedSectionId: string | undefined,
    public readonly content: ContentDefinitionModel
  ) {
    makeObservable(this);
  }

  @computed
  get isAssignment(): boolean {
    return !this.content.dueDay.isSame(this._displayedDay);
  }

  @computed
  get displayColor(): Color | undefined {
    return SectionUtils.getSectionColor(this._contentSection, this._data.account, this._displayedSectionId);
  }

  get taskIconPublishKind(): TaskIconPublishKind {
    return ContentDefinitionUtils.getTaskIconPublishKind(this.content, this._data.sectionsById, this._data.config);
  }

  @computed
  get hasPublishError() {
    return this.content.publishTarget?.status === 'publish-error';
  }

  async displayContentInfo(): Promise<void> {
    if (this.content.kind === 'task') {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.viewTask });

      await this._data.ensureMarkedAsRead(this.content.id);
      await this._navigationService.navigateToTaskInfoModal(this.content.id);
    } else if (this._periodTag != null) {
      await this._navigationService.navigateToNoteEditModal(
        this._displayedDay,
        this._periodTag,
        this._contentSection?.id
      );
    }
  }
}
