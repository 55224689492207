import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { findIndex } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface TimelineHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly currentDay: Day;
  readonly dateTitle: string;
  readonly newTaskDueDay: Day;
  readonly showPlusSign: boolean;
  readonly hasContentFiltersSet: boolean;

  displayFiltersOptions: () => Promise<void | DialogCancelled>;
  displayOptions: () => Promise<void | DialogCancelled>;
}

export class AppTimelineHeaderViewModel extends AppBaseAgendaHeaderViewModel implements TimelineHeaderViewModel {
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore,
    readonly hasFiltersSet: boolean
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  private get currentDayIndex() {
    let currentDay = this._uiSettingsStore.timelineCurrentDay;

    if (currentDay == null) {
      currentDay = dateService.today;
    }
    const index = this.findIndexForDay(currentDay);

    if (index >= 0) {
      return index;
    } else {
      if (currentDay.isBefore(this._data.schoolDays[0].day)) {
        return 0;
      } else {
        return this._data.schoolDays.length - 1;
      }
    }
  }

  @computed
  get currentDay(): Day {
    return this._data.schoolDays[this.currentDayIndex].day;
  }

  @computed
  get dateTitle(): string {
    return this.currentDay.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  @computed
  get newTaskDueDay() {
    const today = dateService.today;
    let index = this.findIndexForDay(today);

    if (index < 0) {
      if (today.isBefore(this._data.schoolDays[0].day)) {
        index = 0;
      } else {
        index = this._data.schoolDays.length - 1;
      }
    }

    return this._data.schoolDays[index].day;
  }

  @computed
  get showPlusSign() {
    return !this._data.isReadOnly;
  }

  @computed
  get hasContentFiltersSet(): boolean {
    return this._preferences.timelineContentFilters.isFiltering;
  }

  displayFiltersOptions() {
    return this._navigationService.navigateToTimelineFilterOptionsModal();
  }

  displayOptions() {
    return this._navigationService.navigateToTimelineOptionMenuModal();
  }

  private findIndexForDay(day: Day) {
    return findIndex(this._data.schoolDays, (sd) => sd.day.isSame(day));
  }
}
