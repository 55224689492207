import { observer } from 'mobx-react';

import { Box, SxProps } from '@mui/material';
import ListValues from '../ListValues';

export interface ListSectionFooterProps {
  sx?: SxProps;
  className?: string;
}

export const ListSectionFooter = observer((props: ListSectionFooterProps) => {
  const { sx, className } = props;

  return <Box sx={{ height: ListValues.sectionFooterHeight, ...sx }} className={className} />;
});
