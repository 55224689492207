import { NetworkService } from '@shared/services';
import { differenceInMilliseconds } from 'date-fns';
import { AccountAutoSyncService } from './AccountAutoSyncService';
import { AccountService } from './AccountService';

const CheckInterval = 5000; // 5 seconds
const NeedRefreshThreshold = 60000; // 60 seconds

export class WebAccountAutoSyncService implements AccountAutoSyncService {
  private _suspensionCount = 0;
  private _handle: ReturnType<typeof setTimeout> | undefined;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _networkService: NetworkService
  ) {
    this.startAutoSync();

    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
  }

  public suspend() {
    this._suspensionCount++;
  }

  public resume() {
    if (this._suspensionCount === 0) {
      console.warn('Called resume without calling suspend before');
    }

    this._suspensionCount--;
  }

  private startAutoSync() {
    if (document.visibilityState !== 'visible') {
      return;
    }

    this.stopAutoSync();

    this._handle = setTimeout(() => this.refreshIfNecessary(), CheckInterval);
  }

  private stopAutoSync() {
    if (this._handle === undefined) {
      return;
    }

    clearTimeout(this._handle);
    this._handle = undefined;
  }

  private handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.startAutoSync();
    } else {
      this.stopAutoSync();
    }
  }

  private refreshIfNecessary() {
    try {
      if (this._accountService.currentDisplayedAccount == null) {
        return;
      }

      console.group('AccountAutoSyncService');

      const accountData = this._accountService.displayedAccountData;
      // Note: Do not use DateService.
      const outOfDate =
        accountData.lastRefreshTimestamp != null &&
        differenceInMilliseconds(new Date(), accountData.lastRefreshTimestamp) >= NeedRefreshThreshold;

      if (this._suspensionCount || accountData.isRefreshing || !this._networkService.isOnline || !outOfDate) {
        console.group('Skipping sync');
        console.log('Suspension count:', this._suspensionCount);
        console.log('Account already refreshing:', accountData.isRefreshing);
        console.log('Network is online:', this._networkService.isOnline);
        console.log('Out of date:', outOfDate);
        console.groupEnd();

        return;
      }

      console.log('Refreshing the account data');

      void accountData.refresh();
    } finally {
      console.groupEnd();
      this.startAutoSync();
    }
  }
}
