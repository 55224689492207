import { Locale, LocalizationService, ThemeService } from '@shared/resources/services';
import { ThemeKind } from '@shared/resources/themes';
import { computed, makeObservable, observable } from 'mobx';
import { AccountService, StudyoAccountSettings, StudyoSettingsStore } from '../../services';

export type SettableThemeKind = ThemeKind | 'system';

export interface PreferencesViewModel {
  locale: Locale;
  theme: SettableThemeKind;
  availableThemes: SettableThemeKind[];
  announcementDateToday: boolean;
  newTaskDueNextPeriod: boolean;
  readonly canDeleteAccount: boolean;

  close: () => void;
  deleteAccount: () => Promise<void>;
}

export class AppPreferencesViewModel implements PreferencesViewModel {
  // Working with a local variable instead of the value in localizationService
  // as it would block the preferences UI update when changing the language
  @observable private _currentLocale: Locale;
  private readonly _preferences: StudyoAccountSettings;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _themeService: ThemeService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._preferences = _settingsStore.getPreferences(_accountService.displayedAccountData.accountId);
    this._currentLocale = _localizationService.currentLocale;
  }

  @computed
  get locale() {
    return this._currentLocale;
  }

  set locale(value: Locale) {
    this._currentLocale = value;
    requestAnimationFrame(() => this._localizationService.setCurrentLocale(value));
  }

  @computed
  get theme(): SettableThemeKind {
    return this._themeService.currentKind ?? 'system';
  }

  set theme(value: SettableThemeKind) {
    this._themeService.setTheme(value === 'system' ? undefined : value);
  }

  get availableThemes(): SettableThemeKind[] {
    const available = this._themeService.availableKinds;

    // We only suggest the system theme if there are 2+ themes.
    return available.length > 1 ? ['system', ...available] : available;
  }

  @computed
  get announcementDateToday() {
    return this._preferences.announcementDateToday;
  }

  set announcementDateToday(value: boolean) {
    this._preferences.announcementDateToday = value;
  }

  @computed
  get newTaskDueNextPeriod() {
    return this._preferences.newTaskDueNextPeriod;
  }

  set newTaskDueNextPeriod(value: boolean) {
    this._preferences.newTaskDueNextPeriod = value;
  }

  @computed
  get canDeleteAccount(): boolean {
    return this._accountService.currentDisplayedAccount?.configurationSummary?.type === 'standalone';
  }

  close() {
    this._onSuccess();
  }

  async deleteAccount() {
    await this._accountService.requestAccountDeletion();
  }
}
