import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';
import { StudyoAccountSettings } from '../../../services';
import { AppSpecialDaySymbolViewModel, SpecialDaySymbolViewModel } from '../../utils';

export interface PlannerSchoolDayHeaderViewModel {
  readonly isToday: boolean;
  readonly day: Day;
  readonly cycleDayTitle: string;
  readonly specialDaySymbols: SpecialDaySymbolViewModel[];
  readonly specialDaysTitle: string;
  readonly isFirstDayOfCycle: boolean;
  readonly displayWeekendIndicator: boolean;
}

export class AppPlannerSchoolDayHeaderViewModel implements PlannerSchoolDayHeaderViewModel {
  constructor(
    private readonly _schoolDay: SchoolDay,
    private readonly _preferences: StudyoAccountSettings
  ) {
    makeObservable(this);
  }

  get isFirstDayOfCycle() {
    return this._schoolDay.cycleDay === 1;
  }

  get cycleDayTitle() {
    return this._schoolDay.displayCycleDayTitle;
  }

  @computed
  get isToday(): boolean {
    return DateUtils.isToday(this._schoolDay.day);
  }

  @computed
  get day(): Day {
    return this._schoolDay.day;
  }

  @computed
  get specialDaySymbols(): SpecialDaySymbolViewModel[] {
    const filteredSpecialDays = this._schoolDay.specialDays.filter((sp) => sp.symbol !== 'none');
    return filteredSpecialDays.map((sp) => {
      return new AppSpecialDaySymbolViewModel(sp.symbol, SectionColors.get(sp.color)!);
    });
  }

  @computed
  get specialDaysTitle(): string {
    const allTitles = this._schoolDay.specialDays.map((sp) => sp.title);
    return allTitles.join(', ');
  }

  @computed
  get displayWeekendIndicator() {
    return !this._preferences.plannerDisplayWeekends;
  }
}
