import { Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { YearHeaderViewModel } from '../../../viewmodels';
import { CalendarHeader } from '../header';

export interface YearHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: YearHeaderViewModel;
}

export const YearHeader = observer(({ sx, viewModel, className }: YearHeaderProps) => {
  return (
    <CalendarHeader
      sx={sx}
      className={className}
      viewModel={viewModel}
      dateSwitcherContext={undefined}
      actionButtons={[]}
      renderDate={(sx) => (
        <Stack sx={sx} alignItems="center" direction="row">
          <Typography sx={{ userSelect: 'none' }} variant="h6">
            {viewModel.dateTitle}
          </Typography>
        </Stack>
      )}
    />
  );
});
