import { SchoolDay } from '@shared/models/calendar';
import { EditableContentDefinition, EditableContentStep } from '@shared/models/content';
import { chain, find } from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { AppTaskStepListItemViewModel, TaskStepListItemViewModel } from './TaskStepListItemViewModel';

export interface TaskStepListViewModel {
  readonly canEdit: boolean;
  readonly canCompleteSteps: boolean;
  readonly steps: TaskStepListItemViewModel[];
  readonly canReorderSteps: boolean;

  createEmptyStep: () => TaskStepListItemViewModel;
  saveEmptyStep: () => void;
  reorder: (oldIndex: number, newIndex: number) => void;
}

export class AppTaskStepListViewModel implements TaskStepListViewModel {
  private _lastCreatedEmptyStep: TaskStepListItemViewModel | undefined;

  constructor(
    private readonly _schoolDays: SchoolDay[],
    private readonly _content: EditableContentDefinition,
    private readonly _displayMasterContentSteps: boolean,
    private readonly _onChange: (() => void) | undefined,
    public readonly canEdit: boolean,
    public readonly canCompleteSteps: boolean,
    public readonly canReorderSteps: boolean
  ) {
    makeObservable(this);
  }

  @computed
  private get displayedSteps() {
    if (this._displayMasterContentSteps && this._content.masterContent != null) {
      return this._content.masterContent.stepsList.map((s) => new EditableContentStep(s, false));
    } else {
      return this._content.steps;
    }
  }
  @computed
  get steps() {
    return chain(this.displayedSteps)
      .filter((step) => !step.shouldBeDeleted)
      .orderBy((s) => s.sortOrder)
      .map(
        (step) =>
          new AppTaskStepListItemViewModel(
            this._content,
            step,
            false,
            this._onChange,
            this._schoolDays,
            this.canEdit,
            this.canCompleteSteps,
            this.canReorderSteps
          )
      )
      .value();
  }

  createEmptyStep() {
    const step = EditableContentStep.createNew(this._content, undefined);
    const stepVM = new AppTaskStepListItemViewModel(
      this._content,
      step,
      true,
      this._onChange,
      this._schoolDays,
      this.canEdit,
      this.canCompleteSteps,
      false
    );

    this._lastCreatedEmptyStep = stepVM;
    return stepVM;
  }

  saveEmptyStep() {
    if (
      this._lastCreatedEmptyStep != null &&
      (this._lastCreatedEmptyStep.title.length > 0 || this._lastCreatedEmptyStep.date != null)
    ) {
      this._lastCreatedEmptyStep.saveNewStep();
    }
  }

  @action
  reorder(oldIndex: number, newIndex: number) {
    const reorderedStep = this.steps[oldIndex];
    this._content.steps.forEach((step) => {
      // Because the sortOrder is a number starting from 1 and index is a number starting from 0
      const stepIndex = step.sortOrder - 1;
      if (newIndex > oldIndex && stepIndex >= oldIndex && stepIndex <= newIndex) {
        step.sortOrder -= 1;
      } else if (newIndex < oldIndex && stepIndex >= newIndex && stepIndex <= oldIndex) {
        step.sortOrder += 1;
      }
    });

    const editableStep = find(this._content.steps, { id: reorderedStep.id });
    editableStep!.sortOrder = newIndex + 1;
  }
}
