import { CircularProgress, Stack, SxProps } from '@mui/material';
import { OnboardingBackgroundImage } from '@studyo/components';

export interface InitializingScreenProps {
  sx?: SxProps;
  className?: string;
}

export function InitializingScreen(props: InitializingScreenProps) {
  const { sx, className } = props;

  return (
    <Stack alignItems="center" justifyContent="center" sx={sx} className={className} position="relative">
      <OnboardingBackgroundImage />
      <CircularProgress sx={{ zIndex: 2 }} />
    </Stack>
  );
}
