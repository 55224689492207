import { Stack, SxProps, Typography } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { ContentDefinitionModel } from '@shared/models/content';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ContentActionHeaderProps {
  sx?: SxProps;
  className?: string;
  content: ContentDefinitionModel;
}

export const ContentActionHeader = observer((props: ContentActionHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, content } = props;

  // Note: No publish error overlay here.

  return (
    <Stack direction="row" sx={sx} className={className} p={2} alignItems="center" spacing={2}>
      <TaskIcon
        icon={content.icon}
        workloadLevel={content.workloadLevel}
        squareSize={36}
        externalSource={content.externalContent != null ? content.externalContent.sourceIntegration : undefined}
        hasSteps={content.steps.length > 0}
      />

      <Stack flex={1}>
        <Typography noWrap variant="body1" fontWeight="500">
          {ContentDefinitionUtils.getDisplayTitleForContent(content, localizationService.localizedStrings)}
        </Typography>

        {content.notes.length > 0 && (
          <Typography noWrap variant="body2">
            {content.notes}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
});
