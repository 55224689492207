import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { OptionMenuView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface PeriodOptionMenuScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const PeriodOptionMenuScreen = observer((props: PeriodOptionMenuScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { className, sx, onSuccess, onCancel } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createPeriodOptionMenu(onSuccess!, onCancel!),
    [onSuccess, onCancel]
  );
  const strings = localizationService.localizedStrings.studyo.agenda.periods.optionMenu;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      onClose={() => viewModel.dismiss()}
      maxHeight="large"
    >
      <DialogHeader title={strings.title} onClose={() => viewModel.dismiss()} />

      <OptionMenuView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={() => viewModel.dismiss()} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
