import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { DragEvent } from 'react';
import { dragManager } from './DragManager.ts';

export interface DroppableProps {
  sx?: SxProps;
  className?: string;
  children: React.ReactNode;
  acceptedType: string;
  canDropData?: (data: unknown) => boolean;
  handleDragOver?: (data: unknown) => void;
  handleDragLeave?: () => void;
  handleDrop: (data: unknown) => void;
}

/**
 * Represents an area where a Draggable can be dropped. it will only handle the drop if its type
 * is the same as the Draggable element.
 *
 * Props:
 *
 * * **acceptedType**: Type of content it accepts.
 * * **handleDragOver**: Callback when dragging over this view.
 * * **handleDragLeave** Callback when the drag over is ending.
 * * **handleDrop**: Callback when a Draggable is dropped on this view.
 *
 * @export
 * @class Droppable
 * @extends {React.Component<DroppableProps>}
 */
export const Droppable = observer((props: DroppableProps) => {
  const {
    sx,
    handleDragOver,
    handleDragLeave,
    children,
    canDropData = () => true,
    handleDrop,
    className,
    acceptedType
  } = props;
  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    const dataType = dragManager.draggedDataType;

    // If the drag event contains a value for this Droppable type, we handle the drag over.
    if (dataType != '' && dataType === acceptedType && canDropData(dragManager.draggedData)) {
      // As the default behaviour of a web element is to not handle to drop, we need to prevent the default behaviour.
      // This will result in the view being able to handle the drop of another element.
      handleDragOver?.(dragManager.draggedData);

      // For now, destinations don't have a say.
      if (dragManager.canCopyMove) {
        event.dataTransfer.dropEffect = dragManager.updateIsMoving(!event.ctrlKey) ? 'move' : 'copy';
      }
    }
  };

  const onDragLeave = () => {
    handleDragLeave?.();
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    const dataType = dragManager.draggedDataType;

    // If the drag event contains a value for this Droppable type, we handle the drop.
    if (dataType != '' && dataType === acceptedType && canDropData(dragManager.draggedData)) {
      // As the default behaviour of a web element is to not handle to drop, we need to prevent the default behaviour.
      // This will result in the view being able to handle the drop of another element.
      handleDrop(dragManager.draggedData);
    }
  };

  return (
    <Box
      sx={{ ...sx, display: 'flex', justifyContent: 'stretch', alignItems: 'stretch', position: 'relative' }}
      className={className}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {children}
    </Box>
  );
});
