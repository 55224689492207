import { UserProfile as PBUserProfile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_auth_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { AccountSummary } from '../config';
import { UserRole } from '../types';
import { loginKindFromProtobuf, userRoleFromProtobuf } from '../types/EnumConversion';

export class UserProfile {
  private _pb: PBUserProfile;

  constructor(pb: PBUserProfile) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get userId(): string {
    return this._pb.userId;
  }

  get username(): string {
    return this._pb.username;
  }

  get email(): string {
    return this._pb.email;
  }

  get isEmailConfirmed(): boolean {
    return this._pb.isEmailConfirmed;
  }

  get userRole(): UserRole {
    return userRoleFromProtobuf(this._pb.userRole);
  }

  @LazyGetter()
  get accountSummaries(): AccountSummary[] {
    return this._pb.accountSummaries.map((a) => new AccountSummary(a));
  }

  get loginKinds() {
    return this._pb.loginKinds.map((k) => loginKindFromProtobuf(k));
  }

  // Should only be used by the admin console to help troubleshoot user issues.
  get parseUserId() {
    return this._pb.parseUserId;
  }

  get isGoogleUser(): boolean {
    return this.loginKinds.includes('google');
  }

  get intercomHash(): string {
    return this._pb.intercomHash;
  }
}
