import { TaskIconPublishKind } from '@shared/components/contents';
import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface DisplayableContentViewModel {
  readonly content: ContentDefinitionModel;
  readonly displayTitle: string;
  readonly color: Color | undefined;
  readonly isAssignment: boolean;
  readonly sectionTitle: string;
  readonly sectionColor: Color | undefined;
  readonly isDisplayedInASection: boolean;
  readonly canEdit: boolean;
  readonly taskIconPublishKind: TaskIconPublishKind;
  readonly hasPublishError: boolean;
  readonly targetAccounts: AccountModel[];
  readonly originalAuthor?: AccountModel;
}

export class AppDisplayableContentViewModel implements DisplayableContentViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _data: AccountData,
    private readonly _section: SectionModel | undefined,
    private readonly _displayedInSectionId: string | undefined,
    readonly content: ContentDefinitionModel,
    readonly isAssignment: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get canEdit(): boolean {
    return !this._data.isReadOnly;
  }

  @computed
  get displayTitle(): string {
    return ContentDefinitionUtils.getDisplayTitleForContent(this.content, this._localizationService.localizedStrings);
  }

  @computed
  get color(): Color | undefined {
    return SectionUtils.getSectionColor(this._section, this._data.account, this._displayedInSectionId);
  }

  @computed
  get isDisplayedInASection() {
    return this._section != null;
  }

  @computed
  get taskIconPublishKind(): TaskIconPublishKind {
    return ContentDefinitionUtils.getTaskIconPublishKind(this.content, this._data.sectionsById, this._data.config);
  }

  @computed
  get sectionColor(): Color | undefined {
    return this._section != null
      ? SectionUtils.getSectionColor(this._section, this._data.account, undefined)
      : undefined;
  }

  @computed
  get sectionTitle(): string {
    return this._section != null ? this._section.title : '';
  }

  @computed
  get hasPublishError() {
    return this.content.publishTarget?.status === 'publish-error';
  }

  @computed
  get targetAccounts() {
    if (this.content.isMasterPublishedToAccounts) {
      return _.compact(this.content.publishTarget!.targetAccountIds.map((id) => this._data.accountsById.get(id)));
    }

    return [];
  }

  @computed
  get originalAuthor() {
    if (this.content.ownerId != this._data.account.id) {
      // Students see their copy of the task, so they are the owner. This is only useful for a teacher
      // having access to a co-teacher's task.
      return this._data.accountsById.get(this.content.ownerId);
    }
  }
}
