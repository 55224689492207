import { SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { NavigationService } from '../../../services';

export interface PlannerSectionHeaderViewModel {
  readonly sectionTitle: string;
  readonly sectionNumber: string;
  readonly color: Color;
  readonly canDisplaySectionInfo: boolean;

  displaySectionInfo: () => void;
}

export class AppPlannerSectionHeaderViewModel implements PlannerSectionHeaderViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _section: SectionModel,
    private readonly _data: AccountData
  ) {
    makeObservable(this);
  }

  @computed
  get sectionTitle(): string {
    return this._section.shortTitle || this._section.title.slice(0, 3);
  }

  @computed
  get sectionNumber(): string {
    return this._section.sectionNumber;
  }

  @computed
  get color(): Color {
    const sectionColor = SectionUtils.getSectionColor(
      this._section,
      this._data.accountsById.get(this._data.accountId)!,
      undefined
    );
    if (sectionColor == null) {
      throw new Error('A Section should always have have a color.');
    }

    return sectionColor;
  }

  @computed
  get canDisplaySectionInfo(): boolean {
    return !this._data.isImpersonating && this._section.teacherIds.indexOf(this._data.account.id) >= 0;
  }

  displaySectionInfo() {
    void this._navigationService.navigateToPlannerSectionInfo(this._section.id);
  }
}
