import { GetPublishingTaskWorkloadImpactResponse } from '@buf/studyo_studyo.bufbuild_es/studyo/services/contents_pb';
import { WorkloadGroup, WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';
import { Account, AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinition, ContentDefinitionModel } from '@shared/models/content';
import { concat, flatten, orderBy } from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface WorkloadInfo {
  readonly workload: GetPublishingTaskWorkloadImpactResponse;
  readonly targetSectionId: string;
  readonly isAlreadyPublished?: boolean;
}

export interface WorkloadContentViewModel {
  readonly content: ContentDefinitionModel;
  readonly section?: SectionModel;
  readonly teacher?: AccountModel;
}

export interface WorkloadGroupViewModel {
  readonly accounts: AccountModel[];
  readonly targetGroup?: string;
  readonly contents: WorkloadContentViewModel[];
  readonly impact: WorkloadImpactSummary;
}

export interface WorkloadImpactDetailsViewModel {
  readonly groups: WorkloadGroupViewModel[];

  close(): void;
  proceed(): void;
}

export class AppWorkloadGroupViewModel implements WorkloadGroupViewModel {
  constructor(
    private readonly _group: WorkloadGroup,
    private readonly _targetSectionId: string,
    private readonly _sectionsById: Map<string, SectionModel>,
    private readonly _accountsById: Map<string, AccountModel>
  ) {
    makeObservable(this);
  }

  @computed
  get accounts() {
    return this._group.accounts.map((a) => new Account(a));
  }

  @computed
  get targetGroup() {
    const targetSection = this._sectionsById.get(this._targetSectionId);

    return targetSection?.sectionNumber;
  }

  @computed
  get contents() {
    return this._group.contents
      .map((c) => ({
        content: new ContentDefinition(c),
        section: this._sectionsById.get(c.sectionId)
      }))
      .map((c) => ({ ...c, teacher: this._accountsById.get(c.section?.defaultTeacherId ?? '') }));
  }

  get impact() {
    return this._group.impact;
  }
}

export class AppWorkloadImpactDetailsViewModel implements WorkloadImpactDetailsViewModel {
  constructor(
    private readonly _workloadInfos: WorkloadInfo[],
    private readonly _sectionsById: Map<string, SectionModel>,
    private readonly _accountsById: Map<string, AccountModel>,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get groups() {
    return orderBy(
      flatten(
        this._workloadInfos.map((info) =>
          concat(info.workload.dayGroups, info.workload.weekGroups).map(
            (g) => new AppWorkloadGroupViewModel(g, info.targetSectionId, this._sectionsById, this._accountsById)
          )
        )
      ),
      (vm) => vm.impact,
      'desc'
    );
  }

  close(): void {
    this._onCancel();
  }

  proceed(): void {
    this._onSuccess();
  }
}
