import { LocalizationService } from '@shared/resources/services';
import { LinkingService } from '@shared/services';
import { computed, makeObservable } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { DailyHeaderViewModel } from './DailyHeaderViewModel';
import { AppDayAndWeekViewModel, DayAndWeekViewModel } from './DayAndWeekViewModel';

export type DailyViewModel = DayAndWeekViewModel;

export class AppDailyViewModel extends AppDayAndWeekViewModel implements DailyViewModel {
  private _dailyHeaderViewModel: DailyHeaderViewModel;

  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    contentPasteboardStore: ContentPasteboardStore,
    accountService: AccountService,
    analyticsService: StudyoAnalyticsService,
    settingsStore: StudyoSettingsStore,
    uiSettingsStore: UISettingsStore,
    attachmentManager: AttachmentManager,
    linkingService: LinkingService
  ) {
    const preferences = settingsStore.getPreferences(accountService.displayedAccountData.accountId);
    // set the number of schoolDaysPerPage to 1 automatically for the daily

    super(
      navigationService,
      localizationService,
      contentPasteboardStore,
      analyticsService,
      linkingService,
      preferences,
      uiSettingsStore,
      'daily',
      accountService,
      1
    );

    makeObservable(this);

    this._dailyHeaderViewModel = new DailyHeaderViewModel(
      this,
      localizationService,
      navigationService,
      attachmentManager,
      this.data,
      preferences,
      uiSettingsStore
    );
  }

  @computed
  get currentDays() {
    return this.allDays;
  }

  @computed
  get headerViewModel() {
    return this._dailyHeaderViewModel;
  }
}
