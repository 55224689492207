import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SpecialDaySymbolAndColor, SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';

import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { FullSpecialDaySymbols } from '@shared/models/types';
import { observer } from 'mobx-react';
import { YearDayViewModel } from '../../../viewmodels';
import { DayInfo } from '../utils';

export interface YearDayProps {
  sx?: SxProps;
  className?: string;
  day?: YearDayViewModel;
  onSelection?: (schoolDay: SchoolDay) => void;
  canDisplayDayInfo?: boolean;
}

export const YearDay = observer((props: YearDayProps) => {
  const { day, sx, className, onSelection, canDisplayDayInfo = true } = props;
  const theme = useTheme();
  const colors = theme.studyo.agenda.yearly.day;

  const symbols: SpecialDaySymbolAndColor[] = [];
  let dayFontColor: string;

  const onDaySelection = () => {
    if (day != null && onSelection != null) {
      const schoolDay = day.schoolDay;
      onSelection(schoolDay);
    }
  };

  if (day != null) {
    if (DateUtils.isToday(day.schoolDay.day)) {
      dayFontColor = theme.palette.common.white;
      symbols.push({ color: colors.todaySymbolColor, symbol: 'circle' });
    } else if (day.isSelected) {
      dayFontColor = theme.palette.common.white;
      symbols.push({ color: 'black', symbol: 'circle' });
    } else {
      symbols.push(
        ...day.schoolDay.specialDays.map((sp) => ({ color: SectionColors.get(sp.color), symbol: sp.symbol }))
      );

      const hasFullSpecialDay = FullSpecialDaySymbols.some(
        (sp) => symbols.findIndex((symbol) => symbol.symbol === sp) >= 0
      );

      if (hasFullSpecialDay) {
        dayFontColor = theme.palette.common.white;
      } else if (DateUtils.isWeekend(day.schoolDay.day)) {
        dayFontColor = theme.palette.text.secondary;
      } else {
        dayFontColor = day.isDisabled ? theme.palette.text.secondary : theme.palette.text.primary;
      }
    }
  } else {
    dayFontColor = colors.normalTextColor;
  }

  const canSelect = day != null && !day.isDisabled && onSelection != null;

  return (
    <Box
      display="flex"
      sx={{ ...sx, height: 36, overflow: 'visible', position: 'relative' }}
      className={className}
      justifyContent="center"
    >
      <DayInfo
        sx={{
          width: 32,
          height: 32,
          overflow: 'visible',
          m: '2px'
        }}
        day={day != null ? day.schoolDay.day : undefined}
        canSelect={canDisplayDayInfo || canSelect}
        customOnSelect={canSelect ? onDaySelection : undefined}
        key={day != null ? day.schoolDay.day.asString : undefined}
        roundedCorners
      >
        <Stack
          direction="row"
          sx={{ overflow: 'visible', width: '100%', height: '100%' }}
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Box sx={{ width: 28, height: 28, position: 'absolute', top: 2, left: 2, right: 2, zIndex: 1 }}>
            <SpecialDaySymbolGrid squareSize={28} symbols={symbols} />
          </Box>

          {day != null && day.isHighlighted && (
            <Box
              sx={{
                borderColor: theme.palette.primary.main,
                width: 28,
                height: 28,
                position: 'absolute',
                top: 1,
                left: 1,
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: 1,
                zIndex: 2
              }}
            />
          )}

          <Typography
            variant="body2"
            textAlign="center"
            sx={{ color: dayFontColor, flex: 1, userSelect: 'none', zIndex: 3 }}
          >
            {day != null ? day.schoolDay.day.day : ''}
          </Typography>

          <Typography
            variant="caption"
            fontSize={11}
            color="textSecondary"
            sx={{ userSelect: 'none', position: 'absolute', left: 32, top: 2 }}
          >
            {day != null ? day.schoolDay.displayCycleDayTitle : ''}
          </Typography>
        </Stack>
      </DayInfo>
    </Box>
  );
});
