import { Divider, ListItem, ListItemText, Stack, SxProps } from '@mui/material';
import { CourseSelectionSectionListViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { CourseSelectionSectionListItem } from './CourseSelectionSectionListItem';

export interface CourseSelectionSectionListProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSelectionSectionListViewModel;
  isSearchResults?: boolean;
  didChangeSelectedForSectionId: (sectionId: string, selected: boolean) => void;
}

export const CourseSelectionSectionList = observer((props: CourseSelectionSectionListProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, didChangeSelectedForSectionId, viewModel, isSearchResults = false } = props;
  const strings = localizationService.localizedStrings.studyo.onboarding.createConfig;
  const items = viewModel.courseItems;

  return (
    <Stack sx={sx} className={className} overflow="auto">
      {items.map((item, i) => {
        return (
          <Fragment key={`selected-courses-list-item-${i}`}>
            <CourseSelectionSectionListItem
              viewModel={item}
              didChangeSelected={(sectionId, isSelected) => didChangeSelectedForSectionId(sectionId, isSelected)}
            />

            {i < items.length - 1 && <Divider />}
          </Fragment>
        );
      })}

      {items.length === 0 && (
        <ListItem>
          <ListItemText primary={isSearchResults ? strings.noCoursesMatched : strings.noCoursesSelected} />
        </ListItem>
      )}
    </Stack>
  );
});
