import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { ConfigLinkListView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface ConfigLinkListScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const ConfigLinkListScreen = observer((props: ConfigLinkListScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onSuccess, onCancel } = props;
  const viewModel = useMemo(() => viewModelFactory.createConfigLinkList(onSuccess!, onCancel!), []);
  const strings = localizationService.localizedStrings.studyo.agenda.setting.links;

  const close = () => viewModel.close();

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
    >
      <DialogHeader title={strings.title} onClose={close} />

      <ConfigLinkListView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
