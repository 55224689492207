import { DateUtils } from '@shared/components/utils';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { Dictionary, first, keyBy, last } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { AccountService } from '../../services';
import { AppYearDayViewModel, AppYearMonthViewModel, YearDayViewModel, YearMonthViewModel } from '../agenda';

export interface SchoolDayPickerViewModel {
  readonly currentMonthIndex: number;
  readonly months: YearMonthViewModel[];
  setSelectedDay: (day: Day) => void;
  setHighlightedDays: (days: Day[]) => void;
  setMinimumAndMaximumDays: (minimumDay: Day | undefined, maximumDay: Day | undefined) => void;
  setCurrentDay: (day: Day) => void;
}

export class AppSchoolDayPickerViewModel implements SchoolDayPickerViewModel {
  @observable private _currentMonthIndex = 0;
  private _highlightedDays: Dictionary<Day>;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    initialHighlightedDays: Day[] = []
  ) {
    makeObservable(this);
    this._highlightedDays = keyBy(initialHighlightedDays, (d) => d.asString);
  }

  @computed
  private get data(): AccountData {
    return this._accountService.displayedAccountData;
  }

  @computed.struct
  private get days(): YearDayViewModel[] {
    return this.data.schoolDays.map(
      (sd) => new AppYearDayViewModel(sd, this._highlightedDays[sd.day.asString] != null)
    );
  }

  @computed
  get currentMonthIndex(): number {
    return this._currentMonthIndex;
  }

  @computed
  get months(): YearMonthViewModel[] {
    const configFirstDay = first(this.data.schoolDays);
    const configLastDay = last(this.data.schoolDays);

    if (configFirstDay == null || configLastDay == null) {
      return [];
    }

    const months: YearMonthViewModel[] = [];
    let firstMonthDate = Day.create({ day: 1, month: configFirstDay.day.month, year: configFirstDay.day.year });

    while (firstMonthDate.isSameOrBefore(configLastDay.day)) {
      const schoolDays = this.days.filter((day) => DateUtils.daysAreInSameMonth(firstMonthDate, day.schoolDay.day));
      months.push(new AppYearMonthViewModel(this._localizationService, firstMonthDate, schoolDays));
      firstMonthDate = firstMonthDate.addMonths(1);
    }

    return months;
  }

  setSelectedDay(day: Day) {
    this.days.forEach((dayVM) => (dayVM.isSelected = dayVM.schoolDay.day.isSame(day)));
  }

  setHighlightedDays(days: Day[]) {
    this._highlightedDays = keyBy(days, (d) => d.asString);
    this.days.forEach((dayVM) => (dayVM.isHighlighted = this._highlightedDays[dayVM.schoolDay.day.asString] != null));
  }

  setMinimumAndMaximumDays(minimumDay: Day | undefined, maximumDay: Day | undefined) {
    if (minimumDay == null && maximumDay == null) {
      this.days.forEach((dayVM) => (dayVM.isDisabled = false));
    } else if (minimumDay == null) {
      this.days.forEach((dayVM) => (dayVM.isDisabled = dayVM.schoolDay.day.isAfter(maximumDay!)));
    } else if (maximumDay == null) {
      this.days.forEach((dayVM) => (dayVM.isDisabled = dayVM.schoolDay.day.isBefore(minimumDay)));
    } else {
      this.days.forEach((dayVM) => (dayVM.isDisabled = !dayVM.schoolDay.day.isWithin(minimumDay, maximumDay)));
    }
  }

  @action
  setCurrentDay(day: Day) {
    const monthIndex = this.months.findIndex((month) =>
      month.weeks.some((week) => week.some((d) => d?.schoolDay.day.isSame(day) ?? false))
    );

    const newIndex = monthIndex >= 0 ? monthIndex : 0;

    if (newIndex !== this._currentMonthIndex) {
      this._currentMonthIndex = newIndex;
    }
  }
}
