import { Storage } from '@shared/services';
import moize from 'moize';
import { AppStudyoAccountSettings } from './AppStudyoAccountSettings';
import { StudyoAccountSettings } from './StudyoAccountSettings';
import { StudyoSettingsStore } from './StudyoSettingsStore';

export class AppStudyoSettingsStore implements StudyoSettingsStore {
  constructor(private readonly _localStorageResolver: () => Storage) {}

  getPreferences = moize(
    (accountId: string): StudyoAccountSettings => new AppStudyoAccountSettings(this._localStorageResolver, accountId)
  );

  readonly useIOSAccessTokenProvider = false;
}
