import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import {
  AttachmentManager,
  NavigationService,
  StudyoAccountSettings,
  StudyoEnvironmentService,
  UISettingsStore
} from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface WorkloadHeaderViewModel extends BaseAgendaHeaderViewModel {
  openInsights(): void;
  refresh(): void;
}

export class AppWorkloadHeaderViewModel extends AppBaseAgendaHeaderViewModel implements WorkloadHeaderViewModel {
  constructor(
    public readonly refresh: () => void,
    private readonly _environmentService: StudyoEnvironmentService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    data: AccountData,
    attachmentManager: AttachmentManager,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
  }

  openInsights() {
    const { configId } = this._data;
    const locale = this._localizationService.currentLocale;
    let url = this._environmentService.insightsBaseUrl;
    url += `/configs/${configId}/metrics/dashboard?lang=${locale}`;
    window.open(url);
  }
}
