import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog, SaveDialog, useSaveDialog } from '@studyo/components';
import { AskNameView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface AskNameScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const AskNameScreen = observer((props: AskNameScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onCancel, onSuccess } = props;
  const viewModel = useMemo(() => viewModelFactory.createAskName(onSuccess!, onCancel!), []);
  const strings = localizationService.localizedStrings.studyo.agenda.setting.askName;
  const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

  const onConfirmPressed = () => {
    startSave();
  };

  const onSaveComplete = (success: boolean) => {
    resetSave();

    if (success) {
      viewModel.close();
    }
  };

  return (
    <ResponsiveDialog sx={sx} className={className} maxWidth="sm" fullWidth={true} open={true} maxHeight="medium">
      <DialogHeader
        title={strings.title}
        rightButton={{
          title: strings.confirm,
          action: onConfirmPressed,
          onlyShowOnExtraSmallScreen: true
        }}
      />

      <AskNameView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton
          type="normal"
          variant="contained"
          title={strings.confirm}
          onPress={onConfirmPressed}
          disabled={!viewModel.canSave}
        />
      </DialogActionButtons>

      <SaveDialog
        promise={savePromise}
        retryCall={retrySave}
        onClose={onSaveComplete}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
