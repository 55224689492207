import { InfoOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react';
import { PlannerSectionHeaderViewModel } from '../../../viewmodels';

export interface PlannerSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerSectionHeaderViewModel;
}

export const PlannerSectionHeader = observer((props: PlannerSectionHeaderProps) => {
  const { viewModel, sx, className } = props;
  const theme = useTheme();

  const backgroundColor = SectionColors.get(viewModel.color);

  const hasBackgroundColor = backgroundColor != null;
  const elementsColor = hasBackgroundColor
    ? theme.studyo.periods.periodWithSectionTextColor
    : theme.studyo.periods.freePeriodTextColor;

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: backgroundColor ?? theme.studyo.periods.freePeriodColor,
        color: elementsColor,
        display: 'flex',
        flexDirection: 'column'
      }}
      className={className}
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={0.5} p={0.5}>
        <Typography fontWeight="600" color="inherit" textAlign="center">
          {viewModel.sectionTitle}
        </Typography>

        {viewModel.sectionNumber != null && viewModel.sectionNumber.length > 0 && (
          <Typography variant="body2" color="inherit" textAlign="center">
            {viewModel.sectionNumber}
          </Typography>
        )}

        {viewModel.canDisplaySectionInfo && (
          <IconButton
            size="medium"
            onClick={() => viewModel.displaySectionInfo()}
            color="inherit"
            sx={{ alignSelf: 'center' }}
          >
            <InfoOutlined fontSize="medium" />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
});
