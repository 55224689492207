import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoSettingsStore } from '@studyo/services';
import { computed, makeObservable } from 'mobx';
import {
  AppOptionMenuElement,
  AppOptionMenuSectionViewModel,
  AppOptionMenuViewModel,
  OptionMenuSectionViewModel,
  OptionMenuViewModel
} from '../../utils';

export type TimelineOptionMenuViewModel = OptionMenuViewModel;

export class AppTimelineOptionMenuViewModel extends AppOptionMenuViewModel implements TimelineOptionMenuViewModel {
  constructor(
    accountService: AccountService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    super(accountService, localizationService, navigationService, onSuccess, onCancel, settingsStore);
    makeObservable(this);
  }

  @computed
  get sections(): OptionMenuSectionViewModel[] {
    if (this.preferences.hasData) {
      return this.loadSections();
    } else {
      return [];
    }
  }

  private loadSections(): OptionMenuSectionViewModel[] {
    const strings = this.localizationService.localizedStrings.studyo.agenda.timeline.optionMenu;
    const cellSize = this.preferences.timelineCellSize;
    const showAssignmentDay = this.preferences.timelineShowAssignmentDay;
    const limitTimelineWeeks = this.preferences.timelineLimitWeeks;

    const regularCellSizeOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 0),
      this.onRegularCellSizePress,
      strings.regularCellSizeLabel,
      cellSize === 'regular'
    );
    const largeCellSizeOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 1),
      this.onLargeCellSizePress,
      strings.largeCellSizeLabel,
      cellSize === 'large'
    );

    const cellSizeSection = new AppOptionMenuSectionViewModel(
      strings.cellSizeSectionTitle,
      [regularCellSizeOption, largeCellSizeOption],
      'single-selection'
    );

    const showAssignmentDayOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 0),
      this.onShowAssignmentDayPress,
      strings.showAssignmentDayIconLabel,
      showAssignmentDay
    );

    const limitTimelineWeeksOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 1),
      this.onLimitTimelineWeeksPress,
      strings.limitTimelineWeeksLabel,
      limitTimelineWeeks
    );

    const displaySection = new AppOptionMenuSectionViewModel(
      strings.displaySectionTitle,
      [showAssignmentDayOption, limitTimelineWeeksOption],
      'independent'
    );

    return [cellSizeSection, displaySection];
  }

  private onRegularCellSizePress = () => {
    this.preferences.timelineCellSize = 'regular';
  };

  private onLargeCellSizePress = () => {
    this.preferences.timelineCellSize = 'large';
  };

  private onShowAssignmentDayPress = (value: boolean) => {
    this.preferences.timelineShowAssignmentDay = value;
  };

  private onLimitTimelineWeeksPress = (value: boolean) => {
    this.preferences.timelineLimitWeeks = value;
  };
}
