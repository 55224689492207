import { Checkbox, ListItemButton, ListItemIcon, ListItemText, SxProps, Typography } from '@mui/material';
import { LinkedTasksPublishOccurrenceViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface LinkedTasksPublishOccurrenceCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: LinkedTasksPublishOccurrenceViewModel;
}

export const LinkedTasksPublishOccurrenceCell = observer((props: LinkedTasksPublishOccurrenceCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.linkedTasksPublish;

  return (
    <ListItemButton sx={sx} className={className} onClick={() => viewModel.selectPublishTarget()} component="div">
      <ListItemIcon>
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <Checkbox checked={viewModel.isPublished} onChange={() => {}} />
      </ListItemIcon>

      <ListItemText
        primary={viewModel.date.formattedString(localizationService.localizedStrings.models.dateFormats.long)}
        secondary={viewModel.periodTag.length > 0 ? strings.periodTag(viewModel.periodTag) : undefined}
      />

      <Typography variant="body2">{viewModel.publishStateDisplayText}</Typography>
    </ListItemButton>
  );
});
