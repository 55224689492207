import { SxProps, useTheme } from '@mui/material';
import { AccountUtils, TopIndicator } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface ImpersonatingIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const ImpersonatingIndicator = observer((props: ImpersonatingIndicatorProps) => {
  const { accountService, localizationService } = useStudyoServices();
  const { sx, className } = props;
  const theme = useTheme();
  const colors = theme.studyo.utils.indicators.impersonating;
  const data = accountService.displayedAccountData;

  if (!data.hasData) {
    return null;
  }

  const message = localizationService.localizedStrings.studyo.agenda.viewingAs(
    AccountUtils.getDisplayFirstLastName(data.account),
    data.impersonatingRole
  );

  return (
    <TopIndicator
      message={message}
      backgroundColor={colors.backgroundColor}
      display={data.isImpersonating}
      sx={sx}
      className={className}
    />
  );
});
