import { TaskEditViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';

import { ListItem, ListItemText, SxProps } from '@mui/material';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditSectionSelector } from './TaskEditSectionSelector.tsx';

export interface TaskEditSectionCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditSectionCell = observer((props: TaskEditSectionCellProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;

  return (
    <ListItem sx={sx} className={className}>
      <ListItemText primary={localizationService.localizedStrings.studyo.contents.taskEdit.section} />
      <TaskEditSectionSelector viewModel={viewModel} />
    </ListItem>
  );
});
