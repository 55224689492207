import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { LinkingService, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, when } from 'mobx';
import {
  AccountService,
  ContentPasteboardStore,
  DailyContentDisplayKind,
  NavigationService,
  StudyoAccountSettings,
  UISettingsStore,
  WeeklyContentDisplayKind
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { DayAndWeekHeaderViewModel } from './DayAndWeekHeaderViewModel';
import { AppDayAndWeekSchoolDayViewModel, DayAndWeekSchoolDayViewModel } from './DayAndWeekSchoolDayViewModel';

export type DayAndWeekViewModelKind = 'daily' | 'weekly';

export interface DayAndWeekViewModel {
  readonly isDaily: boolean;
  readonly data: AccountData;
  readonly currentDayIndex: number;
  readonly currentDays: DayAndWeekSchoolDayViewModel[];
  readonly header: DayAndWeekHeaderViewModel;
  readonly currentDisplayKind: DailyContentDisplayKind | WeeklyContentDisplayKind;
  readonly pointsPerHour: number;
  readonly pointsPerMinutes: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  goToToday: () => void;
  setCurrentDayIndex: (index: number) => void;
}

export abstract class AppDayAndWeekViewModel implements DayAndWeekViewModel {
  protected readonly preferences: StudyoAccountSettings;
  public readonly isDaily: boolean;

  @observable public readonly currentDisplayKind: DailyContentDisplayKind | WeeklyContentDisplayKind;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _linkingService: LinkingService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _viewModelKind: DayAndWeekViewModelKind,
    private readonly _accountService: AccountService,
    protected readonly _numberOfSchoolDaysPerPage: number
  ) {
    makeObservable(this);
    this.isDaily = _viewModelKind === 'daily';
    this.currentDisplayKind = this.isDaily
      ? _preferences.dailyContentDisplayKind
      : _preferences.weeklyContentDisplayKind;
    this.preferences = _preferences;

    when(
      () => this.data.hasData,
      () => {
        const isDailyAndShouldScrollToToday =
          this.isDaily &&
          (this._uiSettingsStore.dailyCurrentDay == null ||
            this.getIndexForDay(this._uiSettingsStore.dailyCurrentDay) === -1);

        const isWeeklyAndShouldScrollToToday =
          !this.isDaily &&
          (this._uiSettingsStore.weeklyCurrentDay == null ||
            this.getIndexForDay(this._uiSettingsStore.weeklyCurrentDay) === -1);

        if (isDailyAndShouldScrollToToday || isWeeklyAndShouldScrollToToday) {
          this.goToToday();
        }
      }
    );
  }

  abstract get currentDays(): DayAndWeekSchoolDayViewModel[];
  abstract get headerViewModel(): DayAndWeekHeaderViewModel;

  @computed
  get currentDayIndex() {
    const currentDay = this.isDaily ? this._uiSettingsStore.dailyCurrentDay : this._uiSettingsStore.weeklyCurrentDay;
    return currentDay != null ? this.getIndexForDay(currentDay) : 0;
  }

  @computed
  get data() {
    return this._accountService.displayedAccountData;
  }

  @computed.struct
  protected get allDays() {
    const schoolDays = this.data.schoolDays;
    return schoolDays.map(
      (currentDay) =>
        new AppDayAndWeekSchoolDayViewModel(
          this._localizationService,
          this._navigationService,
          this._contentPasteboardStore,
          this._analyticsService,
          this._linkingService,
          this._viewModelKind,
          this._preferences,
          currentDay,
          this.data
        )
    );
  }

  @computed
  get pointsPerHour() {
    switch (this.isDaily ? this._preferences.dailyPeriodHeight : this._preferences.weeklyPeriodHeight) {
      case 'small':
        return 48;
      case 'medium':
        return 72;
      case 'large':
        return 96;
      case 'very-large':
        return 144;
      default:
        return 72;
    }
  }

  @computed
  get header() {
    return this.headerViewModel;
  }

  @computed
  get pointsPerMinutes() {
    return this.pointsPerHour / 60;
  }

  @action
  goToToday() {
    const today = dateService.today;
    let todayIndex = _.findIndex(this.data.schoolDays, (sd) => sd.day.isSame(today));

    if (todayIndex === -1) {
      const isBeforeFirstDay = this.data.schoolDays.find((sd) => sd.day.isAfter(today)) != null;
      todayIndex = isBeforeFirstDay ? 0 : this.data.schoolDays.length - 1;
    }

    this.setCurrentDayIndex(todayIndex);
  }

  @action
  goToPreviousPage() {
    let newIndex = Math.max(0, this.currentDayIndex - this._numberOfSchoolDaysPerPage);

    if (this._numberOfSchoolDaysPerPage > 1) {
      const mondayIndex = this.getFirstMondayIndexBeforeIndex(newIndex);
      newIndex = mondayIndex;
    }

    this.setCurrentDayIndex(newIndex);
  }

  @action
  goToNextPage() {
    let newIndex = Math.min(this.data.schoolDays.length - 1, this.currentDayIndex + this._numberOfSchoolDaysPerPage);

    if (this._numberOfSchoolDaysPerPage > 1) {
      newIndex = this.getFirstMondayIndexBeforeIndex(newIndex);
    }

    this.setCurrentDayIndex(newIndex);
  }

  @action
  setCurrentDayIndex(index: number) {
    if (index !== this.currentDayIndex) {
      const schoolDay = this.data.schoolDays[index];
      if (schoolDay == null) {
        return;
      }

      const newDay = schoolDay.day;
      if (this.isDaily) {
        this._uiSettingsStore.dailyCurrentDay = newDay;
      } else {
        this._uiSettingsStore.weeklyCurrentDay = newDay;
      }
    }
  }

  protected getFirstMondayIndexBeforeIndex(index: number) {
    const currentDay = this.allDays[index].day;
    if (currentDay.dayOfWeek === 'monday') {
      return index;
    }

    const mondayIndex = index - currentDay.dayOfWeekNumber + 1;

    // if the array basically doesnt start on a monday.
    return mondayIndex >= 0 ? mondayIndex : index;
  }

  private getIndexForDay(day: Day) {
    const index = this.data.schoolDays.findIndex((sd) => sd.day.isSame(day));
    if (index >= 0) {
      return index;
    }

    const isBeforeFirstDay = this.data.schoolDays.find((sd) => sd.day.isAfter(day)) != null;
    return isBeforeFirstDay ? 0 : this.data.schoolDays.length - 1;
  }
}
