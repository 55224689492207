import { CircularProgress, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { OnboardingBackgroundImage } from '../components';

export interface LogoutScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LogoutScreen = observer((props: LogoutScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx, className } = props;

  const viewModel = useMemo(() => viewModelFactory.createLogout(), []);
  const navigate = useNavigate();
  useEffect(() => {
    void viewModel.onInit(navigate);
  }, [viewModel]);

  return (
    <Stack alignItems="center" justifyContent="center" sx={sx} className={className} position="relative">
      <OnboardingBackgroundImage />
      <CircularProgress sx={{ zIndex: 2 }} />
    </Stack>
  );
});
