import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { DailyViewModel } from './DailyViewModel';
import { AppDayAndWeekHeaderViewModel } from './DayAndWeekHeaderViewModel';

export class DailyHeaderViewModel extends AppDayAndWeekHeaderViewModel {
  readonly isDaily = true;
  protected readonly isWeekly = false;

  constructor(
    private _dailyViewModel: DailyViewModel,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  get currentSchoolDay() {
    return this._dailyViewModel.currentDays[this._dailyViewModel.currentDayIndex];
  }

  @computed
  get dayAndWeekViewModel() {
    return this._dailyViewModel;
  }

  displayOptions() {
    return this._navigationService.navigateToDailyOptionMenuModal();
  }
}
