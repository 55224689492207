import { LocalizationService, ThemeService } from '@shared/resources/services';
import { BaseStartUpService } from '@shared/services';
import { AccountService } from './AccountService';

export class AppStudyoStartUpService extends BaseStartUpService {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _themeService: ThemeService,
    private readonly _accountService: AccountService
  ) {
    super();
  }

  protected async initServices(): Promise<void> {
    // Though AccountService has indirect dependencies on LocalizationService and ThemeService,
    // it's initialization doesn't depend on this. We run them all in parallel.
    await Promise.all([
      this._localizationService.initialize(),
      this._themeService.initialize(),
      this._accountService.startSilentLogin()
    ]);
  }
}
