import { ThemeOptions, createTheme } from '@mui/material';
import { ThemeOptions as MuiThemeOptions, Theme } from '@mui/material/styles/createTheme';
import { Storage } from '@shared/services';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ThemeKind } from '../themes';

const storageKey = 'color-mode';

export interface ThemeService {
  readonly availableKinds: ThemeKind[];
  readonly currentKind: ThemeKind | undefined;
  readonly resolvedCurrentKind: ThemeKind;
  readonly currentMuiTheme: Theme;
  readonly currentMuiThemeOptions: MuiThemeOptions;

  initialize: () => Promise<void>;
  setTheme: (kind?: ThemeKind) => void;
}

export abstract class AppThemeService implements ThemeService {
  // The default kind is not observable by itself. Updating it via updateDefaultTheme
  // "can" affect the current theme if the current kind is "system".
  private _defaultKind: ThemeKind;
  @observable private _currentKind: ThemeKind | undefined;
  @observable private _currentMuiThemeOptions: ThemeOptions;

  constructor(
    defaultKind: ThemeKind,
    private readonly _storage: Storage
  ) {
    makeObservable(this);
    this._defaultKind = defaultKind;

    // This is just a default, updated by initialize().
    this._currentKind = defaultKind;
    this._currentMuiThemeOptions = this.getMaterialThemeForKind(defaultKind);
  }

  abstract get availableKinds(): ThemeKind[];

  @computed
  get currentKind(): ThemeKind | undefined {
    return this._currentKind;
  }

  @computed
  get resolvedCurrentKind(): ThemeKind {
    return this._currentKind ?? this._defaultKind;
  }

  @computed
  get currentMuiTheme(): Theme {
    return createTheme(this.currentMuiThemeOptions);
  }

  @computed
  get currentMuiThemeOptions(): ThemeOptions {
    return this._currentMuiThemeOptions;
  }

  async initialize(): Promise<void> {
    const currentKind: ThemeKind | undefined = await this._storage.get<ThemeKind>(storageKey);

    // Though we must always set observables from actions, this should only happen
    // while the app is initializing.
    runInAction(() => {
      this._currentKind = currentKind;
      this.updateThemeForKind(currentKind ?? this._defaultKind);
    });
  }

  @action
  setTheme(kind?: ThemeKind) {
    // We don't need to wait for these async calls. It's just for the next reload of the app.
    if (kind == null) {
      void this._storage.delete(storageKey);
    } else {
      void this._storage.set(storageKey, kind);
    }

    this._currentKind = kind;
    this.updateThemeForKind(kind ?? this._defaultKind);
  }

  @action
  updateDefaultTheme(kind: ThemeKind) {
    this._defaultKind = kind;

    if (this.currentKind == null) {
      // Since the current kind is "system", we must update the theme.
      this.updateThemeForKind(kind);
    }
  }

  private updateThemeForKind(kind: ThemeKind) {
    this._currentMuiThemeOptions = this.getMaterialThemeForKind(kind);
  }

  protected abstract getMaterialThemeForKind(kind: ThemeKind): ThemeOptions;
}
