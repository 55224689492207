import { Stack, SxProps } from '@mui/material';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { UseCodeView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface UseCodeScreenProps {
  sx?: SxProps;
  className?: string;
}

export const UseCodeScreen = observer((props: UseCodeScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx, className } = props;

  const params = useParams();
  const isInitialConfigNumeric = params.isInitialConfig ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createUseCode(isInitialConfigNumeric === '1'),
    [isInitialConfigNumeric]
  );

  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <UseCodeView sx={{ flex: 1, zIndex: 2 }} viewModel={viewModel} />
        </Stack>
      </div>
    </Stack>
  );
});
