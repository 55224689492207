import { Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { DataPresenter } from '../../components';
import { AskNameViewModel } from '../../viewmodels';

export interface AskNameViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AskNameViewModel;
}

export const AskNameView = observer((props: AskNameViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.askName;

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => (
        <Stack width="100%" height="100%" p={2} spacing={2} overflow="auto">
          <Typography textAlign="center">{strings.subTitle}</Typography>

          <TextField
            variant="filled"
            value={viewModel.firstName}
            label={strings.firstName}
            onChange={(e) => (viewModel.firstName = e.currentTarget.value)}
            autoFocus
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            variant="filled"
            value={viewModel.lastName}
            label={strings.lastName}
            onChange={(e) => (viewModel.lastName = e.currentTarget.value)}
            onSubmit={() => void viewModel.save()}
            inputProps={{ maxLength: 50 }}
          />

          <Typography textAlign="center" variant="subtitle2" color="error">
            {viewModel.message}
          </Typography>
        </Stack>
      )}
    />
  );
});
