import { css } from '@emotion/css';
import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { DoubleClickView } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import {
  AssignedPlannedIconSize,
  DueIconSize,
  LargeSpacerHeight,
  StepViewSize,
  TimelineCollectionViewItemViewModel,
  TimelineUtils,
  getAssignedPlannedIconPadding,
  getContentTitlePadding,
  getDueIconPadding
} from '../../../viewmodels';

export interface TimelineCollectionViewItemComponentProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineCollectionViewItemViewModel;
  itemWidth: number;
  itemHeight: number;
}

export const TimelineCollectionViewItem = observer((props: TimelineCollectionViewItemComponentProps) => {
  const { sx, className, viewModel, itemWidth, itemHeight } = props;
  const theme = useTheme();

  const { assignmentColor } = theme.studyo.contents.icons;
  const { taskIconPlannedColor } = theme.studyo.agenda.timeline;

  const assignmentLeft = viewModel.componentPositionToLeft('assigned', itemWidth);
  const assignedPlannedIconPadding = getAssignedPlannedIconPadding(itemWidth);

  const plannedLeft = viewModel.componentPositionToLeft('planned', itemWidth);

  const maxHeightForTitle = itemHeight - AssignedPlannedIconSize;
  const assignedPlannerTitleRowClassName =
    maxHeightForTitle < 20
      ? // Force that row into the flex.
        css({
          height: '12px',
          overflow: 'visible'
        })
      : // Let icon part use full flex and position the title. This
        // makes the icons look better centered.
        css({
          height: '12px',
          // 4 below icon
          bottom: maxHeightForTitle / 2 - 12 - 4,
          position: 'absolute',
          overflow: 'visible'
        });

  const dueLeft = viewModel.componentPositionToLeft('due', itemWidth);

  const assignmentLineEnd = (viewModel.contentState === 'completed' ? dueLeft : plannedLeft) + itemWidth / 2;
  const dottedAssignmentLineLeft = viewModel.shouldDisplayDottedAssignmentLine ? assignmentLeft : undefined;
  const assignmentLineLeft =
    !viewModel.shouldDisplayAssignedIcon || viewModel.shouldDisplayDottedAssignmentLine
      ? assignmentLeft + itemWidth
      : assignmentLeft + itemWidth / 2;
  const assignmentLineWidth = assignmentLineEnd - assignmentLineLeft;

  const dueLineWidth = dueLeft - plannedLeft;
  const dueLineLeft = plannedLeft + (AssignedPlannedIconSize / 2 + assignedPlannedIconPadding);

  const dayTitleLeft = DueIconSize + getContentTitlePadding(itemWidth);

  const didClickOnItem = () => {
    viewModel.didClickOnItem();
  };

  const didDoubleClickOnAssignedIcon = () => {
    viewModel.didDoubleClickOnAssignedIcon();
  };

  const didDoubleClickOnPlannedIcon = () => {
    viewModel.didDoubleClickOnPlannedIcon();
  };

  const didDoubleClickOnClippedPlannedIcon = () => {
    viewModel.didDoubleClickOnClippedPlannedIcon();
  };

  const didDoubleClickOnDueIcon = () => {
    viewModel.didDoubleClickOnDueIcon();
  };

  const iconClassName = css({
    position: 'absolute',
    overflow: 'visible',
    zIndex: 3
  });

  const renderAssignedIcon = (leftPosition: number): ReactNode => {
    if (viewModel.shouldEnableDoubleClickOnAssignedIcon) {
      return (
        <DoubleClickView
          className={iconClassName}
          sx={{ left: leftPosition }}
          onPress={didClickOnItem}
          onDoublePress={viewModel.canChangePlannedDay ? didDoubleClickOnAssignedIcon : undefined}
          propagateEvent={false}
        >
          <TaskIcon
            icon={viewModel.contentIcon}
            isAssignment={true}
            squareSize={AssignedPlannedIconSize}
            isOpaque={true}
            state={viewModel.contentState}
            workloadLevel={viewModel.workloadLevel}
            publishedKind={viewModel.contentTaskIconPublishKind}
            isUnread={viewModel.contentIsUnread}
            externalSource={viewModel.externalSource}
            isPrivate={viewModel.isPrivate}
            hasSteps={viewModel.allSteps.length > 0}
            hasPublishError={viewModel.hasPublishError}
          />
        </DoubleClickView>
      );
    } else {
      return (
        <Box className={iconClassName} sx={{ left: leftPosition }}>
          <TaskIcon
            icon={viewModel.contentIcon}
            isAssignment={true}
            squareSize={AssignedPlannedIconSize}
            isOpaque={true}
            state={viewModel.contentState}
            workloadLevel={viewModel.workloadLevel}
            publishedKind={viewModel.contentTaskIconPublishKind}
            isUnread={viewModel.contentIsUnread}
            externalSource={viewModel.externalSource}
            isPrivate={viewModel.isPrivate}
            hasSteps={viewModel.allSteps.length > 0}
            hasPublishError={viewModel.hasPublishError}
          />
        </Box>
      );
    }
  };

  return (
    <Stack
      sx={{
        ...sx,
        width: viewModel.componentWidth(itemWidth),
        maxWidth: itemWidth * 4,
        height: itemHeight,
        cursor: 'pointer',
        overflow: 'visible'
      }}
      onClick={didClickOnItem}
      className={className}
    >
      <Box display="flex" flexDirection="row" alignItems="center" flex={1} overflow="visible" position="relative">
        {viewModel.shouldDisplayAssignedIcon && renderAssignedIcon(assignmentLeft + assignedPlannedIconPadding)}

        {dottedAssignmentLineLeft != null && (
          <Box
            sx={{
              zIndex: 1,
              height: 0,
              position: 'absolute',
              backgroundColor: assignmentColor,
              borderWidth: 0,
              borderTopColor: theme.studyo.agenda.timeline.collectionViewDayViewBackgroundColor,
              borderTopWidth: 2,
              borderStyle: 'dashed',
              left: dottedAssignmentLineLeft,
              width: itemWidth
            }}
          />
        )}
        {viewModel.shouldDisplayAssignmentLine && (
          <Box
            sx={{
              zIndex: 1,
              height: 2,
              position: 'absolute',
              backgroundColor: assignmentColor,
              left: assignmentLineLeft,
              width: assignmentLineWidth
            }}
          />
        )}

        {viewModel.shouldDisplayPlannedIcon && (
          <DoubleClickView
            className={iconClassName}
            sx={{ left: plannedLeft + assignedPlannedIconPadding }}
            onPress={didClickOnItem}
            onDoublePress={viewModel.canChangePlannedDay ? didDoubleClickOnPlannedIcon : undefined}
            propagateEvent={false}
          >
            <TaskIcon
              icon={viewModel.contentIcon}
              workloadLevel={viewModel.workloadLevel}
              squareSize={AssignedPlannedIconSize}
              isOpaque={true}
              state={viewModel.contentState}
              publishedKind={viewModel.contentTaskIconPublishKind}
              isUnread={viewModel.contentIsUnread}
              externalSource={viewModel.externalSource}
              isPrivate={viewModel.isPrivate}
              hasSteps={viewModel.allSteps.length > 0}
              hasPublishError={viewModel.hasPublishError}
            />
          </DoubleClickView>
        )}
        {viewModel.shouldDisplayClippedPlannedIcon && (
          <DoubleClickView
            className={iconClassName}
            sx={{ left: assignedPlannedIconPadding }}
            onPress={didClickOnItem}
            onDoublePress={viewModel.canChangePlannedDay ? didDoubleClickOnClippedPlannedIcon : undefined}
            propagateEvent={false}
          >
            <TaskIcon
              icon={viewModel.contentIcon}
              workloadLevel={viewModel.workloadLevel}
              squareSize={AssignedPlannedIconSize}
              isOpaque={true}
              state={viewModel.contentState}
              publishedKind={viewModel.contentTaskIconPublishKind}
              isUnread={viewModel.contentIsUnread}
              externalSource={viewModel.externalSource}
              isPrivate={viewModel.isPrivate}
              isClipped={true}
              hasSteps={viewModel.allSteps.length > 0}
              hasPublishError={viewModel.hasPublishError}
            />
          </DoubleClickView>
        )}

        {viewModel.shouldDisplayDueLine && (
          <Box
            sx={{
              position: 'absolute',
              height: LargeSpacerHeight,
              backgroundColor: TimelineUtils.getStateColor(viewModel.timelineState, theme),
              left: dueLineLeft,
              width: dueLineWidth
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 1,
                backgroundColor: taskIconPlannedColor,
                width: dueLineWidth
              }}
            />
          </Box>
        )}

        {viewModel.steps.map((step) => {
          const state = TimelineUtils.getTimelineStateForStep(step);

          return (
            <Box
              key={`timeline-collectionview-item-${step.id}`}
              sx={{
                left: viewModel.stepViewPositionToLeft(step, itemWidth),
                backgroundColor: TimelineUtils.getStateColor(state, theme),
                position: 'absolute',
                zIndex: 3,
                height: StepViewSize,
                width: StepViewSize,
                borderRadius: '50%',
                pointerEvents: 'none'
              }}
            />
          );
        })}

        <DoubleClickView
          className={iconClassName}
          sx={{ left: dueLeft + getDueIconPadding(itemWidth) }}
          onPress={didClickOnItem}
          onDoublePress={viewModel.canCompleteTask ? didDoubleClickOnDueIcon : undefined}
          propagateEvent={false}
        >
          <TaskIcon
            icon={viewModel.contentIcon}
            isOpaque={true}
            workloadLevel={viewModel.workloadLevel}
            state={viewModel.contentState}
            publishedKind={viewModel.contentTaskIconPublishKind}
            isUnread={viewModel.contentIsUnread}
            externalSource={viewModel.externalSource}
            isPrivate={viewModel.isPrivate}
            squareSize={DueIconSize}
            hasSteps={viewModel.allSteps.length > 0}
            hasPublishError={viewModel.hasPublishError}
          />
        </DoubleClickView>

        <Typography
          sx={{
            left: dueLeft + dayTitleLeft,
            maxWidth: itemWidth * 4 - dayTitleLeft,
            userSelect: 'none',
            position: 'absolute'
          }}
          noWrap
          variant="caption"
        >
          {viewModel.contentTitle}
        </Typography>
      </Box>

      {viewModel.shouldDisplayAssignedPlannedTitle && (
        <Stack direction="row" alignItems="center" className={assignedPlannerTitleRowClassName}>
          {viewModel.shouldDisplayAssignedTitle && (
            <Typography
              sx={{
                left: assignmentLeft + assignedPlannedIconPadding,
                userSelect: 'none',
                position: 'absolute'
              }}
              noWrap
              variant="caption"
            >
              {viewModel.contentTitle}
            </Typography>
          )}

          {viewModel.shouldDisplayPlannedTitle && (
            <Typography
              sx={{
                left: plannedLeft + assignedPlannedIconPadding,
                userSelect: 'none',
                position: 'absolute'
              }}
              noWrap
              variant="caption"
            >
              {viewModel.contentTitle}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
});
