import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoSettingsStore } from '@studyo/services';
import { computed, makeObservable } from 'mobx';
import {
  AppOptionMenuElement,
  AppOptionMenuSectionViewModel,
  AppOptionMenuViewModel,
  OptionMenuSectionViewModel,
  OptionMenuViewModel
} from '../../utils';

export type WeeklyOptionMenuViewModel = OptionMenuViewModel;

export class AppWeeklyOptionMenuViewModel extends AppOptionMenuViewModel implements WeeklyOptionMenuViewModel {
  constructor(
    accountService: AccountService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    super(accountService, localizationService, navigationService, onSuccess, onCancel, settingsStore);
    makeObservable(this);
  }

  @computed
  get sections(): OptionMenuSectionViewModel[] {
    if (this.preferences.hasData) {
      return this.loadSections();
    } else {
      return [];
    }
  }

  private loadSections(): OptionMenuSectionViewModel[] {
    const strings = this.localizationService.localizedStrings.studyo.agenda.weekly.optionMenu;
    const periodHeight = this.preferences.weeklyPeriodHeight;
    const contentDisplayKind = this.preferences.weeklyContentDisplayKind;
    const showWeekends = this.preferences.weeklyDisplayWeekends;

    const smallHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 0),
      this.onSmallHeightPress,
      strings.smallPeriodHeightOptionTitle,
      periodHeight === 'small'
    );
    const mediumHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 1),
      this.onMediumHeightPress,
      strings.mediumPeriodHeightOptionTitle,
      periodHeight === 'medium'
    );
    const largeHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 2),
      this.onLargeHeightPress,
      strings.largePeriodHeightOptionTitle,
      periodHeight === 'large'
    );
    const veryLargeHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 3),
      this.onVeryLargeHeightPress,
      strings.veryLargePeriodHeightOptionTitle,
      periodHeight === 'very-large'
    );
    const periodHeightSection = new AppOptionMenuSectionViewModel(
      strings.periodHeightSectionTitle,
      [smallHeightOption, mediumHeightOption, largeHeightOption, veryLargeHeightOption],
      'single-selection'
    );

    const toggleTasksOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 0),
      this.onTasksPress,
      strings.taskDisplaySectionTitle,
      contentDisplayKind === 'tasks'
    );

    const toggleNotesOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 1),
      this.onNotesPress,
      strings.noteDisplaySectionTitle,
      contentDisplayKind === 'notes'
    );

    const displayedContentTypesSection = new AppOptionMenuSectionViewModel(
      strings.contentDisplaySectionTitle,
      [toggleTasksOption, toggleNotesOption],
      'single-selection'
    );

    const showWeekendsOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 0),
      this.onDisplayWeekendsValueChanged,
      strings.showWeekendsOptionTitle,
      showWeekends
    );

    const otherOptionsSection = new AppOptionMenuSectionViewModel(
      strings.otherOptionsSectionTitle,
      [showWeekendsOption],
      'independent'
    );

    return [periodHeightSection, displayedContentTypesSection, otherOptionsSection];
  }

  private onSmallHeightPress = () => {
    this.preferences.weeklyPeriodHeight = 'small';
  };

  private onMediumHeightPress = () => {
    this.preferences.weeklyPeriodHeight = 'medium';
  };

  private onLargeHeightPress = () => {
    this.preferences.weeklyPeriodHeight = 'large';
  };

  private onVeryLargeHeightPress = () => {
    this.preferences.weeklyPeriodHeight = 'very-large';
  };

  private onTasksPress = () => {
    this.preferences.weeklyContentDisplayKind = 'tasks';
  };

  private onNotesPress = () => {
    this.preferences.weeklyContentDisplayKind = 'notes';
  };

  private onDisplayWeekendsValueChanged = (value: boolean) => {
    this.preferences.weeklyDisplayWeekends = value;
  };
}
