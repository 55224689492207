import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { Color, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader, DialogCancelled } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AccountService, ContentPasteboardStore, NavigationService, StudyoAnalyticsService } from '../../../services';
import { AppContentListItemViewModel, ContentListItemViewModel } from './ContentListItemViewModel';

export interface DisplayableContentListViewModel {
  readonly canPaste: boolean;
  readonly canEditNote: boolean;
  readonly canEdit: boolean;

  readonly data: DataLoader;
  readonly sectionColor: Color | undefined;
  readonly sectionTitle: string | undefined;
  readonly day: Day;
  readonly periodTag: string | undefined;
  readonly contents: ContentListItemViewModel[];

  close: () => void;
  addTask: () => Promise<void | DialogCancelled>;
  editNote: () => Promise<void | DialogCancelled>;
  paste: () => Promise<void>;
}

export class AppDisplayableContentListViewModel implements DisplayableContentListViewModel {
  readonly data: AccountData;

  @observable private _contentIds: string[];

  constructor(
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _sectionId: string | undefined,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly day: Day,
    public readonly periodTag: string | undefined,
    accountService: AccountService,
    contentIds: string[]
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;
    this._contentIds = contentIds;
  }

  @computed
  private get section() {
    return this._sectionId != null ? this.data.sectionsById.get(this._sectionId) : undefined;
  }

  @computed
  get canEdit() {
    return !this.data.isReadOnly && this.data.canAddOrEditContentAtDate(this.day);
  }

  @computed
  get canPaste() {
    return this._contentPasteboardStore.storedContent != null;
  }

  @computed
  get canEditNote() {
    return this.periodTag != null;
  }

  @computed
  get sectionColor(): Color | undefined {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined);
  }

  @computed
  get sectionTitle(): string | undefined {
    return this.section != null ? this.section.title : undefined;
  }

  @computed
  get contents(): ContentListItemViewModel[] {
    const contents = _.chain(this._contentIds)
      .map((id) => this.data.contentsById.get(id))
      .compact()
      .sort((c1, c2) =>
        ContentDefinitionUtils.compareLocalizedContents(
          { content: c1, isAssignment: !c1.dueDay.isSame(this.day) },
          { content: c2, isAssignment: !c2.dueDay.isSame(this.day) },
          this._localizationService.localizedStrings,
          this._localizationService.currentLocale
        )
      )
      .value();

    return contents.map((content) => {
      const contentSection = content.sectionId.length > 0 ? this.data.sectionsById.get(content.sectionId) : undefined;

      return new AppContentListItemViewModel(
        this._navigationService,
        this._analyticsService,
        this.data,
        contentSection,
        this.day,
        this.periodTag,
        this.section != null ? this.section.id : undefined,
        content
      );
    });
  }

  close() {
    this._onSuccess();
  }

  async addTask() {
    const result = await this._navigationService.navigateToTaskEditModal(
      undefined,
      this.day,
      this.periodTag,
      this._sectionId
    );

    if (result != 'cancelled') {
      runInAction(() => this._contentIds.push(result));
    }
  }

  async editNote() {
    if (this.periodTag != null) {
      const response = await this._navigationService.navigateToNoteEditModal(
        this.day,
        this.periodTag,
        this.section?.id
      );

      if (response !== 'cancelled') {
        const content = this.data.contentsById.get(response);

        if (content != null) {
          const indexOfContent = this._contentIds.indexOf(content.id);

          if (indexOfContent >= 0) {
            if (content.notes.length === 0) {
              runInAction(() => this._contentIds.splice(indexOfContent, 1));
            }
          } else {
            if (content.notes.length > 0) {
              runInAction(() => this._contentIds.push(content.id));
            }
          }
        }
      }

      return Promise.resolve();
    } else {
      return Promise.resolve('cancelled' as DialogCancelled);
    }
  }

  async paste() {
    const savedContent = this._contentPasteboardStore.storedContent;
    if (savedContent != null) {
      try {
        if (this.periodTag != null && this.periodTag.length > 0) {
          const newContent = await this._contentPasteboardStore.pasteContentInPeriod(
            savedContent,
            this.day,
            this.periodTag,
            this.data,
            undefined,
            false,
            false
          );
          runInAction(() => this._contentIds.push(newContent.id));
        } else {
          const newContent = await this._contentPasteboardStore.pasteContentInDay(
            savedContent,
            this.day,
            this._sectionId,
            this.data,
            false
          );
          runInAction(() => this._contentIds.push(newContent.id));
        }
      } catch (e) {
        console.warn(`Unable to paste task with id ${savedContent.id}`, e);
      }
    }
  }
}
