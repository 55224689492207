import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { ModalHeights } from '../../screens/Utils.ts';

export interface ResponsiveDialogProps extends DialogProps {
  children: ReactNode;
  maxHeight?: 'small' | 'medium' | 'large';
  supportsFullScreen?: boolean;
}

export const ResponsiveDialog = observer(
  ({ children, maxHeight, supportsFullScreen, ...props }: ResponsiveDialogProps) => {
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
    const resolvedMaxHeight = !isExtraSmallScreen && maxHeight != null ? ModalHeights[maxHeight] : undefined;

    return (
      <Dialog
        {...props}
        fullScreen={supportsFullScreen !== false && isExtraSmallScreen}
        sx={{ ...props.sx }}
        PaperProps={{
          ...props.PaperProps,
          sx: {
            height: resolvedMaxHeight
          }
        }}
      >
        {children}
      </Dialog>
    );
  }
);
