import { Divider, Stack, SxProps } from '@mui/material';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ContentListItemViewModel } from '../../../viewmodels';
import { ContentListItem } from './ContentListItem';

export interface ContentListGroupProps {
  sx?: SxProps;
  className?: string;
  contents: ContentListItemViewModel[];
}

export const ContentListGroup = observer((props: ContentListGroupProps) => {
  const { localizationService } = useStudyoServices();
  const { contents, sx, className } = props;

  const sortedContents = contents.sort((cd1, cd2) =>
    ContentDefinitionUtils.compareLocalizedContents(
      cd1,
      cd2,
      localizationService.localizedStrings,
      localizationService.currentLocale
    )
  );

  return (
    <Stack sx={sx} className={className}>
      {sortedContents.map((content, i) => (
        <Fragment key={content.content.id}>
          <ContentListItem viewModel={content} />
          {i < contents.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Stack>
  );
});
