import { ListItemText, SxProps } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AllContentIcons, ContentIcon } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TaskEditViewModel } from '../../../viewmodels';
import { DropDownSelector } from '../../utils';

export interface TaskEditIconSelectorProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskEditViewModel;
}

export const TaskEditIconSelector = observer((props: TaskEditIconSelectorProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;

  return (
    <DropDownSelector
      sx={sx}
      className={className}
      options={AllContentIcons}
      selectedIndex={AllContentIcons.indexOf(viewModel.icon)}
      didSelectOption={(index) => (viewModel.icon = AllContentIcons[index])}
      disabled={viewModel.isSlaveTask}
      renderPreview={(icon) => (
        <TaskIcon
          icon={icon as ContentIcon}
          squareSize={32}
          workloadLevel={viewModel.task.workloadLevel}
          isPrivate={viewModel.task.isPrivate}
        />
      )}
      renderOption={(icon) => (
        <>
          <TaskIcon
            icon={icon as ContentIcon}
            squareSize={26}
            workloadLevel={viewModel.task.workloadLevel}
            isPrivate={viewModel.task.isPrivate}
          />
          <ListItemText sx={{ ml: 2 }}>
            {localizationService.localizedStrings.models.contents.defaultTitle(icon as ContentIcon)}
          </ListItemText>
        </>
      )}
    />
  );
});
