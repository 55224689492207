import { SchoolDayPeriodUtils } from '@shared/components/utils';
import { SchoolDay, SchoolDayPeriod } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { Dictionary } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ContentPasteboardStore, NavigationService, StudyoAccountSettings } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppPlannerCellViewModel, PlannerCellViewModel } from './PlannerCellViewModel';
import { AppPlannerSchoolDayHeaderViewModel, PlannerSchoolDayHeaderViewModel } from './PlannerSchoolDayHeaderViewModel';

export interface PlannerSchoolDayViewModel {
  readonly header: PlannerSchoolDayHeaderViewModel;
  readonly cells: PlannerCellViewModel[];
}

export class AppPlannerSchoolDayViewModel implements PlannerSchoolDayViewModel {
  private readonly _headerViewModel: AppPlannerSchoolDayHeaderViewModel;

  constructor(
    private readonly _contentPasteboard: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _schoolDay: SchoolDay,
    private readonly _sections: { section: SectionModel; count: number }[],
    private readonly _data: AccountData
  ) {
    makeObservable(this);
    this._headerViewModel = new AppPlannerSchoolDayHeaderViewModel(this._schoolDay, this._preferences);
  }

  @computed
  private get sortedPeriods() {
    return this._schoolDay.periods.slice().sort((p1, p2) => p1.startTime.compare(p2.startTime));
  }

  @computed
  private get periodsBySectionId(): Dictionary<SchoolDayPeriod[]> {
    const periodsBySections: Dictionary<SchoolDayPeriod[]> = {};

    this.sortedPeriods.forEach((period) => {
      const periodCourseOccurrences = SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this._data.account);

      if (periodCourseOccurrences.length > 0) {
        periodCourseOccurrences.forEach((co) => {
          const sectionId = co.sectionId;

          if (periodsBySections[sectionId] == null) {
            periodsBySections[sectionId] = [period];
          } else {
            periodsBySections[sectionId].push(period);
          }
        });
      }
    });
    return periodsBySections;
  }

  @computed
  get header(): PlannerSchoolDayHeaderViewModel {
    return this._headerViewModel;
  }

  @computed
  get cells(): PlannerCellViewModel[] {
    const { periodsBySectionId } = this;
    const cells: PlannerCellViewModel[] = [];

    this._sections.forEach((section) => {
      const periods = periodsBySectionId[section.section.id];

      for (let i = 0; i < section.count; i++) {
        const period = periods != null ? periods[i] : undefined;
        const occurrence =
          period != null
            ? SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this._data.account).find(
                (co) => co.sectionId === section.section.id
              )
            : undefined;
        const vm = new AppPlannerCellViewModel(
          this._contentPasteboard,
          this._localizationService,
          this._navigationService,
          this._analyticsService,
          this._preferences,
          i,
          this._schoolDay,
          occurrence,
          section.section,
          this._data,
          period
        );
        cells.push(vm);
      }
    });
    return cells;
  }
}
