import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface OptionMenuElementViewModel {
  readonly title: string;
  isSelected: boolean;
  onSelection: () => void;
  onSelectionChange: (newValue: boolean) => void;
  toggleSelectedState: () => void;
}

export class AppOptionMenuElement implements OptionMenuElementViewModel {
  @observable private _isSelected: boolean;

  @computed
  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    runInAction(() => (this._isSelected = value));
  }

  constructor(
    private readonly _onSelection: () => void,
    readonly onSelectionChange: (value: boolean) => void,
    readonly title: string,
    isSelected: boolean
  ) {
    makeObservable(this);
    this._isSelected = isSelected;
  }

  onSelection() {
    this._onSelection();
  }

  @action
  toggleSelectedState() {
    this._isSelected = !this._isSelected;
  }
}
