import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { computed, makeObservable } from 'mobx';
import { AccountService, StudyoSettingsStore, UISettingsStore } from '../../services';
import { AppSchoolDayPickerViewModel, SchoolDayPickerViewModel } from './SchoolDayPickerViewModel';

export type DateSwitcherContext = 'daily' | 'weekly' | 'monthly' | 'timeline' | 'planner' | 'periods';

export interface DateSwitcherViewModel {
  readonly schoolDayPickerViewModel: SchoolDayPickerViewModel;
  goToToday: () => void;
  goToDay: (day: Day) => void;
}

export class AppDateSwitcherViewModel implements DateSwitcherViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _context: DateSwitcherContext
  ) {
    makeObservable(this);
  }

  @computed
  private get preferences() {
    return this._settingsStore.getPreferences(this._accountService.displayedAccountData.accountId);
  }

  @computed
  private get currentDay() {
    let day: Day | undefined;
    switch (this._context) {
      case 'daily':
        day = this._uiSettingsStore.dailyCurrentDay;
        break;

      case 'weekly':
        day = this._uiSettingsStore.weeklyCurrentDay;
        break;

      case 'monthly':
        day = this._uiSettingsStore.monthlyCurrentDay;
        break;

      case 'timeline':
        day = this._uiSettingsStore.timelineCurrentDay;
        break;

      case 'planner':
        day = this._uiSettingsStore.plannerCurrentDay;
        break;

      case 'periods':
        day = this._uiSettingsStore.periodsCurrentDay;
        break;
    }

    const computedDay = day ?? dateService.today;
    const data = this._accountService.displayedAccountData;

    if (data.schoolDaysByDay.get(computedDay.asString) != null) {
      return day;
    } else {
      const isBeforeFirstDay = data.schoolDays.find((sd) => sd.day.isAfter(computedDay)) != null;
      return data.schoolDays.at(isBeforeFirstDay ? 0 : data.schoolDays.length - 1)?.day;
    }
  }

  @computed
  get schoolDayPickerViewModel(): SchoolDayPickerViewModel {
    const vm = new AppSchoolDayPickerViewModel(this._accountService, this._localizationService);
    vm.setCurrentDay(this.currentDay ?? dateService.today);
    return vm;
  }

  goToToday() {
    this.goToDay(dateService.today);
  }

  goToDay(day: Day) {
    switch (this._context) {
      case 'daily':
        this._uiSettingsStore.dailyCurrentDay = day;
        break;

      case 'weekly':
        this._uiSettingsStore.weeklyCurrentDay = day;
        break;

      case 'monthly':
        this._uiSettingsStore.monthlyCurrentDay = day;
        break;

      case 'timeline':
        this._uiSettingsStore.timelineCurrentDay = day;
        break;

      case 'planner':
        this._uiSettingsStore.plannerCurrentDay = this.resolvePlannerCurrentDayForSelectedValue(day);
        break;

      case 'periods':
        this._uiSettingsStore.periodsCurrentDay = day;
        break;
    }
  }

  private resolvePlannerCurrentDayForSelectedValue(day: Day): Day | undefined {
    const data = this._accountService.displayedAccountData;
    const schoolDay = data.schoolDaysByDay.get(day.asString);

    if (schoolDay != null && (this.preferences.plannerDisplayWeekends || !day.isWeekend)) {
      return day;
    }

    const nextMonday = day.nextDayForDayOfWeek('monday');
    if (data.schoolDaysByDay.get(nextMonday.asString) != null) {
      return nextMonday;
    }

    const previousFriday = day.previousDayForDayOfWeek('friday');
    if (data.schoolDaysByDay.get(previousFriday.asString) != null) {
      return previousFriday;
    }

    return this.preferences.plannerDisplayWeekends
      ? data.schoolDays[0]?.day
      : data.schoolDays.find((sd) => !sd.day.isWeekend)?.day;
  }
}
