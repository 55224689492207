import { css } from '@emotion/css';
import { Box, SxProps, useTheme } from '@mui/material';
import { ContentIcon, ContentState, ContentWorkloadLevel, Integration } from '@shared/models/types';
import { ImageService } from '@shared/resources/services';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react';
import { useSharedServices } from '../../UseSharedServicesHook.ts';

export type TaskIconPublishKind = 'none' | 'pre-publish' | 'published';

export interface TaskIconProps {
  sx?: SxProps;
  className?: string;
  icon?: ContentIcon;
  workloadLevel?: ContentWorkloadLevel;
  forcedIconColor?: string; // Not used in assignment, completed or cancelled
  customIcon?: string; // If set, overrides the 'icon' prop
  squareSize?: number; // Also affects width, defaults to 24
  isAssignment?: boolean; // Defaults to false
  isUnread?: boolean; // Defaults to false
  hasSteps?: boolean; // Defaults to false
  publishedKind?: TaskIconPublishKind; // Defaults to none
  state?: ContentState; // Defaults to Active
  isOpaque?: boolean; // Defaults to false
  externalSource?: Integration; // Default to unknown
  isPrivate?: boolean; // Defaults to false
  isClipped?: boolean; // Defaults to false
  hasPublishError?: boolean; // Defaults to false
}

type OverlayIcon =
  | 'assignment'
  | 'blank'
  | 'cancelled'
  | 'circle'
  | 'completed'
  | 'opaque-background'
  | 'pre-published'
  | 'published'
  | 'steps'
  | 'unread'
  | 'half'
  | 'error';

export const TaskIcon = observer((props: TaskIconProps) => {
  const {
    customIcon,
    externalSource,
    forcedIconColor,
    icon,
    state,
    sx,
    className,
    workloadLevel,
    isPrivate,
    isClipped = false,
    hasSteps = false,
    isOpaque = false,
    isAssignment = false,
    isUnread = false,
    squareSize = 24,
    publishedKind = 'none',
    hasPublishError = false
  } = props;
  const { imageService } = useSharedServices();
  const { external } = imageService.studyoImages.tasks;
  const theme = useTheme();

  const iconColors = theme.shared.contents.icons;

  const isPrePublished = publishedKind === 'pre-publish';
  const isPublished = publishedKind === 'published';

  const contentState = state ?? 'active';

  const isActive = contentState === 'active';
  const isCompleted = contentState === 'completed';
  const isCancelled = contentState === 'cancelled';

  const isExternalTask = externalSource != null;

  const isMajorAndNotAssignment = workloadLevel === 'major' && !isAssignment;

  const iconStyle = css({
    height: squareSize,
    width: squareSize
  });

  const iconSource = customIcon ?? assetFromContentIcon(icon ?? 'homework', imageService, isPrivate);
  let iconColor: string | undefined = iconColors.normalColor;

  if (customIcon != null) {
    iconColor = undefined;
  } else if (forcedIconColor != null) {
    iconColor = forcedIconColor;
  } else if (isAssignment) {
    iconColor = iconColors.assignmentColor;
  } else if (isCompleted || isCancelled) {
    iconColor = iconColors.completedColor;
  } else if (workloadLevel === 'medium') {
    iconColor = iconColors.workloadMediumIconColor;
  } else if (isMajorAndNotAssignment) {
    iconColor = iconColors.workloadMajorIconColor;
  }

  // If no overlays are required, take a shortcut.
  if (
    !isAssignment &&
    isActive &&
    !isUnread &&
    !isPrePublished &&
    !isPublished &&
    !isOpaque &&
    !isExternalTask &&
    !isMajorAndNotAssignment &&
    !hasSteps &&
    !isClipped &&
    !hasPublishError
  ) {
    return (
      <Box
        sx={{
          ...sx,
          width: squareSize,
          height: squareSize,
          overflow: 'visible',
          position: 'relative'
        }}
        className={className}
      >
        <TintedImage source={iconSource} className={iconStyle} color={iconColor} resizeMode="cover" />
      </Box>
    );
  }

  const assignmentOverlayWidth = isAssignment ? Math.round(squareSize * 0.24) : 0;
  const assignmentOverlayHeight = isAssignment ? Math.round(squareSize * 0.4) : 0;
  const unreadOverlaySize = Math.max(Math.round(squareSize * 0.1), 2);
  const opaqueOverlaySize = squareSize;
  const externalTaskOverlaySize = Math.round(squareSize * 0.6);

  const externalTaskOverlayStyle = css({
    position: 'absolute',
    height: externalTaskOverlaySize,
    width: externalTaskOverlaySize,
    left: Math.round(squareSize - externalTaskOverlaySize + 4),
    top: Math.round(squareSize - externalTaskOverlaySize + 4),
    backgroundColor: iconColors.normalBackgroundColor,
    overflow: 'hidden',
    borderRadius: externalTaskOverlaySize / 2
  });

  return (
    <Box
      sx={{
        ...sx,
        width: squareSize + assignmentOverlayWidth,
        height: squareSize,
        overflow: 'visible',
        position: 'relative'
      }}
    >
      {isAssignment && (
        <TintedImage
          source={assetFromOverlayIcon('assignment', imageService)}
          sx={{
            position: 'absolute',
            height: assignmentOverlayHeight,
            width: assignmentOverlayWidth,
            left: Math.floor(squareSize * 0.97),
            top: Math.round(squareSize / 2 - assignmentOverlayHeight / 2)
          }}
          color={iconColor}
        />
      )}
      {isOpaque && (
        <TintedImage
          source={assetFromOverlayIcon('opaque-background', imageService)}
          sx={{ position: 'absolute', height: opaqueOverlaySize - 2, width: opaqueOverlaySize - 2, left: 1, top: 1 }}
          color={iconColors.normalBackgroundColor}
        />
      )}

      {isMajorAndNotAssignment && (
        <Box
          sx={{
            position: 'absolute',
            left: 1,
            top: 1,
            backgroundColor: iconColors.workloadMajorBackgroundColor,
            height: squareSize - 2,
            width: squareSize - 2,
            borderRadius: (squareSize - 2) / 2,
            opacity: isActive ? 1.0 : 0.15
          }}
        />
      )}

      <TintedImage
        source={iconSource}
        className={iconStyle}
        sx={{ position: 'absolute', left: 0, top: 0, opacity: isActive ? (isClipped ? 0.5 : 1.0) : 0.15 }}
        color={iconColor}
      />
      {isCompleted && (
        <TintedImage
          source={assetFromOverlayIcon('completed', imageService)}
          sx={{ position: 'absolute', left: 0, top: 0 }}
          className={iconStyle}
          color={isAssignment ? iconColors.assignmentColor : iconColors.completedColor}
        />
      )}
      {isCancelled && (
        <TintedImage
          source={assetFromOverlayIcon('cancelled', imageService)}
          sx={{ position: 'absolute', left: 0, top: 0 }}
          className={iconStyle}
          color={isAssignment ? iconColors.assignmentColor : iconColors.normalColor}
        />
      )}
      {isPrePublished && (
        <TintedImage
          source={assetFromOverlayIcon('pre-published', imageService)}
          sx={{ position: 'absolute', width: squareSize, height: squareSize, left: 0, top: 0 }}
          color={iconColors.sharedColor}
        />
      )}
      {isPublished && (
        <TintedImage
          source={assetFromOverlayIcon('published', imageService)}
          sx={{ position: 'absolute', width: squareSize, height: squareSize, left: 0, top: 0 }}
          color={iconColors.sharedColor}
        />
      )}
      {isClipped && (
        <TintedImage
          source={assetFromOverlayIcon('half', imageService)}
          sx={{ position: 'absolute', width: squareSize, height: squareSize, left: 0, top: 0 }}
          color={iconColors.normalBackgroundColor}
        />
      )}
      {hasPublishError && (
        <Box
          sx={{
            position: 'absolute',
            width: externalTaskOverlaySize,
            height: externalTaskOverlaySize,
            left: 0,
            top: Math.round(squareSize - externalTaskOverlaySize + 4),
            borderRadius: externalTaskOverlaySize / 2,
            backgroundColor: iconColors.publishErrorColor
          }}
        >
          <TintedImage
            source={assetFromOverlayIcon('error', imageService)}
            sx={{ width: externalTaskOverlaySize, height: externalTaskOverlaySize }}
            color={iconColors.normalColor}
          />
        </Box>
      )}
      {isUnread && (
        <TintedImage
          source={assetFromOverlayIcon('unread', imageService)}
          sx={{ position: 'absolute', height: unreadOverlaySize, width: unreadOverlaySize, left: squareSize, top: 0 }}
          color={iconColors.unreadColor}
        />
      )}
      {isExternalTask && (
        <img src={external.getIntegrationImage(externalSource)} className={externalTaskOverlayStyle} />
      )}
      {!isExternalTask && hasSteps && (
        <img src={assetFromOverlayIcon('steps', imageService)} className={externalTaskOverlayStyle} />
      )}
    </Box>
  );
});

function assetFromContentIcon(icon: ContentIcon, imageService: ImageService, isPrivate = false) {
  const { privateIcons, regularIcons } = imageService.studyoImages.tasks;
  const icons = isPrivate ? privateIcons : regularIcons;

  switch (icon) {
    case 'homework':
      return icons.homework;
    case 'exam':
      return icons.exam;
    case 'oral':
      return icons.oral;
    case 'reading':
      return icons.reading;
    case 'lab':
      return icons.lab;
    case 'personal':
      return icons.personal;
    case 'teamwork':
      return icons.teamwork;
    case 'activity':
      return icons.activity;
    case 'message':
      return icons.message;
    case 'minitest':
      return icons.minitest;
    case 'play':
      return icons.play;
    case 'music':
      return icons.music;
    case 'reminder':
      return icons.reminder;
    case 'sport':
      return icons.sport;
    case 'study':
      return icons.study;
    case 'tutoring':
      return icons.tutoring;
    case 'project':
      return icons.project;
    case 'recuperation':
      return icons.recuperation;
    case 'dance':
      return icons.dance;
    case 'library':
      return icons.library;
    case 'signature':
      return icons.signature;
    case 'movie':
      return icons.movie;
    case 'survey':
      return icons.survey;
    case 'videoconference':
      return icons.videoconference;
    case 'art':
      return icons.art;
    case 'workplan':
      return icons.workplan;
    default:
      console.warn('Unexpected content icon enum value.');
      return icons.homework;
  }
}

function assetFromOverlayIcon(icon: OverlayIcon, imageService: ImageService) {
  const { overlays } = imageService.studyoImages.tasks;

  switch (icon) {
    case 'assignment':
      return overlays.assignment;
    case 'cancelled':
      return overlays.cancelled;
    case 'circle':
      return overlays.circle;
    case 'completed':
      return overlays.completed;
    case 'opaque-background':
      return overlays.opaque;
    case 'pre-published':
      return overlays.prePublished;
    case 'published':
      return overlays.published;
    case 'unread':
      return overlays.opaque;
    case 'steps':
      return overlays.steps;
    case 'half':
      return overlays.halfCircle;
    case 'error':
      return overlays.error;
    default:
      console.warn(`Unexpected overlay icon enum value [${icon}], defaulting to circle`);
      return overlays.circle;
  }
}
