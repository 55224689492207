import { WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';
import ErrorIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import { ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { isExceededImpact, isReachedImpact } from '@studyo/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PublishingWorkloadImpactViewModel } from '../../../viewmodels';

export interface TaskEditPublishingImpactCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: PublishingWorkloadImpactViewModel;
  visibleImpacts?: WorkloadImpactSummary[];
}

export const TaskEditPublishingImpactCell = observer(
  ({
    sx,
    className,
    viewModel,
    visibleImpacts = [
      WorkloadImpactSummary.DAILY_EXCEEDED,
      WorkloadImpactSummary.DAILY_REACHED,
      WorkloadImpactSummary.WEEKLY_EXCEEDED,
      WorkloadImpactSummary.WEEKLY_REACHED
    ]
  }: TaskEditPublishingImpactCellProps) => {
    if (!visibleImpacts.includes(viewModel.impact)) {
      return undefined;
    }

    const { localizationService } = useStudyoServices();
    const strings = localizationService.localizedStrings.studyo.contents.workload;
    const isExceeding = isExceededImpact(viewModel.impact);
    const isReaching = isReachedImpact(viewModel.impact);

    return (
      <ListItemButton sx={sx} className={className} onClick={() => void viewModel.showDetails()}>
        {isExceeding ? (
          <ListItemIcon>
            <ErrorIcon color="error" />
          </ListItemIcon>
        ) : isReaching ? (
          <ListItemIcon>
            <WarningIcon color="warning" />
          </ListItemIcon>
        ) : undefined}
        <ListItemText
          primary={strings.publishingImpact(viewModel.impact, viewModel.mainStudentCount)}
          primaryTypographyProps={{ color: isExceeding ? 'error' : undefined }}
        />
      </ListItemButton>
    );
  }
);
