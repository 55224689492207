import { Clipboard as CapacitorClipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { AccountService, NavigationService } from '../../services';

export type ShareOption = 'invite-parent' | 'calendar-link';

export interface GearMenuShareViewModel {
  currentDisplay: ShareOption;
  readonly data: AccountData;
  readonly displayOptions: ShareOption[];
  readonly emailWasSent: boolean;
  readonly errorMessage: string;
  readonly hasFetchedData: boolean;
  readonly httpUrl: string;
  readonly isStudent: boolean;
  readonly mailToUrl: string;
  readonly webcalUrl: string;

  email: string;
  includeFreePeriods: boolean;

  close: () => void;
  copyToClipboard: () => void;
  submitInvite: () => Promise<void>;
}

export class AppGearMenuShareViewModel implements GearMenuShareViewModel {
  readonly data: AccountData;
  readonly displayOptions: ShareOption[] = ['calendar-link'];

  @observable private _currentDisplay: ShareOption = 'calendar-link';
  @observable private _emailWasSent = false;
  @observable private _hasFetchedData = false;
  @observable private _httpUrl = '';
  @observable private _message = '';
  @observable private _parentEmail = '';
  @observable private _webcalUrl = '';
  @observable private _includeFreePeriods = false;

  constructor(
    accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;

    if (this.data.account.role === 'student' && !this.data.isImpersonating) {
      this.displayOptions = ['invite-parent', 'calendar-link'];
      this._currentDisplay = 'invite-parent';
    }

    autorun(() => this.fetchIcsUrl(!this._includeFreePeriods));
  }

  @computed
  get currentDisplay(): ShareOption {
    return this._currentDisplay;
  }

  set currentDisplay(value: ShareOption) {
    this._currentDisplay = value;
  }

  @computed
  get email() {
    return this._parentEmail;
  }

  set email(newValue: string) {
    this._parentEmail = newValue;
  }

  @computed
  get includeFreePeriods(): boolean {
    return this._includeFreePeriods;
  }

  set includeFreePeriods(value: boolean) {
    this._includeFreePeriods = value;
  }

  @computed
  get emailWasSent(): boolean {
    return this._emailWasSent;
  }

  @computed
  get errorMessage(): string {
    return this._message;
  }

  @computed
  get hasFetchedData(): boolean {
    return this._hasFetchedData;
  }

  @computed
  get httpUrl(): string {
    return this._httpUrl;
  }

  @computed
  get isStudent() {
    return this.data.account.role === 'student';
  }

  @computed
  get mailToUrl(): string {
    const strings = this._localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;
    const subject = encodeURIComponent(strings.mailSubject);
    const content = encodeURIComponent(strings.mailBody(this._webcalUrl, this._httpUrl));
    return 'mailto:?subject=' + subject + '&body=' + content;
  }

  @computed
  get webcalUrl(): string {
    return this._webcalUrl;
  }

  @action
  copyToClipboard() {
    if (Capacitor.isNativePlatform()) {
      void CapacitorClipboard.write({ url: this._httpUrl });
    } else {
      void navigator.clipboard.writeText(this.httpUrl);
    }
  }

  @action
  async submitInvite() {
    const strings = this._localizationService.localizedStrings.studyo.agenda.setting.gearMenuShare;
    this._message = '';

    if (!this.emailIsValid()) {
      this._message = strings.invalidEmailErrorMessage;
      throw new Error(this._message);
    } else {
      const parentEmail = this.email;
      await this.data.inviteParent(parentEmail);

      runInAction(() => {
        this._emailWasSent = true;
      });
    }
  }

  @action
  private async fetchIcsUrl(ignoreFreePeriods: boolean) {
    this._hasFetchedData = false;
    const response = await this.data.getIcsUrls(ignoreFreePeriods);
    runInAction(() => {
      this._webcalUrl = response.webcalUrl;
      this._httpUrl = response.httpUrl;
      this._hasFetchedData = true;
    });
  }

  close() {
    this._onSuccess();
  }

  private emailIsValid(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }
}
