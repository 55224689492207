import { SectionUtils } from '@shared/components/utils';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { StudyoAccountSettings } from '../../../services';
import { LargeContentLineHeight, StandardContentLineHeight } from './Constants';

export interface TimelineCollectionViewSectionHeaderViewModel {
  readonly backgroundColor?: Color;
  readonly height: number;
  readonly sectionName: string;
  readonly sectionNumber: string;
}

export class AppTimelineCollectionViewSectionHeaderViewModel implements TimelineCollectionViewSectionHeaderViewModel {
  constructor(
    private readonly _preferences: StudyoAccountSettings,
    private readonly _data: AccountData,
    private readonly _numberOfRows: number,
    private readonly _sectionId?: string
  ) {
    makeObservable(this);
  }

  @computed
  private get section() {
    return this._sectionId != null ? this._data.sectionsById.get(this._sectionId) : undefined;
  }

  @computed
  get backgroundColor() {
    return SectionUtils.getSectionColor(this.section, this._data.account, undefined);
  }

  @computed
  get height() {
    const lineHeight =
      this._preferences.timelineCellSize === 'large' ? LargeContentLineHeight : StandardContentLineHeight;
    return lineHeight * this._numberOfRows;
  }

  @computed
  get sectionName() {
    if (this._numberOfRows > 2) {
      return this.section?.title ?? '';
    } else {
      const shortTitle = this.section?.shortTitle ?? '';
      return shortTitle.length > 0 ? shortTitle : this.section?.title.slice(0, 3) ?? '';
    }
  }

  @computed
  get sectionNumber() {
    return this.section?.sectionNumber ?? '';
  }
}
