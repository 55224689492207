import { AccountUtils } from '@shared/components/utils';
import { AccountSummary } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';
import { Location, NavigateFunction } from 'react-router-dom';
import { AccountService, NavigationService } from '../../../services';

export interface OtherProfilesListProfileViewModel {
  readonly firstName: string;
  readonly lastName: string;
  readonly displayName: string;
  readonly color: Color | undefined;
  readonly schoolName: string;
  readonly isSelected: boolean;

  selectProfile(location: Location, navigate: NavigateFunction): Promise<void>;
}

export class AppOtherProfilesListProfileViewModel implements OtherProfilesListProfileViewModel {
  constructor(
    private readonly _accountSummary: AccountSummary,
    private readonly _childAccountSummary: AccountSummary | undefined,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _onProfileSelection: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get firstName(): string {
    // Do not use private first name when viewing a child's profile.
    return this._childAccountSummary == null
      ? this._accountSummary.profile.privateFirstName || this._accountSummary.firstName
      : this._childAccountSummary.firstName;
  }

  @computed
  get lastName(): string {
    // Do not use private last name when viewing a child's profile.
    return this._childAccountSummary == null
      ? this._accountSummary.profile.privateLastName || this._accountSummary.lastName
      : this._childAccountSummary.lastName;
  }

  @computed
  get displayName(): string {
    return AccountUtils.getDisplayFirstLastName(
      this._childAccountSummary ?? this._accountSummary,
      '',
      this._childAccountSummary == null
    );
  }

  @computed
  get color(): Color | undefined {
    return (this._childAccountSummary ?? this._accountSummary).profile.avatarColor;
  }

  @computed
  get schoolName(): string {
    return this._accountSummary.configurationSummary?.schoolName ?? '';
  }

  @computed
  get isSelected(): boolean {
    const account = this._childAccountSummary ?? this._accountSummary;
    return this._accountService.displayedAccountData.accountId === account.id;
  }

  async selectProfile(location: Location, navigate: NavigateFunction) {
    const accountId = this._childAccountSummary?.id ?? this._accountSummary.id;

    await this._accountService.setCurrentDisplayedAccount(accountId);
    this._onProfileSelection();
    await this._navigationService.closeAllModals();
    await this._navigationService.navigateToSamePage(this._accountSummary.configId, accountId, location, navigate);
  }
}
