import { List, SxProps } from '@mui/material';
import { ScrollShadowWrapper } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ContentListGroup, ContentListNoneItem, DataPresenter, ListSection } from '../../components';
import { DisplayableContentListViewModel } from '../../viewmodels';

export interface DisplayableContentListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: DisplayableContentListViewModel;
}

export const DisplayableContentListView = observer((props: DisplayableContentListViewProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;

  const addTask = () => {
    void viewModel.addTask();
  };

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      renderData={() => {
        const dateFormat = localizationService.localizedStrings.models.dateFormats.long;

        let dayAndPeriodInfo = viewModel.day.formattedString(dateFormat);
        if (viewModel.periodTag != null) {
          dayAndPeriodInfo += ` - ${localizationService.localizedStrings.studyo.contents.lists.periodTagLabel(
            viewModel.periodTag
          )}`;
        }

        return (
          <ScrollShadowWrapper sx={{ width: '100%', height: '100%' }}>
            <List>
              <ListSection title={dayAndPeriodInfo}>
                <ContentListGroup contents={viewModel.contents} />

                {viewModel.contents.length === 0 && (
                  <ContentListNoneItem onSelection={viewModel.canEdit ? () => addTask() : undefined} />
                )}
              </ListSection>
            </List>
          </ScrollShadowWrapper>
        );
      }}
    />
  );
});
