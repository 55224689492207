import { SchoolYearConfigurationSummary as PBSchoolYearConfigurationSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { ConfigState, ConfigType, Day, Integration, PremiumFeature } from '../types';
import {
  configStateFromProtobuf,
  configTypeFromProtobuf,
  integrationFromProtobuf,
  premiumFeatureFromProtobuf
} from '../types/EnumConversion';
import { Link } from './Link';

export class SchoolYearConfigurationSummary {
  private _pb: PBSchoolYearConfigurationSummary;

  constructor(pb: PBSchoolYearConfigurationSummary) {
    this.validateProto(pb);
    this._pb = pb;
  }

  private validateProto(pb: PBSchoolYearConfigurationSummary) {
    const startDay = pb.startDay;
    const endDay = pb.endDay;

    if (startDay == null) {
      throw new Error('Invalid data from backend: config.startDay is undefined.');
    }

    if (endDay == null) {
      throw new Error('Invalid data from backend: config.endDay is undefined.');
    }
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get type(): ConfigType {
    return configTypeFromProtobuf(this._pb.type);
  }

  get version(): number {
    return this._pb.version;
  }

  get schoolName(): string {
    return this._pb.schoolName;
  }

  get title(): string {
    return this._pb.title;
  }

  get daysPerCycle(): number {
    return this._pb.daysPerCycle;
  }

  @LazyGetter()
  get startDay(): Day {
    return new Day(this._pb.startDay!);
  }

  @LazyGetter()
  get endDay(): Day {
    return new Day(this._pb.endDay!);
  }

  get managedOnboardCode(): string {
    return this._pb.managedOnboardCode;
  }

  get language(): string {
    return this._pb.language;
  }

  get timezone(): string {
    return this._pb.timezone;
  }

  get enabledIntegrations(): Integration[] {
    return this._pb.enabledIntegrations.map((integration) => integrationFromProtobuf(integration));
  }

  @LazyGetter()
  get links(): Link[] {
    return this._pb.links.map((l) => new Link(l));
  }

  get isManagedWithSubscriptions(): boolean {
    return this._pb.isManagedWithSubscriptions;
  }

  get managedSubscriptionCoupon(): string {
    return this._pb.managedSubscriptionCoupon;
  }

  get isDemo(): boolean {
    return this._pb.isDemo;
  }

  get comments(): string {
    return this._pb.comments;
  }

  @LazyGetter()
  get disabledFeatures(): PremiumFeature[] {
    return this._pb.disabledFeatures.map((f) => premiumFeatureFromProtobuf(f));
  }

  get state(): ConfigState {
    return configStateFromProtobuf(this._pb.state);
  }

  get tags(): string[] {
    return this._pb.tags;
  }

  get expectedStudentCount() {
    return this._pb.expectedStudentCount;
  }

  get previousConfigurationId() {
    return this._pb.previousConfigurationId;
  }
}
