const ListValues = {
  sectionHeaderHeight: 42,
  sectionHeaderHeightNoTitle: 8,
  sectionFooterHeight: 8,

  listItemHeight: 56,
  listItemBorderHeight: 1,
  listItemIconSize: 26,
  listItemDisclosureWidth: 8,
  listItemDisclosureHeight: 16,
  listItemInfoSize: 22
};

export default ListValues;
