import { Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { CalendarHeaderFilterButton } from './CalendarHeaderFilterButton';

export interface CalendarHeaderFilterButtonsProps {
  sx?: SxProps;
  className?: string;
  hasContentFilters?: boolean;
  hasSectionFilters?: boolean;
  onFilterContentsPress?: () => void;
  onFilterSectionsPress?: () => void;
}

export const CalendarHeaderFilterButtons = observer((props: CalendarHeaderFilterButtonsProps) => {
  const { sx, className, onFilterSectionsPress, hasContentFilters, hasSectionFilters, onFilterContentsPress } = props;
  const { localizationService } = useStudyoServices();
  const strings = localizationService.localizedStrings.studyo.agenda;

  if (hasContentFilters == null && hasSectionFilters == null) {
    return null;
  }

  const displayContentFilters = onFilterContentsPress != null && hasContentFilters != null;
  const displaySectionFilters = onFilterSectionsPress != null && hasSectionFilters != null;

  return (
    <Stack direction="row" spacing={1} sx={sx} className={className}>
      {displayContentFilters && (
        <CalendarHeaderFilterButton
          onPress={onFilterContentsPress}
          text={strings.contentFilterButton}
          hasFilters={hasContentFilters}
        />
      )}

      {displaySectionFilters && (
        <CalendarHeaderFilterButton
          onPress={onFilterSectionsPress}
          text={strings.sectionFilterButton}
          hasFilters={hasSectionFilters}
        />
      )}
    </Stack>
  );
});
