import { action, makeObservable, observable } from 'mobx';
import moize, { AnyFn, Options } from 'moize';
import { BaseStore } from '../interfaces';

export class AppBaseStore implements BaseStore {
  @observable private _invalidateSerial = 0;
  private _memoizedFunctions: AnyFn[] = [];

  constructor(
    private readonly _name: string,
    private readonly _bypassCaching = false,
    private readonly _dependantStore?: BaseStore
  ) {
    makeObservable(this);
  }

  protected get isCachingBypassed() {
    return this._bypassCaching;
  }

  @action
  invalidate() {
    this._invalidateSerial++;
    this._dependantStore?.invalidate();
  }

  clear() {
    // NOTE: We need to cast the function to `any` to access the clear method
    //       (https://www.npmjs.com/package/moize#clear)

    this._memoizedFunctions.forEach((fn) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      (fn as any).clear();
    });
  }

  protected withInvalidate<T>(callback: () => T): T {
    // Accessing _invalidateSerial so that the callback is called whenever the property changes.
    this._invalidateSerial;
    return callback();
  }

  protected memoize<T extends AnyFn>(callback: T, options?: Options<T>): T {
    if (this._bypassCaching) {
      return callback;
    }

    const memoized = options == null ? moize(callback) : moize(callback, options);

    this._memoizedFunctions.push(memoized);

    return memoized;
  }
}
