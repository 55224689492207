import { Checkbox, Divider, List, ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { DataPresenter, ListSection } from '@studyo/components';
import { SectionFilterViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { Fragment } from 'react';

export interface MonthSectionFilterViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: SectionFilterViewModel;
}

export const MonthSectionFilterView = observer((props: MonthSectionFilterViewProps) => {
  const { sx, className, viewModel } = props;

  return (
    <DataPresenter
      sx={{ ...sx, overflow: 'hidden' }}
      className={className}
      dataLoaders={[viewModel.data, viewModel.preferences]}
      renderData={() => (
        <List sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <ListSection>
            {viewModel.items.map((item, index) => (
              <Fragment key={`planner-section-filter-${index}`}>
                <ListItemButton onClick={() => item.onSelection()}>
                  <ListItemIcon>
                    <Checkbox checked={item.isSelected} disableRipple />
                  </ListItemIcon>

                  <ListItemText
                    primary={item.sectionTitle}
                    primaryTypographyProps={{ color: SectionColors.get(item.sectionColor) }}
                    secondary={item.sectionNumber}
                  />
                </ListItemButton>

                {index < viewModel.items.length - 1 && <Divider />}
              </Fragment>
            ))}
          </ListSection>
        </List>
      )}
    />
  );
});
