import {
  Account as PBAccount,
  AccountProfile as PBAccountProfile,
  AccountSettings as PBAccountSettings
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { Color as PBColor } from '@buf/studyo_studyo.bufbuild_es/studyo/type_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { chain } from 'lodash';
import { BaseModel, SerializableModel } from '../Model';
import { Color, Role } from '../types';
import { colorFromProtobuf, roleFromProtobuf } from '../types/EnumConversion';
import { AccountProfile, AccountProfileModel } from './AccountProfile';
import { AccountSettings, AccountSettingsModel } from './AccountSettings';
import { TrackedModel } from './TrackedModel';

export interface AccountModel extends SerializableModel<PBAccount>, TrackedModel {
  readonly id: string;
  readonly role: Role;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly managedIdentifier: string;
  readonly configId: string;
  readonly childrenAccountIds: string[];
  readonly childrenAccountPendingVerificationIds: string[];
  readonly isAutomatchPendingEmailVerification: boolean;
  readonly language: string;
  readonly userId: string;
  readonly syncToken: string;
  readonly isDeleted: boolean;
  readonly allowNonVerifiedEmailAutomatch: boolean;
  readonly settings: AccountSettingsModel;
  readonly profile: AccountProfileModel;
  readonly isAdmin: boolean;
  readonly gradeLevel: string;
  readonly optedOutOfParentReports: boolean;
  readonly isLocked: boolean;
  readonly preferredScheduleTag: string;

  // Commodity properties.
  readonly selectedSectionIds: string[];
  readonly customizedSectionColorsBySectionId: Record<string, Color>;
  readonly canImpersonate: boolean;
  readonly applicableAutoEnrollTags: string[];
  readonly visibleEmail: string;
}

export class Account extends BaseModel<PBAccount> implements AccountModel {
  constructor(pb: PBAccount) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get role(): Role {
    return roleFromProtobuf(this._pb.role);
  }

  get firstName(): string {
    return this._pb.firstName;
  }

  get lastName(): string {
    return this._pb.lastName;
  }

  get email(): string {
    return this._pb.email;
  }

  get managedIdentifier(): string {
    return this._pb.managedIdentifier;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get childrenAccountIds(): string[] {
    return this._pb.childrenAccountIds;
  }

  get childrenAccountPendingVerificationIds(): string[] {
    return this._pb.childrenAccountPendingVerificationIds;
  }

  get isAutomatchPendingEmailVerification(): boolean {
    return this._pb.isAutomatchPendingEmailVerification;
  }

  get language(): string {
    return this._pb.language;
  }

  get userId(): string {
    return this._pb.userId;
  }

  get syncToken(): string {
    return this._pb.syncToken;
  }

  get isDeleted(): boolean {
    return this._pb.isDeleted;
  }

  get allowNonVerifiedEmailAutomatch(): boolean {
    return this._pb.allowNonVerifiedEmailAutomatch;
  }

  get gradeLevel(): string {
    return this._pb.gradeLevel;
  }

  get optedOutOfParentReports(): boolean {
    return this._pb.optedOutOfParentReports;
  }

  get isLocked(): boolean {
    return this._pb.isLocked;
  }

  @LazyGetter()
  get settings(): AccountSettingsModel {
    return new AccountSettings(this._pb.settings ?? new PBAccountSettings());
  }

  @LazyGetter()
  get profile(): AccountProfileModel {
    return new AccountProfile(this._pb.profile ?? new PBAccountProfile());
  }

  get isAdmin(): boolean {
    return this._pb.isAdmin;
  }

  get manualChanges(): string[] {
    return this._pb.manualChanges;
  }

  get preferredScheduleTag(): string {
    return this._pb.preferredScheduleTag;
  }

  //
  // Simplified Protobuf properties
  //

  get selectedSectionIds(): string[] {
    const settings = this._pb.settings;
    return settings ? settings.selectedSectionIds : [];
  }

  @LazyGetter()
  get customizedSectionColorsBySectionId(): Record<string, Color> {
    const settings = this._pb.settings;
    return settings
      ? chain(settings.sectionColors)
          .map((sc) => ({
            sectionId: sc.sectionId,
            color: sc.colorIndex as PBColor
          }))
          .keyBy('sectionId')
          .mapValues((o) => colorFromProtobuf(o.color))
          .value()
      : {};
  }

  get canImpersonate(): boolean {
    // Parents always impersonate a child. Teachers can impersonate students.
    // In the future, we'll want to create admin accounts that can impersonate any student.
    return this.role === 'parent' || this.role === 'teacher';
  }

  @LazyGetter()
  get applicableAutoEnrollTags(): string[] {
    const gradeLevel = this.gradeLevel;

    if (gradeLevel.length === 0) {
      return [];
    }

    return [`gradeLevel=${gradeLevel}`];
  }

  @LazyGetter()
  get visibleEmail(): string {
    return this.profile.publicEmail.length > 0 ? this.profile.publicEmail : this.email;
  }
}
