import { OptionMenuElementViewModel } from './OptionMenuElementViewModel';

export type OptionMenuSectionSelectionMode = 'single-selection' | 'multiple-selection' | 'independent';

export interface OptionMenuSectionViewModel {
  readonly title?: string;
  readonly data: OptionMenuElementViewModel[];
  readonly selectionType: OptionMenuSectionSelectionMode;
}

export class AppOptionMenuSectionViewModel implements OptionMenuSectionViewModel {
  constructor(
    readonly title: string | undefined,
    readonly data: OptionMenuElementViewModel[],
    readonly selectionType: OptionMenuSectionSelectionMode
  ) {}
}
