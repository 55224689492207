import { action, computed, makeObservable, observable } from 'mobx';

export interface DataLoader {
  /**
   * Returns whether if data exists.
   */
  hasData: boolean;

  /**
   * Returns true if loading initial data or refreshing the current data.
   * In the later, the `hasData` value won't change.
   */
  isRefreshing: boolean;

  /**
   * If an error happened when refreshing the data, it will be exposed by this property.
   */
  lastRefreshError?: Error;

  /**
   * Returns the date/time of the last successful refresh
   */
  lastRefreshTimestamp?: Date;

  refresh: () => Promise<void>;
}

export abstract class AppDataLoader implements DataLoader {
  @observable protected _hasData = false;
  @observable protected _isRefreshing = false;
  @observable protected _lastLoadError?: Error;
  @observable protected _lastRefreshTimestamp?: Date;

  constructor() {
    makeObservable(this);
  }

  @computed
  get hasData() {
    return this._hasData;
  }

  @computed
  get isRefreshing() {
    return this._isRefreshing;
  }

  @computed
  get lastRefreshError() {
    return this._lastLoadError;
  }

  @computed
  get lastRefreshTimestamp() {
    return this._lastRefreshTimestamp;
  }

  async refresh() {
    return this.loadData(true);
  }

  /**
   * Loads all the data necessary. After the first successfull load, `hasData` will be true.
   */
  protected abstract loadData(isRefresh: boolean): Promise<void>;

  /**
   * Set properties to expose a load of data. It will return whether or not we should load the data.
   * If it returns false, it means a load is already running.
   */
  @action
  protected startLoadingData(): boolean {
    if (!this._isRefreshing) {
      this._lastLoadError = undefined;
      this._isRefreshing = true;
      return true;
    }

    return false;
  }

  /**
   * Set properties to a non-loading state.
   * @param error Error that occurred during the load
   */
  @action
  protected stopLoadingData(error: Error | undefined) {
    this._hasData = this._hasData || error == null;
    this._lastLoadError = error;
    this._isRefreshing = false;

    if (error == null) {
      // Note: Do not use DateService here.
      this._lastRefreshTimestamp = new Date();
    }

    if (error != null) {
      console.warn(error);
    }
  }
}
