import { SxProps } from '@mui/material';
import { DayAndWeekPeriodInfoBoxViewModel } from '../../../viewmodels';
import { CompactDayAndWeekPeriodInfoBox } from './CompactDayAndWeekPeriodInfoBox';
import { RegularDayAndWeekPeriodInfoBox } from './RegularDayAndWeekPeriodInfoBox';

export interface DayAndWeekPeriodInfoBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekPeriodInfoBoxViewModel;
  backgroundColor: string;
  borderColor?: string;
  isCompact: boolean;
  height: number;
}

export function DayAndWeekPeriodInfoBox(props: DayAndWeekPeriodInfoBoxProps) {
  if (props.isCompact) {
    return <CompactDayAndWeekPeriodInfoBox {...props} />;
  } else {
    return <RegularDayAndWeekPeriodInfoBox {...props} />;
  }
}
