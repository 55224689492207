import { SchoolDayPeriod as PBSchoolDayPeriod } from '@buf/studyo_studyo.bufbuild_es/studyo/type_generator_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { PeriodKind, Time } from '../types';
import { periodKindFromProtobuf } from '../types/EnumConversion';
import { CourseOccurrence } from './CourseOccurrence';

export class SchoolDayPeriod {
  private _pb: PBSchoolDayPeriod;

  constructor(pb: PBSchoolDayPeriod) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  @LazyGetter()
  get startTime(): Time {
    const time = this._pb.startTime;
    if (time == null) {
      throw new Error('Invalid data from backend: SchoolDayPeriod with no StartTime.');
    }

    return new Time(time);
  }

  @LazyGetter()
  get endTime(): Time {
    const time = this._pb.endTime;
    if (time == null) {
      throw new Error('Invalid data from backend: SchoolDayPeriod with no EndTime.');
    }

    return new Time(time);
  }

  get tag(): string {
    return this._pb.tag;
  }

  get isCustomSchedule(): boolean {
    return this._pb.isCustomSchedule;
  }

  get scheduleTag(): string {
    return this._pb.scheduleTag;
  }

  @LazyGetter()
  get courseOccurrences(): CourseOccurrence[] {
    return this._pb.courseOccurrences.map((c) => new CourseOccurrence(c));
  }

  get conflictingPeriodIds(): string[] {
    return this._pb.conflictingPeriodIds;
  }

  get kind(): PeriodKind {
    return periodKindFromProtobuf(this._pb.kind);
  }
}
