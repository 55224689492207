import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { PreferencesView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface PreferencesScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const PreferencesScreen = observer((props: PreferencesScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onCancel, onSuccess } = props;
  const viewModel = useMemo(() => viewModelFactory.createPreferences(onSuccess!, onCancel!), []);
  const strings = localizationService.localizedStrings.studyo.agenda.setting.preferences;

  const close = () => viewModel.close();

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
    >
      <DialogHeader title={strings.title} onClose={close} />

      <PreferencesView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
