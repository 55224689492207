import { SchoolDayPeriodUtils, SectionUtils } from '@shared/components/utils';
import { MoveCourseOccurrencesParamsModel } from '@shared/models/calendar';
import { EditableContentDefinition } from '@shared/models/content';
import { Color, Day, MoveCourseOccurrencesDirection } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnDestroy, OnInit } from '@shared/services';
import { AccountData, CalendarStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction, when } from 'mobx';
import { AccountAutoSyncService, AccountService, NavigationService } from '../../../../services';

export interface PlannerPeriodInfoViewModel extends OnInit, OnDestroy {
  readonly data: AccountData;

  readonly canEdit: boolean;
  readonly periodTitle: string;
  readonly occurrenceLeftInYear: number;
  readonly occurrenceInTerm: number;
  readonly occurrenceLeftInTerm: number;
  readonly sectionTitle: string;
  readonly sectionNumber: string;
  readonly roomName: string;
  readonly sectionColor: Color;
  readonly isSkipped: boolean;
  readonly isNoteDirty: boolean;
  readonly canQuickMovePrevious: boolean;
  readonly canQuickUnskipAndMoveFromNext: boolean;
  readonly canQuickSkipAndMoveNext: boolean;
  readonly canQuickMoveNext: boolean;

  notes: string | undefined;
  openEditPeriod: () => Promise<void>;
  openContentModification: () => Promise<void>;
  moveContentToPreviousPeriod: () => Promise<void>;
  unskipPeriodAndMoveContentFromNext: () => Promise<void>;
  skipPeriodAndMoveContentToNext: () => Promise<void>;
  moveContentToNextPeriod: () => Promise<void>;

  save: () => Promise<void>;
  dismiss: () => void;
}

export class AppPlannerPeriodInfoViewModel implements PlannerPeriodInfoViewModel {
  readonly data: AccountData;
  readonly canQuickMoveNext = true;

  @observable private _note: EditableContentDefinition | undefined;

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _sectionId: string,
    private readonly _periodTag: string,
    private readonly _day: Day,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = _accountService.displayedAccountData;

    when(
      () => this.data.hasData,
      () => {
        const existingNote = this.data.contents.find(
          (cd) => cd.kind === 'note' && cd.dueDay.isSame(_day) && cd.duePeriodTag === _periodTag
        );

        if (existingNote != null) {
          this._note = new EditableContentDefinition(existingNote, false);
        } else {
          this._note = EditableContentDefinition.createNewNote(
            this._accountService.displayedAccountData.configId,
            this._accountService.displayedAccountData.accountId,
            _day,
            _periodTag
          );
        }
      }
    );
  }

  @computed
  private get courseOccurrence() {
    const schoolDay = this.data.schoolDaysByDay.get(this._day.asString);
    if (schoolDay == null) {
      throw new Error(`No school day found for specified day. [${this._day.asString}]`);
    }

    const periods = schoolDay.periods.filter((p) => p.tag === this._periodTag);
    if (periods.length === 0) {
      throw new Error(`No period found for specified period tag. [${this._periodTag}]`);
    }

    // Getting the course occurrence for each period. Normally, only one period should return a non-null occurrence.
    const occurrences = _(periods)
      .map((period) => {
        const periodCourseOccurrences = SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this.data.account);
        return periodCourseOccurrences.find((co) => co.sectionId === this._sectionId);
      })
      .compact()
      .value();

    if (occurrences.length === 0) {
      throw new Error(`No occurrence found with specified section id. [${this._sectionId}]`);
    }
    if (occurrences.length > 1) {
      console.error(`Multiple occurrences found with specified section id. [${this._sectionId}]`);
    }

    return occurrences[0];
  }

  @computed
  private get section() {
    const section = this.data.sectionsById.get(this._sectionId);
    if (section == null) {
      throw new Error(`A section is required in planner period info. Section id [${this._sectionId}]`);
    }

    return section;
  }

  @computed
  get canEdit() {
    return !this.data.isReadOnly && this.data.canAddOrEditContentAtDate(this._day);
  }

  @computed
  get isNoteDirty(): boolean {
    if (this._note == null) {
      return false;
    } else {
      return this._note.hasChanges;
    }
  }

  @computed
  get notes(): string {
    return this._note!.notes;
  }

  set notes(value: string) {
    runInAction(() => (this._note!.notes = value));
  }

  @computed
  get periodTitle(): string {
    return this.courseOccurrence.displayTitle;
  }

  @computed
  get occurrenceLeftInYear(): number {
    return this.courseOccurrence.yearRemaining;
  }

  @computed
  get occurrenceInTerm(): number {
    return this.courseOccurrence.termOrdinal;
  }

  @computed
  get occurrenceLeftInTerm(): number {
    return this.courseOccurrence.termRemaining;
  }

  @computed
  get sectionTitle(): string {
    return this.section.title;
  }

  @computed
  get sectionNumber(): string {
    return this.section.sectionNumber;
  }

  @computed
  get roomName(): string {
    return this.courseOccurrence.customRoomName.length > 0
      ? this.courseOccurrence.customRoomName
      : this.courseOccurrence.roomName;
  }

  @computed
  get sectionColor(): Color {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined)!;
  }

  @computed
  get canQuickMovePrevious(): boolean {
    // It doesn't make sense to move left a skipped period. It won't move, skipped
    // periods are untouched. The next period would move.
    return !this.courseOccurrence.skipped;
  }

  @computed
  get canQuickUnskipAndMoveFromNext(): boolean {
    // If it's skipped, we allow a "move previous" starting at the next occurrence, which
    // unskips this one.
    return this.courseOccurrence.skipped;
  }

  @computed
  get canQuickSkipAndMoveNext(): boolean {
    return !this.courseOccurrence.skipped;
  }

  @computed
  get isSkipped(): boolean {
    return this.courseOccurrence.skipped;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async openEditPeriod() {
    await this._navigationService.navigateToPlannerPeriodEditModal(this.courseOccurrence);
  }

  async openContentModification() {
    await this._navigationService.navigateToPlannerPeriodContentMoveModal(this._day, this._periodTag, this._sectionId);
  }

  moveContentToPreviousPeriod() {
    return this.moveContents('left', this.courseOccurrence.ordinal);
  }

  unskipPeriodAndMoveContentFromNext(): Promise<void> {
    // The API will use first non-skipped period from that ordinal.
    return this.moveContents('left', this.courseOccurrence.ordinal + 1);
  }

  skipPeriodAndMoveContentToNext() {
    return this.moveContents('right-skip', this.courseOccurrence.ordinal);
  }

  moveContentToNextPeriod(): Promise<void> {
    return this.moveContents('right', this.courseOccurrence.ordinal);
  }

  dismiss() {
    this._onCancel();
  }

  async save() {
    if (this._note != null) {
      await this.data.createOrUpdateContent(this._note);
      this._onSuccess();
    }
  }

  private async moveContents(moveDirection: MoveCourseOccurrencesDirection, startOrdinal: number) {
    const params: MoveCourseOccurrencesParamsModel = {
      configId: this._accountService.displayedAccountData.configId,
      sectionId: this._sectionId,
      moveDirection: moveDirection,
      moveNotes: true,
      moveTasks: true,
      moveTitles: true,
      startOrdinal: startOrdinal,
      untilDay: undefined
    };

    await this._calendarStore.moveCourseOccurrences(params);
    return this.data.refresh();
  }
}
