import { ContentDefinition_Step as PBStep } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import { Day } from '../types';

export interface ContentStepModel extends SerializableModel<PBStep> {
  readonly id: string;
  readonly stepId: string;
  readonly title: string;
  readonly date: Day | undefined;
  readonly sortOrder: number;
  readonly completed: boolean;
}

export class ContentStep extends BaseModel<PBStep> implements ContentStepModel {
  constructor(pb: PBStep) {
    super(pb);
  }
  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get stepId(): string {
    return this._pb.stepId;
  }

  get title(): string {
    return this._pb.title;
  }

  @LazyGetter()
  get date(): Day | undefined {
    return Day.fromPB(this._pb.date);
  }

  get sortOrder(): number {
    return this._pb.sortOrder;
  }

  get completed(): boolean {
    return this._pb.completed;
  }
}
