import { IndexPath } from '../../components/utils';

export interface VirtualizedSectionListGroup<T> {
  title?: string;
  data: T[];
}

export class BaseVirtualizedSectionListDataSource {
  static indexPathForRow = <T>(sections: VirtualizedSectionListGroup<T>[], row: number): IndexPath | undefined => {
    let previousRow = 0;
    let currentRow = 0;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      // Header + elements of section + footer;
      currentRow += 2 + section.data.length;

      // Row is in section.
      if (currentRow > row) {
        // Header row
        if (row === previousRow) {
          return { section: i, index: -1, type: 'header' };
        } else if (row === currentRow - 1) {
          return { section: i, index: -1, type: 'footer' };
        } else {
          /* An element in section */
          const elementIndex = Math.abs(previousRow - row + 1);
          return { section: i, index: elementIndex, type: 'cell' };
        }
      }

      previousRow = currentRow;
    }

    // No IndexPath found
    console.warn('Row index is out of bounds for row ' + row);
    return undefined;
  };
}
