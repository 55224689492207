import { ContentAttachmentUtils } from '@shared/components/utils/models';
import { ContentAttachmentModel } from '@shared/models/content';
import { ImageService } from '@shared/resources/services';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager } from '../../services';

export interface AttachmentListItemViewModel {
  readonly key: string;
  readonly iconSource: string | undefined;
  readonly displayTitle: string;
  readonly isPhoto: boolean;
  readonly canDelete: boolean;

  display: () => void;
  delete: () => void;
}

export class AppAttachmentListItemViewModel implements AttachmentListItemViewModel {
  constructor(
    private readonly _attachmentManager: AttachmentManager,
    private readonly _imageService: ImageService,
    private readonly _attachment: ContentAttachmentModel,
    private readonly _delete: (attachment: ContentAttachmentModel) => void,
    public readonly canDelete: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get key() {
    return this._attachment.id;
  }

  @computed
  get isPhoto() {
    const { kind } = this._attachment;

    return kind === 'photo' || kind === 'url';
  }

  @computed
  get iconSource() {
    const { kind } = this._attachment;

    if (kind === 'photo' || (kind === 'url' && this._attachment.thumbUrl.length > 0)) {
      return this._attachment.thumbUrl;
    } else {
      return ContentAttachmentUtils.imageForAttachmentIcon(this._attachment.attachmentIcon, this._imageService);
    }
  }

  @computed
  get displayTitle() {
    if (this._attachment.kind === 'photo') {
      // TODO: Localization
      return 'Photo';
    } else {
      return this._attachment.title;
    }
  }

  display() {
    this._attachmentManager.openAttachment(this._attachment);
  }

  delete() {
    this._delete(this._attachment);
  }
}
