import { Breakpoint, Button, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';

export type DialogButtonType = 'normal' | 'destructive' | 'cancel' | 'success';
export type DialogButtonVariant = 'contained' | 'text';

type DialogButtonColor = 'success' | 'primary' | 'error';

export interface DialogButtonProps {
  sx?: SxProps;
  className?: string;
  title: string;
  type: DialogButtonType;
  variant?: DialogButtonVariant;
  onPress: () => void;
  disabled?: boolean;
  minimumScreenSize?: Breakpoint;
}

export const DialogButton = observer((props: DialogButtonProps) => {
  const { sx, className, title, type, onPress, disabled, minimumScreenSize, variant = 'text' } = props;
  const theme = useTheme();
  const isVisible = useMediaQuery(() => theme.breakpoints.up(minimumScreenSize ?? 'xs'));

  if (!isVisible) {
    return null;
  }

  let color: DialogButtonColor = 'primary';
  if (type === 'destructive' || type === 'cancel') {
    color = 'error';
  } else if (type === 'success') {
    color = 'success';
  }

  return (
    <Button sx={sx} className={className} color={color} disabled={disabled} onClick={() => onPress()} variant={variant}>
      {title}
    </Button>
  );
});
