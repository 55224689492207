import { action, makeObservable, observable } from 'mobx';

export interface NetworkService {
  readonly isOnline: boolean;
}

export abstract class BaseNetworkService implements NetworkService {
  @observable private _isOnline = true;

  constructor() {
    makeObservable(this);
  }

  get isOnline() {
    return this._isOnline;
  }

  @action
  protected setIsOnline(value: boolean) {
    this._isOnline = value;
  }
}
