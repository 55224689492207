import { Box, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { OptionMenuView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface MonthlyFiltersOptionsScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const MonthlyFiltersOptionsScreen = observer((props: MonthlyFiltersOptionsScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { className, sx, onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createMonthlyFiltersOptions(onSuccess!, onCancel!),
    [onSuccess, onCancel]
  );
  const strings = localizationService.localizedStrings.studyo.agenda.monthly;

  const dismiss = () => viewModel.dismiss();

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={dismiss}
    >
      <DialogHeader title={strings.filterOptions.title} onClose={dismiss} />

      <OptionMenuView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons>
        <DialogButton
          title={strings.filterOptions.reset}
          type="destructive"
          disabled={!viewModel.isFiltered}
          onPress={() => viewModel.resetFilters()}
        />
        <Box flex={1} />
        <DialogButton title={strings.filterOptions.close} type="normal" onPress={dismiss} minimumScreenSize="sm" />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
