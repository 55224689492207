import { Button, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';

export interface TaskInfoBannerProps {
  sx?: SxProps;
  className?: string;
  message: string;
  button?: { title: string; action: () => void };
  variant?: 'info' | 'success' | 'warning' | 'error';
}

export const TaskInfoBanner = observer(({ sx, button, message, className, variant }: TaskInfoBannerProps) => {
  const theme =useTheme()

  return (
    <Stack
      direction="row"
      pl={2}
      pr={1}
      py={button != null ? 0.5 : 1}
      sx={{ ...sx, backgroundColor: backgroundColorFromVariant(theme, variant) }}
      className={className}
      alignItems="center"
      spacing={1}
    >
      <Typography flex={1} variant="body2">
        {message}
      </Typography>

      {button != null && (
        <Button onClick={() => button.action()} size="small">
          {button.title}
        </Button>
      )}
    </Stack>
  );
});

function backgroundColorFromVariant(
  theme: Theme,
  variant?: 'info' | 'success' | 'warning' | 'error'
): string {
  switch (variant ?? 'info') {
    case 'info':
      return theme.studyo.contents.taskInfo.bannerColor;
    case 'success':
      return theme.studyo.utils.toast.successBackgroundColor;
    case 'warning':
      return theme.studyo.utils.toast.warningBackgroundColor;
    case 'error':
      return theme.studyo.utils.toast.errorBackgroundColor;
  }
}
