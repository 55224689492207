import { SchoolDay as PBSchoolDay } from '@buf/studyo_studyo.bufbuild_es/studyo/type_generator_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { flatMap } from 'lodash';
import moize from 'moize';
import { Day } from '../types';
import { SchoolDayPeriod } from './SchoolDayPeriod';
import { SpecialDayOccurrence } from './SpecialDayOccurrence';

export class SchoolDay {
  private _pb: PBSchoolDay;

  constructor(pb: PBSchoolDay) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  @LazyGetter()
  get day(): Day {
    const day = this._pb.day;
    if (day == null) {
      throw new Error('Invalid data from backend: SchoolDay without a Day');
    }
    return new Day(day);
  }

  get cycleDay(): number {
    return this._pb.cycleDay;
  }

  get isPadding(): boolean {
    return this._pb.isPadding;
  }

  @LazyGetter()
  get specialDays(): SpecialDayOccurrence[] {
    return this._pb.specialDays.map((s) => new SpecialDayOccurrence(s));
  }

  get termNumber(): number {
    return this._pb.termNumber;
  }

  @LazyGetter()
  get periods(): SchoolDayPeriod[] {
    return this._pb.periods.map((p) => new SchoolDayPeriod(p));
  }

  get cycleDayTitle(): string {
    return this._pb.cycleDayTitle;
  }

  get displayCycleDayTitle(): string {
    if (this.cycleDayTitle != '') {
      return this.cycleDayTitle;
    } else if (this.cycleDay != 0) {
      return '' + this.cycleDay;
    } else {
      return '';
    }
  }

  @LazyGetter()
  get prunedPeriods(): SchoolDayPeriod[] {
    return this._pb.prunedPeriods.map((p) => new SchoolDayPeriod(p));
  }

  //
  // Calculated Properties
  //

  @LazyGetter()
  get visibleSpecialDays(): SpecialDayOccurrence[] {
    return this.specialDays.filter(
      (specialDay) => specialDay.symbol !== 'none' || (specialDay.symbol === 'none' && specialDay.title.length > 0)
    );
  }

  @LazyGetter()
  get sortedPeriods(): SchoolDayPeriod[] {
    return this.periods.slice().sort((p1, p2) => p1.startTime.compare(p2.startTime));
  }

  @LazyGetter()
  get sortedPrunedPeriods(): SchoolDayPeriod[] {
    return this.prunedPeriods.slice().sort((p1, p2) => p1.startTime.compare(p2.startTime));
  }

  hasCourseOccurrence = moize((sectionId: string): boolean => {
    const courseOccurrences = flatMap(this.periods, (p) => p.courseOccurrences);
    return courseOccurrences.find((c) => c.sectionId === sectionId) != null;
  });
}
