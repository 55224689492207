import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Locale } from '@shared/resources/services';
import { LoginStrings } from '@shared/resources/strings/studyo/onboarding/LoginStrings.ts';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { StudyoLogo } from '../components';
import { LoginViewModel } from '../viewmodels';

export interface LoginViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: LoginViewModel;
}

export const LoginView = observer((props: LoginViewProps) => {
  const { localizationService } = useStudyoServices();
  const location = useLocation();
  const navigate = useNavigate();

  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.onboarding.login;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  return (
    <Stack
      sx={{ ...sx, overflowX: 'hidden', overflowY: 'auto' }}
      className={clsx(className, 'login-content')}
      flex={1}
      alignItems="center"
      spacing={2}
      p={{ xs: 2, sm: 3 }}
    >
      <StudyoLogo style={{ height: isExtraSmallScreen ? 75 : 100 }} />

      <Stack my={3} alignItems="center" spacing={1} px={1}>
        <Typography variant="h5" textAlign="center">
          {strings.welcome}
        </Typography>

        <Typography textAlign="center" variant="body1">
          {strings.userMustLogin}
        </Typography>
      </Stack>

      <Box width="100%" px={2} display="flex" flexDirection="row" justifyContent={{ xs: 'stretch', sm: 'center' }}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={viewModel.isSigningUp}
          onClick={() => void viewModel.signUp(location, navigate)}
          disabled={viewModel.isSigningUp || viewModel.isLoggingIn}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.signUp}
        </LoadingButton>
      </Box>

      {viewModel.showPrivacyAlert && (
        <Typography my={1} variant="subtitle2" color="error" textAlign="center">
          {strings.privacyPolicyAlertText}
        </Typography>
      )}

      <FormControlLabel
        sx={{ maxWidth: 350 }}
        control={<Checkbox />}
        checked={viewModel.hasAcceptedTerms}
        onChange={(_, checked) => viewModel.setHasAcceptedTerms(checked)}
        label={
          <Typography fontSize={12} sx={{ color: theme.palette.text.secondary }} component="span">
            <div dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage }} />
          </Typography>
        }
      />

      <Box width="100%" px={2} display="flex" flexDirection="row" justifyContent={{ xs: 'stretch', sm: 'center' }}>
        <LoadingButton
          variant="contained-grey"
          color="primary"
          onClick={() => void viewModel.login(location, navigate)}
          loading={viewModel.isLoggingIn}
          disabled={viewModel.isSigningUp || viewModel.isLoggingIn}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.login}
        </LoadingButton>
      </Box>

      {viewModel.authInitErrorMessage != null && (
        <Typography color="error" textAlign="center">
          {viewModel.authInitErrorMessage}
        </Typography>
      )}

      {viewModel.hasError && (
        <Typography color="error" textAlign="center">
          {strings.loginError}
        </Typography>
      )}

      <Button onClick={() => viewModel.changeLanguage()} sx={{ p: 1, m: 1 }} size="small">
        {getLanguageButtonTitle(localizationService.currentLocale, strings)}
      </Button>
    </Stack>
  );
});

function getLanguageButtonTitle(currentLocale: Locale, strings: LoginStrings): string {
  switch (currentLocale) {
    case 'en':
      return strings.french;

    case 'fr':
      return strings.english;
  }
}
