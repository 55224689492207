import { ContentDefinitionUtils, DateUtils } from '@shared/components/utils';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigationService, StudyoAnalyticsService, StudyoSettingsStore } from '../../../services';
import { TimelineListElementMarginType } from './TimelineListElementMarginType';
import {
  AppTimelineListItemViewModel,
  TimelineListItemViewModel,
  TimelineListItemViewModelDelegate
} from './TimelineListItemViewModel';
import { AppTimelineListStepViewModel, TimelineListStepViewModel } from './TimelineListStepViewModel';
import { TimelineSearchFilter } from './TimelineListViewModel';

export interface TimelineListElement {
  readonly content?: TimelineListItemViewModel;
  readonly step?: TimelineListStepViewModel;
  readonly marginType: TimelineListElementMarginType;
}

export interface TimelineListGroup {
  readonly id: string;
  readonly title?: string;
  readonly data: TimelineListElement[];
}

export type TimelineListEditActionType = 'complete' | 'uncomplete';

export interface TimelineListSectionBaseViewModel {
  readonly sections: TimelineListGroup[];
  readonly contents: ContentDefinitionModel[];
  readonly searchFilterMatches: number;

  isEditing: boolean;
  readonly hasSelection: boolean;
  readonly editActionType: TimelineListEditActionType;
  readonly isUpdating: boolean;
  readonly isReadOnly: boolean;

  selectAllContents(): void;
  selectPastContents(): void;
  markSelectedContentsAsDone(): Promise<void>;
  moveAllPlannedDates(): void;
}

export abstract class AppTimelineListSectionBaseViewModel implements TimelineListSectionBaseViewModel {
  @observable protected _selectedContentIds: string[] = [];
  @observable private _isEditing = false;
  @observable private _isUpdating = false;

  protected constructor(
    protected readonly _listItemDelegate: TimelineListItemViewModelDelegate,
    protected readonly _searchFilterProvider: TimelineSearchFilter,
    protected readonly _navigationService: NavigationService,
    protected readonly _localizationService: LocalizationService,
    protected readonly _analyticsService: StudyoAnalyticsService,
    protected readonly _data: AccountData,
    protected readonly _settingsStore: StudyoSettingsStore,
    private readonly _onMovePlannedDates: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get isReadOnly(): boolean {
    return this._data.isReadOnly;
  }

  @computed
  get isEditing() {
    return this._isEditing;
  }

  set isEditing(value: boolean) {
    this._isEditing = value;

    this.sections.forEach((section) => {
      section.data.forEach((item) => {
        if (item.content != null) {
          item.content.isEditing = value;
          item.content.isSelected = false;
        }
      });
    });

    // Reset selected content when editing begins
    if (value) {
      this._selectedContentIds = [];
    }
  }

  abstract get editActionType(): TimelineListEditActionType;

  @computed
  get isUpdating() {
    return this._isUpdating;
  }

  @computed
  get hasSelection() {
    return this._selectedContentIds.length > 0;
  }

  @computed
  get sections(): TimelineListGroup[] {
    return [];
  }

  @computed
  get contents() {
    const filterManager = this._settingsStore.getPreferences(this._data.accountId).timelineContentFilters;
    const tasks = this._data.contents.filter((cd) => cd.kind === 'task');

    return filterManager.applyFiltersToContents(
      tasks,
      this._localizationService,
      true,
      this._searchFilterProvider.searchFilter
    );
  }

  @computed
  get searchFilterMatches(): number {
    return this._searchFilterProvider.searchFilter.length > 0
      ? this.sections.reduce((sum, s) => sum + s.data.length, 0)
      : 0;
  }

  @action
  selectAllContents() {
    this._selectedContentIds = _.chain(
      this.sections.map((section) =>
        section.data.map((item) => {
          if (item.content != null) {
            item.content.isSelected = true;
            return item.content.contentId;
          }

          return null;
        })
      )
    )
      .flatten()
      .compact()
      .value();
  }

  @action
  selectPastContents() {
    this._selectedContentIds = _.chain(
      this.sections.map((section) =>
        section.data.map((item) => {
          if (item.content?.isLate) {
            item.content.isSelected = true;
            return item.content.contentId;
          }

          return null;
        })
      )
    )
      .flatten()
      .compact()
      .value();
  }

  async markSelectedContentsAsDone() {
    this.isEditing = false;
    this._isUpdating = true;

    try {
      const contents: ContentDefinitionModel[] = [];

      this._selectedContentIds.forEach((id) => {
        const content = this._data.contentsById.get(id);

        if (content == null) {
          return;
        }

        const editableContent = new EditableContentDefinition(content, false);
        editableContent.state = this.editActionType === 'complete' ? 'completed' : 'active';
        contents.push(editableContent);
      });

      await this._data.createOrUpdateContents(contents);
    } finally {
      runInAction(() => (this._isUpdating = false));
    }
  }

  moveAllPlannedDates() {
    this._onMovePlannedDates();
  }

  protected calculateContentGroupByMonth(contents: ContentDefinitionModel[]) {
    const contentsByMonth = _.groupBy(contents, (c) => c.dueDay.year + c.dueDay.month.toString().padStart(2, '0'));
    const sortedMonthKeys = _.orderBy(Object.keys(contentsByMonth), [(k) => k], ['desc']);

    return sortedMonthKeys.map((key) => {
      const monthContents = contentsByMonth[key];
      const month = monthContents[0].dueDay.month;
      const year = monthContents[0].dueDay.year;
      const firstDayOfMonth = Day.create({ day: 1, month: month, year: year });

      monthContents.sort((t1, t2) => {
        const dueDayComparison = t1.dueDay.compare(t2.dueDay);

        if (dueDayComparison !== 0) {
          return -dueDayComparison;
        }

        const t1Title = ContentDefinitionUtils.getDisplayTitleForContent(
          t1,
          this._localizationService.localizedStrings
        );
        const t2Title = ContentDefinitionUtils.getDisplayTitleForContent(
          t2,
          this._localizationService.localizedStrings
        );

        return t1Title.localeCompare(t2Title, this._localizationService.currentLocale, {
          sensitivity: 'base'
        });
      });

      const elements: TimelineListElement[] = monthContents.map((c) => ({
        content: new AppTimelineListItemViewModel(
          this._listItemDelegate,
          this._navigationService,
          this._localizationService,
          this._analyticsService,
          this._data,
          c.id,
          c.sectionId,
          () => this.changeSelectionStateOfContent(c)
        ),
        marginType: 'normal'
      }));

      return {
        id: firstDayOfMonth.asString,
        title: firstDayOfMonth.formattedString(
          this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
        ),
        data: elements
      };
    });
  }

  protected compareTwoContents = (
    c1: ContentDefinitionModel,
    c2: ContentDefinitionModel,
    comparePlannedDay: boolean
  ): number => {
    const dueDayComparison = DateUtils.compareDayWithOther(c1.dueDay, c2.dueDay);
    if (dueDayComparison != 0) {
      return dueDayComparison;
    }

    if (comparePlannedDay) {
      const plannedDayComparison = DateUtils.compareDayWithOther(c1.plannedDay, c2.plannedDay);
      if (plannedDayComparison != 0) {
        return plannedDayComparison;
      }
    }

    const c1Section = this._data.sectionsById.get(c1.sectionId);
    const c2Section = this._data.sectionsById.get(c2.sectionId);

    if (c1Section == null && c2Section != null) {
      return 1;
    } else if (c1Section != null && c2Section == null) {
      return -1;
    } else if (c1Section != null && c2Section != null) {
      // Section Title
      const sectionTitleComparison = c1Section.title.localeCompare(
        c2Section.title,
        this._localizationService.currentLocale,
        {
          sensitivity: 'base'
        }
      );

      if (sectionTitleComparison != 0) {
        return sectionTitleComparison;
      }

      // Section number
      const sectionNumberComparison = c1Section.sectionNumber.localeCompare(
        c2Section.sectionNumber,
        this._localizationService.currentLocale,
        {
          sensitivity: 'base'
        }
      );

      if (sectionNumberComparison != 0) {
        return sectionNumberComparison;
      }
    }

    const c1Display = ContentDefinitionUtils.getDisplayTitleForContent(c1, this._localizationService.localizedStrings);
    const c2Display = ContentDefinitionUtils.getDisplayTitleForContent(c2, this._localizationService.localizedStrings);

    const titleComparison = c1Display.localeCompare(c2Display, this._localizationService.currentLocale, {
      sensitivity: 'base'
    });

    if (titleComparison != 0) {
      return titleComparison;
    }

    return -1;
  };

  protected createElementsForContent = (content: ContentDefinitionModel) => {
    const elements: TimelineListElement[] = [];
    const taskMarginType: TimelineListElementMarginType = content.notCompletedSteps.length > 0 ? 'none' : 'normal';

    elements.push({
      content: new AppTimelineListItemViewModel(
        this._listItemDelegate,
        this._navigationService,
        this._localizationService,
        this._analyticsService,
        this._data,
        content.id,
        content.sectionId,
        () => this.changeSelectionStateOfContent(content)
      ),
      marginType: taskMarginType
    });

    content.notCompletedSteps.forEach((step, i) => {
      const isLast = i === content.notCompletedSteps.length - 1;
      const stepMarginType = isLast ? 'bigger' : 'none';

      elements.push({
        step: new AppTimelineListStepViewModel(content.id, step.id, this._data),
        marginType: stepMarginType
      });
    });
    return elements;
  };

  @action
  private changeSelectionStateOfContent = (content: ContentDefinitionModel) => {
    const idIndex = this._selectedContentIds.indexOf(content.id);
    const isSelected = idIndex >= 0;

    if (isSelected) {
      this._selectedContentIds.splice(idIndex, 1);
    } else {
      this._selectedContentIds.push(content.id);
    }
  };
}
