import { IndexPath } from '@shared/components/utils';
import { CourseOccurrenceInfo } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { VirtualizedSectionListGroup, VirtualizedSectionListOnScrollProps } from '@shared/rxp/virtualized-section-list';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ContentPasteboardStore, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppPeriodsListItemViewModel, PeriodsListItemViewModel } from './PeriodsListItemViewModel';

export const PeriodsListItemHeight = 105;

export interface PeriodsListViewModel {
  readonly lastNoteChangedIndexPath: IndexPath | null;
  readonly sections: VirtualizedSectionListGroup<CourseOccurrenceInfo>[];
  readonly sectionId: string | undefined;
  readonly scrollItemIndex: number;
  readonly scrollOffset: number;

  heightForRowAtIndexPath(indexPath: IndexPath): number;
  listDidScrollWithProps(props: VirtualizedSectionListOnScrollProps): void;
  periodsListItemViewModelAtIndexPath(indexPath: IndexPath): PeriodsListItemViewModel;
  scrollToCurrentDay(): void;
}

export class AppPeriodsListViewModel implements PeriodsListViewModel {
  private _initialScrollSet = false;
  private _scrollOffset = 0;
  @observable private _currentDay: Day | undefined;
  @observable private _lastNoteChangedIndexPath: IndexPath | null = null;
  @observable private _scrollItemIndex = 0;
  @observable private _selectedSection: SectionModel | undefined;

  constructor(
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _data: AccountData,
    section: SectionModel | undefined
  ) {
    makeObservable(this);
    this._selectedSection = section;

    reaction(
      () => this._uiSettingsStore.periodsCurrentDay,
      (storeDay) => {
        this._currentDay = storeDay;
        this.scrollToCurrentDay();
      }
    );
  }

  @computed
  get lastNoteChangedIndexPath() {
    return this._lastNoteChangedIndexPath;
  }

  @computed
  get sections(): VirtualizedSectionListGroup<CourseOccurrenceInfo>[] {
    return [
      {
        data: this.occurrences
      }
    ];
  }

  @computed
  get sectionId() {
    return this._selectedSection?.id;
  }

  @computed
  get scrollItemIndex(): number {
    return this._scrollItemIndex;
  }

  @computed
  get scrollOffset(): number {
    return this._scrollOffset;
  }

  @computed
  get occurrences(): CourseOccurrenceInfo[] {
    return this.sectionId != null ? this._data.getOccurrencesForSectionId(this.sectionId) : [];
  }

  // interface methods

  heightForRowAtIndexPath = (indexPath: IndexPath) => {
    const occurrence = this.occurrences[indexPath.index];
    const notes = _.filter(this._data.contents, (cd) => {
      return cd.kind === 'note' && cd.dueDay.isSame(occurrence.day) && cd.duePeriodTag === occurrence.period.tag;
    });

    if (notes.length === 0) {
      return PeriodsListItemHeight;
    }

    const note = notes[0];
    const nbLines = note.notes.split('\n').length;
    const normalLineHeight = 24;
    const rowHeight = nbLines * normalLineHeight + 24;

    return Math.max(rowHeight, PeriodsListItemHeight);
  };

  listDidScrollWithProps = (props: VirtualizedSectionListOnScrollProps) => {
    if (this._initialScrollSet && !props.scrollUpdateWasRequested) {
      this._scrollOffset = props.scrollOffset;

      const index = Math.floor(this._scrollOffset / PeriodsListItemHeight);
      const occurrence = this.occurrences[index];

      if (occurrence != null) {
        const currentDay = this._uiSettingsStore.periodsCurrentDay ?? dateService.today;

        if (!currentDay.isSame(occurrence.day)) {
          this._uiSettingsStore.periodsCurrentDay = occurrence.day;
        }
      }
    } else {
      if (!this._initialScrollSet) {
        // Make sure we don't update `periodsCurrentDay` on initial scroll
        this._initialScrollSet = true;
      }
    }
  };

  periodsListItemViewModelAtIndexPath(indexPath: IndexPath) {
    const occurrence = this.occurrences[indexPath.index];

    return new AppPeriodsListItemViewModel(
      this._analyticsService,
      this._localizationService,
      this._navigationService,
      this._contentPasteboardStore,
      this._preferences,
      this._data,
      this._selectedSection!,
      occurrence,
      indexPath,
      (newIndexPath) => this.noteDidChangeAtIndexPath(newIndexPath)
    );
  }

  @action
  scrollToCurrentDay() {
    const day = this._currentDay ?? dateService.today;
    const index = _.findIndex(this.occurrences, (o) => day.isSameOrBefore(o.day));

    if (index !== -1) {
      if (this._scrollItemIndex !== index) {
        this._scrollItemIndex = index;
      }
    } else {
      // There will be a scroll so, prevent update current day
      this._initialScrollSet = false;
    }
  }

  // instance methods

  @action
  updateSelectedSection(section: SectionModel | undefined) {
    this._initialScrollSet = false;
    this._selectedSection = section;
    this.scrollToCurrentDay();
  }

  @action
  private noteDidChangeAtIndexPath(indexPath: IndexPath) {
    runInAction(() => {
      this._lastNoteChangedIndexPath = indexPath;
    });
  }
}
