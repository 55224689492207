import { RouteNameInfo } from '@shared/web/services';

export const RouteParamNames = {
  configId: 'configId',
  accountId: 'accountId',
  isInitialConfig: 'isInitialConfig'
};

const configRouteTemplate = `/c/:${RouteParamNames.configId}`;
const accountRouteTemplate = `${configRouteTemplate}/a/:${RouteParamNames.accountId}`;
const agendaRouteTemplate = `${accountRouteTemplate}/agenda`;
const configCreationTemplate = `:${RouteParamNames.isInitialConfig}`;

export const RouteTemplates = {
  /**
   * This is the root of the app.
   * @example /
   */
  landing: '/',
  /**
   * The login page
   * @example /login
   */
  login: '/login',
  /**
   * The logout page
   * @example /logout
   */
  logout: '/logout',
  /**
   * The various redirect pages
   */
  redirect: {
    /**
     * The page responsible for getting a checkout url and redirecting to it.
     */
    parentAccess: `/r/pa/:${RouteParamNames.configId}/a/:${RouteParamNames.accountId}`,
    /**
     * The destination page after a successful checkout
     */
    success: '/r/success'
  },
  /** Gets all the possible routes for the onboarding flow. */
  onboarding: {
    /**
     * The create config page. If the number is 1, we are creating the initial config. If 0, we are simpy
     * adding another one.
     * @example /onboarding/createconfig/1
     */
    configCreation: `/onboarding/createconfig/${configCreationTemplate}`,
    /**
     * The page about Today.
     * @example /onboarding/today
     */
    aboutToday: `/onboarding/today`,
    /**
     * The use code page
     * @example /onboarding/usecode
     */
    useCode: `/onboarding/usecode/${configCreationTemplate}`
  },
  /** Gets all the possible routes for a config. */
  config: {
    /** Gets all the possible routes for an account. */
    account: {
      /** Gets all the possible routes for the agenda. */
      agenda: {
        /**
         * Root path for the agenda. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda
         */
        path: agendaRouteTemplate,
        /**
         * Path for the daily agenda view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/daily
         */
        daily: `${agendaRouteTemplate}/daily`,
        /**
         * Path for the weekly view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/weekly
         */
        weekly: `${agendaRouteTemplate}/weekly`,
        /**
         * Path for the monthly view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/weekly
         */
        monthly: `${agendaRouteTemplate}/monthly`,
        /**
         * Path for the yearly view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/yearly
         */
        yearly: `${agendaRouteTemplate}/yearly`,
        /**
         * Path for the timeline view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/timeline
         */
        timeline: `${agendaRouteTemplate}/timeline`,
        /**
         * Path for the planner view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/planner
         */
        planner: `${agendaRouteTemplate}/planner`,
        /**
         * Path for the workload view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/workload
         */
        workload: `${agendaRouteTemplate}/workload`,
        /**
         * Path for the periods view. `configId` and `accountId` must be provided.
         * @example /c/123/a/456/agenda/periods
         */
        periods: `${agendaRouteTemplate}/periods`
      }
    }
  }
};

export const RouteNames: RouteNameInfo[] = [
  { routeTemplate: RouteTemplates.landing, localizationKey: 'home' },
  { routeTemplate: RouteTemplates.login, localizationKey: 'login' },
  { routeTemplate: RouteTemplates.logout, localizationKey: 'logout' },
  { routeTemplate: RouteTemplates.redirect.parentAccess, localizationKey: 'parentAccess' },
  {
    routeTemplate: RouteTemplates.onboarding.configCreation,
    localizationKey: 'onboardingConfigCreation'
  },
  { routeTemplate: RouteTemplates.onboarding.useCode, localizationKey: 'onboardingUseCode' },
  { routeTemplate: RouteTemplates.config.account.agenda.path, localizationKey: 'agenda' },
  { routeTemplate: RouteTemplates.config.account.agenda.daily, localizationKey: 'agendaDaily' },
  { routeTemplate: RouteTemplates.config.account.agenda.weekly, localizationKey: 'agendaWeekly' },
  { routeTemplate: RouteTemplates.config.account.agenda.monthly, localizationKey: 'agendaMonthly' },
  { routeTemplate: RouteTemplates.config.account.agenda.yearly, localizationKey: 'agendaYearly' },
  { routeTemplate: RouteTemplates.config.account.agenda.timeline, localizationKey: 'agendaTimeline' },
  { routeTemplate: RouteTemplates.config.account.agenda.planner, localizationKey: 'agendaPlanner' },
  { routeTemplate: RouteTemplates.config.account.agenda.workload, localizationKey: 'agendaWorkload' },
  { routeTemplate: RouteTemplates.config.account.agenda.periods, localizationKey: 'agendaPeriods' }
];
