import { CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { OnboardingBackgroundImage } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ErrorScreen } from '../ErrorScreen';

export interface ParentAccessRedirectScreenProps {
  sx?: SxProps;
  className?: string;
}

export const ParentAccessRedirectScreen = observer((props: ParentAccessRedirectScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createParentAccessRedirect(configId, accountId),

    [configId, accountId]
  );

  const strings = localizationService.localizedStrings.studyo.redirects;

  if (!viewModel.isLoading) {
    window.location.assign(viewModel.checkoutUrl);
    return null;
  }

  if (viewModel.hasError) {
    return (
      <ErrorScreen
        sx={sx}
        className={className}
        title={strings.errorTitle}
        subtitle={strings.errorSubtitle}
        message={viewModel.errorMessage}
      />
    );
  }
  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />
      <Stack spacing={2} alignItems="center" zIndex={2}>
        <Typography variant="h6" color="textPrimdary">
          {strings.contactingStripeMessage}
        </Typography>
        <CircularProgress />
      </Stack>
    </Stack>
  );
});
