import { SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';

export interface PeriodsSectionListItemViewModel {
  readonly color?: Color;
  readonly name: string;
  readonly number: string;
  readonly sectionId: string;
  readonly teacherNames: string;

  readonly didSelectItem: () => void;
}

export class AppPeriodsSectionListItemViewModel implements PeriodsSectionListItemViewModel {
  constructor(
    private readonly _data: AccountData,
    private readonly _section: SectionModel,
    private readonly didSelectSection: (section: SectionModel) => void
  ) {
    makeObservable(this);
  }

  @computed
  get color(): Color | undefined {
    return SectionUtils.getSectionColor(this._section, this._data.account, undefined);
  }

  @computed
  get name() {
    return this._section.title;
  }

  @computed
  get number() {
    return this._section.sectionNumber;
  }

  @computed
  get sectionId() {
    return this._section.id;
  }

  @computed
  get teacherNames() {
    return SectionUtils.getTeacherNames(this._section, this._data.accountsById);
  }

  didSelectItem() {
    this.didSelectSection(this._section);
  }
}
