import { Box, Divider, List, Stack, SxProps, Typography } from '@mui/material';
import { NetworkService } from '@shared/services';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ActiveProfileBox,
  OtherProfilesRow,
  ProfileMenuCreateConfigRow,
  ProfileMenuDemoRow,
  ProfileMenuNewYearMessageRow,
  ProfileMenuOptionRow,
  ProfileMenuProfileRow,
  ProfileMenuSynchronizeRow,
  StudyoLogo
} from '../../components';
import { ProfileMenuViewModel } from '../../viewmodels';

export interface ProfileMenuViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuViewModel;
  networkService?: NetworkService;
}

export const ProfileMenuView = observer((props: ProfileMenuViewProps) => {
  const { viewModel, sx, className } = props;
  const navigate = useNavigate();

  return (
    <Box sx={sx} className={className} overflow="hidden">
      <List sx={{ width: '100%', height: '100%', overflow: 'auto', pb: 2 }}>
        <ActiveProfileBox viewModel={viewModel.activeProfile} />
        <Divider />

        {viewModel.displayNewSchoolYearMessage && viewModel.currentRole !== 'individual' && (
          <>
            <ProfileMenuNewYearMessageRow role={viewModel.currentRole} />
            <ProfileMenuCreateConfigRow action={() => viewModel.createNewConfig(navigate)} />
          </>
        )}

        {viewModel.currentYearProfiles.map((profile, i) => (
          <ProfileMenuProfileRow key={`profile-menu-profile-${i}`} viewModel={profile} />
        ))}

        <OtherProfilesRow onPress={() => viewModel.presentOtherProfiles()} />
        <Divider />

        {viewModel.options.map((option, index) => (
          <Fragment key={`profile-menu-option-${index}`}>
            <ProfileMenuOptionRow viewModel={option} />
            <Divider />
          </Fragment>
        ))}

        <ProfileMenuSynchronizeRow viewModel={viewModel.synchronizeOption} />

        {viewModel.demoOptions.isDemoSchool && <ProfileMenuDemoRow sx={{ my: 2 }} viewModel={viewModel.demoOptions} />}

        <Stack direction="column" alignItems="center" justifyContent="center" py={2} spacing={0.5}>
          <StudyoLogo style={{ height: 60 }} />

          <Typography color="textSecondary" variant="caption" textAlign="center">
            {viewModel.version}
          </Typography>
          <Typography color="textSecondary" variant="caption" textAlign="center">
            {viewModel.copyright}
          </Typography>
        </Stack>
      </List>
    </Box>
  );
});
