import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AccountSummary } from '@shared/models/config';
import { action, computed, makeObservable, observable } from 'mobx';
import { OtherProfilesListProfileViewModel } from './OtherProfilesListProfileViewModel';

export interface OtherProfilesListSectionViewModel {
  readonly title: string;
  readonly profiles: OtherProfilesListProfileViewModel[];
}

export class AppOtherProfilesListSectionViewModel implements OtherProfilesListSectionViewModel {
  @observable private _profiles: OtherProfilesListProfileViewModel[];

  constructor(
    public readonly startYear: number,
    public readonly endYear: number,
    profiles: OtherProfilesListProfileViewModel[]
  ) {
    makeObservable(this);
    this._profiles = profiles;
  }

  @computed
  get title(): string {
    return SchoolYearConfigurationUtils.getDatesTitle(this.startYear, this.endYear);
  }

  @computed
  get profiles() {
    return this._profiles;
  }

  @action
  addProfile(profile: OtherProfilesListProfileViewModel) {
    this._profiles.push(profile);
  }

  shouldContainAccountSummaryProfile(account: AccountSummary): boolean {
    return (
      this.startYear === account.configurationSummary?.startDay.year &&
      this.endYear === account.configurationSummary?.endDay.year
    );
  }

  compare(otherSection: AppOtherProfilesListSectionViewModel): -1 | 0 | 1 {
    if (this.startYear !== otherSection.startYear) {
      return this.startYear < otherSection.startYear ? -1 : 1;
    } else if (this.endYear !== otherSection.endYear) {
      return this.endYear < otherSection.endYear ? -1 : 1;
    } else {
      return 0;
    }
  }
}
