import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { ReactNode, Ref, useImperativeHandle, useState } from 'react';

export interface HorizontalPagedViewElement<ItemT> {
  item: ItemT;
  index: number;
}

export interface HorizontalPagedViewProps<ItemT> {
  sx?: SxProps;
  className?: string;
  // Using a props instead of forwardRef as it messes up the generic of these props.
  forwardedRef: Ref<HorizontalPagedViewHandle>;
  data: ItemT[];
  renderPage: (item: HorizontalPagedViewElement<ItemT>, pageWidth: number) => ReactNode;
  initialPage?: number;
  onPageChanged?: (item: HorizontalPagedViewElement<ItemT>) => void;
  pageWidth: number; // Used only on iOS
  displayPagingControls: boolean;
}

export interface HorizontalPagedViewHandle {
  scrollToPage: (page: number, animated: boolean, triggerOnChange?: boolean) => boolean;
}

export const HorizontalPagedView = observer(<ItemT,>(props: HorizontalPagedViewProps<ItemT>) => {
  const {
    sx,
    className,
    forwardedRef,
    data,
    initialPage,
    pageWidth,
    onPageChanged,
    renderPage,
    displayPagingControls
  } = props;

  const [currentIndex, setCurrentIndex] = useState(initialPage ?? 0);

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        scrollToPage(page: number, _animated: boolean, triggerOnChange = false): boolean {
          if (page < 0 || page >= data.length) {
            return false;
          }

          setCurrentIndex(page);

          if (triggerOnChange && onPageChanged != null) {
            const dataObject = data[currentIndex];
            const item: HorizontalPagedViewElement<ItemT> = { item: dataObject, index: currentIndex };
            onPageChanged(item);
          }

          return true;
        }
      };
    },
    []
  );

  /**
   * Change to next element. It is a public method in case that the UI
   * handling page change is in another component.
   */
  const goToNextPage = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }

    if (onPageChanged != null) {
      const dataObject = data[currentIndex];
      const item: HorizontalPagedViewElement<ItemT> = { item: dataObject, index: currentIndex };
      onPageChanged(item);
    }
  };

  /**
   * Change to previous element. It is a public method in case that the UI
   * handling page change is in another component.
   */
  const goToPreviousPage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }

    if (onPageChanged) {
      const dataObject = data[currentIndex];
      const item: HorizontalPagedViewElement<ItemT> = { item: dataObject, index: currentIndex };
      onPageChanged(item);
    }
  };

  const renderCurrentPage = () => {
    const index = currentIndex;
    const dataObject = data[index];
    const item: HorizontalPagedViewElement<ItemT> = { item: dataObject, index };

    return renderPage(item, pageWidth);
  };
  const displayArrows = displayPagingControls == null || displayPagingControls;

  return (
    <Stack direction="row" sx={sx} className={className} overflow="hidden">
      {displayArrows && (
        <IconButton size="medium" onClick={goToPreviousPage} sx={{ alignSelf: 'center' }} disabled={currentIndex === 0}>
          <ArrowBackIosNew fontSize="small" />
        </IconButton>
      )}

      <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
        {renderCurrentPage()}
      </Box>

      {displayArrows && (
        <IconButton
          size="medium"
          onClick={goToNextPage}
          sx={{ alignSelf: 'center' }}
          disabled={currentIndex >= data.length - 1}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
});
