import { PluginListenerHandle, registerPlugin } from '@capacitor/core';
import { captureMessage } from '@sentry/capacitor';
import { AuthenticationService, CompleteLoginResult } from '@shared/services';
import { action, computed, makeObservable, observable, when } from 'mobx';

interface AppAuthPlugin {
  getStatus(): Promise<{ isReady: boolean; isLoggedIn: boolean; errorMessage?: string; exceptionMessage?: string }>;
  login(): Promise<{ isLoggedIn: boolean }>;
  getAccessToken(): Promise<{ accessToken: string }>;
  logout(): Promise<void>;

  addListener(
    eventName: 'appAuthReady',
    listenerFunc: (status: {
      isReady: boolean;
      isLoggedIn: boolean;
      errorMessage?: string;
      exceptionMessage?: string;
    }) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
}

const AppAuth = registerPlugin<AppAuthPlugin>('AppAuth');

export class AndroidAuthenticationService implements AuthenticationService {
  @observable private _initializationErrorMessage: string | undefined = undefined;
  @observable private _isAppAuthReady = false;
  @observable private _isAuthenticated = false;
  @observable private _isLoggingIn = true;

  @computed
  get initializationErrorMessage(): string | undefined {
    return this._initializationErrorMessage;
  }

  @computed
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  @computed
  get isLoggingIn() {
    // Hijack the isLoggingIn property when AppAuth is not ready, so the UI displays a spinner.
    return !this._isAppAuthReady || this._isLoggingIn;
  }

  constructor() {
    makeObservable(this);

    AppAuth.getStatus()
      .then((status) => {
        this.setIsReady(status.isReady, status.isLoggedIn, status.errorMessage, status.exceptionMessage);
      })
      .catch((error: string) => {
        this.setIsReady(false, false, error, error);
      });

    void AppAuth.addListener('appAuthReady', (status) => {
      this.setIsReady(status.isReady, status.isLoggedIn, status.errorMessage, status.exceptionMessage);
    });
  }

  async startSilentSigninFlow(): Promise<void> {
    await when(() => this._isAppAuthReady);
    this.setIsLoggingIn(false);
  }

  async login(): Promise<boolean> {
    try {
      this.setIsLoggingIn(true);

      if (this.isAuthenticated) {
        console.error('The user is already logged in.');
        return true;
      }

      const result = await AppAuth.login();
      this.setIsAuthenticated(result.isLoggedIn);
      return result.isLoggedIn;
    } catch (error) {
      console.error('An error occurred while logging in', error);
      this.setIsAuthenticated(false);
      return false;
    } finally {
      this.setIsLoggingIn(false);
    }
  }

  async completeLogin(): Promise<CompleteLoginResult> {
    // Nothing to do
    return Promise.resolve({ success: true });
  }

  async logout(): Promise<void> {
    if (!this.isAuthenticated) {
      console.error('The user is already logged out.');
      return;
    }
    try {
      await AppAuth.logout();
    } catch (error) {
      console.error('An error occurred while logging out,', error);
    } finally {
      this.setIsAuthenticated(false);
    }
  }

  async completeLogout(): Promise<void> {
    // Nothing to do
  }

  async getFreshAccessToken(): Promise<string | undefined> {
    try {
      const result = await AppAuth.getAccessToken();
      return result.accessToken;
    } catch (error) {
      console.error('Unable to get access token', error);
    }

    return undefined;
  }

  @action
  private setIsLoggingIn(value: boolean) {
    this._isLoggingIn = value;
  }

  @action
  private setIsAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  @action
  private setIsReady(isReady: boolean, isLoggedIn: boolean, errorMessage?: string, exceptionMessage?: string) {
    this._isAppAuthReady = isReady || errorMessage != null || exceptionMessage != null;
    if (isReady) {
      this._isAuthenticated = isLoggedIn;
      this._initializationErrorMessage = undefined;
    } else {
      this._initializationErrorMessage = errorMessage;

      if (errorMessage != null || exceptionMessage != null) {
        captureMessage('Authentication service is not ready', {
          tags: {
            errorMessage: errorMessage ?? 'n/a',
            exceptionMessage: exceptionMessage ?? 'n/a'
          },
          level: 'error'
        });
      }
    }
  }
}
