import { Box, Stack, SxProps, useTheme } from '@mui/material';
import { Droppable, dragManager } from '@shared/rxp/drag-drop';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { PlannerCellViewModel } from '../../../viewmodels';
import { DisplayableContentBox, isDisplayableContentDragData } from '../../contents';
import { PeriodCellDragOverIndicator, PeriodCellDragOverType } from '../utils';
import { PlannerConstants } from './PlannerConstants';

export interface PlannerNoPeriodCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerCellViewModel;
}

export const PlannerNoPeriodCell = observer((props: PlannerNoPeriodCellProps) => {
  const { viewModel, sx, className } = props;
  const theme = useTheme();
  const [dragState, setDragState] = useState<PeriodCellDragOverType | undefined>();

  const onDragOver = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      setDragState(viewModel.compareSection(data.sectionId) ? 'move' : 'copy');
    }
  };

  const onDragLeave = () => {
    setDragState(undefined);
  };

  const onDrop = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      dragManager.clearDragData();
      viewModel.copyContent(data.contentId, data.sectionId, data.isDraggingAssignment);
    }
    setDragState(undefined);
  };

  const canDropData = (data: unknown): boolean => {
    if (isDisplayableContentDragData(data)) {
      return viewModel.canPasteContent(data.contentId);
    }

    return false;
  };

  return (
    <Stack
      sx={{ ...sx, backgroundColor: theme.studyo.agenda.planner.grid.cellWithoutPeriodBackgroundColor }}
      className={className}
    >
      <Box sx={{ height: PlannerConstants.periodHeaderHeight }} />

      <Droppable
        sx={{ flex: 1 }}
        canDropData={canDropData}
        handleDragOver={onDragOver}
        handleDragLeave={onDragLeave}
        handleDrop={onDrop}
        acceptedType="content"
      >
        <Stack flex={1} position="relative">
          <DisplayableContentBox
            sx={{ flex: 1 }}
            viewModel={viewModel.contentBox}
            iconSize={PlannerConstants.iconSize}
            itemSpacing={PlannerConstants.itemSpacing}
            minItemHeight={PlannerConstants.minItemHeight}
            minItemWidth={PlannerConstants.columnWidth}
            representationKind="detailed"
            canDragTasks={true}
            invertActionButtonColors
          />

          {dragState != null && (
            <PeriodCellDragOverIndicator sx={{ position: 'absolute', bottom: 0, left: 0 }} type={dragState} />
          )}
        </Stack>
      </Droppable>
    </Stack>
  );
});
