import { useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../Routes.ts';
import { useStudyoServices } from '../UseStudyoServicesHook';

export interface AgendaScreenRouteProps {
  supportsSmallScreen?: boolean;
  children: ReactNode;
}

export const AgendaScreenRoute = observer(({ supportsSmallScreen = true, children }: AgendaScreenRouteProps) => {
  const { reactRouterRouteService } = useStudyoServices();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  if (isExtraSmallScreen && !supportsSmallScreen) {
    // If we navigate to a screen hidden because of the screen size, we redirect to the daily screen
    const destination = reactRouterRouteService.resolveLocation(RouteTemplates.config.account.agenda.path, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.accountId, value: accountId }
    ]);

    return <Navigate to={destination} replace />;
  }

  return <>{children}</>;
});
