import { SectionUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { computed, makeObservable } from 'mobx';
import { LinkedTasksPublishOccurrenceViewModel } from './LinkedTasksPublishOccurrenceViewModel';

export interface LinkedTasksPublishSectionViewModel {
  readonly sectionColor: Color | undefined;
  readonly displaySectionTitle: string;
  readonly occurrences: LinkedTasksPublishOccurrenceViewModel[];
}

export class AppLinkedTasksPublishSectionViewModel implements LinkedTasksPublishSectionViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _account: AccountModel,
    private readonly _section: SectionModel | undefined,
    public readonly occurrences: LinkedTasksPublishOccurrenceViewModel[]
  ) {
    makeObservable(this);
  }

  @computed
  get sectionColor() {
    return SectionUtils.getSectionColor(this._section, this._account, undefined);
  }

  @computed
  get displaySectionTitle() {
    if (this._section == null) {
      return this._localizationService.localizedStrings.studyo.contents.linkedTasksDelete.noCourse;
    }

    return this._section.sectionNumber.length > 0
      ? `${this._section.title} (${this._section.sectionNumber})`
      : this._section.title;
  }
}
