import { alpha, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { useTrackWebPage } from '@studyo/services';
import { LoginView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';
import './styles/LoginScreen.style.scss';

export interface LoginScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LoginScreen = observer((props: LoginScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx, className } = props;
  const viewModel = useMemo(() => viewModelFactory.createLogin(), []);
  const theme = useTheme();
  const footerTextColor = alpha(theme.palette.common.white, 0.87);
  const location = useLocation();
  const navigate = useNavigate();

  useTrackWebPage('Onboarding login');
  useEffect(() => {
    void viewModel.onInit(location, navigate);
  }, [viewModel]);

  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />
          <LoginView viewModel={viewModel} sx={{ zIndex: 2 }} />
        </Stack>
      </div>

      <Stack alignItems="center" mt={1}>
        <Typography variant="caption" color={footerTextColor} zIndex={2}>
          {viewModel.versionNumber}
        </Typography>
        <Typography variant="caption" color={footerTextColor} zIndex={2}>
          {viewModel.copyright}
        </Typography>
      </Stack>
    </Stack>
  );
});
