import { Box, CardActionArea, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { StudyoTheme } from '../../../theme';

export interface PlannerPeriodHeaderProps {
  sx?: SxProps;
  className?: string;
  backgroundColor: string;
  shadowColor: string;
  textColor: string;
  periodTitle: string;
  numberOfPeriodRemainingInTerm?: number;
  onPress: () => void;
  theme?: StudyoTheme;
}

export const PlannerPeriodHeader = observer((props: PlannerPeriodHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const {
    backgroundColor,
    shadowColor,
    periodTitle,
    numberOfPeriodRemainingInTerm,
    onPress,
    sx,
    className,
    textColor
  } = props;

  const remainingPeriodCountText =
    numberOfPeriodRemainingInTerm != null
      ? localizationService.localizedStrings.studyo.agenda.planner.periodRemainingInTerm(numberOfPeriodRemainingInTerm)
      : undefined;

  return (
    <CardActionArea
      sx={{
        ...sx,
        backgroundColor,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
      onClick={onPress}
      className={className}
    >
      <Stack flex={1} direction="row" alignItems="center" justifyContent="space-between" px={1} color={textColor}>
        <Typography variant="subtitle2" color="inherit">
          {periodTitle}
        </Typography>

        {remainingPeriodCountText != null && (
          <Typography variant="caption" color="inherit">
            {remainingPeriodCountText}
          </Typography>
        )}
      </Stack>

      <Box sx={{ height: '2px', backgroundColor: shadowColor }} />
    </CardActionArea>
  );
});
