import { AppModalService } from '@shared/web/services';
import { StudyoServicesContextValues } from './StudyoServicesContext.tsx';
import { AppStudyoServiceProvider } from './StudyoWebServices.ts';
import { AppViewModelFactory } from './services';

export class StudyoServiceContainer {
  private static _instance: StudyoServiceContainer | undefined;
  private readonly _services: StudyoServicesContextValues;

  static get instance(): StudyoServiceContainer {
    if (this._instance == null) {
      this._instance = new StudyoServiceContainer();
    }

    return this._instance;
  }

  static get services(): StudyoServicesContextValues {
    return this.instance.services;
  }

  constructor() {
    const modalService = new AppModalService();
    const serviceProvider = new AppStudyoServiceProvider(modalService);
    const viewModelFactory = new AppViewModelFactory();
    this._services = { ...serviceProvider, viewModelFactory, modalService };
  }

  get services(): StudyoServicesContextValues {
    return this._services;
  }
}
