import { Divider, List, ListItem, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AttachmentListViewModel } from '../../../viewmodels';
import { ListSection } from '../../lists';
import { TaskAttachmentListItem } from './TaskAttachmentListItem';

export interface TaskAttachmentListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AttachmentListViewModel;
}

export const TaskAttachmentListView = observer((props: TaskAttachmentListViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.contents.attachments;

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className} disablePadding>
      <ListSection>
        {viewModel.attachments.length === 0 && (
          <ListItem>
            <ListItemText primary={strings.noAttachments} />
          </ListItem>
        )}
        {viewModel.attachments.map((attachment, i) => (
          <Fragment key={attachment.key}>
            <TaskAttachmentListItem viewModel={attachment} />
            {i < viewModel.attachments.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ListSection>
    </List>
  );
});
