import { css } from '@emotion/css';
import { Box, SxProps } from '@mui/material';
import clsx from 'clsx';
import { Ref, forwardRef } from 'react';

export interface TintedImageProps {
  sx?: SxProps;
  className?: string;
  source: string;
  resizeMode?: 'cover' | 'stretch' | 'repeat';
  color?: string;
}

export const TintedImage = forwardRef((props: TintedImageProps, ref: Ref<HTMLDivElement>) => {
  const { sx, className, color, resizeMode } = props;

  let maskSize = 'contain';
  switch (resizeMode) {
    case 'cover':
      maskSize = 'cover';
      break;

    case 'stretch':
      maskSize = '100% 100%';
      break;

    case 'repeat':
      maskSize = 'auto';
      break;

    default:
      break;
  }

  const maskRepeat = resizeMode === 'repeat' ? 'repeat' : 'no-repeat';

  // It is necessary to wrap the url in quotes as in url("a.jpg?q=(a and b)").
  // If the url is unquoted and contains parenthesis, e.g. a.jpg?q=(a and b), it will become url(a.jpg?q=(a and b))
  // which will not render on the screen.
  const imageUrl = 'url("' + props.source + '")';

  const imageClassName = css(
    color != null
      ? {
          backgroundColor: color,
          maskPosition: 'center center',
          maskSize,
          maskRepeat,
          WebkitMaskPosition: 'center center',
          WebkitMaskSize: maskSize,
          WebkitMaskRepeat: maskRepeat,
          maskImage: imageUrl,
          WebkitMaskImage: imageUrl
        }
      : {
          backgroundImage: imageUrl,
          backgroundPosition: 'center center',
          backgroundSize: resizeMode ?? 'cover',
          backgroundRepeat: maskRepeat
        }
  );

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        overflow: 'visible',
        backgroundColor: 'transparent',
        display: 'flex',
        ...sx
      }}
      className={clsx([className, imageClassName])}
    />
  );
});

TintedImage.displayName = 'TintedImage';
