import { AccountUtils, SectionUtils } from '@shared/components/utils';
import { AccountModel, EditableAccount, EditableSectionColorSetting, SectionModel } from '@shared/models/config';
import { AllColors, Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface CourseSelectionSectionListItemViewModel {
  readonly color: Color;
  readonly groupNumber: string;
  readonly isReadonly: boolean;
  readonly isSelected: boolean;
  readonly isTaughtSection: boolean;
  readonly isAutoEnrolled: boolean;
  sectionId: string;
  showCustomColorSelection: boolean;
  readonly teacherName?: string;
  readonly title: string;

  colorFromStringValue: (value: string) => Color;
  didSelectCustomColor: (color: Color) => void;
}

export class AppCourseSelectionSectionListItemViewModel implements CourseSelectionSectionListItemViewModel {
  constructor(
    private readonly _data: AccountData,
    private readonly _account: EditableAccount,
    public readonly sectionId: string
  ) {
    makeObservable(this);
  }

  @computed
  get color(): Color {
    return SectionUtils.getSectionColor(this.section, this._account, undefined)!;
  }

  @computed
  get groupNumber() {
    return this.section.sectionNumber;
  }

  @computed
  get isReadonly() {
    const hasConflicts = this.conflictingSections.indexOf(this.section.id) >= 0;
    // Sections with an auto-enroll grade level tag should not be selectable even if the user doesn't match the
    // grade level. This is because the student may not have a gradeLevel initially, but we still want to prevent
    // selecting those courses.
    const hasAutoEnrollTags = this.section.autoEnrollTags.some((tag) => tag.startsWith('gradeLevel='));
    return this.isAutoEnrolled || (!this.isUserSection && hasConflicts) || this.isTaughtSection || hasAutoEnrollTags;
  }

  @computed
  get isSelected() {
    return (
      this._account.settings.selectedSectionIds.indexOf(this.sectionId) >= 0 ||
      this.isAutoEnrolled ||
      this.isTaughtSection
    );
  }

  @computed
  get showCustomColorSelection() {
    return this.isAutoEnrolled || !this.isReadonly;
  }

  @computed
  get teacherName(): string | undefined {
    return AccountUtils.getDisplayLastFirstName(this.defaultTeacher);
  }

  @computed
  get title() {
    return this.section.title;
  }

  colorFromStringValue(value: string) {
    const colorIndex = _.chain(AllColors)
      .map((c) => c.toString())
      .indexOf(value)
      .value();

    return colorIndex >= 0 ? AllColors[colorIndex] : 'light-bmgray';
  }

  didSelectCustomColor(color: Color) {
    const setting = this.getCustomColorSetting();

    if (setting != null) {
      setting.color = color;
    } else {
      const colorSetting = EditableSectionColorSetting.createNew(this.sectionId);
      colorSetting.color = color;

      this._account.getEditableSettings().addSectionColor(colorSetting);
    }
  }

  @computed
  private get conflictingSections(): string[] {
    const conflicts = _.chain(this._account.settings.selectedSectionIds)
      .map((sId) => this._data.scheduleConflicts.GetLabelsIntersectingWith(sId))
      .flatMap()
      .uniq()
      .value();

    return conflicts;
  }

  private getCustomColorSetting(): EditableSectionColorSetting | undefined {
    return this._account.getEditableSettings().editableSectionColors.find((c) => c.sectionId === this.sectionId);
  }

  @computed
  private get defaultTeacher(): AccountModel | undefined {
    return this._data.accountsById.get(this.section.defaultTeacherId);
  }

  @computed
  get isAutoEnrolled(): boolean {
    return (
      this.section.autoEnrollRoles.indexOf(this._account.role) >= 0 ||
      this.section.autoEnrollTags.indexOf(`gradeLevel=${this._account.gradeLevel}`) >= 0
    );
  }

  @computed
  get isTaughtSection(): boolean {
    return this.section.teacherIds.findIndex((id) => id === this._data.accountId) >= 0;
  }

  @computed
  private get isUserSection(): boolean {
    return this._account.settings.selectedSectionIds.indexOf(this.sectionId) >= 0;
  }

  @computed
  private get section(): SectionModel {
    return this._data.sectionsById.get(this.sectionId)!;
  }
}
