import { Button, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { WorkloadHeaderViewModel } from '../../viewmodels';
import { CalendarHeader } from './header';

export interface WorkloadHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkloadHeaderViewModel;
}

export const WorkloadHeader = observer(({ sx, viewModel, className }: WorkloadHeaderProps) => {
  const { localizationService, imageService } = useStudyoServices();
  const strings = localizationService.localizedStrings.studyo.agenda.workload;

  const onRefreshButtonPress = () => {
    viewModel.refresh();
  };

  const onOpenInsightsButtonPress = () => {
    viewModel.openInsights();
  };

  return (
    <CalendarHeader
      sx={sx}
      className={className}
      viewModel={viewModel}
      dateSwitcherContext={undefined}
      actionButtons={[{ kind: 'sync', action: () => onRefreshButtonPress() }]}
      renderOptionalView={(sx) => (
        <Button
          sx={{ ...sx, whiteSpace: 'nowrap' }}
          onClick={onOpenInsightsButtonPress}
          startIcon={
            <img
              src={imageService.studyoImages.generic.insightsLogo}
              alt={'Insights logo'}
              style={{ width: 24, height: 24 }}
            />
          }
          size="small"
        >
          {strings.openInsights}
        </Button>
      )}
      renderDate={(sx) => (
        <Stack sx={sx} alignItems="center" direction="row">
          <Typography sx={{ userSelect: 'none' }} variant="h6">
            {strings.title}
          </Typography>
        </Stack>
      )}
    />
  );
});
