import { DateUtils } from '@shared/components/utils';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface MonthlyHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly hasContentFiltersSet: boolean;
  readonly hasSectionFiltersSet: boolean;
  readonly currentDay: Day;
  readonly currentDays: Day[];
  readonly dateTitle: string;

  displayContentsFilter: () => Promise<void | DialogCancelled>;
  displayOptionMenu: () => Promise<void | DialogCancelled>;
  displaySectionsFilter: () => Promise<void | DialogCancelled>;
}

export class AppMonthlyHeaderViewModel extends AppBaseAgendaHeaderViewModel implements MonthlyHeaderViewModel {
  @computed
  get currentDay(): Day {
    const day = this._uiSettingsStore.monthlyCurrentDay ?? dateService.today;

    if (this._data.schoolDaysByDay.get(day.asString) != null) {
      return day;
    } else {
      const isBeforeFirstDay = this._data.schoolDays.find((sd) => sd.day.isAfter(day)) != null;
      return this._data.schoolDays[isBeforeFirstDay ? 0 : this._data.schoolDays.length - 1].day;
    }
  }

  @computed
  get currentDays(): Day[] {
    return this._data.schoolDays
      .filter((sd) => DateUtils.daysAreInSameMonth(sd.day, this.currentDay))
      .map((sd) => sd.day);
  }

  @computed
  get dateTitle(): string {
    return this.currentDay.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  @computed
  get hasContentFiltersSet() {
    return this._preferences.monthlyContentFilters.isFiltering;
  }

  @computed
  get hasSectionFiltersSet() {
    const numberOfSections = this._preferences.monthlySectionFilters.length;
    return numberOfSections !== 0 && numberOfSections !== this._data.userSections.length;
  }

  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  async displayContentsFilter() {
    return this._navigationService.navigateToMonthlyFilterOptionsModal();
  }

  async displayOptionMenu() {
    return this._navigationService.navigateToMonthlyOptionMenuModal();
  }

  async displaySectionsFilter() {
    return this._navigationService.navigateToMonthlySectionFilterModal();
  }
}
