import { Capacitor } from '@capacitor/core';
import { ImageService, LocalizationService, ThemeService } from '@shared/resources/services';
import { ThemeKind } from '@shared/resources/themes';
import {
  AuthenticationService,
  KillSwitchService,
  LinkingService,
  ModalService,
  NetworkService,
  ScreenService,
  StartUpService,
  Storage
} from '@shared/services';
import { MultiAnalyticsService } from '@shared/services/analytics';
import {
  AppCalendarStore,
  AppContentStore,
  AppSchoolYearConfigurationStore,
  AppUserStore,
  CalendarStore,
  ContentStore,
  SchoolYearConfigurationStore,
  UserStore
} from '@shared/services/stores';
import { GrpcTransports } from '@shared/services/transports';
import {
  AppIOSAccessTokenProvider,
  AppReactRouterRouteService,
  ReactRouterRouteService,
  WebAuthenticationService,
  WebImageService,
  WebKillSwitchService,
  WebLinkingService,
  WebLocalStorage,
  WebLocalizationService,
  WebNetworkService,
  WebScreenService
} from '@shared/web/services';
import { AndroidAuthenticationService } from '@shared/web/services/AndroidAuthenticationService';
import { WebMixpanelAnalyticsService } from '@shared/web/services/WebMixpanelAnalyticsService';
import {
  AccountAutoSyncService,
  AccountService,
  AppAccountService,
  AppContentPasteboardStore,
  AppStudyoSettingsStore,
  AppStudyoStartUpService,
  AppUISettingsStore,
  AppWebEnvironmentService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoSettingsStore,
  StudyoThemeService,
  StudyoWebAppInsightsAnalyticsService,
  StudyoWebIntercomAnalyticsService,
  SubscriptionsManager,
  UISettingsStore,
  WebAccountAutoSyncService,
  WebAttachmentManager,
  WebEnvironmentService,
  WebNavigationService,
  WebSubscriptionsManager
} from '@studyo/services';
import { RouteNames } from './Routes';
import { StudyoAnalyticsService } from './services';
import { AndroidAttachmentManager } from './services/AndroidAttachmentManager';

export interface StudyoServiceProvider {
  readonly accountAutoSyncService: AccountAutoSyncService;
  readonly accountService: AccountService;
  readonly analyticsService: StudyoAnalyticsService;
  readonly attachmentManager: AttachmentManager;
  readonly authenticationService: AuthenticationService;
  readonly calendarStore: CalendarStore;
  readonly contentPasteboardStore: ContentPasteboardStore;
  readonly contentStore: ContentStore;
  readonly environmentService: WebEnvironmentService;
  readonly imageService: ImageService;
  readonly killSwitchService: KillSwitchService;
  readonly linkingService: LinkingService;
  readonly localStorage: Storage;
  readonly localizationService: LocalizationService;
  readonly navigationService: NavigationService;
  readonly networkService: NetworkService;
  readonly reactRouterRouteService: ReactRouterRouteService;
  readonly schoolYearConfigurationStore: SchoolYearConfigurationStore;
  readonly screenService: ScreenService;
  readonly settingsStore: StudyoSettingsStore;
  readonly subscriptionsManager: SubscriptionsManager;
  readonly themeService: ThemeService;
  readonly uiSettingsStore: UISettingsStore;
  readonly userStore: UserStore;
  readonly startUpService: StartUpService;
}

export class AppStudyoServiceProvider implements StudyoServiceProvider {
  readonly accountAutoSyncService: AccountAutoSyncService;
  readonly accountService: AccountService;
  readonly analyticsService: StudyoAnalyticsService;
  readonly attachmentManager: AttachmentManager;
  readonly authenticationService: AuthenticationService;
  readonly calendarStore: CalendarStore;
  readonly contentPasteboardStore: ContentPasteboardStore;
  readonly contentStore: ContentStore;
  readonly environmentService: WebEnvironmentService;
  readonly imageService: ImageService;
  readonly killSwitchService: KillSwitchService;
  readonly linkingService: LinkingService;
  readonly localStorage: Storage;
  readonly localizationService: LocalizationService;
  readonly navigationService: NavigationService;
  readonly networkService: NetworkService;
  readonly reactRouterRouteService: ReactRouterRouteService;
  readonly schoolYearConfigurationStore: SchoolYearConfigurationStore;
  readonly screenService: ScreenService;
  readonly settingsStore: StudyoSettingsStore;
  readonly subscriptionsManager: SubscriptionsManager;
  readonly themeService: ThemeService;
  readonly uiSettingsStore: UISettingsStore;
  readonly userStore: UserStore;
  readonly startUpService: StartUpService;

  constructor(modalService: ModalService) {
    this.environmentService = new AppWebEnvironmentService();

    this.killSwitchService = new WebKillSwitchService(this.environmentService);

    const appInsightsAnalyticsService = new StudyoWebAppInsightsAnalyticsService(this.environmentService);
    const intercomAnalyticsService = new StudyoWebIntercomAnalyticsService(this.environmentService);
    const mixpanelAnalyticsService = new WebMixpanelAnalyticsService(this.environmentService);
    this.analyticsService = new MultiAnalyticsService([
      appInsightsAnalyticsService,
      intercomAnalyticsService,
      mixpanelAnalyticsService
    ]);

    this.localStorage = new WebLocalStorage();
    this.imageService = new WebImageService();

    const defaultTheme: ThemeKind = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    this.themeService = new StudyoThemeService(defaultTheme, this.localStorage);

    this.reactRouterRouteService = new AppReactRouterRouteService(RouteNames);
    this.navigationService = new WebNavigationService(
      () => this.reactRouterRouteService,
      () => modalService
    );
    this.localizationService = new WebLocalizationService(this.localStorage);
    this.settingsStore = new AppStudyoSettingsStore(() => this.localStorage);
    this.uiSettingsStore = new AppUISettingsStore();
    this.contentPasteboardStore = new AppContentPasteboardStore(this.navigationService);

    const iOSAccessTokenProvider = new AppIOSAccessTokenProvider();
    this.authenticationService =
      Capacitor.getPlatform() === 'android'
        ? new AndroidAuthenticationService()
        : new WebAuthenticationService(this.environmentService, iOSAccessTokenProvider, () => this.settingsStore);

    const transports = new GrpcTransports(this.authenticationService, this.environmentService);
    this.calendarStore = new AppCalendarStore(transports.generator, transports.school);
    this.contentStore = new AppContentStore(transports.content);
    this.schoolYearConfigurationStore = new AppSchoolYearConfigurationStore(transports.school, transports.content);
    this.userStore = new AppUserStore(() => this.localStorage, transports.user);
    this.networkService = new WebNetworkService();

    this.accountService = new AppAccountService(
      this.userStore,
      this.localStorage,
      this.settingsStore,
      this.uiSettingsStore,
      this.authenticationService,
      this.navigationService,
      this.networkService,
      this.analyticsService,
      this.killSwitchService,
      transports.school,
      transports.generator,
      transports.content
    );
    this.attachmentManager =
      Capacitor.getPlatform() === 'android'
        ? new AndroidAttachmentManager(this.accountService)
        : new WebAttachmentManager(
            () => this.environmentService,
            () => this.localizationService
          );
    this.accountAutoSyncService = new WebAccountAutoSyncService(this.accountService, this.networkService);

    const screenService = new WebScreenService();
    this.screenService = screenService;
    screenService.onInit();
    this.linkingService = new WebLinkingService();

    this.subscriptionsManager = new WebSubscriptionsManager(transports.subscriptions);

    // Always last.
    this.startUpService = new AppStudyoStartUpService(this.localizationService, this.themeService, this.accountService);
  }
}
