import { css } from '@emotion/css';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ThemedScreen } from '@shared/ThemedScreen.tsx';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from './utils';

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const fallback = (
    <ThemedScreen>
      <ErrorBoundaryFallback />
    </ThemedScreen>
  );

  return (
    <SentryErrorBoundary
      fallback={fallback}
      onError={(error, componentStack) => {
        console.error(error);
        console.log(componentStack);
      }}
    >
      {props.children}
    </SentryErrorBoundary>
  );
};

const ErrorBoundaryFallback = observer(() => {
  const { accountService, localizationService } = useStudyoServices();
  const strings = localizationService.localizedStrings.studyo.errorBoundary;
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  const logout = async () => {
    await accountService.logout();
    navigate('/');
  };

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const controlClassName = css({
    minWidth: !isExtraSmallScreen ? 350 : undefined
  });

  return (
    <Stack flex={1} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            flex={1}
            zIndex={2}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            p={{ xs: 2, sm: 3 }}
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
            spacing={2}
          >
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h5" textAlign="center" color="textPrimary">
                {strings.title}
              </Typography>
              <Typography variant="body1" textAlign="center" color="textPrimary">
                {strings.subtitle}
              </Typography>
            </Stack>

            <Box
              sx={{
                height: '1px',
                backgroundColor: theme.studyo.onboarding.separatorLineColor,
                width: !isExtraSmallScreen ? 300 : undefined
              }}
            />

            <Button variant="contained" onClick={goToHomepage} className={controlClassName}>
              {strings.goToHomepage}
            </Button>

            <Button variant="contained-grey" color="error" onClick={() => void logout()} className={controlClassName}>
              {strings.logout}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
