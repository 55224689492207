import { SpecialDaySymbolAndColor } from '@shared/components/special_day_symbols';
import { AccountUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SpecialDayOccurrence } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';
import { DayAndWeekSchoolDayViewModel } from './DayAndWeekSchoolDayViewModel';
import { DayAndWeekViewModel } from './DayAndWeekViewModel';

export interface DayAndWeekHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly isDaily: boolean;
  readonly currentDay: Day;
  readonly currentDayCycleDay: string;
  readonly currentDays: Day[];
  readonly weeklyDateTitle: string;
  readonly specialDays: SpecialDayOccurrence[];
  readonly specialDayTitles: string;
  readonly specialDaySymbolAndColors: SpecialDaySymbolAndColor[];

  displayOptions: () => Promise<void | DialogCancelled>;
}

export abstract class AppDayAndWeekHeaderViewModel
  extends AppBaseAgendaHeaderViewModel
  implements DayAndWeekHeaderViewModel
{
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  abstract get currentSchoolDay(): DayAndWeekSchoolDayViewModel;
  abstract get dayAndWeekViewModel(): DayAndWeekViewModel;
  abstract get isDaily(): boolean;
  protected abstract get isWeekly(): boolean;

  @computed
  get currentDay(): Day {
    return this.currentSchoolDay.day;
  }

  @computed
  get currentDayCycleDay(): string {
    return this.currentSchoolDay.cycleDayTitle;
  }

  @computed
  get currentDays(): Day[] {
    return this.dayAndWeekViewModel.currentDays.map((d) => d.day);
  }

  @computed
  get weeklyDateTitle() {
    return this.currentDay.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  @computed
  get profileFullName(): string {
    return AccountUtils.getDisplayFirstLastName(this._data.account);
  }

  @computed
  get specialDays(): SpecialDayOccurrence[] {
    return this.currentSchoolDay.specialDays;
  }

  @computed
  get specialDayTitles(): string {
    return this.specialDays.map((d) => d.title).join(', ');
  }

  @computed
  get specialDaySymbolAndColors(): SpecialDaySymbolAndColor[] {
    return this.specialDays.map(
      (sd) =>
        ({
          symbol: sd.symbol,
          color: SectionColors.get(sd.color)
        }) as SpecialDaySymbolAndColor
    );
  }

  abstract displayOptions(): Promise<void | DialogCancelled>;
}
