import { SxProps } from '@mui/material';
import { ContentAttachmentModel } from '@shared/models/content';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, DialogHeader, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ContentAttachmentAddView } from '../../../views';

export interface ContentAttachmentAddScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  addAttachment: (attachment: ContentAttachmentModel) => Promise<void>;
}

export const ContentAttachmentAddScreen = observer((props: ContentAttachmentAddScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, addAttachment, onSuccess, onCancel } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createAddAttachment(addAttachment, onSuccess!, onCancel!),
    [addAttachment]
  );

  const strings = localizationService.localizedStrings.studyo.contents.attachments.add;

  const close = () => {
    viewModel.close(false);
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="md"
      fullWidth={true}
      open={true}
      maxHeight="medium"
      onClose={close}
      disableEnforceFocus
    >
      <DialogHeader title={strings.title} onClose={close} />

      <ContentAttachmentAddView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} minimumScreenSize="sm">
        <DialogButton title={strings.close} onPress={close} type="normal" />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
