import { TaskIconPublishKind } from '@shared/components/contents';
import { ContentDefinitionUtils, DateUtils, SchoolDayPeriodUtils } from '@shared/components/utils';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { EditableContentDefinition } from '@shared/models/content';
import {
  ContentIcon,
  ContentState,
  ContentWorkloadLevel,
  Day,
  Integration,
  UnplannedContentIcons
} from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable, observable } from 'mobx';

export type TaskDueBoxTaskState = 'active' | 'today' | 'late' | 'none';

export interface TaskDueBoxViewModel {
  assignmentDay: Day;
  plannedDay: Day;
  dueDay: Day;
  duePeriodTag: string;

  readonly canEditDates: boolean;
  readonly hasChangedAssignmentDay: boolean;
  readonly hasChangedPlannedDay: boolean;
  readonly displayPlannedDay: boolean;
  readonly externalSource: Integration | undefined;
  readonly icon: ContentIcon;
  readonly isPrivate: boolean;
  readonly state: ContentState;
  readonly taskIconPublishKind: TaskIconPublishKind;
  readonly dueBoxTaskState: TaskDueBoxTaskState;
  readonly hasSteps: boolean;
  readonly workloadLevel: ContentWorkloadLevel;
}

export class AppTaskDueBoxViewModel implements TaskDueBoxViewModel {
  @observable private _hasChangedAssignmentDay = false;
  @observable private _hasChangedPlannedDay = false;

  readonly canEditDates = true;

  constructor(
    private readonly _data: AccountData,
    private readonly _task: EditableContentDefinition,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
  }

  @computed
  get assignmentDay() {
    return this._task.assignmentDay;
  }

  set assignmentDay(value: Day) {
    this._task.assignmentDay = value;
    this._hasChangedAssignmentDay = true;

    if (this.plannedDay.isBefore(value)) {
      this.plannedDay = value;
    }
  }

  @computed
  get hasChangedAssignmentDay() {
    return this._hasChangedAssignmentDay;
  }

  @computed
  get plannedDay() {
    return this._task.plannedDay;
  }

  set plannedDay(value: Day) {
    this._task.plannedDay = value;
    this._hasChangedPlannedDay = true;
  }

  @computed
  get hasChangedPlannedDay() {
    return this._hasChangedPlannedDay;
  }

  @computed
  get dueDay() {
    return this._task.dueDay;
  }

  set dueDay(value: Day) {
    this._task.dueDay = value;

    const newDuePeriod = this.getFirstPeriodForTaskSectionInDueSchoolDay();
    if (newDuePeriod != null) {
      this.duePeriodTag = newDuePeriod.tag;
    }

    if (this.assignmentDay.isAfter(value)) {
      this.assignmentDay = value;
    }

    if (this.plannedDay.isAfter(value)) {
      this.plannedDay = value;
    }

    if (
      this._task.shouldBeCreated &&
      !this.hasChangedAssignmentDay &&
      !this.hasChangedPlannedDay &&
      UnplannedContentIcons.includes(this._task.icon)
    ) {
      this._task.assignmentDay = this._task.plannedDay = value;
    }
  }

  @computed
  get duePeriodTag() {
    const schoolDay = this.dueSchoolDay;

    if (this._task.duePeriodTag === '') {
      return '';
    } else if (this.isClassTimesConfig && schoolDay != null) {
      const period = schoolDay.periods.find((p) => p.tag === this._task.duePeriodTag);

      if (period != null) {
        const format = this._localizationService.localizedStrings.models.timeFormats.singleDigit;

        return `${period.startTime.formattedString(format)} - ${period.endTime.formattedString(format)}`;
      } else {
        return '';
      }
    } else {
      return this._task.duePeriodTag;
    }
  }

  set duePeriodTag(value: string) {
    this._task.duePeriodTag = value;
  }

  @computed
  get icon() {
    return this._task.icon;
  }

  @computed
  get state() {
    return this._task.state;
  }

  @computed
  get dueBoxTaskState() {
    if (this.state !== 'active') {
      return 'none';
    } else if (DateUtils.isToday(this._task.dueDay)) {
      return 'today';
    } else if (DateUtils.isLate(this._task.dueDay)) {
      return 'late';
    }

    return 'active';
  }

  @computed
  get isPrivate() {
    return this._task.isPrivate;
  }

  @computed
  get workloadLevel() {
    return this._task.workloadLevel;
  }

  @computed
  get taskIconPublishKind(): TaskIconPublishKind {
    return ContentDefinitionUtils.getTaskIconPublishKind(this._task, this._data.sectionsById, this._data.config);
  }

  @computed
  get externalSource() {
    return this._task.externalContent != null ? this._task.externalContent.sourceIntegration : undefined;
  }

  @computed
  get displayPlannedDay() {
    return !this._task.isMaster;
  }

  @computed
  get hasSteps(): boolean {
    return this._task.steps.length > 0;
  }

  @computed
  private get config() {
    return this._data.config;
  }

  @computed
  private get schoolDays() {
    return this._data.schoolDays;
  }

  @computed
  private get isClassTimesConfig() {
    return this.config.scheduleKind === 'class-times';
  }

  @computed
  private get dueSchoolDay() {
    return this.schoolDays.find((sd) => sd.day.isSame(this._task.dueDay));
  }

  private getFirstPeriodForTaskSectionInDueSchoolDay(): SchoolDayPeriod | undefined {
    if (this.dueSchoolDay != null && this._task.sectionId.length > 0) {
      return this.dueSchoolDay.sortedPeriods.find((period) =>
        SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this._data.account).some(
          (co) => co.sectionId === this._task.sectionId
        )
      );
    }

    return undefined;
  }
}
