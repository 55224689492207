import { ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { ContentPasteboardStore, NavigationService } from '../../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../../services/analytics';
import { DisplayableContentViewModel } from './DisplayableContentViewModel';

export interface DisplayableContentBoxViewModel {
  readonly contents: DisplayableContentViewModel[];
  readonly canEdit: boolean;
  readonly canPaste: boolean;
  readonly isImpersonating: boolean;
  createNewTask: () => Promise<void>;
  openTaskInfoForContent: (content: ContentDefinitionModel) => Promise<void>;
  openNoteEdit: (content: ContentDefinitionModel) => Promise<void>;
  openPeriodContentList: () => Promise<void>;
  openSchoolDayContentList: () => Promise<void>;
  toggleStateForContent: (content: ContentDefinitionModel) => Promise<void>;
}

export class AppDisplayableContentBoxViewModel implements DisplayableContentBoxViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _data: AccountData,
    private readonly _day: Day,
    private readonly _period: SchoolDayPeriod | undefined,
    private readonly _section: () => SectionModel | undefined,
    private readonly _contents: () => DisplayableContentViewModel[]
  ) {
    makeObservable(this);
  }

  @computed
  get canEdit() {
    return !this._data.isReadOnly && this._data.canAddOrEditContentAtDate(this._day);
  }

  @computed
  get canPaste(): boolean {
    return this._contentPasteboardStore.storedContent != null;
  }

  @computed
  get contents(): DisplayableContentViewModel[] {
    const contents = this._contents().slice();
    return contents.sort((c1, c2) =>
      ContentDefinitionUtils.compareLocalizedContents(
        { content: c1.content, isAssignment: c1.isAssignment },
        { content: c2.content, isAssignment: c2.isAssignment },
        this._localizationService.localizedStrings,
        this._localizationService.currentLocale
      )
    );
  }

  @computed
  get isImpersonating() {
    return this._data.isImpersonating;
  }

  async createNewTask() {
    if (this.canEdit) {
      const section = this._section();
      const sectionId = section != null ? section.id : undefined;

      await this._navigationService.navigateToTaskEditModal(
        undefined,
        this._day,
        this._period != null ? this._period.tag : '',
        sectionId
      );
    }
    return Promise.resolve();
  }

  async openTaskInfoForContent(content: ContentDefinitionModel) {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.viewTask });

    if (!(this.isImpersonating && content.isPrivate)) {
      await this._data.ensureMarkedAsRead(content.id);
      await this._navigationService.navigateToTaskInfoModal(content.id);
    }
  }

  async openNoteEdit(content: ContentDefinitionModel) {
    if (content.kind !== 'note') {
      throw new Error('Cannot display period info from a non-note content.');
    }

    await this._navigationService.navigateToNoteEditModal(content.dueDay, content.duePeriodTag, this._section()?.id);
  }

  async openPeriodContentList() {
    if (this._day == null) {
      throw new Error("Can't display period content list if no day is defined.");
    }

    const section = this._section();
    const sectionId = section != null ? section.id : undefined;

    await this._navigationService.navigateToDisplayableContentListModal(
      this._day,
      this._period != null ? this._period.tag : '',
      sectionId,
      this.contents.map((content) => content.content.id)
    );
  }

  async openSchoolDayContentList() {
    if (this._day == null) {
      throw new Error("Can't display school day content list if no day is defined.");
    }

    await this._navigationService.navigateToSchoolDayContentListModal(this._day);
  }

  toggleStateForContent(content: ContentDefinitionModel) {
    const editableContentDefinition = new EditableContentDefinition(content, false);
    editableContentDefinition.toggleState();
    editableContentDefinition.isUnread = false;

    void this._data.createOrUpdateContent(editableContentDefinition);

    return Promise.resolve();
  }
}
