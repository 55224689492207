import { Box, SxProps } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import { AutoSizer } from '../../components/layout';
import { IndexPath } from '../../components/utils';
import {
  BaseVirtualizedSectionListDataSource,
  VirtualizedSectionListGroup
} from './BaseVirtualizedSectionListDataSource.ts';

export interface VirtualizedSectionListHandle {
  scrollToOffset: (offset: number) => void;
  scrollToItem: (index: number) => void;
  reloadRowsAtIndexPath: (indexPath: IndexPath, forceUpdate: boolean) => void;
}

export type VirtualizedSectionListScrollDirection = 'forward' | 'backward';

export interface VirtualizedSectionListOnScrollProps {
  scrollDirection: VirtualizedSectionListScrollDirection;
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
}

export interface VirtualizedSectionListProps<T> {
  sx?: SxProps;
  className?: string;
  sections: VirtualizedSectionListGroup<T>[];

  renderRowAtIndexPath: (indexPath: IndexPath) => ReactNode;
  heightForRowAtIndexPath: (indexPath: IndexPath) => number;
  renderHeaderForSection: (section: number) => ReactNode;
  heightForSectionHeader: (section: number) => number;
  renderFooterForSection: (section: number) => ReactNode;
  heightForSectionFooter: (section: number) => number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll?: (props: VirtualizedSectionListOnScrollProps) => any;

  initialOffset?: number;
  offsetBottom: number;
  overscanCount?: number;
}

const VirtualizedSectionListComponent = <T,>(
  props: VirtualizedSectionListProps<T>,
  ref: ForwardedRef<VirtualizedSectionListHandle>
) => {
  const {
    initialOffset,
    onScroll,
    overscanCount,
    sections,
    sx,
    className,
    heightForSectionFooter,
    heightForSectionHeader,
    renderFooterForSection,
    renderHeaderForSection,
    offsetBottom,
    heightForRowAtIndexPath,
    renderRowAtIndexPath
  } = props;
  const listRef = useRef<List>(null);

  useImperativeHandle(
    ref,
    () => ({
      scrollToOffset(offset: number) {
        listRef.current?.scrollTo(offset);
      },

      scrollToItem(index: number) {
        listRef.current?.scrollToItem(index);
      },

      reloadRowsAtIndexPath(indexPath: IndexPath, forceUpdate: boolean) {
        listRef.current?.resetAfterIndex(indexPath.index, forceUpdate);
      }
    }),
    []
  );

  let rowCount = 0;
  sections.forEach((section) => (rowCount += 2 + section.data.length));

  // Resetting cached styles for list when data changes.
  listRef.current?.resetAfterIndex(0, false);

  const rowHeightAtIndex = (index: number) => {
    const indexPath = BaseVirtualizedSectionListDataSource.indexPathForRow(sections, index);

    if (indexPath != null) {
      if (indexPath.type === 'cell') {
        return heightForRowAtIndexPath(indexPath);
      } else if (indexPath.type === 'header') {
        return heightForSectionHeader(indexPath.section);
      } else {
        return heightForSectionFooter(indexPath.section);
      }
    }

    return 0;
  };

  const rowRenderer = ({ index, style }: ListChildComponentProps) => {
    return <div style={style}>{componentForRow(index)}</div>;
  };

  const componentForRow = (row: number): ReactNode => {
    const indexPath = BaseVirtualizedSectionListDataSource.indexPathForRow(sections, row);

    if (indexPath != null) {
      if (indexPath.type === 'header') {
        return renderHeaderForSection(indexPath.section);
      } else if (indexPath.type === 'footer') {
        return renderFooterForSection(indexPath.section);
      } else {
        return renderRowAtIndexPath(indexPath);
      }
    } else {
      return <Box />;
    }
  };

  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column" overflow="hidden">
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            height={height}
            itemCount={rowCount}
            itemSize={rowHeightAtIndex}
            width={width}
            initialScrollOffset={initialOffset}
            onScroll={onScroll}
            style={{ paddingBottom: offsetBottom }}
            overscanCount={overscanCount}
          >
            {rowRenderer}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
};

export const VirtualizedSectionList = forwardRef(VirtualizedSectionListComponent);
