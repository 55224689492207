import { ContentDefinition_Attachment as PBAttachment } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { BaseModel, SerializableModel } from '../Model';
import { AttachmentIcon, AttachmentKind } from '../types';
import { attachmentKindFromProtobuf } from '../types/EnumConversion';

export interface ContentAttachmentModel extends SerializableModel<PBAttachment> {
  readonly id: string;
  readonly kind: AttachmentKind;
  readonly title: string;
  readonly thumbUrl: string;
  readonly fileUrl: string;
  readonly fileName: string;
  readonly externalUrl: string;
  readonly fileExtension: string | undefined;
  readonly localFilename: string;
  readonly localThumbFilename: string;
  readonly attachmentIcon: AttachmentIcon;
}

export class ContentAttachment extends BaseModel<PBAttachment> implements ContentAttachmentModel {
  constructor(pb: PBAttachment) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get kind(): AttachmentKind {
    return attachmentKindFromProtobuf(this._pb.kind);
  }

  get title(): string {
    return this._pb.title;
  }

  get thumbUrl(): string {
    return this._pb.thumbUrl;
  }

  get fileUrl(): string {
    return this._pb.fileUrl;
  }

  get fileName(): string {
    return this._pb.fileName;
  }

  get externalUrl(): string {
    return this._pb.externalUrl;
  }

  get fileExtension(): string | undefined {
    const filename = ['document', 'photo'].find((k) => k === this.kind) != null ? this.fileName : this.title;
    const parts = filename.split('.').filter((p) => p != '');

    if (parts.length <= 1) {
      return undefined;
    }

    let extension = parts.pop();

    if (extension == 'zip' && parts.length > 1) {
      const otherPart = parts.pop();

      /* istanbul ignore else */
      if (otherPart != undefined && ['numbers', 'pages', 'key'].indexOf(otherPart) > -1) {
        extension = otherPart + '.' + extension;
      }
    }

    return extension;
  }

  get localFilename(): string {
    const _fileExtension = this.fileExtension;
    const extension = _fileExtension != undefined ? '.' + _fileExtension : '';

    return this.id + extension;
  }

  get localThumbFilename(): string {
    const thumbnailExtension = '-thumb.jpg';
    return this.id + thumbnailExtension;
  }

  get attachmentIcon(): AttachmentIcon {
    switch (this.kind) {
      case 'photo':
        return 'photo-thumb';

      case 'url':
        return 'link';

      case 'document':
        return this.attachmentIconForFileExtension;

      case 'document-url':
        return this.attachmentIconForFileExtension;

      /* istanbul ignore next */
      default:
        return 'document';
    }
  }

  private get attachmentIconForFileExtension(): AttachmentIcon {
    let icon: AttachmentIcon = 'document';

    switch (this.fileExtension) {
      case 'zip':
        icon = 'zip';
        break;
      case 'doc':
      case 'docx':
        icon = 'word';
        break;
      case 'xls':
      case 'xlsx':
        icon = 'excel';
        break;
      case 'ppt':
      case 'pptx':
        icon = 'powerpoint';
        break;
      case 'pages':
      case 'pages.zip':
        icon = 'pages';
        break;
      case 'numbers':
      case 'numbers.zip':
        icon = 'numbers';
        break;
      case 'key':
      case 'key.zip':
        icon = 'keynote';
        break;
      case 'txt':
        icon = 'text';
        break;
      case 'pdf':
        icon = 'pdf';
        break;
      case 'tif':
      case 'tiff':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
        icon = 'image';
        break;
      case 'avi':
      case 'flv':
      case 'wmv':
      case 'mov':
      case 'mp4':
      case 'MPEG-4':
        icon = 'video';
        break;
      case 'mp3':
      case 'wma':
      case 'aac':
        icon = 'music';
        break;
      default:
        break;
    }

    return icon;
  }
}
