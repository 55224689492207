import { css } from '@emotion/css';
import { Box, Button, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LearnAboutTodayViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface LearnAboutTodayViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: LearnAboutTodayViewModel;
}

export const LearnAboutTodayView = observer((props: LearnAboutTodayViewProps) => {
  const { imageService, localizationService, navigationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.onboarding.learnAboutToday;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const navigate = useNavigate();
  const location = useLocation();

  const logoUrl =
    theme.palette.mode === 'dark'
      ? imageService.studyoImages.generic.studyoGoLogoDark
      : imageService.studyoImages.generic.studyoGoLogoLight;

  const controlClassName = css({
    minWidth: !isExtraSmallScreen ? 350 : undefined
  });

  const openToday = (url: string) => {
    window.location.href = url;
  };

  const logout = () => {
    void viewModel.logout();
  };

  const goBack = () => {
    void navigationService.redirectToReferrerOrLanding(location, navigate);
  };

  return (
    <Stack
      sx={{ ...sx, overflowY: 'auto', overflowX: 'hidden' }}
      className={className}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      p={{ xs: 2, sm: 3 }}
      spacing={2}
    >
      <Stack alignItems="center">
        <img src={logoUrl} style={{ width: 160, height: 90 }} alt={'Studyo Go logo'} />

        <Typography textAlign="center" variant="body1">
          {strings.subtitle}
        </Typography>
      </Stack>

      <Button variant="contained" onClick={() => openToday(strings.url)} className={controlClassName}>
        {strings.openToday}
      </Button>

      <Box
        sx={{
          height: '1px',
          backgroundColor: theme.studyo.onboarding.separatorLineColor,
          width: !isExtraSmallScreen ? 300 : undefined
        }}
      />

      <Button variant="contained-grey" onClick={goBack} className={controlClassName}>
        {strings.goBack}
      </Button>

      <Button variant="contained-grey" onClick={logout} className={controlClassName} color="error">
        {strings.logout}
      </Button>
    </Stack>
  );
});
