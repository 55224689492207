import { List, SxProps } from '@mui/material';
import { TaskStepListItemViewModel, TaskStepListViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { ReorderableList } from '../../utils';
import { TaskStepListItem } from './TaskStepListItem';

export type TaskStepListMode = 'teacher' | 'user';

export interface TaskStepListProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskStepListViewModel;
  displaySeparator?: boolean;
}

export const TaskStepList = observer((props: TaskStepListProps) => {
  const { sx, className, viewModel, displaySeparator = true } = props;
  const { steps } = viewModel;

  const renderStep = (step: TaskStepListItemViewModel, index: number) => {
    const { steps } = viewModel;
    const isNotLast = index < steps.length - 1 + (viewModel.canEdit ? 1 : 0);
    const resolvedDisplaySeparator = displaySeparator && isNotLast;

    return (
      <TaskStepListItem
        key={step.id}
        index={index}
        viewModel={step}
        displaySeparator={resolvedDisplaySeparator}
        sx={{ zIndex: 10_000 }}
      />
    );
  };

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className} disablePadding dense>
      <ReorderableList
        items={viewModel.steps}
        onOrderChanged={(oldIndex, newIndex) => viewModel.reorder(oldIndex, newIndex)}
        renderItem={renderStep}
      />
      {viewModel.canEdit && renderStep(viewModel.createEmptyStep(), steps.length)}
    </List>
  );
});
