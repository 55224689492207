import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Stack, Switch, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { ProfileMenuDemoOptionsViewModel } from '../../../viewmodels';

export interface ProfileMenuDemoRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuDemoOptionsViewModel;
}

export const ProfileMenuDemoRow = observer((props: ProfileMenuDemoRowProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileMenu;

  return (
    <Stack sx={sx} className={className} px={2}>
      <Typography color="textSecondary" variant="subtitle2">
        {strings.demoSchoolNotice}
      </Typography>

      <Stack direction="row" spacing={1} flexWrap="wrap">
        <Stack sx={{ flexGrow: 1, flexBasis: 0, minWidth: 240 }}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <Typography variant="body2">{strings.demoSchoolPreventChangesLabel}</Typography>

            <Switch
              checked={viewModel.isPreventingChanges}
              onChange={(e) => (viewModel.isPreventingChanges = e.currentTarget.checked)}
            />
          </Stack>
        </Stack>
        <Stack sx={{ flexGrow: 1, flexBasis: 0, minWidth: 240 }}>
          <Stack direction="row" justifyContent="stretch" alignContent="center" spacing={1}>
            <IconButton size="small" onClick={() => (viewModel.today = viewModel.today.addDays(-1))}>
              <ArrowBackIosNew fontSize="medium" />
            </IconButton>

            <Stack flex={1} alignItems="center" spacing={0.5}>
              <Typography variant="body2" color="textSecondary">
                {strings.demoSchoolTodayLabel}
              </Typography>
              <Typography variant="body2">
                {viewModel.today.formattedString(localizationService.localizedStrings.models.dateFormats.long)}
              </Typography>
            </Stack>

            <IconButton size="small" onClick={() => (viewModel.today = viewModel.today.addDays(1))}>
              <ArrowForwardIos fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
