import { SpecialDaySymbol } from '@shared/models/types';

export interface SpecialDaySymbolViewModel {
  readonly symbol: SpecialDaySymbol;
  readonly color: string;
}

export class AppSpecialDaySymbolViewModel implements SpecialDaySymbolViewModel {
  constructor(
    readonly symbol: SpecialDaySymbol,
    readonly color: string
  ) {}
}
