import { ContentDefinitionUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { NavigationService, StudyoSettingsStore } from '@studyo/services';
import { StudyoAnalyticsService } from '@studyo/services/analytics';
import _ from 'lodash';
import { makeObservable, override } from 'mobx';
import { TimelineListItemViewModelDelegate } from './TimelineListItemViewModel';
import {
  AppTimelineListSectionBaseViewModel,
  TimelineListElement,
  TimelineListGroup,
  TimelineListSectionBaseViewModel
} from './TimelineListSectionBaseViewModel';
import { TimelineSearchFilter } from './TimelineListViewModel';

export type TimelineListSectionTodayViewModel = TimelineListSectionBaseViewModel;

export class AppTimelineListSectionTodayViewModel extends AppTimelineListSectionBaseViewModel {
  constructor(
    listItemDelegate: TimelineListItemViewModelDelegate,
    searchFilterProvider: TimelineSearchFilter,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    analyticsService: StudyoAnalyticsService,
    data: AccountData,
    settingsStore: StudyoSettingsStore,
    onMovePlannedDates: () => void
  ) {
    super(
      listItemDelegate,
      searchFilterProvider,
      navigationService,
      localizationService,
      analyticsService,
      data,
      settingsStore,
      onMovePlannedDates
    );
    makeObservable(this);
  }

  @override
  get sections() {
    const today = dateService.today;
    const contents = this.contents;
    const tasks = _.chain(contents)
      .filter(
        (content) =>
          content.kind === 'task' &&
          ContentDefinitionUtils.isVisibleContent(content) &&
          content.state == 'active' &&
          !content.plannedDay.isAfter(today)
      )
      .sort((c1, c2) => this.compareTwoContents(c1, c2, true))
      .value();

    const elements: TimelineListElement[] = _.flatten(_.map(tasks, (t) => this.createElementsForContent(t)));

    const group: TimelineListGroup = {
      id: 'today',
      data: elements
    };

    return [group];
  }

  readonly editActionType = 'complete';
}
