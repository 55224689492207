import { DataPresenter, YearHeader, YearMonth } from '@studyo/components';
import { YearViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';

import { Box, Divider, Stack, SxProps, useTheme } from '@mui/material';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import './AgendaYearlyView.css';

export interface AgendaYearlyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: YearViewModel;
}

export const AgendaYearlyView = observer((props: AgendaYearlyViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();

  return (
    <DataPresenter
      sx={sx}
      className={className}
      dataLoaders={[viewModel.data]}
      defaultErrorMessage={localizationService.localizedStrings.studyo.agenda.errorMessage}
      renderData={() => (
        <Stack width="100%" height="100%" overflow="hidden">
          <Box>
            <YearHeader viewModel={viewModel.header} />
          </Box>

          <Divider />

          <Stack
            sx={{
              flex: 1,
              overflow: 'auto',
              backgroundColor: theme.studyo.agenda.yearly.backgroundColor
            }}
          >
            <Stack p={3}>
              <div className="yearly-months-container">
                {viewModel.months.map((month, monthIndex) => (
                  <div key={monthIndex} className="yearly-month" style={{ display: 'flex' }}>
                    <YearMonth sx={{ flex: 1 }} month={month} />
                  </div>
                ))}
              </div>
            </Stack>
          </Stack>
        </Stack>
      )}
    />
  );
});
