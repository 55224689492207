import { Add } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { AttachmentsListBoxViewModel } from '../../../viewmodels';
import { AttachmentsListItemBox } from './AttachmentsListItemBox';

interface AttachmentsListBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: AttachmentsListBoxViewModel;
}

export const AttachmentsListBox = observer((props: AttachmentsListBoxProps) => {
  const { attachmentManager } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const showPlus = viewModel.showPlusSign;
  const numberOfAttachments = viewModel.attachments.length;

  const didClickOnPlusSign = () => {
    void viewModel.didClickOnPlusSign();
  };

  const didClickOnAttachmentWithIndex = (index: number) => {
    const attachment = viewModel.attachments[index];
    attachmentManager.openAttachment(attachment);
  };

  return (
    <Stack direction="row" sx={{ ...sx, overflowY: 'auto', display: 'flex' }} className={className} spacing={1}>
      {viewModel.attachments.map((att, i) => (
        <AttachmentsListItemBox
          key={`attachment-item-${att.id}`}
          sx={{ flexShrink: 0 }}
          index={i}
          attachment={att}
          didClickOnAttachmentWithIndex={didClickOnAttachmentWithIndex}
          isLoading={false}
        />
      ))}
      {showPlus && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: 78 }}
          key={`attachment-item-${numberOfAttachments}`}
        >
          <IconButton onClick={didClickOnPlusSign} color="primary">
            <Add fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
});
