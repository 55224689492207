import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface BadgeProps {
  badgeContent?: number;
  color?: 'primary' | 'secondary' | 'default' | 'error';
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

// Not using @themedComponent since we need a classname instead of a style to pass to the badge
export const Badge = observer((props: BadgeProps) => {
  const { badgeContent, color, children, className, style } = props;

  const badgeClassName = css({ top: '2px !important' });
  return (
    <MUI.Badge
      badgeContent={badgeContent}
      color={color}
      classes={{ badge: badgeClassName }}
      className={className}
      style={style}
    >
      {children}
    </MUI.Badge>
  );
});
