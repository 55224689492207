import { WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';
import { ContentDefinitionModel } from '@shared/models/content';
import { AccountData } from '@shared/services/stores';
import { NavigationService } from '@studyo/services';
import { WorkloadInfo } from '@studyo/viewmodels';
import { zip } from 'lodash';

export function isExceededImpact(impact: WorkloadImpactSummary) {
  return impact === WorkloadImpactSummary.DAILY_EXCEEDED || impact === WorkloadImpactSummary.WEEKLY_EXCEEDED;
}

export function isReachedImpact(impact: WorkloadImpactSummary) {
  return impact === WorkloadImpactSummary.DAILY_REACHED || impact === WorkloadImpactSummary.WEEKLY_REACHED;
}

export async function verifyAndConfirmWorkload(
  navigation: NavigationService,
  data: AccountData,
  task: ContentDefinitionModel,
  usePublishTarget: boolean
): Promise<boolean> {
  if (task.workloadLevel !== 'medium' && task.workloadLevel !== 'major') {
    return true;
  }

  // There's no harm ignoring this task's id if it exists but isn't published yet.
  const workload = await data.getPublishingTaskWorkloadImpact(
    task.sectionId,
    task.dueDay,
    usePublishTarget && task.publishTarget?.kind === 'accounts' ? task.publishTarget.targetAccountIds : [],
    task.id
  );

  if (isExceededImpact(workload.impactSummary)) {
    const response = await navigation.navigateToWorkloadImpactDetails(
      [{ workload, targetSectionId: task.sectionId }],
      data.sectionsById,
      data.accountsById,
      false
    );

    return response !== 'cancelled';
  }

  return true;
}

export async function verifyAndConfirmWorkloads(
  navigation: NavigationService,
  data: AccountData,
  tasks: ContentDefinitionModel[],
  usePublishTarget: boolean
): Promise<boolean> {
  const importantTasks = tasks.filter((t) => t.workloadLevel === 'medium' || t.workloadLevel === 'major');

  if (importantTasks.length === 0) {
    return true;
  }

  // There's no harm ignoring these tasks' ids if they exist but aren't published yet.
  const workloads = await Promise.all(
    importantTasks.map((t) =>
      data.getPublishingTaskWorkloadImpact(
        t.sectionId,
        t.dueDay,
        usePublishTarget && t.publishTarget?.kind === 'accounts' ? t.publishTarget.targetAccountIds : [],
        t.id
      )
    )
  );

  const relevantWorkloadInfos = zip(workloads, importantTasks)
    .filter((pair) => isExceededImpact(pair[0]!.impactSummary))
    .map<WorkloadInfo>((pair) => ({ workload: pair[0]!, targetSectionId: pair[1]!.sectionId }));

  if (relevantWorkloadInfos.length > 0) {
    const response = await navigation.navigateToWorkloadImpactDetails(
      relevantWorkloadInfos,
      data.sectionsById,
      data.accountsById,
      false
    );

    return response !== 'cancelled';
  }

  return true;
}
