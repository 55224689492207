import { SchoolDay } from '@shared/models/calendar';
import { EditableContentDefinition, EditableContentStep } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { action, computed, makeObservable } from 'mobx';

export interface TaskStepListItemViewModel {
  readonly canEdit: boolean;
  readonly canCompleteStep: boolean;
  readonly id: string;
  readonly isStepCreation: boolean;
  readonly schoolDays: SchoolDay[];
  readonly maximumDay: Day;
  readonly minimumDay: Day;
  readonly canReorder: boolean;

  isCompleted: boolean;
  title: string;
  date: Day | undefined;

  saveNewStep: () => void;
  delete: () => void;
}

export class AppTaskStepListItemViewModel implements TaskStepListItemViewModel {
  constructor(
    private readonly _content: EditableContentDefinition,
    private readonly _step: EditableContentStep,
    private readonly _isCreatingNewStep: boolean,
    private readonly _onChange: (() => void) | undefined,
    public readonly schoolDays: SchoolDay[],
    public readonly canEdit: boolean,
    public readonly canCompleteStep: boolean,
    public readonly canReorder: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get id() {
    return this._step.id;
  }

  @computed
  get isStepCreation() {
    return this._isCreatingNewStep;
  }

  @computed
  get minimumDay() {
    return this._content.assignmentDay;
  }

  @computed
  get maximumDay() {
    return this._content.dueDay;
  }

  @computed
  get isCompleted() {
    return this._step.completed;
  }

  set isCompleted(value: boolean) {
    this._step.completed = value;
    if (this._onChange != null) {
      this._onChange();
    }
  }

  @computed
  get title() {
    return this._step.title;
  }

  set title(value: string) {
    this._step.title = value;
  }

  @computed
  get date() {
    return this._step.date;
  }

  set date(value: Day | undefined) {
    this._step.date = value;
  }

  saveNewStep() {
    if (this._isCreatingNewStep) {
      this._content.addStep(this._step);
    }
  }

  @action
  delete() {
    const sortOrder = this._step.sortOrder;

    this._step.markAsDeleted();

    this._content.steps.forEach((step) => {
      if (!step.shouldBeDeleted && step.sortOrder > sortOrder) {
        step.sortOrder -= 1;
      }
    });

    if (this._onChange != null) {
      this._onChange();
    }
  }
}
