import { SectionUtils } from '@shared/components/utils';
import { Section } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable, observable } from 'mobx';

export interface ContentDistributeElementViewModel {
  isSelected: boolean;
  readonly color: Color;
  readonly sectionTitle: string;
  readonly groupNumber: string;
  readonly isContentSection: boolean;
}

export class AppContentDistributeElementViewModel implements ContentDistributeElementViewModel {
  @observable private _isSelected = false;

  constructor(
    private readonly _data: AccountData,
    private readonly _section: Section,
    private readonly _onSelection: () => void,
    public readonly isContentSection: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get color(): Color {
    return SectionUtils.getSectionColor(this._section, this._data.account, undefined)!;
  }

  @computed
  get sectionTitle() {
    return this._section.title;
  }

  @computed
  get groupNumber() {
    return this._section.sectionNumber;
  }

  @computed
  get isSelected() {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    if (!this.isContentSection) {
      this._isSelected = value;
      this._onSelection();
    }
  }
}
