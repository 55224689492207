import { SectionUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { LinkingService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { LazyGetter } from 'lazy-get-decorator';
import { computed, makeObservable } from 'mobx';
import {
  ContentPasteboardStore,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService
} from '../../../services';
import {
  AppDayAndWeekPeriodContentBoxViewModel,
  DayAndWeekPeriodContentBoxViewModel
} from './DayAndWeekPeriodContentBoxViewModel';
import {
  AppDayAndWeekPeriodInfoBoxViewModel,
  DayAndWeekPeriodInfoBoxViewModel
} from './DayAndWeekPeriodInfoBoxViewModel';
import { DayAndWeekViewModelKind } from './DayAndWeekViewModel';

export interface DayAndWeekPeriodViewModel {
  readonly canEdit: boolean;
  readonly periodTag: string;
  readonly displayPeriodTag: boolean;
  readonly borderColor?: string;
  readonly backgroundColor?: string;
  readonly displayPeriodPrioritySelector: () => Promise<void>;
  readonly isOverlapping: boolean;
  readonly hasPriority: boolean;
  readonly isSkipped: boolean;
  readonly contentBoxViewModel: DayAndWeekPeriodContentBoxViewModel;
  readonly infoBoxViewModel: DayAndWeekPeriodInfoBoxViewModel;
}

export class AppDayAndWeekPeriodViewModel implements DayAndWeekPeriodViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _linkingService: LinkingService,
    private readonly _period: SchoolDayPeriod,
    private readonly _data: AccountData,
    private readonly _day: Day,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _viewModelKind: DayAndWeekViewModelKind,
    private readonly _periods: SchoolDayPeriod[]
  ) {
    makeObservable(this);
  }

  async displayPeriodPrioritySelector() {
    if (this._data.periodPrioritiesStore.getNumberOfConflictsForPeriod(this._period, this._day) > 1) {
      await this._navigationService.navigateToPeriodPrioritySelector(this._period.tag, this._day);
    } else {
      this._data.periodPrioritiesStore.togglePriorityForPeriod(this._period, this._day);
    }
  }

  @computed
  private get courseOccurrence() {
    return this._data.periodPrioritiesStore.getOccurrenceForPeriod(this._period, this._day);
  }

  @computed
  private get section() {
    return this.courseOccurrence != null ? this._data.sectionsById.get(this.courseOccurrence.sectionId) : undefined;
  }

  @LazyGetter()
  get contentBoxViewModel() {
    return new AppDayAndWeekPeriodContentBoxViewModel(
      this._viewModelKind,
      this._navigationService,
      this._localizationService,
      this._contentPasteboardStore,
      this._analyticsService,
      this._data,
      this._day,
      this._period,
      () => this.section,
      this.canEdit,
      this._preferences,
      this._periods
    );
  }

  @LazyGetter()
  get infoBoxViewModel() {
    return new AppDayAndWeekPeriodInfoBoxViewModel(
      this._day,
      this._period,
      this._data,
      this._localizationService,
      this._navigationService,
      this._linkingService
    );
  }

  @computed
  get canEdit() {
    return !this._data.isReadOnly;
  }

  // Returns only the first two characters if there are more
  @computed
  get periodTag() {
    if (this._period != null) {
      // We create a new array containing the individual characters of the tag as emojis can have a length superior
      // to 2, but we only want to consider them as single character.
      // https://blog.karthik.bio/why-length-of-emoji-is-2-in-javascript
      const tagChars = Array.from(this._period.tag);

      if (tagChars.length > 2) {
        return tagChars.slice(0, 2).join('');
      }
      return this._period.tag;
    } else {
      return '';
    }
  }

  @computed
  get displayPeriodTag() {
    return this._data.config.scheduleKind !== 'class-times' && this._viewModelKind === 'daily';
  }

  @computed
  get borderColor() {
    const sectionColor = SectionUtils.getSectionColor(this.section, this._data.account, undefined);
    return sectionColor != null && this.courseOccurrence ? SectionColors.get(sectionColor) : undefined;
  }

  @computed
  get backgroundColor() {
    if (this.courseOccurrence?.skipped ?? false) {
      return undefined;
    }

    const sectionColor = SectionUtils.getSectionColor(this.section, this._data.account, undefined);
    return sectionColor != null && this.courseOccurrence ? SectionColors.get(sectionColor) : undefined;
  }

  @computed
  get isOverlapping() {
    return this._data.periodPrioritiesStore.getPeriodIsOverlapping(this._period, this._day);
  }

  @computed
  get hasPriority() {
    return this._data.periodPrioritiesStore.getPeriodHasOverlappingPriority(this._period, this._day);
  }

  @computed
  get isSkipped() {
    return this.courseOccurrence?.skipped ?? false;
  }
}
