import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { min } from 'lodash';
import { action, autorun, computed, makeObservable } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoAccountSettings,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppPlannerGridViewModel, PlannerGridViewModel } from './PlannerGridViewModel';
import { AppPlannerHeaderViewModel, PlannerHeaderViewModel } from './PlannerHeaderViewModel';

export interface PlannerViewModel {
  readonly header: PlannerHeaderViewModel;
  readonly grid: PlannerGridViewModel;
  readonly data: AccountData;
  readonly preferences: StudyoAccountSettings;
  numberOfDaysPerPage: number;

  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
}

export class AppPlannerViewModel implements PlannerViewModel {
  private _numberOfDaysPerPage = 0;

  constructor(
    private readonly _uiSettingsStore: UISettingsStore,
    private readonly _contentPasteboard: ContentPasteboardStore,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _accountService: AccountService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _attachmentManager: AttachmentManager
  ) {
    makeObservable(this);
    autorun(() => {
      if (
        this.data.schoolDays.length > 0 &&
        (_uiSettingsStore.plannerCurrentDay == null || this.findIndexOfDay(_uiSettingsStore.plannerCurrentDay) === -1)
      ) {
        this.goToToday();
      }
    });
  }

  @computed
  private get currentDayIndex() {
    const currentDay = this._uiSettingsStore.plannerCurrentDay;

    if (currentDay != null) {
      return this.findIndexOfDay(currentDay);
    } else {
      const todayIndex = this.todayIndex;
      const today = this.displayedSchoolDays[todayIndex];
      return todayIndex - today.day.dayOfWeekNumber + 1;
    }
  }

  @computed
  private get displayedSchoolDays(): SchoolDay[] {
    if (this.preferences.plannerDisplayWeekends) {
      return this.data.schoolDays;
    } else {
      return this.data.schoolDays.filter((sd) => !DateUtils.isWeekend(sd.day));
    }
  }

  private get todayIndex() {
    const today = dateService.today;
    let dayIndex = -1;
    const schoolDays = this.displayedSchoolDays;

    for (let i = 0; i < schoolDays.length; i++) {
      const schoolDay = schoolDays[i];
      const compareDate = schoolDay.day.compare(today);

      if (compareDate === 0) {
        // Today is in the displayed school days, we scroll to its index.
        dayIndex = i;
        break;
      } else if (compareDate > 0) {
        // We haven't found today in the displayed school days, but we have passed it.
        // This means today is a weekend day and they are hidden. In this case, we scroll the
        // the day before.
        //
        // If we are evaluating the first school day, it means today is before the start of the school days.
        // In this case, we scroll to the first day.
        dayIndex = i > 0 ? i - 1 : 0;
        break;
      }
    }

    // All school days displayed are before today. In this case, we scroll to the last day of the year.
    if (dayIndex == -1) {
      dayIndex = schoolDays.length - 1;
    }

    return dayIndex;
  }

  @computed
  get data() {
    return this._accountService.displayedAccountData;
  }

  @computed
  get preferences() {
    return this._settingsStore.getPreferences(this.data.accountId);
  }

  @computed
  get header(): AppPlannerHeaderViewModel {
    return new AppPlannerHeaderViewModel(
      this._localizationService,
      this._navigationService,
      this._attachmentManager,
      this.data,
      this.preferences,
      this._uiSettingsStore
    );
  }

  @computed
  get grid(): AppPlannerGridViewModel {
    return new AppPlannerGridViewModel(
      this._contentPasteboard,
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this.preferences,
      this._uiSettingsStore,
      this.data
    );
  }

  get numberOfDaysPerPage(): number {
    return this._numberOfDaysPerPage;
  }

  set numberOfDaysPerPage(value: number) {
    this._numberOfDaysPerPage = value;
  }

  goToNextWeek() {
    const numberOfDaysToSkip = min([this.numberOfDaysPerPage, this.preferences.plannerDisplayWeekends ? 7 : 5])!;
    this.updateCurrentDay(this.currentDayIndex + numberOfDaysToSkip);
  }

  goToPreviousWeek() {
    const numberOfDaysToSkip = min([this.numberOfDaysPerPage, this.preferences.plannerDisplayWeekends ? 7 : 5])!;
    this.updateCurrentDay(this.currentDayIndex - numberOfDaysToSkip);
  }

  @action
  private updateCurrentDay(index: number) {
    if (index < 0) {
      this._uiSettingsStore.plannerCurrentDay = this.displayedSchoolDays[0].day;
    } else if (index >= this.grid.schoolDays.length) {
      this._uiSettingsStore.plannerCurrentDay = this.displayedSchoolDays[this.displayedSchoolDays.length - 1].day;
    } else {
      this._uiSettingsStore.plannerCurrentDay = this.displayedSchoolDays[index]?.day;
    }
  }

  private goToToday() {
    this._uiSettingsStore.plannerCurrentDay = this.displayedSchoolDays[this.todayIndex].day;
  }

  private findIndexOfDay(day: Day) {
    return this.displayedSchoolDays.findIndex((sd) => sd.day.isSame(day));
  }
}
