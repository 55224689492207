import { Day } from '../types';
import { CourseOccurrence } from './CourseOccurrence';
import { SchoolDayPeriod } from './SchoolDayPeriod';

export interface CourseOccurrenceInfo {
  readonly day: Day;
  readonly period: SchoolDayPeriod;
  readonly occurrence: CourseOccurrence;
}

export class AppCourseOccurrenceInfo {
  constructor(
    public readonly day: Day,
    public readonly period: SchoolDayPeriod,
    public readonly occurrence: CourseOccurrence
  ) {}
}
