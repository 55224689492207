import { AccountService } from '../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../services/analytics';

export interface LearnAboutTodayViewModel {
  logout: () => Promise<void>;
}

export class AppLearnAboutTodayViewModel implements LearnAboutTodayViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _analyticsService: StudyoAnalyticsService
  ) {}

  async logout() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.logout });
    await this._accountService.logout();
  }
}
