import { AnalyticsEventActionInfo } from '@shared/services/analytics';

export type StudyoAnalyticsEventCategory =
  | 'Authentication'
  | 'Navigation'
  | 'Info popover'
  | 'Task'
  | 'Note'
  | 'Teacher'
  | 'Sync'
  | 'Subscription';

export const StudyoAnalyticsEventActions = {
  authentication: {
    login: { name: 'Login', category: 'Authentication' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    signup: { name: 'Signup', category: 'Authentication' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    logout: { name: 'Logout', category: 'Authentication' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  navigation: {
    gotoToday: { name: 'Goto today', category: 'Navigation' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    gotoHeaderDay: {
      name: 'Goto header day',
      category: 'Navigation'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeActiveConfig: {
      name: 'Change active config',
      category: 'Navigation'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewSchoolLink: {
      name: 'View school link',
      category: 'Navigation'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewGearMenu: {
      name: 'View gear menu',
      category: 'Navigation'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  infoPopover: {
    viewPeriodInfo: {
      name: 'View period info',
      category: 'Info popover'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewDayInfo: {
      name: 'View day info',
      category: 'Info popover'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewPeriodContents: {
      name: 'View period contents',
      category: 'Info popover'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewDayContents: {
      name: 'View day contents',
      category: 'Info popover'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  task: {
    viewTask: { name: 'View task', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    addAttachment: {
      name: 'Add attachment',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    removeAttachment: {
      name: 'Remove attachment',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeSection: {
      name: 'Change section',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeIcon: { name: 'Change icon', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changePeriod: { name: 'Change period', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeTitle: { name: 'Change title', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeNotes: { name: 'Change notes', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    toggleImportant: {
      name: 'Toggle important',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeAnnouncementDate: {
      name: 'Change announcement date',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changePlannedDate: {
      name: 'Change planned date',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    changeDueDate: {
      name: 'Change due date',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    addStep: { name: 'Add step', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    deleteStep: { name: 'Delete step', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    editStepDate: {
      name: 'Edit step date',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    createTask: { name: 'Create task', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    updateTask: { name: 'Update task', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    createWithCamera: {
      name: 'Create with camera',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    markAsDone: { name: 'Mark as done', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    markAsNotDone: {
      name: 'Mark as not done',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    delete: { name: 'Delete', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    copy: { name: 'Copy', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    paste: { name: 'Paste', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    publishToGroup: {
      name: 'Publish to group',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    publishCustom: {
      name: 'Publish custom',
      category: 'Task'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    unpublish: { name: 'Unpublish', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    distribute: { name: 'Distribute', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    repeat: { name: 'Repeat', category: 'Task' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  note: {
    viewNote: { name: 'View note', category: 'Note' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    updateNote: { name: 'Update note', category: 'Note' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  teacher: {
    quickMovePeriod: {
      name: 'Quick move period',
      category: 'Teacher'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    customMovePeriod: {
      name: 'Custom move period',
      category: 'Teacher'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    editPeriod: { name: 'Edit period', category: 'Teacher' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewDashboardDayContents: {
      name: 'View dashboard day contents',
      category: 'Teacher'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    viewTeacherPeriodInfo: {
      name: 'View teacher period info',
      category: 'Teacher'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  sync: {
    manualSync: { name: 'Manual sync', category: 'Sync' } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  },

  subscription: {
    clickOnGiftButton: {
      name: 'Click on gift button',
      category: 'Subscription'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>,
    clickOnPlan: {
      name: 'Click on plan',
      category: 'Subscription'
    } as AnalyticsEventActionInfo<StudyoAnalyticsEventCategory>
  }
};
