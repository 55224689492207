import { Stack, SxProps, useTheme } from '@mui/material';
import { SchoolDayPeriod } from '@shared/models/calendar';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { DayAndWeekSchoolDayViewModel } from '../../../viewmodels';
import { DayAndWeekPeriod } from '../shared/DayAndWeekPeriod';
import { PeriodContentBoxDisplayType } from '../shared/PeriodContentBoxDisplayType';
import { PeriodLayout } from './PeriodLayout';

export interface DayAndWeekSchoolDayPresenterProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekSchoolDayViewModel;
  pointsPerHour: number;
  displayType: PeriodContentBoxDisplayType; // passed by the weeklyViewModel
  isCompact: boolean;
  isSmallPeriodTag: boolean;
}

const zIndexPriorityPeriod = 1000;
const zIndexNoPriorityPeriod = 500;

export const DayAndWeekSchoolDayPresenter = observer((props: DayAndWeekSchoolDayPresenterProps) => {
  const { sx, viewModel, isCompact, isSmallPeriodTag, className, pointsPerHour, displayType } = props;
  const theme = useTheme();

  const minuteHeight = useMemo(() => pointsPerHour / 60, [pointsPerHour]);

  const getPeriodLayout = (period: SchoolDayPeriod): PeriodLayout => {
    const endTimeInMinutes = period.endTime.hour * 60 + period.endTime.minute;
    const startTimeInMinutes = period.startTime.hour * 60 + period.startTime.minute;

    const minutesDuration = endTimeInMinutes - startTimeInMinutes;

    // Removing 1 pixel to make sure there is a separator between periods
    const height = minutesDuration * minuteHeight - 1;
    const offset = startTimeInMinutes * minuteHeight;
    return { height, offset };
  };

  return (
    <Stack
      sx={{ ...sx, height: pointsPerHour * 24, overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}
      className={className}
      px={1}
    >
      {viewModel.periods.map((p, i) => {
        const layout = getPeriodLayout(p.period);
        const { offset, height } = layout;

        return (
          <DayAndWeekPeriod
            key={'DayAndWeek-Period-' + i}
            isCompact={isCompact}
            isSmallPeriodTag={isSmallPeriodTag}
            viewModel={p.viewModel}
            contentDisplayType={displayType}
            height={height}
            sx={{
              position: 'absolute',
              left: theme.spacing(1),
              right: theme.spacing(1),
              top: offset,
              height: height,
              zIndex: p.viewModel.hasPriority ? zIndexPriorityPeriod : zIndexNoPriorityPeriod
            }}
          />
        );
      })}
    </Stack>
  );
});
