import { ContentDefinitionModel } from '@shared/models/content';
import { AllContentWorkloadLevels, ContentWorkloadLevel } from '@shared/models/types';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

export const ContentFilterKeys = {
  Published: 'published',
  NotPublished: 'not-published',
  Exams: 'exams',
  Homework: 'homework',
  OtherTasks: 'others',
  MinimumWorkload: 'minimum-workload',
  WithAttachments: 'with-attachements',
  NotCompleted: 'not-completed',
  ShowAnnounced: 'show-announced'
};

export interface ContentFilter {
  enabled: boolean;
  readonly isFiltering: boolean;

  filterContent: (content: ContentDefinitionModel) => boolean;
  resetFilter: () => void;
}

abstract class AppContentFilter implements ContentFilter {
  @observable protected _enabled: boolean;

  protected constructor(enabled: boolean) {
    makeObservable(this);
    this._enabled = enabled;
  }

  @computed
  get enabled() {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
  }

  abstract get isFiltering(): boolean;

  abstract filterContent(content: ContentDefinitionModel): boolean;

  abstract resetFilter(): void;
}

export interface SingleChoiceFilter extends ContentFilter {
  value: string;
}

export abstract class AppSingleChoiceFilter extends AppContentFilter implements SingleChoiceFilter {
  protected constructor() {
    super(true);
    makeObservable(this);
    this._enabled = true;
  }

  abstract get value(): string;
  abstract set value(newValue: string);
}

export class PublishedFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? PublishedFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== PublishedFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.isPublished;
  }

  @action
  resetFilter() {
    this._enabled = PublishedFilter.defaultValue;
  }
}

export class NotPublishedFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? NotPublishedFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== NotPublishedFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return !content.isPublished;
  }

  @action
  resetFilter() {
    this._enabled = NotPublishedFilter.defaultValue;
  }
}

export class ExamsFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? ExamsFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== ExamsFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.icon === 'exam';
  }

  @action
  resetFilter() {
    this._enabled = ExamsFilter.defaultValue;
  }
}

export class HomeworkFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? HomeworkFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== HomeworkFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.icon === 'homework';
  }

  @action
  resetFilter() {
    this._enabled = HomeworkFilter.defaultValue;
  }
}

export class OtherTasksFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? OtherTasksFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== OtherTasksFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return !_.includes(['homework', 'exam'], content.icon);
  }

  @action
  resetFilter() {
    this._enabled = OtherTasksFilter.defaultValue;
  }
}

export class MinimumWorkloadFilter extends AppSingleChoiceFilter {
  private static defaultValue: ContentWorkloadLevel = 'none';
  @observable private _minimumWorkload: ContentWorkloadLevel;

  constructor(value?: string) {
    super();

    makeObservable(this);

    this._minimumWorkload = (value as ContentWorkloadLevel) ?? 'none';
  }

  get isFiltering() {
    return this._minimumWorkload !== MinimumWorkloadFilter.defaultValue;
  }

  get value(): string {
    return this._minimumWorkload;
  }

  set value(newValue: string) {
    this._minimumWorkload = newValue as ContentWorkloadLevel;
  }

  filterContent(content: ContentDefinitionModel) {
    return _.includes(this.possibleWorkloadLevels, content.workloadLevel);
  }

  @action
  resetFilter() {
    this._minimumWorkload = MinimumWorkloadFilter.defaultValue;
  }

  private get possibleWorkloadLevels(): ContentWorkloadLevel[] {
    switch (this._minimumWorkload) {
      case 'none':
        return AllContentWorkloadLevels;
      case 'regular':
        return ['regular', 'medium', 'major'];
      case 'medium':
        return ['medium', 'major'];
      case 'major':
        return ['major'];
      case 'unknown':
        return AllContentWorkloadLevels;
    }
  }
}

export class WithAttachmentsFilter extends AppContentFilter {
  private static defaultValue = false;

  constructor(enabled?: boolean) {
    super(enabled ?? WithAttachmentsFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== WithAttachmentsFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.attachments.length > 0;
  }

  @action
  resetFilter() {
    this._enabled = WithAttachmentsFilter.defaultValue;
  }
}

export class NotCompletedFilter extends AppContentFilter {
  private static defaultValue = false;

  constructor(enabled?: boolean) {
    super(enabled ?? NotCompletedFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== NotCompletedFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.state !== 'completed';
  }

  @action
  resetFilter() {
    this._enabled = NotCompletedFilter.defaultValue;
  }
}

export class DoneTasksFilter extends AppContentFilter {
  private static defaultValue = false;

  constructor(enabled?: boolean) {
    super(enabled ?? DoneTasksFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== DoneTasksFilter.defaultValue;
  }

  filterContent(content: ContentDefinitionModel) {
    return content.state === 'completed';
  }

  @action
  resetFilter() {
    this._enabled = DoneTasksFilter.defaultValue;
  }
}

export class ShowAnnouncedFilter extends AppContentFilter {
  private static defaultValue = true;

  constructor(enabled?: boolean) {
    super(enabled ?? ShowAnnouncedFilter.defaultValue);
    makeObservable(this);
  }

  get isFiltering() {
    return this.enabled !== ShowAnnouncedFilter.defaultValue;
  }

  filterContent() {
    return true;
  }

  @action
  resetFilter() {
    this._enabled = ShowAnnouncedFilter.defaultValue;
  }
}
