import { SpecialDaySymbolAndColor } from '@shared/components/special_day_symbols';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { DayOfWeek } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface TimelineCollectionViewSchoolDayHeaderViewModel {
  readonly cycleDayTitle: string;
  readonly dayOfWeek: DayOfWeek;
  readonly schoolDay: SchoolDay;
  readonly schoolDayDay: number;
  readonly symbolsAndColors: SpecialDaySymbolAndColor[];
}

export class AppTimelineCollectionViewSchoolDayHeaderViewModel
  implements TimelineCollectionViewSchoolDayHeaderViewModel
{
  constructor(
    private readonly _data: AccountData,
    private readonly _schoolDayIndex: number
  ) {
    makeObservable(this);
  }

  @computed
  get cycleDayTitle() {
    return this.schoolDay.displayCycleDayTitle;
  }

  @computed
  get dayOfWeek() {
    return this.schoolDay.day.dayOfWeek;
  }

  @computed
  get schoolDay() {
    return this._data.schoolDays[this._schoolDayIndex];
  }

  @computed
  get schoolDayDay() {
    return this.schoolDay.day.day;
  }

  @computed
  get symbolsAndColors() {
    return _.map(this.schoolDay.specialDays, (sd) => {
      return { symbol: sd.symbol, color: SectionColors.get(sd.color) };
    });
  }
}
