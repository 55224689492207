import { SchoolDayPeriod } from '@shared/models/calendar';
import { AccountModel } from '@shared/models/config';

export class SchoolDayPeriodUtils {
  static getDisplayedCourseOcccurrences(period: SchoolDayPeriod, account: AccountModel) {
    const occurrences = period.courseOccurrences;

    if (account.role !== 'teacher') {
      return occurrences;
    }

    return occurrences.filter(
      (co) => account.selectedSectionIds.includes(co.sectionId) || co.teacherIds.includes(account.id)
    );
  }
}
