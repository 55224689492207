import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Color, Day, DayOfWeek } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { verifyAndConfirmWorkloads } from '@studyo/utils';
import { range } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { AccountAutoSyncService, AccountService, ContentPasteboardStore, NavigationService } from '../../services';

export type ContentRepeatKind = 'occurrence' | 'day' | 'day-of-week' | 'cycle-day';

export interface ContentRepeatViewModel extends OnInit, OnDestroy {
  readonly title: string;
  readonly taskColor: Color | undefined;
  readonly cycleDays: string[];
  readonly canRepeatByCycleDay: boolean;
  readonly minimumUntilDate: Day;
  readonly maximumUntilDate: Day;
  readonly schoolDays: SchoolDay[];

  repeatKind: ContentRepeatKind;
  selectedWeekCount: number;
  selectedDaysOfWeek: DayOfWeek[];
  selectedCycleDay: number;
  untilDate: Day;

  readonly repeatingDays: Day[];
  readonly canProceed: boolean;

  repeatContent: () => Promise<void>;
  cancel: () => void;
  dismiss: () => void;
}

export class AppContentRepeatViewModel implements ContentRepeatViewModel {
  private readonly _data: AccountData;
  private _task: ContentDefinitionModel;

  @observable private _repeatKind: ContentRepeatKind = 'occurrence';
  @observable private _cycleDay: number;
  @observable private _weekCount = 1;
  @observable private _daysOfWeek: DayOfWeek[];
  @observable private _untilDate: Day;

  constructor(
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _onSuccess: (updatedContent: ContentDefinitionModel) => void,
    private readonly _onCancel: () => void,
    accountService: AccountService,
    contentId: string
  ) {
    makeObservable(this);
    this._data = accountService.displayedAccountData;
    this._task = this._data.contentsById.get(contentId)!;
    const schoolDay = this._data.schoolDaysByDay.get(this._task.dueDay.asString);
    this._cycleDay = (schoolDay?.cycleDay ?? 0) > 0 ? schoolDay!.cycleDay : 1;
    this._daysOfWeek = [this._task.dueDay.dayOfWeek];
    this._untilDate = this._task.dueDay;
  }

  @computed
  get title() {
    return ContentDefinitionUtils.getDisplayTitleForContent(this._task, this._localizationService.localizedStrings);
  }

  @computed
  get taskColor() {
    const taskSection = this._task.sectionId.length > 0 ? this._data.sectionsById.get(this._task.sectionId) : undefined;
    return SectionUtils.getSectionColor(taskSection, this._data.account, undefined);
  }

  @computed
  get cycleDays() {
    const titles = this._data.config.cycleDayTitles;
    return range(0, this._data.config.daysPerCycle).map((cycleDay) =>
      (titles[cycleDay]?.trim().length ?? 0) > 0 ? titles[cycleDay] : `${cycleDay + 1}`
    );
  }

  @computed
  get canRepeatByCycleDay() {
    return this._data.config.scheduleKind !== 'week';
  }

  @computed
  get minimumUntilDate() {
    return this._task.dueDay;
  }

  @computed
  get maximumUntilDate() {
    return this._data.config.endDay;
  }

  @computed
  get schoolDays() {
    return this._data.schoolDays;
  }

  @computed
  get repeatKind() {
    return this._repeatKind;
  }

  set repeatKind(value: ContentRepeatKind) {
    if (value !== 'cycle-day' || this.canRepeatByCycleDay) {
      this._repeatKind = value;
    }
  }

  @computed
  get selectedWeekCount() {
    return this._weekCount;
  }

  set selectedWeekCount(value: number) {
    this._weekCount = value;
  }

  @computed
  get selectedDaysOfWeek() {
    return this._daysOfWeek;
  }

  set selectedDaysOfWeek(values: DayOfWeek[]) {
    this._daysOfWeek = values;
  }

  @computed
  get selectedCycleDay() {
    return this._cycleDay;
  }

  set selectedCycleDay(value: number) {
    if (value >= 1 && value <= this._data.config.daysPerCycle) {
      this._cycleDay = value;
    }
  }

  @computed
  get untilDate() {
    return this._untilDate;
  }

  set untilDate(value: Day) {
    if (!value.isBefore(this.minimumUntilDate) && !value.isAfter(this.maximumUntilDate)) {
      this._untilDate = value;
    }
  }

  @computed
  get canProceed() {
    return this.repeatKind !== 'day-of-week' || this.selectedDaysOfWeek.length > 0;
  }

  @computed
  get repeatingDays(): Day[] {
    const kind = this.repeatKind;

    // We return nothing for "occurrence", is has its own method. Avoid the useless
    // calculations.
    if (kind === 'occurrence') {
      return [];
    }

    const daysDiff = this._task.dueDay.dayCountUntil(this.untilDate);
    const weekCount = this.selectedWeekCount;
    const dayOffset = this._task.dueDay.dayOfWeekNumber;

    return range(1, daysDiff + 1)
      .map((dayNumber) => ({ day: this._task.dueDay.addDays(dayNumber), dayNumber }))
      .map((info) => ({
        ...info,
        schoolDay: this._data.schoolDaysByDay.get(info.day.asString)!,
        weekNumber: Math.floor((info.dayNumber + dayOffset) / 7)
      }))
      .filter(
        (info) =>
          (kind === 'day-of-week' &&
            this.selectedDaysOfWeek.includes(info.day.dayOfWeek) &&
            info.weekNumber % weekCount === 0) ||
          (kind === 'cycle-day' && info.schoolDay.cycleDay === this.selectedCycleDay) ||
          kind === 'day'
      )
      .map((info) => info.day);
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async repeatContent() {
    switch (this.repeatKind) {
      case 'occurrence':
        await this.repeatEveryOccurrence();
        break;

      default:
        await this.repeatEvery();
        break;
    }
  }

  cancel() {
    this._onCancel();
  }

  dismiss() {
    this._onSuccess(this._task);
  }

  private async repeatEvery() {
    const contentsToSave: ContentDefinitionModel[] = [];
    const editableTask = new EditableContentDefinition(this._task, false);
    const firstNewTaskIndex = editableTask.contentsGroupIdentifier.length === 0 ? 1 : 0;

    if (firstNewTaskIndex > 0) {
      editableTask.contentsGroupIdentifier = uuidv4();
      contentsToSave.push(editableTask);
    }

    // Must iterate with "for each" since we await.
    for (const day of this.repeatingDays) {
      const targetSchoolDay = this._data.schoolDaysByDay.get(day.asString)!;
      const duePeriod = targetSchoolDay.periods.find((p) => p.tag === this._task.duePeriodTag);

      const newContent =
        duePeriod != null
          ? await this._contentPasteboardStore.pasteContentInPeriod(
              editableTask,
              day,
              duePeriod.tag,
              this._data,
              editableTask.sectionId,
              true,
              false,
              false
            )
          : await this._contentPasteboardStore.pasteContentInDay(
              editableTask,
              day,
              this._task.sectionId,
              this._data,
              false,
              false
            );

      contentsToSave.push(newContent);
    }

    await this.saveContents(contentsToSave, firstNewTaskIndex);
    this._task = editableTask;
  }

  private async repeatEveryOccurrence() {
    const date = this._task.dueDay.addDays(1);
    const contentsToSave: ContentDefinitionModel[] = [];

    const editableTask = new EditableContentDefinition(this._task, false);
    const firstNewTaskIndex = editableTask.contentsGroupIdentifier.length === 0 ? 1 : 0;

    if (firstNewTaskIndex > 0) {
      editableTask.contentsGroupIdentifier = uuidv4();
      contentsToSave.push(editableTask);
    }

    const sectionOccurrences =
      editableTask.sectionId.length > 0 ? this._data.getOccurrencesForSectionId(editableTask.sectionId) : [];

    let currentOccurrenceIndex = sectionOccurrences.findIndex((occ) => occ.day.isSameOrAfter(date));
    if (currentOccurrenceIndex < 0) {
      // Next occurrence not found for task
      return;
    }

    while (
      currentOccurrenceIndex < sectionOccurrences.length &&
      sectionOccurrences[currentOccurrenceIndex].day.isSameOrBefore(this.untilDate)
    ) {
      const occurrence = sectionOccurrences[currentOccurrenceIndex];
      const newContent = await this._contentPasteboardStore.pasteContentInPeriod(
        editableTask,
        occurrence.day,
        occurrence.period.tag,
        this._data,
        undefined,
        true,
        false,
        false
      );
      contentsToSave.push(newContent);
      currentOccurrenceIndex += 1;
    }

    await this.saveContents(contentsToSave, firstNewTaskIndex);
  }

  private async saveContents(contents: ContentDefinitionModel[], firstNewTaskIndex: number) {
    const newPublishedTasks = contents.filter((c, i) => i >= firstNewTaskIndex && c.isMaster);
    if (newPublishedTasks.length > 0) {
      if (!(await verifyAndConfirmWorkloads(this._navigationService, this._data, newPublishedTasks, true))) {
        return;
      }
    }

    await Promise.all(contents.map((c) => this._data.createOrUpdateContent(c)));
  }
}
