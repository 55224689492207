import { Stack, SxProps } from '@mui/material';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { LearnAboutTodayView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import '../../styles/LoginScreen.style.scss';

export interface LearnAboutTodayScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LearnAboutTodayScreen = observer((props: LearnAboutTodayScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx, className } = props;
  const viewModel = useMemo(() => viewModelFactory.createLearnAboutTodayScreen(), []);

  return (
    <Stack sx={sx} className={className} alignItems="center" justifyContent="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <LearnAboutTodayView sx={{ flex: 1, zIndex: 2 }} viewModel={viewModel} />
        </Stack>
      </div>
    </Stack>
  );
});
