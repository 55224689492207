import { AccountUtils } from '@shared/components/utils';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable, override } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface PeriodsHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly currentDay: Day;
  readonly hasContentFiltersSet: boolean;
  readonly hasSectionFiltersSet: boolean;
  readonly periodsDateTitle: string;

  displayOptions: () => Promise<void | DialogCancelled>;
  displayFilterContents: () => Promise<void | DialogCancelled>;
  displayFilterSections: () => Promise<void | DialogCancelled>;
}

export class AppPeriodsHeaderViewModel extends AppBaseAgendaHeaderViewModel implements PeriodsHeaderViewModel {
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  get currentDay(): Day {
    return this._uiSettingsStore.periodsCurrentDay ?? dateService.today;
  }

  @override
  get profileDisplayName(): string {
    return AccountUtils.getDisplayFirstLastName(this._data.account);
  }

  @computed
  get hasContentFiltersSet() {
    return this._preferences.periodContentFilters.isFiltering;
  }

  @computed
  get hasSectionFiltersSet() {
    const numberOfSections = this._preferences.periodSectionFilters.length;
    return numberOfSections !== 0 && numberOfSections !== this._data.userSections.length;
  }

  @computed
  get periodsDateTitle() {
    return this.currentDay.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  displayOptions() {
    return this._navigationService.navigateToPeriodOptionMenuModal();
  }

  displayFilterContents() {
    return this._navigationService.navigateToPeriodTasksFiltersModal();
  }

  displayFilterSections() {
    return this._navigationService.navigateToPeriodSectionsFiltersModal();
  }
}
