import { ContentDefinitionUtils, DateUtils, SectionUtils } from '@shared/components/utils';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled, dateService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { LazyGetter } from 'lazy-get-decorator';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigationService, StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../../../services';
import { AppDisplayableContentViewModel, DisplayableContentViewModel } from '../../contents';
import { TimelineContentState } from './TimelineContentState';
import { TimelineUtils } from './TimelineUtils';

export interface TimelineListItemViewModelDelegate {
  scrollToContent: (content: ContentDefinitionModel) => void;
}

export interface TimelineListItemViewModel {
  readonly contentId: string;
  readonly color?: Color;
  readonly isActive: boolean;
  readonly isLate: boolean;
  readonly displayableContentViewModel: DisplayableContentViewModel;
  readonly notes: string;
  readonly hasSection: boolean;
  readonly sectionNumber: string;
  readonly sectionTitle: string;
  readonly state: TimelineContentState;
  readonly stateText: string;
  readonly title: string;
  isEditing: boolean;
  isSelected: boolean;

  scrollToContentAndOpenTaskInfo: () => Promise<void | DialogCancelled>;
  toggleContentState: () => void;
  toggleSelection: () => void;
}

export class AppTimelineListItemViewModel implements TimelineListItemViewModel {
  @observable private _isSelected = false;
  @observable private _isEditing = false;

  constructor(
    private readonly _delegate: TimelineListItemViewModelDelegate,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _data: AccountData,
    readonly contentId: string,
    private readonly _sectionId: string | undefined,
    private readonly _selectionChanged: () => void
  ) {
    makeObservable(this);
  }

  @computed
  private get account() {
    return this._data.account;
  }

  @computed
  get color() {
    return SectionUtils.getSectionColor(this.section, this.account, undefined);
  }

  @computed
  private get content() {
    return this._data.contentsById.get(this.contentId)!;
  }

  @computed
  private get section() {
    return this._sectionId != null ? this._data.sectionsById.get(this._sectionId) : undefined;
  }

  @LazyGetter()
  get displayableContentViewModel() {
    return new AppDisplayableContentViewModel(
      this._localizationService,
      this._data,
      this.section,
      this.content.sectionId,
      this.content,
      false
    );
  }

  @computed
  get isActive(): boolean {
    return this.content.state === 'active';
  }

  @computed
  get isLate(): boolean {
    return this.isActive && DateUtils.isLate(this.content.dueDay);
  }

  @computed
  get notes(): string {
    return ContentDefinitionUtils.getDisplayedNotes(this.content);
  }

  @computed
  get hasSection() {
    return this.section != null;
  }

  @computed
  get sectionNumber(): string {
    return this.hasSection ? this.section!.sectionNumber : '';
  }

  @computed
  get sectionTitle(): string {
    return this.section != null ? this.section.shortTitle || this.section.title.slice(0, 3) : '';
  }

  @computed
  get state(): TimelineContentState {
    return TimelineUtils.getTimelineStateForContent(this.content);
  }

  // TODO move somewhere else?
  @computed
  get stateText(): string {
    if (this.content.state != 'active') {
      return '';
    }

    const delta = DateUtils.numberOfDaysBetween(this.content.dueDay, dateService.today);

    if (delta < 0) {
      return '!';
    } else if (delta < 1) {
      return '';
    } else {
      return `${delta}${this._localizationService.localizedStrings.studyo.agenda.timeline.numberOfDays}`;
    }
  }

  @computed
  get title(): string {
    return ContentDefinitionUtils.getDisplayTitleForContent(this.content, this._localizationService.localizedStrings);
  }

  @computed
  get isSelected() {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  @computed
  get isEditing() {
    return this._isEditing;
  }

  set isEditing(value: boolean) {
    this._isEditing = value;
  }

  async scrollToContentAndOpenTaskInfo() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.viewTask });
    this._delegate.scrollToContent(this.content);

    await this._data.ensureMarkedAsRead(this.content.id);
    return await this._navigationService.navigateToTaskInfoModal(this.content.id);
  }

  toggleContentState() {
    const editableContent = new EditableContentDefinition(this.content, false);
    editableContent.toggleState();

    void this._data.createOrUpdateContent(editableContent);
  }

  @action
  toggleSelection() {
    this._selectionChanged();
    this._isSelected = !this._isSelected;
  }
}
