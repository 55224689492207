import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react';
import { SectionHeaderWidth, TimelineCollectionViewSectionHeaderViewModel } from '../../../viewmodels';

export interface TimelineCollectionViewSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineCollectionViewSectionHeaderViewModel;
}

export const TimelineCollectionViewSectionHeader = observer((props: TimelineCollectionViewSectionHeaderProps) => {
  const { sx, className, viewModel } = props;
  const { backgroundColor, sectionName, sectionNumber } = viewModel;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: SectionHeaderWidth,
        ...sx,
        backgroundColor:
          backgroundColor != null ? SectionColors.get(backgroundColor)! : theme.studyo.periods.freePeriodColor,
        height: viewModel.height,
        color: theme.studyo.agenda.timeline.sectionHeaderTextColor,
        display: 'flex',
        flexDirection: 'column'
      }}
      className={className}
      justifyContent="center"
      overflow="hidden"
    >
      <Stack flex={1} p={1} justifyContent="center">
        <Typography variant="subtitle2" textAlign="center" color="inherit" textOverflow="ellipsis" overflow="hidden">
          {sectionName}
        </Typography>
        <Typography variant="caption" textAlign="center" color="inherit">
          {sectionNumber}
        </Typography>
      </Stack>
    </Box>
  );
});
