import { SectionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { EditableContentDefinition } from '@shared/models/content';
import { Color } from '@shared/models/types';
import { DataLoader, OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AccountAutoSyncService, AccountService } from '../../../services';
import { AppTaskStepListViewModel, TaskStepListViewModel } from '../TaskStepListViewModel';

export interface TaskStepsOrderViewModel extends OnInit, OnDestroy {
  save: () => Promise<void>;
  cancel: () => Promise<void>;

  readonly data: DataLoader;
  readonly schoolDays: SchoolDay[];
  readonly userSteps: TaskStepListViewModel;
  readonly hasChanges: boolean;

  getSectionColor(section: SectionModel): Color;
}

export class AppTaskStepsOrderViewModel implements TaskStepsOrderViewModel {
  readonly data: AccountData;

  constructor(
    accountService: AccountService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _content: EditableContentDefinition,
    private readonly _onSave: (content: EditableContentDefinition) => void,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = accountService.displayedAccountData;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  cancel() {
    this._content.editableSteps.reset();
    this._onCancel();
    return Promise.resolve();
  }

  save() {
    this._onSave(this._content);
    this._onSuccess();
    return Promise.resolve();
  }

  getSectionColor(section: SectionModel) {
    return SectionUtils.getSectionColor(section, this.data.account, undefined)!;
  }

  @computed
  get schoolDays() {
    return this.data.schoolDays;
  }

  @computed
  get userSteps() {
    return new AppTaskStepListViewModel(this.schoolDays, this._content, false, undefined, false, false, true);
  }

  @computed
  get hasChanges() {
    return this._content.editableSteps.isChanged;
  }
}
