import { Check } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  ListItemButton,
  ListItemText,
  Stack,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { AccountUtils, IndexPath } from '@shared/components/utils';
import { VirtualizedSectionList } from '@shared/rxp/virtualized-section-list';
import { AvatarView } from '@studyo/components';
import { ImpersonateStudentSelectionViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface ImpersonateStudentSelectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ImpersonateStudentSelectionViewModel;
}

export const ImpersonateStudentSelectionView = observer((props: ImpersonateStudentSelectionViewProps) => {
  const { localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.impersonateStudentSelection;
  const navigate = useNavigate();
  const location = useLocation();

  const hasStudents = viewModel.students.length > 0;

  const renderRow = (indexPath: IndexPath) => {
    const student = viewModel.students[indexPath.index];
    const showSeparator = indexPath.index < viewModel.students.length - 1;
    const isSelected = viewModel.impersonatingAccount != null && viewModel.impersonatingAccount.id === student.id;

    return (
      <Fragment key={student.id}>
        <ListItemButton
          sx={sx}
          className={className}
          onClick={() => viewModel.impersonateStudent(student, location, navigate)}
        >
          <AvatarView
            sx={{ mx: 2 }}
            size={34}
            firstName={student.firstName}
            lastName={student.lastName}
            color={student.profile.avatarColor}
            isImpersonating={false}
          />

          <ListItemText primary={AccountUtils.getDisplayLastFirstName(student, student.visibleEmail)} />

          {isSelected && <Check fontSize="medium" color="primary" />}
        </ListItemButton>
        {showSeparator && <Divider />}
      </Fragment>
    );
  };

  return (
    <Stack sx={sx} className={className} spacing={2} py={2}>
      <Stack spacing={2} px={2}>
        {viewModel.impersonatingAccount != null && (
          <Button onClick={() => viewModel.endImpersonification(location, navigate)} sx={{ borderRadius: 1 }}>
            {strings.stopImpersonating}
          </Button>
        )}

        <TextField
          variant="filled"
          value={viewModel.currentFilter}
          onChange={(e) => (viewModel.currentFilter = e.currentTarget.value)}
          label={strings.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Stack>

      {!hasStudents && (
        <Stack flex={1} alignItems="center" pt={4}>
          <Typography variant="body2" fontStyle="italic">
            {strings.noStudents}
          </Typography>
        </Stack>
      )}

      {hasStudents && (
        <VirtualizedSectionList
          sx={{ flex: 1 }}
          sections={[{ data: viewModel.students }]}
          renderHeaderForSection={() => <Box />}
          heightForSectionHeader={() => 0}
          renderRowAtIndexPath={renderRow}
          heightForRowAtIndexPath={() => 50}
          renderFooterForSection={() => <Box />}
          heightForSectionFooter={() => 0}
          offsetBottom={20}
        />
      )}
    </Stack>
  );
});
