import { Button, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface SuccessScreenProps {
  sx?: SxProps;
  className?: string;
}

export const SuccessScreen = observer((props: SuccessScreenProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className } = props;
  // Yes, same as "not found" page
  const strings = localizationService.localizedStrings.studyo.redirects;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Stack sx={sx} className={className} justifyContent="center" alignItems="center" position="relative">
      <OnboardingBackgroundImage />

      <div className="login-container">
        <Stack flex={1} position="relative">
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            flex={1}
            zIndex={2}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            p={{ xs: 2, sm: 3 }}
            spacing={2}
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h5" textAlign="center">
                {strings.successTitle}
              </Typography>
              <Typography variant="body1" px={4} textAlign="center">
                {strings.successSubtitle}
              </Typography>
            </Stack>

            <Divider sx={{ width: 300 }} />

            <Button variant="contained" onClick={goToHomepage} sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }}>
              {strings.goToHomepageButtonTitle}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
