import { IconButton, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface ListSectionHeaderIconProps {
  sx?: SxProps;
  className?: string;
  icon: React.ReactNode;
  inheritColor?: boolean;
  onIconPressed?: () => void;
  disabled?: boolean;
}

export const ListSectionHeaderIcon = observer((props: ListSectionHeaderIconProps) => {
  const { icon, onIconPressed, disabled = false, sx, className, inheritColor = false } = props;
  const hasAction = onIconPressed != null && !disabled;

  return (
    <IconButton
      size="small"
      sx={sx}
      className={className}
      color={inheritColor ? 'inherit' : 'default'}
      disabled={!hasAction}
      onClick={() => onIconPressed?.()}
    >
      {icon}
    </IconButton>
  );
});
