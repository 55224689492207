import { AttachmentIcon } from '@shared/models/types';
import { ImageService } from '@shared/resources/services';

export class ContentAttachmentUtils {
  static imageForAttachmentIcon(icon: AttachmentIcon, imageService: ImageService) {
    const images = imageService.studyoImages.tasks.documentTypes;

    switch (icon) {
      case 'document':
        return images.document;
      case 'excel':
        return images.excel;
      case 'image':
        return images.image;
      case 'keynote':
        return images.keynote;
      case 'link':
        return imageService.studyoImages.tasks.addAttachments.baseLink;
      case 'music':
        return images.music;
      case 'numbers':
        return images.numbers;
      case 'pages':
        return images.pages;
      case 'pdf':
        return images.pdf;
      case 'photo-thumb':
        return images.image;
      case 'powerpoint':
        return images.powerpoint;
      case 'presentation':
        return images.presentation;
      case 'spreadsheet':
        return images.spreadsheet;
      case 'text':
        return images.text;
      case 'video':
        return images.video;
      case 'word':
        return images.word;
      case 'zip':
        return images.zip;
      default:
        break;
    }
  }
}
