import { Box, CardActionArea, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors, SectionShadowColors } from '@shared/models/Colors';
import { observer } from 'mobx-react';
import { PeriodsSectionListItemViewModel } from '../../../viewmodels';

export interface PeriodsSectionListItemProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  viewModel: PeriodsSectionListItemViewModel;
}

export const PeriodsSectionListItem = observer((props: PeriodsSectionListItemProps) => {
  const { isSelected, sx, className, viewModel } = props;
  const theme = useTheme();
  const colors = theme.studyo.periods;
  const backgroundColor = viewModel.color != null ? SectionColors.get(viewModel.color) : colors.freePeriodColor;
  const textColor = viewModel.color != null ? colors.periodWithSectionTextColor : colors.freePeriodTextColor;
  const selectedIndicatorColor =
    viewModel.color != null ? SectionShadowColors.get(viewModel.color) : colors.freePeriodShadowColor;

  const didSelectSection = () => {
    viewModel.didSelectItem();
  };

  return (
    <CardActionArea
      onClick={didSelectSection}
      sx={{ ...sx, backgroundColor, color: textColor, p: 1 }}
      className={className}
    >
      <Stack direction="row" spacing={0.5}>
        <Stack flex={1}>
          <Typography color="inherit" variant="body1" fontWeight="500">
            {viewModel.name}
          </Typography>
          <Typography color="inherit" variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {viewModel.number.length !== 0 ? viewModel.number : ' '}
          </Typography>
          <Typography color="inherit" variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {viewModel.teacherNames.length !== 0 ? viewModel.teacherNames : ' '}
          </Typography>
        </Stack>

        {isSelected && (
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                height: 20,
                width: 20,
                borderRadius: '50%',
                backgroundColor: selectedIndicatorColor
              }}
            />
          </Box>
        )}
      </Stack>
    </CardActionArea>
  );
});
