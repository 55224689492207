import { Time } from '@shared/models/types';
import { OverlappingPeriodPriorityObject } from '../../interfaces';

export const OverlappingPeriodPriorityObjectKey = 'periodPrioritiesOverlappingObjects';

export class AppOverlappingPeriodPriorityObject implements OverlappingPeriodPriorityObject {
  static fromJson(value: Record<string, unknown>) {
    const cycleDay = value.cycleDay as number;
    const periodTag = value.periodTag as string;
    const startTime: Time = Time.create(value.startTime as { hour: number; minute: number });
    const endTime: Time = Time.create(value.endTime as { hour: number; minute: number });
    const scheduleTag = value.scheduleTag as string;
    // New property, might not exist.
    const isSkipped = (value.isSkipped as boolean | undefined) ?? false;

    return new AppOverlappingPeriodPriorityObject(cycleDay, periodTag, startTime, endTime, scheduleTag, isSkipped);
  }

  constructor(
    public readonly cycleDay: number,
    public readonly periodTag: string,
    public readonly startTime: Time,
    public readonly endTime: Time,
    public readonly scheduleTag: string,
    public readonly isSkipped: boolean
  ) {}

  get asJson() {
    return {
      cycleDay: this.cycleDay,
      periodTag: this.periodTag,
      startTime: this.startTime.asJson,
      endTime: this.endTime.asJson,
      scheduleTag: this.scheduleTag
    };
  }

  overlapsWithOther(other: OverlappingPeriodPriorityObject): boolean {
    return this.overlapsWithTimes(other.startTime, other.endTime);
  }

  overlapsWithTimes(startTime: Time, endTime: Time): boolean {
    return (
      endTime.isSame(this.endTime) ||
      startTime.isSame(this.startTime) ||
      (startTime.isBefore(this.startTime) && endTime.isAfter(this.startTime)) ||
      (endTime.isAfter(this.endTime) && startTime.isBefore(this.endTime)) ||
      (startTime.isSameOrAfter(this.startTime) && endTime.isSameOrBefore(this.endTime))
    );
  }

  equals(other: OverlappingPeriodPriorityObject): boolean {
    return (
      other.cycleDay === this.cycleDay &&
      other.startTime.isSame(this.startTime) &&
      other.endTime.isSame(this.endTime) &&
      other.periodTag === this.periodTag &&
      other.scheduleTag === this.scheduleTag
    );
  }
}
