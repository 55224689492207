import { ContentAttachmentModel, EditableContentAttachment, EditableContentDefinition } from '@shared/models/content';
import { computed, makeObservable } from 'mobx';
import { AccountService, NavigationService } from '../../services';

export interface AttachmentsListBoxViewModel {
  readonly attachments: ContentAttachmentModel[];
  readonly showPlusSign: boolean;
  didClickOnPlusSign: () => Promise<void>;
}

export class AppAttachmentsListBoxViewModel implements AttachmentsListBoxViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _content: EditableContentDefinition,
    public readonly canEdit: boolean
  ) {
    makeObservable(this);
  }

  @computed
  private get isLinkedToOtherTasks() {
    if (this._content.contentsGroupIdentifier.length === 0) {
      return false;
    }

    return (
      this._accountService.displayedAccountData.visibleContents.filter(
        (cd) => cd.id !== this._content.id && cd.contentsGroupIdentifier === this._content.contentsGroupIdentifier
      ).length > 0
    );
  }

  @computed
  get attachments() {
    return this._content.isSlave ? this._content.masterContent!.attachmentsList : this._content.attachments;
  }

  @computed
  get showPlusSign() {
    return this.canEdit && !this._content.isSlave && !this.isLinkedToOtherTasks;
  }

  async didClickOnPlusSign() {
    if (this._content.isSlave) {
      await Promise.reject();
    }

    await this._navigationService.navigateToAttachmentAdd(
      async (a) => await this.addAttachment(a as EditableContentAttachment)
    );
  }

  private async addAttachment(attachment: EditableContentAttachment) {
    this._content.addAttachment(attachment);
    await this._accountService.displayedAccountData.createOrUpdateContent(this._content);
  }
}
