import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Stack, SxProps, TextField, Typography } from '@mui/material';
import { CourseSelectionFilterCell, CourseSelectionSectionList } from '@studyo/components';
import { CourseSelectionViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface CourseSelectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSelectionViewModel;
}

export const CourseSelectionView = observer((props: CourseSelectionViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const { courseSelection: strings } = localizationService.localizedStrings.studyo.agenda.setting;

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  const didSelectNewGradeLevel = (index: number | undefined) => {
    viewModel.didSelectNewGradeLevel(index);
    viewModel.gradeLevels.selectedIndex = index;
  };

  const didSelectNewSectionNumber = (index: number | undefined) => {
    viewModel.didSelectNewSectionNumber(index);
    viewModel.sectionNumbers.selectedIndex = index;
  };

  const didSelectNewTeacher = (index: number | undefined) => {
    viewModel.didSelectNewTeacher(index);
    viewModel.teachers.selectedIndex = index;
  };

  const didChangeSelectedForSectionId = (sectionId: string, selected: boolean) => {
    viewModel.didChangeSelectedForSectionId(sectionId, selected);
  };

  const hasGradeLevelFilter = viewModel.gradeLevels.selectedIndex != null;
  const hasSectionNumberFilter = viewModel.sectionNumbers.selectedIndex != null;
  const hasTeachersFilters = viewModel.teachers.selectedIndex != null;

  return (
    <Stack sx={sx} className={className} overflow="hidden" spacing={2} py={2}>
      <Stack spacing={2} px={2}>
        <TextField
          variant="filled"
          value={viewModel.searchText}
          onChange={(e) => (viewModel.searchText = e.currentTarget.value)}
          label={strings.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />

        <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
          <Typography variant="body2">{strings.filtersLabel}</Typography>

          {!hasTeachersFilters && (
            <CourseSelectionFilterCell
              includeNoneValue={true}
              title={strings.gradeLevelTitle}
              didSelectNewIndex={didSelectNewGradeLevel}
              viewModel={viewModel.gradeLevels}
            />
          )}

          {!hasTeachersFilters && (
            <CourseSelectionFilterCell
              includeNoneValue={viewModel.showNoneValueForSectionNumberFilter}
              title={strings.sectionNumberTitle}
              didSelectNewIndex={didSelectNewSectionNumber}
              viewModel={viewModel.sectionNumbers}
            />
          )}

          {!hasGradeLevelFilter && !hasSectionNumberFilter && (
            <CourseSelectionFilterCell
              includeNoneValue={false}
              title={strings.teacherTitle}
              didSelectNewIndex={didSelectNewTeacher}
              viewModel={viewModel.teachers}
            />
          )}
        </Stack>
      </Stack>

      {viewModel.searchResultsViewModel != null && (
        <Box overflow="auto" flex={1} display="flex" flexDirection="column">
          <CourseSelectionSectionList
            sx={{ flex: 1 }}
            viewModel={viewModel.searchResultsViewModel}
            isSearchResults
            didChangeSelectedForSectionId={didChangeSelectedForSectionId}
          />
        </Box>
      )}

      {viewModel.searchResultsViewModel == null && (
        <Box overflow="auto" flex={1}>
          <Stack spacing={2}>
            {viewModel.searchResultsViewModel == null && (
              <Typography variant="caption" color="textSecondary" px={2}>
                {strings.filterSubtitle}
              </Typography>
            )}

            <Typography textAlign="center" variant="subtitle2" px={2}>
              {strings.selectedCoursesTitle}
            </Typography>

            <CourseSelectionSectionList
              sx={{ flex: 1 }}
              viewModel={viewModel.selectedCourses}
              didChangeSelectedForSectionId={didChangeSelectedForSectionId}
            />
          </Stack>
        </Box>
      )}
    </Stack>
  );
});
