import { ContentWorkloadLevel } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { action, computed, makeObservable } from 'mobx';
import { AccountService, NavigationService, StudyoSettingsStore } from '../../../services';
import { ContentFilterKeys, ContentFiltersManager } from '../../../utils';
import {
  AppOptionMenuElement,
  AppOptionMenuSectionViewModel,
  AppOptionMenuViewModel,
  OptionMenuSectionViewModel,
  OptionMenuViewModel
} from '../../utils';

export interface MonthlyFiltersOptionsViewModel extends OptionMenuViewModel {
  readonly isFiltered: boolean;
  resetFilters: () => void;
}

export class AppMonthlyFiltersOptionsViewModel
  extends AppOptionMenuViewModel
  implements MonthlyFiltersOptionsViewModel
{
  get sections(): OptionMenuSectionViewModel[] {
    return this.loadSections();
  }

  @computed
  get isFiltered() {
    return this.preferences.monthlyContentFilters.isFiltering;
  }
  private _filterService: ContentFiltersManager | undefined;

  constructor(
    accountService: AccountService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    super(accountService, localizationService, navigationService, onSuccess, onCancel, settingsStore);
    makeObservable(this);
  }

  resetFilters() {
    this.preferences.monthlyContentFilters.resetFilters();
  }

  private loadSections(): OptionMenuSectionViewModel[] {
    const strings = this.localizationService.localizedStrings.studyo.agenda.monthly.filterOptions;
    this._filterService = this.preferences.monthlyContentFilters;

    const publishedOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 0),
      this.onPublishedValueChanged,
      strings.published,
      this._filterService.isFilterEnabled(ContentFilterKeys.Published)
    );

    const notPublishedOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 1),
      this.onNotPublishedValueChanged,
      strings.notPublished,
      this._filterService.isFilterEnabled(ContentFilterKeys.NotPublished)
    );

    const includeSection = new AppOptionMenuSectionViewModel(
      strings.includeTitle,
      [publishedOption, notPublishedOption],
      'multiple-selection'
    );

    const examsOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 0),
      this.onExamsValueChanged,
      strings.exams,
      this._filterService.isFilterEnabled(ContentFilterKeys.Exams)
    );

    const homeworkOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 1),
      this.onHomeworkValueChanged,
      strings.homework,
      this._filterService.isFilterEnabled(ContentFilterKeys.Homework)
    );

    const othersOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 2),
      this.onOthersValueChanged,
      strings.others,
      this._filterService.isFilterEnabled(ContentFilterKeys.OtherTasks)
    );

    const showSection = new AppOptionMenuSectionViewModel(
      strings.showTitle,
      [examsOption, homeworkOption, othersOption],
      'multiple-selection'
    );

    const workloadStrings = this.localizationService.localizedStrings.models.contents.workloadLevelString;
    const minimumWorkload = this._filterService.filterValue(ContentFilterKeys.MinimumWorkload);
    const noLoadOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 0),
      () => this.onMinimumWorkloadLevelSelected('none'),
      workloadStrings('none'),
      minimumWorkload === 'none'
    );

    const regularOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 1),
      () => this.onMinimumWorkloadLevelSelected('regular'),
      workloadStrings('regular'),
      minimumWorkload === 'regular'
    );

    const mediumOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 2),
      () => this.onMinimumWorkloadLevelSelected('medium'),
      workloadStrings('medium'),
      minimumWorkload === 'medium'
    );

    const majorOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 3),
      () => this.onMinimumWorkloadLevelSelected('major'),
      workloadStrings('major'),
      minimumWorkload === 'major'
    );

    const minimumWorkloadSection = new AppOptionMenuSectionViewModel(
      strings.minimumWorkloadTitle,
      [noLoadOption, regularOption, mediumOption, majorOption],
      'single-selection'
    );

    const withAttachmentsOption = new AppOptionMenuElement(
      () => this.didSelectElement(3, 0),
      this.onWithAttachmentsValueChanged,
      strings.withAttachments,
      this._filterService.isFilterEnabled(ContentFilterKeys.WithAttachments)
    );

    const onlySection = new AppOptionMenuSectionViewModel(strings.onlyTitle, [withAttachmentsOption], 'independent');

    return [includeSection, showSection, minimumWorkloadSection, onlySection];
  }

  private onPublishedValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.Published, value);
  };

  private onNotPublishedValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.NotPublished, value);
  };

  private onExamsValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.Exams, value);
  };

  private onHomeworkValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.Homework, value);
  };

  private onOthersValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.OtherTasks, value);
  };

  private onMinimumWorkloadLevelSelected = (workload: ContentWorkloadLevel) => {
    this.updateEnabledFilter(ContentFilterKeys.MinimumWorkload, workload);
  };

  private onWithAttachmentsValueChanged = (value: boolean) => {
    this.updateEnabledFilter(ContentFilterKeys.WithAttachments, value);
  };

  @action
  private updateEnabledFilter(key: string, value: boolean | ContentWorkloadLevel) {
    if (this._filterService != null) {
      this._filterService.setFilterEnabled(key, value);
      this.preferences.saveMonthlyContentFilters();
    }
  }
}
