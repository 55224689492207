import { computed, makeObservable, observable, runInAction } from 'mobx';
import { SubscriptionsManager } from '../../services';

export interface ParentAccessRedirectViewModel {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly errorMessage: string;
  readonly checkoutUrl: string;
}

export class AppParentAccessRedirectViewModel implements ParentAccessRedirectViewModel {
  @observable private _isLoading = true;
  @observable private _error: string | undefined;
  @observable private _checkoutUrl: string | undefined;

  constructor(
    private readonly _subscriptionsManager: SubscriptionsManager,
    configId: string,
    accountId: string
  ) {
    makeObservable(this);
    // Start the async check.
    void this.loadCheckoutUrl(configId, accountId);
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get hasError(): boolean {
    return this._error != null;
  }

  @computed
  get errorMessage(): string {
    return this._error ?? '';
  }

  @computed
  get checkoutUrl(): string {
    return this._checkoutUrl ?? '';
  }

  private async loadCheckoutUrl(configId: string, accountId: string): Promise<void> {
    try {
      const url = await this._subscriptionsManager.getCheckoutUrl(configId, accountId, 'parent-access');
      runInAction(() => (this._checkoutUrl = url));
    } catch (error) {
      runInAction(() => (this._error = (error as Error).message));
    } finally {
      runInAction(() => (this._isLoading = false));
    }
  }
}
