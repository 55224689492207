import { SchoolDay, SpecialDayOccurrence } from '@shared/models/calendar';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { ContentPasteboardStore, NavigationService, StudyoAccountSettings } from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import {
  AppDisplayableContentBoxViewModel,
  AppDisplayableContentViewModel,
  DisplayableContentBoxViewModel,
  DisplayableContentViewModel
} from '../../contents';

export interface MonthDayViewModel {
  schoolDay: SchoolDay;
  specialDays: SpecialDayOccurrence[];
  contents: DisplayableContentBoxViewModel;
}

export class AppMonthDayViewModel implements MonthDayViewModel {
  constructor(
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _schoolDay: SchoolDay,
    private readonly _data: AccountData,
    private readonly _preferences: StudyoAccountSettings
  ) {
    makeObservable(this);
  }

  @computed
  private get schoolDayContents(): DisplayableContentViewModel[] {
    const showTasks = this._preferences.monthlyShowTasks;
    const showNotes = this._preferences.monthlyShowNotes;

    const sectionsToDisplay = this._preferences.monthlySectionFilters;
    const schoolDayContents = this._data.visibleContents.filter((cd) => cd.dueDay.isSame(this._schoolDay.day));
    const tasks = showTasks
      ? schoolDayContents.filter(
          (cd) =>
            cd.kind === 'task' &&
            (cd.sectionId.length === 0 ||
              sectionsToDisplay.length === 0 ||
              sectionsToDisplay.indexOf(cd.sectionId) >= 0)
        )
      : [];
    const filteredTasks = this._preferences.monthlyContentFilters.applyFiltersToContents(
      tasks,
      this._localizationService
    );
    const notes = showNotes ? schoolDayContents.filter((cd) => cd.kind === 'note' && cd.notes.length > 0) : [];

    const contents = filteredTasks.concat(notes);

    return contents.map(
      (c) =>
        new AppDisplayableContentViewModel(
          this._localizationService,
          this._data,
          c.sectionId.length > 0 ? this._data.sectionsById.get(c.sectionId) : undefined,
          undefined,
          c,
          false
        )
    );
  }

  @computed
  get schoolDay() {
    return this._schoolDay;
  }

  @computed
  get specialDays(): SpecialDayOccurrence[] {
    return this._schoolDay.specialDays;
  }

  @computed
  get contents(): DisplayableContentBoxViewModel {
    return new AppDisplayableContentBoxViewModel(
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this._contentPasteboardStore,
      this._data,
      this._schoolDay.day,
      undefined,
      () => undefined,
      () => this.schoolDayContents
    );
  }
}
