import { ContentDefinitionUtils } from '@shared/components/utils';
import { ContentDefinitionModel } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import _ from 'lodash';
import {
  AppSingleChoiceFilter,
  ContentFilter,
  ContentFilterKeys,
  ExamsFilter,
  HomeworkFilter,
  MinimumWorkloadFilter,
  NotCompletedFilter,
  NotPublishedFilter,
  OtherTasksFilter,
  PublishedFilter,
  WithAttachmentsFilter
} from './ContentsFilters';

export interface ContentFiltersManager {
  jsonValue: Record<string, boolean | string>;
  isFiltering: boolean;

  applyFiltersToContents: (
    contents: ContentDefinitionModel[],
    localizationService: LocalizationService,
    ignoreStateFilters?: boolean,
    searchFilter?: string
  ) => ContentDefinitionModel[];
  filterValue: (key: string) => boolean | string;
  isFilterEnabled: (key: string) => boolean;
  resetFilters: () => void;
  setFilterEnabled: (key: string, enabled: boolean | string) => void;
}

export class AppContentFiltersManager implements ContentFiltersManager {
  private readonly _filters: Record<string, ContentFilter>;

  constructor(init: Record<string, boolean | string> = {}) {
    const newFilter = {};

    const publishedInit = init[ContentFilterKeys.Published];
    newFilter[ContentFilterKeys.Published] = new PublishedFilter(
      typeof publishedInit === 'boolean' ? publishedInit : undefined
    );

    const notPublishedInit = init[ContentFilterKeys.NotPublished];
    newFilter[ContentFilterKeys.NotPublished] = new NotPublishedFilter(
      typeof notPublishedInit === 'boolean' ? notPublishedInit : undefined
    );

    const examsInit = init[ContentFilterKeys.Exams];
    newFilter[ContentFilterKeys.Exams] = new ExamsFilter(typeof examsInit === 'boolean' ? examsInit : undefined);

    const homeworkInit = init[ContentFilterKeys.Homework];
    newFilter[ContentFilterKeys.Homework] = new HomeworkFilter(
      typeof homeworkInit === 'boolean' ? homeworkInit : undefined
    );

    const otherInit = init[ContentFilterKeys.OtherTasks];
    newFilter[ContentFilterKeys.OtherTasks] = new OtherTasksFilter(
      typeof otherInit === 'boolean' ? otherInit : undefined
    );

    const workloadInit = init[ContentFilterKeys.MinimumWorkload];
    newFilter[ContentFilterKeys.MinimumWorkload] = new MinimumWorkloadFilter(
      typeof workloadInit === 'string' ? workloadInit : undefined
    );

    const attachmentsInit = init[ContentFilterKeys.WithAttachments];
    newFilter[ContentFilterKeys.WithAttachments] = new WithAttachmentsFilter(
      typeof attachmentsInit === 'boolean' ? attachmentsInit : undefined
    );

    const notCompletedInit = init[ContentFilterKeys.NotCompleted];
    newFilter[ContentFilterKeys.NotCompleted] = new NotCompletedFilter(
      typeof notCompletedInit === 'boolean' ? notCompletedInit : undefined
    );

    this._filters = newFilter;
  }

  get isFiltering() {
    return Object.values(this._filters).some((filter) => filter.isFiltering);
  }

  applyFiltersToContents(
    contents: ContentDefinitionModel[],
    localizationService: LocalizationService,
    ignoreStateFilters?: boolean,
    searchFilter?: string
  ) {
    const filteredContents = _.filter(contents, (content) => {
      const withAttachmentsFilter = this._filters[ContentFilterKeys.WithAttachments];

      if (withAttachmentsFilter.enabled && !withAttachmentsFilter.filterContent(content)) {
        return false;
      }

      if (ignoreStateFilters !== true) {
        const notCompletedFilter = this._filters[ContentFilterKeys.NotCompleted];

        if (notCompletedFilter.enabled && !notCompletedFilter.filterContent(content)) {
          return false;
        }
      }

      const published = this.filterContent(ContentFilterKeys.Published, content);
      const notPublished = this.filterContent(ContentFilterKeys.NotPublished, content);

      const exams = this.filterContent(ContentFilterKeys.Exams, content);
      const homework = this.filterContent(ContentFilterKeys.Homework, content);
      const otherTasks = this.filterContent(ContentFilterKeys.OtherTasks, content);

      const minimumWorkload = this.filterContent(ContentFilterKeys.MinimumWorkload, content);

      return (published || notPublished) && (exams || homework || otherTasks) && minimumWorkload;
    });

    if (searchFilter == null || searchFilter.length === 0) {
      return filteredContents;
    }

    const resolvedSearchFilter = searchFilter.toLocaleLowerCase();
    return filteredContents.filter((c) => {
      if (c.notes.toLocaleLowerCase().includes(resolvedSearchFilter)) {
        return true;
      }

      const title = ContentDefinitionUtils.getDisplayTitleForContent(c, localizationService.localizedStrings);
      return title.toLocaleLowerCase().includes(resolvedSearchFilter);
    });
  }

  resetFilters() {
    Object.values(this._filters).forEach((filter) => filter.resetFilter());
  }

  get jsonValue(): Record<string, boolean | string> {
    const json: Record<string, boolean | string> = {};

    Object.keys(this._filters).forEach((filterKey) => {
      const filter = this.get(filterKey);

      if (filter instanceof AppSingleChoiceFilter) {
        json[filterKey] = filter.value;
      } else {
        json[filterKey] = filter.enabled;
      }
    });

    return json;
  }

  filterValue(key: string) {
    const filter = this.get(key);

    if (filter instanceof AppSingleChoiceFilter) {
      return filter.value;
    } else {
      return filter.enabled;
    }
  }

  isFilterEnabled(key: string) {
    return this.get(key).enabled;
  }

  setFilterEnabled(key: string, value: boolean | string) {
    const filter = this.get(key);

    if (typeof value === 'boolean') {
      filter.enabled = value;
    } else {
      if (filter instanceof AppSingleChoiceFilter) {
        filter.value = value;
      }
    }
  }

  private get(key: string) {
    const filter = this._filters[key];

    if (filter == null) {
      throw new Error('Unknown filter');
    }

    return filter;
  }

  private filterContent(key: string, content: ContentDefinitionModel) {
    const filter = this.get(key);

    if (filter.enabled) {
      return filter.filterContent(content);
    }

    return false;
  }
}
