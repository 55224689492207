import { Button, SxProps } from '@mui/material';
import { observer } from 'mobx-react';

export interface CalendarHeaderFilterButtonProps {
  sx?: SxProps;
  className?: string;
  text: string;
  onPress: () => void;
  hasFilters: boolean;
}

export const CalendarHeaderFilterButton = observer((props: CalendarHeaderFilterButtonProps) => {
  const { text, onPress, hasFilters, sx, className } = props;

  return (
    <Button
      onClick={() => onPress()}
      size="small"
      variant={hasFilters ? 'contained' : 'text'}
      sx={sx}
      className={className}
    >
      {text}
    </Button>
  );
});
