import { SchoolDayPeriodUtils, SectionUtils } from '@shared/components/utils';
import { MoveCourseOccurrencesParamsModel } from '@shared/models/calendar';
import { Color, Day, MoveCourseOccurrencesDirection } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader, OnDestroy, OnInit } from '@shared/services';
import { AccountData, CalendarStore } from '@shared/services/stores';
import { computed, makeObservable, observable } from 'mobx';
import { AccountAutoSyncService, AccountService, NavigationService } from '../../../../services';
import { AppSchoolDayPickerViewModel, SchoolDayPickerViewModel } from '../../../utils';

export const AllMoveCourseOccurrencesDirections: MoveCourseOccurrencesDirection[] = ['left', 'right'];
export type ContentMoveDateLimitType = 'end-school-year' | 'specific-date';

export interface PlannerPeriodContentMoveViewModel extends OnInit, OnDestroy {
  data: DataLoader;
  sectionColor: Color;
  contentMoveType: MoveCourseOccurrencesDirection;
  readonly canSkip: boolean;
  isSkipping: boolean;
  includeTitles: boolean;
  includeTasks: boolean;
  includeNotes: boolean;
  dateLimitType: ContentMoveDateLimitType;
  dateLimit: Day;
  readonly schoolDaysViewModel: SchoolDayPickerViewModel;

  dismiss: (dismissAll: boolean) => Promise<void>;
  save: () => Promise<void>;
}

export class AppPlannerPeriodContentMoveViewModel implements PlannerPeriodContentMoveViewModel {
  readonly data: AccountData;

  @observable private _contentMoveType: MoveCourseOccurrencesDirection = 'right';
  @observable private _isSkipping = true;
  @observable private _includeTitles = true;
  @observable private _includeTasks = true;
  @observable private _includeNotes = true;
  @observable private _dateLimitType: ContentMoveDateLimitType = 'end-school-year';
  @observable private _dateLimit: Day;

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _sectionId: string,
    private readonly _periodTag: string,
    private readonly _day: Day,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = _accountService.displayedAccountData;
    this._dateLimit = _day;
  }

  @computed
  private get section() {
    return this.data.sectionsById.get(this._sectionId);
  }

  @computed
  private get courseOccurrence() {
    const schoolDay = this.data.schoolDaysByDay.get(this._day.asString);
    if (schoolDay == null) {
      throw new Error(`No school day found for specified day. [${this._day.asString}]`);
    }

    const period = schoolDay.periods.find((p) => p.tag === this._periodTag);
    if (period == null) {
      throw new Error(`No period found for specified period tag. [${this._periodTag}]`);
    }

    const periodCourseOccurrences = SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this.data.account);
    const occurrence = periodCourseOccurrences.find((co) => co.sectionId === this._sectionId);
    if (occurrence == null) {
      throw new Error(`No occurrence found with specified section id. [${this._sectionId}]`);
    }

    return occurrence;
  }

  @computed
  get sectionColor() {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined)!;
  }

  @computed
  get schoolDaysViewModel() {
    const vm = new AppSchoolDayPickerViewModel(this._accountService, this._localizationService);
    vm.setSelectedDay(this._dateLimit);
    vm.setCurrentDay(this._dateLimit);
    vm.setMinimumAndMaximumDays(this._day, undefined);
    return vm;
  }

  @computed
  get contentMoveType() {
    return this._contentMoveType;
  }

  set contentMoveType(value: MoveCourseOccurrencesDirection) {
    this._contentMoveType = value;

    if (value === 'right' && this._isSkipping) {
      this._includeTitles = true;
    }
  }

  @computed
  get canSkip(): boolean {
    return !this.courseOccurrence.skipped && this.contentMoveType === 'right';
  }

  @computed
  get isSkipping(): boolean {
    return this._isSkipping;
  }

  set isSkipping(value: boolean) {
    this._isSkipping = value;

    if (value) {
      this._includeTitles = true;
    }
  }

  @computed
  get includeTitles() {
    return this._includeTitles;
  }

  set includeTitles(value: boolean) {
    this._includeTitles = value;
  }

  @computed
  get includeTasks() {
    return this._includeTasks;
  }

  set includeTasks(value: boolean) {
    this._includeTasks = value;
  }

  @computed
  get includeNotes() {
    return this._includeNotes;
  }

  set includeNotes(value: boolean) {
    this._includeNotes = value;
  }

  @computed
  get dateLimitType() {
    return this._dateLimitType;
  }

  set dateLimitType(value: ContentMoveDateLimitType) {
    this._dateLimitType = value;
  }

  @computed
  get dateLimit() {
    return this._dateLimit;
  }

  set dateLimit(value: Day) {
    this._dateLimit = value;
    this.schoolDaysViewModel.setSelectedDay(value);
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  async dismiss(dismissAll: boolean) {
    if (dismissAll) {
      this._onSuccess();
      return this._navigationService.closeAllModals();
    } else {
      this._onCancel();
    }
  }

  async save() {
    const untilDay = this.dateLimitType === 'specific-date' ? this.dateLimit : undefined;

    // We use a "move right skip" if "Move right" is selected and either
    // the occurrence is already skipped or isSkipping was set.
    const moveDirection: MoveCourseOccurrencesDirection =
      this.contentMoveType === 'right' && (this.courseOccurrence.skipped || this.isSkipping)
        ? 'right-skip'
        : this.contentMoveType;

    const params: MoveCourseOccurrencesParamsModel = {
      configId: this.data.configId,
      sectionId: this._sectionId,
      moveDirection: moveDirection,
      moveNotes: this._includeNotes,
      moveTasks: this._includeTasks,
      moveTitles: this._includeTitles,
      startOrdinal: this.courseOccurrence.ordinal,
      untilDay: untilDay
    };

    await this._calendarStore.moveCourseOccurrences(params);
    await this.data.refresh();
  }
}
