import { DateUtils, SchoolDayPeriodUtils } from '@shared/components/utils';
import { CourseOccurrence, SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { Dictionary, flatten, groupBy, max } from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import {
  ContentPasteboardStore,
  NavigationService,
  PlannerPeriodHeightKind,
  StudyoAccountSettings,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppPlannerSchoolDayViewModel, PlannerSchoolDayViewModel } from './PlannerSchoolDayViewModel';
import { AppPlannerSectionHeaderViewModel, PlannerSectionHeaderViewModel } from './PlannerSectionHeaderViewModel';

export interface PlannerGridViewModel {
  readonly sections: PlannerSectionHeaderViewModel[];
  readonly schoolDays: PlannerSchoolDayViewModel[];
  readonly currentDayIndex: number;
  readonly periodHeightKind: PlannerPeriodHeightKind;

  goToDayAtIndex: (index: number) => void;
}

export class AppPlannerGridViewModel implements PlannerGridViewModel {
  constructor(
    private readonly _contentPasteboard: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _uiSettingsStore: UISettingsStore,
    private _data: AccountData
  ) {
    makeObservable(this);
  }

  @computed
  private get taughtSections(): SectionModel[] {
    return this._data.userSections.filter((s) => s.teacherIds.indexOf(this._data.accountId) >= 0);
  }

  @computed
  private get sectionRows(): { section: SectionModel; count: number; noOccurrence: boolean }[] {
    const rowsBySection: Dictionary<number> = {};
    const periodsBySectionBySchoolDay: Dictionary<CourseOccurrence[]>[] = [];

    this._data.schoolDays.forEach((sd) => {
      const periodsOccurrences = sd.periods.map((period) =>
        SchoolDayPeriodUtils.getDisplayedCourseOcccurrences(period, this._data.account)
      );
      const allOccurrences = flatten(periodsOccurrences);
      periodsBySectionBySchoolDay.push(groupBy(allOccurrences, (occurrence) => occurrence.sectionId));
    });

    const filteredSectionIds = this._preferences.plannerSectionFilters;

    const sections =
      filteredSectionIds.length > 0
        ? this.taughtSections.filter((section) => filteredSectionIds.indexOf(section.id) !== -1)
        : this.taughtSections;

    sections.forEach((section) => {
      const occurrencesCounts = periodsBySectionBySchoolDay.map((d) =>
        d[section.id] != null ? d[section.id].length : 0
      );
      rowsBySection[section.id] = occurrencesCounts.reduce((previous, current) => max([previous, current])!, 0);
    });

    const sectionsWithCount = sections.map((section) => ({
      section,
      count: Math.max(rowsBySection[section.id], 1),
      noOccurrence: rowsBySection[section.id] == 0
    }));

    const locale = this._localizationService.currentLocale;
    return sectionsWithCount.sort((s1, s2) =>
      // Sections without occurrences always after
      s1.noOccurrence != s2.noOccurrence
        ? s1.noOccurrence
          ? 1
          : -1
        : s1.section.title.localeCompare(s2.section.title, locale, { sensitivity: 'base' }) ||
          s1.section.sectionNumber.localeCompare(s2.section.sectionNumber, locale, { sensitivity: 'base' })
    );
  }

  @computed
  private get displayedSchoolDays(): SchoolDay[] {
    if (this._preferences.plannerDisplayWeekends) {
      return this._data.schoolDays;
    } else {
      return this._data.schoolDays.filter((sd) => !DateUtils.isWeekend(sd.day));
    }
  }

  @computed
  get periodHeightKind(): PlannerPeriodHeightKind {
    return this._preferences.plannerPeriodHeight;
  }

  @computed
  get currentDayIndex() {
    const currentDay = this._uiSettingsStore.plannerCurrentDay;
    return currentDay != null ? this.findIndexOfDay(currentDay) : 0;
  }

  @computed
  get schoolDays(): PlannerSchoolDayViewModel[] {
    return this.displayedSchoolDays.map(
      (sd) =>
        new AppPlannerSchoolDayViewModel(
          this._contentPasteboard,
          this._localizationService,
          this._navigationService,
          this._analyticsService,
          this._preferences,
          sd,
          this.sectionRows,
          this._data
        )
    );
  }

  @computed
  get sections(): PlannerSectionHeaderViewModel[] {
    const rows: PlannerSectionHeaderViewModel[] = [];

    this.sectionRows.forEach((section) => {
      for (let i = 0; i < section.count; i++) {
        rows.push(new AppPlannerSectionHeaderViewModel(this._navigationService, section.section, this._data));
      }
    });

    return rows;
  }

  @action
  goToDayAtIndex(index: number) {
    if (this._data.schoolDays.length > 0 && index >= 0 && index < this._data.schoolDays.length) {
      this._uiSettingsStore.plannerCurrentDay = this._data.schoolDays[index].day;
    }
  }

  private findIndexOfDay(day: Day) {
    let dayIndex = -1;
    const schoolDays = this.displayedSchoolDays;

    for (let i = 0; i < schoolDays.length; i++) {
      const schoolDay = schoolDays[i];
      const compareDate = schoolDay.day.compare(day);

      if (compareDate === 0) {
        // Today is in the displayed school days, we scroll to its index.
        dayIndex = i;
        break;
      } else if (compareDate > 0) {
        // We haven't found today in the displayed school days, but we have passed it.
        // This means today is a weekend day and they are hidden. In this case, we scroll the
        // the day before.
        //
        // If we are evaluating the first school day, it means today is before the start of the school days.
        // In this case, we scroll to the first day.
        dayIndex = i > 0 ? i - 1 : 0;
        break;
      }
    }

    // All school days displayed are before today. In this case, we scroll to the last day of the year.
    if (dayIndex == -1) {
      dayIndex = schoolDays.length - 1;
    }

    return dayIndex;
  }
}
