import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoSettingsStore } from '@studyo/services';
import { computed, makeObservable } from 'mobx';
import {
  AppOptionMenuElement,
  AppOptionMenuSectionViewModel,
  AppOptionMenuViewModel,
  OptionMenuSectionViewModel,
  OptionMenuViewModel
} from '../../utils';

export type DailyOptionMenuViewModel = OptionMenuViewModel;

export class AppDailyOptionMenuViewModel extends AppOptionMenuViewModel implements DailyOptionMenuViewModel {
  constructor(
    accountService: AccountService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    super(accountService, localizationService, navigationService, onSuccess, onCancel, settingsStore);
    makeObservable(this);
  }

  @computed
  get sections(): OptionMenuSectionViewModel[] {
    if (this.preferences.hasData) {
      return this.loadSections();
    } else {
      return [];
    }
  }

  private loadSections(): OptionMenuSectionViewModel[] {
    const strings = this.localizationService.localizedStrings.studyo.agenda.daily.optionMenu;
    const periodHeight = this.preferences.dailyPeriodHeight;
    const contentDisplayKind = this.preferences.dailyContentDisplayKind;
    const contentPresentationKind = this.preferences.dailyContentPresentationKind;

    const smallHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 0),
      this.onSmallHeightPress,
      strings.smallPeriodHeightOptionTitle,
      periodHeight === 'small'
    );
    const mediumHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 1),
      this.onMediumHeightPress,
      strings.mediumPeriodHeightOptionTitle,
      periodHeight === 'medium'
    );
    const largeHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 2),
      this.onLargeHeightPress,
      strings.largePeriodHeightOptionTitle,
      periodHeight === 'large'
    );
    const veryLargeHeightOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 3),
      this.onVeryLargeHeightPress,
      strings.veryLargePeriodHeightOptionTitle,
      periodHeight === 'very-large'
    );
    const periodHeightSection = new AppOptionMenuSectionViewModel(
      strings.periodHeightSectionTitle,
      [smallHeightOption, mediumHeightOption, largeHeightOption, veryLargeHeightOption],
      'single-selection'
    );

    const toggleTasksOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 0),
      this.onTasksPress,
      strings.taskDisplaySectionTitle,
      contentDisplayKind === 'tasks' || contentDisplayKind === 'both'
    );

    const toggleNotesOption = new AppOptionMenuElement(
      () => this.didSelectElement(1, 1),
      this.onNotesPress,
      strings.noteDisplaySectionTitle,
      contentDisplayKind === 'notes' || contentDisplayKind === 'both'
    );

    const displayedContentTypesSection = new AppOptionMenuSectionViewModel(
      strings.contentDisplaySectionTitle,
      [toggleTasksOption, toggleNotesOption],
      'multiple-selection'
    );

    const toggleIconTitleOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 0),
      this.onIconTitlePress,
      strings.iconTitlePresentationSectionTitle,
      contentPresentationKind === 'icon-title'
    );

    const toggleDetailedOption = new AppOptionMenuElement(
      () => this.didSelectElement(2, 1),
      this.onDetailedPress,
      strings.detailedPresentationSectionTitle,
      contentPresentationKind === 'detailed'
    );

    const displayedContentRepresentationSection = new AppOptionMenuSectionViewModel(
      strings.contentPresentationSectionTitle,
      [toggleIconTitleOption, toggleDetailedOption],
      'single-selection'
    );

    const displayAnnouncedDayOption = new AppOptionMenuElement(
      () => this.didSelectElement(3, 0),
      this.onDisplayAnnouncedDayPress,
      strings.displayAnnouncedDayTitle,
      this.preferences.dailyShowAssignmentDay
    );

    const displayedElementsSection = new AppOptionMenuSectionViewModel(
      strings.displayedElementsTitle,
      [displayAnnouncedDayOption],
      'independent'
    );

    return [
      periodHeightSection,
      displayedContentTypesSection,
      displayedContentRepresentationSection,
      displayedElementsSection
    ];
  }

  private onSmallHeightPress = () => {
    this.preferences.dailyPeriodHeight = 'small';
  };

  private onMediumHeightPress = () => {
    this.preferences.dailyPeriodHeight = 'medium';
  };

  private onLargeHeightPress = () => {
    this.preferences.dailyPeriodHeight = 'large';
  };

  private onVeryLargeHeightPress = () => {
    this.preferences.dailyPeriodHeight = 'very-large';
  };

  private onTasksPress = () => {
    if (this.preferences.dailyContentDisplayKind === 'both') {
      this.preferences.dailyContentDisplayKind = 'notes';
    } else {
      this.preferences.dailyContentDisplayKind = 'both';
    }
  };

  private onNotesPress = () => {
    if (this.preferences.dailyContentDisplayKind === 'both') {
      this.preferences.dailyContentDisplayKind = 'tasks';
    } else {
      this.preferences.dailyContentDisplayKind = 'both';
    }
  };

  private onIconTitlePress = () => {
    this.preferences.dailyContentPresentationKind = 'icon-title';
  };

  private onDetailedPress = () => {
    this.preferences.dailyContentPresentationKind = 'detailed';
  };

  private onDisplayAnnouncedDayPress = () => {
    this.preferences.dailyShowAssignmentDay = !this.preferences.dailyShowAssignmentDay;
  };
}
