import { AnalyticsEvent, AnalyticsPage } from '@shared/services/analytics';
import { IntercomEventProperties, WebIntercomAnalyticsService } from '@shared/web/services';
import { StudyoAnalyticsEventCategory, StudyoAnalyticsPage, StudyoAnalyticsService } from './analytics';

interface StudyoEventProperties extends IntercomEventProperties {
  activeScreen?: string;
}

export class StudyoWebIntercomAnalyticsService
  extends WebIntercomAnalyticsService<
    AnalyticsPage<StudyoAnalyticsPage>,
    AnalyticsEvent<StudyoAnalyticsEventCategory>,
    StudyoEventProperties
  >
  implements StudyoAnalyticsService
{
  private _activePage: AnalyticsPage<StudyoAnalyticsPage> | undefined;

  trackPage(page: AnalyticsPage<StudyoAnalyticsPage>) {
    this._activePage = page;
    super.trackPage(page);
  }

  protected buildEventProperties(event: AnalyticsEvent<StudyoAnalyticsEventCategory>): StudyoEventProperties {
    return {
      eventCategory: event.action.category,
      eventLabel: event.label,
      activeScreen: this._activePage?.name ?? 'n/a'
    };
  }
}
