export const DayAndWeekConstants = {
  spaceBetweenDays: 8,
  timesColumnWidth: 60,
  timesColumnCompactWidth: 20,
  schoolDayHeaderHeight: 68,
  // Hour to which align the scroll when loading the view
  startHour: 7,

  itemSpacing: 4,

  detailedIconSize: 20,
  detailedMinItemHeight: 25,
  detailedMinItemWidth: 100,
  detailedMaxItemWidth: 300,

  iconTitleIconSize: 26,
  iconTitleMinItemHeight: 45,
  iconTitleMinItemWidth: 50,
  iconTitleMaxItemWidth: 150
};
