import { Chip, Menu, MenuItem, SxProps } from '@mui/material';
import { CourseSelectionFilterCellViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface CourseSelectionFilterCellProps {
  sx?: SxProps;
  className?: string;
  includeNoneValue: boolean;
  title: string;
  viewModel: CourseSelectionFilterCellViewModel;
  didSelectNewIndex: (newIndex: number | undefined) => void;
}

export const CourseSelectionFilterCell = observer((props: CourseSelectionFilterCellProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, includeNoneValue, title, viewModel, didSelectNewIndex } = props;
  const [menuAnchorRef, setMenuAnchorRef] = useState<HTMLDivElement | null>(null);
  const selectedIndex =
    viewModel.selectedIndex != null ? viewModel.selectedIndex + (includeNoneValue ? 1 : 0) : includeNoneValue ? 0 : -1;

  const values = includeNoneValue
    ? [localizationService.localizedStrings.studyo.agenda.setting.courseSelection.none, ...viewModel.values]
    : viewModel.values;

  const didSelectElement = (index: number) => {
    setMenuAnchorRef(null);

    if ((index === 0 && includeNoneValue) || index < 0) {
      viewModel.selectedIndex = undefined;
      didSelectNewIndex(undefined);
    } else {
      const resolvedIndex = index - (includeNoneValue ? 1 : 0);
      viewModel.selectedIndex = resolvedIndex;
      didSelectNewIndex(resolvedIndex);
    }
  };

  const label = viewModel.selectedIndex != null ? `${title}: ${values[selectedIndex]}` : title;

  return (
    <>
      <Chip
        sx={sx}
        className={className}
        onClick={(e) => setMenuAnchorRef(e.currentTarget)}
        label={label}
        color={viewModel.selectedIndex != null ? 'primary' : undefined}
        onDelete={viewModel.selectedIndex != null ? () => didSelectElement(-1) : undefined}
      />

      <Menu
        open={menuAnchorRef != null}
        anchorEl={menuAnchorRef}
        onClose={() => setMenuAnchorRef(null)}
        slotProps={{ paper: { sx: { minWidth: 300 } } }}
      >
        {values.map((value, i) => (
          <MenuItem selected={i === selectedIndex} key={value} onClick={() => didSelectElement(i)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
