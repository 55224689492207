import { EditableAccount, SectionModel } from '@shared/models/config';
import { AccountData } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  AppCourseSelectionSectionListItemViewModel,
  CourseSelectionSectionListItemViewModel
} from './CourseSelectionSectionListItemViewModel';

export interface CourseSelectionSectionListViewModel {
  readonly courseItems: CourseSelectionSectionListItemViewModel[];
}

export class AppCourseSelectionSectionListViewModel implements CourseSelectionSectionListViewModel {
  constructor(
    private readonly _data: AccountData,
    private readonly _account: EditableAccount,
    private readonly _sections: SectionModel[]
  ) {
    makeObservable(this);
  }

  @computed
  get courseItems() {
    return _.map(
      this._sections,
      (section) => new AppCourseSelectionSectionListItemViewModel(this._data, this._account, section.id)
    );
  }
}
