export interface OccurrencePeriodPriorityObject {
  readonly cycleDay: number;
  readonly sectionId: string | undefined;
  readonly periodTag: string;
  readonly isSkipped: boolean;
  readonly asJson: Record<string, unknown>;
}

export function occurrencePeriodPriorityObjectsAreEqual(
  first: OccurrencePeriodPriorityObject,
  second: OccurrencePeriodPriorityObject
): boolean {
  return (
    first.cycleDay === second.cycleDay && first.periodTag === second.periodTag && first.sectionId === second.sectionId
  );
}
