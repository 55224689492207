export interface DisplayableContentDragData {
  contentId: string;
  sectionId: string;
  isDraggingAssignment: boolean;
}

export function isDisplayableContentDragData(value: unknown): value is DisplayableContentDragData {
  const data = value as DisplayableContentDragData;
  return data.sectionId != null && data.isDraggingAssignment != null && data.contentId != null;
}
