import {
  ContentDefinition_PublishTarget as PBPublishTarget,
  ContentDefinition_PublishTargetKind as PBPublishTargetKind
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import { PublishStatus, PublishTargetKind } from '../types';
import { publishStatusFromProtobuf, publishTargetKindFromProtobuf } from '../types/EnumConversion';

export interface ContentPublishTargetModel extends SerializableModel<PBPublishTarget> {
  readonly isPrivate: boolean;
  readonly publishedAt: Date | undefined;
  readonly kind: PublishTargetKind;
  readonly targetAccountIds: string[];
  readonly status: PublishStatus;
  readonly lastPublishingAt: Date | undefined;
  readonly lastPublishedAt: Date | undefined;
  readonly lastPublishTraceId: string;
}

export class ContentPublishTarget extends BaseModel<PBPublishTarget> implements ContentPublishTargetModel {
  static createNew() {
    const publishTarget = new PBPublishTarget();
    publishTarget.isPrivate = false;
    publishTarget.publishedAt = undefined;
    publishTarget.targetAccountIds = [];
    publishTarget.kind = PBPublishTargetKind.ACCOUNTS;

    return new ContentPublishTarget(publishTarget);
  }

  constructor(pb: PBPublishTarget) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get isPrivate() {
    return this._pb.isPrivate;
  }

  @LazyGetter()
  get publishedAt(): Date | undefined {
    return this._pb.publishedAt?.toDate();
  }

  get kind(): PublishTargetKind {
    return publishTargetKindFromProtobuf(this._pb.kind);
  }

  get targetAccountIds(): string[] {
    return this._pb.targetAccountIds;
  }

  get status(): PublishStatus {
    return publishStatusFromProtobuf(this._pb.status);
  }

  get lastPublishingAt(): Date | undefined {
    return this._pb.lastPublishingAt?.toDate();
  }

  get lastPublishedAt(): Date | undefined {
    return this._pb.lastPublishedAt?.toDate();
  }

  get lastPublishTraceId(): string {
    return this._pb.lastPublishTraceId;
  }
}
