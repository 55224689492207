import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';

interface SortableItem {
  id: UniqueIdentifier;
}

export interface ReorderableListProps<ItemT extends SortableItem> {
  items: ItemT[];
  renderItem: (item: ItemT, index: number) => ReactNode;
  onOrderChanged?: (oldIndex: number, newIndex: number) => void;
  showIndexBetweenItems?: boolean;
}

export const ReorderableList = observer(
  <ItemT extends SortableItem>({ items, renderItem, onOrderChanged }: ReorderableListProps<ItemT>) => {
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    function handleDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      if (onOrderChanged != null && over != null && active.id !== over.id) {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);
        onOrderChanged(oldIndex, newIndex);
      }
    }

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always
          }
        }}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item, index) => (
            <Fragment key={item.id}>{renderItem(item, index)}</Fragment>
          ))}
        </SortableContext>
      </DndContext>
    );
  }
);
