import { SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { DialogResult } from '@shared/services';
import {
  DialogActionButtons,
  DialogButton,
  OptionsDialog,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialogWithParam
} from '@studyo/components';
import { OptionsDialogElement } from '@studyo/models';
import { ContentDistributeKind } from '@studyo/viewmodels';
import { ContentDistributeView } from '@studyo/views';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

export interface ContentDistributeScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  contentId: string;
}

export const ContentDistributeScreen = observer((props: ContentDistributeScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, contentId, onSuccess, onCancel } = props;

  const viewModel = useMemo(
    () => viewModelFactory.createContentDistribute(contentId, onSuccess!, onCancel!),
    [contentId]
  );

  const theme = useTheme();
  const headerColor = viewModel.color && SectionColors.get(viewModel.color);
  const strings = localizationService.localizedStrings.studyo.contents.contentDistribute;

  const [distributePromise, startDistribute, resetDistribute, retryDistribute] = useSaveDialogWithParam<
    ContentDistributeKind | undefined
  >(undefined, (k) => viewModel.distribute(k!));
  const [displayDistributeToOptionsDialog, setDisplayDistributeToOptionsDialog] = useState(false);

  const distributeContentButtonPressed = () => {
    showOptionsDialog();
  };

  const showOptionsDialog = () => {
    setDisplayDistributeToOptionsDialog(true);
  };

  const hideOptionsDialog = () => {
    setDisplayDistributeToOptionsDialog(false);
  };

  const distributeSamePeriod = () => {
    hideOptionsDialog();
    startDistribute('same-period');
  };

  const distributeSameDay = () => {
    hideOptionsDialog();
    startDistribute('same-day');
  };

  const distributeSameOccurrence = () => {
    hideOptionsDialog();
    startDistribute('same-occurrence');
  };

  const distributeProcessDialogClose = (success: boolean) => {
    resetDistribute();
    if (success) {
      viewModel.dismiss();
    }
  };

  const distributeToOptions = useMemo<OptionsDialogElement[]>(() => {
    const options: OptionsDialogElement[] = [
      {
        selectionHandler: () => distributeSamePeriod(),
        title: strings.distributeSamePeriodOptionTitle,
        type: 'normal'
      },
      {
        selectionHandler: () => distributeSameDay(),
        title: strings.distributeSameDayOptionTitle,
        type: 'normal'
      }
    ];

    if (viewModel.canDistributeToOccurrence) {
      options.push({
        selectionHandler: () => distributeSameOccurrence(),
        title: strings.distributeSameOccurrenceOptionTitle,
        type: 'normal'
      });
    }

    options.push({
      selectionHandler: () => hideOptionsDialog(),
      title: strings.distributeCancelOptionTitle,
      type: 'disabled'
    });

    return options;
  }, [strings, viewModel.canDistributeToOccurrence]);

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="medium"
      onClose={() => viewModel.cancel()}
    >
      <DialogHeader
        title={viewModel.contentTitle}
        color={headerColor}
        tintColor={headerColor && theme.studyo.periods.periodWithSectionTextColor}
        onClose={() => viewModel.cancel()}
        rightButton={{
          color: 'inherit',
          onlyShowOnExtraSmallScreen: true,
          title: strings.distribute,
          action: () => distributeContentButtonPressed()
        }}
      />

      <ContentDistributeView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.cancel} type="cancel" onPress={() => viewModel.cancel()} />
        <DialogButton
          title={strings.distribute}
          type="normal"
          variant="contained"
          onPress={distributeContentButtonPressed}
        />
      </DialogActionButtons>

      <OptionsDialog
        open={displayDistributeToOptionsDialog}
        title={strings.distributeToOptionsTitle}
        elements={distributeToOptions}
        onClose={hideOptionsDialog}
      />

      <SaveDialog
        promise={distributePromise}
        retryCall={retryDistribute}
        onClose={distributeProcessDialogClose}
        titles={{
          saving: strings.savingTitle,
          saved: strings.savedTitle,
          error: strings.saveErrorTitle
        }}
        descriptions={{
          saving: strings.savingDescription,
          saved: strings.savedDescription,
          error: strings.saveErrorDescription,
          permanentError: strings.savePermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
