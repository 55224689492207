import { ContentDefinitionUtils } from '@shared/components/utils';
import { ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { verifyAndConfirmWorkloads } from '@studyo/utils';
import { Dictionary, compact, flatten, groupBy, sortBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService, NavigationService } from '../../../services';
import { AppLinkedTasksPublishOccurrenceViewModel } from './LinkedTasksPublishOccurrenceViewModel';
import {
  AppLinkedTasksPublishSectionViewModel,
  LinkedTasksPublishSectionViewModel
} from './LinkedTasksPublishSectionViewModel';

export interface LinkedTasksPublishViewModel {
  readonly hasChanges: boolean;
  readonly content: ContentDefinitionModel;
  readonly sections: LinkedTasksPublishSectionViewModel[];
  readonly hasLongTimeSpanContent: boolean;
  cancel: () => void;
  dismiss: () => void;
  publish: () => Promise<void>;
}

export class AppLinkedTasksPublishViewModel implements LinkedTasksPublishViewModel {
  private readonly _sections: LinkedTasksPublishSectionViewModel[];

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _contentId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._sections = this.createSections();
  }

  @computed
  private get data() {
    return this._accountService.displayedAccountData;
  }

  @computed
  get hasChanges() {
    let hasChanges = false;

    this._sections.forEach((section) =>
      section.occurrences.forEach((occ) => {
        if (occ.hasChanges) {
          hasChanges = true;
        }
      })
    );

    return hasChanges;
  }

  @computed
  get content() {
    return this.data.contentsById.get(this._contentId)!;
  }

  @computed
  get sections() {
    return this._sections;
  }

  @computed
  get hasLongTimeSpanContent() {
    return (
      this._sections.find(
        (s) => s.occurrences.find((oc) => ContentDefinitionUtils.hasLongTimeSpan(oc.content, this.data)) != null
      ) != null
    );
  }

  cancel() {
    this._onCancel();
  }

  dismiss() {
    this._onSuccess();
  }

  async publish() {
    const contents = flatten(this.sections.map((s) => s.occurrences.map((o) => o.content)));
    const publishedContents = contents.filter((c) => c.isMaster);

    if (publishedContents.length > 0) {
      if (!(await verifyAndConfirmWorkloads(this._navigationService, this.data, publishedContents, true))) {
        return;
      }
    }

    await this.data.createOrUpdateContents(contents);
  }

  private createSections(): LinkedTasksPublishSectionViewModel[] {
    const tasks = this.data.visibleContents.filter(
      (cd) => cd.contentsGroupIdentifier === this.content.contentsGroupIdentifier
    );

    const tasksBySectionId: Dictionary<ContentDefinitionModel[]> = groupBy(tasks, (t) => t.sectionId);
    const sections = Object.keys(tasksBySectionId).map((id) => this.data.sectionsById.get(id));
    const sortedSections = sortBy(compact(sections), [(s) => s.title, (s) => s.sectionNumber]);

    return sortedSections.map((section) => {
      const sectionTasks = tasksBySectionId[section.id].sort((c1, c2) => this.sortContent(c1, c2));
      const occurrences = sectionTasks.map(
        (task) =>
          new AppLinkedTasksPublishOccurrenceViewModel(
            this._localizationService,
            this._navigationService,
            this.data,
            new EditableContentDefinition(task, false)
          )
      );

      return new AppLinkedTasksPublishSectionViewModel(
        this._localizationService,
        this.data.account,
        section,
        occurrences
      );
    });
  }

  private sortContent(cd1: ContentDefinitionModel, cd2: ContentDefinitionModel) {
    const locale = this._localizationService.currentLocale;
    const dateCompare = cd1.dueDay.compare(cd2.dueDay);
    if (dateCompare !== 0) {
      return dateCompare;
    }
    return cd1.duePeriodTag.localeCompare(cd2.duePeriodTag, locale);
  }
}
