import { SchoolYearConfiguration_AssessmentPlanningSettings as PBAssessmentPlanningSettings } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import { Day } from '../types';

export interface AssessmentPlanningSettingsModel extends SerializableModel<PBAssessmentPlanningSettings> {
  readonly gradeLevel: string;
  readonly examsAndImportantVisibilityDay?: Day;
}

export class AssessmentPlanningSettings
  extends BaseModel<PBAssessmentPlanningSettings>
  implements AssessmentPlanningSettingsModel
{
  constructor(pb: PBAssessmentPlanningSettings) {
    super(pb);
  }

  get gradeLevel(): string {
    return this._pb.gradeLevel;
  }

  @LazyGetter()
  get examsAndImportantVisibilityDay(): Day | undefined {
    if (this._pb.examsAndImportantVisibilityDay) {
      return Day.fromPB(this._pb.examsAndImportantVisibilityDay);
    }

    return undefined;
  }
}
