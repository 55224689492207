import { NavigateFunction } from 'react-router-dom';
import { AccountService } from '../services';

export interface LogoutViewModel {
  onInit(navigate: NavigateFunction): Promise<void>;
}

export class AppLogoutViewModel implements LogoutViewModel {
  constructor(private readonly _accountService: AccountService) {}

  async onInit(navigate: NavigateFunction) {
    try {
      await this._accountService.completeLogout();
      navigate('/');
    } catch {
      // Nothing to do.
    }
  }
}
