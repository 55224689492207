export interface CreateConfigStrings {
  readonly titleInitialConfig: string;
  readonly titleNewConfig: string;
  readonly subtitle: string;
  readonly subtitle2: string;
  readonly enterCode: string;
  readonly learnAboutToday: string;
  readonly loggedInAs: string;
  readonly logout: string;
  readonly goBack: string;
  readonly noCoursesMatched: string;
  readonly noCoursesSelected: string;
}

export const EnglishCreateConfigStrings: CreateConfigStrings = {
  titleInitialConfig: 'Welcome!',
  titleNewConfig: 'Add a new school',
  subtitle: 'Enter a student or parent code to join a Studyo school',
  subtitle2: 'or use Studyo Go to manage your own tasks',
  enterCode: 'Enter a code',
  learnAboutToday: 'Learn about Studyo Go',
  loggedInAs: 'Logged in as',
  logout: 'Log out',
  goBack: 'Go back to planner',
  noCoursesMatched: 'No courses were matched',
  noCoursesSelected: 'You currently have no selected courses.'
};

export const FrenchCreateConfigStrings: CreateConfigStrings = {
  titleInitialConfig: 'Bienvenue!',
  titleNewConfig: 'Ajouter une nouvelle école',
  subtitle: 'Entrez le code d’élève ou parent pour rejoindre une école Studyo',
  subtitle2: 'ou utilisez Studyo Go pour gérer vos propres tâches',
  enterCode: 'Entrer un code',
  learnAboutToday: 'En savoir plus sur Studyo Go',
  loggedInAs: 'Connecté avec',
  logout: 'Déconnexion',
  goBack: 'Retour à l’agenda',
  noCoursesMatched: 'Aucun cours ne correspond',
  noCoursesSelected: 'Vous n’avez aucun cours sélectionné présentement.'
};
