import { SxProps } from '@mui/material';
import { TaskStepList } from '@studyo/components';
import { TaskStepsOrderViewModel } from '@studyo/viewmodels';

export interface TaskStepsOrderViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: TaskStepsOrderViewModel;
}

export const TaskStepsOrderView = ({ sx, className, viewModel }: TaskStepsOrderViewProps) => {
  return <TaskStepList viewModel={viewModel.userSteps} sx={sx} className={className} />;
};
