import { Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ReactNode } from 'react';
import { ListSectionFooter, ListSectionHeader } from '../elements';

export interface ListSectionProps {
  sx?: SxProps;
  className?: string;
  title?: string;
  titleColor?: string;
  headerColor?: string;
  icon?: ReactNode;
  isIconColorInherited?: boolean;
  onIconPressed?: () => void;
  iconIsDisabled?: boolean;
  children: React.ReactNode;
}

export const ListSection = observer((props: ListSectionProps) => {
  const {
    sx,
    className,
    children,
    title,
    icon,
    isIconColorInherited,
    onIconPressed,
    iconIsDisabled,
    headerColor,
    titleColor
  } = props;

  return (
    <Stack sx={sx} className={className}>
      <ListSectionHeader
        title={title}
        titleColor={titleColor}
        color={headerColor}
        icon={icon}
        isIconColorInherited={isIconColorInherited}
        onIconPressed={onIconPressed}
        iconIsDisabled={iconIsDisabled}
      />
      {children}
      <ListSectionFooter />
    </Stack>
  );
});
