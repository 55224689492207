import {
  Box,
  DialogContent,
  FormControl,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { AutoSizer } from '@shared/components/layout';
import { ScrollShadowWrapper } from '@shared/components/utils';
import { Integration } from '@shared/models/types';
import { TaskInfoStrings } from '@shared/resources/strings/studyo/contents/TaskInfoStrings.ts';
import {
  AttachmentsListBox,
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  DialogHeader,
  ListSection,
  ResponsiveDialog,
  TaskDueBox,
  TaskStepList
} from '@studyo/components';
import { TaskInfoViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { TaskInfoBanner } from './TaskInfoBanner.tsx';

export interface TaskInfoViewProps {
  sx?: SxProps;
  className?: string;
  isRoot: boolean;
  viewModel: TaskInfoViewModel;
}

export const TaskInfoView = observer((props: TaskInfoViewProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { viewModel, sx, className } = props;
  const [displayUnlinkConfirmationDialog, setDisplayUnlinkConfirmationDialog] = useState(false);
  const [displayPublishErrorDetailsDialog, setDisplayPublishErrorDetailsDialog] = useState(false);

  useEffect(() => {
    viewModel.onInit();
    return () => viewModel.onDestroy();
  }, []);

  const strings = localizationService.localizedStrings.studyo.contents.taskInfo;
  const showAttachmentAdd = viewModel.canEdit && !viewModel.task.isSlave && !viewModel.isLinkedToOtherTasks;
  const showAttachments = showAttachmentAdd || viewModel.attachments.length > 0;

  const unlinkButtonPressed = () => {
    showUnlinkConfirmationDialog();
  };

  const unlink = () => {
    hideUnlinkConfirmationDialog();
    viewModel.unlink();
  };

  const showUnlinkConfirmationDialog = () => {
    setDisplayUnlinkConfirmationDialog(true);
  };

  const hideUnlinkConfirmationDialog = () => {
    setDisplayUnlinkConfirmationDialog(false);
  };

  const iconResourceForExternalSource = (source: Integration) => {
    return imageService.studyoImages.tasks.external.getIntegrationImage(source);
  };

  // Only available to root admins
  const publishTraceLink = `https://grafana.studyo.tech/explore?left=%7B%22datasource%22:%22campus-tempo%22,%22queries%22:%5B%7B%22query%22:%22${
    viewModel.task.publishTarget?.lastPublishTraceId ?? ''
  }%22,%22queryType%22:%22traceql%22%7D%5D,%22range%22:%7B%22from%22:%22now%2Fy%22,%22to%22:%22now%2Fy%22%7D%7D`;

  return (
    <ScrollShadowWrapper sx={sx} className={className}>
      <List disablePadding>
        {renderPublishStatusBanner(viewModel, strings, setDisplayPublishErrorDetailsDialog)}

        {viewModel.isLinkedToOtherTasks && (
          <TaskInfoBanner
            message={strings.linkedTaskCount(viewModel.numberOfLinkedTasks)}
            button={viewModel.canEdit ? { title: strings.unlink, action: () => unlinkButtonPressed() } : undefined}
          />
        )}
        {viewModel.showAssessmentPlanningMessage && <TaskInfoBanner message={strings.assessmentFilteredTask} />}
        {viewModel.showNoLoadMessage && <TaskInfoBanner message={strings.noLoadMessage} />}
        {viewModel.showIsPrivateMessage && <TaskInfoBanner message={strings.isPrivateMessage} />}

        <ListSection title={viewModel.title}>
          <Box sx={{ height: 100, mx: 2, my: 1 }}>
            <AutoSizer>
              {({ width }: { width: number }) => (
                <TaskDueBox viewModel={viewModel.taskDueBoxViewModel} componentWidth={width} />
              )}
            </AutoSizer>
          </Box>

          {viewModel.externalSource != null &&
            viewModel.externalLink != null &&
            viewModel.externalSource !== 'studyo-internal' && (
              <ListItemButton component="a" href={viewModel.externalLink} target="_blank">
                <ListItemIcon>
                  <img
                    src={iconResourceForExternalSource(viewModel.externalSource)}
                    style={{ width: 26, height: 26 }}
                  />
                </ListItemIcon>
                <ListItemText primary={strings.externalSourceButtonTitle(viewModel.externalSource)} />
              </ListItemButton>
            )}
        </ListSection>

        {viewModel.task.masterContent != null && viewModel.task.masterContent.notes.length > 0 && (
          <ListSection title={strings.teacherNotesTitle}>
            <Typography sx={{ whiteSpace: 'pre-wrap', mx: 2 }}>{viewModel.task.masterContent.notes}</Typography>
          </ListSection>
        )}

        {viewModel.task.notes.length > 0 && (
          <ListSection title={strings.notesTitle}>
            <Typography sx={{ whiteSpace: 'pre-wrap', mx: 2 }}>{viewModel.task.notes}</Typography>
          </ListSection>
        )}

        {viewModel.hasSteps && (
          <ListSection title={strings.stepsTitle}>
            <TaskStepList viewModel={viewModel.userStepsViewModel} displaySeparator={false} />
          </ListSection>
        )}

        {showAttachments && (
          <ListSection title={strings.attachmentsTitle}>
            <AttachmentsListBox sx={{ px: 2 }} viewModel={viewModel.attachmentsListBoxViewModel} />
          </ListSection>
        )}

        <ConfirmDialog
          open={displayUnlinkConfirmationDialog}
          title={strings.unlinkConfirmTitle}
          description={strings.unlinkConfirmDescription}
          cancelTitle={strings.unlinkConfirmStayTitle}
          onCancelPress={hideUnlinkConfirmationDialog}
          confirmTitle={strings.unlinkConfirmProceedTitle}
          onConfirmPress={unlink}
          onClose={hideUnlinkConfirmationDialog}
        />

        <ResponsiveDialog
          maxWidth="sm"
          fullWidth
          open={displayPublishErrorDetailsDialog}
          onClose={() => setDisplayPublishErrorDetailsDialog(false)}
        >
          <DialogHeader title={strings.publishErrorDetailsLabel} />
          <DialogContent>
            <Stack spacing={2}>
              <Typography>{strings.publishStatus(viewModel.task.publishTarget?.status)}</Typography>
              <TextField
                fullWidth
                variant="standard"
                label={strings.lastPublishingLabel}
                value={viewModel.task.publishTarget?.lastPublishingAt ?? ''}
                InputProps={{ readOnly: true }}
              />
              <FormControl fullWidth>
                <Link href={publishTraceLink} target="_blank">
                  <Typography>{viewModel.task.publishTarget?.lastPublishTraceId ?? ''}</Typography>
                </Link>
              </FormControl>
              <TextField
                fullWidth
                variant="standard"
                label={strings.lastPublishedLabel}
                value={viewModel.task.publishTarget?.lastPublishedAt ?? strings.never}
                InputProps={{ readOnly: true }}
              />
            </Stack>
          </DialogContent>
          <DialogActionButtons>
            <Box flex={1} />
            <DialogButton
              title={strings.close}
              type="cancel"
              onPress={() => setDisplayPublishErrorDetailsDialog(false)}
            />
          </DialogActionButtons>
        </ResponsiveDialog>
      </List>
    </ScrollShadowWrapper>
  );
});

function renderPublishStatusBanner(
  viewModel: TaskInfoViewModel,
  strings: TaskInfoStrings,
  setDisplayPublishErrorDetailsDialog: Dispatch<SetStateAction<boolean>>
) {
  const publishDetailsButton = viewModel.canShowPublishDetails
    ? { title: strings.publishErrorDetailsLabel, action: () => setDisplayPublishErrorDetailsDialog(true) }
    : undefined;

  return viewModel.isPublishing ? (
    <TaskInfoBanner message={strings.publishingBanner} button={publishDetailsButton} />
  ) : viewModel.isUnpublishing ? (
    <TaskInfoBanner message={strings.unpublishingBanner} button={publishDetailsButton} />
  ) : viewModel.hasPublishError ? (
    <TaskInfoBanner message={strings.publishErrorBanner} button={publishDetailsButton} variant="error" />
  ) : viewModel.canShowPublishDetails ? (
    <TaskInfoBanner message={strings.rootAdminBanner} button={publishDetailsButton} />
  ) : undefined;
}
