import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AccountUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { useStudyoServices } from '@studyo/UseStudyoServicesHook';
import { isExceededImpact } from '@studyo/utils';
import { WorkloadImpactDetailsViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react';

export interface WorkloadImpactDetailsViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkloadImpactDetailsViewModel;
}

export const WorkloadImpactDetailsView = observer(({ sx, className, viewModel }: WorkloadImpactDetailsViewProps) => {
  const { localizationService } = useStudyoServices();
  const dateStrings = localizationService.localizedStrings.models.dateFormats;
  const strings = localizationService.localizedStrings.studyo.contents.workload;
  const theme = useTheme();
  const studentBackgroundColor = theme.studyo.contents.displayableContentMoreBackgroundColor;

  return (
    <Stack sx={sx} className={className} overflow="auto">
      {viewModel.groups.map((g, i) => (
        <Stack key={`group-${i}`}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: studentBackgroundColor }}>
              <Typography variant="subtitle1" color={isExceededImpact(g.impact) ? 'error' : undefined}>
                {strings.publishingImpact(g.impact, g.accounts.length, g.targetGroup)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: studentBackgroundColor }}>
              {g.accounts.map((a) => (
                <Typography key={`account-${a.id}`} variant="body2">
                  {AccountUtils.getDisplayFirstLastName(a)}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
          <Stack p={2} spacing={1}>
            {g.contents.map((c) => (
              <Stack key={`content-${c.content.id}`} direction="row" spacing={1} alignItems="center">
                <TaskIcon
                  icon={c.content.icon}
                  externalSource={c.content.externalContent?.sourceIntegration}
                  workloadLevel={c.content.workloadLevel}
                />
                <Typography variant="body2" sx={{ flex: 1 }}>
                  {c.content.title}
                </Typography>
                {c.section != null && (
                  <Stack borderLeft={`4px solid ${SectionColors.get(c.section.color)}`} pr={2} width={150}>
                    <Typography ml={1}>{c.section.title}</Typography>
                    {c.teacher != null && (
                      <Typography ml={1} variant="caption">
                        {AccountUtils.getDisplayLastFirstName(c.teacher)}
                      </Typography>
                    )}
                  </Stack>
                )}
                <Typography variant="caption">
                  {c.content.dueDay.formattedString(dateStrings.shortUnabridged)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
});
