import { DateUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { LinkingService } from '@shared/services';
import { computed, makeObservable } from 'mobx';
import {
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoSettingsStore,
  UISettingsStore
} from '../../../services';
import { StudyoAnalyticsService } from '../../../services/analytics';
import { AppDayAndWeekViewModel, DayAndWeekViewModel } from './DayAndWeekViewModel';
import { WeeklyHeaderViewModel } from './WeeklyHeaderViewModel';

export type WeeklyViewModel = DayAndWeekViewModel;

export class AppWeeklyViewModel extends AppDayAndWeekViewModel implements WeeklyViewModel {
  private _weeklyHeaderViewModel: WeeklyHeaderViewModel;

  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    contentPasteboardStore: ContentPasteboardStore,
    numberOfSchoolDaysPerPage: number,
    accountService: AccountService,
    analyticsService: StudyoAnalyticsService,
    settingsStore: StudyoSettingsStore,
    uiSettingsStore: UISettingsStore,
    attachmentManager: AttachmentManager,
    linkingService: LinkingService
  ) {
    const preferences = settingsStore.getPreferences(accountService.displayedAccountData.accountId);

    super(
      navigationService,
      localizationService,
      contentPasteboardStore,
      analyticsService,
      linkingService,
      preferences,
      uiSettingsStore,
      'weekly',
      accountService,
      numberOfSchoolDaysPerPage
    );

    makeObservable(this);

    this._weeklyHeaderViewModel = new WeeklyHeaderViewModel(
      this,
      localizationService,
      navigationService,
      attachmentManager,
      this.data,
      preferences,
      uiSettingsStore
    );
  }

  // Make the array start on monday if there is more than 1 day.
  @computed
  get currentDays() {
    const mondayIndex = this.getFirstMondayIndexBeforeIndex(this.currentDayIndex);
    const days = this.allDays.slice(mondayIndex, mondayIndex + this._numberOfSchoolDaysPerPage);

    const displayWeekends = this.preferences.weeklyDisplayWeekends;

    if (displayWeekends) {
      return days;
    } else {
      return days.filter((d) => !DateUtils.isWeekend(d.day));
    }
  }

  @computed
  get headerViewModel() {
    return this._weeklyHeaderViewModel;
  }
}
