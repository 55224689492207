import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar, SxProps } from '@mui/material';
import { observer } from 'mobx-react';

export type ToastVariant = 'info' | 'success' | 'warning' | 'error';
export type ToastDuration = 'long' | 'short';

export interface ToastProps {
  sx?: SxProps;
  className?: string;
  open: boolean;
  message: string;
  /**
   * Only used on the web
   *
   * @type {ToastVariant}
   * @memberof ToastProps
   */
  variant: ToastVariant;
  duration: ToastDuration;
  onClose: () => void;
}

export const Toast = observer((props: ToastProps) => {
  const { message, onClose, open, variant, duration, sx, className } = props;

  return (
    <Snackbar
      sx={sx}
      className={className}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={duration === 'short' ? 2_000 : 3_500}
      onClose={onClose}
    >
      <Alert
        security={variant}
        action={
          <IconButton key="close" aria-label="close" onClick={onClose} color="inherit" size="small">
            <CloseIcon />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
});
