import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { AllDayOfWeek } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface YearMonthDayOfWeeksProps {
  sx?: SxProps;
  className?: string;
}

export const YearMonthDayOfWeeks = observer((props: YearMonthDayOfWeeksProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className } = props;
  const theme = useTheme();

  return (
    <Stack direction="row" sx={sx} className={className}>
      {AllDayOfWeek.map((dow) => (
        <Typography
          key={`dow-${dow}`}
          sx={{ userSelect: 'none' }}
          textAlign="center"
          flexGrow={1}
          flexBasis={0}
          flexShrink={0}
          variant="body2"
          color={dow === 'sunday' || dow === 'saturday' ? theme.palette.text.disabled : 'textSecondary'}
        >
          {localizationService.localizedStrings.models.dayOfWeek.singleLetterDayOfWeek(dow)}
        </Typography>
      ))}
    </Stack>
  );
});
