import { SchoolYearConfiguration as PBSchoolYearConfiguration } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import {
  ConfigState,
  ConfigType,
  CycleDayKind,
  Day,
  GradeLevelSource,
  Integration,
  PremiumFeature,
  ScheduleKind
} from '../types';
import {
  configStateFromProtobuf,
  configTypeFromProtobuf,
  cycleDayKindFromProtobuf,
  gradeLevelSourceFromProtobuf,
  integrationFromProtobuf,
  premiumFeatureFromProtobuf,
  scheduleKindFromProtobuf
} from '../types/EnumConversion';
import { AssessmentPlanningSettings, AssessmentPlanningSettingsModel } from './AssessmentPlanningSettings';
import { DayConfiguration, DayConfigurationModel } from './DayConfiguration';
import { Link, LinkModel } from './Link';
import { Schedule, ScheduleModel } from './Schedule';
import { Section, SectionModel } from './Section';
import { SpecialDay, SpecialDayModel } from './SpecialDay';
import { Term, TermModel } from './Term';
import { WorkloadThreshold, WorkloadThresholdModel } from './WorkloadThreshold';

export interface SchoolYearConfigurationModel extends SerializableModel<PBSchoolYearConfiguration> {
  readonly id: string;
  readonly syncToken: string;
  readonly isDeleted: boolean;
  readonly type: ConfigType;
  readonly version: number;
  readonly schoolName: string;
  readonly title: string;
  readonly daysPerCycle: number;
  readonly startDay: Day;
  readonly endDay: Day;
  readonly managedOnboardCode: string;
  readonly language: string;
  readonly timezone: string;
  readonly enabledIntegrations: Integration[];
  readonly links: LinkModel[];
  readonly isManagedWithSubscriptions: boolean;
  readonly managedSubscriptionCoupon: string;
  readonly isDemo: boolean;
  readonly comments: string;
  readonly sections: SectionModel[];
  readonly scheduleKind: ScheduleKind;
  readonly cycleDayKind: CycleDayKind;
  readonly specialDays: SpecialDayModel[];
  readonly schedules: ScheduleModel[];
  readonly dayConfigurations: DayConfigurationModel[];
  readonly cycleDayTitles: string[];
  readonly terms: TermModel[];
  readonly dailyWorkloadThreshold: number;
  readonly weeklyWorkloadThreshold: number;
  readonly workloadThresholds: WorkloadThresholdModel[];
  readonly teacherAvailabilityDate: Date | undefined;
  readonly studentAvailabilityDate: Date | undefined;
  readonly assessmentPlanningSettings: AssessmentPlanningSettingsModel[];
  readonly disabledFeatures: PremiumFeature[];
  readonly gradeLevelSource: GradeLevelSource;
  readonly demoDay: Day | undefined;
  readonly state: ConfigState;
  readonly tags: string[];
  readonly allowedScheduleTags: string[];
  readonly expectedStudentCount: number;
  readonly previousConfigurationId: string;

  toProtobufWithoutSections(): PBSchoolYearConfiguration;
}

export class SchoolYearConfiguration
  extends BaseModel<PBSchoolYearConfiguration>
  implements SchoolYearConfigurationModel
{
  constructor(pb: PBSchoolYearConfiguration) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get syncToken(): string {
    return this._pb.syncToken;
  }

  get isDeleted(): boolean {
    return this._pb.isDeleted;
  }

  get type(): ConfigType {
    return configTypeFromProtobuf(this._pb.type);
  }

  get version(): number {
    return this._pb.version;
  }

  get schoolName(): string {
    return this._pb.schoolName;
  }

  get title(): string {
    return this._pb.title;
  }

  get daysPerCycle(): number {
    return this._pb.daysPerCycle;
  }

  @LazyGetter()
  get startDay(): Day {
    const day = this._pb.startDay;
    if (day == null) {
      throw new Error('Invalid data from backend: config.startDay is undefined.');
    }

    return new Day(day);
  }

  @LazyGetter()
  get endDay(): Day {
    const day = this._pb.endDay;
    if (day == null) {
      throw new Error('Invalid data from backend: config.endDay is undefined.');
    }

    return new Day(day);
  }

  get managedOnboardCode(): string {
    return this._pb.managedOnboardCode;
  }

  get language(): string {
    return this._pb.language;
  }

  get timezone(): string {
    return this._pb.timezone;
  }

  get enabledIntegrations(): Integration[] {
    return this._pb.enabledIntegrations.map((integration) => integrationFromProtobuf(integration));
  }

  @LazyGetter()
  get links(): LinkModel[] {
    return this._pb.links.map((l) => new Link(l));
  }

  get isManagedWithSubscriptions(): boolean {
    return this._pb.isManagedWithSubscriptions;
  }

  get managedSubscriptionCoupon(): string {
    return this._pb.managedSubscriptionCoupon;
  }

  get isDemo(): boolean {
    return this._pb.isDemo;
  }

  get comments(): string {
    return this._pb.comments;
  }

  @LazyGetter()
  get sections(): SectionModel[] {
    return this._pb.sections.map((s) => new Section(s));
  }

  get scheduleKind(): ScheduleKind {
    return scheduleKindFromProtobuf(this._pb.scheduleKind);
  }

  get cycleDayKind(): CycleDayKind {
    return cycleDayKindFromProtobuf(this._pb.cycleDayKind);
  }

  @LazyGetter()
  get specialDays(): SpecialDayModel[] {
    return this._pb.specialDays.map((sd) => new SpecialDay(sd));
  }

  @LazyGetter()
  get schedules(): ScheduleModel[] {
    return this._pb.schedules.map((s) => new Schedule(s));
  }

  @LazyGetter()
  get dayConfigurations(): DayConfigurationModel[] {
    return this._pb.dayConfigurations.map((dc) => new DayConfiguration(dc));
  }

  get cycleDayTitles(): string[] {
    return this._pb.cycleDayTitles;
  }

  get terms() {
    return this._pb.terms.map((t) => new Term(t));
  }

  get dailyWorkloadThreshold(): number {
    return this._pb.dailyWorkloadThreshold;
  }

  get weeklyWorkloadThreshold(): number {
    return this._pb.weeklyWorkloadThreshold;
  }

  @LazyGetter()
  get workloadThresholds(): WorkloadThresholdModel[] {
    return this._pb.workloadThresholds.map((dc) => new WorkloadThreshold(dc));
  }

  get teacherAvailabilityDate(): Date | undefined {
    const timestamp = this._pb.teacherAvailabilityDate;

    if (timestamp == null) {
      return undefined;
    }

    return timestamp.toDate();
  }

  get studentAvailabilityDate(): Date | undefined {
    const timestamp = this._pb.studentAvailabilityDate;

    if (timestamp == null) {
      return undefined;
    }

    return timestamp.toDate();
  }

  @LazyGetter()
  get assessmentPlanningSettings(): AssessmentPlanningSettingsModel[] {
    return this._pb.assessmentPlanningSettings.map((aps) => new AssessmentPlanningSettings(aps));
  }

  @LazyGetter()
  get disabledFeatures(): PremiumFeature[] {
    return this._pb.disabledFeatures.map((df) => premiumFeatureFromProtobuf(df));
  }

  get gradeLevelSource(): GradeLevelSource {
    return gradeLevelSourceFromProtobuf(this._pb.gradeLevelSource);
  }

  @LazyGetter()
  get demoDay(): Day | undefined {
    const pbDay = this._pb.demoDay;
    return pbDay && new Day(pbDay);
  }

  get state(): ConfigState {
    return configStateFromProtobuf(this._pb.state);
  }

  get tags(): string[] {
    return this._pb.tags ?? [];
  }

  get allowedScheduleTags(): string[] {
    return this._pb.allowedScheduleTags;
  }

  get expectedStudentCount() {
    return this._pb.expectedStudentCount;
  }

  get previousConfigurationId() {
    return this._pb.previousConfigurationId;
  }

  toProtobufWithoutSections(): PBSchoolYearConfiguration {
    const pb = this.toProtobuf();
    pb.sections = [];

    return pb;
  }
}
