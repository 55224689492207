import { Box, SxProps } from '@mui/material';
import { DataLoader } from '@shared/services';
import { chain } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PresenterPendingView } from './PresenterPendingView.tsx';
import { PresenterRejectedView } from './PresenterRejectedView.tsx';

export interface DataPresenterProps {
  sx?: SxProps;
  className?: string;
  dataLoaders: DataLoader[];
  loadingMessage?: string;
  defaultErrorMessage?: string;
  renderData: () => React.ReactNode;
}

export const DataPresenter = observer((props: DataPresenterProps) => {
  const { viewModelFactory } = useStudyoServices();
  const {
    loadingMessage,
    defaultErrorMessage,
    sx,
    className,
    renderData,

    dataLoaders
  } = props;

  const hasData = dataLoaders.every((loader) => loader.hasData);

  const isRefreshing = dataLoaders.some((loader) => loader.isRefreshing);

  const errors = chain(dataLoaders)
    .map((loader) => loader.lastRefreshError)
    .compact()
    .value();

  let componentToRender: React.ReactNode;

  if (!hasData) {
    if (isRefreshing || errors.length === 0) {
      componentToRender = <PresenterPendingView loadingMessage={loadingMessage} sx={sx} className={className} />;
    } else {
      const errorViewModel = viewModelFactory.createPresenterRejected(dataLoaders, errors, defaultErrorMessage);
      componentToRender = <PresenterRejectedView viewModel={errorViewModel} sx={sx} className={className} />;
    }
  } else {
    componentToRender = renderData();
  }

  return (
    <Box sx={sx} className={className} overflow="hidden">
      {componentToRender}
    </Box>
  );
});
