import { OccurrencePeriodPriorityObject } from '../../interfaces';

export const OccurrencePeriodPriorityObjectKey = 'periodPrioritiesOccurrenceObjects';

export class AppOccurrencePeriodPriorityObject implements OccurrencePeriodPriorityObject {
  static fromJson(value: Record<string, unknown>) {
    const cycleDay = value.cycleDay as number;
    const periodTag = value.periodTag as string;
    const sectionId = value.sectionId as string | undefined;
    // New property, might not exist.
    const isSkipped = (value.isSkipped as boolean | undefined) ?? false;

    return new AppOccurrencePeriodPriorityObject(cycleDay, periodTag, sectionId, isSkipped);
  }

  constructor(
    public readonly cycleDay: number,
    public readonly periodTag: string,
    public readonly sectionId: string | undefined,
    public readonly isSkipped: boolean
  ) {}

  get asJson() {
    return {
      cycleDay: this.cycleDay,
      periodTag: this.periodTag,
      sectionId: this.sectionId
    };
  }
}
