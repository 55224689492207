import { CardActionArea, SxProps, Typography, useTheme } from '@mui/material';
import { OptionsDialogElement } from '@studyo/models';
import { observer } from 'mobx-react';

export interface OptionsDialogElementRowProps {
  sx?: SxProps;
  className?: string;
  element: OptionsDialogElement;
}

export const OptionsDialogElementRow = observer((props: OptionsDialogElementRowProps) => {
  const { element, sx, className } = props;
  const theme = useTheme();

  let textColor = '';

  switch (element.type) {
    case 'destructive':
      textColor = theme.palette.error.main;
      break;

    case 'disabled':
      textColor = theme.palette.text.secondary;
      break;

    case 'normal':
      textColor = theme.palette.primary.main;
      break;
  }

  return (
    <CardActionArea
      sx={{ ...sx, color: textColor, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}
      onClick={() => element.selectionHandler()}
      className={className}
    >
      <Typography variant="button" color="inherit" textTransform="none">
        {element.title}
      </Typography>
    </CardActionArea>
  );
});
