import { CourseOccurrence } from '@shared/models/calendar';
import { AccountModel } from '@shared/models/config/Account.ts';
import { SectionModel } from '@shared/models/config/Section.ts';
import { ContentAttachmentModel, ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { ImageService, LocalizationService, ThemeService } from '@shared/resources/services';
import { DataLoader, LinkingService, NetworkService } from '@shared/services';
import { AnalyticsEvent, AnalyticsPage, AnalyticsService } from '@shared/services/analytics';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { ReactRouterRouteService } from '@shared/web/services';
import { StudyoServiceContainer } from '../StudyoServiceContainer.ts';
import * as VM from '../viewmodels';
import { AccountAutoSyncService } from './AccountAutoSyncService';
import { AccountService } from './AccountService';
import { AttachmentManager } from './AttachmentManager';
import { ContentPasteboardStore } from './ContentPasteboardManager';
import { NavigationService } from './NavigationService';
import { StudyoEnvironmentService } from './StudyoEnvironmentService';
import { SubscriptionsManager } from './SubscriptionsManager';
import { UISettingsStore } from './UISettingsStore';
import { StudyoAnalyticsEventCategory, StudyoAnalyticsPage } from './analytics';
import { StudyoSettingsStore } from './settings';

export interface ViewModelFactory {
  createLogin: () => VM.LoginViewModel;
  createLogout: () => VM.LogoutViewModel;
  createConfigCreationScreen: (isInitialConfig: boolean) => VM.ConfigCreationViewModel;
  createLearnAboutTodayScreen: () => VM.LearnAboutTodayViewModel;
  createUseCode: (isInitialConfig: boolean) => VM.UseCodeViewModel;

  createDateSwitcher: (context: VM.DateSwitcherContext) => VM.DateSwitcherViewModel;
  createDayInfo: (day: Day | undefined) => VM.DayInfoViewModel;

  createBaseAgenda: () => VM.BaseAgendaViewModel;
  createAgendaDaily: () => VM.DailyViewModel;
  createAgendaWeekly: () => VM.WeeklyViewModel;
  createAgendaMonthly: () => VM.MonthlyViewModel;
  createAgendaYearly: () => VM.YearViewModel;
  createAgendaTimeline: () => VM.AgendaTimelineViewModel;
  createAgendaPlanner: () => VM.PlannerViewModel;
  createAgendaPeriods: () => VM.AgendaPeriodsViewModel;
  createAgendaWorkload: () => VM.AgendaWorkloadViewModel;

  createPreparingSchool: () => VM.PreparingSchoolViewModel;

  /* Daily */

  createDailyOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.DailyOptionMenuViewModel;

  /* Weekly */

  createWeeklyOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.WeeklyOptionMenuViewModel;

  /* Monthly */

  createMonthlyFiltersOptions: (onSuccess: () => void, onCancel: () => void) => VM.MonthlyFiltersOptionsViewModel;
  createMonthlyOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.MonthlyOptionMenuViewModel;
  createMonthlySectionFilter: (onSuccess: () => void, onCancel: () => void) => VM.SectionFilterViewModel;

  /* Timeline */

  createTimelineFiltersOptions: (onSuccess: () => void, onCancel: () => void) => VM.TimelineFiltersOptionsViewModel;
  createTimelineOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.TimelineOptionMenuViewModel;

  /* Planner */

  createPlannerPeriodInfo: (
    day: Day,
    periodTag: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PlannerPeriodInfoViewModel;

  createPlannerPeriodMoveContent: (
    day: Day,
    periodTag: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PlannerPeriodContentMoveViewModel;

  createPlannerPeriodEdit: (
    occurrence: CourseOccurrence,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PlannerPeriodEditViewModel;

  createPlannerOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.PlannerOptionMenuViewModel;
  createPlannerSectionFilter: (onSuccess: () => void, onCancel: () => void) => VM.SectionFilterViewModel;
  createPlannerContentFiltersOptions: (onSuccess: () => void, onCancel: () => void) => VM.PlannerFilterOptionsViewModel;
  createPlannerSectionInfo: (
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PlannerSectionInfoViewModel;

  /* Periods */

  createPeriodOptionMenu: (onSuccess: () => void, onCancel: () => void) => VM.PeriodsOptionMenuViewModel;
  createPeriodSectionsFilterOptions: (onSuccess: () => void, onCancel: () => void) => VM.SectionFilterViewModel;
  createPeriodTasksFilterOptions: (onSuccess: () => void, onCancel: () => void) => VM.PeriodTasksFilterOptionsViewModel;

  /* Contents */

  createDisplayableContentList: (
    day: Day,
    periodTag: string | undefined,
    sectionId: string | undefined,
    contentIds: string[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.DisplayableContentListViewModel;

  createSchoolDayContentList: (
    day: Day,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.SchoolDayContentListViewModel;

  createTaskInfo: (contentId: string, onSuccess: () => void, onCancel: () => void) => VM.TaskInfoViewModel;

  createTaskEdit: (
    contentId: string | undefined,
    day: Day | undefined,
    periodTag: string | undefined,
    sectionId: string | undefined,
    onSuccess: (value: string) => void,
    onCancel: () => void
  ) => VM.TaskEditViewModel;

  createTaskStepsOrder: (
    content: EditableContentDefinition,
    onSave: (content: EditableContentDefinition) => void,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.TaskStepsOrderViewModel;

  createNoteEdit: (
    day: Day,
    periodTag: string,
    sectionId: string | undefined,
    onSuccess: (value: string) => void,
    onCancel: () => void
  ) => VM.NoteEditViewModel;

  createAttachmentList: (
    getAttachments: () => ContentAttachmentModel[],
    deleteAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    addAttachment: (attachment: ContentAttachmentModel) => void,
    isSlaveContent: boolean,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.AttachmentListViewModel;

  createAddAttachment: (
    addAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ContentAttachmentAddViewModel;

  createContentPublishStudentSelection: (
    content: string | (() => ContentDefinitionModel),
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ContentPublishStudentSelectionViewModel;

  createContentRepeat: (
    contentId: string,
    onSuccess: (updatedContent: ContentDefinitionModel) => void,
    onCancel: () => void
  ) => VM.ContentRepeatViewModel;
  createContentDistribute: (
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ContentDistributeViewModel;

  createLinkedTasksDelete: (
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LinkedTasksDeleteViewModel;

  createLinkedTasksPublish: (
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LinkedTasksPublishViewModel;

  createWorkloadImpactDetails: (
    workloadInfos: VM.WorkloadInfo[],
    sectionsById: Map<string, SectionModel>,
    accountsById: Map<string, AccountModel>,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.WorkloadImpactDetailsViewModel;

  /* Settings */

  createProfileMenu: (onSuccess: () => void, onCancel: () => void) => VM.ProfileMenuViewModel;
  createOtherProfilesList: (onSuccess: () => void, onCancel: () => void) => VM.OtherProfilesListViewModel;
  createProfileEdit: (onSuccess: () => void, onCancel: () => void) => VM.ProfileEditViewModel;
  createCourseSelection: (onSuccess: () => void, onCancel: () => void) => VM.CourseSelectionViewModel;
  createAskName: (onSuccess: () => void, onCancel: () => void) => VM.AskNameViewModel;
  createGearMenuShare: (onSuccess: () => void, onCancel: () => void) => VM.GearMenuShareViewModel;
  createConfigLinkList: (onSuccess: () => void, onCancel: () => void) => VM.ConfigLinkListViewModel;
  createPreferences: (onSuccess: () => void, onCancel: () => void) => VM.PreferencesViewModel;
  createImpersonateStudentSelection: (
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImpersonateStudentSelectionViewModel;

  /* Utils */
  createPresenterRejected: (
    dataLoaders: DataLoader[],
    errors: Error[],
    defaultErrorMessage?: string
  ) => VM.PresenterRejectedViewModel;
  createSchoolDayPicker: () => VM.SchoolDayPickerViewModel;
  createPeriodPrioritySelector: (
    periodTag: string,
    day: Day,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PeriodPrioritySelectorViewModel;

  /* Redirect pages */
  createParentAccessRedirect: (configId: string, accountId: string) => VM.ParentAccessRedirectViewModel;
}

export class AppViewModelFactory implements ViewModelFactory {
  createLogin(): VM.LoginViewModel {
    return new VM.AppLoginViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveLocalizationService(),
      this.resolveAnalyticsService(),
      this.resolveEnvironmentService(),
      this.resolveUISettingsStore()
    );
  }

  createLogout(): VM.LogoutViewModel {
    return new VM.AppLogoutViewModel(this.resolveAccountService());
  }

  createConfigCreationScreen(isInitialConfig: boolean): VM.ConfigCreationViewModel {
    return new VM.AppConfigCreationViewModel(
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      isInitialConfig
    );
  }

  createLearnAboutTodayScreen(): VM.LearnAboutTodayViewModel {
    return new VM.AppLearnAboutTodayViewModel(this.resolveAccountService(), this.resolveAnalyticsService());
  }

  createUseCode(isInitialConfig: boolean): VM.UseCodeViewModel {
    return new VM.AppUseCodeViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveSchoolYearConfigurationStore(),
      this.resolveLocalizationService(),
      this.resolveAnalyticsService(),
      isInitialConfig
    );
  }

  createDateSwitcher(context: VM.DateSwitcherContext): VM.DateSwitcherViewModel {
    return new VM.AppDateSwitcherViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore(),
      context
    );
  }

  createDayInfo(day: Day | undefined): VM.DayInfoViewModel {
    return new VM.AppDayInfoViewModel(this.resolveAccountService(), this.resolveUISettingsStore(), day);
  }

  createBaseAgenda(): VM.BaseAgendaViewModel {
    return new VM.AppBaseAgendaViewModel(
      this.resolveAccountService(),
      this.resolveSettingsStore(),
      this.resolveLocalizationService()
    );
  }

  createAgendaDaily(): VM.DailyViewModel {
    return new VM.AppDailyViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveContentPasteboardStore(),
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore(),
      this.resolveAttachmentManager(),
      this.resolveLinkingService()
    );
  }

  createAgendaWeekly(): VM.WeeklyViewModel {
    return new VM.AppWeeklyViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveContentPasteboardStore(),
      7,
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore(),
      this.resolveAttachmentManager(),
      this.resolveLinkingService()
    );
  }

  createAgendaMonthly(): VM.MonthlyViewModel {
    return new VM.AppMonthlyViewModel(
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveUISettingsStore(),
      this.resolveContentPasteboardStore(),
      this.resolveSettingsStore(),
      this.resolveAttachmentManager()
    );
  }

  createAgendaYearly(): VM.YearViewModel {
    return new VM.AppYearViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAttachmentManager(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore()
    );
  }

  createAgendaTimeline(): VM.AgendaTimelineViewModel {
    return new VM.AppAgendaTimelineViewModel(
      this.resolveUISettingsStore(),
      this.resolveNavigationService(),
      this.resolveLocalizationService(),
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveSettingsStore(),
      this.resolveAttachmentManager()
    );
  }

  createAgendaPlanner(): VM.PlannerViewModel {
    return new VM.AppPlannerViewModel(
      this.resolveUISettingsStore(),
      this.resolveContentPasteboardStore(),
      this.resolveSettingsStore(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveAttachmentManager()
    );
  }

  createAgendaPeriods(): VM.AgendaPeriodsViewModel {
    return new VM.AppAgendaPeriodsViewModel(
      this.resolveAccountService(),
      this.resolveAnalyticsService(),
      this.resolveAttachmentManager(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore(),
      this.resolveContentPasteboardStore()
    );
  }

  createAgendaWorkload(): VM.AgendaWorkloadViewModel {
    return new VM.AppAgendaWorkloadViewModel(
      this.resolveAccountService(),
      this.resolveEnvironmentService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAttachmentManager(),
      this.resolveSettingsStore(),
      this.resolveUISettingsStore()
    );
  }

  createPreparingSchool(): VM.PreparingSchoolViewModel {
    return new VM.AppPreparingSchoolViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveAnalyticsService()
    );
  }

  /* Daily */

  createDailyOptionMenu(onSuccess: () => void, onCancel: () => void): VM.DailyOptionMenuViewModel {
    return new VM.AppDailyOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  /* Weekly */

  createWeeklyOptionMenu(onSuccess: () => void, onCancel: () => void): VM.WeeklyOptionMenuViewModel {
    return new VM.AppWeeklyOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  /* Monthly */

  createMonthlyFiltersOptions(onSuccess: () => void, onCancel: () => void): VM.MonthlyFiltersOptionsViewModel {
    return new VM.AppMonthlyFiltersOptionsViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  createMonthlyOptionMenu(onSuccess: () => void, onCancel: () => void): VM.MonthlyOptionMenuViewModel {
    return new VM.AppMonthlyOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  createMonthlySectionFilter(onSuccess: () => void, onCancel: () => void): VM.SectionFilterViewModel {
    return new VM.AppMonthlySectionFilterViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveAccountService(),
      this.resolveSettingsStore()
    );
  }

  /* Planner */

  createPlannerPeriodInfo(
    day: Day,
    periodTag: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PlannerPeriodInfoViewModel {
    return new VM.AppPlannerPeriodInfoViewModel(
      this.resolveCalendarStore(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAccountAutoSyncService(),
      sectionId,
      periodTag,
      day,
      this.resolveAccountService(),
      onSuccess,
      onCancel
    );
  }

  createPlannerPeriodMoveContent(
    day: Day,
    periodTag: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PlannerPeriodContentMoveViewModel {
    return new VM.AppPlannerPeriodContentMoveViewModel(
      this.resolveCalendarStore(),
      this.resolveNavigationService(),
      this.resolveLocalizationService(),
      this.resolveAccountAutoSyncService(),
      sectionId,
      periodTag,
      day,
      this.resolveAccountService(),
      onSuccess,
      onCancel
    );
  }

  createPlannerPeriodEdit(
    occurrence: CourseOccurrence,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PlannerPeriodEditViewModel {
    return new VM.AppPlannerPeriodEditViewModel(
      this.resolveCalendarStore(),
      this.resolveNavigationService(),
      this.resolveAccountAutoSyncService(),
      occurrence,
      this.resolveAccountService(),
      onSuccess,
      onCancel
    );
  }

  createPlannerOptionMenu(onSuccess: () => void, onCancel: () => void): VM.PlannerOptionMenuViewModel {
    return new VM.AppPlannerOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  createPlannerSectionFilter(onSuccess: () => void, onCancel: () => void): VM.SectionFilterViewModel {
    return new VM.AppPlannerSectionFilterViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveAccountService(),
      this.resolveSettingsStore()
    );
  }

  createPlannerContentFiltersOptions(onSuccess: () => void, onCancel: () => void): VM.PlannerFilterOptionsViewModel {
    return new VM.AppPlannerFilterOptionsViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  createPlannerSectionInfo(
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PlannerSectionInfoViewModel {
    return new VM.AppPlannerSectionInfoViewModel(
      sectionId,
      onSuccess,
      onCancel,
      this.resolveLinkingService(),
      this.resolveAccountService()
    );
  }

  /* Periods */

  createPeriodOptionMenu(onSuccess: () => void, onCancel: () => void): VM.PeriodsOptionMenuViewModel {
    return new VM.AppPeriodsOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  createPeriodSectionsFilterOptions(onSuccess: () => void, onCancel: () => void): VM.SectionFilterViewModel {
    return new VM.AppPeriodSectionFilterViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveAccountService(),
      this.resolveSettingsStore()
    );
  }

  createPeriodTasksFilterOptions(onSuccess: () => void, onCancel: () => void): VM.PeriodTasksFilterOptionsViewModel {
    return new VM.AppPeriodTasksFilterOptionsViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  /* Timeline */

  createTimelineFiltersOptions(onSuccess: () => void, onCancel: () => void): VM.TimelineFiltersOptionsViewModel {
    return new VM.AppTimelineFiltersOptionsViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveSettingsStore(),
      onSuccess,
      onCancel
    );
  }

  createTimelineOptionMenu(onSuccess: () => void, onCancel: () => void): VM.TimelineOptionMenuViewModel {
    return new VM.AppTimelineOptionMenuViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel,
      this.resolveSettingsStore()
    );
  }

  /* Contents */

  createDisplayableContentList(
    day: Day,
    periodTag: string | undefined,
    sectionId: string | undefined,
    contentIds: string[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.DisplayableContentListViewModel {
    return new VM.AppDisplayableContentListViewModel(
      this.resolveContentPasteboardStore(),
      this.resolveNavigationService(),
      this.resolveLocalizationService(),
      this.resolveAnalyticsService(),
      sectionId,
      onSuccess,
      onCancel,
      day,
      periodTag,
      this.resolveAccountService(),
      contentIds
    );
  }

  createSchoolDayContentList(day: Day, onSuccess: () => void, onCancel: () => void): VM.SchoolDayContentListViewModel {
    return new VM.AppSchoolDayContentListViewModel(
      this.resolveContentPasteboardStore(),
      this.resolveNavigationService(),
      this.resolveAnalyticsService(),
      this.resolveLocalizationService(),
      this.resolveAccountService(),
      onSuccess,
      onCancel,
      day
    );
  }

  createTaskInfo(contentId: string, onSuccess: () => void, onCancel: () => void): VM.TaskInfoViewModel {
    return new VM.AppTaskInfoViewModel(
      this.resolveAccountService(),
      this.resolveAttachmentManager(),
      this.resolveContentPasteboardStore(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAnalyticsService(),
      this.resolveAccountAutoSyncService(),
      contentId,
      onSuccess,
      onCancel
    );
  }

  createTaskEdit(
    contentId: string | undefined,
    day: Day | undefined,
    periodTag: string | undefined,
    sectionId: string | undefined,
    onSuccess: (value: string) => void,
    onCancel: () => void
  ): VM.TaskEditViewModel {
    return new VM.AppTaskEditViewModel(
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAnalyticsService(),
      this.resolveAccountAutoSyncService(),
      this.resolveSettingsStore(),
      onSuccess,
      onCancel,
      this.resolveAccountService(),
      contentId,
      day != null ? { day, periodTag, sectionId } : undefined
    );
  }

  createTaskStepsOrder(
    content: EditableContentDefinition,
    onSave: (content: EditableContentDefinition) => void,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.TaskStepsOrderViewModel {
    return new VM.AppTaskStepsOrderViewModel(
      this.resolveAccountService(),
      this.resolveAccountAutoSyncService(),
      content,
      onSave,
      onSuccess,
      onCancel
    );
  }

  createNoteEdit(
    day: Day,
    periodTag: string,
    sectionId: string | undefined,
    onSuccess: (value: string) => void,
    onCancel: () => void
  ): VM.NoteEditViewModel {
    return new VM.AppNoteEditViewModel(
      this.resolveLocalizationService(),
      this.resolveAccountAutoSyncService(),
      this.resolveLinkingService(),
      onSuccess,
      onCancel,
      sectionId,
      day,
      periodTag,
      this.resolveAccountService()
    );
  }

  createContentPublishStudentSelection(
    content: string | (() => ContentDefinitionModel),
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ContentPublishStudentSelectionViewModel {
    return new VM.AppContentPublishStudentSelectionViewModel(
      this.resolveNavigationService(),
      this.resolveLocalizationService(),
      this.resolveAnalyticsService(),
      this.resolveAccountAutoSyncService(),
      onSuccess,
      onCancel,
      content,
      this.resolveAccountService()
    );
  }

  createContentRepeat(
    contentId: string,
    onSuccess: (updatedContent: ContentDefinitionModel) => void,
    onCancel: () => void
  ): VM.ContentRepeatViewModel {
    return new VM.AppContentRepeatViewModel(
      this.resolveContentPasteboardStore(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      this.resolveAccountAutoSyncService(),
      onSuccess,
      onCancel,
      this.resolveAccountService(),
      contentId
    );
  }

  createContentDistribute(
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ContentDistributeViewModel {
    return new VM.AppContentDistributeViewModel(
      this.resolveAccountService(),
      this.resolveContentPasteboardStore(),
      this.resolveLocalizationService(),
      contentId,
      onSuccess,
      onCancel
    );
  }

  createAttachmentList(
    getAttachments: () => ContentAttachmentModel[],
    deleteAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    addAttachment: (attachment: ContentAttachmentModel) => void,
    isSlaveContent: boolean,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.AttachmentListViewModel {
    return new VM.AppAttachmentListViewModel(
      this.resolveAttachmentManager(),
      this.resolveImageService(),
      this.resolveNavigationService(),
      getAttachments,
      deleteAttachment,
      addAttachment,
      isSlaveContent,
      sectionId,
      onSuccess,
      onCancel,
      this.resolveAccountService()
    );
  }

  createAddAttachment(
    addAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ContentAttachmentAddViewModel {
    return new VM.AppContentAttachmentAddViewModel(
      this.resolveAccountService(),
      this.resolveAccountAutoSyncService(),
      this.resolveAttachmentManager(),
      addAttachment,
      onSuccess,
      onCancel
    );
  }

  createLinkedTasksDelete(
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LinkedTasksDeleteViewModel {
    return new VM.AppLinkedTasksDeleteViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      onSuccess,
      onCancel,
      contentId
    );
  }

  createLinkedTasksPublish(
    contentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LinkedTasksPublishViewModel {
    return new VM.AppLinkedTasksPublishViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      contentId,
      onSuccess,
      onCancel
    );
  }

  createWorkloadImpactDetails(
    workloadInfos: VM.WorkloadInfo[],
    sectionsById: Map<string, SectionModel>,
    accountsById: Map<string, AccountModel>,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.WorkloadImpactDetailsViewModel {
    return new VM.AppWorkloadImpactDetailsViewModel(workloadInfos, sectionsById, accountsById, onSuccess, onCancel);
  }

  /* Settings */

  createProfileMenu(onSuccess: () => void, onCancel: () => void): VM.ProfileMenuViewModel {
    return new VM.AppProfileMenuViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveImageService(),
      this.resolveLocalizationService(),
      this.resolveAnalyticsService(),
      this.resolveEnvironmentService(),
      this.resolveNetworkService(),
      onSuccess,
      onCancel
    );
  }

  createOtherProfilesList(onSuccess: () => void, onCancel: () => void): VM.OtherProfilesListViewModel {
    return new VM.AppOtherProfilesListViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveReactRouterRouteService(),
      onSuccess,
      onCancel
    );
  }

  createProfileEdit(onSuccess: () => void, onCancel: () => void): VM.ProfileEditViewModel {
    return new VM.AppProfileEditViewModel(this.resolveAccountService(), onSuccess, onCancel);
  }

  createCourseSelection(onSuccess: () => void, onCancel: () => void): VM.CourseSelectionViewModel {
    return new VM.AppCourseSelectionViewModel(
      this.resolveNavigationService(),
      this.resolveAccountAutoSyncService(),
      onSuccess,
      onCancel,
      this.resolveAccountService()
    );
  }

  createAskName(onSuccess: () => void): VM.AskNameViewModel {
    return new VM.AppAskNameViewModel(this.resolveLocalizationService(), this.resolveAccountService(), onSuccess);
  }

  createGearMenuShare(onSuccess: () => void, onCancel: () => void): VM.GearMenuShareViewModel {
    return new VM.AppGearMenuShareViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel
    );
  }

  createConfigLinkList(onSuccess: () => void, onCancel: () => void): VM.ConfigLinkListViewModel {
    return new VM.AppConfigLinkListViewModel(
      this.resolveAccountService(),
      this.resolveAttachmentManager(),
      onSuccess,
      onCancel
    );
  }

  createPreferences(onSuccess: () => void, onCancel: () => void): VM.PreferencesViewModel {
    return new VM.AppPreferencesViewModel(
      this.resolveAccountService(),
      this.resolveLocalizationService(),
      this.resolveThemeService(),
      this.resolveSettingsStore(),
      onSuccess,
      onCancel
    );
  }

  createImpersonateStudentSelection(
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImpersonateStudentSelectionViewModel {
    return new VM.AppImpersonateStudentSelectionViewModel(
      this.resolveAccountService(),
      this.resolveNavigationService(),
      onSuccess,
      onCancel
    );
  }

  /* Utils */

  createPresenterRejected(
    dataLoaders: DataLoader[],
    errors: Error[],
    defaultErrorMessage?: string
  ): VM.PresenterRejectedViewModel {
    return new VM.AppPresenterRejectedViewModel(
      this.resolveLocalizationService(),
      this.resolveAccountService(),
      this.resolveNavigationService(),
      this.resolveSubscriptionsManager(),
      dataLoaders,
      errors,
      defaultErrorMessage
    );
  }

  createSchoolDayPicker(): VM.SchoolDayPickerViewModel {
    return new VM.AppSchoolDayPickerViewModel(this.resolveAccountService(), this.resolveLocalizationService());
  }

  createPeriodPrioritySelector(
    periodTag: string,
    day: Day,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PeriodPrioritySelectorViewModel {
    return new VM.AppPeriodPrioritySelectorViewModel(
      this.resolveLocalizationService(),
      this.resolveAccountService(),
      periodTag,
      day,
      onSuccess,
      onCancel
    );
  }

  /* Redirects */

  createParentAccessRedirect(configId: string, accountId: string): VM.ParentAccessRedirectViewModel {
    return new VM.AppParentAccessRedirectViewModel(this.resolveSubscriptionsManager(), configId, accountId);
  }

  /* Private helper functions */

  private resolveAnalyticsService(): AnalyticsService<
    AnalyticsPage<StudyoAnalyticsPage>,
    AnalyticsEvent<StudyoAnalyticsEventCategory>
  > {
    return StudyoServiceContainer.services.analyticsService;
  }

  private resolveContentPasteboardStore(): ContentPasteboardStore {
    return StudyoServiceContainer.services.contentPasteboardStore;
  }

  private resolveCalendarStore(): CalendarStore {
    return StudyoServiceContainer.services.calendarStore;
  }

  private resolveLocalizationService(): LocalizationService {
    return StudyoServiceContainer.services.localizationService;
  }

  private resolveSchoolYearConfigurationStore(): SchoolYearConfigurationStore {
    return StudyoServiceContainer.services.schoolYearConfigurationStore;
  }

  private resolveAccountService(): AccountService {
    return StudyoServiceContainer.services.accountService;
  }

  private resolveAccountAutoSyncService(): AccountAutoSyncService {
    return StudyoServiceContainer.services.accountAutoSyncService;
  }

  private resolveAttachmentManager(): AttachmentManager {
    return StudyoServiceContainer.services.attachmentManager;
  }

  private resolveNavigationService(): NavigationService {
    return StudyoServiceContainer.services.navigationService;
  }

  private resolveImageService(): ImageService {
    return StudyoServiceContainer.services.imageService;
  }

  private resolveThemeService(): ThemeService {
    return StudyoServiceContainer.services.themeService;
  }

  private resolveSettingsStore(): StudyoSettingsStore {
    return StudyoServiceContainer.services.settingsStore;
  }

  private resolveUISettingsStore(): UISettingsStore {
    return StudyoServiceContainer.services.uiSettingsStore;
  }

  private resolveEnvironmentService(): StudyoEnvironmentService {
    return StudyoServiceContainer.services.environmentService;
  }

  private resolveNetworkService(): NetworkService {
    return StudyoServiceContainer.services.networkService;
  }

  private resolveLinkingService(): LinkingService {
    return StudyoServiceContainer.services.linkingService;
  }

  private resolveSubscriptionsManager(): SubscriptionsManager {
    return StudyoServiceContainer.services.subscriptionsManager;
  }

  private resolveReactRouterRouteService(): ReactRouterRouteService {
    return StudyoServiceContainer.services.reactRouterRouteService;
  }
}
