import { Box, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';

export interface EmptyNotePlaceholderBoxProps {
  sx?: SxProps;
  className?: string;
}

export const EmptyNotePlaceholderBox = observer((props: EmptyNotePlaceholderBoxProps) => {
  const { sx, className } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: theme.studyo.contents.icons.notesBackgroundColor
      }}
      className={className}
    />
  );
});
