import { Stack, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface BottomTabBarProps {
  sx?: SxProps;
  className?: string;
  children?: React.ReactNode;
}

export const BottomTabBar = observer((props: BottomTabBarProps) => {
  const { children, sx, className } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{
        ...sx,
        backgroundColor: theme.studyo.navigation.bottomTabBarBackgroundColor,
        borderColor: theme.studyo.navigation.separatorColor,
        borderWidth: 0,
        borderTopWidth: 0.5,
        borderStyle: 'solid'
      }}
      className={className}
      alignItems="center"
      spacing={{ xs: 1, md: 0 }}
      justifyContent={{ xs: 'stretch', sm: 'center' }}
    >
      {children}
    </Stack>
  );
});
