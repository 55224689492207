import { computed, makeObservable } from 'mobx';
import { AccountService, NavigationService, StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../services';

export interface PreparingSchoolViewModel {
  readonly schoolName: string;
  readonly configId: string | undefined;
  readonly accountId: string | undefined;

  viewProfiles: () => Promise<void>;
  logOut: () => Promise<void>;
}

export class AppPreparingSchoolViewModel implements PreparingSchoolViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService
  ) {
    makeObservable(this);
  }

  @computed
  get schoolName() {
    return this._accountService.currentDisplayedConfiguration?.schoolName ?? '';
  }

  @computed
  get configId() {
    return this._accountService.currentDisplayedConfiguration?.id;
  }

  @computed
  get accountId() {
    return this._accountService.currentDisplayedAccount?.id;
  }

  async viewProfiles() {
    await this._navigationService.navigateToOtherProfilesListModal();
  }

  async logOut() {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.logout });
    await this._accountService.logout();
  }
}
