import { SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';

export interface PeriodPrioritySelectorElementViewModel {
  isSelected: boolean;
  title: string;
  subtitle: string | undefined;
  periodTag: string;
  color: Color | undefined;
  isSkipped: boolean;
  onPress: () => void;
}

export class AppPeriodPrioritySelectorElementViewModel implements PeriodPrioritySelectorElementViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _data: AccountData,
    private readonly _section: SectionModel | undefined,
    public readonly isSelected: boolean,
    public readonly periodTag: string,
    public readonly isSkipped: boolean,
    public readonly onPress: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get title() {
    return (
      this._section?.title ??
      this._localizationService.localizedStrings.studyo.utils.periodPrioritySelector.freePeriodTitle
    );
  }

  @computed
  get subtitle(): string | undefined {
    return this._section?.sectionNumber;
  }

  @computed
  get color(): Color | undefined {
    return SectionUtils.getSectionColor(this._section, this._data.account, undefined);
  }
}
