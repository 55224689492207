import { Locale, LocalizationService } from '@shared/resources/services';
import { EnvironmentService } from '@shared/services';
import { getYear } from 'date-fns';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Location, NavigateFunction } from 'react-router-dom';
import { RouteTemplates } from '../Routes.ts';
import { AccountService, NavigationService, UISettingsStore } from '../services';
import { StudyoAnalyticsEventActions, StudyoAnalyticsService } from '../services/analytics';

export interface LoginViewModel {
  readonly authInitErrorMessage?: string;
  readonly hasAcceptedTerms: boolean;
  readonly showPrivacyAlert: boolean;
  readonly isSigningUp: boolean;
  readonly isLoggingIn: boolean;
  readonly hasError: boolean;
  readonly versionNumber: string;
  readonly copyright: string;

  onInit(location: Location, navigate: NavigateFunction): Promise<void>;
  setHasAcceptedTerms(value: boolean): void;
  changeLanguage: () => void;
  signUp: (location: Location, navigate: NavigateFunction) => Promise<void>;
  login: (location: Location, navigate: NavigateFunction) => Promise<void>;
  resetError: () => void;
}

export class AppLoginViewModel implements LoginViewModel {
  @observable private _hasAcceptedTerms = false;
  @observable private _showPrivacyAlert = false;
  @observable private _isSigningUp = false;
  @observable private _isLoggingIn = false;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _environmentService: EnvironmentService,
    private readonly _uiSettingsStore: UISettingsStore
  ) {
    makeObservable(this);
  }

  async onInit(location: Location, navigate: NavigateFunction) {
    try {
      if (this._uiSettingsStore.isCompletingLogin) {
        runInAction(() => (this._isLoggingIn = true));

        // Complete the login process
        let result = await this._accountService.completeLogin();

        if (result.success) {
          // If successful, this will navigate the user to the proper pager.
          this._uiSettingsStore.isCompletingLogin = false;
          await this._navigationService.completeLogin(location, navigate, result.referrer);
        } else {
          // An error can occur when the user closes the login popup after he has
          // successfully logged in but still in the process (e.g. waiting for email
          // confirmation). In that case, `login()` reports an error (the popup was closed
          // but the callback url was not invoked, so we did not receive auth tokens),
          // but the user is actually logged in. Initiating a silent sign-in flow will
          // allow us to proceed with the login without having to display "an error has occurred"
          // to the user.
          await this._accountService.startSilentLogin();
          result = { ...result, success: this._accountService.isLoggedIn };

          if (result.success) {
            this._uiSettingsStore.isCompletingLogin = false;
            await this._navigationService.completeLogin(location, navigate, result.referrer);
          } else {
            // An error occurred. Set proper state and redirect to the login
            // page (without any authentication information)
            runInAction(() => {
              this._uiSettingsStore.hasLoginError = true;
              this._isLoggingIn = false;
            });

            this._uiSettingsStore.isCompletingLogin = false;
            navigate(RouteTemplates.login, { replace: true });
          }
        }
      } else {
        // "Normal" login flow. Redirect the user if he is already logged in.
        await this.redirectIfAlreadyLoggedIn(location, navigate);
      }
    } catch (e) {
      console.error(e);
    }
  }

  @computed
  get authInitErrorMessage(): string | undefined {
    return this._accountService.authInitErrorMessage;
  }

  @computed
  get hasAcceptedTerms(): boolean {
    return this._hasAcceptedTerms;
  }

  @action
  setHasAcceptedTerms(value: boolean) {
    this._hasAcceptedTerms = value;
  }

  @computed
  get showPrivacyAlert(): boolean {
    return this._showPrivacyAlert;
  }

  @action
  setShowPrivacyAlert(value: boolean) {
    this._showPrivacyAlert = value;
  }

  @computed
  get isSigningUp(): boolean {
    return this._isSigningUp || this._accountService.isLoggingIn;
  }

  @computed
  get isLoggingIn(): boolean {
    return this._isLoggingIn || this._accountService.isLoggingIn;
  }

  @computed
  get hasError(): boolean {
    return this._uiSettingsStore.hasLoginError;
  }

  @computed
  get versionNumber() {
    return this._environmentService.formattedVersionNumber;
  }

  @computed
  get copyright() {
    return this._localizationService.localizedStrings.studyo.copyright(getYear(new Date()));
  }

  async signUp(location: Location, navigate: NavigateFunction) {
    if (!this._hasAcceptedTerms) {
      this.setShowPrivacyAlert(true);
      return;
    }

    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.signup });

    try {
      runInAction(() => {
        this._isSigningUp = true;
        this._uiSettingsStore.hasLoginError = false;
      });

      const referrer = this._navigationService.extractReferrer(location);
      const result = await this._accountService.login(referrer);

      runInAction(() => {
        this._isSigningUp = false;
      });

      if (result) {
        await this._navigationService.redirectToReferrerOrLanding(location, navigate);
      }
    } catch (e) {
      runInAction(() => {
        this._isSigningUp = false;
        this._uiSettingsStore.hasLoginError = true;
      });
    }
  }

  async login(location: Location, navigate: NavigateFunction): Promise<void> {
    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.authentication.login });

    try {
      runInAction(() => {
        this._isLoggingIn = true;
        this._uiSettingsStore.hasLoginError = false;
      });

      const referrer = this._navigationService.extractReferrer(location);
      const result = await this._accountService.login(referrer);

      runInAction(() => {
        this._isLoggingIn = false;
      });

      if (result) {
        await this._navigationService.redirectToReferrerOrLanding(location, navigate);
      }
    } catch (e) {
      runInAction(() => {
        this._isLoggingIn = false;
        this._uiSettingsStore.hasLoginError = true;
      });
    }
  }

  async redirectIfAlreadyLoggedIn(location: Location, navigate: NavigateFunction): Promise<void> {
    if (this._accountService.isLoggedIn) {
      await this._navigationService.redirectToReferrerOrLanding(location, navigate);
    }
  }

  resetError() {
    this.setHasError(false);
  }

  changeLanguage() {
    const newLocale: Locale = this._localizationService.currentLocale === 'en' ? 'fr' : 'en';
    this._localizationService.setCurrentLocale(newLocale);
  }

  @action
  private setHasError(hasError: boolean) {
    this._uiSettingsStore.hasLoginError = hasError;
  }
}
