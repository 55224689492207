import { SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { ImpersonateStudentSelectionView } from '../../../views';

export interface ImpersonateStudentSelectionScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const ImpersonateStudentSelectionScreen = observer((props: ImpersonateStudentSelectionScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onSuccess, onCancel } = props;
  const viewModel = useMemo(() => viewModelFactory.createImpersonateStudentSelection(onSuccess!, onCancel!), []);
  const strings = localizationService.localizedStrings.studyo.agenda.setting.impersonateStudentSelection;

  const close = () => {
    viewModel.close();
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={close}
    >
      <DialogHeader title={strings.title} onClose={close} />

      <ImpersonateStudentSelectionView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons minimumScreenSize="sm">
        <DialogButton title={strings.close} type="normal" onPress={close} />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
