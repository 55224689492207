import { Box, Divider, Stack, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { WeeklyViewModel } from '../../../viewmodels';
import { DayAndWeekConstants } from '../shared';
import { WeeklyDayHeader } from '../weekly/WeeklyDayHeader';

export interface SchoolDayHeaderRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: WeeklyViewModel;
}

export const SchoolDayHeaderRow = observer((props: SchoolDayHeaderRowProps) => {
  const { sx, className, viewModel } = props;
  const theme = useTheme();
  const backgroundColor = theme.studyo.agenda.header.dateRowBackgroundColor;

  return (
    <Stack sx={sx} className={className}>
      <Stack direction="row" sx={{ backgroundColor }} justifyContent="space-between" overflow="hidden">
        <Box
          sx={{
            backgroundColor,
            width: DayAndWeekConstants.timesColumnWidth + DayAndWeekConstants.itemSpacing * 2,
            flexShrink: 0
          }}
        />
        {viewModel.currentDays.map((sd, i) => (
          <WeeklyDayHeader
            key={'header-' + i}
            viewModel={sd.weeklyDayHeaderViewModel}
            sx={{ backgroundColor, flexBasis: 0, flexGrow: 1, flexShrink: 0, ml: i > 0 ? 1 : 0, mr: 1 }}
            contentBoxViewModel={sd.dayContentsViewModel}
          />
        ))}
      </Stack>
      <Divider />
    </Stack>
  );
});
