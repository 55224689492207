import { css } from '@emotion/css';
import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ObserverAutoSizer } from '@shared/components/layout';
import { DateUtils } from '@shared/components/utils';
import {
  AgendaTimelineCollectionViewViewModel,
  CollectionViewItemPadding,
  LargeContentLineHeight,
  LargeSingleDayWidth,
  SectionHeaderWidth,
  SingleDayWidth,
  StandardContentLineHeight
} from '@studyo/viewmodels';
import { times } from 'lodash';
import { observer } from 'mobx-react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TimelineCollectionViewItem } from './TimelineCollectionViewItem';
import { TimelineCollectionViewSchoolDayHeader } from './TimelineCollectionViewSchoolDayHeader';
import { TimelineCollectionViewSectionHeader } from './TimelineCollectionViewSectionHeader';

export interface AgendaTimelineCollectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaTimelineCollectionViewViewModel;
}

export const AgendaTimelineCollectionView = observer((props: AgendaTimelineCollectionViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();

  const renderSchoolDayHeaderViews = (startIndex: number, dayWidth: number, numberOfDays: number) => {
    return [
      <Box key={`timeline-schoolday-header-0`} sx={{ width: SectionHeaderWidth, flexShrink: 0 }} />,

      ...times(numberOfDays).map((i) => (
        <TimelineCollectionViewSchoolDayHeader
          key={`timeline-schoolday-header-${i + 1}`}
          viewModel={viewModel.schoolDayViewModelAtIndex(i + startIndex)}
          itemWidth={dayWidth}
          sx={{ flexShrink: 0 }}
        />
      ))
    ];
  };

  const renderSectionHeaderViews = () => {
    return viewModel.sections.map((element, i) => (
      <TimelineCollectionViewSectionHeader
        sx={{ zIndex: 5, flexShrink: 0 }}
        key={`timeline-section-header-${i}`}
        viewModel={element}
      />
    ));
  };

  const renderDayViews = (startIndex: number, dayWidth: number, numberOfDays: number, height: number) => {
    const dayViewClassName = css({
      width: dayWidth,
      height: height,
      flexShrink: 0,
      position: 'relative'
    });

    return times(numberOfDays).map((i) => {
      let backgroundColor = theme.studyo.agenda.timeline.collectionViewDayViewBackgroundColor;
      let backgroundColorFadeKind: 'big' | 'small' | 'none' = 'none';

      const schoolDay = viewModel.schoolDayAtIndex(i + startIndex);

      if (schoolDay != null) {
        if (DateUtils.isToday(schoolDay.day)) {
          backgroundColor = theme.studyo.todaySymbolBackgroundColor;
          backgroundColorFadeKind = 'big';
        } else if (DateUtils.isWeekend(schoolDay.day)) {
          backgroundColorFadeKind = 'small';
        }
      }

      const backgroundColorClassName = css({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: backgroundColor,
        opacity: backgroundColorFadeKind === 'big' ? 0.15 : backgroundColorFadeKind === 'small' ? 0.6 : 1
      });

      return (
        <Box key={`timeline-day-view-${i}`} className={dayViewClassName}>
          <Box className={backgroundColorClassName} />
          {/* Not using a border on the root box, as Windows renders them smaller than 1px.
              Not using a border on the background box, as its opacity changes.
              Not using an actual 1px separator, as this is too strong. */}
          <Box
            sx={{
              position: 'relative',
              width: '0.5px',
              height: height,
              right: 0,
              backgroundColor: theme.studyo.agenda.timeline.collectionViewSeparatorColor
            }}
          />
        </Box>
      );
    });
  };

  const renderContentItems = (dayWidth: number, lineHeight: number) => {
    return viewModel.contentItems.map((element, i) => {
      const leftIndex = element.startColumnIndex - viewModel.startDayIndex;

      return (
        <TimelineCollectionViewItem
          key={`timeline-collectionview-item-${i}`}
          sx={{
            position: 'absolute',
            top: element.rowIndex * lineHeight + CollectionViewItemPadding + lineHeight / 2,
            left: leftIndex * dayWidth + SectionHeaderWidth,
            zIndex: 3
          }}
          viewModel={element.vm}
          itemWidth={dayWidth}
          itemHeight={lineHeight}
        />
      );
    });
  };

  return (
    <Box
      sx={{ ...sx, backgroundColor: theme.studyo.agenda.timeline.listBackgroundColor }}
      className={className}
      display="flex"
      overflow="hidden"
    >
      <ObserverAutoSizer>
        {({ width, height }) => {
          const dayColumnsWidth = width - SectionHeaderWidth;
          const dayWidth = viewModel.cellSize === 'large' ? LargeSingleDayWidth : SingleDayWidth;
          const numberOfDays = Math.floor(dayColumnsWidth / dayWidth) + 1;
          viewModel.setCalculatedNumberOfDays(numberOfDays);

          return (
            <Stack sx={{ width, height, overflow: 'hidden' }}>
              {viewModel.maxRowIndex !== -1 && (
                <Stack flex={1} overflow="hidden">
                  <Stack direction="row" overflow="hidden">
                    {renderSchoolDayHeaderViews(viewModel.startDayIndex, dayWidth, numberOfDays)}
                  </Stack>

                  <Box flex={1}>
                    <ObserverAutoSizer>
                      {(bounds) => {
                        const lineHeight =
                          viewModel.cellSize === 'large' ? LargeContentLineHeight : StandardContentLineHeight;
                        const contentHeight = (viewModel.maxRowIndex + 2) * lineHeight;
                        const maxHeight = Math.max(contentHeight, bounds.height);
                        const marginTop = lineHeight / 2;

                        return (
                          <Box
                            sx={{
                              overflowX: 'hidden',
                              overflowY: 'auto',
                              display: 'flex',
                              position: 'relative'
                            }}
                            ref={(ref) => viewModel.setScrollViewReference(ref as HTMLDivElement | null)}
                          >
                            <Stack width={SectionHeaderWidth} flexShrink={0}>
                              <Box sx={{ backgroundColor: 'transparent', height: marginTop, flexShrink: 0 }} />
                              {renderSectionHeaderViews()}
                              <Box sx={{ backgroundColor: 'transparent', height: marginTop, flexShrink: 0 }} />
                            </Stack>

                            {renderDayViews(viewModel.startDayIndex, dayWidth, numberOfDays, maxHeight)}
                            {renderContentItems(dayWidth, lineHeight)}
                          </Box>
                        );
                      }}
                    </ObserverAutoSizer>
                  </Box>
                </Stack>
              )}

              {viewModel.maxRowIndex === -1 && (
                <Stack flex={1} alignItems="center" justifyContent="center">
                  <Typography>
                    {localizationService.localizedStrings.studyo.agenda.timeline.noRemainingTasks}
                  </Typography>
                </Stack>
              )}
            </Stack>
          );
        }}
      </ObserverAutoSizer>
    </Box>
  );
});
