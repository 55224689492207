import {
  Account,
  AccountModel,
  AccountSummary,
  OnboardingCodeValidation,
  OnboardingCodeValidationModel,
  SchoolYearConfiguration,
  SchoolYearConfigurationModel,
  SchoolYearConfigurationSummary,
  SectionModel
} from '@shared/models/config';
import { Role } from '@shared/models/types';
import { onboardingCodeKindFromProtobuf } from '@shared/models/types/EnumConversion';
import _, { chain, flatMap, keyBy, unionWith, uniqBy, uniqWith } from 'lodash';
import { IComputedValue, autorun, computed, makeObservable } from 'mobx';
import { ContentTransport, SchoolTransport } from '../../transports';
import { SchoolYearConfigurationStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';
import { DemoSchoolInterceptor } from './DemoSchoolInterceptor';

export class AppSchoolYearConfigurationStore extends AppBaseStore implements SchoolYearConfigurationStore {
  private readonly _demoInterceptor?: DemoSchoolInterceptor;
  private readonly _transport: SchoolTransport;

  constructor(
    schoolTransport: SchoolTransport,
    private readonly _contentTransport: ContentTransport,
    bypassCaching?: boolean,
    private readonly _isDemoModeEnabled?: boolean,
    private readonly _isDemoMode?: () => boolean
  ) {
    super('AppSchoolYearConfigurationStore', bypassCaching);

    makeObservable(this);

    if (_isDemoModeEnabled) {
      this._demoInterceptor = new DemoSchoolInterceptor(schoolTransport, _contentTransport);
      this._transport = this._demoInterceptor;
    } else {
      this._transport = schoolTransport;
    }

    autorun(() => {
      if (this._demoInterceptor != null) {
        this._demoInterceptor.isPreventingChanges = this._isDemoMode?.() ?? false;
      }
    });
  }

  @computed
  get withoutCaching(): SchoolYearConfigurationStore {
    if (this.isCachingBypassed) {
      return this;
    }

    return new AppSchoolYearConfigurationStore(
      this._transport,
      this._contentTransport,
      true,
      this._isDemoModeEnabled,
      this._isDemoMode
    );
  }

  @computed
  get anonymizeData(): boolean {
    return this._isDemoMode?.() ?? false;
  }

  getConfig(configId: string): Promise<SchoolYearConfigurationModel> {
    return this.getMemoizedConfig(configId).get();
  }

  getConfigSummary(configId: string): Promise<SchoolYearConfigurationSummary> {
    return this.getMemoizedConfigSummary(configId).get();
  }

  getConfigs(startYear: number): Promise<SchoolYearConfigurationSummary[]> {
    return this.getMemoizedConfigs(startYear).get();
  }

  async saveConfig(config: SchoolYearConfigurationModel): Promise<SchoolYearConfigurationModel> {
    const pbConfig = await this._transport.createOrUpdateConfig(config.toProtobuf());

    this.invalidate();
    return new SchoolYearConfiguration(pbConfig);
  }

  validateConfig(configId: string): Promise<string[]> {
    return this.getMemoizedConfigValidation(configId).get();
  }

  getSection(configId: string, sectionId: string): Promise<SectionModel> {
    return this.getMemoizedSection(configId, sectionId).get();
  }

  getSections(configId: string): Promise<SectionModel[]> {
    return this.getMemoizedSections(configId).get();
  }

  getSectionsByIds(configId: string, sectionIds: string[]): Promise<SectionModel[]> {
    return this.getMemoizedSectionsByIds(configId, sectionIds).get();
  }

  getSectionsById(configId: string): Promise<Record<string, SectionModel>> {
    return this.getMemoizedSectionsById(configId).get();
  }

  async deleteAccount(account: AccountModel): Promise<void> {
    await this._transport.deleteAccount(account.toProtobuf());

    // TODO: Reloading the whole list is so wrong.
    this.invalidate();
  }

  async undeleteAccount(account: AccountModel): Promise<void> {
    await this._transport.undeleteAccount(account.toProtobuf());

    this.invalidate();
  }

  getAccounts(configId: string, includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedAccounts(configId, includeDeletedAccounts).get();
  }

  getAccountsById(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedAccountsById(configId, includeDeletedAccounts).get();
  }

  getAccount(configId: string, accountId: string, includeDeletedAccounts: boolean): Promise<AccountModel> {
    return this.getMemoizedAccount(configId, accountId, includeDeletedAccounts).get();
  }

  getAccountsForIds(configId: string, accountIds: string[], includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedAccountsForIds(configId, accountIds, includeDeletedAccounts).get();
  }

  getAccountsForRoles(configId: string, roles: Role[], includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedAccountsForRoles(configId, roles, includeDeletedAccounts).get();
  }

  async saveAccount(account: AccountModel, preventInvalidate?: boolean): Promise<AccountModel> {
    const pbAccount = await this._transport.createOrUpdateAccount(account.toProtobuf());

    if (preventInvalidate !== true) {
      this.invalidate();
    }

    return new Account(pbAccount);
  }

  async purgeDeletedAccounts(configId: string): Promise<number> {
    const count = await this._transport.purgeDeletedAccounts(configId);
    this.invalidate();

    return count;
  }

  getStudent(configId: string, studentId: string, includeDeletedAccounts: boolean): Promise<AccountModel> {
    return this.getMemoizedStudent(configId, studentId, includeDeletedAccounts).get();
  }

  getStudents(configId: string, includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedStudents(configId, includeDeletedAccounts).get();
  }

  getStudentsById(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedStudentsById(configId, includeDeletedAccounts).get();
  }

  getStudentsByManagedId(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedStudentsByManagedId(configId, includeDeletedAccounts).get();
  }

  getStudentsByEmail(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedStudentsByEmail(configId, includeDeletedAccounts).get();
  }

  getStudentsForGradeLevel(
    configId: string,
    gradeLevel: string,
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedStudentsForGradeLevel(configId, gradeLevel, includeDeletedAccounts).get();
  }

  getStudentsForSection(
    configId: string,
    section: SectionModel,
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedStudentsForSection(configId, section, includeDeletedAccounts).get();
  }

  getStudentsForSectionId(
    configId: string,
    sectionId: string,
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedStudentsForSectionId(configId, sectionId, includeDeletedAccounts).get();
  }

  getStudentsForSections(
    configId: string,
    sections: SectionModel[],
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedStudentsForSections(configId, sections, includeDeletedAccounts).get();
  }

  getStudentsForSectionIds(
    configId: string,
    sectionIds: string[],
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedStudentsForSectionIds(configId, sectionIds, includeDeletedAccounts).get();
  }

  getSectionsGradeLevels(configId: string): Promise<string[]> {
    return this.getMemoizedSectionsGradeLevels(configId).get();
  }

  getStudentsGradeLevels(configId: string, includeDeletedAccounts: boolean): Promise<string[]> {
    return this.getMemoizedStudentsGradeLevels(configId, includeDeletedAccounts).get();
  }

  getTeacher(configId: string, teacherId: string, includeDeletedAccounts: boolean): Promise<AccountModel> {
    return this.getMemoizedTeacher(configId, teacherId, includeDeletedAccounts).get();
  }

  getTeachers(configId: string, includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedTeachers(configId, includeDeletedAccounts).get();
  }

  getTeachersById(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedTeachersById(configId, includeDeletedAccounts).get();
  }

  getTeachersForSection(
    configId: string,
    section: SectionModel,
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedTeachersForSection(configId, section, includeDeletedAccounts).get();
  }

  getTeachersForSectionId(
    configId: string,
    sectionId: string,
    includeDeletedAccounts: boolean
  ): Promise<AccountModel[]> {
    return this.getMemoizedTeachersForSectionId(configId, sectionId, includeDeletedAccounts).get();
  }

  getTeachersAndStaff(configId: string, includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedTeachersAndStaff(configId, includeDeletedAccounts).get();
  }

  getTeachersAndStaffById(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel>> {
    return this.getMemoizedTeachersAndStaffById(configId, includeDeletedAccounts).get();
  }

  getParents(configId: string, includeDeletedAccounts: boolean): Promise<AccountModel[]> {
    return this.getMemoizedParents(configId, includeDeletedAccounts).get();
  }

  getParentsByChildId(configId: string, includeDeletedAccounts: boolean): Promise<Record<string, AccountModel[]>> {
    return this.getMemoizedParentsByChildId(configId, includeDeletedAccounts).get();
  }

  getSectionsForTeacher(configId: string, teacher: AccountModel): Promise<SectionModel[]> {
    return this.getMemoizedSectionsForTeacher(configId, teacher).get();
  }

  getTaughtSectionsForTeacher(configId: string, teacher: AccountModel): Promise<SectionModel[]> {
    return this.getMemoizedTaughtSectionsForTeacher(configId, teacher).get();
  }

  getTaughtSectionsForTeacherId(configId: string, teacherId: string): Promise<SectionModel[]> {
    return this.getMemoizedTaughtSectionsForTeacherId(configId, teacherId).get();
  }

  getSectionsForStudent(
    configId: string,
    student: AccountModel,
    includeAutoEnrolledSections?: boolean
  ): Promise<SectionModel[]> {
    return this.getMemoizedSectionsForStudent(configId, student, includeAutoEnrolledSections).get();
  }

  getSectionsForStudentId(configId: string, studentId: string): Promise<SectionModel[]> {
    return this.getMemoizedSectionsForStudentId(configId, studentId, false).get();
  }

  getAutoEnrolledSectionsForStudent(configId: string, student: AccountModel): Promise<SectionModel[]> {
    return this.getMemoizedAutoEnrolledSectionsForStudent(configId, student).get();
  }

  getAutoEnrolledSectionsForTeacher(configId: string, teacher: AccountModel): Promise<SectionModel[]> {
    return this.getMemoizedAutoEnrolledSectionsForTeacher(configId, teacher).get();
  }

  getSectionsForAccount(configId: string, account: AccountModel): Promise<SectionModel[]> {
    return this.getMemoizedSectionsForAccount(configId, account).get();
  }

  getSectionsForAccountId(configId: string, accountId: string): Promise<SectionModel[]> {
    return this.getMemoizedSectionsForAccountId(configId, accountId).get();
  }

  getSectionsByIdForAccount(configId: string, account: AccountModel): Promise<Record<string, SectionModel>> {
    return this.getMemoizedSectionsByIdForAccount(configId, account).get();
  }

  getSectionsByIdForAccountId(configId: string, accountId: string): Promise<Record<string, SectionModel>> {
    return this.getMemoizedSectionsByIdForAccountId(configId, accountId).get();
  }

  async useOnboardingCode(code: string): Promise<AccountSummary | undefined> {
    const pb = await this._transport.useOnboardingCode(code);
    if (!pb.codeIsValid) {
      return undefined;
    }
    const pbAccount = pb.accountSummary;
    if (pbAccount == null) {
      throw new Error('Unexpected result from backend: A UseOnboardingCore request did not return an AccountSummary.');
    }
    return new AccountSummary(pbAccount);
  }

  async validateOnboardingCode(code: string): Promise<OnboardingCodeValidationModel> {
    const pb = await this._transport.validateOnboardingCode(code);
    return new OnboardingCodeValidation(pb.codeIsValid, onboardingCodeKindFromProtobuf(pb.codeKind));
  }

  async clearCachedConfig(configId: string): Promise<void> {
    await this._transport.invalidateCachedConfig(configId);
  }

  private getMemoizedConfig = this.memoize(
    (configId: string): IComputedValue<Promise<SchoolYearConfigurationModel>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbConfig = await this._transport.fetchConfig(configId, this.anonymizeData);

            if (pbConfig == null) {
              throw new Error(
                'Unexpected result from backend: A GetConfig request without a sync token did not return a configuration.'
              );
            }

            return new SchoolYearConfiguration(pbConfig);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedConfigSummary = this.memoize(
    (configId: string): IComputedValue<Promise<SchoolYearConfigurationSummary>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbConfig = await this._transport.fetchConfigSummary(configId, this.anonymizeData);
            return new SchoolYearConfigurationSummary(pbConfig);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedConfigs = this.memoize(
    (startYear: number): IComputedValue<Promise<SchoolYearConfigurationSummary[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const configsResult = await this._transport.fetchConfigs(startYear);
            return configsResult.result.map((c) => new SchoolYearConfigurationSummary(c));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedSection = this.memoize(
    (configId: string, sectionId: string): IComputedValue<Promise<SectionModel>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const sectionsById = await this.getSectionsById(configId);
          return sectionsById[sectionId];
        })
      )
  );

  private getMemoizedSections = this.memoize(
    (configId: string): IComputedValue<Promise<SectionModel[]>> =>
      computed(() => this.withInvalidate(() => this.getConfig(configId).then((config) => config.sections)))
  );

  private getMemoizedSectionsByIds = this.memoize(
    (configId: string, sectionIds: string[]): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const sectionsById = await this.getSectionsById(configId);

          return chain(sectionIds)
            .uniq()
            .map((id) => sectionsById[id])
            .compact()
            .value();
        })
      )
  );

  private getMemoizedSectionsById = this.memoize(
    (configId: string): IComputedValue<Promise<Record<string, SectionModel>>> =>
      computed(() =>
        this.withInvalidate(() => this.getSections(configId).then((sections) => keyBy(sections, (s) => s.id)))
      )
  );

  private getMemoizedConfigValidation = this.memoize(
    (configId: string): IComputedValue<Promise<string[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            return await this._transport.validateConfig(configId);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedAccounts = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const accountsResult = await this._transport.fetchAccounts(configId, this.anonymizeData);
            return includeDeletedAccounts
              ? accountsResult.result.map((a) => new Account(a))
              : chain(accountsResult.result)
                  .filter((a) => !a.isDeleted)
                  .map((a) => new Account(a))
                  .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedAccount = this.memoize(
    (congigId: string, accountId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const accounts = await this.getAccountsById(congigId, includeDeletedAccounts);
          return accounts[accountId];
        })
      )
  );

  private getMemoizedAccountsForIds = this.memoize(
    (
      congigId: string,
      accountIds: string[],
      includeDeletedAccounts: boolean
    ): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const accounts = await this.getAccountsById(congigId, includeDeletedAccounts);
          return chain(accountIds)
            .uniq()
            .map((id) => accounts[id])
            .compact()
            .value();
        })
      )
  );

  private getMemoizedAccountsForRoles = this.memoize(
    (congigId: string, roles: Role[], includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const rolesSet = new Set(roles);
          const accounts = await this.getAccounts(congigId, includeDeletedAccounts);
          return accounts.filter((account) => rolesSet.has(account.role));
        })
      )
  );

  private getMemoizedAccountsById = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccounts(configId, includeDeletedAccounts).then((accounts) => keyBy(accounts, (a) => a.id))
        )
      )
  );

  private getMemoizedStudent = this.memoize(
    (configId: string, studentId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccountsById(configId, includeDeletedAccounts).then((accounts) => accounts[studentId])
        )
      )
  );

  private getMemoizedStudents = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccounts(configId, includeDeletedAccounts).then((accounts) =>
            accounts.filter((a) => a.role === 'student')
          )
        )
      )
  );

  private getMemoizedStudentsById = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getStudents(configId, includeDeletedAccounts).then((accounts) => keyBy(accounts, (a) => a.id))
        )
      )
  );

  private getMemoizedStudentsByManagedId = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getStudents(configId, includeDeletedAccounts).then((accounts) =>
            keyBy(
              // Should never happen, but in case.
              uniqBy(accounts, (a) => a.managedIdentifier),
              (a) => a.managedIdentifier
            )
          )
        )
      )
  );

  private getMemoizedStudentsByEmail = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getStudents(configId, includeDeletedAccounts).then((accounts) =>
            keyBy(
              // Should rarely happen, but be safe.
              uniqBy(accounts, (a) => a.email),
              (a) => a.email
            )
          )
        )
      )
  );

  private getMemoizedStudentsForGradeLevel = this.memoize(
    (configId: string, gradeLevel: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const [sectionsById, students] = await Promise.all([
            this.getSectionsById(configId),
            this.getStudents(configId, includeDeletedAccounts)
          ]);

          return chain(students)
            .filter((student) => {
              // Compute the count of sections by grade level
              const sectionCountByGradeLevel = chain(student.selectedSectionIds)
                .map((sectionId) => sectionsById[sectionId]?.gradeLevel)
                .countBy((sectionGradeLevel) => sectionGradeLevel)
                .value();

              // Compute the grade level of a student. We take the grade level
              // where the student have the higher section count.
              const studentComputedGradeLevel = chain(Object.entries(sectionCountByGradeLevel))
                .maxBy(([, count]) => count)
                .value();

              return studentComputedGradeLevel?.[0] === gradeLevel;
            })
            .value();
        })
      )
  );

  private getMemoizedStudentsForSection = this.memoize(
    (
      configId: string,
      section: SectionModel,
      includeDeletedAccounts: boolean
    ): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const students = await this.getStudents(configId, includeDeletedAccounts);

          if (section.autoEnrollRoles.indexOf('student') !== -1) {
            return students;
          }

          const autoEnrollGrades = new Set(
            section.autoEnrollTags
              .map((tag) => tag.split('='))
              .filter((pair) => pair[0] === 'gradeLevel')
              .map((pair) => pair[1])
          );

          return students.filter(
            (student) =>
              autoEnrollGrades.has(student.gradeLevel) || student.selectedSectionIds.indexOf(section.id) !== -1
          );
        })
      ),
    { isDeepEqual: true }
  );

  private getMemoizedStudentsForSections = this.memoize(
    (
      configId: string,
      sections: SectionModel[],
      includeDeletedAccounts: boolean
    ): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const students = flatMap(
            await Promise.all(
              sections.map(
                async (section) => await this.getStudentsForSection(configId, section, includeDeletedAccounts)
              )
            )
          );

          return uniqBy(students, (student) => student.id);
        })
      ),
    { isDeepEqual: true }
  );

  private getMemoizedStudentsForSectionId = this.memoize(
    (configId: string, sectionId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const section = await this.getSection(configId, sectionId);
          return this.getStudentsForSection(configId, section, includeDeletedAccounts);
        })
      )
  );

  private getMemoizedStudentsForSectionIds = this.memoize(
    (
      configId: string,
      sectionIds: string[],
      includeDeletedAccounts: boolean
    ): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const sections = await this.getSectionsByIds(configId, sectionIds);
          return this.getStudentsForSections(configId, sections, includeDeletedAccounts);
        })
      )
  );

  private getMemoizedSectionsGradeLevels = this.memoize(
    (configId: string): IComputedValue<Promise<string[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getSections(configId).then((sections) =>
            _.chain(sections)
              .map((section) => section.gradeLevel.toUpperCase())
              .uniq()
              .compact()
              .orderBy((gradeLevel) => gradeLevel)
              .value()
          )
        )
      )
  );

  private getMemoizedStudentsGradeLevels = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<string[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getStudents(configId, includeDeletedAccounts).then((students) =>
            _.chain(students)
              .map((student) => student.gradeLevel.toUpperCase())
              .uniq()
              .compact()
              .orderBy((gradeLevel) => gradeLevel)
              .value()
          )
        )
      )
  );

  private getMemoizedTeacher = this.memoize(
    (configId: string, teacherId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccountsById(configId, includeDeletedAccounts).then((accounts) => accounts[teacherId])
        )
      )
  );

  private getMemoizedTeachers = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccounts(configId, includeDeletedAccounts).then((accounts) =>
            accounts.filter((a) => a.role === 'teacher')
          )
        )
      )
  );

  private getMemoizedTeachersById = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getTeachers(configId, includeDeletedAccounts).then((accounts) => keyBy(accounts, (a) => a.id))
        )
      )
  );

  private getMemoizedTeachersForSection = this.memoize(
    (
      configId: string,
      section: SectionModel,
      includeDeletedAccounts: boolean
    ): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const teachers = await this.getTeachers(configId, includeDeletedAccounts);

          return teachers.filter((teacher) => section.teacherIds.find((teacherId) => teacherId === teacher.id) != null);
        })
      )
  );

  private getMemoizedTeachersForSectionId = this.memoize(
    (configId: string, sectionId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const section = await this.getSection(configId, sectionId);
          return this.getTeachersForSection(configId, section, includeDeletedAccounts);
        })
      )
  );

  private getMemoizedTeachersAndStaff = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccounts(configId, includeDeletedAccounts).then((accounts) =>
            accounts.filter((a) => ['teacher', 'school-staff', 'studyo-staff'].includes(a.role))
          )
        )
      )
  );

  private getMemoizedTeachersAndStaffById = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getTeachersAndStaff(configId, includeDeletedAccounts).then((accounts) => keyBy(accounts, (a) => a.id))
        )
      )
  );
  private getMemoizedParents = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<AccountModel[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getAccounts(configId, includeDeletedAccounts).then((accounts) =>
            accounts.filter((a) => a.role === 'parent')
          )
        )
      )
  );

  private getMemoizedParentsByChildId = this.memoize(
    (configId: string, includeDeletedAccounts: boolean): IComputedValue<Promise<Record<string, AccountModel[]>>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const parents = await this.getParents(configId, includeDeletedAccounts);
          const children = _.flatten(
            parents.map((parent) =>
              parent.childrenAccountIds
                .concat(parent.childrenAccountPendingVerificationIds)
                .map((id) => ({ childId: id, parent }))
            )
          );
          const parentInfosByChildId = _.groupBy(children, (child) => child.childId);

          const parentsByChildId: Record<string, AccountModel[]> = {};

          Object.keys(parentInfosByChildId).forEach(
            (childId) => (parentsByChildId[childId] = parentInfosByChildId[childId].map((child) => child.parent))
          );

          return parentsByChildId;
        })
      )
  );

  private getMemoizedSectionsForTeacher = this.memoize(
    (configId: string, teacher: AccountModel): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const taughtSections = await this.getTaughtSectionsForTeacherId(configId, teacher.id);
          const sectionsById = await this.getSectionsById(configId);
          const selectedSections = chain(teacher.selectedSectionIds)
            .uniq()
            .map((sectionId) => sectionsById[sectionId])
            .compact()
            .value();

          const allSections = taughtSections;
          allSections.push(...selectedSections);
          return uniqWith(allSections, (a: SectionModel, b: SectionModel) => a.id === b.id);
        })
      )
  );

  private getMemoizedTaughtSectionsForTeacher = this.memoize(
    (configId: string, teacher: AccountModel): IComputedValue<Promise<SectionModel[]>> =>
      computed(() => this.withInvalidate(() => this.getTaughtSectionsForTeacherId(configId, teacher.id)))
  );

  private getMemoizedTaughtSectionsForTeacherId = this.memoize(
    (configId: string, teacherId: string): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const sections = await this.getSections(configId);
          return sections.filter(
            (section) =>
              section.defaultTeacherId === teacherId ||
              section.schedules.filter((schedule) => schedule.teacherIds.indexOf(teacherId) !== -1).length > 0
          );
        })
      )
  );

  private getMemoizedSectionsForStudent = this.memoize(
    (
      configId: string,
      student: AccountModel,
      includeAutoEnrolledSections?: boolean
    ): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const sectionsById = await this.getSectionsById(configId);
          const selectedSections = chain(student.selectedSectionIds)
            .uniq()
            .map((sectionId) => sectionsById[sectionId])
            .compact()
            .value();
          const applicableTags = student.applicableAutoEnrollTags;
          const autoEnrolledSections = includeAutoEnrolledSections
            ? (await this.getSections(configId)).filter(
                (section) =>
                  section.autoEnrollRoles.find((r) => r === 'student') != null ||
                  applicableTags.includes(`gradeLevel=${section.gradeLevel}`)
              )
            : [];

          return unionWith(selectedSections, autoEnrolledSections, (a, b) => a.id === b.id);
        })
      )
  );

  private getMemoizedAutoEnrolledSectionsForStudent = this.memoize(
    (configId: string, student: AccountModel): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const applicableTags = student.applicableAutoEnrollTags;
          return (await this.getSections(configId)).filter(
            (section) =>
              section.autoEnrollRoles.find((r) => r === 'student') != null ||
              section.autoEnrollTags.find((tag) => applicableTags.includes(tag))
          );
        })
      )
  );

  private getMemoizedAutoEnrolledSectionsForTeacher = this.memoize(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (configId: string, student: AccountModel): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          return (await this.getSections(configId)).filter(
            (section) => section.autoEnrollRoles.find((r) => r === 'teacher') != null
          );
        })
      )
  );

  private getMemoizedSectionsForStudentId = this.memoize(
    (
      configId: string,
      studentId: string,
      includeAutoEnrolledSections?: boolean
    ): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const accountsById = await this.getAccountsById(configId, true);
          const student = accountsById[studentId];

          return await this.getSectionsForStudent(configId, student, includeAutoEnrolledSections);
        })
      )
  );

  private getMemoizedSectionsForAccount = this.memoize(
    (configId: string, account: AccountModel): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          let sections: SectionModel[] = [];

          if (account.role === 'student') {
            sections = await this.getSectionsForStudent(configId, account);
          } else if (account.role === 'teacher') {
            sections = await this.getSectionsForTeacher(configId, account);
          } else if (account.role === 'individual') {
            sections = await this.getSections(configId);
          }

          return sections;
        })
      )
  );

  private getMemoizedSectionsForAccountId = this.memoize(
    (configId: string, accountId: string): IComputedValue<Promise<SectionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const account = await this.getAccount(configId, accountId, true);
          return await this.getSectionsForAccount(configId, account);
        })
      )
  );

  private getMemoizedSectionsByIdForAccount = this.memoize(
    (configId: string, account: AccountModel): IComputedValue<Promise<Record<string, SectionModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getSectionsForAccount(configId, account).then((sections) => keyBy(sections, (s) => s.id))
        )
      )
  );

  private getMemoizedSectionsByIdForAccountId = this.memoize(
    (configId: string, accountId: string): IComputedValue<Promise<Record<string, SectionModel>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getSectionsForAccountId(configId, accountId).then((sections) => keyBy(sections, (s) => s.id))
        )
      )
  );
}
