import {
  ContentDefinitionModel,
  ContentPublishTarget,
  EditableContentDefinition,
  EditableContentPublishTarget
} from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { NavigationService } from '../../../services';

export interface LinkedTasksPublishOccurrenceViewModel {
  readonly hasChanges: boolean;
  readonly content: ContentDefinitionModel;
  readonly date: Day;
  readonly periodTag: string;
  readonly publishStateDisplayText: string;
  isPublished: boolean;

  selectPublishTarget: () => void;
}

export class AppLinkedTasksPublishOccurrenceViewModel implements LinkedTasksPublishOccurrenceViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _data: AccountData,
    public readonly content: EditableContentDefinition
  ) {
    makeObservable(this);
  }

  @computed
  get hasChanges() {
    return this.content.hasChanges;
  }

  @computed
  get date() {
    return this.content.dueDay;
  }

  @computed
  get periodTag() {
    return this.content.duePeriodTag;
  }

  @computed
  get publishStateDisplayText() {
    const strings = this._localizationService.localizedStrings.studyo.contents.linkedTasksPublish;

    if (
      this.content.publishTarget == null ||
      (this.content.publishTarget.kind === 'accounts' && this.content.publishTarget.targetAccountIds.length === 0)
    ) {
      return strings.nonePublish;
    } else if (this.content.publishTarget.kind === 'accounts') {
      const section = this._data.sectionsById.get(this.content.sectionId)!;
      const students = this._data.getStudentsForSection(section);
      const selectedStudents = students.filter(
        (student) => this.content.publishTarget!.targetAccountIds.indexOf(student.id) >= 0
      );

      return strings.somePublish(selectedStudents.length, students.length);
    } else {
      return strings.allPublish;
    }
  }

  @computed
  get isPublished() {
    const publishTarget = this.content.publishTarget;

    return (
      publishTarget != null &&
      (publishTarget.kind === 'section' ||
        (publishTarget.kind === 'accounts' && publishTarget.targetAccountIds.length > 0))
    );
  }

  set isPublished(value: boolean) {
    if (this.content.publishTarget == null) {
      this.content.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
    }

    this.content.publishTarget.targetAccountIds = [];
    this.content.publishTarget.kind = value ? 'section' : 'accounts';
  }

  selectPublishTarget() {
    void this._navigationService.navigateToContentPublishStudentSelection(() => this.content);
  }
}
