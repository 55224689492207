import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppDayAndWeekHeaderViewModel } from './DayAndWeekHeaderViewModel';
import { WeeklyViewModel } from './WeeklyViewModel';

export class WeeklyHeaderViewModel extends AppDayAndWeekHeaderViewModel {
  readonly isDaily = false;
  protected readonly isWeekly = true;

  constructor(
    private _weeklyViewModel: WeeklyViewModel,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  get currentSchoolDay() {
    return this._weeklyViewModel.currentDays[0];
  }

  @computed
  get dayAndWeekViewModel() {
    return this._weeklyViewModel;
  }

  displayOptions() {
    return this._navigationService.navigateToWeeklyOptionMenuModal();
  }
}
