import { css } from '@emotion/css';
import { ArrowCircleLeftSharp, ArrowCircleRightSharp } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import {
  HorizontalPagedView,
  HorizontalPagedViewElement,
  HorizontalPagedViewHandle
} from '@shared/rxp/horizontal-paged-view';
import {
  DailyHeader,
  DayAndWeekConstants,
  DayAndWeekSchoolDayPresenter,
  DisplayableContentBox,
  TimesColumn
} from '@studyo/components';
import { DailyViewModel, DayAndWeekSchoolDayViewModel } from '@studyo/viewmodels';
import _, { times } from 'lodash';
import { autorun } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';

export interface DailyViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: DailyViewModel;
}

export const DailyView = observer((props: DailyViewProps) => {
  const { modalService } = useStudyoServices();
  const { sx, className, viewModel } = props;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const pageViewRef = useRef<HorizontalPagedViewHandle | null>(null);
  const scrollViews = useRef<(HTMLDivElement | null)[]>([]);
  const lastScrollTop = useRef<number | null>(null);

  useEffect(() => {
    const cancellable = autorun(() => {
      const indexToScroll = viewModel.currentDayIndex;

      if (pageViewRef.current != null) {
        pageViewRef.current.scrollToPage(indexToScroll, false);
      }
    });

    return () => cancellable();
  }, [viewModel]);

  const currentDayIndex = viewModel.currentDayIndex;

  const renderPage = (item: HorizontalPagedViewElement<DayAndWeekSchoolDayViewModel>) => {
    const separatorClassName = css({
      backgroundColor: theme.studyo.agenda.daily.headerSeparatorColor,
      height: 1,
      position: 'absolute',
      right: 0, // if removed, the separators will not show
      left: 58
    });

    // Period should be compact only on mobile because of the smaller screen
    const periodCompact = isExtraSmallScreen;

    return (
      <Observer>
        {() => (
          <Box height="100" position="relative" display="flex" flexDirection="column" overflow="hidden">
            <div
              ref={(ref) => {
                scrollViews.current[item.index] = ref;
                scrollViewDidLayout();
              }}
              style={{ overflow: 'auto', flex: 1, position: 'relative' }}
              onScroll={(e) => onScroll(e.currentTarget.scrollTop)}
            >
              {/* the array(23) is for 23 hours to map periods in dailyView by hour. */}
              {times(23).map((_, i) => (
                <Box
                  key={`time-line-${i}`}
                  sx={{ top: viewModel.pointsPerHour * (i + 1) }}
                  className={separatorClassName}
                />
              ))}

              <Stack direction="row">
                <TimesColumn pointsPerHour={viewModel.pointsPerHour} />

                <DayAndWeekSchoolDayPresenter
                  sx={{ flex: 1 }}
                  viewModel={item.item}
                  displayType={viewModel.currentDisplayKind}
                  pointsPerHour={viewModel.pointsPerHour}
                  isCompact={periodCompact}
                  isSmallPeriodTag={false}
                />
              </Stack>
            </div>
          </Box>
        )}
      </Observer>
    );
  };

  const onPageChanged = (item: HorizontalPagedViewElement<DayAndWeekSchoolDayViewModel>) => {
    viewModel.setCurrentDayIndex(item.index);
  };

  const scrollViewDidLayout = () => {
    _.forEach(scrollViews.current, (sv) => {
      if (sv != null) {
        let top: number;

        if (lastScrollTop.current != null) {
          top = lastScrollTop.current;
        } else {
          top = viewModel.pointsPerHour * DayAndWeekConstants.startHour - 12;
        }

        sv.scrollTo({ top: top, behavior: 'auto' });
      }
    });
  };

  const onScroll = (top: number) => {
    lastScrollTop.current = top;
  };

  const showFloatingNavigationButtons = isExtraSmallScreen && !modalService.isDisplayingModal;

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: theme.studyo.agenda.weekly.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'stretch'
      }}
      className={className}
    >
      <Box>
        <DailyHeader
          viewModel={viewModel.header}
          goToNextPage={() => viewModel.goToNextPage()}
          goToPreviousPage={() => viewModel.goToPreviousPage()}
        />
      </Box>

      <Stack
        direction="row"
        sx={{
          backgroundColor: theme.studyo.agenda.dayAndWeekHeaderBackgroundColor,
          borderColor: theme.studyo.agenda.daily.headerSeparatorColor,
          p: '2px',
          borderWidth: 0,
          borderBottomWidth: 1,
          borderTopWidth: 1,
          borderStyle: 'solid'
        }}
      >
        <Box
          sx={{
            width: isExtraSmallScreen
              ? DayAndWeekConstants.timesColumnCompactWidth
              : DayAndWeekConstants.timesColumnWidth
          }}
        />

        <DisplayableContentBox
          sx={{ height: 42, pt: '2px', flex: 1 }}
          viewModel={viewModel.currentDays[currentDayIndex].dayContentsViewModel}
          itemSpacing={DayAndWeekConstants.itemSpacing}
          iconSize={DayAndWeekConstants.detailedIconSize}
          minItemHeight={DayAndWeekConstants.detailedMinItemHeight}
          minItemWidth={DayAndWeekConstants.detailedMinItemWidth}
          maxItemWidth={DayAndWeekConstants.detailedMaxItemWidth}
          representationKind="detailed"
          backgroundColor={theme.studyo.periods.contentBoxBackgroundColor}
        />
      </Stack>

      <HorizontalPagedView
        forwardedRef={(ref) => (pageViewRef.current = ref)}
        data={viewModel.currentDays}
        renderPage={renderPage}
        sx={{ flex: 1 }}
        initialPage={currentDayIndex}
        onPageChanged={onPageChanged}
        pageWidth={450}
        displayPagingControls={false}
      />

      {showFloatingNavigationButtons && (
        <IconButton
          sx={{ zIndex: 10_000, position: 'absolute', bottom: 4, left: 4 }}
          onClick={() => viewModel.goToPreviousPage()}
        >
          <ArrowCircleLeftSharp />
        </IconButton>
      )}

      {showFloatingNavigationButtons && (
        <IconButton
          sx={{ zIndex: 10_000, position: 'absolute', bottom: 4, right: 4 }}
          onClick={() => viewModel.goToNextPage()}
        >
          <ArrowCircleRightSharp />
        </IconButton>
      )}
    </Box>
  );
});
