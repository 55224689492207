import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogCancelled } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AttachmentManager, NavigationService, StudyoAccountSettings, UISettingsStore } from '../../../services';
import { AppBaseAgendaHeaderViewModel, BaseAgendaHeaderViewModel } from '../BaseAgendaHeaderViewModel';

export interface PlannerHeaderViewModel extends BaseAgendaHeaderViewModel {
  readonly hasContentFiltersSet: boolean;
  readonly hasSectionFiltersSet: boolean;
  readonly currentDay: Day;
  readonly dateTitle: string;

  displayContentsFilter: () => Promise<void | DialogCancelled>;
  displaySectionsFilter: () => Promise<void | DialogCancelled>;

  displayOptions: () => Promise<void | DialogCancelled>;
}

export class AppPlannerHeaderViewModel extends AppBaseAgendaHeaderViewModel implements PlannerHeaderViewModel {
  constructor(
    localizationService: LocalizationService,
    navigationService: NavigationService,
    attachmentManager: AttachmentManager,
    data: AccountData,
    preferences: StudyoAccountSettings,
    uiSettingsStore: UISettingsStore
  ) {
    super(localizationService, navigationService, attachmentManager, data, preferences, uiSettingsStore);
    makeObservable(this);
  }

  @computed
  private get displayedSchoolDays(): SchoolDay[] {
    if (this._preferences.plannerDisplayWeekends) {
      return this._data.schoolDays;
    } else {
      return this._data.schoolDays.filter((sd) => !DateUtils.isWeekend(sd.day));
    }
  }

  @computed
  get currentDay(): Day {
    const currentDay = this._uiSettingsStore.plannerCurrentDay;
    const currentDayIndex = currentDay != null ? this.findIndexOfDay(currentDay) : 0;
    return this.displayedSchoolDays[currentDayIndex].day;
  }

  @computed
  get dateTitle(): string {
    return this.currentDay.formattedString(
      this._localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
    );
  }

  @computed
  get hasContentFiltersSet() {
    return this._preferences.plannerContentFilters.isFiltering;
  }

  @computed
  get hasSectionFiltersSet() {
    const numberOfSections = this._preferences.plannerSectionFilters.length;
    return numberOfSections !== 0 && numberOfSections !== this._data.userSections.length;
  }

  displayContentsFilter() {
    return this._navigationService.navigateToPlannerFilterOptionsModal();
  }

  displaySectionsFilter() {
    return this._navigationService.navigateToPlannerSectionFilterModal();
  }

  displayOptions() {
    return this._navigationService.navigateToPlannerOptionMenuModal();
  }

  private findIndexOfDay(day: Day) {
    let dayIndex = -1;
    const schoolDays = this.displayedSchoolDays;

    for (let i = 0; i < schoolDays.length; i++) {
      const schoolDay = schoolDays[i];
      const compareDate = schoolDay.day.compare(day);

      if (compareDate === 0) {
        // Today is in the displayed school days, we scroll to its index.
        dayIndex = i;
        break;
      } else if (compareDate > 0) {
        // We haven't found today in the displayed school days, but we have passed it.
        // This means today is a weekend day and they are hidden. In this case, we scroll the
        // the day before.
        //
        // If we are evaluating the first school day, it means today is before the start of the school days.
        // In this case, we scroll to the first day.
        dayIndex = i > 0 ? i - 1 : 0;
        break;
      }
    }

    // All school days displayed are before today. In this case, we scroll to the last day of the year.
    if (dayIndex == -1) {
      dayIndex = schoolDays.length - 1;
    }

    return dayIndex;
  }
}
