import { Box, SxProps } from '@mui/material';
import { DialogResult } from '@shared/services';
import { DialogActionButtons, DialogButton, ResponsiveDialog } from '@studyo/components';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { PlannerSectionFilterView } from '../../../views';

export interface PlannerSectionFilterScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
}

export const PlannerSectionFilterScreen = observer((props: PlannerSectionFilterScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx, className, onCancel, onSuccess } = props;
  const viewModel = useMemo(
    () => viewModelFactory.createPlannerSectionFilter(onSuccess!, onCancel!),
    [onSuccess, onCancel]
  );
  const strings = localizationService.localizedStrings.studyo.agenda.planner;

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="xs"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={() => viewModel.dismiss()}
    >
      <DialogHeader title={strings.sectionFilterTitle} onClose={() => viewModel.dismiss()} />

      <PlannerSectionFilterView sx={{ flex: 1 }} viewModel={viewModel} />

      <DialogActionButtons>
        <DialogButton
          title={strings.filterOptions.reset}
          type="destructive"
          disabled={!viewModel.isFiltered}
          onPress={() => viewModel.reset()}
        />

        <Box flex={1} />

        <DialogButton
          title={strings.sectionFilterClose}
          type="normal"
          onPress={() => viewModel.dismiss()}
          minimumScreenSize="sm"
        />
      </DialogActionButtons>
    </ResponsiveDialog>
  );
});
