import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoAccountSettings, StudyoSettingsStore } from '../../../services';
import { OptionMenuElementViewModel } from './OptionMenuElementViewModel';
import { OptionMenuSectionViewModel } from './OptionMenuSectionViewModel';

export interface OptionMenuViewModel {
  readonly preferences: StudyoAccountSettings;
  readonly sections: OptionMenuSectionViewModel[];
  readonly didSelectElement: (index: number, sectionIndex: number) => void;
  readonly dismiss: () => void;
}

export abstract class AppOptionMenuViewModel implements OptionMenuViewModel {
  readonly preferences: StudyoAccountSettings;

  constructor(
    protected readonly accountService: AccountService,
    protected readonly localizationService: LocalizationService,
    protected readonly navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    this.preferences = settingsStore.getPreferences(accountService.displayedAccountData.accountId);
  }

  abstract get sections(): OptionMenuSectionViewModel[];

  dismiss() {
    this._onSuccess();
  }

  didSelectElement = (sectionIndex: number, index: number) => {
    const section = this.sections[sectionIndex];
    const item = section.data[index];
    const canToggle = this.toggleValidator(section, item);

    if (canToggle) {
      item.toggleSelectedState();
      item.onSelectionChange(item.isSelected);
    }
  };

  private singleSelectionHandler = (section: OptionMenuSectionViewModel, item: OptionMenuElementViewModel) => {
    let canToggle = true;

    // Checks if element to toggle is already selected.
    // If true, can not toggle off since it requires to have one selected element.
    if (item.isSelected) {
      canToggle = false;
    }

    // If false, removes other toggle-ons
    if (canToggle) {
      section.data.forEach((element) => {
        if (element.isSelected) {
          element.isSelected = false;
        }
      });
    }

    return canToggle;
  };

  private multipleSelectionHandler = (section: OptionMenuSectionViewModel, item: OptionMenuElementViewModel) => {
    let canToggle = false;

    // If item was toggled false then it can toggle true without verifying further.
    if (!item.isSelected) {
      canToggle = true;
    } else {
      // Else, check if at least one other item is toggled true so there is always a toggled on item.
      section.data.forEach((element) => {
        if (element !== item && element.isSelected) {
          canToggle = true;
        }
      });
    }

    return canToggle;
  };

  private toggleValidator = (section: OptionMenuSectionViewModel, item: OptionMenuElementViewModel) => {
    let canToggle = true;

    switch (section.selectionType) {
      // Only one selected at a time, if a new one is selected, remove the other selection
      case 'single-selection':
        canToggle = this.singleSelectionHandler(section, item);
        break;

      // At least one is selected, but can be more.
      // Can't toggle off if it is the last one.
      case 'multiple-selection':
        canToggle = this.multipleSelectionHandler(section, item);
        break;

      // Each item doesn't care about the others in order to change it's
      // selection state which means it can always toggle
      case 'independent':
        canToggle = true;
        break;
    }

    return canToggle;
  };
}
