import { EditableAccount } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { AccountData } from '@shared/services/stores';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { AccountService } from '../../services';

export interface AskNameViewModel {
  readonly data: AccountData;
  readonly canSave: boolean;
  message: string;
  firstName: string;
  lastName: string;

  close: () => void;
  save: () => Promise<void>;
}

export class AppAskNameViewModel implements AskNameViewModel {
  readonly data: AccountData;

  @observable private _account?: EditableAccount;
  @observable private _message = ' ';

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void
  ) {
    makeObservable(this);
    this.data = this._accountService.displayedAccountData;
    when(
      () => this.data.hasData,
      () => (this._account = new EditableAccount(this.data.account))
    );
  }

  @computed
  get firstName() {
    return this._account != null ? this._account.firstName : '';
  }

  set firstName(newValue: string) {
    if (this._account != null) {
      this._account.firstName = newValue;
    } else {
      throw new Error('attempting to edit name of a null account');
    }
  }

  @computed
  get lastName() {
    return this._account != null ? this._account.lastName : '';
  }

  set lastName(newValue: string) {
    if (this._account != null) {
      this._account.lastName = newValue;
    } else {
      throw new Error('attempting to edit name of a null account');
    }
  }

  @computed
  get message() {
    return this._message;
  }

  set message(newValue: string) {
    this._message = newValue;
  }

  @computed
  get canSave() {
    return this._account != null ? this.firstName.length > 0 && this.lastName.length > 0 : false;
  }

  @action
  async save() {
    this.message = '';
    if (this._account != null) {
      await this.data.createOrUpdateAccount(this._account);
      await this._accountService.refreshAccounts();

      // Try to set the account back to the one we had. This is because
      // the refreshAccount have loaded a new list of account summaries,
      // and we want to ensure we still have the account in the new list.
      await this._accountService.setCurrentDisplayedAccount(this.data.accountId);
    } else {
      this.message = this._localizationService.localizedStrings.studyo.agenda.setting.askName.errorMessage;
      throw new Error('Trying to set a name while there is no current account.');
    }
  }

  close() {
    this._onSuccess();
  }
}
