import { SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { YearMonthViewModel } from '../../../viewmodels';

export interface YearMonthTitleProps {
  sx?: SxProps;
  className?: string;
  month: YearMonthViewModel;
}

export const YearMonthTitle = observer((props: YearMonthTitleProps) => {
  const { sx, className, month } = props;

  return (
    <Typography sx={sx} className={className} variant="h6" fontWeight="500">
      {month.monthTitle}
    </Typography>
  );
});
