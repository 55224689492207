import {
  ContentDefinition_Attachment as PBAttachment,
  ContentDefinition as PBContentDefinition,
  ContentDefinition_ContentState as PBContentState,
  ContentDefinition_Step as PBStep
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { LazyGetter } from 'lazy-get-decorator';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BaseModel, SerializableModel } from '../Model';
import { ContentIcon, ContentKind, ContentState, ContentWorkloadLevel, Day, Time } from '../types';
import {
  contentIconFromProtobuf,
  contentKindFromProtobuf,
  contentStateFromProtobuf,
  contentWorkloadLevelFromProtobuf,
  protobufFromAttachmentKind,
  protobufFromContentIcon,
  protobufFromContentKind,
  protobufFromContentWorkloadLevel
} from '../types/EnumConversion';
import { ContentAttachment, ContentAttachmentModel } from './ContentAttachment';
import { ContentPublishTarget, ContentPublishTargetModel } from './ContentPublishTarget';
import { ContentStep, ContentStepModel } from './ContentStep';
import { ExternalContentDefinition, ExternalContentDefinitionModel } from './ExternalContentDefinition';
import { MasterContentDefinition, MasterContentDefinitionModel } from './MasterContentDefinition';

export interface ContentDefinitionModel extends SerializableModel<PBContentDefinition> {
  readonly id: string;
  readonly syncToken: string;
  readonly isDeleted: boolean;
  readonly syncLocalId: string;
  readonly configId: string;
  readonly ownerId: string;
  readonly sectionId: string;
  readonly contentsGroupIdentifier: string;
  readonly kind: ContentKind;
  readonly title: string;
  readonly notes: string;
  readonly icon: ContentIcon;
  readonly workloadLevel: ContentWorkloadLevel;
  readonly isPrivate: boolean;
  readonly attachments: ContentAttachmentModel[];
  readonly steps: ContentStepModel[];
  readonly assignmentDay: Day;
  readonly plannedDay: Day;
  readonly dueDay: Day;
  readonly duePeriodTag: string;
  readonly state: ContentState;
  readonly isUnread: boolean;
  readonly reminderDays: number;
  readonly reminderTime: Time | undefined;

  readonly publishTarget: ContentPublishTargetModel | undefined;
  readonly masterContent: MasterContentDefinitionModel | undefined;
  readonly externalContent: ExternalContentDefinitionModel | undefined;
  readonly isMaster: boolean;
  readonly isMasterPublishedToAccounts: boolean;
  readonly isMasterPublishedToSection: boolean;
  readonly isSlave: boolean;
  readonly isPublished: boolean;

  readonly sortedSteps: ContentStepModel[];
  readonly notCompletedSteps: ContentStepModel[];

  // TODO: ...Model
  // readonly externalContent: ExternalContentDefinitionModel | undefined;
}

export class ContentDefinition extends BaseModel<PBContentDefinition> implements ContentDefinitionModel {
  // Creates a copy of a content.
  static copy(originalContent: ContentDefinitionModel, configId: string, accountId: string): ContentDefinitionModel {
    const newContent = new PBContentDefinition();
    newContent.configId = configId;
    newContent.ownerId = accountId;

    newContent.dueDay = originalContent.dueDay.asPB;
    newContent.plannedDay = originalContent.plannedDay.asPB;
    newContent.assignmentDay = originalContent.assignmentDay.asPB;

    newContent.icon = protobufFromContentIcon(originalContent.icon);
    newContent.title = originalContent.title;
    newContent.notes = originalContent.notes;
    newContent.workload = protobufFromContentWorkloadLevel(originalContent.workloadLevel);
    newContent.isPrivate = originalContent.isPrivate;
    newContent.kind = protobufFromContentKind(originalContent.kind);
    newContent.state = PBContentState.ACTIVE;

    newContent.attachments = originalContent.attachments.map((originalAttachment) => {
      const newAttachment = new PBAttachment();
      newAttachment.id = uuidv4();
      newAttachment.kind = protobufFromAttachmentKind(originalAttachment.kind);
      newAttachment.fileName = originalAttachment.fileName;
      newAttachment.fileUrl = originalAttachment.fileUrl;
      newAttachment.externalUrl = originalAttachment.externalUrl;
      newAttachment.thumbUrl = originalAttachment.thumbUrl;
      newAttachment.title = originalAttachment.title;
      return newAttachment;
    });

    newContent.steps = originalContent.steps.map((originalStep) => {
      const newStep = new PBStep();
      newStep.id = uuidv4();
      newStep.stepId = originalStep.stepId;
      newStep.sortOrder = originalStep.sortOrder;
      newStep.title = originalStep.title;
      return newStep;
    });

    return new ContentDefinition(newContent);
  }

  constructor(pb: PBContentDefinition) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get syncToken(): string {
    return this._pb.syncToken;
  }

  get isDeleted(): boolean {
    return this._pb.isDeleted;
  }

  get syncLocalId(): string {
    return this._pb.syncLocalId;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get ownerId(): string {
    return this._pb.ownerId;
  }

  get sectionId(): string {
    return this._pb.sectionId;
  }

  get contentsGroupIdentifier(): string {
    return this._pb.contentsGroupIdentifier;
  }

  get kind(): ContentKind {
    return contentKindFromProtobuf(this._pb.kind);
  }

  get title(): string {
    return this._pb.title;
  }

  get notes(): string {
    return this._pb.notes;
  }

  get icon(): ContentIcon {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get isPrivate(): boolean {
    return this._pb.isPrivate;
  }

  get workloadLevel(): ContentWorkloadLevel {
    return contentWorkloadLevelFromProtobuf(this._pb.workload);
  }

  @LazyGetter()
  get attachments(): ContentAttachmentModel[] {
    return this._pb.attachments.map((a) => new ContentAttachment(a));
  }

  @LazyGetter()
  get steps(): ContentStepModel[] {
    return this._pb.steps.map((s) => new ContentStep(s));
  }

  @LazyGetter()
  get sortedSteps() {
    return _.sortBy(this.steps, ['sortOrder']);
  }

  @LazyGetter()
  get notCompletedSteps() {
    return _.filter(this.sortedSteps, (s) => !s.completed);
  }

  @LazyGetter()
  get assignmentDay(): Day {
    const day = this._pb.assignmentDay;
    // This value is never supposed to be null, but past apps did insert null values by mistake.
    return day ? new Day(day) : this.dueDay;
  }

  @LazyGetter()
  get plannedDay(): Day {
    const day = this._pb.plannedDay;
    // This value is never supposed to be null, but past apps did insert null values by mistake.
    return day ? new Day(day) : this.assignmentDay;
  }

  @LazyGetter()
  get dueDay(): Day {
    const day = this._pb.dueDay;
    if (day == null) {
      throw new Error('Invalid data from backend: ContentDefinition with no DueDay.');
    }

    return new Day(day);
  }

  get duePeriodTag(): string {
    return this._pb.duePeriodTag;
  }

  get state(): ContentState {
    const state = contentStateFromProtobuf(this._pb.state);
    return state === 'active' && ContentDefinitionUtils.isNoLoadAndPastDue(this) ? 'completed' : state;
  }

  get isUnread(): boolean {
    return this._pb.isUnread;
  }

  get reminderDays(): number {
    return this._pb.reminderDays;
  }

  @LazyGetter()
  get reminderTime(): Time | undefined {
    return Time.fromPB(this._pb.reminderTime);
  }

  @LazyGetter()
  get publishTarget(): ContentPublishTargetModel | undefined {
    const target = this._pb.publishTarget;
    return target ? new ContentPublishTarget(target) : undefined;
  }

  @LazyGetter()
  get masterContent(): MasterContentDefinitionModel | undefined {
    const master = this._pb.masterContent;
    return master ? new MasterContentDefinition(master) : undefined;
  }

  @LazyGetter()
  get externalContent(): ExternalContentDefinitionModel | undefined {
    const external = this._pb.externalContent;
    return external ? new ExternalContentDefinition(external) : undefined;
  }

  get isMaster(): boolean {
    return this.isMasterPublishedToAccounts || this.isMasterPublishedToSection;
  }

  get isMasterPublishedToAccounts(): boolean {
    return (
      this.publishTarget != null &&
      this.publishTarget.kind === 'accounts' &&
      this.publishTarget.targetAccountIds.length > 0
    );
  }

  get isMasterPublishedToSection(): boolean {
    return this.publishTarget != null && this.publishTarget.kind === 'section';
  }

  get isSlave(): boolean {
    return this._pb.masterContent != null;
  }

  get isPublished(): boolean {
    return this.isMaster || this.isSlave;
  }

  //
  // Protobuf properties not exposed by this client model:
  //
  //   - dueCourseOrdinal
}
