import { Add, CameraAlt, Pending, Sync } from '@mui/icons-material';
import { IconButton, SxProps } from '@mui/material';
import { observer } from 'mobx-react';

export type CalendarHeaderActionButtonKind = 'add' | 'camera' | 'options' | 'sync';

export interface CalendarHeaderActionButtonOptions {
  readonly kind: CalendarHeaderActionButtonKind;
  action: () => void;
}

export interface CalendarHeaderActionButtonProps {
  sx?: SxProps;
  className?: string;
  options: CalendarHeaderActionButtonOptions;
  iconSize?: number;
}

export const CalendarHeaderActionButton = observer((props: CalendarHeaderActionButtonProps) => {
  const { options, sx, className } = props;

  const icon = () => {
    switch (options.kind) {
      case 'add':
        return <Add fontSize="medium" />;
      case 'camera':
        return <CameraAlt fontSize="medium" />;
      case 'options':
        return <Pending fontSize="medium" />;
      case 'sync':
        return <Sync fontSize="medium" />;
    }
  };

  return (
    <IconButton sx={sx} className={className} size="small" onClick={() => options.action()} color="primary">
      {icon()}
    </IconButton>
  );
});
