import { NetworkService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';

export interface ProfileMenuSynchronizeOptionViewModel {
  readonly isSyncing: boolean;
  readonly lastSynchronizationTimestamp: Date | undefined;
  readonly lastSynchronizationError: Error | undefined;
  readonly isOffline: boolean;

  synchronize(): void;
}

export class AppProfileMenuSynchronizeOptionViewModel implements ProfileMenuSynchronizeOptionViewModel {
  constructor(
    private readonly _accountData: AccountData,
    private readonly _networkService: NetworkService
  ) {
    makeObservable(this);
  }

  @computed
  get isSyncing() {
    return this._accountData.isRefreshing;
  }

  @computed
  get lastSynchronizationError() {
    return this._accountData.lastRefreshError;
  }

  @computed
  get lastSynchronizationTimestamp() {
    return this._accountData.lastRefreshTimestamp;
  }

  @computed
  get isOffline() {
    return !this._networkService.isOnline;
  }

  synchronize() {
    void this._accountData.refresh();
  }
}
