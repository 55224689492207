import { Section_Schedule_Custom as PBScheduleCustom } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel, SerializableModel } from '../Model';
import { Time } from '../types';

export interface SectionScheduleCustomModel extends SerializableModel<PBScheduleCustom> {
  readonly startTime: Time;
  readonly endTime: Time;
  readonly displayPeriodTag: string;
}

export class SectionScheduleCustom extends BaseModel<PBScheduleCustom> implements SectionScheduleCustomModel {
  constructor(pb: PBScheduleCustom) {
    super(pb);
  }

  @LazyGetter()
  get startTime(): Time {
    const time = this._pb.startTime;
    if (time == null) {
      throw new Error('Invalid data from backend: SectionSchedule.Custom.startTime is undefined.');
    }
    return new Time(time);
  }

  @LazyGetter()
  get endTime(): Time {
    const time = this._pb.endTime;
    if (time == null) {
      throw new Error('Invalid data from backend: SectionSchedule.Custom.endTime is undefined.');
    }
    return new Time(time);
  }

  get displayPeriodTag(): string {
    return this._pb.displayPeriodTag;
  }
}
