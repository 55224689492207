import { LocalizationService } from '@shared/resources/services';
import { AccountService, NavigationService, StudyoSettingsStore } from '@studyo/services';
import { computed, makeObservable } from 'mobx';
import {
  AppOptionMenuElement,
  AppOptionMenuSectionViewModel,
  AppOptionMenuViewModel,
  OptionMenuSectionViewModel,
  OptionMenuViewModel
} from '../../utils';

export type PeriodsOptionMenuViewModel = OptionMenuViewModel;

export class AppPeriodsOptionMenuViewModel extends AppOptionMenuViewModel implements PeriodsOptionMenuViewModel {
  constructor(
    accountService: AccountService,
    localizationService: LocalizationService,
    navigationService: NavigationService,
    onSuccess: () => void,
    onCancel: () => void,
    settingsStore: StudyoSettingsStore
  ) {
    super(accountService, localizationService, navigationService, onSuccess, onCancel, settingsStore);
    makeObservable(this);
  }

  @computed
  get sections(): OptionMenuSectionViewModel[] {
    if (this.preferences.hasData) {
      return this.loadSections();
    } else {
      return [];
    }
  }

  private loadSections(): OptionMenuSectionViewModel[] {
    const strings = this.localizationService.localizedStrings.studyo.agenda.periods.optionMenu;

    const displayAnnouncedDayOption = new AppOptionMenuElement(
      () => this.didSelectElement(0, 0),
      this.onDisplayAnnouncedDayPress,
      strings.displayAnnouncedDayTitle,
      this.preferences.periodShowAssignmentDay
    );

    const displayedElementsSection = new AppOptionMenuSectionViewModel(
      strings.displayedElementsTitle,
      [displayAnnouncedDayOption],
      'independent'
    );

    return [displayedElementsSection];
  }

  private onDisplayAnnouncedDayPress = () => {
    this.preferences.periodShowAssignmentDay = !this.preferences.periodShowAssignmentDay;
  };
}
