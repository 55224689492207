import { Breakpoint, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface DialogActionButtonsProps {
  sx?: SxProps;
  className?: string;
  children: React.ReactNode;
  minimumScreenSize?: Breakpoint;
  backgroundColor?: string;
}

export const DialogActionButtons = observer((props: DialogActionButtonsProps) => {
  const { sx, className, children, backgroundColor, minimumScreenSize } = props;
  const theme = useTheme();
  const isVisible = useMediaQuery(() => theme.breakpoints.up(minimumScreenSize ?? 'xs'));

  if (!isVisible) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{ ...sx, backgroundColor: backgroundColor }}
      className={className}
      p={2}
      justifyContent="flex-end"
      spacing={1}
    >
      {children}
    </Stack>
  );
});
