import { SchoolDay, SchoolDayPeriod, SpecialDayOccurrence } from '@shared/models/calendar';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { LinkingService } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { LazyGetter } from 'lazy-get-decorator';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  ContentPasteboardStore,
  NavigationService,
  StudyoAccountSettings,
  StudyoAnalyticsService
} from '../../../services';
import {
  AppDisplayableContentBoxViewModel,
  AppDisplayableContentViewModel,
  DisplayableContentBoxViewModel,
  DisplayableContentViewModel
} from '../../contents';
import { AppDayAndWeekPeriodViewModel, DayAndWeekPeriodViewModel } from './DayAndWeekPeriodViewModel';
import { DayAndWeekViewModelKind } from './DayAndWeekViewModel';
import { AppWeeklyDayHeaderViewModel, WeeklyDayHeaderViewModel } from './WeeklyDayHeaderViewModel';

interface DayAndWeekSchoolDayPeriodViewModelData {
  viewModel: DayAndWeekPeriodViewModel;
  period: SchoolDayPeriod;
}

export interface DayAndWeekSchoolDayViewModel {
  readonly periods: DayAndWeekSchoolDayPeriodViewModelData[];
  readonly day: Day;
  readonly schoolDayContents: ContentDefinitionModel[];
  readonly dayContentsViewModel: DisplayableContentBoxViewModel;
  readonly specialDays: SpecialDayOccurrence[];
  readonly cycleDayTitle: string;
  readonly weeklyDayHeaderViewModel: WeeklyDayHeaderViewModel;
}

export class AppDayAndWeekSchoolDayViewModel implements DayAndWeekSchoolDayViewModel {
  public readonly dayContentsViewModel: DisplayableContentBoxViewModel;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _contentPasteboardStore: ContentPasteboardStore,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _linkingService: LinkingService,
    private readonly _viewModelKind: DayAndWeekViewModelKind,
    private readonly _preferences: StudyoAccountSettings,
    private readonly _schoolDay: SchoolDay,
    private readonly _data: AccountData
  ) {
    makeObservable(this);
    this.dayContentsViewModel = new AppDisplayableContentBoxViewModel(
      this._localizationService,
      this._navigationService,
      this._analyticsService,
      this._contentPasteboardStore,
      this._data,
      this.day,
      undefined,
      () => undefined,
      () => this.transformContentsIntoViewModels(this.schoolDayContents)
    );
  }

  private shouldDisplayAssignments(): boolean {
    return this._viewModelKind === 'daily';
  }

  @computed
  get specialDays() {
    return this._schoolDay.specialDays;
  }

  @computed
  get cycleDayTitle() {
    return this._schoolDay.displayCycleDayTitle;
  }

  @computed
  get day() {
    return this._schoolDay.day;
  }

  @computed
  get schoolDayContents(): ContentDefinitionModel[] {
    // Notes can appear in the all-day section, when their period tag has disappeared.
    const allDayContents = this._data.visibleContents.filter(
      (cd) => (cd.kind === 'task' && cd.assignmentDay.isSame(this.day)) || cd.dueDay.isSame(this.day)
    );
    const sectionIds = new Set(
      _.compact(
        _.map(this._schoolDay.periods, (p) => {
          const periodCourseOccurrence = this._data.periodPrioritiesStore.getOccurrenceForPeriod(
            p,
            this._schoolDay.day
          );
          return periodCourseOccurrence != null ? periodCourseOccurrence.sectionId : undefined;
        })
      )
    );

    return allDayContents.filter((content) => {
      if (this.isAssignment(content)) {
        return this.shouldDisplayAssignments() && (content.sectionId === '' || !sectionIds.has(content.sectionId));
      } else {
        if (content.duePeriodTag === '') {
          return true;
        } else {
          // Tasks or notes can belong to periods that are no more visible. They end up
          // in the all-day area.
          return this._schoolDay.periods.every((p) => p.tag !== content.duePeriodTag);
        }
      }
    });
  }

  @LazyGetter()
  get weeklyDayHeaderViewModel() {
    return new AppWeeklyDayHeaderViewModel(this.day, this.cycleDayTitle, this.specialDays, false, false);
  }

  @LazyGetter()
  get periods() {
    const periods = this._schoolDay.sortedPeriods.filter(
      (p) => !this._data.periodPrioritiesStore.getPeriodIsHidden(p, this.day)
    );

    return periods.map((p) => ({
      viewModel: new AppDayAndWeekPeriodViewModel(
        this._localizationService,
        this._navigationService,
        this._contentPasteboardStore,
        this._analyticsService,
        this._linkingService,
        p,
        this._data,
        this._schoolDay.day,
        this._preferences,
        this._viewModelKind,
        periods
      ),
      period: p
    }));
  }

  private transformContentsIntoViewModels(contentDefinitions: ContentDefinitionModel[]): DisplayableContentViewModel[] {
    // Always hide announced days in week view. Hide if unset in daily view.
    return contentDefinitions
      .filter(
        (cd) => !this.isAssignment(cd) || (this._viewModelKind === 'daily' && this._preferences.dailyShowAssignmentDay)
      )
      .map(
        (cd) =>
          new AppDisplayableContentViewModel(
            this._localizationService,
            this._data,
            cd.sectionId.length > 0 ? this._data.sectionsById.get(cd.sectionId) : undefined,
            undefined,
            cd,
            this.isAssignment(cd)
          )
      );
  }

  private isAssignment(content: ContentDefinitionModel): boolean {
    return !content.dueDay.isSame(this._schoolDay.day);
  }
}
