export interface EmailInfo {
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  body?: string;
}

export interface LinkingService {
  launchEmail(emailInfo: EmailInfo): void;
}

/**
 * Implementing our own LinkingService since `RX.Linking.launchEmail` doesn't work properly with "new" Outlook for Mac
 * @see https://app.clubhouse.io/studyodev/story/12800
 */
export abstract class AppLinkingService {
  abstract launchEmail(emailInfo: EmailInfo): void;

  protected generateMailToUrl(emailInfo: EmailInfo): string {
    const params = new URLSearchParams();

    if (emailInfo.cc != null) {
      params.append('cc', emailInfo.cc.join(','));
    }

    if (emailInfo.bcc != null) {
      params.append('bcc', emailInfo.bcc.join(','));
    }

    if (emailInfo.subject != null) {
      params.append('subject', emailInfo.subject);
    }

    if (emailInfo.body != null) {
      params.append('body', emailInfo.body);
    }

    /**
     * `URLSearchParams` encodes spaces as `+`, but we want them to be encoded as `%20`
     * otherwise the mailTo will not parse the `+` as spaces
     */
    const paramsUrlStr = params.toString().replace(/\+/g, '%20');

    return `mailto:${emailInfo.to?.join(',')}?${paramsUrlStr}`;
  }
}
