import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  SxProps,
  Typography,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react';
import { ReactNode, useState } from 'react';

export interface DropDownSelectorProps {
  sx?: SxProps;
  className?: string;
  options: string[];
  selectedIndex: number;
  didSelectOption: (index: number) => void;
  renderOption?: (option: string, index: number) => ReactNode;
  displayIconForOption?: (option: string, index: number) => ReactNode;
  displayValueForOption?: (option: string, index: number) => string;
  renderPreview?: (option: string, index: number) => ReactNode;
  renderOptions?: (options: string[], onSelectItem: (index: number) => void) => ReactNode;
  maxHeight?: number;
  disabled?: boolean;
}

export const DropDownSelector = observer(
  ({
    sx,
    className,
    selectedIndex,
    didSelectOption,
    renderOption,
    options,
    renderOptions,
    renderPreview,
    maxHeight,
    displayIconForOption,
    displayValueForOption,
    disabled
  }: DropDownSelectorProps) => {
    const theme = useTheme();
    const [selectMenuAnchor, setSelectMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const selectedOption = options[selectedIndex];

    const selectOptionAtIndex = (index: number) => {
      setSelectMenuAnchor(null);
      didSelectOption(index);
    };

    function renderDefaultPreview(option: string, index: number) {
      return (
        <Stack direction="row" spacing={1.5} alignItems="center">
          {displayIconForOption?.(option, index)}
          <Typography flex={1} variant="body2">
            {displayValueForOption?.(option, index) ?? option}
          </Typography>
        </Stack>
      );
    }

    const renderItem = (option: string, index: number) => {
      if (renderOption != null) {
        return renderOption(option, index);
      } else if (displayValueForOption != null) {
        return (
          <>
            {displayIconForOption != null && <ListItemIcon>{displayIconForOption(option, index)}</ListItemIcon>}
            <ListItemText primary={displayValueForOption(option, index)} />{' '}
          </>
        );
      }

      return option;
    };

    return (
      <Box>
        <CardActionArea
          sx={{ ...sx, display: 'flex', flexDirection: 'row', pl: 1, pr: 0, py: 0.5, borderRadius: 1, minHeight: 30 }}
          className={className}
          onClick={(e) => setSelectMenuAnchor(e.currentTarget)}
          disabled={disabled}
        >
          {renderPreview?.(selectedOption, selectedIndex) ??
            renderOption?.(selectedOption, selectedIndex) ??
            renderDefaultPreview(selectedOption, selectedIndex)}
          <ExpandMore fontSize="small" sx={{ color: theme.palette.text.secondary, ml: 1 }} />
        </CardActionArea>

        {renderOptions != null ? (
          <Popover
            open={selectMenuAnchor != null}
            anchorEl={selectMenuAnchor}
            onClose={() => setSelectMenuAnchor(null)}
          >
            {renderOptions(options, selectOptionAtIndex)}
          </Popover>
        ) : (
          <Menu
            open={selectMenuAnchor != null}
            anchorEl={selectMenuAnchor}
            onClose={() => setSelectMenuAnchor(null)}
            slotProps={{ paper: { sx: { minWidth: 250, maxHeight } } }}
          >
            {options.map((v, i) => (
              <MenuItem key={v} onClick={() => selectOptionAtIndex(i)} selected={i === selectedIndex}>
                {renderItem(v, i)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    );
  }
);
