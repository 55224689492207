import { Storage } from './Storage';

export abstract class AppLocalStorage implements Storage {
  async get<T>(key: string): Promise<T | undefined> {
    try {
      const json = await this.getJson(key);

      if (json == null) {
        return undefined;
      }

      return JSON.parse(json) as T;
    } catch (error) {
      console.warn(`Failed to get other item "${key}" from storage.`);
      return undefined;
    }
  }

  async set<T>(key: string, item: T): Promise<void> {
    try {
      const json = JSON.stringify(item);
      await this.setJson(key, json);
    } catch (error) {
      console.warn(`Failed to save other item "${key}" to storage.`);
    }
  }

  async delete(key: string): Promise<void> {
    try {
      await this.deleteKey(key);
    } catch (error) {
      console.warn(`Failed to delete item "${key}" to storage.`);
    }
  }

  protected abstract getJson(key: string): Promise<string | null>;

  protected abstract setJson(key: string, json: string): Promise<void>;

  protected abstract deleteKey(key: string): Promise<void>;
}
